import React from "react";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import { formatRupiah } from "utils/formatRupiah";
import { deleteData } from "utils/fetchData";
import { fetchPayout, setNotif, fetchBalance } from "features/Balance/actions";
import { useDispatch } from "react-redux";
import Spinner from "components/atoms/Spinner";
import moment from "moment";
import "moment/locale/id";

export default function TableBalance({ data }) {
  const dispatch = useDispatch();

  const cancelPayout = async (id) => {
    await deleteData(`v2/balance/request/${id}`).then((res) => {
      if (res.data.code === 200) {
        let notif = {
          message: `Transfer dana telah dibatalkan`,
          type: "primary",
          status: "danger",
          toggle: true,
        };
        dispatch(setNotif(notif));
        dispatch(fetchPayout());
        dispatch(fetchBalance());
      }
    });
  };

  return (
    <>
      <table className="w-full mt-4">
        <thead>
          <tr>
            <th>Tanggal & waktu</th>
            <th>Nomor transfer</th>
            <th>Jumlah</th>
            <th>Destinasi</th>
            <th colSpan={2}>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.status === "process" ? (
            <tr key="status">
              <td colSpan={6} style={{ textAlign: "center" }}>
                <div className="flex items-center justify-center">
                  <Spinner size={64} color="#859DFF" />
                </div>
              </td>
            </tr>
          ) : data.data.length ? (
            data.data.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{moment(item.date).locale("id").format("LLLL")}</td>
                  <td className="text-blue-03">{item.refrence}</td>
                  <td>{`${formatRupiah(item.value)}`}</td>
                  <td>{item.number}</td>
                  <td className="text-gray-02">{item.status}</td>
                  {item.status !== "APPROVE" ? (
                    <td className="flex justify-end">
                      <Dropdown product="subtle" color="blue" content="icon only">
                        <DropdownList
                          type="button"
                          color="red"
                          onClick={() => cancelPayout(item.id)}
                        >
                          <p className="text-red-03">Batal transfer dana</p>
                        </DropdownList>
                      </Dropdown>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5} style={{ textAlign: "center" }}>
                Tidak Ditemukan Data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
