import React from 'react'

export default function Product({width,fill,height,className}) {
    return (
           <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.00002 3.99999C4.80224 3.99999 4.6089 4.05864 4.44445 4.16852C4.28 4.2784 4.15182 4.43458 4.07614 4.61731C4.00045 4.80003 3.98065 5.0011 4.01923 5.19508C4.05782 5.38906 4.15306 5.56724 4.29291 5.7071C4.43276 5.84695 4.61095 5.94219 4.80493 5.98077C4.99891 6.01936 5.19997 5.99956 5.3827 5.92387C5.56543 5.84818 5.7216 5.72001 5.83149 5.55556C5.94137 5.39111 6.00002 5.19777 6.00002 4.99999C6.00002 4.73477 5.89466 4.48042 5.70712 4.29288C5.51959 4.10535 5.26523 3.99999 5.00002 3.99999ZM19.71 9.77999L10.23 0.31999C10.1368 0.219453 10.0239 0.139165 9.89828 0.0841158C9.7727 0.0290662 9.63713 0.000431106 9.50002 -9.98859e-06H3.50002C3.36841 -0.000770882 3.23795 0.0244542 3.11611 0.0742188C2.99427 0.123983 2.88346 0.197309 2.79002 0.28999L0.290017 2.77999C0.197335 2.87343 0.12401 2.98425 0.0742455 3.10608C0.0244809 3.22792 -0.000744179 3.35838 1.67143e-05 3.48999V9.48999C0.0037002 9.75476 0.107283 10.0084 0.290017 10.2L9.78002 19.7C9.97165 19.8827 10.2253 19.9863 10.49 19.99C10.6216 19.9907 10.7521 19.9655 10.8739 19.9158C10.9958 19.866 11.1066 19.7927 11.2 19.7L19.71 11.19C19.8027 11.0965 19.876 10.9857 19.9258 10.8639C19.9756 10.7421 20.0008 10.6116 20 10.48C19.9938 10.2187 19.8904 9.9691 19.71 9.77999ZM10.49 17.59L2.00002 9.08999V3.89999L3.90002 1.99999H9.08002L17.58 10.49L10.49 17.59Z" fill={fill}/>
</svg>


    )
}

Product.defaultProps ={
    width:'20px',
    height:'20px',
    fill:'#8F8F8F'
}
