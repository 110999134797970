import {
  START_FETCHING_VARIANT,
  ERROR_FETCHING_VARIANT,
  SUCCESS_FETCHING_VARIANT,
  SET_PAGE,
  SET_KEYWORD,
  SET_NOTIF,
  SET_LIMIT
} from "./constants";
import { userLogout } from 'features/Auth/actions'

import { getData } from 'utils/fetchData'
import debounce from "debounce-promise";

let debouncedFetchVariant = debounce(getData, 1000);

export const startFetchingVariant = () => {
  return {
    type: START_FETCHING_VARIANT,
  };
};

export const errorFetchingVariant = () => {
  return {
    type: ERROR_FETCHING_VARIANT,
  };
};

export const successFetchingVariant = ({ variant, pages, count }) => {
  return {
    type: SUCCESS_FETCHING_VARIANT,
    variant,
    pages,
    count
  };
};

export const fetchVariant = () => {
  return async (dispatch, getState) => {
    try {

      const notif = {
        message: '',
        type: '',
        status: '',
        toggle: false
      }

      setTimeout(() => {
        dispatch(setNotif(notif))
      }, 5000)

      let keyword = getState().variant.keyword || ''
      let page = getState().variant.page || 1
      let limit = getState().variant.limit || 10

      dispatch(startFetchingVariant());
      const params = {
        keyword,
        page,
        limit
      }
      const res = await debouncedFetchVariant('v2/variant', params);

      console.log("res >>")
      console.log(res)

      if (res.data?.code === 401) {
        dispatch(userLogout())
      }

      dispatch(successFetchingVariant({ variant: res.data.variants, pages: res.data.pages,  count: res.data.total }));

    } catch (err) {
      dispatch(errorFetchingVariant());
    }
  };
};

export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

export const setPage = (number = 1) => {
  return {
    type: SET_PAGE,
    page: number
  }
}

export const setPerPage = (limit) => {
  return {
    type: SET_LIMIT,
    limit: limit
  }
}

