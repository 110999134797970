import {
  START_FETCHING_HISTORY,
  ERROR_FETCHING_HISTORY,
  SUCCESS_FETCHING_HISTORY,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_PRODUCT,
  SET_LOCATION,
  SET_CHANGE,
  SET_DATE,
  SET_MODAL,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  modal: "hide-history-material",
  history: [],
  date: {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  },
  location: "",
  reason_stock: "",
  product: "",
  page: 1,
  limit: 10,
  totalPage: 1,
  status: statuslist.idle,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_HISTORY:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_HISTORY:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_HISTORY:
      return {
        ...state,
        status: statuslist.success,
        history: action.history,
      };

    case SET_DATE:
      return { ...state, date: action.date };

    case SET_LOCATION:
      return {
        ...state,
        location: action.location,
      };
    case SET_CHANGE:
      return {
        ...state,
        reason_stock: action.reason_stock,
      };
    case SET_PRODUCT:
      return {
        ...state,
        product: action.product,
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: action.limit,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case SET_TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.totalPage,
      };
    case SET_MODAL:
      return {
        ...state,
        modal: action.modal,
      };

    default:
      return state;
  }
}
