import * as React from "react";
import * as Unicons from 'react-icons/fa'

const Icons = props => {
  const { iconName, iconSize = '', iconColor = '' } = props;
  const className = [props.className]
  const icon = React.createElement(Unicons[iconName]);
  return <div className={`${className.join(" ")}`} style={{ fontSize: iconSize, color: iconColor }}>{icon}</div>;
};


export default Icons