import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import Button from "components/atoms/Button";
import { useDispatch } from "react-redux";
import { fetchMeasure, setNotif } from "features/Measure/action";
import { postData } from "utils/fetchData";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextCaption from "components/atoms/Text/TextCaption";

const MeasureSchema = yup.object().shape({
  name: yup.string()
    .required("nama satuan ukur tidak boleh kosong.")
    .test('len', 'Panjang nama satuan ukur minimal 3 karakter', val => val.length > 2),
  unit: yup.string().required("singkatan satuan ukur tidak boleh kosong."),
});



function MeasureCreate({ open, close, type }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [field, setField] = React.useState({ name: '', unit: '' })

  const { register, handleSubmit, errors, setError } = useForm({
    mode: "onBlur",
    validationSchema: MeasureSchema,
  });

  const onChange = e => {
    setField({ ...field, [e.target.name]: e.target.value })
  }

  const onSubmit = async (payload) => {

    setLoading(true);
    if (type === "material") {
      const res = await postData("v2/unitofmeasure", payload);
      if (res.data.code === 200) {
        setLoading(false);
        close();
        clearField();
        dispatch(fetchMeasure());
      }
    } else {
      const res = await postData("v2/unitofmeasure", payload);
      if (res.data.code === 200) {
        setLoading(false);
        close();
        clearField();
        const notif = {
          message: `Satuan ukur "${res.data.unit.name}" berhasil ditambahkan`,
          type: "primary",
          status: "success",
          id: res.data.unit.id,
          toggle: true,
        };
        dispatch(setNotif(notif));
        dispatch(fetchMeasure());
      }
    }

  }

  const clearField = () => {
    setField({ name: "", unit: "" });
  };

  return (
    <div>
      <ModalDialog
        size={"alert"}
        show={open}
        onClose={close}
        title="Tambah satuan ukur baru"
      >
        <form onSubmit={!loading ? handleSubmit(onSubmit) : null}>
          <div className={`bg-gray-05 p-5 h-full overflow-scroll pl-20`}>
            <InputTextLargeWithLabel
              onChange={(e) => onChange(e)}
              value={field.name}
              className="w-full"
              label="Nama satuan ukur"
              name="name"
              status={errors?.name ? "error" : "default"}
              register={register}
            >
              {errors.name && <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.name?.message} />
              </div>}
            </InputTextLargeWithLabel>
            <InputTextLargeWithLabel
              onChange={(e) => onChange(e)}
              value={field.unit}
              className="w-full mt-3"
              label="Singkatan"
              name="unit"
              status={errors?.unit ? "error" : "default"}
              register={register}
            >
              {errors.unit && <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.unit?.message} />
              </div>}
            </InputTextLargeWithLabel>
          </div>

          <div className="px-5 flex justify-end items-center bg-white">
            <Button
              disabled={field.name !== "" && field.unit !== "" ? false : true}
              variant="primary"
              color="blue"
              className="my-5"
              type="submit"
              loading={loading ? true : false}
            >
              Selesai tambah satuan ukur
            </Button>
          </div>
        </form>
      </ModalDialog>
    </div>
  );
}

export default MeasureCreate;
