import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import stockReducer from "features/Stock/reducers";
import stockHistoryReducer from "features/StockHistory/reducers";
import authReducer from "features/Auth/reducer";
import categoryReducer from "features/Category/reducer";
import checkboxReducer from "features/Checkbox/reducer";
import variantReducer from "features/Variant/reducer";
import taxReducer from "features/Tax/reducer";
import discountReducer from "features/Discount/reducer";
import productReducer from "features/Product/reducer";
import measureReducer from "features/Measure/reducer";
import materialReducer from "features/Material/reducer";
import materialHistoryReducer from "features/MaterialHistory/reducers";
import locationReducer from "features/Location/reducer";
import deviceReducer from "features/Device/reducer";
import transactionReducer from "features/Transaction/reducer";
import transferStockReducer from "features/TransferStock/reducer";
import paymentReducer from "features/Payment/reducer";
import barcodeReducer from "features/Barcode/reducer";
import customerReducer from "features/Customer/reducers";
import customerGroupReducer from "features/CustomerGroup/reducers";
import customerReviewReducer from "features/CustomerReview/reducers";
import balanceReducer from "features/Balance/reducer";
import bankReducer from "features/Bank/reducer";
import searchReducer from "features/GlobalSearch/reducer";
import regionReducer from "features/Region/reducer";
import reportReducer from "features/Report/reducer";
import accountsReducer from "features/Accounts/reducer";
import reportPaymentReducer from "features/ReportPayment/reducer";
import reportTrendReducer from "features/ReportTrend/reducer";
import reportReportCategory from 'features/ReportCategory/reducer'
import reportReportProduct from 'features/ReportProduct/reducer'
import reportReportDiscount from 'features/ReportDiscount/reducer'
import reportReportVariant from 'features/ReportVariant/reducer'
import reportReportTax from 'features/ReportTax/reducer'
import reportReportRefund from 'features/ReportRefund/reducer'
import dashboardReducer from "features/Dashboard/reducer";
import logReducer from "features/ReportLog/reducer";

import thunk from "redux-thunk";

const composerEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducers = combineReducers({
  auth: authReducer,
  category: categoryReducer,
  inventory: stockReducer,
  inventory_history: stockHistoryReducer,
  checkboxs: checkboxReducer,
  variant: variantReducer,
  tax: taxReducer,
  discount: discountReducer,
  product: productReducer,
  measure: measureReducer,
  materials: materialReducer,
  material_history: materialHistoryReducer,
  location: locationReducer,
  device: deviceReducer,
  transaction: transactionReducer,
  transferStock: transferStockReducer,
  payment: paymentReducer,
  barcode: barcodeReducer,
  customer: customerReducer,
  customerGroup: customerGroupReducer,
  reviews: customerReviewReducer,
  balance: balanceReducer,
  bank: bankReducer,
  search: searchReducer,
  region: regionReducer,
  report: reportReducer,
  accounts: accountsReducer,
  reportPayment: reportPaymentReducer,
  reportTrend: reportTrendReducer,
  reportCategory: reportReportCategory,
  reportProduct: reportReportProduct,
  reportDiscount: reportReportDiscount,
  reportVariant: reportReportVariant,
  reportTax: reportReportTax,
  reportRefund: reportReportRefund,
  dashboard: dashboardReducer,
  log: logReducer,
});

const store = createStore(rootReducers, composerEnhancer(applyMiddleware(thunk)));

// (6) export store
export default store;
