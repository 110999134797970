import * as React from "react";
import { DateRange } from "react-date-range";
import DatePicker from "react-datepicker";
import propTypes from "prop-types";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import formatDate from "utils/formatDate";
import IconCalender from "../../Icons/Custom/icon new/Calender";
import IconArrowUp from "../../Icons/Custom/ArrowUp";
import moment from "moment";

/**
 * variant : default,
 * type : single, range
 */

// start input date range 1
function InputDateRange(props) {
  const {
    value,
    placeholder,
    name,
    onChange,
    variant,
    type,
    onChangeDateCostum,
    position,
  } = props;
  const [isShowed, setIsShowed] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const refDate = React.useRef(null);
  const handleClickOutside = (event) => {
    if (refDate && !refDate.current.contains(event.target)) {
      setIsShowed(false);
    }
  };

  const check = (focus) => {
    focus.indexOf(1) < 0 && setIsShowed(false);
  };

  const displayDate = `${value.startDate ? formatDate(value.startDate) : ""}${
    value.endDate ? " - " + formatDate(value.endDate) : ""
  }`;

  if (variant === "range") {
    return (
      <div className={`flex flex-col ${position} z-20`}>
        <div
          ref={refDate}
          className={[
            "calender-range input-date relative",
            props.outerClassName,
          ].join(" ")}
        >
          <div className="input-group">
            <IconCalender
              className={
                "absolute top-1/2 transform -translate-y-1/2 left-4 z-10"
              }
              fill={"#0025B8"}
            />
            <div className="w-full">
              <input
                readOnly
                type="text"
                value={displayDate}
                placeholder={placeholder}
                onClick={() => setIsShowed(!isShowed)}
              />
            </div>
            <IconArrowUp
              className={
                "absolute top-1/2 transform -translate-y-1/2 right-4 z-10"
              }
              fill={"#0025B8"}
            />
          </div>
          {isShowed && (
            <div
              className={`date-range-wrapper absolute top-12 ${
                type === "custom right" && "flex items-start"
              }`}
            >
              <DateRange
                name={name}
                editableDateInputs={true}
                onChange={onChange}
                moveRangeOnFirstSelection={false}
                onRangeFocusChange={check}
                ranges={[value]}
                maxDate={new Date()}
              />

              {type === "custom right" && (
                <ul
                  className="flex flex-col justify-between bg-white py-5 px-4 border-l"
                  style={{
                    height: "418px",
                  }}
                >
                  <li>
                    <button
                      onClick={() =>
                        onChangeDateCostum(moment().toDate(), moment().toDate())
                      }
                      className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full whitespace-nowrap"
                    >
                      Today
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        onChangeDateCostum(
                          moment().add(-1, "day").toDate(),
                          moment().add(-1, "day").toDate()
                        )
                      }
                      className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full whitespace-nowrap"
                    >
                      Yesterday
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        onChangeDateCostum(
                          moment().startOf("week").toDate(),
                          moment().endOf("week").toDate()
                        )
                      }
                      className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full whitespace-nowrap"
                    >
                      This week
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        onChangeDateCostum(
                          moment().add(-1, "week").startOf("week").toDate(),
                          moment().add(-1, "week").endOf("week").toDate()
                        )
                      }
                      className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full whitespace-nowrap"
                    >
                      Last week
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        onChangeDateCostum(
                          moment().startOf("month").toDate(),
                          moment().endOf("month").toDate()
                        )
                      }
                      className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full whitespace-nowrap"
                    >
                      This month
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        onChangeDateCostum(
                          moment().add(-1, "month").startOf("month").toDate(),
                          moment().add(-1, "month").endOf("month").toDate()
                        )
                      }
                      className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full whitespace-nowrap"
                    >
                      Last month
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        onChangeDateCostum(
                          moment().startOf("year").toDate(),
                          moment().endOf("year").toDate()
                        )
                      }
                      className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full whitespace-nowrap"
                    >
                      This year
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        onChangeDateCostum(
                          moment().add(-1, "year").startOf("year").toDate(),
                          moment().add(-1, "year").endOf("year").toDate()
                        )
                      }
                      className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full whitespace-nowrap"
                    >
                      Last year
                    </button>
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

InputDateRange.propTypes = {
  value: propTypes.object,
  onChange: propTypes.func,
  placeholder: propTypes.string,
  outerClassName: propTypes.string,
};

InputDateRange.defaultProps = {
  position: "items-center",
};
// end input date range 1
// start input date range 2
function InputDateRange2(props) {
  const { value, placeholder, name, onChange } = props;
  const [isShowed, setIsShowed] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const refDate = React.useRef(null);
  const handleClickOutside = (event) => {
    if (refDate && !refDate.current.contains(event.target)) {
      setIsShowed(false);
    }
  };

  const check = (focus) => {
    focus.indexOf(1) < 0 && setIsShowed(false);
  };

  const displayDate = `${value.startDate ? formatDate(value.startDate) : ""}${
    value.endDate ? " - " + formatDate(value.endDate) : ""
  }`;
  return (
    <div className="flex flex-col items-center mt-10">
      <div
        ref={refDate}
        className={[
          "calender-range range2 input-date",
          props.outerClassName,
        ].join(" ")}
      >
        <div className="input-group">
          <div className="input-group-prepend bg-gray-900">
            <span className="input-group-text">
              {/* <img src={iconCalendar} alt="icon calendar" /> */}
            </span>
          </div>
          <IconCalender
            className={
              "absolute top-1/2 transform -translate-y-1/2 left-3 z-10"
            }
            fill={"#0025B8"}
          />
          <input
            readOnly
            type="text"
            className="form-control"
            value={displayDate}
            placeholder={placeholder}
            onClick={() => setIsShowed(!isShowed)}
          />
          <IconArrowUp
            className={
              "absolute top-1/2 transform -translate-y-1/2 right-3 z-10"
            }
            fill={"#0025B8"}
          />
        </div>
        {isShowed && (
          <div className="date-range-wrapper absolute flex items-start shadow">
            <DateRange
              name={name}
              editableDateInputs={true}
              onChange={onChange}
              moveRangeOnFirstSelection={false}
              onRangeFocusChange={check}
              ranges={[value]}
              maxDate={new Date()}
            />
            <ul className="flex flex-col h-377 justify-between bg-white mt-1 py-5 px-4 border-l">
              <li>
                <button className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full">
                  Today
                </button>
              </li>
              <li>
                <button className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full">
                  Yesterday
                </button>
              </li>
              <li>
                <button className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full">
                  This week
                </button>
              </li>
              <li>
                <button className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full">
                  Last week
                </button>
              </li>
              <li>
                <button className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full">
                  This month
                </button>
              </li>
              <li>
                <button className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full">
                  Last month
                </button>
              </li>
              <li>
                <button className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full">
                  This year
                </button>
              </li>
              <li>
                <button className="btn-bg bg-gray-05 text-gray-03 text-sm font-semibold w-full">
                  Last year
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

InputDateRange2.propTypes = {
  value: propTypes.object,
  onChange: propTypes.func,
  placeholder: propTypes.string,
  outerClassName: propTypes.string,
};
// end input date range 2

/** input date single */
function InputDate(props) {
  const { onChange, date, variant, type } = props;
  if (variant === "single")
    return (
      <div className="flex items-center">
        <div className="calender relative max-w-155 bg-white">
          <IconCalender
            className={
              "absolute top-1/2 transform -translate-y-1/2 left-4 z-10"
            }
            fill={"#0025B8"}
          />
          <DatePicker selected={date} onChange={onChange}>
            {type === "label" && (
              <div>
                <div className="date-range flex flex-col w-full border-t pt-4 px-4">
                  <span className="font-semibold text-xs text-gray-02">
                    DATE ENTRY
                  </span>
                  <div className="border rounded-lg py-2 px-4 text-sm text-gray-03 mt-1 mb-4">
                    {date ? moment(date).format("DD/MM/YYYY") : "??/??/????"}
                  </div>
                </div>
              </div>
            )}
          </DatePicker>
          <IconArrowUp
            className={
              "absolute top-1/2 transform -translate-y-1/2 right-4 z-10"
            }
            fill={"#0025B8"}
          />
        </div>
      </div>
    );
}

InputDate.defaultProps = {
  date: new Date(),
};

export { InputDateRange, InputDateRange2, InputDate };
