import React from "react";
import Spinner from "components/atoms/Spinner";
import IconSadFace from "components/atoms/Icons/Custom/icon new/SadFace";
import IconCustomerHappy from "components/atoms/Icons/Custom/icon new/CustomerHappy";
import moment from "moment";

export default function TableCustomerReview({ data }) {
  console.log(data);
  return (
    <table className="w-full mt-4">
      <thead>
        <tr>
          <th>Ulasan pelanggan</th>
          <th>Aspek</th>
        </tr>
      </thead>
      <tbody>
        {data.status === "process" ? (
          <tr key="status">
            <td colSpan="2" className="text-center">
              <div className="flex items-center justify-center">
                <Spinner size={64} color="#859DFF" />
              </div>
            </td>
          </tr>
        ) : data?.data?.length ? (
          data?.data?.map((item) => {
            return (
              <tr key={item.id}>
                <td>
                  <div className="flex items-center">
                    {item?.type ? (
                      <IconCustomerHappy className="mr-4" fill="#28C437" />
                    ) : (
                      <IconSadFace className="mr-4" fill="#DB1111" />
                    )}

                    <p className="text-3">
                      {`${item?.customer?.first_name} ${item?.customer?.last_name}`} memberikan
                      ulasan{" "}
                      {item?.type ? (
                        <span className="text-green-03">positif</span>
                      ) : (
                        <span className="text-red-03">negatif</span>
                      )}{" "}
                      untuk {item?.sales?.externalId} di {item?.outlet?.outletName} <br />
                      <span className="text-blue-03">
                        {moment(item.createdAt).format("HH:mm, DD/MM/YYYY")}
                      </span>
                    </p>
                  </div>
                </td>
                <td colSpan="2">
                  <div className="flex items-center justify-between">
                    {item?.reviewCategory?.name}
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="4" className="text-center">
              <span className="text-gray-02">Tidak ditemukan data</span>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
