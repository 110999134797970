import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import Button from "components/atoms/Button";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextCaption from "components/atoms/Text/TextCaption";
import { postData, getData } from "utils/fetchData";
import { useDispatch } from "react-redux";
import SearchBar from "components/atoms/SearchBar";
import Checkbox from "components/atoms/Checkbox";
import { setNotif, fetchCategory } from "features/Category/actions";
import PropTypes from 'prop-types'

const CategorySchema = yup.object().shape({
  name: yup.string().required("nama kategori tidak boleh kosong."),
  website: yup.string().url(),
});

export default function CategoryCreate(props) {
  const { show, close, title, type } = props
  const dispatch = useDispatch();
  const [field, setField] = React.useState({ name: "" });
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [list, setList] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [isShowProduct, setIsShowProduct] = React.useState(false);
  const { register, handleSubmit, errors, setError } = useForm({
    mode: "onBlur",
    validationSchema: CategorySchema,
  });

  // convert data product in checkbox
  const checkboxProduct = async (category = "") => {
    const res = await getData(`v2/product/notinmodule?notInID=0&notInModule=CATEGORY`);
    let products = [];
    res?.data?.products.forEach((data) => {
      products.push({
        id: data.productId,
        name: data.productName,
        isChecked: false,
      });
    });
    setProduct(products);
    setList(products);
  };

  const onChecked = (e) => {
    let products = [...product];
    products.forEach((product) => {
      if (product.id === e.target.value) product.isChecked = e.target.checked;
    });
    setProduct(products);
  };

  React.useEffect(() => {
    const countProduct = () => {
      let check = product.filter((data) => {
        return data.isChecked === true;
      });
      setCount(check.length);
    };
    countProduct();
  }, [product]);

  React.useEffect(() => {
    checkboxProduct();
  }, []);

  // const onFilterProduct = (e) => {
  //   let data = [...list];
  //   data = data.filter((product) => {
  //     return (
  //       product.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
  //     );
  //   });
  //   setProduct(data);

  //   setSearch(e.target.value);
  // };

  const onFilterProduct = React.useMemo(() => {
    if (!search) return product;

    return product.filter((product) => {
      return product.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search, product]);


  const onSubmit = async () => {
    setLoading(true);

    let payload = {
      name: field.name,
      products: [],
    };

    product.map((data) => {
      if (data.isChecked) payload.products.push(data.id);
    });

    if (type === 'product') {
      const res = await postData("v2/category", payload);
      if (res.data.code === 200) {
        setLoading(false);
        close();
        clearField();
        props.fetchCheckboxCategory()
      }
    } else {

      const res = await postData("categories", payload);
      if (res.data.code === 200) {
        checkboxProduct();
        setLoading(false);
        setIsShowProduct(false);
        close();
        clearField();
        const notif = {
          message: `Kategori "${res.data.category.name}" berhasil ditambahkan`,
          type: "primary",
          status: "success",
          id: res.data.category.id,
          toggle: true,
        };
        dispatch(setNotif(notif));
        dispatch(fetchCategory());
      }
    }
  };

  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const clearField = () => {
    setField({ name: "" });
  };

  return (
    <form onSubmit={!loading ? handleSubmit(onSubmit) : null}>
      {/* START: MODAL INPUT KATEGORI */}
      <ModalDialog
        size={"alert"}
        show={show}
        onClose={() => {
          clearField();
          close();
        }}
        title={title}
      >
        {/* START: CONTENT */}
        <div className={`bg-gray-05 p-5 h-full overflow-scroll pl-20`}>
          <InputTextLargeWithLabel
            className="w-full"
            label="Nama kategori"
            status={errors?.name ? "error" : "default"}
            register={register}
            name="name"
            onChange={onChange}
            value={field.name}
          >
            {errors.name && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.name?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
        </div>
        {/* END: CONTENT */}

        {/* START: FOOTER */}
        <div className="px-5 flex justify-end items-center bg-white">
          {type === 'product' ? <Button
            variant="primary"
            color="blue"
            className="my-5"
            type="submit"
            loading={loading ? true : false}
            disabled={field.name === "" ? true : false}
          >
            Selesai tambah kategori
          </Button> : <React.Fragment><Button
            variant="secondary"
            color="blue"
            className="my-5 mx-2"
            type="button"
            onClick={() => setIsShowProduct(true)}
            disabled={field.name === "" ? true : loading ? true : false}
          >
            Selesai dan pilih produk
          </Button>
            <Button
              variant="primary"
              color="blue"
              className="my-5"
              type="submit"
              loading={loading ? true : false}
              disabled={field.name === "" ? true : false}
            >
              Selesai tambah kategori
          </Button>
          </React.Fragment>}
          {/* END: FOOTER */}
        </div>
      </ModalDialog>
      {/* END: MODAL INPUT KATEGORI */}

      {/* START: MODAL INPUT KATEGORI */}
      <ModalDialog
        size={"alert"}
        show={isShowProduct}
        onClose={() => setIsShowProduct(false)}
        title={
          <p className="heading-3 text-dark">
            Pilih porduct ke
            <span className="text-blue-03"> {field.name}</span>
          </p>
        }
      >
        <div className={`bg-gray-05 px-6 pt-6 h-full overflow-scroll`}>
          <p className="text-2">
            Produk yang saat ini ada di kategori lain akan dipindahkan ke
            kategori ini. 0{" "}
            <span className="text-blue-03 bold-text-2">produk</span> akan
            dipindahkan ke kategori{" "}
            <span className="text-blue-03 bold-text-2">“{field.name}”</span>.
          </p>
          <SearchBar
            name="search"
            value={search}
            onChange={(e)=> setSearch(e.target.value)}
            onClick={()=> setSearch('')}
            placeholder="Cari produk"
            className="my-4"
          />
          <ul
            className="bg-white border border-gray-04 rounded-lg px-4 mb-3 overflow-y-scroll"
            style={{ height: "45vh" }}
          >
            {onFilterProduct.map((product, i) => {
              return (
                <li className="mt-6" key={i}>
                  <Checkbox
                    checked={product.isChecked}
                    onChange={(e) => onChecked(e)}
                    label={product.name}
                    value={product.id}
                    name={product.id}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        {/* START: FOOTER */}
        <div className="px-5 flex justify-end items-center bg-white">
          <p className="bold-text-2">{count} produk dipilih</p>
          <Button
            variant="primary"
            color="blue"
            className="my-5 ml-2"
            type="submit"
            loading={loading ? true : false}
          >
            Selesai pilih produk
          </Button>
          {/* END: FOOTER */}
        </div>
      </ModalDialog>
      {/* END: MODAL INPUT KATEGORI */}
    </form>
  );
}

CategoryCreate.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string
}
