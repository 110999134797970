import {
  START_FETCHING_REGION,
  SUCCESS_FETCHING_REGION,
  ERROR_FETCHING_REGION,
  SET_PROVINCE,
  SET_CITY,
} from "./constants";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";

// (1) bungkus `getLocation` dengan `debounce`
let debouncedFetchRegion = debounce(getData, 1000);

export const startFetchingRegions = () => {
  return {
    type: START_FETCHING_REGION,
  };
};

export const errorFetchingRegions = () => {
  return {
    type: ERROR_FETCHING_REGION,
  };
};

export const successFetchingRegions = ({ province }) => {
  return {
    type: SUCCESS_FETCHING_REGION,
    province,
  };
};
export const setCity = (city) => {
  return {
    type: SET_CITY,
    city,
  };
};

export const fetchRegions = () => {
  return async (dispatch) => {
    dispatch(startFetchingRegions());

    try {
      let {
        data: { provinces },
      } = await debouncedFetchRegion("v2/region/ID/province");

      let province = [];
      provinces.forEach((item) => {
        province.push({
          label: item.province,
          value: item.province_id,
        });
      });

      dispatch(successFetchingRegions({ province }));
    } catch (err) {
      dispatch(errorFetchingRegions());
    }
  };
};
export const fetchCity = (id) => {
  return async (dispatch) => {
    try {
      let {
        data: { cities },
      } = await getData(`v2/region/ID/province/${id}/city`);

      let city = [];
      cities.forEach((item) => {
        city.push({
          label: item.city_name,
          value: item.city_id,
          post_code: item.postal_code,
        });
      });

      dispatch(setCity(city));
    } catch (err) {
      console.log(err.response);
    }
  };
};
