import React, { useState, useEffect } from "react";
import { formatRupiah } from "utils/formatRupiah";
import { getData } from "utils/fetchData";
import Banner from "components/atoms/Banner";
import IconArrowRight from "components/atoms/Icons/Custom/ArrowRight";
import IconVerification from "components/atoms/Icons/Custom/icon new/Verification";
import IconBankAccount from "components/atoms/Icons/Custom/icon new/BankAccount";
import Table from "./tableBalance";
import RequestPayout from "./requestPayout";
import ModalCreate from "pages/BalanceBill/create";
import Button from "components/atoms/Button";
import "./balance.css";

import { useSelector, useDispatch } from "react-redux";
import { fetchBalance, fetchPayout } from "features/Balance/actions";
import { fetchBankInBalance } from "features/Bank/actions";

export default function PageReportBalance() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.balance);
  const bank = useSelector((state) => state.bank.select_box);

  const [isShowCreate, setIsShowCreate] = useState(false);
  const [statusVerived, setStatusVerived] = useState(0);

  const [transferDana, setTransferDana] = useState(false);

  useEffect(() => {
    dispatch(fetchPayout());
    dispatch(fetchBalance());
    dispatch(fetchBankInBalance());
  }, []);

  useEffect(() => {
    const getBrand = async () => {
      await getData(`accounts/brand`).then((res) => {
        setStatusVerived(res.data.data.verification);
      });
    };
    getBrand();
  }, []);

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {data.notif?.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={data.notif.message}
          status={data.notif.status}
          type={data.notif.type}
        />
      )}
      {/* head content */}
      <div className="grid grid-cols-2 gap-4">
        {statusVerived === 0 ? (
          <div className="col-span-1 flex bg-red-03 p-4">
            {/* icon */}
            <div className="mr-4">
              <IconVerification height="24" width="24" />
            </div>
            <div className="flex items-center text-white">
              <p className="text-2">
                Untuk menerima pembayaran dan melakukan transfer saldo ke rekening, anda harus
                melakakukan verifikasi identitas terlebih dahulu.
              </p>
              <div className="ml-4">
                <IconArrowRight fill="currentColor" width="24px" height="24px" />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {bank.length === 0 ? (
          <div className="col-span-1 flex bg-red-03 p-4" onClick={() => setIsShowCreate(true)}>
            {/* icon */}
            <div className="mr-4">
              <IconBankAccount fill="#ffffff" height="24" width="24" />
            </div>
            <div className="flex items-center text-white">
              <p className="text-2">
                Jangan lupa untuk menambahkan rekening bank anda untuk destinasi transfer saldo
                anda. Proses verifikasi rekening bank akan memakan waktu 2-3 hari kerja.
              </p>
              <div className="ml-4">
                <IconArrowRight fill="currentColor" width="24px" height="24px" />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        <h1 className="heading-3 text-dark mt-4">
          Saldo anda: <span className="text-blue-03">{`${formatRupiah(data.balance)}`}</span>
        </h1>
        <div className="flex items-end justify-between">
          <p className="text-1 text-gray-02 mt-2 w-2/3">
            Nominal tertera di atas adalah jumlah saldo anda sekarang yang dikumpul dari semua
            transaksi setelah transfer sebelumnya. Proses transfer biasanya memakan waktu 1-2 hari
            kerja.
          </p>
          <Button variant="primary" color="blue" onClick={() => setTransferDana(true)}>
            <p className="whitespace-nowrap">Transfer Dana</p>
          </Button>
        </div>
      </div>

      {/* table */}
      <Table data={data} />
      {/* modal */}
      {transferDana && (
        <RequestPayout
          close={() => setTransferDana(false)}
          show={transferDana}
          bank={bank}
          balance={data.balance}
        />
      )}

      {isShowCreate && <ModalCreate show={isShowCreate} close={() => setIsShowCreate(false)} />}
    </div>
  );
}
