import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getData } from "utils/fetchData";
import IconClose from "components/atoms/Icons/Custom/Closes";
import IconRight from "components/atoms/Icons/Custom/ArrowDown";
import IconLeft from "components/atoms/Icons/Custom/ArrowLeft";

const activateAccount = [
  {
    id: 1,
    link: "/verification",
    title: "Verifikasi identitas",
    desc:
      "Upload foto wajah dan KTP Anda agar Anda bisa menerima berbagai jenis pembayaran dan melakukan transfer.",
  },
  {
    id: 2,
    link: "/balance/bill",
    title: "Tambah rekening bank",
    desc:
      "Tambahkan rekening bank Anda agar hasil penjualan di Dalenta bisa ditransferkan ke rekening Anda.",
  },
];

const product = [
  {
    id: 1,
    link: "/product",
    title: "Tambah produk",
    desc: "Tambah satu produk/jasa yang akan ditawarkan kepada pelanggan oleh bisnis Anda.",
  },
  {
    id: 2,
    link: "/product/variant",
    title: "Tambah variasi",
    desc: "Tambahkan variasi seperti ukuran, rasa, dan warna untuk berbagai jenis produk Anda.",
  },
  {
    id: 3,
    link: "/product/category",
    title: "Tambah kategori",
    desc:
      "Tambahkan kategori dan buat daftar produk lebih rapi dan terorganisir untuk Anda dan juga pelanggan.",
  },
  {
    id: 4,
    link: "/product/discount",
    title: "Tambah diskon",
    desc: "Tambahkan diskon ke produk atau kategori untuk menarik perhatian pelanggan kamu. ",
  },
  {
    id: 5,
    link: "/product/tax",
    title: "Tambah pajak",
    desc: "Tambahkan pajak seperti GST atau service charge akan dikenakan kepada pelanggan.",
  },
];
const device = [
  {
    id: 1,
    link: "/",
    title: "Beli perangkat",
    desc:
      "Jelajahi toko online Dalenta dan beli beragam jenis perangkat sesuai dengan kebutuh bisnis Anda.",
  },
  {
    id: 2,
    link: "/device",
    title: "Daftarkan perangkat",
    desc:
      "Daftarkan perangkat yang sudah dibeli agar bisa disinkronisasikan dan digunakan bisnis Anda.",
  },
  {
    id: 3,
    link: "/device/screensaver",
    title: "Ubah tampilan perangkat",
    desc: "Ubah tampilan depan perangkat Anda sesuai dengan branding dan keinginan bisnis Anda.",
  },
];
const business = [
  {
    id: 1,
    link: "/setting/locations",
    title: "Lokasi bisnis",
    desc:
      "Tambahkan lokasi lain bisnis Anda dan lengkapi informasi lainnya seperti alamat dan jam operasional.",
  },
  {
    id: 2,
    link: "/setting/team-account-access",
    title: "Manajemen tim",
    desc:
      "Tambahkan personil tim di bisnis Anda dan berikan masing-masing akses untuk menajamen akun Sello.",
  },
  {
    id: 3,
    link: "/setting/digital-receipt",
    title: "Kustomisasi struk digital",
    desc:
      "Sesuaikan struk digital dengan keinginan bisnis Anda yang akan dikirimkan ke pelanggan Anda.",
  },
];

function Guide({ show, close }) {
  const [isShowDefault, setIsShowDefault] = useState(true);
  const [isShowActivate, setIsShowActivate] = useState(false);
  const [isShowManageProduct, setIsShowManageProduct] = useState(false);
  const [isShowDevice, setIsShowDevice] = useState(false);
  const [isShowBusiness, setIsShowBusiness] = useState(false);
  const [verivication, setVerivication] = useState(0);

  const onActivateAccount = () => {
    setIsShowActivate(true);
    setIsShowDefault(false);
    setIsShowManageProduct(false);
    setIsShowDevice(false);
    setIsShowBusiness(false);
  };
  const onManageProduct = () => {
    setIsShowManageProduct(true);
    setIsShowActivate(false);
    setIsShowDefault(false);
    setIsShowDevice(false);
    setIsShowBusiness(false);
  };
  const onDevice = () => {
    setIsShowDevice(true);
    setIsShowManageProduct(false);
    setIsShowActivate(false);
    setIsShowDefault(false);
    setIsShowBusiness(false);
  };
  const onBusiness = () => {
    setIsShowBusiness(true);
    setIsShowDevice(false);
    setIsShowManageProduct(false);
    setIsShowActivate(false);
    setIsShowDefault(false);
  };

  const onDefault = () => {
    setIsShowDefault(true);
    setIsShowActivate(false);
    setIsShowManageProduct(false);
    setIsShowDevice(false);
    setIsShowBusiness(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData(`accounts/brand`);
      console.log(res);
      setVerivication(res.data.data.verification);
    };
    fetchData();
  }, []);
  return (
    <React.Fragment>
      {show ? (
        <div className="absolute w-full h-screen inset-0 bg-black bg-opacity-50 z-10">
          {/* <p className="bg-white" onClick={close}>
            TUTUP
          </p> */}
          <div
            className="bg-white absolute w-96 h-screen z-50 duration-200"
            style={{ right: show ? "0" : "-100%" }}
          >
            {/* header */}
            <div className="py-4 px-6 relative shadow-HeavyDropShadow mb-3">
              {/* icon close */}
              {!isShowDefault ? (
                <div
                  className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-gray-05 p-2 w-max"
                  onClick={onDefault}
                >
                  <IconLeft fill={"currentColor"} />
                </div>
              ) : (
                <div
                  className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-gray-05 p-2 w-max"
                  onClick={close}
                >
                  <IconClose fill={"currentColor"} />
                </div>
              )}

              {isShowDefault && (
                <h1 className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 heading-3">
                  Panduan Sello
                </h1>
              )}
              {isShowActivate && (
                <h1 className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 heading-3">
                  Aktivasi akun
                </h1>
              )}
              {isShowManageProduct && (
                <h1 className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 heading-3">
                  Kelola produk
                </h1>
              )}
              {isShowDevice && (
                <h1 className="absolute whitespace-nowrap left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 heading-3">
                  Daftarkan perangkat
                </h1>
              )}
              {isShowBusiness && (
                <h1 className="absolute whitespace-nowrap left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 heading-3">
                  Lengkapi bisnis Anda
                </h1>
              )}
            </div>
            {/* content */}
            <ul className="mx-6 overflow-y-scroll scroll-hidden" style={{ height: "82vh" }}>
              {isShowDefault && (
                <React.Fragment>
                  <li className="shadow-LightDropShadow p-6 mt-3">
                    <div className="flex items-center justify-between">
                      <div>
                        <h2 className="heading-3 text-dark">Aktivasi akun</h2>
                        <p className="text-2 text-gray-03">
                          Verifikasi identitas dan tambahkan rekening bank supaya Anda bisa menerima
                          pembayaran dan transfer saldo.
                        </p>
                      </div>
                      <div
                        className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-green-05 p-2 w-max ml-6"
                        onClick={onActivateAccount}
                      >
                        <IconRight fill={"currentColor"} style={{ transform: "rotate(270deg)" }} />
                      </div>
                    </div>
                  </li>
                  <li className="shadow-LightDropShadow p-6 mt-3">
                    <div className="flex items-center justify-between">
                      <div>
                        <h2 className="heading-3 text-dark">Kelola produk</h2>
                        <p className="text-2 text-gray-03">
                          Mulai tambah produk, variasi, dan kategori yang akan dijual di lokasi
                          Anda. Tambahkan juga diskon dan pajak untuk produk-produk Anda.
                        </p>
                      </div>
                      <div
                        className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-green-05 p-2 w-max ml-6"
                        onClick={onManageProduct}
                      >
                        <IconRight fill={"currentColor"} style={{ transform: "rotate(270deg)" }} />
                      </div>
                    </div>
                  </li>
                  <li className="shadow-LightDropShadow p-6 mt-3">
                    <div className="flex items-center justify-between">
                      <div>
                        <h2 className="heading-3 text-dark">Daftarkan perangkat</h2>
                        <p className="text-2 text-gray-03">
                          Beli perangkat Sello dan daftarkan untuk digunakan ke bisnis dan mulai
                          menerima pembayaran.
                        </p>
                      </div>
                      <div
                        className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-green-05 p-2 w-max ml-6"
                        onClick={onDevice}
                      >
                        <IconRight fill={"currentColor"} style={{ transform: "rotate(270deg)" }} />
                      </div>
                    </div>
                  </li>
                  <li className="shadow-LightDropShadow p-6 mt-3">
                    <div className="flex items-center justify-between">
                      <div>
                        <h2 className="heading-3 text-dark">Lengkapi bisnis</h2>
                        <p className="text-2 text-gray-03">
                          Lengkapi detail dan informasi bisnis Anda seperti lokasi, struk, dan
                          personil tim Anda untuk memudahkan manajemen dan pengelolaan.
                        </p>
                      </div>
                      <div
                        className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-green-05 p-2 w-max ml-6"
                        onClick={onBusiness}
                      >
                        <IconRight fill={"currentColor"} style={{ transform: "rotate(270deg)" }} />
                      </div>
                    </div>
                  </li>
                </React.Fragment>
              )}
              {isShowActivate &&
                activateAccount.map((item) => {
                  return (
                    <li className="shadow-LightDropShadow p-6 mt-3" key={item.id}>
                      <Link to={item.id === 1 && verivication === 1 ? "/dashboard" : item.link}>
                        <div className="flex items-center justify-between">
                          <div>
                            <h2
                              className={`${
                                item.id === 1 && verivication === 1
                                  ? "heading-3 text-gray-04"
                                  : "heading-3 text-dark"
                              }`}
                            >
                              {item.title}
                            </h2>
                            <p
                              className={`${
                                item.id === 1 && verivication === 1
                                  ? "text-2 text-gray-04"
                                  : "text-2 text-gray-03"
                              }`}
                            >
                              {item.desc}
                            </p>
                          </div>
                          {item.id === 1 && verivication === 1 ? (
                            ""
                          ) : (
                            <div className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-green-05 p-2 w-max ml-6">
                              <IconRight
                                fill={"currentColor"}
                                style={{ transform: "rotate(270deg)" }}
                              />
                            </div>
                          )}
                        </div>
                      </Link>
                    </li>
                  );
                })}
              {isShowManageProduct &&
                product.map((item) => {
                  return (
                    <li className="shadow-LightDropShadow p-6 mt-3" key={item.id}>
                      <Link to={item.link}>
                        <div className="flex items-center justify-between">
                          <div>
                            <h2 className="heading-3 text-dark">{item.title}</h2>
                            <p className="text-2 text-gray-03">{item.desc}</p>
                          </div>
                          <div className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-green-05 p-2 w-max ml-6">
                            <IconRight
                              fill={"currentColor"}
                              style={{ transform: "rotate(270deg)" }}
                            />
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              {isShowDevice &&
                device.map((item) => {
                  return (
                    <li className="shadow-LightDropShadow p-6 mt-3" key={item.id}>
                      <div className="flex items-center justify-between">
                        <div>
                          <h2 className="heading-3 text-dark">{item.title}</h2>
                          <p className="text-2 text-gray-03">{item.desc}</p>
                        </div>

                        {item.title === "Beli perangkat" ? (
                          <div
                            onClick={() =>
                              (window.location.href = "https://dalenta.tech/sello/perangkat")
                            }
                            className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-green-05 p-2 w-max ml-6"
                          >
                            <IconRight
                              fill={"currentColor"}
                              style={{ transform: "rotate(270deg)" }}
                            />
                          </div>
                        ) : (
                          <Link to={item.link}>
                            <div className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-green-05 p-2 w-max ml-6">
                              <IconRight
                                fill={"currentColor"}
                                style={{ transform: "rotate(270deg)" }}
                              />
                            </div>
                          </Link>
                        )}
                      </div>
                    </li>
                  );
                })}
              {isShowBusiness &&
                business.map((item) => {
                  return (
                    <li className="shadow-LightDropShadow p-6 mt-3" key={item.id}>
                      <Link to={item.link}>
                        <div className="flex items-center justify-between">
                          <div>
                            <h2 className="heading-3 text-dark">{item.title}</h2>
                            <p className="text-2 text-gray-03">{item.desc}</p>
                          </div>
                          <div className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-green-05 p-2 w-max ml-6">
                            <IconRight
                              fill={"currentColor"}
                              style={{ transform: "rotate(270deg)" }}
                            />
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default Guide;
