import React from "react";

export default function Plus({ width, fill, height, className }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8333 6.16666H7.83334V1.16666C7.83334 0.945648 7.74554 0.733686 7.58926 0.577406C7.43298 0.421126 7.22102 0.333328 7.00001 0.333328C6.779 0.333328 6.56703 0.421126 6.41075 0.577406C6.25447 0.733686 6.16668 0.945648 6.16668 1.16666V6.16666H1.16668C0.945663 6.16666 0.733702 6.25446 0.577421 6.41074C0.421141 6.56702 0.333344 6.77898 0.333344 6.99999C0.333344 7.22101 0.421141 7.43297 0.577421 7.58925C0.733702 7.74553 0.945663 7.83333 1.16668 7.83333H6.16668V12.8333C6.16668 13.0543 6.25447 13.2663 6.41075 13.4226C6.56703 13.5789 6.779 13.6667 7.00001 13.6667C7.22102 13.6667 7.43298 13.5789 7.58926 13.4226C7.74554 13.2663 7.83334 13.0543 7.83334 12.8333V7.83333H12.8333C13.0544 7.83333 13.2663 7.74553 13.4226 7.58925C13.5789 7.43297 13.6667 7.22101 13.6667 6.99999C13.6667 6.77898 13.5789 6.56702 13.4226 6.41074C13.2663 6.25446 13.0544 6.16666 12.8333 6.16666Z"
        fill={fill}
      />
    </svg>
  );
}

Plus.defaultProps = {
  width: "13.33px",
  height: "13.33px",
  fill: "white",
};
