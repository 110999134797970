import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "assets/stylesheets/tailwind.css";
import "assets/stylesheets/scroll.css";
import "assets/stylesheets/calender/calender1.css";
import "assets/stylesheets/calender/calender2.css";
import "assets/stylesheets/button.css";
import "assets/stylesheets/input.css";
import "assets/stylesheets/colorPicker/lightBlue.css";
import "assets/stylesheets/colorPicker/lightGray.css";
import "assets/stylesheets/colorPicker/lightGreen.css";
import "assets/stylesheets/colorPicker/lightRed.css";
import "assets/stylesheets/colorPicker/lightOrange.css";
import "assets/stylesheets/colorPicker/lightPurple.css";
import "assets/stylesheets/colorPicker/lightDark.css";
import "assets/stylesheets/colorPicker/darkBlue.css";
import "assets/stylesheets/colorPicker/darkGray.css";
import "assets/stylesheets/colorPicker/darkGreen.css";
import "assets/stylesheets/colorPicker/darkRed.css";
import "assets/stylesheets/colorPicker/darkOrange.css";
import "assets/stylesheets/colorPicker/darkPurple.css";
import "assets/stylesheets/colorPicker/darkDark.css";
import "react-datepicker/dist/react-datepicker.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
