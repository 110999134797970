import * as React from 'react'
import ModalDialog from "components/molecules/Modal";
import SearchBar from "components/atoms/SearchBar";
import Button from "components/atoms/Button";
import { putData, getData } from "utils/fetchData";
import Checkbox from "components/atoms/Checkbox";
import { useDispatch } from "react-redux";
import { setNotif, fetchCategory } from "features/Category/actions";

export default function ShowProduct({show, close, category}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [field, setField] = React.useState({ name: "", products: [] });
  const [count, setCount] = React.useState(0);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    const getSingleCategory = async () => {
      const res = await getData(`v2/category/${category}`);

      const resProduct = await getData(`v2/product/notinmodule?notInID=0&notInModule=CATEGORY`);
      let products = [];


      resProduct?.data?.products.forEach((data) => {
        products.push({
          id: data.productId,
          name: data.productName,
          isChecked: false,
        });
      });
      
      if (res.data?.code === 200) {
        res.data?.category?.products.forEach(productInCategory=>{
          products.forEach((data) => {
            
            if(productInCategory.productId === data.id){
              console.log("sama >>")
              console.log(data.id)
              data.isChecked = true
            }            
          });
        })
        setField({
          name: res?.data?.category?.categoryName,
          products: products,
        });
      }
    };
    getSingleCategory();
  }, []);

  React.useEffect(() => {
    const countProduct = () => {
      let check = field.products.filter((data) => {
        return data.isChecked === true;
      });
      setCount(check.length);
    };
    countProduct();
  }, [field]);

  const onChecked = (e) => {
    let products = [...field.products];
    products.forEach((product) => {
      if (product.id === e.target.value) product.isChecked = e.target.checked;
    });
    setField({...field, products})
  };


  const onFilterProduct = React.useMemo(() => {
    if (!search) return field.products;

    return field.products.filter((product) => {
      return product.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search, field.products]);

  const onSubmit = async () => {
    setLoading(true);
    try {

      const products = []
      field.products.forEach(data=>{
        if(data.isChecked) products.push(data.id)
      })


      const res = await putData(`v2/category/pushproduct/${category}`,  {products});
      if (res.data.code === 200) {
        setLoading(false)
        close()
        setField({ name: '' })
        const notif = {
          message: `Produk didalam kategori "${res.data.category.categoryName}" berhasil diubah`,
          type: 'primary',
          status: 'success',
          id: Number(res.data.category.id),
          toggle: true
        }
        dispatch(setNotif(notif))
        dispatch(fetchCategory())
      }
    } catch (error) {
      console.log(error.response)
    }
  };



  return (
      
       <ModalDialog
       size={"alert"}
       show={show}
       onClose={close}
       title={
         <p className="heading-3 text-dark">
           Pilih porduct ke
           <span className="text-blue-03"> {field.name}</span>
         </p>
       }
     >
       <div className={`bg-gray-05 px-6 pt-6 h-full overflow-scroll`}>
         <p className="text-2">
           Produk yang saat ini ada di kategori lain akan dipindahkan ke
           kategori ini. 0{" "}
           <span className="text-blue-03 bold-text-2">produk</span> akan
           dipindahkan ke kategori{" "}
           <span className="text-blue-03 bold-text-2">“{field.name}”</span>.
         </p>
         <SearchBar
           name="search"
           value={search}
           onChange={(e)=>setSearch(e.target.value)}
           onClick={()=>setSearch("")}
           placeholder="Cari produk"
           className="my-4"
         />
         <ul
           className="bg-white border border-gray-04 rounded-lg px-4 mb-3 overflow-y-scroll"
           style={{ height: "45vh" }}
         >
           {onFilterProduct.map((product, i) => {
             return (
               <li className="mt-6" key={i}>
                 <Checkbox
                   checked={product.isChecked}
                   onChange={(e) => onChecked(e)}
                   label={product.name}
                   value={product.id}
                   name={product.id}
                 />
               </li>
             );
           })}
         </ul>
       </div>
       {/* START: FOOTER */}
       <div className="px-5 flex justify-end items-center bg-white">
         <p className="bold-text-2">{count} produk dipilih</p>
         <Button
           variant="primary"
           color="blue"
           className="my-5 ml-2"
           type="submit"
           onClick={()=>!loading ? onSubmit(): null}
           loading={loading ? true : false}
         >
           Selesai pilih produk
         </Button>
         {/* END: FOOTER */}
       </div>
     </ModalDialog>

  )
}
