import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTax, setKeyword } from "features/Tax/actions";
import Table from "./tableTax";
import Button from "components/atoms/Button";
import SearchBar from "components/atoms/SearchBar";
import EmptyPage from "components/templates/Empty";
import Banner from "components/atoms/Banner";
import TaxCraete from "./create";
import EmptyPajak from "assets/icon/undraw_receipt_ecdd 1.svg";

export default function TaxPage() {
  const dispatch = useDispatch();
  const tax = useSelector((state) => state.tax);
  const [isShowSearch, setIsShowSearch] = React.useState(false);
  const [isShowCreate, setIsShowCreate] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchTax());
  }, [dispatch, tax.keyword]);

  // ambil data error dan convert ke view
  if (tax.status === "error") return <h1>internal server error</h1>;

  if (tax.keyword === "" && tax?.data?.length === 0 && tax.status === "success")
    return (
      <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-2">
        <EmptyPage
          title="Pajak"
          text="Tambahkan pajak seperti seperti GST atau service charge untuk setiap penjualan. Pajak akan dihitung dari total semua produk di dalam sebuah transaksi."
          image={EmptyPajak}
          label="Tambah pajak baru"
          onClick={() => setIsShowCreate(true)}
        />
        {/* START: CREATE */}
        <TaxCraete
          title="Tambah pajak baru"
          show={isShowCreate}
          close={() => setIsShowCreate(false)}
        />
        {/* END: CREATE */}
      </div>
    );

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {/* START: NOTIF */}
      {tax.notif?.toggle && tax.notif.status === "danger" && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          status={tax.notif.status}
          type={tax.notif.type}
          message={tax.notif.message}
        />
      )}
      {/* END: NOTIF */}

      {/* START:: HEADER */}
      <div className="flex items-center justify-between my-2">
        {/* START: HEADER */}
        {isShowSearch ? (
          <SearchBar
            name={tax.keyword}
            value={tax.keyword}
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            onClick={() => setIsShowSearch(!isShowSearch)}
          />
        ) : (
          <Button
            variant="outlined"
            color="gray"
            content="icon infront"
            iconName="UilSearch"
            onClick={() => setIsShowSearch(!isShowSearch)}
          >
            Cari pajak
          </Button>
        )}
        <Button
          variant="primary"
          color="blue"
          className="ml-2 whitespace-nowrap"
          onClick={() => setIsShowCreate(true)}
        >
          Tambah pajak baru
        </Button>
        {/* END: HEADER */}
      </div>
      {/* END:: HEADER */}

      {/* START: TABLE */}
      <div className="overflow-scroll scroll-hidden" style={{ height: "74vh" }}>
        <Table status={tax.status} data={tax.data} />
      </div>
      {/* END: TABLE */}

      {/* START: CREATE */}
      <TaxCraete
        title="Tambah pajak baru"
        show={isShowCreate}
        close={() => setIsShowCreate(false)}
      />
      {/* END: CREATE */}
    </div>
  );
}
