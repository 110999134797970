//fetching
export const START_FETCHING_HISTORY = "features/StockHistory/START_FETCHING_HISTORY";
export const ERROR_FETCHING_HISTORY = "features/StockHistory/ERROR_FETCHING_HISTORY";
export const SUCCESS_FETCHING_HISTORY = "features/StockHistory/SUCCESS_FETCHING_HISTORY";

//filter
export const SET_DATE = "features/StockHistory/SET_DATE";
export const SET_LOCATION = "features/StockHistory/SET_LOCATION";
export const SET_CHANGE = "features/StockHistory/SET_CHANGE";
export const SET_PRODUCT = "features/StockHistory/SET_PRODUCT";
export const SET_PAGE = "features/StockHistory/SET_PAGE";
export const SET_LIMIT = "features/StockHistory/SET_LIMIT";
export const SET_TOTAL_PAGE = "features/StockHistory/SET_TOTAL_PAGE";
export const SET_MODAL = "features/StockHistory/SET_MODAL";
export const SET_ID = "features/StockHistory/SET_ID";
