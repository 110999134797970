import {
  START_FETCHING_REPORT_PAYMENT,
  SUCCESS_FETCHING_REPORT_PAYMENT,
  ERROR_FETCHING_REPORT_PAYMENT,
  SET_LOCATION,
  SET_DATE
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  payment: {},
  status: statuslist.idle,
  date: {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  },
  location: '',
  outlets: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_REPORT_PAYMENT:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_REPORT_PAYMENT:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_REPORT_PAYMENT:
      return {
        ...state,
        status: statuslist.success,
        payment: action.payment,
        outlets: action.location
      };
    case SET_LOCATION:
      return { ...state, location: action.location }

    case SET_DATE:
      return { ...state, date: action.date }
    default:
      return state;
  }
}
