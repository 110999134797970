import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import routes from "routes";
import store from "app/store";
import { Provider } from "react-redux";
import { listen } from "app/listener";

const App = () => {
  const history = window.location.pathname;
  const [routeSelected, setRouteSelected] = React.useState([]);
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [access, setAccess] = React.useState(auth?.access);

  const createAccess = () => {
    let _temp = [];
    if (access) {
      access.forEach((parrentRoute) => {
        routes.forEach((childRoute) => {
          if (parrentRoute?.status && parrentRoute?.key === childRoute?.key) {
            _temp.push(childRoute);
          }
        });
      });
      setRouteSelected(_temp);

      let _path = "";
      _temp.forEach((item) => {
        if (item.path === history) {
          _path = item.path;
        }
      });

      if (_path !== history) {
        window.location.href = "/dashboard";
      }
    } else {
      setRouteSelected(routes);
    }
  };

  React.useEffect(() => {
    if (!auth?.access) {
      setAccess(routes);
    }
    createAccess();

    listen();
  }, []);

  return (
    <Provider store={store}>
      <Router>
        {routeSelected.length ? (
          <Switch>
            {routeSelected.map((route, i) => (
              <Route {...route} key={i} />
            ))}
          </Switch>
        ) : (
          <Switch>
            {routes.map((route, i) => (
              <Route {...route} key={i} />
            ))}
          </Switch>
        )}
      </Router>
    </Provider>
  );
};
export default App;
