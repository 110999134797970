import React from 'react'

export default function TableManagement({width,fill,height,className}) {
    return (
            <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 11H12C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11ZM18 18H13V13H18V18ZM8 0H1C0.734783 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734783 0 1V8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734783 9 1 9H8C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8V1C9 0.734783 8.89464 0.48043 8.70711 0.292893C8.51957 0.105357 8.26522 0 8 0ZM7 7H2V2H7V7Z" fill={fill}/>
<path d="M1.31606 12.3006C0.473263 13.1471 0 14.2937 0 15.4891C0 16.6844 0.473263 17.831 1.31606 18.6776C1.94221 19.3057 2.73964 19.7346 3.60831 19.9105C4.47698 20.0865 5.3782 20.0017 6.1989 19.6667C7.01961 19.3317 7.72325 18.7615 8.22154 18.0276C8.71984 17.2937 8.9906 16.4288 8.99986 15.5414C9.00456 14.9488 8.89058 14.3614 8.66464 13.8137C8.4387 13.2661 8.10539 12.7693 7.68443 12.3529C7.27066 11.929 6.77728 11.5913 6.23267 11.3592C5.68807 11.127 5.10299 11.005 4.5111 11.0002C3.91922 10.9953 3.33222 11.1077 2.78389 11.3309C2.23556 11.5541 1.73671 11.8836 1.31606 12.3006ZM6.21239 17.1931C5.81677 17.5953 5.2939 17.8474 4.73319 17.9063C4.17248 17.9652 3.60874 17.8273 3.13836 17.5161C2.66798 17.2048 2.32017 16.7397 2.1544 16.2001C1.98862 15.6605 2.01517 15.08 2.22951 14.5578C2.44386 14.0356 2.83267 13.6042 3.32949 13.3374C3.82632 13.0705 4.40029 12.9847 4.95327 13.0947C5.50626 13.2047 6.00392 13.5036 6.36117 13.9404C6.71843 14.3771 6.91309 14.9245 6.91187 15.4891C6.89668 16.1344 6.62633 16.7473 6.1602 17.1931H6.21239Z" fill={fill}/>
<path d="M12.3161 1.30061C11.4733 2.14715 11 3.29374 11 4.48909C11 5.68445 11.4733 6.83104 12.3161 7.67758C12.9422 8.30565 13.7396 8.73457 14.6083 8.91054C15.477 9.0865 16.3782 9.00168 17.1989 8.6667C18.0196 8.33172 18.7232 7.76151 19.2215 7.0276C19.7198 6.29369 19.9906 5.42878 19.9999 4.54137C20.0046 3.94883 19.8906 3.36137 19.6646 2.81371C19.4387 2.26606 19.1054 1.76932 18.6844 1.35288C18.2707 0.929047 17.7773 0.591338 17.2327 0.359182C16.6881 0.127027 16.103 0.00500907 15.5111 0.000151014C14.9192 -0.00470704 14.3322 0.10769 13.7839 0.330875C13.2356 0.55406 12.7367 0.883625 12.3161 1.30061ZM17.2124 6.19311C16.8168 6.59532 16.2939 6.84743 15.7332 6.90634C15.1725 6.96525 14.6087 6.82729 14.1384 6.51606C13.668 6.20483 13.3202 5.73965 13.1544 5.20006C12.9886 4.66046 13.0152 4.07996 13.2295 3.5578C13.4439 3.03564 13.8327 2.60424 14.3295 2.33737C14.8263 2.0705 15.4003 1.98473 15.9533 2.09471C16.5063 2.2047 17.0039 2.50362 17.3612 2.94036C17.7184 3.3771 17.9131 3.92454 17.9119 4.48909C17.8967 5.13439 17.6263 5.74727 17.1602 6.19311H17.2124Z" fill={fill}/>
</svg>


    )
}

TableManagement.defaultProps ={
    width:'20px',
    height:'20px',
    fill:'#8F8F8F'
}
