import React from "react";

export default function ColorPicker() {
  return (
    <div className="pt-36">
      <div class="grid grid-cols-7 max-w-md">
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-light-blue">
              <input type="checkbox" />
              <span class="ceklist-light-blue"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-light-gray">
              <input type="checkbox" />
              <span class="ceklist-light-gray"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-light-green">
              <input type="checkbox" />
              <span class="ceklist-light-green"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-light-red">
              <input type="checkbox" />
              <span class="ceklist-light-red"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-light-orange">
              <input type="checkbox" />
              <span class="ceklist-light-orange"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-light-purple">
              <input type="checkbox" />
              <span class="ceklist-light-purple"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-light-dark">
              <input type="checkbox" />
              <span class="ceklist-light-dark"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-7 mt-20 max-w-md">
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-dark-blue">
              <input type="checkbox" />
              <span class="ceklist-dark-blue"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-dark-gray">
              <input type="checkbox" />
              <span class="ceklist-dark-gray"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-dark-green">
              <input type="checkbox" />
              <span class="ceklist-dark-green"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-dark-red">
              <input type="checkbox" />
              <span class="ceklist-dark-red"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-dark-orange">
              <input type="checkbox" />
              <span class="ceklist-dark-orange"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-dark-purple">
              <input type="checkbox" />
              <span class="ceklist-dark-purple"></span>
            </label>
          </div>
        </div>
        <div class="col-span-1">
          <div className="relative">
            <label class="wrap-checkbox-dark-dark">
              <input type="checkbox" />
              <span class="ceklist-dark-dark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
