import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMaterialOnly } from "features/Stock/actions";
import { setModalSecondary, setCheckboxData } from "features/Material/action";
import Button from "components/atoms/Button";
import IconAdd from "components/atoms/Icons/Custom/icon new/Add";
import { InputGroupSmall } from "components/atoms/Form";
import MaterialChoice from "pages/Inventory/Stock/modal/materialChoice";
import MaterialCreate from "../modal/materialCreate";

function WithMaterialOnly() {
  const dispatch = useDispatch();
  const inventory = useSelector((state) => state.inventory);
  const materials = useSelector((state) => state.materials);
  const [showCreate, setShowCreate] = React.useState(false);

  const changeHandler = (e, index) => {
    let _temp = [...inventory.withMaterialOnly];
    _temp[index].value = parseInt(e.target.value);
    dispatch(setMaterialOnly(_temp));
  };

  const dropHandler = (id) => {
    let _temp = [...inventory.withMaterialOnly];
    _temp.splice(
      _temp.findIndex((e) => e.rawMaterialId === id),
      1
    );
    let _checkbox = [...materials.materials_checkbox];
    _checkbox.forEach((item) => {
      if (item.rawMaterialId === id) {
        item.isChecked = false;
        item.value = 0;
      }
    });

    dispatch(setCheckboxData(_checkbox));
    dispatch(setMaterialOnly(_temp));
  };

  return (
    <div className="p-6 bg-gray-05 rounded-lg">
      <div className="bg-white rounded-lg">
        <div className="px-6 py-4">
          <ul>
            {inventory?.withMaterialOnly?.length ? (
              inventory?.withMaterialOnly?.map((item, i) => {
                return (
                  <li className="flex items-center justify-between mt-2" key={i}>
                    <p className="text-2 w-24">{item?.name}</p>
                    <InputGroupSmall
                      value={item?.value}
                      type="number"
                      onChange={(e) => changeHandler(e, i)}
                      status="default"
                      content="text"
                      category={item?.unit?.unit}
                    />

                    <div className="flex">
                      <Button
                        onClick={() => dropHandler(item.rawMaterialId)}
                        variant="secondary"
                        color="red"
                        type="submit"
                        className="mr-2"
                      >
                        Hapus
                      </Button>
                      {/* <Button variant="secondary" color="blue" type="submit">
                          Ubah
                        </Button> */}
                    </div>
                  </li>
                );
              })
            ) : (
              <p className="text-center text-sm text-gray-600">Belum ada bahan baku</p>
            )}
          </ul>
        </div>
      </div>
      <Button
        className="flex items-center w-full mt-4 justify-center"
        variant="primary"
        color="green"
        onClick={() => dispatch(setModalSecondary("show-material-only"))}
      >
        <IconAdd className="mr-2" />
        Tambah bahan baku
      </Button>

      <MaterialChoice
        create={() => {
          dispatch(setModalSecondary("hide-material-only"));
          setShowCreate(true);
        }}
        close={() => dispatch(setModalSecondary("hide-material-only"))}
        show={materials.modal_secondary === "show-material-only" ? true : false}
      />
      <MaterialCreate
        show={showCreate}
        close={() => {
          setShowCreate(false);
          dispatch(setModalSecondary("show-material-only"));
        }}
      />
    </div>
  );
}

export default WithMaterialOnly;
