import React, { useEffect } from "react";
import Button from "components/atoms/Button";
import Table from "./tableLocations";
import ModalCreate from "./create";
import Banner from "components/atoms/Banner";
import { useSelector, useDispatch } from "react-redux";
import { fetchLocations } from "features/Location/actions";

export default function PageSettingLocation() {
  const dispatch = useDispatch();
  const [isShowLocation, setIsShowLocation] = React.useState(false);
  const outlet = useSelector((state) => state.location);

  useEffect(() => {
    dispatch(fetchLocations());
  }, [dispatch]);

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {outlet.notif?.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={outlet.notif.message}
          status={outlet.notif.status}
          type={outlet.notif.type}
        />
      )}
      <div className="flex justify-end">
        <Button variant="primary" color="blue" onClick={() => setIsShowLocation(true)}>
          <p className="whitespace-nowrap">Tambah lokasi</p>
        </Button>
      </div>
      {/* table */}
      <Table data={outlet} />
      {/* modal */}
      {isShowLocation && (
        <ModalCreate show={isShowLocation} close={() => setIsShowLocation(false)} />
      )}
    </div>
  );
}
