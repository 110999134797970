import React from "react";
import "pages/Inventory/Material/style.css";
import Toggle from "components/atoms/Toggle";
import { getData } from "utils/fetchData";
import { useDispatch } from "react-redux";
import { setWithoutMaterialVariant } from "features/Stock/actions";

function WithoutMaterialVariant({ data }) {
  const dispatch = useDispatch();
  const [reason, setReason] = React.useState([]);
  const [operator, setOperator] = React.useState("PLUS");

  const getReason = async () => {
    await getData("v2/reason").then((res) => {
      let _temp = [];
      res.data.reasons.forEach((item) => {
        if (item.title !== "Sales") {
          _temp.push({
            label: item.title,
            value: item.id,
            operator: item.operator,
          });
        }
      });
      setReason(_temp);
    });
  };
  const onChange = (e, index) => {
    let _temp = [...data.withoutMaterialVariant];
    if (e.target.name === "changeStock") {
      if (operator === "PLUS") {
        _temp[index].changeStock = parseInt(e.target.value);
        if (_temp[index].changeStock > 0) {
          _temp[index].afterStock = _temp[index].beforeStock + parseInt(e.target.value);
        } else {
          _temp[index].afterStock = 0;
        }
      } else if (operator === "MINUS") {
        _temp[index].changeStock = parseInt(e.target.value);
        if (_temp[index].changeStock > 0) {
          _temp[index].afterStock = _temp[index].beforeStock - parseInt(e.target.value);
        } else {
          _temp[index].afterStock = 0;
        }
      } else if (operator === "RESET") {
        _temp[index].changeStock = parseInt(e.target.value);
        if (_temp[index].changeStock > 0) {
          _temp[index].afterStock = _temp[index].beforeStock - _temp[index].changeStock;
        } else {
          _temp[index].afterStock = 0;
        }
      }
    } else if (e.target.name === "afterStock") {
      _temp[index].afterStock = parseInt(e.target.value);
      if (operator === "PLUS") {
        if (_temp[index].afterStock > 0) {
          _temp[index].changeStock = _temp[index].beforeStock - parseInt(e.target.value);
        } else {
          _temp[index].changeStock = 0;
        }
      } else if (operator === "MINUS") {
        if (_temp[index].afterStock > 0) {
          _temp[index].changeStock = parseInt(e.target.value) - _temp[index].beforeStock;
        } else {
          _temp[index].changeStock = 0;
        }
      } else if (operator === "RESET") {
        if (_temp[index].afterStock > 0) {
          _temp[index].changeStock = parseInt(e.target.value) - _temp[index].beforeStock;
        } else {
          _temp[index].changeStock = 0;
        }
      } else {
        if (_temp[index].afterStock > 0) {
          _temp[index].changeStock = parseInt(e.target.value) - _temp[index].beforeStock;
        } else {
          _temp[index].changeStock = 0;
        }
      }
    } else if (e.target.name === "reasonId") {
      let _reason = [...reason];
      _reason.forEach((prev) => {
        if (parseInt(e.target.value) === prev.value) setOperator(prev.operator);
      });
      _temp[index].reasonId = parseInt(e.target.value);
      _temp[index].changeStock = 0;
      _temp[index].afterStock = 0;
    } else if (e.target.name === "limitStock") {
      _temp[index].limitStock = parseInt(e.target.value);
    } else if (e.target.name === "track-stock") {
      _temp[index].trackStock = !_temp[index].trackStock;
    } else if (e.target.name === "track-status") {
      _temp[index].trackingStatus = !_temp[index].trackingStatus;
      _temp[index].limitStock = 0;
    }

    dispatch(setWithoutMaterialVariant(_temp));
  };
  React.useEffect(() => {
    getReason();
  }, []);
  return (
    <ul>
      {data.withoutMaterialVariant?.length
        ? data.withoutMaterialVariant.map((item, index) => {
            return (
              <li key={item.outletId} className="p-6 rounded-lg bg-gray-05 flex items-start mt-6">
                <p className="bold-text-1 w-36 mr-5 whitespace-nowrap relative top-3">
                  {item.outletName}
                </p>
                <div className="flex flex-col w-full justify-between">
                  <div className="py-4 px-6 border rounded-lg bg-white w-full flex justify-between">
                    <span className="bold-text-2">Lacak & hitung stok bahan baku</span>
                    <div className="relative right-10">
                      <Toggle
                        size="lg"
                        checked={item.trackStock}
                        name="track-stock"
                        value={item.outletId}
                        onChange={(e) => onChange(e, index)}
                      />
                    </div>
                  </div>
                  {item.trackStock ? (
                    <>
                      {/* FIELD STOCK */}
                      <div className="flex items-center w-full">
                        <div className="w-4/12">
                          <p className="bold-text-2 text-gray-02 text-right mr-4">Stok</p>
                        </div>
                        <div className="w-8/12">
                          <div className="frame-children">
                            {data.idStock !== 0 ? (
                              <input
                                type="number"
                                disabled
                                value={item.beforeStock}
                                placeholder="1,000"
                                className="input-material"
                              />
                            ) : (
                              <input
                                name="afterStock"
                                type="number"
                                onChange={(e) => onChange(e, index)}
                                data-value={item.outletId}
                                value={item.afterStock}
                                placeholder="1,000"
                                className="input-material"
                              />
                            )}

                            <span className="font-small-gray"></span>
                          </div>
                        </div>
                      </div>
                      {/* FIELD REASON */}
                      <div className="flex items-center w-full">
                        <div className="w-4/12">
                          <p className="bold-text-2 text-gray-02 text-right mr-4">
                            Alasan perubahan
                          </p>
                        </div>
                        <div className="w-8/12">
                          <div className="frame-children">
                            <select
                              onChange={(e) => onChange(e, index)}
                              name="reasonId"
                              data-value={item.outletId}
                              className="w-full focus:outline-none focus:shadow-none"
                              value={item.reasonId}
                            >
                              <option>Pilih alasan</option>
                              {reason.map((reason) => {
                                return (
                                  <option value={reason.value} key={reason.value}>
                                    {reason.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center w-full">
                        <div className="w-4/12">
                          <p className="bold-text-2 text-gray-02 text-right mr-4">
                            Jumlah perubahan
                          </p>
                        </div>
                        <div className="w-8/12">
                          <div className="frame-children">
                            <input
                              name="changeStock"
                              type="number"
                              onChange={(e) => onChange(e, index)}
                              data-value={item.outletId}
                              value={item.changeStock}
                              placeholder="1,000"
                              className="input-material"
                              disabled={item.reasonId === 4 ? true : false}
                            />
                            <span className="font-small-gray"></span>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center w-full">
                        <div className="w-4/12">
                          <p className="bold-text-2 text-gray-02 text-right mr-4">
                            Jumlah stok baru
                          </p>
                        </div>
                        <div className="w-8/12">
                          <div className="frame-children">
                            <input
                              name="afterStock"
                              type="number"
                              onChange={(e) => onChange(e, index)}
                              data-value={item.outletId}
                              value={item.afterStock}
                              placeholder="1,000"
                              className="input-material"
                              disabled={item.reasonId === 0 ? true : false}
                            />
                            <span className="font-small-gray"></span>
                          </div>
                        </div>
                      </div>

                      {/* FIELD TRACKING LIMIT */}
                      <div className="frame-children">
                        <span className="font-small-gray">Peringatan stok</span>
                        <div className="mr-10">
                          <Toggle
                            size="lg"
                            checked={item.trackingStatus}
                            name="track-limit"
                            value={item.outletId}
                            onChange={(e) => onChange(e, index)}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {item.trackingStatus && item.trackStock ? (
                    <div className="flex items-center w-full">
                      <div className="w-4/12">
                        <p className="font-small-gray">Peringatan sewaktu</p>
                      </div>
                      <div className="w-8/12">
                        <div className="frame-children">
                          <input
                            type="number"
                            onChange={(e) => onChange(e, index)}
                            name="limitStock"
                            data-value={item.outletId}
                            value={item.limitStock}
                            placeholder="1,000"
                            className="input-material"
                          />
                          <span className="font-small-gray"></span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </li>
            );
          })
        : ""}
    </ul>
  );
}

export default WithoutMaterialVariant;
