import {
  START_FETCHING_STOCK,
  SUCCESS_FETCHING_STOCK,
  ERROR_FETCHING_STOCK,
  SET_KEYWORD,
  SET_PAGE,
  SET_LIMIT,
  SET_TOTAL_PAGE,
  SET_EXPAND,
  SET_MODAL_PRIMARY,
  SET_WITH_MATERIAL_VARIANT,
  SET_WITHOUT_MATERIAL_VARIANT,
  SET_WITH_MATERIAL_VARIANT_SINGLE,
  SET_MODAL_SECONDARY,
  SET_TOGGLE_MATERIAL,
  SET_TOGGLE_VARIANT,
  SET_PRODUCT_NAME,
  SET_WITH_MATERIAL_ONLY,
  SET_WITH_VARIANT_ONLY,
  SET_OUTLETS,
  SET_VARIANT_WITH_OUTLETS,
  SET_WITH_VARIANT_ONLY_SINGLE,
  SET_ID_STOCK,
  SET_DISABLED_VARIANT,
  SET_NOTIF,
  SET_DATA_TEMPORARY,
  SET_ID_VARIANT,
} from "./constants";

import { getData } from "utils/fetchData";
import { setMaterialUsed } from "features/Material/action";
import debounce from "debounce-promise";

export const setIdStock = (idStock) => {
  return {
    type: SET_ID_STOCK,
    idStock,
  };
};
export const setIdVariant = (idVariant) => {
  return {
    type: SET_ID_VARIANT,
    idVariant,
  };
};
export const setProductName = (productName) => {
  return {
    type: SET_PRODUCT_NAME,
    productName,
  };
};

export const setPage = (page = 1) => {
  return {
    type: SET_PAGE,
    page,
  };
};

export const setToggleMaterial = (withMaterial) => {
  return {
    type: SET_TOGGLE_MATERIAL,
    withMaterial,
  };
};

export const setToggleVariant = (withVariant) => {
  return {
    type: SET_TOGGLE_VARIANT,
    withVariant,
  };
};
export const setDisabledVariant = (disabledVariant) => {
  return {
    type: SET_DISABLED_VARIANT,
    disabledVariant,
  };
};

let debouncedFetchStock = debounce(getData, 1000);

export const startFetchingStock = () => {
  return {
    type: START_FETCHING_STOCK,
  };
};

export const errorFetchingStock = () => {
  return {
    type: ERROR_FETCHING_STOCK,
  };
};

export const successFetchingStock = ({ stocks }) => {
  return {
    type: SUCCESS_FETCHING_STOCK,
    stocks,
  };
};
export const setDataTemporary = (temporary) => {
  return {
    type: SET_DATA_TEMPORARY,
    temporary,
  };
};

export const fetchStock = () => {
  return async (dispatch, getState) => {
    const notif = {
      id: 0,
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      dispatch(setNotif(notif));
    }, 5000);

    dispatch(startFetchingStock());
    let keyword = getState().inventory.keyword || "";
    let page = getState().inventory.page || 1;
    let limit = getState().inventory.limit || 10;
    const params = {
      keyword,
      page,
      limit,
    };
    try {
      let {
        data: { inventories, pages },
      } = await debouncedFetchStock("v2/inventory", params);
      let totalPage = pages;
      let stocks = [];
      inventories.forEach((item) => {
        stocks.push({
          id: item.id,
          withVariant: item.withVariant,
          withMaterial: item.withMaterial,
          productName: item.productName,
          stock: item.stock,
          variants: item.variants,
          createdAt: item.createdAt,
          isExpand: false,
        });
      });
      dispatch(successFetchingStock({ stocks }));
      dispatch(setTotalPage(totalPage));
    } catch (err) {
      dispatch(errorFetchingStock());
    }
  };
};

export const setExpand = (stocks) => {
  return {
    type: SET_EXPAND,
    stocks,
  };
};
export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};
export const setLimit = (limit) => {
  return {
    type: SET_LIMIT,
    limit,
  };
};

export const setTotalPage = (totalPage) => {
  return {
    type: SET_TOTAL_PAGE,
    totalPage,
  };
};

export const setModalPrimary = (modal_primary) => {
  return {
    type: SET_MODAL_PRIMARY,
    modal_primary,
  };
};
export const setModalSecondary = (modal_secondary) => {
  return {
    type: SET_MODAL_SECONDARY,
    modal_secondary,
  };
};
export const setOutlets = (outlets) => {
  return {
    type: SET_OUTLETS,
    outlets,
  };
};

// option 1
export const setVariantOnly = (withVariantOnly) => {
  return {
    type: SET_WITH_VARIANT_ONLY,
    withVariantOnly,
  };
};
export const setVariantWithLocation = (variantWithlocation) => {
  return {
    type: SET_VARIANT_WITH_OUTLETS,
    variantWithlocation,
  };
};
export const setWithVariantSingle = (withMaterialOnlySingle) => {
  return {
    type: SET_WITH_VARIANT_ONLY_SINGLE,
    withMaterialOnlySingle,
  };
};
// option 2
export const setMaterialOnly = (withMaterialOnly) => {
  return {
    type: SET_WITH_MATERIAL_ONLY,
    withMaterialOnly,
  };
};
// option 3
export const setWithMaterialVariant = (withMaterialVariant) => {
  return {
    type: SET_WITH_MATERIAL_VARIANT,
    withMaterialVariant,
  };
};
export const setWithMaterialVariantSingle = (withMaterialVariantSingle) => {
  return {
    type: SET_WITH_MATERIAL_VARIANT_SINGLE,
    withMaterialVariantSingle,
  };
};
// option 4
export const setWithoutMaterialVariant = (withoutMaterialVariant) => {
  return {
    type: SET_WITHOUT_MATERIAL_VARIANT,
    withoutMaterialVariant,
  };
};
//1. Generate option variant & material
export const createInventory = (data) => {
  console.log(data);
  return async (dispatch) => {
    let _tempMaterialVariant = [];
    if (data.variants.length > 0) {
      //generate material variant
      data.variants.forEach((current) => {
        current.option.forEach((prev, key) => {
          if (prev.type === 1) {
            _tempMaterialVariant.push({
              sku: `${data.name.replace(/\s/g, "").toUpperCase()}${key + 1}`,
              name: `${data.name} - ${prev.name}`,
              variantOptionId: prev.id,
              isChecked: false,
              isUpdate: false,
              rawMaterial: [],
            });
          }
        });
      });
    }
    //generate variant inside each location
    let _tempVariantLocation = [];
    let _tempWithoutBoth = [];
    data.outlets.forEach((current, i) => {
      //without both
      _tempWithoutBoth.push({
        isUpdate: false,
        outletId: current.value,
        outletName: current.label,
        name: data.name,
        sku: `SKU-00${i + 1}`,
        trackStock: true,
        trackingStatus: true,
        limitStock: 0,
        beforeStock: 0,
        changeStock: 0,
        totalStock: 0,
        afterStock: 0,
        reasonId: 1,
        abstractId: null,
      });

      if (data.variants.length > 0) {
        let _check = { status: "" }; // buat check data double
        //generate variant only
        let _tempVariantOnly = [];
        //combine variant name
        let _variantsXName = [];
        let _variantsYName = [];
        //combine variant id
        let _variantsXId = [];
        let _variantsYId = [];
        data.variants.forEach((current) => {
          let id = [];
          let name = [];
          current.option.forEach((item) => {
            if (item.type === 1) {
              id.push(item.id);
              name.push(item.name);
            }
          });
          if (current.require === 1) {
            // jika type = true
            if (_check.status === "") {
              _check.status = "require";
            } else if (_check.status === "require") {
              _check.status = "require";
            } else {
              _check.status = "merge";
            }
            _variantsXName.push(name);
            _variantsXId.push(id);
            if (data.variants.length > 1) {
              _variantsYName.push(name);
              _variantsYId.push(id);
            }
          } else {
            _variantsYName.push(name);
            _variantsYId.push(id);

            if (_check.status === "") {
              _check.status = "non require";
            } else if (_check.status === "non require") {
              _check.status = "non require";
            } else {
              _check.status = "merge";
            }
          }
        });

        let loopOverName = (arr, str = "", final = []) => {
          if (arr.length > 1) {
            arr[0].forEach((v) => loopOverName(arr.slice(1), str + `${v},`, final));
          } else {
            arr[0].forEach((v) => final.push(str + v));
          }
          return final;
        };

        let loopOverId = (arr, str = "", final = []) => {
          if (arr.length > 1) {
            arr[0].forEach((v) => loopOverId(arr.slice(1), str + `${v},`, final));
          } else {
            arr[0].forEach((v) => final.push(str + v));
          }
          return final;
        };

        let _combineName;
        let _combineId;

        if (_check.status === "require") {
          _combineName = loopOverName(_variantsXName);
          _combineId = loopOverId(_variantsXId);
        } else if (_check.status === "non require") {
          _combineName = loopOverName(_variantsYName);
          _combineId = loopOverId(_variantsYId);
        } else {
          _combineName = loopOverName(_variantsYName).concat(loopOverName(_variantsXName));
          _combineId = loopOverId(_variantsYId).concat(loopOverId(_variantsXId));
        }

        _combineName.forEach((itemsName, key) => {
          _tempVariantOnly.push({
            name: `${data.name} - ${itemsName}`,
            sku: `${data.name.replace(/\s/g, "").toUpperCase()}${key + 1}`,
            trackStock: true,
            trackingStatus: true,
            limitStock: 0,
            beforeStock: 0,
            changeStock: 0,
            afterStock: 0,
            reasonId: 1,
            totalStock: 0,
            abstractId: null,
            variantOption: [_combineId[key]].join(",").split(",").map(Number),
          });
        });

        //generate variant with outlet
        _tempVariantLocation.push({
          outletId: current.value,
          outletName: current.label,
          isUpdate: false,
          variants: _tempVariantOnly,
        });

        dispatch(setVariantOnly(_tempVariantOnly));
      }
    });
    //parse data
    dispatch(setProductName(data.name));
    dispatch(setOutlets(data.outlets));
    localStorage.setItem("variant_location", JSON.stringify(_tempVariantLocation));
    dispatch(setVariantWithLocation(_tempVariantLocation));
    dispatch(setWithMaterialVariant(_tempMaterialVariant));
    dispatch(setWithoutMaterialVariant(_tempWithoutBoth));
    dispatch(setModalPrimary("show-create-stock"));
  };
};

//2. Show material choice
export const MaterialChoiceHandler = () => {
  return async (dispatch) => {
    dispatch(setModalSecondary("show-materialChoice-create"));
  };
};
//3. Show material create
export const MaterialCreateHandler = () => {
  return async (dispatch) => {
    dispatch(setModalSecondary("show-materialCreate-create"));
  };
};
//4. Show material manage
export const MaterialManageHandler = () => {
  return async (dispatch) => {
    dispatch(setModalSecondary("show-materialManage-create"));
  };
};
//4. Show material edit
export const MaterialEditHandler = (id, material, materialUsed) => {
  return async (dispatch) => {
    let _tempSingle = {
      isChecked: false,
      name: "",
      rawMaterial: [],
      sku: "",
      variantOptionId: 0,
    };
    material?.forEach((prev) => {
      if (id === prev.variantOptionId) {
        _tempSingle.variantOptionId = prev.variantOptionId;
        _tempSingle.isChecked = prev.isChecked;
        _tempSingle.name = prev.name;
        _tempSingle.rawMaterial = prev.rawMaterial;
        _tempSingle.sku = prev.sku;
      }
    });
    let _used = [];
    materialUsed?.forEach((item) => {
      _used.push({
        id: item.id,
        date: item.createdAt,
        isChecked: true,
        name: item.name,
        rawMaterialId: item.rawMaterialId,
        unit: item.unit,
        value: item.value,
      });
    });
    dispatch(setMaterialUsed(_used));
    dispatch(setWithMaterialVariantSingle(_tempSingle));
    dispatch(setModalSecondary("show-materialEdit-create"));
  };
};
//5. Show recipt manage
export const ReciptManageHandler = () => {
  return async (dispatch) => {
    dispatch(setModalSecondary("show-reciptManage-create"));
  };
};
//6. Show edit stock
export const EditStockHandler = () => {
  return async (dispatch) => {
    dispatch(setModalSecondary("show-editStockVariant-create"));
  };
};
//7. Show track stock
export const EditTrackStockHandler = () => {
  return async (dispatch) => {
    dispatch(setModalSecondary("show-editTrackStockVariant-create"));
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};
