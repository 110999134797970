import React, { useState } from "react";
import "./style.css";
import ReactPaginate from "react-paginate";
import propTypes from "prop-types";
// import {} from '@iconscout/react-unicons'

import IconRight from "components/atoms/Icons/Custom/ArrowRight";
import IconLeft from "components/atoms/Icons/Custom/ArrowRight";
import IconDown from "components/atoms/Icons/Custom/ArrowRight";

function Pagination({
  list,
  items,
  onPageChange,
  currentPage,
  onChangePerPage,
  label,
  limit,
}) {
  // set show select
  const [showSelect, setShowSelect] = useState(false);
  // change value when click
  const [perPage, setPerpage] = useState(limit);
  // function set show select
  const changestateSelect = () => {
    setShowSelect(!showSelect);
  };
  // function selected value select
  const changeValueoption = (item) => {
    setPerpage(item.value);
    onChangePerPage(item.value);
    setShowSelect(false);
  };
  return (
    <div className="wrap-pagination mt-8" id="react-paginate">
      <ReactPaginate
        previousLabel={
          <IconLeft
            width={"17px"}
            height={"17px"}
            fill={"#1F4BFF"}
            className={"transform rotate-180"}
          />
        }
        nextLabel={
          <IconRight width={"17px"} height={"17px"} fill={"#1F4BFF"} />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={items}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={currentPage - 1}
      />
      <p className="text-show mb-10">
        Menampilkan
        {/* dropdown */}
        <button
          className="relative flex items-start ml-2 mr-3 outline-none focus:outline-none"
          onClick={() => changestateSelect()}
        >
          <span>{perPage}</span>
          <IconDown
            width={"17px"}
            height={"17px"}
            fill={"#1F4BFF"}
            className={"transform relative rotate-270de- ml-1 top-1px"}
          />

          {showSelect === false ? (
            ""
          ) : (
            <ul className="list-item absolute flex flex-col left-0 top-7 border rounded">
              {list.map((item) => {
                return (
                  <li key={item.id} onClick={() => changeValueoption(item)}>
                    {item.value}
                  </li>
                );
              })}
            </ul>
          )}
        </button>
        {label}
      </p>
    </div>
  );
}

Pagination.defaultProps = {
  list: [
    { id: 1, value: 10 },
    { id: 2, value: 25 },
    { id: 3, value: 50 },
  ],
  items: 10,
  currentPage: 1,
  limit: 10,
  label: "label per halaman",
};

Pagination.propTypes = {
  list: propTypes.array,
  items: propTypes.number,
  onPageChange: propTypes.func,
  currentPage: propTypes.number,
  onChangePerPage: propTypes.func,
  label: propTypes.string,
  limit: propTypes.number,
};

export default Pagination;
