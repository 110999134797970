import * as React from "react";
import EmptyPage from "components/templates/Empty";
import ImageEmpty from "assets/icon/undraw_business_shop_qw5t 1.png";
import Logo from "components/atoms/Logo";
import "./firstlogin.css";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import Pin from "components/atoms/Pin";
import Button from "components/atoms/Button";
import ImageEmptyModal from "assets/icon/undraw_Onboarding_re_6osc 1.png";
import { postData } from "utils/fetchData";
import { useHistory } from 'react-router-dom'

export default function FirstSignIn() {
  const history = useHistory()
  const [status, setStatus] = React.useState("empty")
  const [pin, setPin] = React.useState("")
  const [isShow, setIsShow] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [field, setField] = React.useState({})

  const onChangeStatus = status => {
    setStatus(status)
  }

  React.useEffect(() => {
    const fetchData = () => {
      let { role, username } = localStorage.getItem('auth')
        ? JSON.parse(localStorage.getItem('auth')) : {};
      setField({ username: username, role: role })
    }

    fetchData()
  }, [])

  const onChange = (data) => {
    if (data.length) {
      setPin(data)
    }
  }

  const onSubmit = async () => {
    try {

      setLoading(true)
      const payload = {
        pin: pin
      }
      const res = await postData(`v2/account/setup`, payload)
      if (res.data.code === 200) {
        setLoading(false)
        setIsShow(true)
      }

    } catch (err) {
      setLoading(false)
      console.log(err.response)
    }
  }

  if (status === "empty") {
    return (
      <div>
        {/* header */}
        <div className="flex justify-center shadow-HeavyDropShadow py-6 fixed top-0 left-0 right-0 w-full bg-white">
          {/* icon */}
          <Logo type="sello" content="icon" variant="blue" />
        </div>
        {/* content */}
        <div className="h-screen px-40 pt-28 relative z-10 first-login">
          <EmptyPage
            title="Selamat datang!"
            text="Baik Anda memiliki usaha sampingan atau menjalankan bisnis besar, beberapa pertanyaan berikut ini akan membantu kami memahami cara terbaik untuk memenuhi kebutuhan Anda. Anda selalu dapat mengedit detail ini nanti, mohon dijawab sebaik mungkin."
            image={ImageEmpty}
            label="Lanjut"
            onClick={() => onChangeStatus("pin")}
          />
        </div>
      </div>
    );
  }

  if (status === "pin") {
    return (
      <div>

        <div className="flex justify-center shadow-HeavyDropShadow py-6 fixed top-0 left-0 right-0 w-full bg-white">

          <Logo type="sello" content="icon" variant="blue" />
        </div>
        <div className="bg-white pt-24 h-screen flex justify-center">
          <div className="w-728px max-w-728px">
            <h3 className="heading-3 mb-4">Buat PIN akun</h3>
            <p className="mb-4 mt-1 text-2 text-gray-03">
              PIN akan digunakan nanti untuk akses perangkat dan lainnya.
          </p>
            <div className="form-first-login">
              <InputTextLargeWithLabel
                spacing="w-32"
                className="w-full mt-3"
                label="Nama lengkap"
                name="name"
                placeholder=""
                value={field.username}
                disabled
              />
            </div>
            <div className="form-first-login">
              <InputTextLargeWithLabel
                spacing="w-32"
                className="w-full mt-3"
                label="Posisi"
                name="name"
                placeholder=""
                value={field.role === 1 ? 'Master Admin' : field.role === 2 ? 'Admin' : field.role === 3 ? 'Primary User' : 'Sub User'}
                disabled
              />
            </div>
            <div className="mt-2 form-first-login pin">
              <Pin
                focus
                color="blue"
                label="Pin Field label"
                onChange={onChange}
              />
            </div>
            <div className="flex justify-end mt-24">
              <Button
                loading={loading}
                disabled={pin.length === 4 ? false : true}
                variant="primary"
                color="blue"
                onClick={() => !loading ? onSubmit() : null}
              >
                Selesai
            </Button>
            </div>
          </div>
        </div>
        {/* modal */}
        {isShow && (
          <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center">
            <div className="bg-gray-05 rounded-lg max-w-screen-lg overflow-hidden first-login">
              <EmptyPage
                title="Kenali Sello"
                text="Sello penuh dengan fitur dan kemampuan dan fungsi yang dapat membantu bisnis Anda berjalan lebih lancar, cepat, dan mudah. Kenali Sello dengen singkat untuk mengetahui cara memakainya dengan mulus."
                image={ImageEmptyModal}
                label="Mulai introduksi singkat"
                onClick={() => history.push('/')}
              />
            </div>
          </div>
        )}
      </div>

    )
  }
}
