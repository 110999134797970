import React, { useState } from "react";
import IconArrowDown from "components/atoms/Icons/Custom/ArrowDown";
import IconArrowUp from "components/atoms/Icons/Custom/ArrowUp";

function SelectBox({ className, defaultValue, items, spacingY, onClick, anotherAction }) {
  const [showSelect, setShowSelect] = useState(false);
  return (
    <div className={`${className} relative flex items-start`}>
      <div className="flex flex-col w-full">
        <div className="relative w-full ">
          <div
            className={`text-field-small w-full item-select cursor-pointer bg-white duration-500 ${spacingY}`}
            onClick={() => setShowSelect(!showSelect)}
          >
            {defaultValue}
          </div>

          {!showSelect ? (
            <IconArrowDown
              fill={"currentColor"}
              className={"absolute top-1/2 transform -translate-y-1/2 right-5"}
            />
          ) : (
            <IconArrowUp
              fill={"currentColor"}
              className={"absolute top-1/2 transform -translate-y-1/2 right-5"}
            />
          )}
        </div>

        {showSelect ? (
          <ul className="absolute item-list top-10 z-10 left-0 right-0 shadow-HeavyDropShadow overflow-hidden bg-white rounded-lg mt-3 mb-11">
            {items.length
              ? items.map((option) => {
                  return (
                    <li
                      key={option.id}
                      value={option.value}
                      onClick={() => {
                        onClick(option.id, option.value, option.title);
                        setShowSelect(!showSelect);
                      }}
                      className="cursor-pointer hover:bg-blue-05 duration-500 p-3 text-sm"
                    >
                      {option.title}
                    </li>
                  );
                })
              : ""}
            {anotherAction}
          </ul>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default SelectBox;
