import React from "react";
import ModalDialog from "components/molecules/Modal";
import "./index.css";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import IconCustomerHappy from "components/atoms/Icons/Custom/icon new/CustomerHappy";
import IconArrowRight from "components/atoms/Icons/Custom/icon new/Arrow";

export default function CustomerActivity({ show, close }) {
  const [value, setValue] = React.useState({
    label: "Restoran nomor 1",
    value: "",
  });
  const text = [
    {
      label: "name 1",
      value: "value 2",
    },
    {
      label: "name 2",
      value: "value 2",
    },
  ];
  const onChange = (value) => {
    console.log(value);
  };
  const [list, setList] = React.useState([
    {
      id: 1,
      name: "Edit informasi pelanggan",
    },
    {
      id: 2,
      name: "Hapus pelanggan",
    },
  ]);

  const onClick = (data) => {
    let check = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === data.id) {
        check.push({
          ...list[i],
          isChecked: data.isChecked ? (data.isChecked = false) : (data.isChecked = true),
        });
      } else {
        check.push(list[i]);
      }
    }

    setList(check);
  };
  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title={
        <h1 className="text-gray-02 heading-3">
          <span className="text-blue-03">Name&nbsp;</span>/ Aktivasi pelanggan
        </h1>
      }
      textCenter={
        <p className="absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 heading-3 text-blue-03">
          state
        </p>
      }
      anotherAction={
        <Dropdown variant="outlined" color="blue" content="label only" label="Aksi lainnya">
          {list.map((data, i) => (
            <DropdownList key={i} onClick={() => onClick(data)}>
              {data.name}
            </DropdownList>
          ))}
        </Dropdown>
      }
    >
      <div className={`bg-white border-t p-5 h-full overflow-scroll flex justify-center`}>
        <div className="w-728px max-w-728px">
          <p className="text-1">
            Semua aktivitas pelanggan seperti pembelian dan ulasan akan ditampilan disini
          </p>
          <ul className="mt-8">
            <li className="flex items-center p-4 border-b cursor-pointer hover:bg-blue-05 duration-200">
              {/* icon smile */}
              <IconCustomerHappy className="mr-4" />
              <div className="flex items-center justify-between w-full">
                <p className="text-3">
                  Memberikan ulasan positif untuk INV-001 di Restoran Nomor Satu 04:00 PM,
                  02/10/2020
                </p>
                {/* icon rowright */}
                <IconArrowRight fill={"#0066CC"} width="10" height="10" />
              </div>
            </li>
            <li className="flex items-center p-4 border-b cursor-pointer hover:bg-blue-05 duration-200">
              {/* icon smile */}
              <IconCustomerHappy className="mr-4" />
              <div className="flex items-center justify-between w-full">
                <p className="text-3">
                  Memberikan ulasan positif untuk INV-001 di Restoran Nomor Satu 04:00 PM,
                  02/10/2020
                </p>
                {/* icon rowright */}
                <IconArrowRight fill={"#0066CC"} width="10" height="10" />
              </div>
            </li>
            <li className="flex items-center p-4 border-b cursor-pointer hover:bg-blue-05 duration-200">
              {/* icon smile */}
              <IconCustomerHappy className="mr-4" />
              <div className="flex items-center justify-between w-full">
                <p className="text-3">
                  Memberikan ulasan positif untuk INV-001 di Restoran Nomor Satu 04:00 PM,
                  02/10/2020
                </p>
                {/* icon rowright */}
                <IconArrowRight fill={"#0066CC"} width="10" height="10" />
              </div>
            </li>
          </ul>
        </div>
      </div>{" "}
    </ModalDialog>
  );
}
