import React from 'react'

export default function Transactions({width,fill,height,className}) {
    return (
           <svg className={className} width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.71 5.28994L15.71 1.28994C15.6168 1.1967 15.5061 1.12274 15.3843 1.07228C15.2624 1.02182 15.1319 0.99585 15 0.99585C14.7337 0.99585 14.4783 1.10164 14.29 1.28994C14.1017 1.47825 13.9959 1.73364 13.9959 1.99994C13.9959 2.26624 14.1017 2.52164 14.29 2.70994L16.59 4.99994H5.00002C4.7348 4.99994 4.48045 5.1053 4.29291 5.29283C4.10537 5.48037 4.00002 5.73472 4.00002 5.99994C4.00002 6.26516 4.10537 6.51951 4.29291 6.70705C4.48045 6.89458 4.7348 6.99994 5.00002 6.99994H19C19.1974 6.99896 19.3902 6.93955 19.5539 6.82922C19.7176 6.71889 19.845 6.56256 19.92 6.37994C19.9966 6.19783 20.0175 5.99712 19.9801 5.80313C19.9428 5.60914 19.8488 5.43057 19.71 5.28994ZM15 8.99994H1.00002C0.802592 9.00093 0.609876 9.06033 0.446156 9.17066C0.282435 9.281 0.155037 9.43732 0.0800174 9.61994C0.00343795 9.80205 -0.0174847 10.0028 0.0198894 10.1968C0.0572635 10.3907 0.15126 10.5693 0.290017 10.7099L4.29002 14.7099C4.38298 14.8037 4.49358 14.8781 4.61544 14.9288C4.7373 14.9796 4.86801 15.0057 5.00002 15.0057C5.13203 15.0057 5.26274 14.9796 5.38459 14.9288C5.50645 14.8781 5.61705 14.8037 5.71002 14.7099C5.80375 14.617 5.87814 14.5064 5.92891 14.3845C5.97968 14.2627 6.00582 14.132 6.00582 13.9999C6.00582 13.8679 5.97968 13.7372 5.92891 13.6154C5.87814 13.4935 5.80375 13.3829 5.71002 13.2899L3.41002 10.9999H15C15.2652 10.9999 15.5196 10.8946 15.7071 10.707C15.8947 10.5195 16 10.2652 16 9.99994C16 9.73472 15.8947 9.48037 15.7071 9.29284C15.5196 9.1053 15.2652 8.99994 15 8.99994Z" fill={fill}/>
</svg>


    )
}

Transactions.defaultProps ={
    width:'20px',
    height:'20px',
    fill:'#8F8F8F'
}
