import {
  START_FETCHING_MATERIAL,
  SUCCESS_FETCHING_MATERIAL,
  ERROR_FETCHING_MATERIAL,
  SET_KEYWORD,
  SET_LOCATION,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_STOCKS,
  SET_OUTLET_SELECTED,
  SET_OUTLET,
  SET_ALL_SELECTED,
  SET_REASON,
  SET_ID,
  SET_NAME,
  SET_MEASURE_ID,
  SET_MEASURE_NAME,
  SET_MEASURE_UNIT,
  SET_BANNER,
  SET_MODAL,
  SET_MESSAGE,
  SET_MODAL_SECONDARY,
  SET_MATERIAL_USED,
  SET_MATERIAL_CHECKBOX,
} from "./constant";

import { getData, postData, putData, deleteData } from "utils/fetchData";
import { setWithMaterialVariantSingle, setMaterialOnly } from "features/Stock/actions";
import debounce from "debounce-promise";

let debouncedFetchMaterial = debounce(getData, 700);

export const startFetchingMaterial = () => {
  return {
    type: START_FETCHING_MATERIAL,
  };
};

export const errorFetchingMaterial = () => {
  return {
    type: ERROR_FETCHING_MATERIAL,
  };
};

export const successFetchingMaterial = ({ materials }) => {
  return {
    type: SUCCESS_FETCHING_MATERIAL,
    materials,
  };
};

export const setCheckboxData = (materials_checkbox) => {
  return {
    type: SET_MATERIAL_CHECKBOX,
    materials_checkbox,
  };
};

export const setMaterialUsed = (material_used) => {
  return {
    type: SET_MATERIAL_USED,
    material_used,
  };
};

//fetching required data
export const fetchReason = () => {
  return async (dispatch) => {
    const res = await getData(`v2/reason`);
    let reason = res.data.reasons;
    dispatch(setReason(reason));
  };
};
export const fetchLocation = () => {
  return async (dispatch) => {
    const res = await getData(`outlets`);
    let patch_location = [];
    let patch_selected = [];
    res.data.outlets.forEach((item) => {
      patch_location.push({
        id: item.id,
        name: item.name,
        checked: true,
      });
    });
    res.data.outlets.map((data) => {
      patch_selected.push(data.id);
    });
    dispatch(setOutlet(patch_location));
    dispatch(setOutletSelected(patch_selected));
    dispatch(setLocation(patch_selected.join(",")));
  };
};
export const fetchMaterial = () => {
  return async (dispatch, getState) => {
    dispatch(startFetchingMaterial());
    let keyword = getState().materials.keyword || "";
    let location = getState().materials.location || "";
    let page = getState().materials.page || 1;
    let limit = getState().materials.limit || 10;
    const params = {
      keyword,
      page,
      limit,
      location,
    };
    try {
      let {
        data: { materials, pages },
      } = await debouncedFetchMaterial("v2/rawmaterial", params);
      let totalPage = pages;
      dispatch(successFetchingMaterial({ materials }));
      dispatch(setTotalPage(totalPage));
    } catch (err) {
      dispatch(errorFetchingMaterial());
    }
  };
};
export const fetchMaterialInStock = () => {
  return async (dispatch, getState) => {
    let material_used = getState().materials.material_used || [];
    let page = 1;
    let limit = 1000;
    const params = {
      page,
      limit,
    };
    let res = await getData("v2/rawmaterial", params);
    let _tempCheckbox = [];
    res?.data?.materials?.forEach((item) => {
      _tempCheckbox.push({
        id: null,
        rawMaterialId: item.id,
        name: item.name,
        value: 0,
        unit: item.unit,
        isChecked: false,
        date: item.createdAt,
      });
    });
    var results = _tempCheckbox.map(
      (obj) => material_used.find((o) => o.rawMaterialId === obj.rawMaterialId) || obj
    );
    dispatch(setCheckboxData(results));
  };
};

export const fetchOneMaterial = (id) => {
  return async (dispatch) => {
    await getData(`v2/rawmaterial/${id}`)
      .then((res) => {
        let stocks = [];
        res.data.material.rawMaterialAbstracts.forEach((item) => {
          stocks.push({
            outletId: item.outlet.outletId,
            outletName: item.outlet.outletName,
            trackStock: item.trackStock,
            trackingStatus: item.trackingStatus,
            limitStock: item.limitStock,
            beforeStock: item.stock,
            afterStock: 0,
            changeStock: 0,
            reasonId: 1,
          });
        });
        dispatch(setId(res.data.material.id));
        dispatch(setName(res.data.material.name));
        dispatch(setUnit(res.data.material.unitOfMeasure.id));
        dispatch(
          setMeasureName(
            `${res.data.material.unitOfMeasure.name} (${res.data.material.unitOfMeasure.unit})`
          )
        );
        dispatch(setMeasureUnit(res.data.material.unitOfMeasure.unit));
        dispatch(setStocks(stocks));
        dispatch(setModal("show-update"));
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};
export const fetchOneMaterialToStock = (id) => {
  return async (dispatch) => {
    await getData(`v2/rawmaterial/${id}`)
      .then((res) => {
        let stocks = [];
        res.data.material.rawMaterialAbstracts.forEach((item) => {
          stocks.push({
            outletId: item.outlet.outletId,
            outletName: item.outlet.outletName,
            trackStock: item.trackStock,
            trackingStatus: item.trackingStatus,
            limitStock: item.limitStock,
            beforeStock: item.stock,
            afterStock: 0,
            changeStock: 0,
            reasonId: 1,
          });
        });
        dispatch(setId(res.data.material.id));
        dispatch(setName(res.data.material.name));
        dispatch(setUnit(res.data.material.unitOfMeasure.id));
        dispatch(setStocks(stocks));
        dispatch(setModalSecondary("show-stock-table"));
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};

export const fetchOneMaterialToTrack = (id) => {
  return async (dispatch) => {
    await getData(`v2/rawmaterial/${id}`)
      .then((res) => {
        let stocks = [];
        res.data.material.rawMaterialAbstracts.forEach((item) => {
          stocks.push({
            outletId: item.outlet.outletId,
            outletName: item.outlet.outletName,
            trackStock: item.trackStock,
            trackingStatus: item.trackingStatus,
            limitStock: item.limitStock,
            beforeStock: item.stock,
            afterStock: 0,
            changeStock: 0,
            reasonId: 1,
          });
        });
        dispatch(setId(res.data.material.id));
        dispatch(setName(res.data.material.name));
        dispatch(setUnit(res.data.material.unitOfMeasure.id));
        dispatch(setStocks(stocks));
        dispatch(setModalSecondary("show-track-table"));
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};

export const setReason = (reason) => {
  return {
    type: SET_REASON,
    reason,
  };
};

//create, update, & delete data
export const createMaterial = (payload) => {
  return async (dispatch) => {
    await postData("v2/rawmaterial", payload)
      .then((res) => {
        dispatch(fetchMaterial());
        dispatch(fetchLocation());
        dispatch(setModal("hide-create"));
        let id = res.data.rawmaterial.id;
        let message = `Bahan baku ${res.data.rawmaterial.name} berhasil ditambah`;
        dispatch(setMessage(message));
        dispatch(setId(parseInt(id)));
        dispatch(setBanner("success"));

        setTimeout(() => {
          dispatch(setMessage(""));
          dispatch(setId(0));
          dispatch(setBanner(""));
        }, 4000);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};
export const updateMaterial = (id, payload) => {
  return async (dispatch) => {
    await putData(`v2/rawmaterial/${id}`, payload)
      .then((res) => {
        dispatch(fetchMaterial());
        dispatch(setModal("hide-update"));
        dispatch(setModalSecondary("hide-stock-table"));
        let id = res.data.rawmaterial.id;
        let message = `Bahan baku ${res.data.rawmaterial.name} berhasil diubah`;
        dispatch(setMessage(message));
        dispatch(setId(parseInt(id)));
        dispatch(setBanner("success"));
        setTimeout(() => {
          dispatch(setMessage(""));
          dispatch(setId(0));
          dispatch(setBanner("idle"));
        }, 4000);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};
export const dropMaterial = (id) => {
  return async (dispatch) => {
    await deleteData(`v2/rawmaterial/${id}`).then(() => {
      dispatch(setModal("hide-delete"));
      dispatch(setBanner("danger"));
      setTimeout(() => {
        dispatch(setBanner("idle"));
      }, 3000);
      dispatch(fetchMaterial());
    });
  };
};
//filter data
export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};
export const setLimit = (limit) => {
  return {
    type: SET_LIMIT,
    limit,
  };
};
export const setPage = (page = 1) => {
  return {
    type: SET_PAGE,
    page,
  };
};
export const setTotalPage = (totalPage) => {
  return {
    type: SET_TOTAL_PAGE,
    totalPage,
  };
};
export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};
export const setOutlet = (outlet) => {
  return {
    type: SET_OUTLET,
    outlet,
  };
};
export const setOutletSelected = (selectedOutlet) => {
  return {
    type: SET_OUTLET_SELECTED,
    selectedOutlet,
  };
};
export const setAllSelected = (allSelected) => {
  return {
    type: SET_ALL_SELECTED,
    allSelected,
  };
};

//modal & banner message
export const setMessage = (message) => {
  return {
    type: SET_MESSAGE,
    message,
  };
};
export const setBanner = (banner) => {
  return {
    type: SET_BANNER,
    banner,
  };
};
export const setModal = (modal) => {
  return {
    type: SET_MODAL,
    modal,
  };
};
export const setModalSecondary = (modal_secondary) => {
  return {
    type: SET_MODAL_SECONDARY,
    modal_secondary,
  };
};

//form material
export const setId = (id) => {
  return {
    type: SET_ID,
    id,
  };
};
export const setName = (name) => {
  return {
    type: SET_NAME,
    name,
  };
};
export const setUnit = (unitOfMeasureId) => {
  return {
    type: SET_MEASURE_ID,
    unitOfMeasureId,
  };
};
export const setMeasureName = (measure_name) => {
  return {
    type: SET_MEASURE_NAME,
    measure_name,
  };
};
export const setMeasureUnit = (measure_unit) => {
  return {
    type: SET_MEASURE_UNIT,
    measure_unit,
  };
};
export const setStocks = (stocks) => {
  return {
    type: SET_STOCKS,
    stocks,
  };
};

//material to checkbox action
export const selectMaterial = (items, index) => {
  return async (dispatch, getState) => {
    let checkboxData = getState().materials.materials_checkbox || [];
    let _temp = [...checkboxData];
    _temp[index]["isChecked"] = !items.isChecked;
    dispatch(setCheckboxData(_temp));
  };
};

export const submitCheckbox = (hide) => {
  return async (dispatch, getState) => {
    let inventory = getState().inventory || [];
    let modal_status = getState().materials.modal_secondary || "";
    let checkboxData = getState().materials.materials_checkbox || [];
    let idVariant = getState().inventory.idVariant || 0;

    if (modal_status === "show-material-recipt-create") {
      inventory.withMaterialVariant.forEach((prev) => {
        let check = [];
        checkboxData.forEach((data) => {
          if (data.isChecked) {
            check.push(data);
          }
        });
        if (prev.variantOptionId === idVariant) {
          prev.rawMaterial = check;
        }
      });
      dispatch(setModalSecondary(hide));
    } else if (modal_status === "show-material-global") {
      let _temp = [];
      checkboxData.forEach((current) => {
        if (current.isChecked) {
          _temp.push(current);
        }
      });
      dispatch(setMaterialUsed(_temp));
      dispatch(setModalSecondary(hide));
    } else if (modal_status === "show-material-single-create") {
      let _temp = [];
      checkboxData.forEach((current) => {
        if (current.isChecked) {
          _temp.push(current);
        }
      });
      let _tempSingle = {
        isChecked: inventory.withMaterialVariantSingle.isChecked,
        name: inventory.withMaterialVariantSingle.name,
        rawMaterial: [...inventory.withMaterialVariantSingle.rawMaterial],
        sku: inventory.withMaterialVariantSingle.sku,
        variantOptionId: inventory.withMaterialVariantSingle.variantOptionId,
      };

      inventory.withMaterialVariant.forEach((prev) => {
        if (inventory.withMaterialVariantSingle.variantOptionId === prev.variantOptionId) {
          _tempSingle.variantOptionId = prev.variantOptionId;
          _tempSingle.isChecked = prev.isChecked;
          _tempSingle.name = prev.name;
          _tempSingle.rawMaterial = _temp;
          _tempSingle.sku = prev.sku;
        }
      });

      dispatch(setWithMaterialVariantSingle(_tempSingle));
      dispatch(setModalSecondary("hide-material-single-create"));
    } else if (modal_status === "show-material-only") {
      let check = [];
      checkboxData.forEach((item) => {
        if (item.isChecked) {
          check.push(item);
        }
      });
      dispatch(setMaterialOnly(check));
      dispatch(setMaterialUsed(check));
      dispatch(setModalSecondary("hide-material-only"));
    }
  };
};
