import GuardRoute from "utils/guardRoute";
import GuardOnlyRoute from "utils/guardOnlyRoute";
import Layout from "components/templates/app";
import SignIn from "pages/Authentication";
import ForgotPassword from "pages/ForgotPassword";
import ResetForgotPassword from "pages/ForgotPassword/reset";
import FinishForgot from "pages/ForgotPassword/finish";
import RedirectForgot from "pages/ForgotPassword/redirect";
import Dashboard from "pages/Dashboard";
import Stock from "pages/Inventory/Stock";
import Material from "pages/Inventory/Material";
import Measure from "pages/Inventory/Measure";
import Variant from "pages/Variant";
import Product from "pages/Product";
import Category from "pages/Category";
import Logout from "pages/Logout";
import Tax from "pages/Tax";
import Discount from "pages/Discount";
import Customer from "pages/Customer";
import CustomerReview from "pages/CustomerReview";
import CustomerReviewCreate from "pages/CustomerReview/create";
import CustomerReviewFinish from "pages/CustomerReview/finish";
import Device from "pages/Device";
import DeviceLogo from "pages/DeviceLogo";
import DeviceScreensaver from "pages/DeviceScreensaver";
//
import CustomerGroup from "pages/CustomerGroup";
import CustomerSetting from "pages/CustomerSetting";
import Transaction from "pages/Transaction";
import Report from "pages/Report";
import ReportCategory from "pages/ReportCategory";
import ReportDiscount from "pages/ReportDiscount";
import ReportNoteActivity from "pages/ReportNoteActivity";
import ReportPayment from "pages/ReportPayment";
import ReportProduct from "pages/ReportProduct";
import ReportRefund from "pages/ReportRefund";
import ReportTax from "pages/ReportTax";
import ReportTrend from "pages/ReportTrend";
import ReportVariasi from "pages/ReportVariasi";
import Balance from "pages/Balance";
import BalanceBill from "pages/BalanceBill";
import SettingAccount from "pages/SettingAccount";
import SettingLocation from "pages/SettingLocation";
import SettingNotification from "pages/SettingNotification";
import SettingStruckDigital from "pages/SettingStruckDigital";
import SettingStruckKertas from "pages/SettingStruckKertas";
import SettingTeam from "pages/SettingTeam";
import TransferStock from "pages/Inventory/TransferStock";
import Barcode from "pages/Inventory/Barcode";
import BarcodeSucess from "pages/Inventory/Barcode/success";
import FirstSignIn from "pages/FirstSignIn";
import BuatPINAkun from "pages/FirstSignIn/buatPINakun";
import Verification from "pages/Verification";
import MoreApp from "pages/MoreApp";

/** component */
import ComponentFlag from "pages/Component/Flags";
import ComponentColorPicker from "pages/Component/ColorPicker";
import ComponentAvatar from "pages/Component/Avatar";
import ComponentTypography from "pages/Component/Typography";
import ComponentShadow from "pages/Component/Shadow";
import ComponentColor from "pages/Component/Colors";
import ComponentButton from "pages/Component/Buttons";
import ComponentBanner from "pages/Component/Banners";
import ComponentInputFieldSmall from "pages/Component/Form/InputFieldSmall";
import ComponentInputFieldLarge from "pages/Component/Form/InputFieldLarge";
import ComponentTextCaption from "pages/Component/Text/TextCaption";
import InputTextArea from "pages/Component/Form/InputTextArea";
import ComponentCalender from "pages/Component/Form/InputDate";
import ComponentCheckbox from "pages/Component/Checkboxs";
import ComponentToggle from "pages/Component/Toggles";
import ComponentRadio from "pages/Component/Radios";
import ComponentSwitch from "pages/Component/Switchs";
import ComponentSearchbar from "pages/Component/Searchbars";
import ComponentProgressBar from "pages/Component/ProgressBar";
import ComponentLogo from "pages/Component/Logo";
import ComponentPin from "pages/Component/Pin";
import ComponentTabVertical from "pages/Component/TabVertical";
import ComponentTag from "pages/Component/Tags";
import ComponentImage from "pages/Component/ImageFields";
import ComponentStepper from "pages/Component/Steppers";
import ComponentPagination from "pages/Component/Paginate";
import ComponentSpacing from "pages/Component/Spacing";
import ComponentSpinner from "pages/Component/Spinner";
import ComponentModal from "pages/Component/Modal";
import ComponentTable from "pages/Component/Tables";
import ComponentHorizontal from "pages/Component/TabHorizontal";
import ComponentTooltips from "pages/Component/Tooltips";
import ComponentDropdownMenu from "pages/Component/Dropdown";
import ComponentSelectDropdown from "pages/Component/SelectBoxs";

const DashboardPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Dashboard />
      </Layout>
    </GuardRoute>
  );
};
const DeviceScreensaverPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <DeviceScreensaver />
      </Layout>
    </GuardRoute>
  );
};
const DeviceLogoPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <DeviceLogo />
      </Layout>
    </GuardRoute>
  );
};
const TransferStockPage = () => {
  return (
    <GuardRoute>
      <TransferStock />
    </GuardRoute>
  );
};

const FirstSignInPage = () => {
  return <FirstSignIn />;
};
const VerificationPage = () => {
  return <Verification />;
};

const BarcodePage = () => {
  return (
    <GuardRoute>
      <Barcode />
    </GuardRoute>
  );
};
const BarcodeSucessPage = () => {
  return (
    <GuardRoute>
      <BarcodeSucess />
    </GuardRoute>
  );
};

const CustomerPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Customer />
      </Layout>
    </GuardRoute>
  );
};
const TransactionPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Transaction />
      </Layout>
    </GuardRoute>
  );
};
const ReportCategoryPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <ReportCategory />
      </Layout>
    </GuardRoute>
  );
};
const ReportDiscountPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <ReportDiscount />
      </Layout>
    </GuardRoute>
  );
};
const ReportNoteActivityPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <ReportNoteActivity />
      </Layout>
    </GuardRoute>
  );
};
const SettingAccountPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <SettingAccount />
      </Layout>
    </GuardRoute>
  );
};
const SettingLocationPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <SettingLocation />
      </Layout>
    </GuardRoute>
  );
};
const SettingNotificationPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <SettingNotification />
      </Layout>
    </GuardRoute>
  );
};
const SettingStruckDigitalPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <SettingStruckDigital />
      </Layout>
    </GuardRoute>
  );
};
const SettingStruckKertasPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <SettingStruckKertas />
      </Layout>
    </GuardRoute>
  );
};
const SettingTeamPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <SettingTeam />
      </Layout>
    </GuardRoute>
  );
};
const ReportPaymentPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <ReportPayment />
      </Layout>
    </GuardRoute>
  );
};
const ReportProductPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <ReportProduct />
      </Layout>
    </GuardRoute>
  );
};
const ReportRefundPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <ReportRefund />
      </Layout>
    </GuardRoute>
  );
};
const BalancePage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Balance />
      </Layout>
    </GuardRoute>
  );
};
const BalanceBillPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <BalanceBill />
      </Layout>
    </GuardRoute>
  );
};
const ReportTaxPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <ReportTax />
      </Layout>
    </GuardRoute>
  );
};
const ReportTrendPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <ReportTrend />
      </Layout>
    </GuardRoute>
  );
};
const ReportVariasiPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <ReportVariasi />
      </Layout>
    </GuardRoute>
  );
};
const CustomerReviewPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <CustomerReview />
      </Layout>
    </GuardRoute>
  );
};
const ReportPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Report />
      </Layout>
    </GuardRoute>
  );
};
const CustomerGroupPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <CustomerGroup />
      </Layout>
    </GuardRoute>
  );
};
const DevicePage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Device />
      </Layout>
    </GuardRoute>
  );
};
const CustomerSettingPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <CustomerSetting />
      </Layout>
    </GuardRoute>
  );
};
const CategoryPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Category />
      </Layout>
    </GuardRoute>
  );
};
const ProductPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Product />
      </Layout>
    </GuardRoute>
  );
};
const TaxPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Tax />
      </Layout>
    </GuardRoute>
  );
};
const DiscountPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Discount />
      </Layout>
    </GuardRoute>
  );
};
const StockPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Stock />
      </Layout>
    </GuardRoute>
  );
};
const MaterialPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Material />
      </Layout>
    </GuardRoute>
  );
};
const MeasurePage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Measure />
      </Layout>
    </GuardRoute>
  );
};
const VariantPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <Variant />
      </Layout>
    </GuardRoute>
  );
};

const ComponentButtonPage = () => {
  return (
    <Layout>
      <ComponentButton />
    </Layout>
  );
};

const ComponentBannerPage = () => {
  return (
    <Layout>
      <ComponentBanner />
    </Layout>
  );
};
const ComponentAvatarPage = () => {
  return (
    <Layout>
      <ComponentAvatar />
    </Layout>
  );
};
const ComponentColorPickerPage = () => {
  return (
    <Layout>
      <ComponentColorPicker />
    </Layout>
  );
};
const ComponentFlagPage = () => {
  return (
    <Layout>
      <ComponentFlag />
    </Layout>
  );
};

const ComponentInputFieldSmallPage = () => {
  return (
    <Layout>
      <ComponentInputFieldSmall />
    </Layout>
  );
};

const ComponentTextCaptionPage = () => {
  return (
    <Layout>
      <ComponentTextCaption />
    </Layout>
  );
};
const ComponentInputFieldLargePage = () => {
  return (
    <Layout>
      <ComponentInputFieldLarge />
    </Layout>
  );
};
const InputTextAreaPage = () => {
  return (
    <Layout>
      <InputTextArea />
    </Layout>
  );
};

const ComponentCalenderPage = () => {
  return (
    <Layout>
      <ComponentCalender />
    </Layout>
  );
};

const ComponentCheckboxPage = () => {
  return (
    <Layout>
      <ComponentCheckbox />
    </Layout>
  );
};
const ComponentTogglePage = () => {
  return (
    <Layout>
      <ComponentToggle />
    </Layout>
  );
};
const ComponentRadioPage = () => {
  return (
    <Layout>
      <ComponentRadio />
    </Layout>
  );
};
const ComponentSwitchPage = () => {
  return (
    <Layout>
      <ComponentSwitch />
    </Layout>
  );
};
const ComponentSearchbarPage = () => {
  return (
    <Layout>
      <ComponentSearchbar />
    </Layout>
  );
};
const ComponentProgressBarPage = () => {
  return (
    <Layout>
      <ComponentProgressBar />
    </Layout>
  );
};
const ComponentLogoPage = () => {
  return (
    <Layout>
      <ComponentLogo />
    </Layout>
  );
};
const ComponentPinPage = () => {
  return (
    <Layout>
      <ComponentPin />
    </Layout>
  );
};

const ComponentTabVerticalPage = () => {
  return (
    <Layout>
      <ComponentTabVertical />
    </Layout>
  );
};

const ComponentTagPage = () => {
  return (
    <Layout>
      <ComponentTag />
    </Layout>
  );
};
const ComponentImagePage = () => {
  return (
    <Layout>
      <ComponentImage />
    </Layout>
  );
};
const ComponentStepperPage = () => {
  return (
    <Layout>
      <ComponentStepper />
    </Layout>
  );
};
const ComponentPaginationPage = () => {
  return (
    <Layout>
      <ComponentPagination />
    </Layout>
  );
};
const ComponentSpacingPage = () => {
  return (
    <Layout>
      <ComponentSpacing />
    </Layout>
  );
};
const ComponentSpinnerPage = () => {
  return (
    <Layout>
      <ComponentSpinner />
    </Layout>
  );
};
const ComponentModalPage = () => {
  return (
    <Layout>
      <ComponentModal />
    </Layout>
  );
};
const ComponentTablePage = () => {
  return (
    <Layout>
      <ComponentTable />
    </Layout>
  );
};
const ComponentHorizontalPage = () => {
  return (
    <Layout>
      <ComponentHorizontal />
    </Layout>
  );
};
const ComponentTooltipsPage = () => {
  return (
    <Layout>
      <ComponentTooltips />
    </Layout>
  );
};
const ComponentDropdownMenuPage = () => {
  return (
    <Layout>
      <ComponentDropdownMenu />
    </Layout>
  );
};
const ComponentSelectDropdownPage = () => {
  return (
    <Layout>
      <ComponentSelectDropdown />
    </Layout>
  );
};
const ComponentColorPage = () => {
  return (
    <Layout>
      <ComponentColor />
    </Layout>
  );
};
const ComponentShadowPage = () => {
  return (
    <Layout>
      <ComponentShadow />
    </Layout>
  );
};
const ComponentTypographyPage = () => {
  return (
    <Layout>
      <ComponentTypography />
    </Layout>
  );
};

const SignInPage = () => {
  return (
    <GuardOnlyRoute>
      <SignIn />
    </GuardOnlyRoute>
  );
};
const FinishForgotPage = () => {
  return <FinishForgot />;
};

const MoreAppPage = () => {
  return (
    <GuardRoute>
      <Layout>
        <MoreApp />
      </Layout>
    </GuardRoute>
  );
};

const router = [
  // 1. PUBLIC ROUTE
  {
    key: "PUBLIC",
    path: "/",
    exact: true,
    component: SignInPage,
  },
  {
    key: "PUBLIC",
    path: "/first-signin/buatPINAkun",
    exact: true,
    component: BuatPINAkun,
  },
  {
    key: "PUBLIC",
    path: "/signin",
    exact: false,
    component: SignIn,
  },
  {
    key: "PUBLIC",
    path: "/forgot",
    exact: true,
    component: ForgotPassword,
  },
  {
    key: "PUBLIC",
    path: "/forgot/reset/:param",
    exact: true,
    component: ResetForgotPassword,
  },
  {
    key: "PUBLIC",
    path: "/forgot/finish",
    exact: false,
    component: FinishForgotPage,
  },
  {
    key: "PUBLIC",
    path: "/forgot/redirect",
    exact: false,
    component: RedirectForgot,
  },
  {
    key: "PUBLIC",
    path: "/logout",
    exact: false,
    component: Logout,
  },
  {
    key: "PUBLIC",
    path: "/dashboard",
    exact: false,
    component: DashboardPage,
  },
  {
    key: "PUBLIC",
    path: "/first-signin",
    exact: true,
    component: FirstSignInPage,
  },
  {
    key: "PUBLIC",
    path: "/verification",
    exact: true,
    component: VerificationPage,
  },
  {
    key: "PUBLIC",
    path: "/customer/review/create/:aspect/:id",
    exact: true,
    component: CustomerReviewCreate,
  },
  {
    key: "PUBLIC",
    path: "/customer/review/finish",
    exact: true,
    component: CustomerReviewFinish,
  },
  // 2. PRODUCT ROUTE
  {
    key: "PRODUCT",
    path: "/product",
    exact: true,
    component: ProductPage,
  },
  {
    key: "PRODUCT",
    path: "/product/category",
    exact: true,
    component: CategoryPage,
  },
  {
    key: "PRODUCT",
    path: "/product/discount",
    exact: true,
    component: DiscountPage,
  },
  {
    key: "PRODUCT",
    path: "/product/tax",
    exact: true,
    component: TaxPage,
  },
  {
    key: "PRODUCT",
    path: "/product/inventory/default",
    exact: true,
    component: StockPage,
  },
  {
    key: "PRODUCT",
    path: "/product/inventory/transfer-stock",
    exact: true,
    component: TransferStockPage,
  },
  {
    key: "PRODUCT",
    path: "/product/inventory/barcode",
    exact: true,
    component: BarcodePage,
  },
  {
    key: "PRODUCT",
    path: "/product/inventory/barcode/success",
    exact: true,
    component: BarcodeSucessPage,
  },
  {
    key: "PRODUCT",
    path: "/product/inventory/material",
    exact: true,
    component: MaterialPage,
  },
  {
    key: "PRODUCT",
    path: "/product/inventory/measure",
    exact: true,
    component: MeasurePage,
  },
  {
    key: "PRODUCT",
    path: "/product/variant",
    exact: true,
    component: VariantPage,
  },
  // 3. DEVICE ROUTE
  {
    key: "DEVICE",
    path: "/device",
    exact: true,
    component: DevicePage,
  },
  {
    key: "DEVICE",
    path: "/device/screensaver",
    exact: true,
    component: DeviceScreensaverPage,
  },
  {
    key: "DEVICE",
    path: "/device/logo",
    exact: true,
    component: DeviceLogoPage,
  },
  // 3. CUSTOMER ROUTE
  {
    key: "CUSTOMER",
    path: "/customer",
    exact: true,
    component: CustomerPage,
  },
  {
    key: "CUSTOMER",
    path: "/customer/review",
    exact: true,
    component: CustomerReviewPage,
  },
  {
    key: "CUSTOMER",
    path: "/customer/group",
    exact: true,
    component: CustomerGroupPage,
  },
  {
    key: "CUSTOMER",
    path: "/customer/setting",
    exact: true,
    component: CustomerSettingPage,
  },
  // 4. TRANSACTION ROUTE
  {
    key: "TRANSACTION",
    path: "/transaction",
    exact: true,
    component: TransactionPage,
  },
  // 5. REPORT ROUTE
  {
    key: "REPORT",
    path: "/report",
    exact: true,
    component: ReportPage,
  },
  {
    key: "REPORT",
    path: "/report/variation",
    exact: true,
    component: ReportVariasiPage,
  },
  {
    key: "REPORT",
    path: "/report/category",
    exact: true,
    component: ReportCategoryPage,
  },
  {
    key: "REPORT",
    path: "/report/discount",
    exact: true,
    component: ReportDiscountPage,
  },
  {
    key: "REPORT",
    path: "/report/noteactivity",
    exact: true,
    component: ReportNoteActivityPage,
  },
  {
    key: "REPORT",
    path: "/report/payment-method",
    exact: true,
    component: ReportPaymentPage,
  },
  {
    key: "REPORT",
    path: "/report/product",
    exact: true,
    component: ReportProductPage,
  },
  {
    key: "REPORT",
    path: "/report/refund",
    exact: true,
    component: ReportRefundPage,
  },
  {
    key: "REPORT",
    path: "/report/impost",
    exact: true,
    component: ReportTaxPage,
  },
  {
    key: "REPORT",
    path: "/report/trend",
    exact: true,
    component: ReportTrendPage,
  },
  // 6. BALANCE ROUTE
  {
    key: "BALANCE",
    path: "/balance",
    exact: true,
    component: BalancePage,
  },
  {
    key: "BALANCE",
    path: "/balance/bill",
    exact: true,
    component: BalanceBillPage,
  },
  // 7. SETTING ROUTE
  {
    key: "REPORT",
    path: "/setting",
    exact: true,
    component: SettingAccountPage,
  },
  {
    key: "REPORT",
    path: "/setting/digital-receipt",
    exact: true,
    component: SettingStruckDigitalPage,
  },
  {
    key: "REPORT",
    path: "/setting/physical-receipt",
    exact: true,
    component: SettingStruckKertasPage,
  },
  {
    key: "REPORT",
    path: "/setting/team-account-access",
    exact: true,
    component: SettingTeamPage,
  },
  {
    key: "REPORT",
    path: "/setting/locations",
    exact: true,
    component: SettingLocationPage,
  },
  {
    key: "REPORT",
    path: "/setting/email-notification",
    exact: true,
    component: SettingNotificationPage,
  },
  //=====================================================
  // *component route
  {
    path: "/component/banner",
    key: "REPORT",
    exact: false,
    component: ComponentBannerPage,
  },
  {
    path: "/component/button",
    key: "REPORT",
    exact: false,
    component: ComponentButtonPage,
  },
  {
    path: "/component/text-field-large",
    key: "REPORT",
    exact: false,
    component: ComponentInputFieldLargePage,
  },
  {
    path: "/component/text-field-small",
    key: "REPORT",
    exact: false,
    component: ComponentInputFieldSmallPage,
  },
  {
    path: "/component/text-caption",
    key: "REPORT",
    exact: false,
    component: ComponentTextCaptionPage,
  },
  {
    path: "/component/text-area",
    key: "REPORT",
    exact: false,
    component: InputTextAreaPage,
  },
  {
    path: "/component/calender",
    key: "REPORT",
    exact: false,
    component: ComponentCalenderPage,
  },
  {
    path: "/component/flag",
    key: "REPORT",
    exact: false,
    component: ComponentFlagPage,
  },
  {
    path: "/component/checkbox",
    key: "REPORT",
    exact: false,
    component: ComponentCheckboxPage,
  },
  {
    path: "/component/toggle",
    key: "REPORT",
    exact: false,
    component: ComponentTogglePage,
  },
  {
    path: "/component/radio",
    key: "REPORT",
    exact: false,
    component: ComponentRadioPage,
  },
  {
    path: "/component/switch",
    key: "REPORT",
    exact: false,
    component: ComponentSwitchPage,
  },
  {
    path: "/component/searchbar",
    key: "REPORT",
    exact: false,
    component: ComponentSearchbarPage,
  },
  {
    path: "/component/color-picker",
    key: "REPORT",
    exact: false,
    component: ComponentColorPickerPage,
  },
  {
    path: "/component/avatar",
    key: "REPORT",
    exact: false,
    component: ComponentAvatarPage,
  },
  {
    path: "/component/progress-bar",
    key: "REPORT",
    exact: false,
    component: ComponentProgressBarPage,
  },
  {
    path: "/component/logo",
    key: "REPORT",
    exact: false,
    component: ComponentLogoPage,
  },
  {
    path: "/component/pin",
    key: "REPORT",
    exact: false,
    component: ComponentPinPage,
  },
  {
    path: "/component/tab-vertical",
    key: "REPORT",
    exact: false,
    component: ComponentTabVerticalPage,
  },
  {
    path: "/component/tag",
    key: "REPORT",
    exact: false,
    component: ComponentTagPage,
  },
  {
    path: "/component/image-field",
    key: "REPORT",
    exact: false,
    component: ComponentImagePage,
  },
  {
    path: "/component/stepper",
    key: "REPORT",
    exact: false,
    component: ComponentStepperPage,
  },
  {
    path: "/component/pagination",
    key: "REPORT",
    exact: false,
    component: ComponentPaginationPage,
  },
  {
    path: "/component/spacing",
    key: "REPORT",
    exact: false,
    component: ComponentSpacingPage,
  },
  {
    path: "/component/spinner",
    key: "REPORT",
    exact: false,
    component: ComponentSpinnerPage,
  },
  {
    path: "/component/modal",
    key: "REPORT",
    exact: false,
    component: ComponentModalPage,
  },
  {
    path: "/component/table",
    key: "REPORT",
    exact: false,
    component: ComponentTablePage,
  },
  {
    path: "/component/tab-horizontal",
    key: "REPORT",
    exact: false,
    component: ComponentHorizontalPage,
  },
  {
    path: "/component/tooltip",
    key: "REPORT",
    exact: false,
    component: ComponentTooltipsPage,
  },
  {
    path: "/component/select-field-dropdown",
    key: "REPORT",
    exact: false,
    component: ComponentSelectDropdownPage,
  },
  {
    path: "/component/dropdown",
    key: "REPORT",
    exact: false,
    component: ComponentDropdownMenuPage,
  },
  {
    path: "/component/color",
    key: "REPORT",
    exact: false,
    component: ComponentColorPage,
  },
  {
    path: "/component/typography",
    key: "REPORT",
    exact: false,
    component: ComponentTypographyPage,
  },
  {
    path: "/component/shadow",
    key: "REPORT",
    exact: false,
    component: ComponentShadowPage,
  },
  {
    path: "/more",
    exact: false,
    component: MoreAppPage,
  },
];

export default router;
