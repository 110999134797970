import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import Pin from "components/atoms/Pin";

export default function UpdatePin({
  show,
  close,
  pin,
  loading,
  onChange,
  updatePin,
}) {
  return (
    <ModalDialog
      size={"alert"}
      show={show}
      onClose={close}
      title={<h1 className="text-gray-02 heading-3">Edit PIN</h1>}
    >
      <div
        className={`bg-white p-5 h-full overflow-scroll scroll-hidden`}
      >
        <p className="text-center mb-4">Masukan pin dibawah</p>
        <div className="flex justify-center">
          <Pin
            initialValue={pin}
            length={4}
            focus
            color="blue"
            onChange={onChange}
          />
        </div>
      </div>
      <div className="px-5 flex justify-end items-center bg-white border-t">
        <Button
          onClick={close}
          variant="primary"
          color="red"
          className="my-5 mr-4"
          type="button"
        >
          Batal
        </Button>
        <Button
          loading={loading}
          onClick={updatePin}
          variant="primary"
          color="blue"
          className="my-5"
          type="button"
        >
          Selesai buat PIN
        </Button>
      </div>
    </ModalDialog>
  );
}
