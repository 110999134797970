import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function Dropdown({ type, options, onOptionSelect, value }) {
  // Keep track of whether the dropdown is open or not.
  const [isActive, setActive] = React.useState(false);

  const buttonClasses = `inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-blue-500 active:text-gray-200 transition ease-in-out duration-150`;

  return (
    <>
      {type === "header" ? (
        <div className="relative">
          <button
            onClick={() => setActive(!isActive)}
            className="text-blue-hedgehog p-2 font-semibold focus:outline-none whitespace-nowrap"
          >
            {/* 1. check type dropdown bila header makan ambil state dari redux bila buka type header tampilin default value */}
            {type === "header" ? value : "Ethos Dining Bar"}
          </button>
          <div
            className={`origin-top-right right-0 absolute mt-6 w-56 rounded-md shadow-lg ${
              isActive ? "block" : "hidden"
            }`}
          >
            <div className="rounded-md bg-white shadow-xs">
              <div className="py-1">
                <Link
                  to="/setting"
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                >
                  Pengaturan Akun
                </Link>
                {/* <Link
                  to="/"
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                >
                  Jelajahi Dalenta
                </Link> */}
                <Link
                  to="/logout"
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                >
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <button onClick={() => setActive(!isActive)} className={buttonClasses}>
            {/* 1. check type dropdown bila header makan ambil state dari redux bila buka type header tampilin default value */}
            {type === "header" ? "user login" : value}
          </button>

          <div
            className={`origin-top-right absolute mt-2 w-56 rounded-md shadow-lg ${
              isActive ? "block" : "hidden"
            }`}
          >
            {options.map((option) => (
              <div
                key={option}
                className="rounded-md bg-white shadow-xs block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                onClick={(e) => onOptionSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

Dropdown.defaultProps = {
  type: "input",
};

Dropdown.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
};
