import {
  START_FETCHING_PRODUCT,
  SUCCESS_FETCHING_PRODUCT,
  ERROR_FETCHING_PRODUCT,
  SUCCESS_FETCHING_LOCATION,
  SUCCESS_FETCHING_CATEGORY,
  SUCCESS_FETCHING_VARIANT,
  SET_FILTER_LIST_VARIANT,
  SET_VARIANT,
  SET_KEYWORD,
  SET_NOTIF,
  SET_PAGE,
  SET_LIMIT,
  SET_CATEGORY,
  SET_LOCATION
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  pages: 0,
  page: 1,
  limit: 10,
  keyword: '',
  category: [],
  outlets: [],
  variant: [],
  listVariant: [], //digunakan untuk filter
  status: statuslist.idle,
  notif: {
    id: '',
    message: '',
    type: '',
    status: '',
    toggle: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case START_FETCHING_PRODUCT:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_PRODUCT:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_PRODUCT:
      return { ...state, status: statuslist.success, data: action.product, pages: action.pages }

    case SUCCESS_FETCHING_LOCATION:
      return { ...state, outlets: action.outlets }

    case SUCCESS_FETCHING_CATEGORY:
      return { ...state, category: action.category }

    case SUCCESS_FETCHING_VARIANT:
      return { ...state, variant: action.variant, listVariant: action.variant }

    case SET_CATEGORY:
      return { ...state, category: action.category }

    case SET_LOCATION:
      return { ...state, outlets: action.location }

    case SET_VARIANT:
      return { ...state, variant: action.variant }

    case SET_FILTER_LIST_VARIANT:
      let data = { ...state }
      const search = action.search.toLowerCase()
      data = data.listVariant.filter(function (variant) {
        return (
          variant.label.toLowerCase().indexOf(search) !== -1
        )
      })
      return { ...state, variant: data }

    case SET_KEYWORD:
      return { ...state, keyword: action.keyword, page: 1 }

    case SET_PAGE:
      return { ...state, page: action.page }

    case SET_NOTIF:
      return { ...state, notif: action.notif }

    case SET_LIMIT:
      return { ...state, limit: action.limit, page: 1 }

    default:
      return state;
  }
}
