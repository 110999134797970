import React from "react";
import { useSelector } from "react-redux";
// import { setWithVariantSingle } from "features/Stock/actions";
import EditStockVariantSingle from "../modal/editStockVariantSingle";

function WithVariantOnly() {
  // const dispatch = useDispatch();
  const inventory = useSelector((state) => state.inventory);

  const [showStockVariantSingle, setShowStockVariantSingle] = React.useState(false);
  // const [singleData, setSingleData] = React.useState([]);

  const handleSingleStock = (items) => {
    let payload = {
      name: items.name,
      sku: items.sku,
      outlets: [],
    };

    // console.log("items");
    // console.log(items);
    inventory.outlets.forEach((outlet) => {
      payload.outlets.push({
        outletId: outlet.value,
        outletName: outlet.label,
        name: items.name,
        sku: items.sku,
        trackStock: items.trackStock,
        trackingStatus: items.trackingStatus,
        limitStock: items.limitStock,
        beforeStock: items.beforeStock,
        afterStock: items.afterStock,
        reasonId: items.reasonId,
        variantOption: items.variantOption,
      });
    });
    // setSingleData(payload);
    // dispatch(setWithVariantSingle(payload));
    // setShowStockVariantSingle(true);
  };

  return (
    <>
      <table className="mt-5 w-full">
        <thead>
          <tr>
            <th className="bg-gray-200 border-t border-b text-left border-gray-300">
              Produk ({inventory.withVariantOnly.length} variasi)
            </th>
            <th className="bg-gray-200 border-t border-b text-left border-gray-300">SKU</th>
            <th className="bg-gray-200 border-t border-b text-left border-gray-300">Jumlah stok</th>
          </tr>
        </thead>
        <tbody>
          {inventory.withVariantOnly?.length
            ? inventory.withVariantOnly.map((current, index) => {
                return (
                  <tr key={index} onClick={() => handleSingleStock(current)}>
                    <td>{current?.name}</td>
                    <td>{current.sku}</td>
                    <td>{current.totalStock}</td>
                  </tr>
                );
              })
            : ""}
        </tbody>
      </table>
      <EditStockVariantSingle
        show={showStockVariantSingle}
        close={() => setShowStockVariantSingle(false)}
      />
    </>
  );
}
export default WithVariantOnly;
