import React from "react";
import ModalDialog from "components/molecules/Modal";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import Button from "components/atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchMeasure, setNotif } from "features/Measure/action";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextCaption from "components/atoms/Text/TextCaption";
import { putData, getData } from "utils/fetchData";

const MeasureSchema = yup.object().shape({
  name: yup.string()
    .required("nama satuan ukur tidak boleh kosong.")
    .test('len', 'Panjang nama satuan ukur minimal 3 karakter', val => val.length > 2),
  unit: yup.string().required("singkatan satuan ukur tidak boleh kosong."),
});

function MeasureCreate({ show, close, title, measure }) {
  const dispatch = useDispatch();

  const [field, setField] = React.useState({ name: "", unit: "" });
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, errors, setError } = useForm({
    mode: "onBlur",
    validationSchema: MeasureSchema,
  });

  React.useEffect(() => {
    const getSingleMeasure = async () => {
      const res = await getData(`v2/unitofmeasure/${measure}`);
      if (res.data?.code === 200) {
        setField({
          ...field,
          name: res?.data?.unit?.name,
          unit: res?.data?.unit?.unit,
        });
      }
    };
    getSingleMeasure();
  }, []);

  const onSubmit = async (payload) => {
    setLoading(true);
    try {
      const res = await putData(`v2/unitofmeasure/${measure}`, payload);
      if (res.data.code === 200) {
        setLoading(false);
        close();
        clearField()
        const notif = {
          message: `Satuan ukur "${res.data.unit.name}" berhasil diubah`,
          type: "primary",
          status: "success",
          id: Number(res.data.unit.id),
          toggle: true,
        };
        dispatch(setNotif(notif));
        dispatch(fetchMeasure());

      }
    } catch (error) {
      setLoading(true);
      console.log(error.response);
    }
  };

  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const clearField = () => {
    setField({ name: "", product: [] });
  };

  return (
    <div>
      <ModalDialog
        size={"alert"}
        show={show}
        onClose={close}
        title={title}
      >
        <form onSubmit={!loading ? handleSubmit(onSubmit) : null}>
          <div className={`bg-gray-05 p-5 h-full overflow-scroll pl-20`}>
            <InputTextLargeWithLabel
              onChange={(e) => onChange(e)}
              value={field.name}
              className="w-full"
              label="Nama satuan ukur"
              name="name"
              status={errors?.name ? "error" : "default"}
              register={register}
            >
              {errors.name && <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.name?.message} />
              </div>}
            </InputTextLargeWithLabel>
            <InputTextLargeWithLabel
              onChange={(e) => onChange(e)}
              value={field.unit}
              className="w-full mt-3"
              label="Singkatan"
              name="unit"
              status={errors?.unit ? "error" : "default"}
              register={register}
            >
              {errors.unit && <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.unit?.message} />
              </div>}
            </InputTextLargeWithLabel>
          </div>

          <div className="px-5 flex justify-end items-center bg-white">
            <Button
              disabled={field.name !== "" && field.unit !== "" ? false : true}
              loading={loading ? true : false}
              variant="primary"
              color="blue"
              className="my-5"
              type="submit"
            >
              Selesai edit satuan ukur
            </Button>
          </div>
        </form>
      </ModalDialog>
    </div>
  );
}

export default MeasureCreate;
