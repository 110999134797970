import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import { useDispatch } from "react-redux";
import { deleteData } from "utils/fetchData";
import { fetchGroup, setNotif } from "features/CustomerGroup/actions";

export default function DeleteGroup({ show, close, id }) {
  const dispatch = useDispatch();
  const dropGroup = async () => {
    await deleteData(`v2/group/${id}`).then((res) => {
      let notif = {
        message: res.data.message,
        type: "primary",
        status: "danger",
        toggle: true,
      };
      dispatch(setNotif(notif));
      dispatch(fetchGroup());
      close();
    });
  };
  return (
    <ModalDialog size={"alert"} show={show} onClose={close} title="Hapus">
      <div className={`bg-white p-5 h-full overflow-scroll`}>
        <p>
          {`Apakah Anda yakin ingin menghapus group ? Aksi ini tidak bisa dibatalkan lagi jika group telah berhasil dihapus.`}
        </p>
      </div>
      <div className="px-5 flex justify-between items-center bg-white">
        <Button onClick={close} variant="primary" color="gray" className="my-4 mr-4" type="button">
          Batal hapus group rekekning
        </Button>
        <Button onClick={dropGroup} variant="primary" color="red" className="my-4" type="button">
          Hapus group
        </Button>
      </div>
    </ModalDialog>
  );
}
