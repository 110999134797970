import * as React from "react";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import { InputDateRange } from "components/atoms/Form/InputDate/index";
import Button from "components/atoms/Button";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchReportProduct,
  setLocation,
  setDate,
} from "features/ReportProduct/actions";
import { formatRupiah } from "utils/formatRupiah";
import "pages/Dashboard/style.css";
import Spinner from "components/atoms/Spinner";
import "pages/Dashboard/style.css";
import Graphic from "components/molecules/Charts";

export default function PageReportProduct() {
  const dispatch = useDispatch();
  const report = useSelector((state) => state.reportProduct);

  const onChangeDateCostum = (start, end) => {
    const ranges = {
      startDate: start,
      endDate: end,
      key: "selection",
    };
    dispatch(setDate(ranges));
  };

  React.useEffect(() => {
    dispatch(fetchReportProduct());
  }, [dispatch, report.location, report.date]);

  if (report.status === "process") {
    return (
      <div className="flex items-center justify-center mt-56">
        <Spinner size={64} color="#859DFF" />
      </div>
    );
  }

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <InputDateRange
            onChangeDateCostum={(start, end) => onChangeDateCostum(start, end)}
            variant="range"
            position="items-start"
            type="custom right"
            onChange={(ranges) => dispatch(setDate(ranges))}
            name="date"
            value={report.date}
          />

          <div className="ml-4">
            <Dropdown
              variant="outlined"
              color="gray"
              className="ml-2"
              content="label only"
              label={report.location?.label}
            >
              {report?.outlets.map((outlet) => {
                return (
                  <React.Fragment key={outlet.value}>
                    <DropdownList
                      color="blue"
                      type="button"
                      onClick={() => dispatch(setLocation(outlet))}
                    >
                      {outlet.label}
                    </DropdownList>
                  </React.Fragment>
                );
              })}
            </Dropdown>
          </div>
        </div>
        {/* <Button variant="outlined" color="blue">
          Export laporan
        </Button> */}
      </div>
      <h1 className="bold-text-1 my-6">
        5 produk teratas dengan penjualan terbanyak
      </h1>
      <div className="flex items-center flex-wrap">
        {report.table.map((v, i) => (
          <div className="flex items-center min-w-min mr-4">
            <div
              className={`w-4 h-4 rounded-full ${
                i === 0
                  ? "bg-blue-03 mr-2"
                  : i === 1
                  ? "bg-green-03"
                  : i === 2
                  ? "bg-red-03"
                  : i === 3
                  ? "bg-orange-03"
                  : "bg-purple-03"
              }`}
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            ></div>
            <p className="whitespace-nowrap text-2 text-gray-03">
              {v.productName}: {formatRupiah(v.total)}
            </p>
          </div>
        ))}
      </div>
      <div className="overflow-x-scroll overflow-y-hidden spacing-graph">
        <div className="App relative">
          {report?.graphic?.data && (
            <Graphic
              type={report?.graphic?.type}
              width={2500}
              height={330}
              data={report?.graphic?.data}
              multi={true}
            />
          )}
        </div>
      </div>
      {/* table */}
      <div className="mt-6 mb-6">
        <table className="w-full mt-4">
          <thead>
            <tr>
              <th>Produk</th>
              <th>Terjual</th>
              <th>Penjualan</th>
              <th>Diskon</th>
              <th>Refund</th>
              <th>Pajak</th>
            </tr>
          </thead>
          <tbody>
            {report.table.map((v, i) => (
              <tr>
                <td>{v.productName}</td>
                <td>{v.count}</td>
                <td>{formatRupiah(v.total)}</td>
                <td>{formatRupiah(v.discount)}</td>
                <td>{formatRupiah(v.refund)}</td>
                <td>{formatRupiah(v.tax)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr style={{ backgroundColor: "#0066CC", color: "white" }}>
              <td>Total</td>
              <td>{`${report.table.reduce(
                (a, b) => a + b.count,
                0
              )} terjual`}</td>
              <td>{`${formatRupiah(
                report.table.reduce((a, b) => a + b.total, 0)
              )} `}</td>
              <td>{`${formatRupiah(
                report.table.reduce((a, b) => a + b.discount, 0)
              )} `}</td>
              <td>{`${formatRupiah(
                report.table.reduce((a, b) => a + b.refund, 0)
              )} `}</td>
              <td>{`${formatRupiah(
                report.table.reduce((a, b) => a + b.tax, 0)
              )} `}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
