import React from 'react'
import PropsType from "prop-types";

export const size = {
  14: "14px",
  17: "17px",
};

export default function Search({ width, className, fill, height }) {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4733 12.5267L11 10.0733C11.9601 8.87627 12.425 7.35686 12.2992 5.82753C12.1734 4.2982 11.4664 2.87519 10.3237 1.85109C9.18091 0.827001 7.6892 0.279671 6.15528 0.321644C4.62136 0.363618 3.16181 0.991705 2.07676 2.07676C0.991705 3.16181 0.363618 4.62136 0.321644 6.15528C0.279671 7.6892 0.827001 9.18091 1.85109 10.3237C2.87519 11.4664 4.2982 12.1734 5.82753 12.2992C7.35686 12.425 8.87627 11.9601 10.0733 11L12.5267 13.4533C12.5886 13.5158 12.6624 13.5654 12.7436 13.5993C12.8249 13.6331 12.912 13.6505 13 13.6505C13.088 13.6505 13.1751 13.6331 13.2564 13.5993C13.3376 13.5654 13.4114 13.5158 13.4733 13.4533C13.5935 13.329 13.6607 13.1629 13.6607 12.99C13.6607 12.8171 13.5935 12.651 13.4733 12.5267ZM6.33333 11C5.41035 11 4.5081 10.7263 3.74067 10.2135C2.97324 9.70074 2.3751 8.97191 2.02189 8.11919C1.66868 7.26647 1.57627 6.32815 1.75633 5.42291C1.9364 4.51766 2.38085 3.68614 3.0335 3.0335C3.68614 2.38085 4.51766 1.9364 5.42291 1.75633C6.32815 1.57627 7.26647 1.66868 8.11919 2.02189C8.97191 2.3751 9.70074 2.97324 10.2135 3.74067C10.7263 4.5081 11 5.41035 11 6.33333C11 7.57101 10.5083 8.75799 9.63316 9.63316C8.75799 10.5083 7.57101 11 6.33333 11Z" fill={fill} />
    </svg>

  )
}

Search.defaultProps = {
  width: '14px',
  width: '14px',
  fill: 'gray'
}
