import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import Toggle from "components/atoms/Toggle";

function TrackUpdate({ show, close, data, handleChange, handleSubmit }) {
  return (
    <ModalDialog
      size={"default"}
      show={show}
      onClose={() => {
        close();
      }}
      title="Edit pelacakan bahan baku"
    >
      <div className="p-3 bg-white">
        <table className="w-full">
          <thead>
            <tr>
              <th>Lokasi stok</th>
              <th className="text-center">Lacak & hitung stok</th>
              <th className="text-center">Peringatan stok</th>
              <th className="text-right">Peringatan sewaktu</th>
            </tr>
          </thead>
          <tbody className="font-12 bg-white">
            {data.stocks.length > 0 ? (
              data.stocks.map((item) => {
                return (
                  <tr key={item.outletId}>
                    <td>{item.outletName}</td>
                    <td className="pb-9 text-center">
                      <Toggle
                        size="lg"
                        checked={item.trackStock}
                        name="track-stock"
                        value={item.outletId}
                        onChange={handleChange}
                        className="self-center"
                      />
                    </td>
                    <td className="pb-9 text-center">
                      <Toggle
                        size="lg"
                        checked={item.trackingStatus}
                        name="track-limit"
                        value={item.outletId}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        onChange={handleChange}
                        name="limitStock"
                        data-value={item.outletId}
                        value={item.limitStock}
                        className="input-material"
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan="6" className="p-0">
                <div className="d-flex justify-content-center w-100 py-5">
                  <span>Data not found</span>
                </div>
              </td>
            )}
          </tbody>
        </table>
      </div>
      <div className="px-5 flex justify-between items-center bg-white ">
        <Button
          onClick={() => {
            close();
          }}
          variant="secondary"
          color="blue"
          className="my-5 mx-2"
          type="button"
        >
          Buang perubahan
        </Button>
        <Button
          onClick={handleSubmit}
          variant="primary"
          color="blue"
          className="my-5"
          type="submit"
        >
          Selesai edit pelacakan bahan baku
        </Button>
      </div>
    </ModalDialog>
  );
}

export default TrackUpdate;
