import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategory, setKeyword } from "features/Category/actions";
import Table from "./tableCategory";
import Button from "components/atoms/Button";
import SearchBar from "components/atoms/SearchBar";
import EmptyPage from "components/templates/Empty";
import Banner from "components/atoms/Banner";
import CategoryCraete from "./create";
import EmptyCategory from "assets/icon/undraw_dropdown_menu_vv9j 1.svg";

export default function CategoryPage() {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const [isShowSearch, setIsShowSearch] = React.useState(false);
  const [isShowCreate, setIsShowCreate] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch, category.keyword]);

  // ambil data error dan convert ke view
  if (category.status === "error") return <h1>internal server error</h1>;

  if (
    category.keyword === "" &&
    category.data.length === 0 &&
    category.status === "success"
  )
    return (
      <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-2">
        <EmptyPage
          title="Kategori"
          text="Kategori bisa membuat daftar produk Anda lebih rapi dan terorganisir dan bisa membuat proses pemesanan untuk pelanggan jadi lebih mudah karena produk telah diklasifikasikan."
          image={EmptyCategory}
          label="Tambah kategori baru"
          onClick={() => setIsShowCreate(true)}
        />
        {/* START: CREATE */}
        <CategoryCraete
          title="Tambah kategori baru"
          show={isShowCreate}
          close={() => setIsShowCreate(false)}
        />
        {/* END: CREATE */}
      </div>
    );

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {/* START: NOTIF */}
      {category.notif?.toggle && category.notif.status === "danger" && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          status={category.notif.status}
          type={category.notif.type}
          message={category.notif.message}
        />
      )}
      {/* END: NOTIF */}

      {/* START:: HEADER */}
      <div className="flex items-center justify-between my-2">
        {/* START: HEADER */}
        {isShowSearch ? (
          <SearchBar
            name={category.keyword}
            value={category.keyword}
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            onClick={() => setIsShowSearch(!isShowSearch)}
          />
        ) : (
          <Button
            variant="outlined"
            color="gray"
            content="icon infront"
            iconName="UilSearch"
            onClick={() => setIsShowSearch(!isShowSearch)}
          >
            Cari Kategori
          </Button>
        )}
        <Button
          variant="primary"
          color="blue"
          className="ml-2 whitespace-nowrap"
          onClick={() => setIsShowCreate(true)}
        >
          Tambah kategori baru
        </Button>
        {/* END: HEADER */}
      </div>
      {/* END:: HEADER */}

      {/* START: TABLE */}
      <div className="overflow-scroll scroll-hidden" style={{ height: "74vh" }}>
        <Table status={category.status} data={category.data} />
      </div>
      {/* END: TABLE */}

      {/* START: CREATE */}
      <CategoryCraete
        title="Tambah kategori baru"
        show={isShowCreate}
        close={() => setIsShowCreate(false)}
      />
      {/* END: CREATE */}
    </div>
  );
}
