import {
  START_FETCHING_PAYMENT,
  SUCCESS_FETCHING_PAYMENT,
  ERROR_FETCHING_PAYMENT,
} from "./constants";

import { getData } from 'utils/fetchData'

export const startFetchingPayments = () => {
  return {
    type: START_FETCHING_PAYMENT,
  };
};

export const errorFetchingPayments = () => {
  return {
    type: ERROR_FETCHING_PAYMENT,
  };
};

export const successFetchingPayments = ({ data }) => {
  return {
    type: SUCCESS_FETCHING_PAYMENT,
    data
  };
};

export const fetchPayments = () => {
  return async (dispatch) => {
    dispatch(startFetchingPayments());

    try {
      let res = await getData('payment');

      let check = [{ value: '', label: 'Semua metode pembayaran' }]
      res.data.data.forEach(data => {
        check.push({ label: data.name, value: data.id })
      });

      dispatch(successFetchingPayments({ data: check }));
    } catch (err) {
      dispatch(errorFetchingPayments());
    }
  };
};
