import React, { useState, useEffect, useMemo } from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import Checkbox from "components/atoms/Checkbox";
import SearchBar from "components/atoms/SearchBar";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import TextCaption from "components/atoms/Text/TextCaption";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getData, putData } from "utils/fetchData";
import { setCheckboxData, setCustomerSelected } from "features/Customer/actions";

import { fetchGroup, setNotif } from "features/CustomerGroup/actions";
import { fetchLog, setNotifLog } from "features/ReportLog/actions";

const GroupSchema = yup.object().shape({
  name: yup.string().required("nama kelompok tidak boleh kosong."),
  website: yup.string().url(),
});

export default function UpdateGroup({ show, close, id }) {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);

  const { register, errors } = useForm({
    mode: "onBlur",
    validationSchema: GroupSchema,
  });

  const [field, setField] = useState({
    name: "",
    customers: [],
  });

  const [isShowSearch, setIsShowSearch] = React.useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    if (e.target.name === "customers") {
      let checkbox_data = [...customer.checkbox_data];
      let selected = [];
      checkbox_data.forEach((prev) => {
        if (prev.id === e.target.value) {
          prev.isChecked = e.target.checked;
        }
      });
      checkbox_data.forEach((current) => {
        if (current.isChecked && current.id) {
          selected.push(current.id);
        }
      });
      dispatch(setCustomerSelected(selected));
      dispatch(setCheckboxData(checkbox_data));
      setField({ ...field, [e.target.name]: selected });

      if (checkbox_data.length === selected.length) {
        setCheckAll(true);
      } else {
        setCheckAll(false);
      }
    } else {
      setField({ ...field, [e.target.name]: e.target.value });
    }
  };

  const selectAll = (e) => {
    setCheckAll(e.target.checked);

    let checkbox_data = [...customer.checkbox_data];
    if (e.target.checked) {
      checkbox_data.forEach((prev) => {
        prev.isChecked = true;
      });
    } else {
      checkbox_data.forEach((prev) => {
        prev.isChecked = false;
      });
    }
    let _custId = [];
    let selected = [];
    checkbox_data.forEach((current) => {
      if (current.isChecked && current.id) {
        _custId.push(current.id);
        selected.push({
          id: current.id,
          name: `${current.first_name} ${current.last_name}`,
          isChecked: true,
        });
      }
    });
    setField({ ...field, customers: _custId });
    dispatch(setCustomerSelected(selected));
    dispatch(setCheckboxData(checkbox_data));
  };
  const clearField = () => {
    let results = [...customer.checkbox_data];
    results.forEach((item) => {
      item.isChecked = false;
    });
    dispatch(setCheckboxData(results));
    close();
  };

  const onSubmit = async () => {
    setLoading(true);
    await putData(`v2/group/${id}`, field)
      .then((res) => {
        if (res.data.code === 200) {
          let notif = {
            message: res.data.message,
            type: "primary",
            status: "success",
            toggle: true,
          };
          dispatch(setNotif(notif));
          dispatch(setNotifLog(notif));
          setField({ name: "", customers: [] });
          dispatch(fetchGroup());
          dispatch(fetchLog());
          setLoading(false);
          clearField();
          close();
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  };

  //search customer
  const searchCustomer = (e) => {
    setFilter(e.target.value);
  };
  const customerOnfilter = useMemo(() => {
    if (!filter) return customer.checkbox_data;

    return customer.checkbox_data.filter((customer) => {
      return customer.name.toLowerCase().includes(filter.toLowerCase());
    });
  }, [filter, customer.checkbox_data]);

  useEffect(() => {
    const getOneGroup = async () => {
      await getData(`v2/group/${id}`).then((res) => {
        let _custId = [];
        if (res.data.code === 200) {
          console.log("field");
          console.log(res);
          setField({ name: res.data.customerGroup.name, customers: _custId });
        }
        let selected = [];
        res.data.customerGroup.customers.forEach((item) => {
          _custId.push(item.id);
          selected.push({
            id: item.id,
            name: `${item.first_name} ${item.last_name}`,
            isChecked: true,
          });
        });
        var results = customer.checkbox_data.map(
          (obj) => selected.find((o) => o.id === obj.id) || obj
        );
        dispatch(setCustomerSelected(selected));
        dispatch(setCheckboxData(results));
      });
    };
    getOneGroup();
  }, [dispatch]);

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={clearField}
      title={<h1 className="text-gray-02 heading-3">Edit kelompok</h1>}
      textCenter={
        <p className="absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 heading-3 text-blue-03">
          {field.name}
        </p>
      }
      anotherAction={
        <Button
          disabled={field.name.length ? false : true}
          className="ml-4"
          variant="primary"
          color="blue"
          type="submit"
          onClick={onSubmit}
          loading={loading}
        >
          Selesai edit kelompok
        </Button>
      }
    >
      <div
        className={`bg-white border-t p-5 h-full overflow-scroll flex items-start justify-center`}
      >
        <div className="w-728px max-w-728px">
          <h1 className="heading-3 text-gray-02">Informasi kelompok</h1>

          <ul className="mt-4 create-customer">
            <li className="flex w-full border-gray-04 py-2">
              <InputTextLargeWithLabel
                className="w-full"
                label="Nama kelompok"
                placeholder="Cth:teman,keluarga,bisnis, dsb ..."
                register={register}
                status={errors?.name ? "error" : "default"}
                value={field.name}
                onChange={onChange}
                name="name"
              >
                {errors.name && (
                  <div className="flex items-center mt-1">
                    <TextCaption status="error" message={errors.name?.message} />
                  </div>
                )}
              </InputTextLargeWithLabel>
            </li>
          </ul>

          <div className="flex items-center justify-between mt-4 px-4">
            {isShowSearch ? (
              <SearchBar
                onChange={searchCustomer}
                value={filter}
                onClick={() => setIsShowSearch(!isShowSearch)}
              />
            ) : (
              <div className="flex items-center ml-10 relative">
                <Checkbox
                  type="default"
                  className="absolute -left-10 top-2"
                  checked={checkAll}
                  size="md"
                  onChange={selectAll}
                />

                <Button
                  className="text-1"
                  variant="outlined"
                  color="gray"
                  content="icon infront"
                  iconName="UilSearch"
                  onClick={() => setIsShowSearch(!isShowSearch)}
                >
                  Cari pelanggan untuk ditambahkan
                </Button>
              </div>
            )}
            <p className="text-blue-03 whitespace-nowrap ml-4">
              {customer.selected.length} pelanggan telah ditambahkan
            </p>
          </div>
          {/* end search */}
          <p className="text-gray-02 mt-6">
            Pelanggan yang sudah di dalam kelompok lain (kecuali kelompok otomatis) akan dipindahkan
            ke dalam kategori ini jika dipilih
          </p>
          {/* start list */}
          <ul className="border mt-4">
            {customerOnfilter?.length
              ? customerOnfilter?.map((item) => {
                  return (
                    <li key={item.id} className="flex px-4 pb-3 pt-2 border-b mt-2">
                      <Checkbox
                        type="default"
                        checked={item.isChecked}
                        name="customers"
                        value={item.id}
                        size="md"
                        onChange={onChange}
                      />
                      <p className="text-blue-03 pl-10">{item.name}</p>
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
        {/* end list */}
      </div>{" "}
    </ModalDialog>
  );
}
