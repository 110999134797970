import * as React from "react";
import IconUser from "components/atoms/Icons/Custom/icon new/User";
import IconProduct from "components/atoms/Icons/Custom/icon new/Product";
import IconDevices from "components/atoms/Icons/Custom/icon new/Devices";
import IconCustomer from "components/atoms/Icons/Custom/icon new/Customers";
import IconMoney from "components/atoms/Icons/Custom/icon new/Money";
import IconInvoice from "components/atoms/Icons/Custom/icon new/Invoice";
import IconArrow from "components/atoms/Icons/Custom/icon new/Arrow";
import Button from "components/atoms/Button";
import Spinner from "components/atoms/Spinner";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashboard,
  setLocation,
  setDate,
} from "features/Dashboard/actions";
import { formatRupiah } from "utils/formatRupiah";
import { Line, Doughnut } from "react-chartjs-2";
import { InputDateRange } from "components/atoms/Form/InputDate/index";
import { getData } from "utils/fetchData";
import { userLogout } from "features/Auth/actions";
import "./style.css";

function Dashboard() {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard);
  const color = [
    "bg-blue-03",
    "bg-green-03",
    "bg-red-03",
    "bg-orange-03",
    "bg-purple-03",
  ];

  //location state
  const [locations, setLocations] = React.useState([]);
  const [checkAllLocation, setCheckAllLocation] = React.useState({
    id: "all",
    name: "Semua lokasi",
    isChecked: true,
  });
  const [locationSelected, setLocationSelected] = React.useState([]);

  const filterLocation = (data) => {
    setLocations(
      locations.map((item) => {
        if (item.id === data.id) {
          item.isChecked = !item.isChecked;
        }
        return item;
      })
    );
    let patch_selected = [];
    locations.map((prev) => {
      if (prev.id && prev.isChecked) {
        patch_selected.push(prev.id);
      }
    });
    setLocationSelected(patch_selected);
    dispatch(setLocation(patch_selected.join(",")));
    if (locations.length === patch_selected.length) {
      setCheckAllLocation({
        id: "all",
        name: "Semua lokasi",
        isChecked: true,
      });
    } else {
      setCheckAllLocation({
        id: "all",
        name: "Semua lokasi",
        isChecked: false,
      });
    }
  };

  const filterAllLocation = () => {
    setLocations(
      locations.map((item) => {
        if (checkAllLocation.isChecked) {
          item.isChecked = false;
        } else {
          item.isChecked = true;
        }
        return item;
      })
    );
    setCheckAllLocation({
      id: "all",
      name: "Semua lokasi",
      isChecked: !checkAllLocation.isChecked,
    });
    let patch_selected = [];
    locations.map((prev) => {
      if (prev.id && prev.isChecked) {
        patch_selected.push(prev.id);
      }
    });
    setLocationSelected(patch_selected);
    dispatch(setLocation(patch_selected.join(",")));
  };

  React.useEffect(() => {
    dispatch(fetchDashboard());
  }, [dispatch, dashboard.location, dashboard.date]);

  const getLocation = async () => {
    let patch_location = [];
    let patch_selection = [];
    let res = await getData("outlets");
    if (res.data?.code === 401) {
      return dispatch(userLogout());
    } else {
      res.data.outlets.forEach((location) => {
        patch_location.push({
          id: location.id,
          name: location.name,
          isChecked: true,
        });
        patch_selection.push(location.id);
      });
      setLocations(patch_location);
      setLocationSelected(patch_selection);
    }
  };

  React.useEffect(() => {
    getLocation();
  }, []);

  const onChangeDateCustom = (start, end) => {
    const ranges = {
      startDate: start,
      endDate: end,
      key: "selection",
    };
    dispatch(setDate(ranges));
  };

  // FORMAT LINE CHART
  const optionLine = {
    layout: {
      padding: {
        top: 50,
      },
    },
    tooltips: {
      enabled: true,
      custom: function (tooltip) {
        if (!tooltip) return;
        // disable displaying the color box;
        tooltip.displayColors = false;
      },
      yAlign: "bottom",
      callbacks: {
        label: function (tooltipItem, data) {
          var indice = tooltipItem.index;
          let label = data.datasets[0].count[indice];
          let value = formatRupiah(data.datasets[0].data[indice]);
          return [value, label + " Transaksi"];
        },
      },
    },
    legend: {
      display: false,
    },
    bezierCurve: false,
    responsive: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return formatRupiah(value);
            },
          },
        },
      ],
    },
  };

  const dataLine = {
    maintainAspectRatio: false,
    responsive: false,
    labels: dashboard?.chart?.label,
    datasets: [
      {
        data: dashboard?.chart?.data,
        count: dashboard?.chart?.count,
        borderColor: "rgb(31, 75, 255)",
        backgroundColor: ["rgba(31, 75, 255, 0.3)"],
        lineTension: 0,
      },
    ],
  };

  const optionDoughnut = {
    tooltips: {
      enabled: true,
    },
    legend: {
      display: false,
    },
  };

  const dataDoughnut = {
    maintainAspectRatio: false,
    responsive: false,
    labels: dashboard?.chartPayment?.label,
    datasets: [
      {
        label: `Grafik ringkasan`,
        data: dashboard?.chartPayment?.data,
        backgroundColor: dashboard?.chartPayment?.color,
      },
    ],
  };

  return (
    <div className="pt-24 pb-10 px-20 h-screen overflow-scroll">
      {/* username and filter */}
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-1 shadow-LightDropShadow bg-white rounded-lg">
          <div className="p-8 flex items-center">
            <IconUser className="mr-6" width="66" height="66" />
            <div>
              <h3 className="heading-2 text-blue-02">
                {dashboard?.data?.account?.username}
              </h3>
              <p className="text-1 text-gray-02">
                {dashboard?.data?.account?.role === 3
                  ? "Pemilik bisnis"
                  : "Karyawan"}
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-1 shadow-LightDropShadow bg-white rounded-lg">
          <div className="p-8">
            <h3 className="heading-3 text-dark">Filter ringkasan dashboard:</h3>
            <div className="flex items-center mt-4">
              <InputDateRange
                onChangeDateCostum={(start, end) =>
                  onChangeDateCustom(start, end)
                }
                variant="range"
                type="custom right"
                onChange={(ranges) => dispatch(setDate(ranges))}
                name="date"
                value={dashboard.date}
              />

              <Dropdown
                label={
                  checkAllLocation.isChecked || locationSelected.length === 0
                    ? "Semua lokasi"
                    : `${locationSelected.length} lokasi`
                }
                variant="primary"
                className="ml-2"
                color="blue"
                content="label only"
              >
                <DropdownList
                  onClick={filterAllLocation}
                  checked={checkAllLocation.isChecked}
                  list="checkbox"
                >
                  Semua lokasi
                </DropdownList>
                {locations.map((data, i) => (
                  <DropdownList
                    key={i}
                    onClick={() => filterLocation(data)}
                    checked={data.isChecked}
                    list="checkbox"
                  >
                    {data.name}
                  </DropdownList>
                ))}
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-LightDropShadow rounded-lg py-6 px-7 w-full mt-6">
        <div className="flex justify-between h-42">
          <h2 className=" font-semibold">Grafik performa penjualan</h2>
          <Button
            variant="secondary"
            color="blue"
            className="h-10"
            content="icon behind"
            iconName="UilEye"
            type="link"
            href="/report"
          >
            Pelajari lebih lanjut
          </Button>
        </div>
        <div className="relative chartjs-style">
          <Line
            height={300}
            width={2000}
            data={dataLine}
            options={optionLine}
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6 mt-6">
        <div className="col-span-1 shadow-LightDropShadow bg-white rounded-lg p-12">
          <p className="heading-3 text-gray-02">Penjualan</p>
          {dashboard.status === "process" ? (
            <div className="flex justify-start mt-2">
              <Spinner size={40} color="#859DFF" />
            </div>
          ) : (
            <h3 className="heading-1">
              {dashboard?.data?.salesInfo
                ? formatRupiah(dashboard?.data?.salesInfo?.total)
                : "Rp. 0"}
            </h3>
          )}
        </div>
        <div className="col-span-1 shadow-LightDropShadow bg-white rounded-lg p-12">
          <p className="heading-3 text-gray-02">Laba bersih</p>
          {dashboard.status === "process" ? (
            <div className="flex justify-start mt-2">
              <Spinner size={40} color="#859DFF" />
            </div>
          ) : (
            <h3 className="heading-1">
              {dashboard?.data?.salesInfo
                ? formatRupiah(dashboard?.data?.salesInfo?.netto)
                : "Rp. 0"}
            </h3>
          )}
        </div>
        <div className="col-span-1 shadow-LightDropShadow bg-white rounded-lg p-12">
          <p className="heading-3 text-gray-02">Jumlah transaksi </p>
          {dashboard.status === "process" ? (
            <div className="flex justify-start mt-2">
              <Spinner size={40} color="#859DFF" />
            </div>
          ) : (
            <h3 className="heading-1">
              {dashboard?.data?.salesInfo
                ? dashboard?.data?.salesInfo?.count
                : 0}{" "}
              transaksi
            </h3>
          )}
        </div>
      </div>
      {/* grid bagi two */}
      <div className="grid grid-cols-3 gap-6 mt-6">
        {/* grid left */}
        <div className="col-span-2">
          {/* <div className="bg-white shadow-LightDropShadow rounded-lg py-12 px-7">
            <h4 className="heading-3 text-green-02">
              Persiapkan bisnis Anda dengan Sello
            </h4>
            <p className="text-gray-03 text-2 mt-2">
              Panduan kami akan memandu Anda tentang cara menggunakan Sello.
            </p>
            <ProgressBar progress="w-2/6" color="green" className="mt-6" />
            <p className="text-3 mt-2 text-gray-02">38% Selesai</p>
            <Button variant="primary" color="green" className="mt-12">
              Panduan Sello
            </Button>
          </div> */}
          {/* Metode pembayaran  */}
          <div className="bg-white shadow-LightDropShadow rounded-lg py-12 px-7 mt-6">
            <h4 className="heading-3 text-dark">Metode pembayaran</h4>
            <div className="grid grid-cols-5 mt-2 gap-6">
              {/* list metode pemayaran */}

              <div className="col-span-3">
                <ul>
                  {dashboard.status === "process" ? (
                    <div className="flex justify-start mt-2">
                      <Spinner size={40} color="#859DFF" />
                    </div>
                  ) : dashboard?.data?.topPayment?.length ? (
                    <React.Fragment>
                      {dashboard?.data?.topPayment?.map((payment, i) => {
                        return (
                          <li className="px-4 py-2" key={i}>
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div
                                  className={`w-2 h-2 rounded-full ${color[i]}`}
                                ></div>
                                <p className="text-2 ml-2">{payment?.name}</p>
                              </div>
                              <p className="text-2">
                                {formatRupiah(payment?.total)}
                              </p>
                            </div>
                          </li>
                        );
                      })}
                      <li className="px-4 py-2">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <div className="w-2 h-2 rounded-full bg-white bg-transparent"></div>
                            <p className="bold-text-2 ml-2">TOTAL</p>
                          </div>
                          <p className="bold-text-2">
                            {formatRupiah(dashboard?.chartPayment?.total)}
                          </p>
                        </div>
                      </li>
                    </React.Fragment>
                  ) : (
                    <h1>0 penjualan dalam waktu yang ditentukan.</h1>
                  )}
                </ul>
                {/* <Button
                  variant="secondary"
                  color="blue"
                  className="flex items-center mt-12"
                >
                  Pelajari lebih lanjut <IconRightAlter className="ml-2" />
                </Button> */}
              </div>
              {/* grafik here */}
              <div className="col-span-2">
                <div>
                  <Doughnut data={dataDoughnut} options={optionDoughnut} />
                </div>
              </div>
            </div>
          </div>
          {/* Produk terlaris  */}
          <div className="bg-white shadow-LightDropShadow rounded-lg py-12 px-7 mt-6">
            <h4 className="heading-3 text-dark">Produk terlaris</h4>
            <ul>
              {dashboard.status === "process" ? (
                <div className="flex justify-start mt-2">
                  <Spinner size={40} color="#859DFF" />
                </div>
              ) : dashboard?.data?.sellingProduct?.length ? (
                dashboard?.data?.sellingProduct?.map((product, i) => {
                  return (
                    <li className="px-4 py-2" key={i}>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <p>{i + 1}.</p>
                          <p className="text-2 ml-1">{product?.productName}</p>
                        </div>
                        <p className="text-2">{formatRupiah(product?.total)}</p>
                      </div>
                    </li>
                  );
                })
              ) : (
                <h1>0 penjualan dalam waktu yang ditentukan.</h1>
              )}
            </ul>
            {/* <Button
              variant="secondary"
              color="blue"
              className="flex items-center mt-12"
            >
              Pelajari lebih lanjut <IconRightAlter className="ml-2" />
            </Button> */}
          </div>

          {/* Kategori terlaris  */}
          <div className="bg-white shadow-LightDropShadow rounded-lg py-12 px-7 mt-6">
            <h4 className="heading-3 text-dark">Kategori terlaris</h4>
            <ul>
              {dashboard.status === "process" ? (
                <div className="flex justify-start mt-2">
                  <Spinner size={40} color="#859DFF" />
                </div>
              ) : dashboard?.data?.topCategory?.length ? (
                dashboard?.data?.topCategory?.map((category, i) => {
                  return (
                    <li className="px-4 py-2" key={i}>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <p>{i + 1}.</p>
                          <p className="text-2 ml-1">{category.categoryName}</p>
                        </div>
                        <p className="text-2">{formatRupiah(category.total)}</p>
                      </div>
                    </li>
                  );
                })
              ) : (
                <h1>0 penjualan dalam waktu yang ditentukan.</h1>
              )}
            </ul>
            {/* <Button
              variant="secondary"
              color="blue"
              className="flex items-center mt-12"
            >
              Pelajari lebih lanjut <IconRightAlter className="ml-2" />
            </Button> */}
          </div>
        </div>
        {/* grid right*/}
        <div className="col-span-1">
          {/* Selesaikan berbagai hal lebih cepat dengan aksi ini */}

          <div className="bg-white shadow-LightDropShadow rounded-lg py-12 px-7">
            <h3 className="bold-text-1 text-dark">
              Selesaikan berbagai hal lebih cepat dengan aksi ini
            </h3>
            <Link to="/product">
              <Button
                variant="secondary"
                color="blue"
                className="flex items-center w-full mt-6"
              >
                <div className="w-9">
                  <IconProduct fill="#1F4BFF" />
                </div>
                Tambah produk
              </Button>
            </Link>
            <Link to="/device">
              <Button
                variant="secondary"
                color="blue"
                className="flex items-center w-full mt-1"
              >
                <div className="w-9">
                  <IconDevices fill="#1F4BFF" />
                </div>
                Tambah perangkat
              </Button>
            </Link>
            <Link to="/customer">
              <Button
                variant="secondary"
                color="blue"
                className="flex items-center w-full mt-1"
              >
                <div className="w-9">
                  <IconCustomer fill="#1F4BFF" />
                </div>
                Tambah pelanggan baru
              </Button>
            </Link>
            <Link to="/transaction">
              <Button
                variant="secondary"
                color="blue"
                className="flex items-center w-full mt-1"
              >
                <div className="w-9">
                  <IconInvoice fill="#1F4BFF" />
                </div>
                Catatan transaksi baru
              </Button>
            </Link>
            <Link to="/balance">
              <Button
                variant="secondary"
                color="blue"
                className="flex items-center w-full mt-1"
              >
                <div className="w-9">
                  <IconMoney fill="#1F4BFF" />
                </div>
                Transfer dana
              </Button>
            </Link>
          </div>
          {/* Data pelanggan selama 30 hari terakhir */}
          <div className="bg-white shadow-LightDropShadow rounded-lg py-12 px-7 mt-6">
            <h3 className="bold-text-1 text-dark">
              Data pelanggan selama 30 hari terakhir
            </h3>
            <ul className="mt-6">
              <Link to="/customer">
                <li className="mt-1">
                  <div className="flex items-center justify-between bg-gray-05 p-4 rounded-lg">
                    <p className="text-2">Pelanggan baru</p>
                    <div className="flex items-center">
                      <p className="text-2">{dashboard?.data?.newCustomer}</p>
                      <IconArrow
                        className="ml-4"
                        fill="#8F8F8F"
                        width="13"
                        height="13"
                      />
                    </div>
                  </div>
                </li>
              </Link>
              <Link to="/customer/review">
                <li className="mt-1">
                  <div className="flex items-center justify-between bg-gray-05 p-4 rounded-lg">
                    <p className="text-2">Ulasan positif</p>
                    <div className="flex items-center">
                      <p className="text-2">
                        {dashboard?.data?.review?.positive}
                      </p>
                      <IconArrow
                        className="ml-4"
                        fill="#8F8F8F"
                        width="13"
                        height="13"
                      />
                    </div>
                  </div>
                </li>
              </Link>
              <Link to="/customer/review">
                <li className="mt-1">
                  <div className="flex items-center justify-between bg-gray-05 p-4 rounded-lg">
                    <p className="text-2">Ulasan negatif</p>
                    <div className="flex items-center">
                      <p className="text-2">
                        {dashboard?.data?.review?.negative}
                      </p>
                      <IconArrow
                        className="ml-4"
                        fill="#8F8F8F"
                        width="13"
                        height="13"
                      />
                    </div>
                  </div>
                </li>
              </Link>
            </ul>
          </div>
          {/* Lihat fitur tambahan untuk kebutuhan bisnis Anda */}
          {/* <div className="bg-white shadow-LightDropShadow rounded-lg py-12 px-7 mt-6">
            <h3 className="bold-text-1 text-dark">
              Lihat fitur tambahan untuk kebutuhan bisnis Anda
            </h3>
            <ul className="mt-6">
              <li className="mt-1">
                <div className="flex items-center bg-gray-05 p-4 rounded-lg">

                  <IconInventory className="mr-2" />
                  <p className="bold-text-2 text-gray-03">Inventaris</p>
                </div>
              </li>
              <li className="mt-1">
                <div className="flex items-center bg-gray-05 p-4 rounded-lg">

                  <IconMegaPhone className="mr-2" />
                  <p className="bold-text-2 text-gray-03">Marketing</p>
                </div>
              </li>
              <li className="mt-1">
                <div className="flex items-center bg-gray-05 p-4 rounded-lg">

                  <IconReservation className="mr-2" />
                  <p className="bold-text-2 text-gray-03">Reservasi</p>
                </div>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
