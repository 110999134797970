import * as React from "react";
import Spinner from "components/atoms/Spinner";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import { deleteData } from "utils/fetchData";
import { useDispatch, useSelector } from "react-redux";
import {
  setNotif,
  fetchVariant,
  setPage,
  setPerPage,
} from "features/Variant/actions";
import Banner from "components/atoms/Banner";
import Pagination from "components/atoms/Pagination";
import VariantEdit from './edit'

export default function ComponentTableVariant({ data, status }) {
  const dispatch = useDispatch();
  const notif = useSelector((state) => state.variant.notif);
  const variant = useSelector((state) => state.variant);
  const [check, setCheck] = React.useState({
    id: "",
    name: "",
    option: [],
  });

  const [loading, setLoading] = React.useState(false);
  const [isSweetAlert, setIsSweetAlert] = React.useState(false);
  const [isShowEdit, setIsShowEdit] = React.useState(false);
  const [variantId, setVariantId] = React.useState(null);

  const handleShowSweetAlert = (payload) => {
    setCheck({
      ...check,
      id: payload.variantId,
      name: payload.variantName,
      option: payload.ProductVariantOptions,
    });
    setIsSweetAlert(true);
  };

  const handleShowEdit = (payload) => {
    setVariantId(payload.variantId);
    setIsShowEdit(true);
  };

  const handleDeleteVariant = async (id) => {
    setLoading(true);
    const res = await deleteData(`v2/variant/${id}`);

    if (res.data?.code === 200) {
      setLoading(false);
      const notif = {
        message: `Variant "${check.name}" berhasil di hapus`,
        type: "primary",
        status: "danger",
        toggle: true,
      };
      dispatch(setNotif(notif));
      dispatch(fetchVariant());
      setIsSweetAlert(false);
      setIsSweetAlert(false);
      setCheck({ id: "", name: "", option: [] });
    }
  };
  return (
    <React.Fragment>
      <table className="w-full">
        <thead>
          <tr>
            <th>Nama variasi</th>
            <th>Jenis variasi</th>
            <th>Jumlah opsi</th>
            <th colSpan={2}>Jumlah produk</th>
          </tr>
        </thead>
        <tbody>
          {variant.status === "process" ? (
            <tr key="status">
              <td colSpan={5} style={{ textAlign: "center" }}>
                <div className="flex items-center justify-center">
                  <Spinner size={64} color="#859DFF" />
                </div>
              </td>
            </tr>
          ) : data.length ? (
            data.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{data.variantName}</td>
                    <td>
                      {data.variantType === 0
                        ? "Hanya satu opsi"
                        : "Pilih beberapa opsi"}
                    </td>
                    <td>{`${data.ProductVariantOptions.length} opsi`}</td>
                    <td>{`${data.variants.length} produk`}</td>
                    <td className="relative">
                      <Dropdown
                        variant="subtle"
                        color="blue"
                        content="icon only"
                      >
                        <DropdownList
                          type="button"
                          onClick={() => handleShowEdit(data)}
                          color="blue"
                        >
                          Ubah variasi
                        </DropdownList>
                        <DropdownList
                          type="button"
                          onClick={() => handleShowSweetAlert(data)}
                          color="red"
                        >
                          Hapus variasi
                        </DropdownList>
                      </Dropdown>
                    </td>
                  </tr>
                  {notif.toggle && notif.id === data.variantId && (
                    <tr>
                      <td colSpan={5} className="p-0">
                        <Banner
                          status={notif.status}
                          type={notif.type}
                          message={notif.message}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <td colSpan={5} style={{ textAlign: "center" }}>
                Tidak Ditemukan Data
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* START: PAGINATION */}
      <div className="mt-5">
        <Pagination
          items={variant.pages}
          currentPage={variant.page}
          limit={variant.limit}
          onPageChange={(page) => dispatch(setPage(page.selected + 1))}
          onChangePerPage={(value) => dispatch(setPerPage(value))}
          label="variasi per halaman"
        />
      </div>

      {/* END: PAGINATION */}
      {/* START: ALERT DELETE */}
      <ModalDialog
        size={"alert"}
        show={isSweetAlert}
        onClose={() => setIsSweetAlert(false)}
        title="Hapus kategori"
      >
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p>
            Apakah anda yakin ingin menghapus variant{" "}
            <span className="text-blue-03">"{check.name}"</span> ?
            ini juga akan menghapus <span className="text-blue-03">"{check?.option?.length} variasi"</span>
            yang terhubung ke opsi ini dan stok akan diatur kembali secara otomatis. aksi ini tidak bisa dibatalkan lagi jika variasi telah dihapus.
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
            onClick={() => setIsSweetAlert(false)}
          >
            Batal hapus variasi
          </Button>
          <Button
            variant="primary"
            color="red"
            className="my-5"
            type="button"
            loading={loading ? true : false}
            disabled={loading ? true : false}
            onClick={!loading ? () => handleDeleteVariant(check.id) : null}
          >
            Hapus variasi
          </Button>
        </div>
      </ModalDialog>
      {/* END: ALERT DELETE */}

      {/* START: EDIT Variant */}
      {isShowEdit && <VariantEdit
        title="Ubah nama variasi"
        variant={variantId}
        show={isShowEdit}
        close={() => setIsShowEdit(false)}
      />}
      {/* END: EDIT Variant */}
    </React.Fragment>
  );
}
