import React from "react";

export default function Info({ className, width, height, fillBg, filli }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.0273" cy="12" r="8" fill="white" />
      <path
        d="M12.0273 2C10.0495 2 8.11614 2.58649 6.47165 3.6853C4.82715 4.78412 3.54543 6.3459 2.78855 8.17317C2.03168 10.0004 1.83364 12.0111 2.2195 13.9509C2.60535 15.8907 3.55776 17.6725 4.95628 19.0711C6.35481 20.4696 8.13664 21.422 10.0764 21.8079C12.0163 22.1937 14.0269 21.9957 15.8542 21.2388C17.6814 20.4819 19.2432 19.2002 20.342 17.5557C21.4409 15.9112 22.0273 13.9778 22.0273 12C22.0273 10.6868 21.7687 9.38642 21.2661 8.17317C20.7636 6.95991 20.027 5.85752 19.0984 4.92893C18.1698 4.00035 17.0674 3.26375 15.8542 2.7612C14.6409 2.25866 13.3406 2 12.0273 2ZM12.0273 20C10.4451 20 8.89838 19.5308 7.58279 18.6518C6.26719 17.7727 5.24181 16.5233 4.63631 15.0615C4.03081 13.5997 3.87238 11.9911 4.18107 10.4393C4.48975 8.88743 5.25167 7.46197 6.37049 6.34315C7.48931 5.22433 8.91478 4.4624 10.4666 4.15372C12.0185 3.84504 13.627 4.00346 15.0888 4.60896C16.5506 5.21447 17.8001 6.23984 18.6791 7.55544C19.5582 8.87103 20.0273 10.4177 20.0273 12C20.0273 14.1217 19.1845 16.1566 17.6842 17.6569C16.1839 19.1571 14.1491 20 12.0273 20Z"
        fill={fillBg}
      />
      <path
        d="M12.0276 11C11.7624 11 11.5081 11.1054 11.3205 11.2929C11.133 11.4804 11.0276 11.7348 11.0276 12V16C11.0276 16.2652 11.133 16.5196 11.3205 16.7071C11.5081 16.8946 11.7624 17 12.0276 17C12.2928 17 12.5472 16.8946 12.7347 16.7071C12.9223 16.5196 13.0276 16.2652 13.0276 16V12C13.0276 11.7348 12.9223 11.4804 12.7347 11.2929C12.5472 11.1054 12.2928 11 12.0276 11ZM12.4076 7.08C12.1642 6.97998 11.8911 6.97998 11.6476 7.08C11.5249 7.12759 11.4127 7.19896 11.3176 7.29C11.2293 7.3872 11.1583 7.49881 11.1076 7.62C11.0516 7.73868 11.0242 7.86882 11.0276 8C11.0269 8.13161 11.0521 8.26207 11.1019 8.38391C11.1516 8.50574 11.2249 8.61656 11.3176 8.71C11.4148 8.79833 11.5264 8.86936 11.6476 8.92C11.7991 8.98224 11.9636 9.00632 12.1266 8.99011C12.2896 8.97391 12.4461 8.91792 12.5823 8.82707C12.7186 8.73622 12.8305 8.61328 12.9081 8.46907C12.9858 8.32486 13.0268 8.16378 13.0276 8C13.0239 7.73523 12.9204 7.48163 12.7376 7.29C12.6425 7.19896 12.5304 7.12759 12.4076 7.08Z"
        fill={filli}
      />
    </svg>
  );
}

Info.defaultProps = {
  width: "24",
  height: "25",
  fillBg: "white",
  filli: "#0066CC",
};
