import * as React from "react";
import TextArea from "components/atoms/Form/InputTextArea";
import TextCaption from "components/atoms/Text/TextCaption";

export default function InputTextArea() {
  const [textarea, setTextarea] = React.useState("");
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="flex w-full items-center">
        <div className="flex flex-col w-full">
          <div className="my-2">
            <TextArea
              variant="default"
              rows={5}
              placeholder="placeholder"
              label="Text field label"
              name="textarea"
              value={textarea}
              onChange={(e) => setTextarea(e.target.value)}
            />
          </div>
          <div className="my-2">
            <TextArea
              variant="disabled"
              disabled
              rows={5}
              placeholder="placeholder"
              label="Text field label"
              name="textarea"
              value={textarea}
              onChange={(e) => setTextarea(e.target.value)}
            />
          </div>
          <div className="my-2">
            <TextArea
              variant="error"
              rows={5}
              placeholder="placeholder"
              label="Text field label"
              name="textarea"
              value={textarea}
              onChange={(e) => setTextarea(e.target.value)}
            >
              <TextCaption status="error" message="This field is required" />
            </TextArea>
          </div>
          <div className="my-2">
            <TextArea
              variant="success"
              rows={5}
              placeholder="placeholder"
              label="Text field label"
              name="textarea"
              value={textarea}
              onChange={(e) => setTextarea(e.target.value)}
            >
              <TextCaption status="success" message="This field is required" />
            </TextArea>
          </div>
          <div className="my-2">
            <TextArea
              variant="warning"
              rows={5}
              placeholder="placeholder"
              label="Text field label"
              name="textarea"
              value={textarea}
              onChange={(e) => setTextarea(e.target.value)}
            >
              <TextCaption status="warning" message="This field is required" />
            </TextArea>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center">
        <div className="flex flex-col w-full pr-5">
          <div className="my-2">
            <TextArea
              variant="default"
              rows={5}
              info
              placeholder="placeholder"
              label="Text field label"
              name="textarea"
              value={textarea}
              onChange={(e) => setTextarea(e.target.value)}
            />
          </div>
          <div className="my-2">
            <TextArea
              variant="disabled"
              disabled
              rows={5}
              info
              placeholder="placeholder"
              label="Text field label"
              name="textarea"
              value={textarea}
              onChange={(e) => setTextarea(e.target.value)}
            />
          </div>
          <div className="my-2">
            <TextArea
              variant="error"
              rows={5}
              info
              placeholder="placeholder"
              label="Text field label"
              name="textarea"
              value={textarea}
              onChange={(e) => setTextarea(e.target.value)}
            >
              <div className="ml-5">
                <TextCaption status="error" message="This field is required" />
              </div>
            </TextArea>
          </div>
          <div className="my-2">
            <TextArea
              variant="success"
              rows={5}
              info
              placeholder="placeholder"
              label="Text field label"
              name="textarea"
              value={textarea}
              onChange={(e) => setTextarea(e.target.value)}
            >
              <div className="ml-5">
                <TextCaption
                  status="success"
                  message="This field is required"
                />
              </div>
            </TextArea>
          </div>
          <div className="my-2">
            <TextArea
              variant="warning"
              rows={5}
              info
              placeholder="placeholder"
              label="Text field label"
              name="textarea"
              value={textarea}
              onChange={(e) => setTextarea(e.target.value)}
            >
              <div className="ml-5">
                <TextCaption
                  status="warning"
                  message="This field is required"
                />
              </div>
            </TextArea>
          </div>
        </div>
      </div>
    </div>
  );
}
