import React from 'react'
import { CgSpinnerAlt } from "react-icons/cg";
import propsTypes from 'prop-types'

function Spinner({ color, size }) {
  return (
    <div className={`animate-spin`} style={{ color: color }}>
      <CgSpinnerAlt size={size} />
    </div>
  )
}

Spinner.defaultProps = {
  color: '#859DFF',
  size: 24
}

Spinner.propsTypes = {
  color: propsTypes.string,
  size: propsTypes.number
}


export default Spinner