import {
  START_FETCHING_BANK,
  SUCCESS_FETCHING_BANK,
  ERROR_FETCHING_BANK,
  SET_SELECTBOX,
  SET_NOTIF,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  select_box: [],
  status: statuslist.idle,
  notif: {
    message: "",
    type: "",
    status: "",
    toggle: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_BANK:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_BANK:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_BANK:
      return {
        ...state,
        status: statuslist.success,
        data: action.bank,
      };

    case SET_NOTIF:
      return { ...state, notif: action.notif };

    case SET_SELECTBOX:
      return { ...state, select_box: action.select_box };

    default:
      return state;
  }
}
