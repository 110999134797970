import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import { HorizontalTab } from "pages/Component/ComponentInput/Index";
import "./editStock.css";
import ArrowRight from "components/atoms/Icons/Custom/ArrowRight";
import { useDispatch, useSelector } from "react-redux";
import { getData, putData } from "utils/fetchData";
import {
  setVariantWithLocation,
  fetchStock,
  setWithoutMaterialVariant,
  setIdStock,
  setVariantOnly,
  setDisabledVariant,
  setNotif,
} from "features/Stock/actions";
import _ from "lodash";

function EditStock({ show, close }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.inventory);
  const [outletId, setOutletId] = React.useState("");
  const [index, setIndex] = React.useState(0);
  const [variant, setVariant] = React.useState([]);
  const [reason, setReason] = React.useState([]);
  const [operator, setOperator] = React.useState("PLUS");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCekOnChange, setIsCekOnChange] = React.useState(false);

  console.log("variant===>");
  console.log(variant);

  //tab handler
  const tabHandler = (idOutlet, index) => {
    let _temp = [];
    if (data.disabledVariant) {
      let _obj = {
        outletId: data.withoutMaterialVariant[index].outletId,
        name: data.withoutMaterialVariant[index].name,
        beforeStock: data.withoutMaterialVariant[index].beforeStock,
        changeStock: 0,
        afterStock: data.withoutMaterialVariant[index].afterStock,
        reasonId: data.withoutMaterialVariant[index].reasonId,
      };
      _temp = _temp.concat(_obj);
    } else {
      _temp = _temp.concat(data.variantWithlocation[index].variants);
    }
    setVariant(_temp);
    setOutletId(idOutlet);
    setIndex(index);
  };

  const onChange = (e, index) => {
    setIsCekOnChange(true);
    let _temp = [...variant];

    if (!data.disabledVariant) {
      if (e.target.name === "changeStock") {
        if (operator === "PLUS") {
          _temp[index].changeStock = parseInt(e.target.value);
          _temp[index].isUpdate = true;
          if (_temp[index].changeStock > 0) {
            _temp[index].afterStock = _temp[index].beforeStock + parseInt(e.target.value);
          } else {
            _temp[index].afterStock = 0;
          }
        } else if (operator === "MINUS") {
          _temp[index].changeStock = parseInt(e.target.value);
          _temp[index].isUpdate = true;
          if (_temp[index].changeStock > 0) {
            _temp[index].afterStock = _temp[index].beforeStock - parseInt(e.target.value);
          } else {
            _temp[index].afterStock = 0;
          }
        } else if (operator === "RESET") {
          _temp[index].changeStock = parseInt(e.target.value);
          _temp[index].isUpdate = true;
          if (_temp[index].changeStock > 0) {
            _temp[index].afterStock = _temp[index].changeStock;
          } else {
            _temp[index].afterStock = 0;
          }
        }
      } else if (e.target.name === "afterStock") {
        _temp[index].afterStock = parseInt(e.target.value);
        _temp[index].isUpdate = true;
        if (operator === "PLUS") {
          if (_temp[index].afterStock > 0) {
            _temp[index].changeStock = _temp[index].beforeStock - parseInt(e.target.value);
          } else {
            _temp[index].changeStock = 0;
          }
        } else if (operator === "MINUS") {
          if (_temp[index].afterStock > 0) {
            _temp[index].changeStock = parseInt(e.target.value) - _temp[index].beforeStock;
          } else {
            _temp[index].changeStock = 0;
          }
        } else if (operator === "RESET") {
          _temp[index].afterStock = parseInt(e.target.value);
          _temp[index].isUpdate = true;
          if (_temp[index].changeStock > 0) {
            _temp[index].changeStock = parseInt(e.target.value) - _temp[index].beforeStock;
          } else {
            _temp[index].afterStock = 0;
          }
        }
      } else if (e.target.name === "reasonId") {
        let _reason = [...reason];
        _reason.forEach((prev) => {
          if (parseInt(e.target.value) === prev.value) setOperator(prev.operator);
        });
        _temp[index].reasonId = parseInt(e.target.value);
        _temp[index].isUpdate = true;
        _temp[index].changeStock = 0;
        _temp[index].afterStock = 0;
      }
      dispatch(
        setVariantWithLocation(
          data.variantWithlocation.map((item) => {
            if (item.outletId === outletId) {
              item.isUpdate = true;
            }
            return item;
          })
        )
      );
      setVariant(_temp);
    } else {
      dispatch(
        setWithoutMaterialVariant(
          data.withoutMaterialVariant.map((item) => {
            if (item.outletId === outletId) {
              if (e.target.name === "changeStock") {
                if (operator === "PLUS") {
                  item.changeStock = parseInt(e.target.value);
                  if (item.changeStock > 0) {
                    item.afterStock = item.beforeStock + parseInt(e.target.value);
                  } else {
                    item.afterStock = 0;
                  }
                } else if (operator === "MINUS") {
                  item.changeStock = parseInt(e.target.value);
                  if (item.changeStock > 0) {
                    item.afterStock = item.beforeStock - parseInt(e.target.value);
                  } else {
                    item.afterStock = 0;
                  }
                } else if (operator === "RESET") {
                  item.changeStock = parseInt(e.target.value);
                  if (item.changeStock > 0) {
                    item.afterStock = item.beforeStock - item.changeStock;
                  } else {
                    item.afterStock = 0;
                  }
                }
              } else if (e.target.name === "afterStock") {
                item.afterStock = parseInt(e.target.value);
                if (operator === "PLUS") {
                  if (item.afterStock > 0) {
                    item.changeStock = item.beforeStock - parseInt(e.target.value);
                  } else {
                    item.changeStock = 0;
                  }
                } else if (operator === "MINUS") {
                  if (item.afterStock > 0) {
                    item.changeStock = parseInt(e.target.value) - item.beforeStock;
                  } else {
                    item.changeStock = 0;
                  }
                } else {
                  if (item.afterStock > 0) {
                    item.changeStock = parseInt(e.target.value) - item.beforeStock;
                  } else {
                    item.changeStock = 0;
                  }
                }
              } else if (e.target.name === "reasonId") {
                let _reason = [...reason];
                _reason.forEach((prev) => {
                  if (parseInt(e.target.value) === prev.value) setOperator(prev.operator);
                });
                item.reasonId = parseInt(e.target.value);
              }
              item.isUpdate = true;
            }
            return item;
          })
        )
      );
      setVariant(
        _temp.map((item) => {
          if (item.outletId === outletId) {
            if (e.target.name === "changeStock") {
              _temp[index].changeStock = parseInt(e.target.value);
              if (operator === "PLUS") {
                if (_temp[index].changeStock > 0) {
                  _temp[index].afterStock = _temp[index].beforeStock + parseInt(e.target.value);
                } else {
                  _temp[index].afterStock = 0;
                }
              } else if (operator === "MINUS") {
                if (_temp[index].changeStock > 0) {
                  _temp[index].afterStock = _temp[index].beforeStock - parseInt(e.target.value);
                } else {
                  _temp[index].afterStock = 0;
                }
              } else if (operator === "RESET") {
                if (_temp[index].changeStock > 0) {
                  _temp[index].afterStock = _temp[index].beforeStock - _temp[index].changeStock;
                } else {
                  _temp[index].afterStock = 0;
                }
              }
            } else if (e.target.name === "afterStock") {
              item.afterStock = parseInt(e.target.value);
              if (operator === "PLUS") {
                if (item.afterStock > 0) {
                  item.changeStock = item.beforeStock - parseInt(e.target.value);
                } else {
                  item.changeStock = 0;
                }
              } else if (operator === "MINUS") {
                if (item.afterStock > 0) {
                  item.changeStock = parseInt(e.target.value) - item.beforeStock;
                } else {
                  item.changeStock = 0;
                }
              } else {
                if (item.afterStock > 0) {
                  item.changeStock = parseInt(e.target.value) - item.beforeStock;
                } else {
                  item.changeStock = 0;
                }
              }
            } else if (e.target.name === "reasonId") {
              let _reason = [...reason];
              _reason.forEach((prev) => {
                if (parseInt(e.target.value) === prev.value) setOperator(prev.operator);
              });
              item.reasonId = parseInt(e.target.value);
              _temp[index].changeStock = 0;
              _temp[index].afterStock = 0;
            }
            item.isUpdate = true;
          }
          return item;
        })
      );
    }
  };

  const onSubmit = async () => {
    if (!isCekOnChange) {
      close();
    } else {
      setIsCekOnChange(false);
      if (data.idStock !== 0 && data.modal_secondary === "show-editStockVariant-update") {
        setIsLoading(true);
        let payload = {};
        if (data.withVariant && !data.withMaterial) {
          let _tempUpdate = [];
          data.variantWithlocation.forEach((item) => {
            if (item.isUpdate) {
              let _trueUpdate = [];
              item.variants.forEach((child) => {
                if (child.isUpdate) {
                  _trueUpdate.push(child);
                }
              });
              _tempUpdate.push({
                outletId: item.outletId,
                outletName: item.outletName,
                variants: _trueUpdate,
              });
            }
          });
          payload = {
            withVariant: data.withVariant,
            withMaterial: data.withMaterial,
            locations: _tempUpdate,
          };
        } else if (!data.withVariant && !data.withMaterial) {
          let _tempUpdate2 = [];
          data.withoutMaterialVariant.forEach((item) => {
            if (item.isUpdate === true) {
              _tempUpdate2.push(item);
            }
          });
          payload = {
            withVariant: data.withVariant,
            withMaterial: data.withMaterial,
            locations: _tempUpdate2,
          };
        }
        await putData(`v2/inventory/${data.idStock}`, payload)
          .then((res) => {
            // console.log("res putt");
            // console.log(res);
            // console.log(payload);
            let notif = {
              message: `Berhasil mengubah stok ${res.data.productName} `,
              type: "primary",
              status: "success",
              id: parseInt(res.data.inventoryId),
              toggle: true,
            };

            dispatch(setNotif(notif));
            dispatch(fetchStock());
            dispatch(setIdStock(0));
            dispatch(setDisabledVariant(false));
            setIsLoading(false);
            setVariant([]);
            close();
          })
          .catch((err) => {
            console.log(err.response);
            alert("Terjadi error server");
          });
        setIsLoading(false);
      } else {
        if (!data.disabledVariant) {
          let _tempVariant = data.variantWithlocation;
          if (_tempVariant[index].outletId === outletId) {
            _.update(_tempVariant[index], `variants`, variant);
          }

          const mergeFruits = (data) => {
            let result = [];
            data.forEach((basket, i) => {
              //(2)
              if (i === 0) {
                basket.variants.forEach((data) => {
                  data.totalStock = data.afterStock;
                  result.push(data);
                });
              } else
                result.forEach((res) => {
                  basket.variants.forEach((jum) => {
                    if (res.name === jum.name) {
                      res.totalStock = res.totalStock + jum.afterStock;
                    }
                  });
                });
            });

            return result; //(7)
          };

          const mergedObject = mergeFruits(_tempVariant);

          dispatch(setVariantOnly(mergedObject));
          dispatch(setVariantWithLocation(_tempVariant));
        } else {
          close();
        }
        close();
      }
    }
  };

  const getReason = async () => {
    await getData("v2/reason").then((res) => {
      let _temp = [];
      res.data.reasons.forEach((item) => {
        if (item.title !== "Sales") {
          _temp.push({
            label: item.title,
            value: item.id,
            operator: item.operator,
          });
        }
      });
      setReason(_temp);
    });
  };

  React.useEffect(() => {
    getReason();
  }, [data]);
  return (
    <ModalDialog
      size={"medium"}
      show={show}
      onClose={() => {
        dispatch(setIdStock(0));
        close();
      }}
      title={<p className="text-dark heading-3">Edit jumlah stok</p>}
    >
      <div className="bg-gray-05 h-full">
        <div style={{ height: "81vh" }} className="p-8 border">
          {/* tab horizontal  */}
          <div className="flex items-center w-full">
            <ArrowRight className="transform rotate-180" width="20" height="20" fill="#1F4BFF" />
            <ul className="flex items-center border-b-2 w-full mx-4">
              {data.withoutMaterialVariant.length
                ? data.withoutMaterialVariant.map((outlet, i) => {
                    return (
                      <li key={i} className={`mr-3`} onClick={() => tabHandler(outlet.outletId, i)}>
                        <HorizontalTab
                          active={`${index === i ? "text-blue-02" : ""}`}
                          fontSize={"font-xl"}
                          Text={outlet.outletName}
                        />
                      </li>
                    );
                  })
                : ""}
            </ul>
            <ArrowRight width="20" height="20" fill="#1F4BFF" />
          </div>
          {/* table  */}
          <table className="table-edit-stock mt-4">
            <thead>
              <tr>
                <th>Produk {`${variant.length} variasi`}</th>
                <th>Stok sekarang</th>
                <th className="text-center">Alasan</th>
                <th className="text-right">Perubahan</th>
                <th className="text-right">Stok baru</th>
              </tr>
            </thead>
            <tbody className="w-full">
              {variant.length ? (
                variant.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="bg-white">{item.name}</td>
                      <td>{item.beforeStock}</td>
                      <td className="bg-white">
                        <select
                          name="reasonId"
                          onChange={(e) => onChange(e, index)}
                          className="input-material"
                          value={item.reasonId}
                        >
                          {reason.map((reason) => {
                            return (
                              <option value={reason.value} key={reason.value}>
                                {reason.label}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td className="bg-white text-right">
                        <input
                          name="changeStock"
                          value={item.changeStock}
                          onChange={(e) => onChange(e, index)}
                          type="number"
                          disabled={item.reasonId === 4 ? true : false}
                        />
                      </td>
                      <td className="bg-white text-right">
                        <input
                          name="afterStock"
                          value={item.afterStock}
                          onChange={(e) => onChange(e, index)}
                          type="number"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    className="text-center"
                    colSpan="5"
                    style={{ paddingTop: 50, paddingBottom: 50 }}
                  >
                    Pilih lokasi pada menu tab
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex  justify-between px-6 py-4 bg-white mt-6 absolute bottom-0 left-0 w-full">
          <Button onClick={() => close()} variant="secondary" color="blue" type="button">
            Buang perubahan
          </Button>
          <Button
            loading={isLoading}
            onClick={onSubmit}
            variant="primary"
            color="blue"
            type="button"
          >
            Selesai edit jumlah stok
          </Button>
        </div>
      </div>
      <Button variant="primary" color="gray">
        Selesai edit jumlah stok
      </Button>
    </ModalDialog>
  );
}

export default EditStock;
