import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import { useSelector, useDispatch } from "react-redux";
import { setModal, dropMaterial } from "features/Material/action";

function MaterialDelete() {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.materials);

  return (
    <ModalDialog
      size={"alert"}
      show={material.modal === "show-delete" ? true : false}
      onClose={() => dispatch(setModal("hide-delete"))}
      title="Hapus bahan baku"
    >
      <div className="px-3 py-6 bg-gray-50">
        <p>
          {`Apakah Anda yakin ingin menghapus “${material.name}” ? Aksi ini tidak bisa dibatalkan lagi jika bahan baku telah berhasil dihapus.`}
        </p>
      </div>
      <div className="px-5 flex justify-between items-center bg-white ">
        <Button
          onClick={() => dispatch(setModal("hide-delete"))}
          variant="primary"
          color="blue"
          className="my-5"
          type="submit"
        >
          Batal hapus bahan baku
        </Button>
        <Button
          onClick={() => dispatch(dropMaterial(material.id))}
          variant="primary"
          color="red"
          className="my-5 mx-2"
          type="button"
        >
          Hapus bahan baku
        </Button>
      </div>
    </ModalDialog>
  );
}

export default MaterialDelete;
