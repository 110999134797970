import {
  START_FETCHING_HISTORY,
  ERROR_FETCHING_HISTORY,
  SUCCESS_FETCHING_HISTORY,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_PRODUCT,
  SET_CHANGE,
  SET_LOCATION,
  SET_DATE,
  SET_MODAL,
} from "./constants";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";
import moment from "moment";

let debouncedFetchHistory = debounce(getData, 1000);

export const startFetchingHistory = () => {
  return {
    type: START_FETCHING_HISTORY,
  };
};

export const errorFetchingHistory = () => {
  return {
    type: ERROR_FETCHING_HISTORY,
  };
};

export const successFetchingHistory = ({ history }) => {
  return {
    type: SUCCESS_FETCHING_HISTORY,
    history,
  };
};

export const fetchHistory = (id) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingHistory());
    let start = getState().material_history.date.startDate || "";
    let end = getState().material_history.date.endDate || "";
    let start_date = moment(start).format("YYYY-MM-DD");
    let end_date = moment(end).format("YYYY-MM-DD");
    let location = getState().material_history.location || "";
    let reason_stock = getState().material_history.reason_stock || "";
    let page = getState().material_history.page || 1;
    let limit = getState().material_history.limit || 10;
    const params = {
      start_date,
      end_date,
      page,
      limit,
      location,
    };
    try {
      let {
        data: { rawMaterialHistory, pages },
      } = await debouncedFetchHistory(`v2/rawmaterial/${id}/history`, params);
      let totalPage = pages;
      let history = rawMaterialHistory;

      dispatch(successFetchingHistory({ history }));
      dispatch(setModalHistory("show-history-material-table"));
      dispatch(setTotalPage(totalPage));
    } catch (err) {
      console.log(err.response);
      dispatch(errorFetchingHistory());
    }
  };
};
export const fetchHistoryToUpdate = (id) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingHistory());
    let start = getState().material_history.date.startDate || "";
    let end = getState().material_history.date.endDate || "";
    let start_date = moment(start).format("YYYY-MM-DD");
    let end_date = moment(end).format("YYYY-MM-DD");
    let location = getState().material_history.location || "";
    let reason_stock = getState().material_history.reason_stock || "";
    let page = getState().material_history.page || 1;
    let limit = getState().material_history.limit || 10;
    const params = {
      start_date,
      end_date,
      page,
      limit,
      location,
      reason_stock,
    };
    try {
      let {
        data: { rawMaterialHistory, pages },
      } = await debouncedFetchHistory(`v2/rawmaterial/${id}/history`, params);
      let totalPage = pages;
      let history = rawMaterialHistory;
      dispatch(successFetchingHistory({ history }));
      dispatch(setModalHistory("show-history-material-update"));
      dispatch(setTotalPage(totalPage));
    } catch (err) {
      console.log(err.response);
      dispatch(errorFetchingHistory());
    }
  };
};

export const setDate = (date) => {
  return {
    type: SET_DATE,
    date: date.selection ? date.selection : date,
  };
};

export const setLocationHistory = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};
export const setReason = (reason_stock) => {
  return {
    type: SET_CHANGE,
    reason_stock,
  };
};
export const setProduct = (product) => {
  return {
    type: SET_PRODUCT,
    product,
  };
};

export const setLimitHistory = (limit) => {
  return {
    type: SET_LIMIT,
    limit,
  };
};
export const setPageHistory = (page = 1) => {
  return {
    type: SET_PAGE,
    page,
  };
};
export const setTotalPage = (totalPage) => {
  return {
    type: SET_TOTAL_PAGE,
    totalPage,
  };
};
export const setModalHistory = (modal) => {
  return {
    type: SET_MODAL,
    modal,
  };
};
