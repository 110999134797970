import React from 'react'

export default function Inventory({width,fill,height,className}) {
    return (
            <svg className={className} width={width} height={height} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.49 6.51999C17.4843 6.49362 17.4843 6.46635 17.49 6.43999C17.4852 6.41691 17.4852 6.39307 17.49 6.36999V6.27999L17.43 6.12999C17.4056 6.08906 17.3753 6.05199 17.34 6.01999L17.25 5.93999H17.2L13.26 3.44999L9.54001 1.14999C9.45394 1.08172 9.35546 1.03078 9.25001 0.999989H9.17001C9.08062 0.98507 8.98939 0.98507 8.90001 0.999989H8.80001C8.68385 1.02568 8.57248 1.06956 8.47001 1.12999L1.00001 5.77999L0.910007 5.84999L0.820007 5.92999L0.720007 5.99999L0.670007 6.05999L0.610007 6.20999V6.29999V6.35999C0.600294 6.4263 0.600294 6.49368 0.610007 6.55999V15.29C0.609667 15.4599 0.652643 15.6272 0.734878 15.7759C0.817113 15.9246 0.935894 16.0499 1.08001 16.14L8.58001 20.78L8.73001 20.84H8.81001C8.97919 20.8936 9.16083 20.8936 9.33001 20.84H9.41001L9.56001 20.78L17 16.21C17.1441 16.1199 17.2629 15.9946 17.3451 15.8459C17.4274 15.6972 17.4703 15.5299 17.47 15.36V6.62999C17.47 6.62999 17.49 6.55999 17.49 6.51999ZM9.00001 3.16999L10.78 4.26999L5.19001 7.72999L3.40001 6.62999L9.00001 3.16999ZM8.00001 18.17L2.50001 14.81V8.41999L8.00001 11.82V18.17ZM9.00001 10.06L7.09001 8.90999L12.68 5.43999L14.6 6.62999L9.00001 10.06ZM15.5 14.78L10 18.2V11.82L15.5 8.41999V14.78Z" fill={fill}/>
</svg>


    )
}

Inventory.defaultProps ={
    width:'16.89px',
    height:'19.89px',
    fill:'#8F8F8F'
}
