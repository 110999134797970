import React from "react";
import propTypes from "prop-types";
import Icon from "components/atoms/Icons";

function Switch({
  type,
  onClick,
  content,
  buttons,
  size,
  disabled,
  className,
}) {
  if (content === "icon") {
    return (
      <div
        className={`w-102 h-34 rounded-lg bg-blue-05 flex items-center justify-between px-2px`}
      >
        {buttons.map((data, i) => {
          return (
            <button
              key={i}
              name={data.name}
              onClick={() => onClick(data)}
              disabled={disabled}
              className={
                data.status
                  ? `flex items-center justify-center w-12 h-7 rounded-lg bg-white ${
                      type === "disabled" ? "text-gray-04" : "text-blue-03"
                    } focus:outline-none outline-none`
                  : `flex items-center justify-center w-12 h-7 ${
                      type === "primary"
                        ? "text-blue-04"
                        : type === "secondary"
                        ? "text-blue-03"
                        : "text-gray-04"
                    } rounded-lg bg-transparent focus:outline-none  outline-none`
              }
            >
              <Icon iconName={data.icon} />
            </button>
          );
        })}
      </div>
    );
  }

  if (content === "label") {
    return (
      <div
        className={`${
          size === "sm" ? "w-166 h-8" : "w-540 h-52"
        }  rounded-lg bg-blue-05 flex items-center justify-between px-2px ${className}`}
      >
        {buttons.map((data, i) => {
          return (
            <button
              key={i}
              name={data.name}
              onClick={() => onClick(data)}
              disabled={disabled}
              className={
                data.status
                  ? `${
                      size === "sm" ? "w-20 h-7" : "w-264 h-44"
                    } rounded-lg bg-white ${
                      type === "disabled" ? "text-gray-04" : "text-blue-03"
                    } font-semibold text-sm focus:outline-none outline-none`
                  : `${
                      size === "sm" ? "w-20 h-7" : "w-264 h-44"
                    } rounded-lg bg-transparent ${
                      type === "primary"
                        ? "text-blue-04"
                        : type === "secondary"
                        ? "text-blue-03"
                        : "text-gray-04"
                    } font-semibold text-sm focus:outline-none outline-none`
              }
            >
              {data.name}
            </button>
          );
        })}
      </div>
    );
  }
}

Switch.defaultProps = {
  content: "label",
  disabled: false,
  type: "primary",
  size: "sm",
};

Switch.propTypes = {
  type: propTypes.string,
  content: propTypes.string,
  onClick: propTypes.func,
  size: propTypes.string,
  buttons: propTypes.array,
  disabled: propTypes.bool,
};

export default Switch;
