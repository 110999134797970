import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import SelectBox from "components/atoms/SelectBox";
import "./devices.css";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import TextCaption from "components/atoms/Text/TextCaption";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { fetchCheckboxLocations } from "features/Location/actions";
import { setNotif, fetchDevice } from 'features/Device/actions'
import { postData } from 'utils/fetchData'
import Banner from 'components/atoms/Banner'

const DeviceSchema = yup.object().shape({
  name: yup.string().required("nama perangkat tidak boleh kosong."),
  imei: yup.string().required("imei perangkat tidak boleh kosong."),
  type: yup.string().required("tipe perangkat tidak boleh kosong."),
  outletId: yup.string().required("lokasi perangkat tidak boleh kosong."),
  website: yup.string().url(),
});
const initialState = {
  name: '',
  imei: '',
  outletId: { value: "", label: "Pilih lokasi" },
  type: { value: "", label: "Pilih tipe perangkat" },

}
export default function CreateDevice({ show, close }) {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);
  const [loading, setLoading] = React.useState(false)
  const { register, errors, setError, clearError } = useForm({
    mode: "onBlur",
    validationSchema: DeviceSchema,
  });
  const [field, setField] = React.useState(initialState)

  const type = [
    {
      label: "SELF_SERVICE",
      value: "SELF_SERVICE"
    },
    {
      label: "MOBILE",
      value: "MOBILE"
    },
    {
      label: "DUAL_DISPLAY",
      value: "DUAL_DISPLAY"
    },
    {
      label: "KITCHEN",
      value: "KITCHEN"
    },
  ]

  React.useEffect(() => {
    dispatch(fetchCheckboxLocations());
  }, [dispatch]);

  const onSelectType = (data) => {
    if (data.value === "") {
      setError('type', 'type', 'tipe perangkat tidak boleh kosong.')
    } else {
      clearError('type')
    }
    setField({ ...field, type: data })
  }

  const onSelectLocation = (data) => {
    if (data.value === "") {
      setError('outletId', 'outletId', 'lokasi perangkat tidak boleh kosong.')
    } else {
      clearError('outletId')
    }
    setField({ ...field, outletId: data })
  }

  // Device Type: "SELF_SERVICE", "MOBILE", "DUAL_DISPLAY", "KITCHEN"
  const [usePIN, setUsePIN] = React.useState(false);


  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    try {

      setLoading(true)
      const payload = {
        name: field.name,
        imei: field.imei,
        type: field.type?.value,
        outletId: field.outletId?.value,
      }
      const res = await postData('devices', payload)

      if (res.data.code === 200) {
        setField(initialState)

        setLoading(false)
        const notif = {
          message: `Perangkat "${res.data.device.deviceName} dengan tipe ${res.data.device.type}" berhasil ditambahkan`,
          type: "primary",
          status: "success",
          id: res.data?.device?.deviceId,
          toggle: true,
        };
        dispatch(setNotif(notif));
        dispatch(fetchDevice());
        close()
      }

    } catch (err) {

      if (err.response.data.code === 422) {
        setError('server', 'server', err.response.data.message)
        setError('imei', 'imei', 'imei sudah terdaftar di perangkat lain.')
        setTimeout(() => {
          clearError('server')
          clearError('imei')
        }, 5000)
      }

      setLoading(false)

    }
  }

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title="Tambah perangkat"
      anotherAction={
        <React.Fragment>
          <span className="text-blue-700 font-semibold absolute left-1/2 transform -translate-x-1/2">
            {field.name}
          </span>
          <div className="flex items-center">

            <Button
              loading={loading}
              className="ml-4"
              variant="primary"
              color="blue"
              type="submit"
              disabled={
                field.type.value === "" || field.name === "" || field.imei === "" || field.outletId.value === "" ? true : false
              }
              onClick={() => !loading ? onSubmit() : null}
            >
              Selesai tambah perangkat
            </Button>
          </div>
        </React.Fragment>
      }
    >
      {errors.server && <Banner
        message={errors.server.message}
        type="primary"
        status="warning"
      />}
      <div className={`container-body flex items-start justify-center bg-white`}>
        <div className="w-728px max-w-728px create-device">
          <h1 className="heading-3 text-gray-02 mb-4">Detail perangkat</h1>
          <InputTextLargeWithLabel
            spacing="w-32"
            className="w-full mt-3"
            label="Nama perangkat"
            name="name"
            value={field.name}
            placeholder="Cth: Charles, William, Edw"
            register={register}
            onChange={onChange}
          >
            {errors.name && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.name?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
          <InputTextLargeWithLabel
            spacing="w-32"
            className="w-full mt-3"
            label="IMEI perangkat"
            name="imei"
            value={field.imei}
            placeholder="00000 - 00000 - 00000"
            register={register}
            onChange={onChange}
          >
            {errors.imei && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.imei?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
          <SelectBox
            className="mt-3"
            spacing="w-32"
            type="text"
            value={field.type}
            label="Tipe perangkat"
            options={type}
            onChange={onSelectType}
          >
            {errors.type && (
              <TextCaption status="error" message={errors.type?.message} />
            )}
          </SelectBox>

          <h1 className="heading-3 mt-12 text-gray-02">Detail perangkat</h1>
          <p className="text-2 text-gray-02 mt-2">
            PIN digunakan untuk menjaga keamanan dan mengakses konfigurasi
            perangkat. Pin dihasilkan berdasarkan lokasi. Semua perangkat akan
            menggunkan PIN yang sama di lokasi yang sama.
            <br />
            <span
              className="text-blue-03 bold-text-2 cursor-pointer"
              onClick={() => setUsePIN(true)}
            >
              Pelajari cara menggunakan PIN perangkat
            </span>
          </p>
          <ul className="mt-8">
            <li className="flex border-b border-gray-04">
              <div
                className="py-4 px-6 bg-gray-05 bold-text-2 w-60"
                style={{ minWidth: "15rem" }}
              >
                Lokasi perangkat
              </div>
              <div className="py-4 pl-6 w-full device">
                <SelectBox
                  type="text"
                  value={field.outletId}
                  options={location.checkboxs}
                  label=""
                  onChange={onSelectLocation}
                >
                  {errors.outletId && (
                    <TextCaption status="error" message={errors.outletId?.message} />
                  )}
                </SelectBox>
              </div>
            </li>{" "}
            <li className="flex border-gray-04">
              <div className="py-4 px-6 bg-blue-03 text-white w-full">
                <p className="text-sm whitespace-nowrap text-center">
                  Gunakan PIN untuk login dan mengakses halaman konfigurasi di
                  perangkat.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <ModalDialog
        size={"alert"}
        show={usePIN}
        onClose={() => setUsePIN(false)}
        title="Cara menggunakan PIN perangkat"
      >
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <ul>
            <li className="flex items-start bold-text-2">
              <p className="w-7 text-blue-03">1</p>
              <p>
                Masukkan angka perangkat yang telah ditampilkan ke bagian “Angka
                perangkat” di admin panel.
              </p>
            </li>
            <li className="flex items-start bold-text-2 mt-4">
              <p className="w-7 text-blue-03">2</p>
              <p>
                Setelah memasukkan angka, pilih lokasi dan PIN 6 angka perangkat
                akan dihasilkan berdasarkan lokasi.
              </p>
            </li>
            <li className="flex items-start bold-text-2 mt-4">
              <p className="w-7 text-blue-03">3</p>
              <p>
                Kembali ke perangkat dan masukkan PIN untuk selesai menambahkan
                dan sinkronisasi perangkat.
              </p>
            </li>
            <li className="flex items-start bold-text-2 mt-4">
              <p className="w-7 text-blue-03">4</p>
              <p>
                PIN perangkat akan selalu dibutuhkan untuk mengakses konfigurasi
                dan login perangkat.
              </p>
            </li>
          </ul>
        </div>
        <div className="px-5 flex justify-end items-center bg-white">
          <Button
            onClick={() => setUsePIN(false)}
            variant="primary" color="blue" className="my-5" type="button">
            Kembali
          </Button>
        </div>
      </ModalDialog>
    </ModalDialog>
  );
}
