import React from "react";
import propTypes from "prop-types";

function ProgressBar(props) {
  const { color, progress, className } = props;

  const bgBar = [];
  const bgProgressBar = [];
  if (color === "blue") {
    bgBar.push("bg-blue-05");
    bgProgressBar.push("bg-blue-02");
  }
  if (color === "gray") {
    bgBar.push("bg-gray-05");
    bgProgressBar.push("bg-gray-02");
  }
  if (color === "green") {
    bgBar.push("bg-green-05");
    bgProgressBar.push("bg-green-02");
  }
  if (color === "red") {
    bgBar.push("bg-red-05");
    bgProgressBar.push("bg-red-02");
  }
  if (color === "orange") {
    bgBar.push("bg-orange-05");
    bgProgressBar.push("bg-orange-02");
  }
  if (color === "purple") {
    bgBar.push("bg-purple-05");
    bgProgressBar.push("bg-purple-02");
  }

  return (
    <div
      className={`relative w-full rounded-lg overflow-hidden h-2 ${className} ${bgBar.join(
        " "
      )}`}
    >
      <div
        className={`absolute left-0 top-0 bottom-0 rounded-lg ${bgProgressBar.join(
          " "
        )} ${progress}`}
      ></div>
    </div>
  );
}

ProgressBar.defaultProps = {
  color: "blue",
};

ProgressBar.propTypes = {
  color: propTypes.string,
  progress: propTypes.string,
};

export default ProgressBar;
