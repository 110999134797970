import React from "react";
import propTypes from "prop-types";
import Icon from "components/atoms/Icons/indexV2";

function InputGroup(props) {
  const {
    value,
    type,
    placeholder,
    name,
    onChange,
    onClick,
    size,
    status,
    content,
    category,
    iconName,
    iconColor,
    iconSize,
    disabled,
    register,
    width,
    dataValue,
  } = props;
  const className = [props.className];
  if (status === "default" && size === "sm")
    className.push("focus:border-blue-03 hover:border-blue-03");
  if (status === "error" && size === "sm") className.push("border-red-02");
  if (status === "success" && size === "sm") className.push("border-green-02");
  if (status === "warning" && size === "sm") className.push("border-orange-02");

  if (content === "switch") {
    return (
      <div className="relative">
        <input
          className={`text-field-small ${className.join(" ")} `}
          ref={register}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          data-value={dataValue}
        />
      </div>
    );
  }

  return (
    <div className="relative">
      <input
        className={`text-field-small ${width} ${className.join(" ")} `}
        ref={register}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        data-value={dataValue}
      />
      {content === "text icon" && (
        <>
          <span className="absolute top-1/2 transform -translate-y-1/2 right-4 text-small text-gray-03 font-semibold bold-text-2">
            <Icon iconName={iconName} iconSize={iconSize} iconColor={iconColor} />
          </span>
          <span
            className={`absolute top-1/2 transform -translate-y-1/2 text-small text-gray-03 font-semibold right-12 bold-text-2`}
          >
            {category}
          </span>
        </>
      )}
      <span
        className={`absolute top-1/2 ${
          content === "text switch" && "right-48 z-50"
        } transform -translate-y-1/2 right-4 text-small text-gray-03 font-semibold bold-text-2`}
        onClick={onClick}
      >
        {/* text or icon */}
        {content === "text switch" && category}
        {content === "icon" ? (
          <Icon iconName={iconName} iconSize={iconSize} iconColor={iconColor} />
        ) : (
          content === "text" && category
        )}
      </span>
    </div>
  );
}

InputGroup.defaultProps = {
  type: "text",
  placeholder: "Please type here...",
  size: "sm",
  status: "default",
  content: "text",
  category: "unit",
  status: "default",
};

InputGroup.propTypes = {
  type: propTypes.string,
  name: propTypes.string,
  value: propTypes.string,
  dataValue: propTypes.string,
  placeholder: propTypes.string,
  className: propTypes.string,
  onChange: propTypes.func,
  onClick: propTypes.func,
  iconName: propTypes.string,
  iconSize: propTypes.number,
  iconColor: propTypes.string,
  category: propTypes.string,
  content: propTypes.string,
  status: propTypes.string,
  size: propTypes.oneOf(["sm", "lg"]),
};

export default InputGroup;
