import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "components/atoms/Button";
import "./style.css";

function Tab({ children }) {
  const location = useLocation().pathname;

  const [expand, setExpand] = useState(true);

  return (
    <div className="tab-container">
      {children.map((menu) => {
        return (
          <div key={menu.id}>
            <ul>
              <Button
                variant={menu.link === location ? "secondary" : "link"}
                color="blue"
                type={menu?.submenu ? "button" : "link"}
                href={menu.link}
                onClick={() => (menu?.submenu ? setExpand(!expand) : "")}
                isBlock={menu.link === location ? true : false}
                className={
                  menu.link === location
                    ? "flex my-2 "
                    : "flex my-2 text-gray-01"
                }
              >
                {menu.name}
              </Button>
            </ul>
            <ul className="pl-6">
              {expand
                ? menu?.submenu?.map((submenu) => {
                  return (
                    <Button
                      key={submenu.id}
                      variant={
                        submenu.link === location ? "secondary" : "link"
                      }
                      color="blue"
                      type={"link"}
                      href={submenu.link}
                      isBlock={submenu.link ? true : false}
                      className={
                        submenu.link === location
                          ? "flex my-2 pl-8"
                          : "flex my-2 pl-8 text-gray-01"
                      }
                    >
                      {submenu.name}
                    </Button>
                  );
                })
                : ""}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

export default Tab;
