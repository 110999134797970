export const START_FETCHING_TRANSACTION = 'features/Transaction/START_FETCHING_TRANSACTION';
export const ERROR_FETCHING_TRANSACTION = 'features/Transaction/ERROR_FETCHING_TRANSACTION';
export const SUCCESS_FETCHING_TRANSACTION = 'features/Transaction/SUCCESS_FETCHING_TRANSACTION';
export const SET_KEYWORD = 'features/Transaction/SET_KEYWORD';
export const SET_TRANSACTION = 'features/Transaction/SET_TRANSACTION';
export const SET_DATE = 'features/Transaction/SET_DATE';
export const SET_PAYMENT_METHOD = 'features/Transaction/SET_PAYMENT_METHOD';
export const SET_LOCATION = 'features/Transaction/SET_LOCATION';
export const SET_PAGE = 'features/Product/SET_PAGE';
export const SET_LIMIT = 'features/Product/SET_LIMIT';
