import React from "react";
import { InputGroupSmall } from "components/atoms/Form";
import TextCaption from "components/atoms/Text/TextCaption";

export default function InputGroupContentText() {
  return (
    <div className="flex flex-col items-center">
      <div className="my-2">
        <InputGroupSmall status="default" content="text" category="unit" />
      </div>
      <div className="my-2">
        <InputGroupSmall
          status="disabled"
          disabled
          content="text"
          category="gr"
        />
      </div>
      <div className="my-2">
        <InputGroupSmall status="error" content="text" category="kg" />
        <TextCaption status="error" message="This field is required" />
      </div>
      <div className="my-2">
        <InputGroupSmall status="success" content="text" category="ml" />
        <TextCaption
          status="success"
          message="Field validation is successful"
        />
      </div>
      <div className="my-2">
        <InputGroupSmall status="warning" content="text" category="unit" />
        <TextCaption status="warning" message="This field is required" />
      </div>
    </div>
  );
}
