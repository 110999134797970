import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import "./kirimFaktur.css";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextCaption from "components/atoms/Text/TextCaption";
import { postData } from "utils/fetchData";

const SendFakturSchema = yup.object().shape({
  to: yup
    .string()
    .required("email tidak boleh kosong.")
    .email("email yang anda masukan tidak valid."),
  website: yup.string().url(),
});

export default function KirimFaktur({ show, close, transaction }) {
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, errors, setError } = useForm({
    mode: "onBlur",
    validationSchema: SendFakturSchema,
  });

  const [field, setField] = React.useState({ to: "" });

  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        email: field.to,
      };
      const res = await postData(`v2/sales/invoice/${transaction}`, payload);
      if (res.data.code === 200) {
        setLoading(false);
        close();
        setField({ ...field, to: "" });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleClose = () => {
    close();
    setField({ ...field, to: "" });
  };

  return (
    <ModalDialog
      size={"alert"}
      show={show}
      onClose={handleClose}
      title={<h1 className="text-gray-02 heading-3">Kirim faktur</h1>}
    >
      <div className={`bg-white border-t h-full overflow-scroll`}>
        <div className="p-5">
          <div className="bg-gray-05 p-4 border">
            <p className="font-medium">Email Penerima</p>
          </div>
          <div className="modal-kirim-faktur border">
            <InputTextLargeWithLabel
              register={register}
              name="to"
              value={field.to}
              onChange={onChange}
              placeholder="Cth: sutrisno.wijaya@email.com"
              status={errors?.to ? "error" : "default"}
            />
          </div>
          {errors.to && (
            <TextCaption status="error" message={errors.to?.message} />
          )}
        </div>
        <div className="px-5 py-5 flex justify-end items-center bg-white border-t">
          <Button
            variant="secondary"
            color="red"
            className="mr-4"
            type="button"
            onClick={close}
          >
            Batal
          </Button>
          <Button
            loading={loading}
            disabled={field.to === "" || errors.to ? true : false}
            onClick={() => (!loading ? onSubmit() : null)}
            variant="primary"
            color="blue"
          >
            Kirim faktur
          </Button>
        </div>
      </div>
    </ModalDialog>
  );
}
