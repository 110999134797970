import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAccounts } from "features/Accounts/actions";
import Button from "components/atoms/Button";
import Table from "./tableSettingTeam";
import CreateAccess from "./create";
import Banner from "components/atoms/Banner";

export default function PageSettingTeam() {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.accounts);
  console.log(accounts);

  React.useEffect(() => {
    dispatch(fetchAccounts());
  }, [dispatch]);

  const [tambahRekeningBank, setTambahRekeningBank] = React.useState(false);

  const [isShowCreate, setIsShowCreate] = React.useState(false);
  const [hapus, setHapus] = React.useState(false);
  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {accounts.notif?.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={accounts.notif.message}
          status={accounts.notif.status}
          type={accounts.notif.type}
        />
      )}
      <div>
        <h1 className="heading-3 text-dark mt-4">Akses akun tim</h1>
        <div className="flex items-end justify-between">
          <p className="text-1 text-gray-02 mt-2 w-2/3">
            Izinkan tim/karyawan anda untuk menggunakan akun anda. Anda bisa mengatur batasan dan
            siapa saja yang boleh mengubah atau mengatur untuk setiap titik akses.
          </p>
          <Button variant="primary" color="blue" onClick={() => setIsShowCreate(true)}>
            <p className="whitespace-nowrap">Berikan akses ke akun</p>
          </Button>
        </div>
      </div>
      {/* table */}
      <Table
        show={tambahRekeningBank}
        close={() => setTambahRekeningBank(false)}
        data={accounts}
        hapus={hapus}
        closeHapus={() => setHapus(false)}
        onClickHapus={() => setHapus(true)}
      />
      {/* modal */}

      {isShowCreate && <CreateAccess show={isShowCreate} close={() => setIsShowCreate(false)} />}
    </div>
  );
}
