import * as React from "react";
import Checkbox from "components/atoms/Checkbox";

/**
 * type: checklist, minus, default
 * label
 * size
 */

export default function Checkboxs() {
  const [isCheckbox, setIsCheckbox] = React.useState(true);

  return (
    <div className="w-10/12 pt-16 mt-5">
      {/* checkbox sm */}
      <div className="flex items-center">
        <div className="flex flex-col max-w-max mr-10">
          <Checkbox
            type="default"
            checked={isCheckbox}
            name="checkbox"
            value={isCheckbox}
            onChange={(e) => setIsCheckbox(e.target.checked)}
          />
          <div className="mt-5">
            <Checkbox
              type="default"
              checked={isCheckbox}
              name="checkbox"
              value={isCheckbox}
              disabled
              onChange={(e) => setIsCheckbox(e.target.checked)}
            />
          </div>
        </div>
        <div className="flex flex-col max-w-max">
          <Checkbox
            type="indeterminate"
            checked={isCheckbox}
            name="checkbox"
            value={isCheckbox}
            onChange={(e) => setIsCheckbox(e.target.checked)}
          />
          <div className="mt-5">
            <Checkbox
              type="indeterminate"
              checked={isCheckbox}
              name="checkbox"
              value={isCheckbox}
              disabled
              onChange={(e) => setIsCheckbox(e.target.checked)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center mt-5">
        <div className="flex flex-col max-w-max mt-5 mr-10">
          <Checkbox
            type="default"
            checked={isCheckbox}
            name="checkbox"
            value={isCheckbox}
            size="md"
            onChange={(e) => setIsCheckbox(e.target.checked)}
          />
          <div className="mt-10">
            <Checkbox
              type="default"
              checked={isCheckbox}
              name="checkbox"
              value={isCheckbox}
              disabled
              size="md"
              onChange={(e) => setIsCheckbox(e.target.checked)}
            />
          </div>
        </div>
        <div className="flex flex-col max-w-max mt-5 mr-10">
          <Checkbox
            type="indeterminate"
            checked={isCheckbox}
            name="checkbox"
            value={isCheckbox}
            size="md"
            onChange={(e) => setIsCheckbox(e.target.checked)}
          />
          <div className="mt-10">
            <Checkbox
              type="indeterminate"
              checked={isCheckbox}
              name="checkbox"
              value={isCheckbox}
              disabled
              size="md"
              onChange={(e) => setIsCheckbox(e.target.checked)}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center mt-5">
        <div className="flex flex-col max-w-max mt-5 mr-5">
          <Checkbox
            type="default"
            checked={isCheckbox}
            name="checkbox"
            value={isCheckbox}
            onChange={(e) => setIsCheckbox(e.target.checked)}
            label="checkbox"
          />
          <Checkbox
            type="default"
            checked={isCheckbox}
            name="checkbox"
            value={isCheckbox}
            disabled
            onChange={(e) => setIsCheckbox(e.target.checked)}
            label="checkbox"
          />
        </div>

        <div className="flex flex-col max-w-max mt-5">
          <Checkbox
            type="indeterminate"
            checked={isCheckbox}
            name="checkbox"
            value={isCheckbox}
            onChange={(e) => setIsCheckbox(e.target.checked)}
            label="checkbox"
          />
          <Checkbox
            type="indeterminate"
            checked={isCheckbox}
            name="checkbox"
            value={isCheckbox}
            disabled
            onChange={(e) => setIsCheckbox(e.target.checked)}
            label="checkbox"
          />
        </div>
      </div>
    </div>
  );
}
