import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWithMaterialVariantSingle, setWithMaterialVariant } from "features/Stock/actions";
import { setModalSecondary, setCheckboxData } from "features/Material/action";
import ModalDialog from "components/molecules/Modal";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import "./materialEdit.css";
import InputTextGroupWithLabel from "components/atoms/Form/InputTextSmallGroupWithLabel";
import MaterialChoice from "./materialChoice";
import Button from "components/atoms/Button";
import MaterialCreate from "./materialCreate";

function MaterialEdit({ show, close, variant, data }) {
  const dispatch = useDispatch();
  const materials = useSelector((state) => state.materials);
  const [showCreate, setShowCreate] = React.useState(false);
  const [material, setMaterial] = React.useState([]);

  const onDelete = (id) => {
    let _tempSingle = {
      isChecked: data.withMaterialVariantSingle.isChecked,
      name: data.withMaterialVariantSingle.name,
      rawMaterial: [...data.withMaterialVariantSingle.rawMaterial],
      sku: data.withMaterialVariantSingle.sku,
      variantOptionId: data.withMaterialVariantSingle.variantOptionId,
    };

    var removeIndex = _tempSingle.rawMaterial
      .map(function (item) {
        return item.rawMaterialId;
      })
      .indexOf(id);
    _tempSingle.rawMaterial.splice(removeIndex, 1);

    let _checkbox = [...materials.materials_checkbox];
    _checkbox.forEach((item) => {
      if (item.rawMaterialId === id) {
        item.isChecked = false;
        item.value = 0;
      }
    });

    dispatch(setCheckboxData(_checkbox));
    dispatch(setWithMaterialVariantSingle(_tempSingle));
  };

  const onChange = (e) => {
    let _tempSingle = {
      isChecked: data.withMaterialVariantSingle.isChecked,
      name: data.withMaterialVariantSingle.name,
      rawMaterial: [...data.withMaterialVariantSingle.rawMaterial],
      sku: data.withMaterialVariantSingle.sku,
      variantOptionId: data.withMaterialVariantSingle.variantOptionId,
    };

    _tempSingle.rawMaterial.forEach((current) => {
      if (current.rawMaterialId === parseInt(e.target.name)) {
        current.value = parseInt(e.target.value);
      }
    });
    dispatch(setWithMaterialVariantSingle(_tempSingle));
  };

  const onSubmitDone = () => {
    let _temp = [...variant];
    _temp.forEach((current) => {
      if (data.withMaterialVariantSingle.variantOptionId === current.variantOptionId) {
        current.isChecked = !current.isChecked;
        current.rawMaterial = data.withMaterialVariantSingle.rawMaterial;
      }
    });
    dispatch(setWithMaterialVariant(_temp));
    if (material.length > 0) {
      setMaterial(material);
    } else {
      setMaterial([]);
    }
    close();
  };

  return (
    <>
      <ModalDialog
        size={"medium"}
        show={show}
        onClose={() => close()}
        title={<p className="text-dark heading-3">Edit resep variasi</p>}
      >
        <div className="bg-gray-05 h-full relative overflow-scroll p-6">
          <div className="material-edit">
            <div className="ml-14">
              <InputTextLargeWithLabel
                className="w-full"
                placeholder=""
                label="Nama variasi produk"
                status="default"
                value={data.withMaterialVariantSingle.name}
                type="Text"
                name="name"
                disabled
              />
            </div>
            <div className="ml-14 mt-1">
              <InputTextLargeWithLabel
                className="w-full"
                placeholder=""
                label="SKU variasi"
                status="default"
                value={data.withMaterialVariantSingle.sku}
                type="Text"
                name="name"
                disabled
                onChange={onChange}
              />
            </div>
          </div>
          <ul
            className="bg-white pb-4 px-6 border rounded-lg border-gray-04 mt-6 h-60 overflow-y-scroll scroll-hidden"
            style={{ minHeight: "15rem" }}
          >
            {Object.keys(data.withMaterialVariantSingle).length
              ? data.withMaterialVariantSingle.rawMaterial.map((current) => {
                  return (
                    <li
                      className="flex items-center justify-between mt-4"
                      key={current.rawMaterialId}
                    >
                      <p>{current.name}</p>
                      <div className="flex items-center">
                        <div className="flex items-center">
                          <InputTextGroupWithLabel
                            name={current.rawMaterialId}
                            status="default"
                            content="text"
                            placeholder=""
                            category={current.unit.unit}
                            type="number"
                            width="w-56"
                            value={current.value}
                            onChange={onChange}
                          />
                          <div className="flex relative ml-4" style={{ top: "1px" }}>
                            <Button
                              onClick={() => onDelete(current.rawMaterialId)}
                              variant="secondary"
                              color="red"
                              type="button"
                              className="mr-2"
                            >
                              Hapus
                            </Button>
                            {/* <Button variant="secondary" color="blue" type="button">
                              Ubah
                            </Button> */}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              : ""}
          </ul>
          <Button
            onClick={() => dispatch(setModalSecondary("show-material-single-create"))}
            variant="primary"
            color="green"
            type="button"
            className="w-full mt-4"
          >
            + Tambah bahan baku
          </Button>
          <div className="flex justify-between px-6 py-4 bg-white mt-6 absolute bottom-0 left-0 w-full">
            <Button onClick={() => close()} variant="secondary" color="blue" type="button">
              Buang perubahan
            </Button>
            <Button onClick={onSubmitDone} variant="primary" color="blue" type="button">
              Selesai edit resep variasi
            </Button>
          </div>
        </div>
      </ModalDialog>
      <MaterialChoice
        create={() => {
          dispatch(setModalSecondary("hide-material-single-create"));
          setShowCreate(true);
        }}
        show={materials.modal_secondary === "show-material-single-create" ? true : false}
        close={() => dispatch(setModalSecondary("hide-material-single-create"))}
      />
      <MaterialCreate
        show={showCreate}
        close={() => {
          setShowCreate(false);
          dispatch(setModalSecondary("show-material-single-create"));
        }}
      />
    </>
  );
}

export default MaterialEdit;
