import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import { HorizontalTab } from "pages/Component/ComponentInput/Index";
import "./editTrackStock.css";
import ArrowRight from "components/atoms/Icons/Custom/ArrowRight";
import Toggle from "components/atoms/Toggle";
import { useDispatch, useSelector } from "react-redux";
import { putData } from "utils/fetchData";
import {
  setVariantWithLocation,
  fetchStock,
  setWithoutMaterialVariant,
  setIdStock,
  setDisabledVariant,
  setNotif,
} from "features/Stock/actions";
import _ from "lodash";

function EditTrackStock({ show, close }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.inventory);
  const [outletId, setOutletId] = React.useState("");
  const [index, setIndex] = React.useState(0);
  const [variant, setVariant] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  //tab handler
  const tabHandler = (idOutlet, index) => {
    let _temp = [];
    if (data.disabledVariant) {
      let _obj = {
        outletId: data.withoutMaterialVariant[index].outletId,
        name: data.withoutMaterialVariant[index].name,
        trackStock: data.withoutMaterialVariant[index].trackStock,
        trackingStatus: data.withoutMaterialVariant[index].trackingStatus,
        limitStock: data.withoutMaterialVariant[index].limitStock,
      };
      _temp = _temp.concat(_obj);
    } else {
      _temp = _temp.concat(data.variantWithlocation[index].variants);
    }
    setVariant(_temp);
    setOutletId(idOutlet);
    setIndex(index);
  };

  const onChange = (e, index) => {
    let _temp = [...variant];

    if (!data.disabledVariant) {
      if (e.target.name === "limitStock") {
        _temp[index].limitStock = parseInt(e.target.value);
      } else if (e.target.name === "track-stock") {
        _temp[index].trackStock = !_temp[index].trackStock;
      } else if (e.target.name === "track-status") {
        _temp[index].trackingStatus = !_temp[index].trackingStatus;
        _temp[index].limitStock = 0;
      }
      setVariantWithLocation(
        data.variantWithlocation.map((item) => {
          if (item.outletId === outletId) {
            item.isUpdate = true;
          }
          return item;
        })
      );
      setVariant(_temp);
    } else {
      dispatch(
        setWithoutMaterialVariant(
          data.withoutMaterialVariant.map((item) => {
            if (item.outletId === outletId) {
              if (e.target.name === "limitStock") {
                item.limitStock = parseInt(e.target.value);
              } else if (e.target.name === "track-stock") {
                item.trackStock = !item.trackStock;
              } else if (e.target.name === "track-status") {
                item.trackingStatus = !item.trackingStatus;
                item.limitStock = 0;
              }
              item.isUpdate = true;
            }
            return item;
          })
        )
      );
      setVariant(
        _temp.map((item) => {
          if (item.outletId === outletId) {
            if (e.target.name === "limitStock") {
              item.limitStock = parseInt(e.target.value);
            } else if (e.target.name === "track-stock") {
              item.trackStock = !item.trackStock;
            } else if (e.target.name === "track-status") {
              item.trackingStatus = !item.trackingStatus;
              item.limitStock = 0;
            }
            item.isUpdate = true;
          }
          return item;
        })
      );
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (data.idStock !== 0 && data.modal_secondary === "show-editTrackStockVariant-update") {
      let payload = {};
      if (data.withVariant && !data.withMaterial) {
        let _tempUpdate = [];
        data.variantWithlocation.forEach((item) => {
          if (item.isUpdate === true) {
            _tempUpdate.push(item);
          }
        });
        payload = {
          withVariant: data.withVariant,
          withMaterial: data.withMaterial,
          locations: _tempUpdate,
        };
      } else if (!data.withVariant && !data.withMaterial) {
        let _tempUpdate2 = [];
        data.withoutMaterialVariant.forEach((item) => {
          if (item.isUpdate === true) {
            _tempUpdate2.push(item);
          }
        });
        payload = {
          withVariant: data.withVariant,
          withMaterial: data.withMaterial,
          locations: _tempUpdate2,
        };
      }
      await putData(`v2/inventory/${data.idStock}`, payload)
        .then((res) => {
          // console.log("res putt");
          // console.log(res);
          // console.log(payload);
          let notif = {
            message: `Berhasil mengubah stok ${res.data.productName} `,
            type: "primary",
            status: "success",
            id: parseInt(res.data.inventoryId),
            toggle: true,
          };

          dispatch(setNotif(notif));
          dispatch(fetchStock());
          dispatch(setIdStock(0));
          dispatch(setDisabledVariant(false));
          setIsLoading(false);
          setVariant([]);
          close();
        })
        .catch((err) => {
          console.log(err.response);
          alert("Terjadi error server");
        });
    } else {
      if (!data.disabledVariant) {
        let _tempVariant = [...data.variantWithlocation];
        if (_tempVariant[index].outletId === outletId) {
          _.update(_tempVariant[index], `variants`, variant);
        }
        dispatch(setVariantWithLocation(_tempVariant));
      } else {
        close();
      }
      setIsLoading(false);
      close();
    }
  };

  return (
    <ModalDialog
      size={"medium"}
      show={show}
      onClose={() => {
        dispatch(setIdStock(0));
        close();
      }}
      title={<p className="text-dark heading-3">Edit pelacakan stok</p>}
    >
      <div className="bg-gray-05 h-full">
        <div style={{ height: "81vh" }} className="p-8 border">
          {/* tab horizontal  */}
          <div className="flex items-center w-full">
            <ArrowRight className="transform rotate-180" width="20" height="20" fill="#1F4BFF" />
            <ul className="flex items-center border-b-2 w-full mx-4">
              {data.withoutMaterialVariant.length
                ? data.withoutMaterialVariant.map((outlet, index) => {
                    return (
                      <li
                        key={index}
                        className="mr-3"
                        onClick={() => tabHandler(outlet.outletId, index)}
                      >
                        <HorizontalTab fontSize={"font-xl"} Text={outlet.outletName} />
                      </li>
                    );
                  })
                : ""}
            </ul>
            <ArrowRight width="20" height="20" fill="#1F4BFF" />
          </div>
          {/* table  */}
          <table className="table-track-stock mt-4">
            <thead>
              <tr>
                <th>Produk {`${variant.length} variasi`}</th>
                <th>Lacak & hitung stok</th>
                <th className="text-center">Peringatan stok</th>
                <th className="text-right">Peringatan sewaktu</th>
              </tr>
            </thead>
            <tbody className="w-full">
              {variant.length ? (
                variant.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="bg-white">{item.name}</td>
                      <td>
                        <div className="relative -top-3 left-12">
                          <Toggle
                            size="lg"
                            checked={item.trackStock}
                            name="track-stock"
                            onChange={(e) => onChange(e, index)}
                          />
                        </div>
                      </td>
                      <td className="bg-white">
                        <div className="relative -top-3 left-12">
                          <Toggle
                            size="lg"
                            checked={item.trackingStatus}
                            name="track-status"
                            onChange={(e) => onChange(e, index)}
                          />
                        </div>
                      </td>
                      <td className="bg-white text-right">
                        <input
                          name="limitStock"
                          value={item.limitStock}
                          onChange={(e) => onChange(e, index)}
                          type="number"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    className="text-center"
                    colSpan="5"
                    style={{ paddingTop: 50, paddingBottom: 50 }}
                  >
                    Pilih lokasi pada menu tab
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* bottom fixed */}
        <div className="flex justify-between px-6 py-4 bg-white mt-6 absolute bottom-0 left-0 w-full">
          <Button onClick={() => close()} variant="secondary" color="blue" type="button">
            Buang perubahan
          </Button>
          <Button
            loading={isLoading}
            onClick={onSubmit}
            variant="primary"
            color="blue"
            type="button"
          >
            Selesai edit pelacakan stok
          </Button>
        </div>
      </div>
    </ModalDialog>
  );
}

export default EditTrackStock;
