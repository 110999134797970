import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import SelectBox from "components/atoms/SelectBox";
import Switch from "components/atoms/Switch";
import Checkbox from "components/atoms/Checkbox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Toggle from "components/atoms/Toggle";
import IconArrowUp from "components/atoms/Icons/Custom/icon new/Arrow";
import ModalPilihProduk from "./pilihProduk";
import TextCaption from "components/atoms/Text/TextCaption";
import { putData, getData } from "utils/fetchData";
import {
  setProduct,
  fetchProductInDiscount,
  fetchDiscount,
  fetchCategoryInProduct,
  setCategory,
  setNotif,
} from "features/Discount/actions";
import { useSelector, useDispatch } from "react-redux";
import { formatRupiah } from "utils/formatRupiah";
import { fetchLog, setNotifLog } from "features/ReportLog/actions";
import moment from "moment";

const DiscountSchema = yup.object().shape({
  name: yup.string().required("nama diskon tidak boleh kosong."),
});

export default function CreateDiscount({ show, close, title, discount, logStatus }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const productInDiscount = useSelector((state) => state.discount.productInDiscount);
  const categoryInDiscount = useSelector((state) => state.discount.categoryInDiscount);
  const [currency, setCurrency] = React.useState("rp");
  const [isCheckedAll, setIsCheckedAll] = React.useState({ status: false, count: 0 });
  const [modalBatal, setModalBatal] = React.useState(false);
  const [modalPilihProduk, setModalPilihProduk] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const { register, handleSubmit, errors, setError, clearError } = useForm({
    mode: "onBlur",
    validationSchema: DiscountSchema,
  });

  const [type, setType] = React.useState([
    {
      name: "Rp",
      status: true,
    },
    {
      name: "%",
      status: false,
    },
  ]);

  const [valueCheckbox, setValueCheckbox] = React.useState({
    label: "Semua lokasi",
    value: "",
    isChecked: true,
    require: false,
  });

  const [filterCategory, setFilterCategory] = React.useState({
    label: "Semua kategori",
    value: [],
    isChecked: true,
    all: true,
  });

  const [field, setField] = React.useState({
    name: "",
    value: "",
    type: 1,
    products: [],
    locations: [],
    minCart: "",
    isMincart: false,
    minValue: "",
    isMinValue: false,
    maxValue: "",
    isMaxValue: false,
    isDays: false,
    days: [
      {
        day: "SENIN",
        isChecked: false,
        startTime: "00:00",
        endTime: "00:00",
      },
      {
        day: "SELASA",
        isChecked: false,
        startTime: "00:00",
        endTime: "00:00",
      },
      {
        day: "RABU",
        isChecked: false,
        startTime: "00:00",
        endTime: "00:00",
      },
      {
        day: "KAMIS",
        isChecked: false,
        startTime: "00:00",
        endTime: "00:00",
      },
      {
        day: "JUMAT",
        isChecked: false,
        startTime: "00:00",
        endTime: "00:00",
      },
      {
        day: "SABTU",
        isChecked: false,
        startTime: "00:00",
        endTime: "00:00",
      },
      {
        day: "MINGGU",
        isChecked: false,
        startTime: "00:00",
        endTime: "00:00",
      },
    ],
    isDuration: false,
    isDurationStart: false,
    isDurationEnd: false,
    startDate: null,
    endDate: null,
  });

  console.log("field.startDate");
  console.log(field.startDate);

  const fetchOneDiscount = async () => {
    const res = await getData(`v2/discount/${discount}`);

    console.log("res >>>>>>");
    console.log(res);
    if (res.data.code === 200) {
      let checkType = [];
      if (res.data.discount.type === 1) {
        checkType.push(
          {
            name: "Rp",
            status: true,
          },
          {
            name: "%",
            status: false,
          }
        );
        setCurrency("rp");
      } else {
        checkType.push(
          {
            name: "Rp",
            status: false,
          },
          {
            name: "%",
            status: true,
          }
        );
        setCurrency("%");
      }

      setType(checkType);

      //start: get all locations
      let {
        data: { outlets },
      } = await getData("outlets");
      let select_box = [];
      outlets.forEach((item) => {
        select_box.push({
          label: item.name,
          value: item.id,
          isChecked: false,
        });
      });
      // check get location and single discount
      res.data.discount.locations.forEach((outlet) => {
        select_box.forEach((data) => {
          if (data.value === outlet.outletId) {
            data.isChecked = true;
          }
        });
      });

      let sum = 0;
      select_box.forEach((obj) => {
        if (obj.isChecked === true) sum += 1;
      });

      setValueCheckbox({
        label: sum === select_box.length ? "Semua lokasi" : `${sum} lokasi`,
        value: "sesuaikan kebutuhan",
        isChecked: sum === select_box.length ? true : false,
        require: sum === 0 ? true : false,
      });

      // start: get all product in discount
      let checkProductInDiscount = [];

      let _temp = [...productInDiscount];
      res.data.discount.products.forEach((product) => {
        _temp.forEach((data, index) => {
          if (product.productId === data.id) {
            checkProductInDiscount.push(data);
            _temp[index]["isChecked"] = true;
            dispatch(setProduct(_temp));
          }
        });
      });

      let checkDayInDiscount = [...field.days];
      // check days in discount product
      res.data.discount.days &&
        res.data?.discount?.days.forEach((dayInDiscount) => {
          checkDayInDiscount.forEach((data, index) => {
            console.log("data");
            console.log(data);
            if (dayInDiscount.day === data.day) {
              checkDayInDiscount[index]["isChecked"] = true;
              checkDayInDiscount[index]["startTime"] = dayInDiscount.startTime;
              checkDayInDiscount[index]["endTime"] = dayInDiscount.endTime;
            }
          });
        });

      setField({
        ...field,
        name: res.data.discount.name,
        value: res.data.discount.value,
        type: res.data.discount.type,
        locations: select_box,
        isDays: res.data.discount.days ? true : false,
        days: checkDayInDiscount,
        products: checkProductInDiscount,
        isMinValue: res.data.discount.minValue ? true : false,
        minValue: res.data.discount.minValue,
        isMincart: res.data.discount.minCart ? true : false,
        minCart: res.data.discount.minCart,
        isMaxValue: res.data.discount.maxValue ? true : false,
        maxValue: res.data.discount.maxValue,
        isDuration: true,
        isDurationStart: true,
        isDurationEnd: true,
        startDate: moment(res.data.discount.startDate).format("YYYY-MM-DDThh:mm"),
        endDate: moment(res.data.discount.endDate).format("YYYY-MM-DDThh:mm"),
      });
    }
  };

  React.useEffect(() => {
    // getLocation();
    dispatch(fetchProductInDiscount());
    dispatch(fetchCategoryInProduct());
    fetchOneDiscount();
  }, []);

  const onChange = (e) => {
    let name = e.target.name,
      value = e.target.value;
    if (name === "status") {
      value = e.target.checked ? 1 : 0;
    }
    setField({ ...field, [name]: value });
  };

  const onCurrencyInput = (value, name) => {
    setField({ ...field, [name]: value });
  };

  const onClick = (data) => {
    const typeOld = [...type];
    typeOld.forEach((type) => {
      if (type.name === data.name) {
        type.status = true;
      } else {
        type.status = false;
      }
    });
    setType(typeOld);

    let check = data.name === "%" ? "%" : "rp";
    setCurrency(check);
    setField({ ...field, type: data.name === "%" ? 2 : 1, value: "0" });
  };

  const onSelectLocation = (data) => {
    let check = [];
    for (let i = 0; i < field.locations.length; i++) {
      if (field.locations[i].value === data.value) {
        check.push({
          ...field.locations[i],
          isChecked: data.isChecked ? (data.isChecked = false) : (data.isChecked = true),
        });
      } else {
        check.push(field.locations[i]);
      }
    }
    setField({ ...field, locations: check });
    let sum = 0;
    check.forEach((obj) => {
      if (obj.isChecked === true) sum += 1;
    });

    setValueCheckbox({
      label: sum === field.locations.length ? "Semua lokasi" : `${sum} lokasi`,
      value: "sesuaikan kebutuhan",
      isChecked: sum === field.locations.length ? true : false,
      require: sum === 0 ? true : false,
    });

    if (sum === 0) {
      setError("locations", "locations", "lokasi tidak boleh kosong.");
    } else {
      clearError("locations");
    }
  };

  const onCheckboxProduct = (e) => {
    let _temp = [...productInDiscount];

    _temp.forEach((prev) => {
      if (prev.id === e.target.value) {
        prev.isChecked = e.target.checked;
      }
    });

    let sum = 0;
    _temp.forEach((obj) => {
      if (obj.isChecked === true) sum += 1;
    });

    setIsCheckedAll({
      ...isCheckedAll,
      status: sum === productInDiscount.length ? true : false,
      count: sum,
    });

    dispatch(setProduct(_temp));
  };

  const onCheckboxDays = (e, index) => {
    let _temp = { ...field };
    _temp["days"][index]["isChecked"] = e.target.checked;
    setField(_temp);
  };

  const filterProduct = (e) => {
    setSearch(e.target.value);
    // dispatch(setFilterListVariant(e.target.value));
  };

  const onSelectProduct = () => {
    let check = [];
    productInDiscount.forEach((data) => {
      if (data.isChecked) {
        check.push(data);
      }
    });
    setField({ ...field, products: check });
  };

  const onRemoveProduct = (data) => {
    /** start uncheckbox */
    let check = [];
    for (let i = 0; i < productInDiscount.length; i++) {
      if (productInDiscount[i].id === data.id) {
        check.push({
          ...productInDiscount[i],
          isChecked: false,
        });
      } else {
        check.push(productInDiscount[i]);
      }
    }
    dispatch(setProduct(check));

    let products = [...field.products];
    var removeIndex = products
      .map(function (item) {
        return item.id;
      })
      .indexOf(data.id);
    products.splice(removeIndex, 1);
    setField({ ...field, products: products });
    /** end delete variant  */
  };

  const onChangeDays = (e, index) => {
    let _temp = { ...field };
    const { name, value } = e.target;
    _temp["days"][index][name] = value;
    setField(_temp);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      let products = [],
        locations = [],
        days = [];
      field.products.forEach((data) => {
        products.push(data.id);
      });
      field.locations.forEach((data) => {
        if (data.isChecked) locations.push(data.value);
      });
      const payload = {
        name: field.name,
        value: Number(field.value),
        type: field.type,
        products,
        locations,
      };

      if (field.isMincart) payload.minCart = field.minCart;
      if (field.isMinValue) payload.minValue = field.minValue;
      if (field.isMaxValue) payload.maxValue = field.maxValue;
      if (field.isDays) {
        field.days.forEach((data) => {
          if (data.isChecked) days.push(data);
        });

        payload.days = days;
      }
      if (field.isDurationStart) {
        payload.isDurationStart = field.isDurationStart;
        payload.startDate = `${moment(field.startDate).format("YYYY-MM-DD hh:mm:ss")}`;
      }
      if (field.isDurationEnd) {
        payload.isDurationEnd = field.isDurationEnd;
        payload.endDate = `${moment(field.endDate).format("YYYY-MM-DD hh:mm:ss")}`;
      }

      const res = await putData(`v2/discount/${discount}`, payload);

      if (res.data.code === 200) {
        const notif = {
          message: `Diskon "${res.data.discount.name}" berhasil diubah`,
          type: "primary",
          status: "success",
          id: discount,
          toggle: true,
        };
        dispatch(setNotif(notif));
        logStatus && dispatch(setNotifLog(notif));
        logStatus && dispatch(fetchLog());
        setLoading(false);
        close();
        setField({
          ...field,
          name: "",
          value: "",
          type: 1,
          products: [],
          locations: [],
          minCart: "",
          isMincart: false,
          minValue: "",
          isMinValue: false,
          maxValue: "",
          isMaxValue: false,
          isDays: false,
          days: [
            {
              day: "SENIN",
              isChecked: false,
              startTime: "00:00",
              endTime: "00:00",
            },
            {
              day: "SELASA",
              isChecked: false,
              startTime: "00:00",
              endTime: "00:00",
            },
            {
              day: "RABU",
              isChecked: false,
              startTime: "00:00",
              endTime: "00:00",
            },
            {
              day: "KAMIS",
              isChecked: false,
              startTime: "00:00",
              endTime: "00:00",
            },
            {
              day: "JUMAT",
              isChecked: false,
              startTime: "00:00",
              endTime: "00:00",
            },
            {
              day: "SABTU",
              isChecked: false,
              startTime: "00:00",
              endTime: "00:00",
            },
            {
              day: "MINGGU",
              isChecked: false,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          isDuration: false,
          isDurationStart: false,
          isDurationEnd: false,
          startDate: new Date(),
          endDate: new Date(),
        });
        dispatch(fetchDiscount());
      }
    } catch (err) {
      setLoading(false);
      console.log("err.response");
      console.log(err.response);
    }
  };

  const productOnfilter = React.useMemo(() => {
    // if (!search) return productInDiscount;

    let _temp = [],
      category = [];

    categoryInDiscount.forEach((categoryInDiscount) => {
      if (categoryInDiscount.isChecked) category.push(categoryInDiscount.value);
    });

    console.log("productInDiscount >>");
    console.log(productInDiscount);

    category.forEach((category) => {
      productInDiscount.forEach((productInDiscount) => {
        console.log(productInDiscount.category?.id);
        if (
          productInDiscount.name.toLowerCase().includes(search.toLowerCase()) &&
          productInDiscount?.category?.id === category
        ) {
          _temp.push(productInDiscount);
        }
      });
    });

    console.log("productInDiscount >>> filter");
    console.log(productInDiscount);

    // dispatch(setProduct(_temp))

    return _temp;

    // return productInDiscount.filter((productInDiscount) => {
    //   return productInDiscount.name.toLowerCase().includes(search.toLowerCase());
    // });
  }, [search, productInDiscount, categoryInDiscount]);

  const onChangeSelectAllProduct = (e) => {
    productInDiscount.forEach((data) => {
      data.isChecked = e.target.checked;
    });

    let sum = 0;
    productInDiscount.forEach((obj) => {
      if (obj.isChecked === true) sum += 1;
    });

    setIsCheckedAll({ ...isCheckedAll, count: sum, status: e.target.checked });
  };

  const handleFilterCategory = (data, allCheckbox) => {
    let check = [],
      value = [];
    if (allCheckbox) {
      if (filterCategory.isChecked) {
        for (let i = 0; i < categoryInDiscount.length; i++) {
          check.push({ ...categoryInDiscount[i], isChecked: false });
        }
        setFilterCategory({
          ...filterCategory,
          label: "0 Kategori",
          value: value,
          isChecked: false,
          all: false,
        });
        dispatch(setCategory(check));
      } else {
        for (let i = 0; i < categoryInDiscount.length; i++) {
          check.push({ ...categoryInDiscount[i], isChecked: true });
          value.push(categoryInDiscount[i].value);
        }
        setFilterCategory({
          ...filterCategory,
          label: "Semua kategori",
          value: value,
          isChecked: true,
          all: true,
        });
        dispatch(setCategory(check));
      }
    } else {
      for (let i = 0; i < categoryInDiscount.length; i++) {
        if (categoryInDiscount[i].value === data.value) {
          check.push({
            ...categoryInDiscount[i],
            isChecked: data.isChecked ? (data.isChecked = false) : (data.isChecked = true),
          });
          value.push(categoryInDiscount[i].value);
        } else {
          check.push(categoryInDiscount[i]);
        }
      }

      dispatch(setCategory(check));
      let sum = 0;
      check.forEach((obj) => {
        if (obj.isChecked === true) sum += 1;
      });

      setFilterCategory({
        label: sum === categoryInDiscount.length ? "Semua kategori" : `${sum} kategori`,
        value: value,
        isChecked: sum === categoryInDiscount.length ? true : false,
      });
    }
  };

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title={title}
      anotherAction={
        <>
          <span className="text-blue-700 font-semibold">{field.name}</span>
          <Button
            loading={loading}
            disabled={
              field.name === "" || !field.locations.length || field.value === "" ? true : false
            }
            onClick={() => (!loading ? onSubmit() : null)}
            variant="primary"
            color="blue"
            type="submit"
          >
            Selesai edit diskon
          </Button>
        </>
      }
    >
      <div className="container-body flex items-start justify-center bg-white">
        <div className="w-728px max-w-728px">
          <h3 className="heading-3 mb-4">Informasi diskon</h3>

          <InputTextLargeWithLabel
            spacing="w-36"
            className="w-full"
            label="Nama diskon"
            status={errors?.name ? "error" : "default"}
            register={register}
            name="name"
            onChange={onChange}
            value={field.name}
          >
            {errors.name && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.name?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
          <div className="relative">
            <InputTextLargeWithLabel
              onChange={(e) => onCurrencyInput(e, "value")}
              currency={currency}
              placeholder={currency === "%" ? "Cth: 10 %" : "Cth: Rp. 10,000"}
              spacing="w-36"
              className="w-full mt-1"
              label="Jumlah diskon"
              status={errors?.value ? "error" : "default"}
              register={register}
              name="name"
              value={field.value}
            >
              {errors.value && (
                <div className="flex items-center mt-1">
                  <TextCaption status="error" message={errors.value?.message} />
                </div>
              )}
            </InputTextLargeWithLabel>
            <Switch
              className="absolute top-1/2 transform -translate-y-1/2 right-5"
              type="primary"
              buttons={type}
              content="label"
              onClick={onClick}
            />
          </div>
          <div className="flex items-center mt-1">
            <div className="dropdown-category w-full">
              <SelectBox
                className="mt-3"
                spacing="w-32"
                type="text"
                value={valueCheckbox}
                label="Lokasi tersedia"
                options={field.locations}
                onChange={onSelectLocation}
                type="checkbox"
              >
                {errors.locations && (
                  <TextCaption status="error" message={errors.outlets?.message} />
                )}
              </SelectBox>
            </div>
          </div>
          <h3 className="heading-3 mt-20">Diskon ke produk</h3>
          <p className="mb-4 mt-1 text-2 text-gray-03">
            Pilih produk atau kategori untuk diaplikasikan diskon.
          </p>
          <div className="rounded-lg bg-gray-05 px-6 py-8">
            <ul
              className="bg-white rounded-lg overflow-y-scroll scroll-hidden"
              style={{ height: "52vh" }}
            >
              {field.products.map((product, i) => {
                return (
                  <li className="py-2 px-6" key={i}>
                    <div className="flex items-center justify-between">
                      <p className="text-dark">{product.name}</p>
                      <div className="flex items-center">
                        <p className="text-2 text-gray-02 mr-6">{formatRupiah(product.price)}</p>
                        <Button
                          variant="secondary"
                          color="red"
                          onClick={() => onRemoveProduct(product)}
                        >
                          Hapus
                        </Button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            <Button
              variant="primary"
              className="mt-4 w-full"
              color="green"
              onClick={() => setModalPilihProduk(true)}
            >
              Pilih produk
            </Button>
          </div>
          <div className="w-full flex justify-center items-center my-16 cursor-pointer">
            <p className="text-blue-03 bld-text-2 mr-6">Pengaturan lanjutan</p>
            <IconArrowUp className="transform -rotate-90" fill="#1F4BFF" width="10" height="10" />
          </div>
          <h3 className="heading-3 mt-20">Peraturan diskon</h3>
          <p className="mb-4 mt-1 text-2 text-gray-03">
            Tambah peraturan untuk aktifkan diskon atau batas lainnnya.
          </p>
          <ul>
            <li>
              <div className="rounded-lg border border-gray-04 py-4 px-6 flex justify-between mt-4">
                <div>
                  <p className="text-gray-02 bold-text-2">Kuantitas minimal</p>
                  <p className="text-gray-03 text-2 mt-2">
                    Produk yang dipilih harus dibeli dalam jumlah minimal untuk diskon bisa aktif.
                  </p>
                </div>
                <div className="relative right-8 top-2">
                  <Toggle
                    size="lg"
                    type="primary"
                    value={field.isMincart}
                    name="isMincart"
                    checked={field.isMincart}
                    onChange={() => setField({ ...field, isMincart: !field.isMincart })}
                  />
                </div>
              </div>
              {field.isMincart && (
                <div className="ml-14 mt-1">
                  <InputTextLargeWithLabel
                    className="w-full"
                    label="Kuantitas minimal 4"
                    type="number"
                    name="minCart"
                    onChange={(e) => setField({ ...field, minCart: e.target.value })}
                    value={field.minCart}
                  ></InputTextLargeWithLabel>
                </div>
              )}
            </li>
            <li>
              <div className="rounded-lg border border-gray-04 py-4 px-6 flex justify-between mt-4">
                <div>
                  <p className="text-gray-02 bold-text-2">Pembelian minimal</p>
                  <p className="text-gray-03 text-2 mt-2">
                    Total struk harus memenuhi pembelian minimal untuk diskon bisa aktif.
                  </p>
                </div>
                <div className="relative right-8 top-2">
                  <Toggle
                    size="lg"
                    type="primary"
                    value={field.isMinValue}
                    name="isMinValue"
                    checked={field.isMinValue}
                    onChange={() => setField({ ...field, isMinValue: !field.isMinValue })}
                  />
                </div>
              </div>
              {field.isMinValue && (
                <div className="ml-14 mt-1">
                  <InputTextLargeWithLabel
                    className="w-full"
                    label="Nominal minimal"
                    type="number"
                    name="minValue"
                    onChange={(e) => setField({ ...field, minValue: e.target.value })}
                    value={field.minValue}
                  >
                    {/* {errors.name && (
                <div className="flex items-center mt-1">
                  <TextCaption status="error" message={errors.name?.message} />
                </div>
              )} */}
                  </InputTextLargeWithLabel>
                </div>
              )}
            </li>
            <li>
              <div className="rounded-lg border border-gray-04 py-4 px-6 flex justify-between mt-4">
                <div>
                  <p className="text-gray-02 bold-text-2">
                    Batas nominal diskon (Hanya untuk diskon persen)
                  </p>
                  <p className="text-gray-03 text-2 mt-2">Nominal maksimal diskon per produk.</p>
                </div>
                <div className="relative right-8 top-2">
                  <Toggle
                    size="lg"
                    type="primary"
                    value={field.isMaxValue}
                    name="isMaxValue"
                    checked={field.isMaxValue}
                    onChange={() => setField({ ...field, isMaxValue: !field.isMaxValue })}
                  />
                </div>
              </div>
              {field.isMaxValue && (
                <div className="ml-14 mt-1">
                  <InputTextLargeWithLabel
                    className="w-full"
                    label="Batas nominal"
                    type="number"
                    name="maxValue"
                    onChange={(e) => setField({ ...field, maxValue: e.target.value })}
                    value={field.maxValue}
                  ></InputTextLargeWithLabel>
                </div>
              )}
            </li>
          </ul>
          <h3 className="heading-3 mt-20">Jadwal diskon</h3>
          <ul>
            <li>
              <div className="rounded-lg border border-gray-04 py-4 px-6 flex justify-between mt-4">
                <div>
                  <p className="text-gray-02 bold-text-2">Jadwal diskon</p>
                  <p className="text-gray-03 text-2 mt-2">
                    Tetapkan hari dan jam diskon ini tersedia.
                  </p>
                </div>
                <div className="relative right-8 top-2">
                  <Toggle
                    size="lg"
                    type="primary"
                    value={field.isDays}
                    name="isDays"
                    checked={field.isDays}
                    onChange={() => setField({ ...field, isDays: !field.isDays })}
                  />
                </div>
              </div>
              {field.isDays && (
                <ul className="mt-1">
                  {field.days.map((data, i) => {
                    return (
                      <li key={i}>
                        <div className="grid grid-cols-3">
                          <div className="col-span-1 h-full">
                            <div className="border py-4 px-6 bg-gray-05 flex items-center h-full">
                              <Checkbox
                                type="default"
                                checked={data.isChecked}
                                name="checkbox"
                                value={data.isChecked}
                                className="relative -top-2"
                                onChange={(e) => onCheckboxDays(e, i)}
                              />
                              <p className="text-2 text-dark pl-8">{data.day}</p>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div className="border py-4 px-6 bg-white flex items-center justify-between">
                              <p className="text-2 text-gray-03">Jam mulai</p>
                              <input
                                type="time"
                                className="bg-transparent"
                                value={data.startTime}
                                name={"startTime"}
                                disabled={data.isChecked ? false : true}
                                onChange={(e) => onChangeDays(e, i)}
                              />
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div className="border py-4 px-6 bg-white flex items-center justify-between">
                              <p className="text-2 text-gray-03">Jam berakhir</p>
                              <input
                                type="time"
                                className="bg-transparent"
                                value={data.endTime}
                                name={"endTime"}
                                disabled={data.isChecked ? false : true}
                                onChange={(e) => onChangeDays(e, i)}
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
            <li>
              <div className="rounded-lg border border-gray-04 py-4 px-6 flex justify-between mt-4">
                <div>
                  <p className="text-gray-02 bold-text-2">Durasi diskon</p>
                  <p className="text-gray-03 text-2 mt-2">
                    Pilih tanggal mulai dan akhir untuk diskon ini tersedia.
                  </p>
                </div>
                <div className="relative right-8 top-2">
                  <Toggle
                    size="lg"
                    type="primary"
                    value={field.isDuration}
                    name="isDuration"
                    checked={field.isDuration}
                    onChange={() => setField({ ...field, isDuration: !field.isDuration })}
                  />
                </div>
              </div>
              {field.isDuration && (
                <ul className="mt-1">
                  <li>
                    <div className="grid grid-cols-4">
                      <div className="col-span-1 h-full">
                        <div className="border py-4 px-6 bg-gray-05 flex items-center h-full">
                          <Checkbox
                            className="relative -top-2"
                            checked={field.isDurationStart}
                            value={field.isDurationStart}
                            name="isDurationStart"
                            onChange={(e) => {
                              setField({ ...field, isDurationStart: e.target.checked });
                            }}
                          />
                          <p className="text-2 text-dark pl-8">Tanggal mulai</p>
                        </div>
                      </div>
                      <div className="col-span-1 h-full">
                        <div className="border flex items-center px-2 bg-white h-full">
                          <input
                            type="datetime-local"
                            className="w-full"
                            value={field.startDate}
                            name={"startDate"}
                            onChange={(e) => {
                              setField({ ...field, startDate: e.target.value });
                            }}
                            disabled={field.isDurationStart ? false : true}
                          />
                        </div>
                      </div>
                      {/* <div className="col-span-1 h-full">
                      <div className="border py-4 px-6 bg-white h-full flex items-center">
                        <p className="text-2 text-gray-03">Jam mulai</p>
                      </div>
                    </div>
                    <div className="col-span-1 h-full">
                      <div className="border py-4 px-2 bg-white flex items-center justify-between h-full">
                        <p className="text-2 text-xs text-gray-03">Jam mulai</p>
                        <input type="time" className="bg-transparent" />
                      </div>
                    </div> */}
                    </div>
                  </li>
                  <li>
                    <div className="grid grid-cols-4">
                      <div className="col-span-1 h-full">
                        <div className="border py-4 px-6 bg-gray-05 flex items-center h-full">
                          <Checkbox
                            className="relative -top-2"
                            value={field.isDurationEnd}
                            checked={field.isDurationEnd}
                            name="isDurationEnd"
                            onChange={(e) => {
                              setField({ ...field, isDurationEnd: e.target.checked });
                            }}
                          />
                          <p className="text-2 text-dark pl-8">Tanggal akhir</p>
                        </div>
                      </div>
                      <div className="col-span-1 h-full">
                        <div className="border flex items-center px-2 bg-white h-full">
                          <input
                            type="datetime-local"
                            className="w-full"
                            value={field.endDate}
                            name={"endDate"}
                            onChange={(e) => {
                              setField({ ...field, endDate: e.target.value });
                            }}
                            disabled={field.isDurationEnd ? false : true}
                          />
                        </div>
                      </div>
                      {/* <div className="col-span-1 h-full">
                      <div className="border py-4 px-6 bg-white h-full flex items-center">
                        <p className="text-2 text-gray-03">Jam akhir</p>
                      </div>
                    </div>
                    <div className="col-span-1 h-full">
                      <div className="border py-4 px-2 bg-white flex items-center justify-between h-full">
                        <p className="text-2 text-xs text-gray-03">Jam akhir</p>
                        <input type="time" className="bg-transparent" />
                      </div>
                    </div> */}
                    </div>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
        {/* modal batal */}
        {/* START: ALERT BATAL */}
        <ModalDialog
          show={modalBatal}
          onClose={() => setModalBatal(false)}
          size={"alert"}
          title={<h1 className="text-dark heading-3">Batal tambah diskon?</h1>}
        >
          <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
            <p className="text-2">
              Semua perubahan dan akan hilang saat Anda keluar sebelum menyelesaikan penambahan
              diskon.
            </p>
          </div>
          <div className="px-5 flex justify-between items-center bg-white">
            <Button
              variant="secondary"
              color="blue"
              className="my-5"
              type="button"
              onClick={() => setModalBatal(false)}
            >
              Kembali tambah diskon
            </Button>
            <Button
              // onClick={() => history.push("/product/inventory/default")}
              variant="primary"
              color="red"
              className="my-5"
              type="button"
            >
              Batal dan buang perubahan
            </Button>
          </div>
        </ModalDialog>
        {/* END: ALERT BATAL */}
        <ModalPilihProduk
          handleFilterCategory={handleFilterCategory}
          filterCategory={filterCategory}
          categoryInDiscount={categoryInDiscount}
          productOnfilter={productOnfilter}
          isCheckedAll={isCheckedAll}
          onChangeSelectAllProduct={(e) => onChangeSelectAllProduct(e)}
          show={modalPilihProduk}
          close={() => setModalPilihProduk(false)}
          search={search}
          filterProduct={filterProduct}
          onSelectProduct={() => onSelectProduct()}
          onCheckboxProduct={(e) => onCheckboxProduct(e)}
        />
      </div>
    </ModalDialog>
  );
}
