import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import SearcBar from "components/atoms/SearchBar";
import Button from "components/atoms/Button";
import Checkbox from "components/atoms/Checkbox";
export default function ShowCategory({
  show,
  close,
  data,
  onCheckboxCategory,
  onSelectCategory
}) {
  const [list, setList] = React.useState(data)

  const [keyword, setKeyword] = React.useState("");

  const onFilter = (e) => {
    let check = [...data]
    const search = e.target.value.toLowerCase()
    check = check.filter(function (category) {
      return (
        category.categoryName.toLowerCase().indexOf(search) !== -1
      )
    })

    setKeyword(e.target.value)
    setList(check)
  }

  React.useEffect(() => {
    const setValueList = () => {
      setList(data)
    }
    setValueList()
  }, [data])


  return (
    <div>
      <ModalDialog
        size={"alert"}
        show={show}
        onClose={close}
        title="Pilih kategori"
      >
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <SearcBar
            value={keyword}
            name={keyword}
            onChange={(e) => onFilter(e)}
            onClick={() => setKeyword("")}
            placeholder="Cari kategori"
          />
          <div
            className="bg-white border rounded-lg p-4 mt-6 overflow-y-scroll"
            style={{ maxHeight: "48vh" }}
          >
            <ul>
              {list.map((data, index) => {
                return (
                  <li key={index}>
                    <div className="flex items-center">
                      <div className="relative">
                        <Checkbox
                          className="absolute top-2px"
                          type="default"
                          checked={data.isCheck}
                          name="checkbox"
                          value={data.isCheck}
                          onChange={(e) => onCheckboxCategory(e, index)}
                        />
                        <p className="text-2 pl-8">{data.categoryName}</p>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className="px-5 flex justify-end items-center bg-white">
          <Button
            variant="primary"
            color="blue"
            className="my-5"
            onClick={onSelectCategory}
          >
            Selesai
          </Button>
        </div>
      </ModalDialog>
    </div>
  );
}
