import * as React from "react";
import ImageField from "components/atoms/ImageField";
import TextCaption from "components/atoms/Text/TextCaption";

export default function ImageFields() {
  const [value, setValue] = React.useState("");

  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="my-3">
        <ImageField
          type="default"
          label="Image field"
          action
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        />
      </div>
      <div className="my-3">
        <ImageField
          type="disabled"
          disabled
          action
          label="Image field"
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        />
      </div>
      <div className="my-3">
        <ImageField
          type="error"
          label="Image field"
          action
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        >
          <TextCaption status="error" message="This field is required" />
        </ImageField>
      </div>
      <div className="my-3">
        <ImageField
          type="success"
          label="Image field"
          action
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        >
          <TextCaption status="success" message="This field is required" />
        </ImageField>
      </div>
      <div className="my-3">
        <ImageField
          type="warning"
          label="Image field"
          action
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        >
          <TextCaption status="warning" message="This field is required" />
        </ImageField>
      </div>
      {/* no label */}
      <div className="mb-3 mt-20">
        <ImageField
          type="default"
          action
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        />
      </div>
      <div className="my-3">
        <ImageField
          type="disabled"
          action
          disabled
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        />
      </div>
      <div className="my-3">
        <ImageField
          type="error"
          action
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        >
          <TextCaption status="error" message="This field is required" />
        </ImageField>
      </div>
      <div className="my-2">
        <ImageField
          type="success"
          action
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        >
          <TextCaption status="success" message="This field is required" />
        </ImageField>
      </div>
      <div className="my-2">
        <ImageField
          type="warning"
          action
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        >
          <TextCaption status="warning" message="This field is required" />
        </ImageField>
      </div>
      {/* no label and button */}
      <div className="mb-2 mt-20">
        <ImageField
          type="default"
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        />
      </div>
      <div className="my-2">
        <ImageField
          type="disabled"
          disabled
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        />
      </div>
      <div className="my-2">
        <ImageField
          type="error"
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        >
          <TextCaption status="error" message="This field is required" />
        </ImageField>
      </div>
      <div className="my-2">
        <ImageField
          type="success"
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        >
          <TextCaption status="success" message="This field is required" />
        </ImageField>
      </div>
      <div className="my-2">
        <ImageField
          type="warning"
          value={value}
          name="image"
          onChange={(e) => setValue(e.target.files[0])}
          onClick={() => setValue("")}
        >
          <TextCaption status="warning" message="This field is required" />
        </ImageField>
      </div>
    </div>
  );
}
