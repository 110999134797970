import React from "react";

export default function Add({ className, width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1667 7.33341H9.16675V3.33341C9.16675 3.1566 9.09651 2.98703 8.97149 2.86201C8.84646 2.73699 8.67689 2.66675 8.50008 2.66675C8.32327 2.66675 8.1537 2.73699 8.02868 2.86201C7.90365 2.98703 7.83341 3.1566 7.83341 3.33341V7.33341H3.83341C3.6566 7.33341 3.48703 7.40365 3.36201 7.52868C3.23699 7.6537 3.16675 7.82327 3.16675 8.00008C3.16675 8.17689 3.23699 8.34646 3.36201 8.47149C3.48703 8.59651 3.6566 8.66675 3.83341 8.66675H7.83341V12.6667C7.83341 12.8436 7.90365 13.0131 8.02868 13.1382C8.1537 13.2632 8.32327 13.3334 8.50008 13.3334C8.67689 13.3334 8.84646 13.2632 8.97149 13.1382C9.09651 13.0131 9.16675 12.8436 9.16675 12.6667V8.66675H13.1667C13.3436 8.66675 13.5131 8.59651 13.6382 8.47149C13.7632 8.34646 13.8334 8.17689 13.8334 8.00008C13.8334 7.82327 13.7632 7.6537 13.6382 7.52868C13.5131 7.40365 13.3436 7.33341 13.1667 7.33341Z"
        fill={fill}
      />
    </svg>
  );
}

Add.defaultProps = {
  width: "16",
  height: "16",
  fill: "white",
};
