import React from "react";
import propTypes from "prop-types";
import "assets/stylesheets/inputButton.css";
import IconImage from "components/atoms/Icons/Custom/Image";

function ImageField(props) {
  const {
    action,
    children,
    value,
    onChange,
    name,
    onClick,
    labelButton,
    label,
    disabled,
    type,
    spacing,
    labelStyle,
  } = props;

  const className = [props.className];

  if (type === "default") {
    return (
      <div
        className={`group flex items-center text-gray-04 hover:text-blue-02`}
      >
        {label && (
          <span
            className={`currentColor bold-text-2 duration-200 ${spacing}`}
            style={labelStyle}
          >
            {label}
          </span>
        )}
        <div
          className={`group-hover:bg-blue-05 group-hover:border-blue-03 relative flex items-center justify-center w-166 h-166 rounded-lg border border-gray-04 overflow-hidden cursor-pointer p-6`}
          style={{ minWidth: "166px" }}
        >
          {value === "" ? (
            <IconImage
              className={
                "absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-53 min-w-53 w-53 duration-200 cursor-pointer"
              }
              fill={"currentColor"}
            />
          ) : (
            <img
              className="w-5/6"
              src={value[0] === "h" ? value : URL.createObjectURL(value)}
            />
          )}
          <input
            name={name}
            type="file"
            className="absolute inset-0 opacity-0 z-10 cursor-pointer"
            onChange={onChange}
            disabled={disabled}
          />
        </div>
        {action && value === "" ? (
          <div className="flex items-center w-full justify-end">
            {" "}
            <input name={name} className="inputBtn" type="file" onChange={onChange} />{" "}
          </div>
        ) : (
          <div className="flex items-center w-full justify-end">
            <React.Fragment>
              <button
                className="mr-2 btn-secondary bg-red-05 text-red-03 hover:text-red-02 active:text-red-01 duration-200 whitespace-nowrap"
                onClick={onClick}
              >
                {labelButton}
              </button>
              <input
                className="inputEditBtn relative h-10"
                type="file"
                name={name}
                onChange={onChange}
              />
            </React.Fragment>
          </div>
        )}
      </div>
    );
  }

  if (type === "disabled") {
    return (
      <div className={`flex items-center text-gray-04`}>
        {label && (
          <span className="currentColor duration-200 mr-6">{label}</span>
        )}
        <div className="relative flex items-center justify-center w-166 h-166 rounded-lg border border-gray-04 overflow-hidden cursor-pointer p-2 bg-gray-05">
          {value === "" ? (
            <IconImage
              className={
                "absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-53 min-w-53 w-53 duration-200 cursor-pointer"
              }
              fill={"currentColor"}
            />
          ) : (
            <img className="w-5/6" src={URL.createObjectURL(value)} />
          )}
          <input
            name={name}
            type="file"
            className="absolute inset-0 opacity-0 z-10 cursor-pointer"
            onChange={onChange}
            disabled={disabled}
          />
        </div>
        {action && (
          <div className="flex items-center ml-13">
            <button
              className="mr-2 btn-secondary bg-red-05 text-red-04"
              disabled={disabled}
            >
              Button
            </button>
            <button
              className="btn-secondary bg-blue-05 text-blue-04"
              disabled={disabled}
            >
              Button
            </button>
          </div>
        )}
      </div>
    );
  }

  if (type === "error" || type === "success" || type === "warning") {
    return (
      <div className={`flex items-center text-gray-04 hover:text-blue-02`}>
        {label && (
          <span className="currentColor duration-200 mr-6">{label}</span>
        )}
        <div>
          <div
            className={`relative flex items-center justify-center w-166 h-166 rounded-lg border ${type === "error" && "border-red-03"
              } ${type === "success" && "border-green-03"} ${type === "warning" && "border-orange-03"
              }  overflow-hidden cursor-pointer p-6`}
          >
            {value === "" ? (
              <IconImage
                className={
                  "absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-53 min-w-53 w-53 duration-200 cursor-pointer"
                }
                fill={"currentColor"}
              />
            ) : (
              <img className="w-5/6" src={URL.createObjectURL(value)} />
            )}
            <input
              name={name}
              type="file"
              className="absolute inset-0 opacity-0 z-10 cursor-pointer"
              onChange={onChange}
              disabled={disabled}
            />
          </div>
          {children}
        </div>
        {action && value === "" ? (
          <div className="flex items-center ml-13">
            {" "}
            <input className="inputBtn" type="file" onChange={onChange} />{" "}
          </div>
        ) : (
          <div className="flex items-center ml-13">
            <React.Fragment>
              <button
                className="mr-2 btn-secondary bg-red-05 text-red-03 hover:text-red-02 active:text-red-01 duration-200 whitespace-nowrap"
                onClick={onClick}
              >
                {labelButton}
              </button>
              <input
                className="inputEditBtn relative h-10"
                type="file"
                name={name}
                onChange={onChange}
              />
            </React.Fragment>
          </div>
        )}
      </div>
    );
  }
}

ImageField.defaultProps = {
  value: "",
  disabled: false,
  type: "active",
  action: false,
  spacing: "mr-6",
  labelButton: "Hapus foto",
};

ImageField.propTypes = {
  spacing: propTypes.string,
  name: propTypes.string,
  label: propTypes.string,
  type: propTypes.string,
  onChange: propTypes.func,
  onClick: propTypes.func,
  type: propTypes.string,
  disabled: propTypes.bool,
  action: propTypes.bool,
};

export default ImageField;
