export const Transactions = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.71 9.28994L17.71 5.28994C17.6168 5.1967 17.5061 5.12274 17.3843 5.07228C17.2624 5.02182 17.1319 4.99585 17 4.99585C16.7337 4.99585 16.4783 5.10164 16.29 5.28994C16.1017 5.47825 15.9959 5.73364 15.9959 5.99994C15.9959 6.26624 16.1017 6.52164 16.29 6.70994L18.59 8.99994H7.00002C6.7348 8.99994 6.48045 9.1053 6.29291 9.29283C6.10537 9.48037 6.00002 9.73472 6.00002 9.99994C6.00002 10.2652 6.10537 10.5195 6.29291 10.707C6.48045 10.8946 6.7348 10.9999 7.00002 10.9999H21C21.1974 10.999 21.3902 10.9396 21.5539 10.8292C21.7176 10.7189 21.845 10.5626 21.92 10.3799C21.9966 10.1978 22.0175 9.99712 21.9801 9.80313C21.9428 9.60914 21.8488 9.43057 21.71 9.28994ZM17 12.9999H3.00002C2.80259 13.0009 2.60988 13.0603 2.44616 13.1707C2.28244 13.281 2.15504 13.4373 2.08002 13.6199C2.00344 13.8021 1.98252 14.0028 2.01989 14.1968C2.05726 14.3907 2.15126 14.5693 2.29002 14.7099L6.29002 18.7099C6.38298 18.8037 6.49358 18.8781 6.61544 18.9288C6.7373 18.9796 6.86801 19.0057 7.00002 19.0057C7.13203 19.0057 7.26274 18.9796 7.38459 18.9288C7.50645 18.8781 7.61705 18.8037 7.71002 18.7099C7.80375 18.617 7.87814 18.5064 7.92891 18.3845C7.97968 18.2627 8.00582 18.132 8.00582 17.9999C8.00582 17.8679 7.97968 17.7372 7.92891 17.6154C7.87814 17.4935 7.80375 17.3829 7.71002 17.2899L5.41002 14.9999H17C17.2652 14.9999 17.5196 14.8946 17.7071 14.707C17.8947 14.5195 18 14.2652 18 13.9999C18 13.7347 17.8947 13.4804 17.7071 13.2928C17.5196 13.1053 17.2652 12.9999 17 12.9999Z"
        fill="currentColor"
      />
    </svg>
  );
};
