import React from "react";
import { Flags } from "pages/Component/ComponentInput/Index";
import IconCheck from "components/atoms/Icons/Custom/Check";
import IconAlert from "components/atoms/Icons/Custom/Alert";
import IconInfo from "components/atoms/Icons/Custom/Info";

export default function Flag() {
  return (
    <div className="mt-36">
      <Flags
        icon={
          <IconCheck
            fill={"white"}
            width={"20px"}
            height={"20px"}
            className={"min-w-20px relative top-2px"}
          />
        }
      />
      <Flags
        titleFlag={"Error"}
        bgFlag={"bg-red-02"}
        bgBtn={"bg-red-03"}
        className={"mt-4"}
        icon={
          <IconAlert
            fill={"white"}
            width={"20px"}
            height={"20px"}
            className={"min-w-20px relative top-2px"}
          />
        }
      />
      <Flags
        titleFlag={"Neutral"}
        bgFlag={"bg-gray-02"}
        bgBtn={"bg-gray-03"}
        className={"mt-4 mb-10"}
        icon={
          <IconInfo
            fill={"white"}
            width={"20px"}
            height={"20px"}
            className={"min-w-20px relative top-2px"}
          />
        }
      />
    </div>
  );
}
