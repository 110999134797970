import React from "react";

export default function ArrowDown({ width, fill, height, className, style }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M5.33334 5.46666L9.06668 1.66666C9.33334 1.39999 9.33334 0.999992 9.06668 0.733325C8.80001 0.466659 8.40001 0.466659 8.13334 0.733325L4.80001 3.99999L1.46668 0.733326C1.20001 0.466659 0.80001 0.466659 0.533343 0.733326C0.40001 0.866659 0.333343 0.999993 0.333344 1.19999C0.333344 1.39999 0.40001 1.53333 0.533343 1.66666L4.26668 5.46666C4.60001 5.73333 5.00001 5.73333 5.33334 5.46666C5.26668 5.46666 5.26668 5.46666 5.33334 5.46666Z"
        fill={fill}
      />
    </svg>
  );
}

ArrowDown.defaultProps = {
  width: "9px",
  height: "6px",
  fill: "#8F8F8F",
};
