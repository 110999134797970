export const START_FETCHING_CUSTOMER = "features/Customer/START_FETCHING_CUSTOMER";
export const ERROR_FETCHING_CUSTOMER = "features/Customer/ERROR_FETCHING_CUSTOMER";
export const SUCCESS_FETCHING_CUSTOMER = "features/Customer/SUCCESS_FETCHING_CUSTOMER";

//param action
export const SET_KEYWORD = "features/Customer/SET_KEYWORD";
export const SET_PAGE = "features/Customer/SET_PAGE";
export const SET_LIMIT = "features/Customer/SET_LIMIT";
export const SET_TOTAL_PAGE = "features/Customer/SET_TOTAL_PAGE";
export const SET_CUSTOMER = "features/Customer/SET_CUSTOMER";
export const SET_DATE = "features/Customer/SET_DATE";
export const SET_GROUP = "features/Customer/SET_GROUP";
export const SET_LOCATION = "features/Customer/SET_LOCATION";
export const SET_CRITERIA = "features/Customer/SET_CRITERIA";

export const SET_ACTIVITY = "features/Customer/SET_ACTIVITY";
export const SET_CHECKBOX = "features/Customer/SET_CHECKBOX";
export const SET_NOTIF = "features/Customer/SET_NOTIF";
export const SET_CUSTOMER_SELECTED = "features/Customer/SET_CUSTOMER_SELECTED";
