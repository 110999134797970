import {
  SUCCESS_FETCING_LOCATION,
  SUCCESS_FETCING_TRANSFERSTOCK,
  SET_TRANSFERSTOCK
} from "./constants";

const initialState = {
  locations: [],
  category: [],
  list: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_FETCING_LOCATION:
      return { ...state, locations: action.outlets, };

    case SUCCESS_FETCING_TRANSFERSTOCK:
      return { ...state, category: action.category, list: action.list };

    case SET_TRANSFERSTOCK:
      return { ...state, category: action.category, };

    default:
      return state;
  }
}
