import React from "react";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Button from "components/atoms/Button";
import SearchBar from "components/atoms/SearchBar";
import "./transaction.css";
import IconInfo from "components/atoms/Icons/Custom/icon new/Info";
import Table from './tableTransaction'
import { useSelector, useDispatch } from "react-redux";
import { setDate, fetchTransaction, setKeyword, setTransaction, setPayment, setLocation } from "features/Transaction/actions";
import { fetchPayments } from "features/Payment/actions";
import { formatRupiah } from 'utils/formatRupiah'
import { listTransaction } from './listTansaction.js'
import { InputDateRange } from "components/atoms/Form/InputDate/index";
import EmptyPage from "components/templates/Empty";
import EmptyProduk from "assets/icon/undraw_empty_xct9 1.svg";


export default function PageTransaction() {
  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.transaction);
  // api filter payment belum bejalan
  // const payment = useSelector((state) => state.payment);
  const [isShowSearch, setIsShowSearch] = React.useState(false);

  console.log("transaction >>")
  console.log(transaction)

  React.useEffect(() => {
    dispatch(fetchTransaction());
    dispatch(fetchPayments());
  }, [dispatch, transaction.date, transaction.payment_method, transaction.keyword, transaction.page, transaction.limit, transaction.transaction, transaction.location]);

  const onChangeDateCostum = (start, end) => {
    const ranges = {
      startDate: start,
      endDate: end,
      key: 'selection',
    }
    dispatch(setDate(ranges))
  }

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {/* list btn action */}
      <div className="flex justify-between items-center">
        <div className="flex items-center w-full">
          {isShowSearch ? (
            <SearchBar
              placeholder="cari transaksi"
              name="keyword"
              value={transaction.keyword}
              onChange={(e) => dispatch(setKeyword(e.target.value))}
              onClick={() => setIsShowSearch(!isShowSearch)}
            />
          ) : (
            <div className="flex items-center">
              <Button
                className="text-1"
                variant="outlined"
                color="gray"
                content="icon infront"
                iconName="UilSearch"
                onClick={() => setIsShowSearch(!isShowSearch)}
              >
                Cari transaksi
              </Button>
              <div className="ml-3">
                <InputDateRange
                  onChangeDateCostum={(start, end) => onChangeDateCostum(start, end)}
                  variant="range"
                  type="custom right"
                  onChange={(ranges) => dispatch(setDate(ranges))}
                  name="date"
                  value={transaction.date}
                />
              </div>
              <Dropdown
                variant="outlined"
                color="gray"
                className="ml-2"
                content="label only"
                label={transaction.transaction?.label}
              >
                {listTransaction.map((data, i) => (
                  <DropdownList
                    key={i}
                    onClick={() => dispatch(setTransaction(data))}
                  >
                    {data.label}
                  </DropdownList>
                ))}
              </Dropdown>
              {/* <Dropdown
                variant="outlined"
                color="gray"
                className="ml-2"
                content="label only"
                label={transaction.payment_method.label}
              >
                {payment.data.map((data, i) => (
                  <DropdownList
                    key={i}
                    onClick={() => dispatch(setPayment(data))}
                  >
                    {data.label}
                  </DropdownList>
                ))}
              </Dropdown> */}
              <Dropdown
                variant="outlined"
                color="gray"
                className="ml-2"
                content="label only"
                label={transaction.location?.label}
              >
                {transaction?.outlets.map((outlet) => {
                  return (
                    <React.Fragment key={outlet.value}>
                      <DropdownList color="blue" type="button" onClick={() => dispatch(setLocation(outlet))}>{outlet.label}</DropdownList>
                    </React.Fragment>
                  )
                })}
              </Dropdown>
            </div>
          )}
        </div>
        <div className="transaksi aksi-lainnya ml-4">
          {/* <Dropdown
            variant="outlined"
            color="blue"
            content="label only"
            label="Aksi lainnya"
          >
            {list.map((data, i) => (
              <DropdownList key={i} onClick={() => onClick(data)}>
                {data.name}
              </DropdownList>
            ))}
          </Dropdown> */}
        </div>
      </div>
      {/* list title blue-bg */}
      <div className="grid grid-cols-3 gap-8 mt-10">
        <div className="col-span-1">
          {/* box 1 */}
          <div className="grid grid-cols-2 gap-1">
            <div className="col-span-1 bg-blue-02 px-4 py-2 flex items-center justify-between">
              <p className="text-white">Jumlah transaksi</p>
              {/* icon seru */}
              <IconInfo />
            </div>
            <div className="col-span-1 bg-blue-02 flex items-center justify-center">
              <p className="text-white text-lg">{transaction.salesInfo.count} transaksi</p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          {/* box 2 */}
          <div className="grid grid-cols-2 gap-1">
            <div className="col-span-1 bg-blue-02 px-4 py-2 flex items-center justify-between">
              <p className="text-white whitespace-nowrap">Penjualan bersih</p>
              {/* icon seru */}
              <IconInfo />
            </div>
            <div className="col-span-1 bg-blue-02 flex items-center justify-center">
              <p className="text-white text-lg">{formatRupiah((transaction.salesInfo.total + transaction.salesInfo?.refund?.total) - transaction.salesInfo.tax)}</p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          {/* box 3 */}
          <div className="grid grid-cols-2 gap-1">
            <div className="col-span-1 bg-blue-02 px-4 py-2 flex items-center justify-between">
              <p className="text-white whitespace-nowrap">Dana terkumpul</p>
              {/* icon seru */}
              <IconInfo />
            </div>
            <div className="col-span-1 bg-blue-02 flex items-center justify-center">
              <p className="text-white text-lg">{formatRupiah(transaction.salesInfo.total)}</p>
            </div>
          </div>
        </div>
      </div>
      {/* list item 1 */}

      {/* START: TABLE */}
      <Table status={transaction.status} data={transaction.data} />
      {/* END: TABLE */}



      {/* modal */}
      {/* <ModalFakturRefund
        show={fakturRefund}
        close={() => setFakturRefund(false)}
      />
      <ModalKirimFaktur
        show={kirimFaktur}
        close={() => setKirimFaktur(false)}
      />
      <ModalRefund show={refund} close={() => setRefund(false)} />
      <ModalLihatPelanggan
        show={lihatPelanggan}
        close={() => setLihatPelanggan(false)}
      /> */}
    </div>
  );
}
