import {
  START_FETCHING_REVIEW,
  ERROR_FETCHING_REVIEW,
  SUCCESS_FETCHING_REVIEW,
  SET_ASPECT,
  SET_GROUP,
  SET_LOCATION,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_NOTIF,
  SET_DATE,
  SET_NEGATIF,
  SET_POSITIF,
  SET_TYPE,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  positif: 0,
  negatif: 0,
  page: 1,
  limit: 10,
  totalPage: 1,
  aspect: "",
  type: "",
  group: "",
  location: "",
  date: {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  },
  status: statuslist.idle,
  notif: {
    message: "",
    type: "",
    status: "",
    toggle: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_REVIEW:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_REVIEW:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_REVIEW:
      return {
        ...state,
        status: statuslist.success,
        data: action.reviews,
      };

    case SET_POSITIF:
      return { ...state, positif: action.positif };
    case SET_NEGATIF:
      return { ...state, negatif: action.negatif };
    case SET_DATE:
      return { ...state, date: action.date, page: 1 };
    case SET_TYPE:
      return { ...state, type: action.types };

    case SET_NOTIF:
      return { ...state, notif: action.notif };

    case SET_LOCATION:
      return { ...state, location: action.location };

    case SET_ASPECT:
      return { ...state, aspect: action.aspect };

    case SET_GROUP:
      return { ...state, group: action.group };

    case SET_LIMIT:
      return {
        ...state,
        limit: action.limit,
      };

    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case SET_TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.totalPage,
      };

    default:
      return state;
  }
}
