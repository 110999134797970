import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMaterialInStock, selectMaterial, submitCheckbox } from "features/Material/action";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import IconAdd from "components/atoms/Icons/Custom/icon new/Add";
import { DropdownList } from "components/atoms/Dropdown/dropdown";
import SearchBar from "components/atoms/SearchBar";
import moment from "moment";

function MaterialChoice({ show, close, create }) {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.materials);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    dispatch(fetchMaterialInStock());
  }, [dispatch, data.material_used]);

  const searchMaterial = (e) => {
    setFilter(e.target.value);
  };
  const materialOnfilter = useMemo(() => {
    if (!filter) return data?.materials_checkbox;

    return data?.materials_checkbox?.filter((material) => {
      return material.name.toLowerCase().includes(filter.toLowerCase());
    });
  }, [filter, data.materials_checkbox]);

  // console.log(filter);
  // console.log(materialOnfilter);
  return (
    <ModalDialog size={"alert"} show={show} onClose={() => close()} title="Pilih bahan baku">
      <div className="bg-gray-05 h-full">
        <div style={{ height: "81vh" }} className="p-8">
          <div className="relative" style={{ height: "62vh" }}>
            <SearchBar value={filter} onChange={searchMaterial} placeholder="Cari bahan baku.." />
            <div
              style={{ height: "53vh" }}
              className="bg-white overflow-y-scroll mt-3 rounded-lg border border-gray-04 px-6"
            >
              <ul>
                {materialOnfilter?.map((items, index) => {
                  return (
                    <li key={index} className="flex justify-between py-4">
                      <DropdownList
                        onClick={() => dispatch(selectMaterial(items, index))}
                        checked={items.isChecked}
                        list="checkbox"
                        width="w-full"
                      >
                        {items.name}
                      </DropdownList>
                      <p className="text-green-03 bold-text-2">
                        {moment(items.date).format("DD/MM/YYYY") ===
                        moment(new Date()).format("DD/MM/YYYY")
                          ? "Baru!"
                          : ""}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
            <Button
              variant="primary"
              color="green"
              type="button"
              className="absolute bottom-0 left-0 w-full flex items-center justify-center"
              onClick={() => create()}
            >
              <IconAdd className="mr-2" />
              Tambah bahan baku baru
            </Button>
          </div>
        </div>

        {/* bottom fixed */}
        <div className="flex justify-end px-6 py-4 bg-white mt-6 absolute bottom-0 left-0 w-full">
          <Button
            onClick={() => dispatch(submitCheckbox("hide-material-global"))}
            variant="primary"
            color="blue"
            type="button"
          >
            Selesai tambah bahan baku
          </Button>
        </div>
      </div>
    </ModalDialog>
  );
}

export default MaterialChoice;
