import {
  START_FETCHING_GROUP,
  SUCCESS_FETCHING_GROUP,
  ERROR_FETCHING_GROUP,
  SET_KEYWORD,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_NOTIF,
  SET_CRITERIA,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  criteria: {},
  page: 1,
  limit: 10,
  totalPage: 1,
  keyword: "",
  status: statuslist.idle,
  notif: {
    message: "",
    type: "",
    status: "",
    toggle: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_GROUP:
      return { ...state, status: statuslist.process };

    case SUCCESS_FETCHING_GROUP:
      return { ...state, status: statuslist.success, data: action.customersGroup };

    case ERROR_FETCHING_GROUP:
      return {
        ...state,
        status: statuslist.error,
      };

    case SET_CRITERIA:
      return { ...state, criteria: action.criteria };

    case SET_NOTIF:
      return { ...state, notif: action.notif };

    case SET_KEYWORD:
      return { ...state, keyword: action.keyword };

    case SET_LIMIT:
      return {
        ...state,
        limit: action.limit,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case SET_TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.totalPage,
      };

    default:
      return state;
  }
}
