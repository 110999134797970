import React from "react";
import propTypes from "prop-types";
import Spinner from "components/atoms/Spinner";

function Tbody(props) {
  const {
    loading,
    data,
    display,
    editUrl,
    deleteAction,
    customAction,
    actionDisplay,
    tdStyle,
  } = props;
  return (
    <tbody>
      {loading === "process" ? (
        <tr>
          <td colSpan={display.length + 1} style={{ textAlign: "center" }}>
            <div className="flex items-center justify-center">
              <Spinner size={64} color="#859DFF" />
            </div>
          </td>
        </tr>
      ) : data.length ? (
        data.map((data, index) => {
          return (
            <tr key={index}>
              {Object.keys(data).map((key) => {
                return (
                  display.indexOf(key) > -1 && <td key={key}>{data[key]}</td>
                );
              })}
              {actionDisplay && <td className={tdStyle}>{actionDisplay}</td>}
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={display.length + 1} style={{ textAlign: "center" }}>
            Tidak Ditemukan Data
          </td>
        </tr>
      )}
    </tbody>
  );
}

Tbody.propTypes = {
  data: propTypes.array,
  display: propTypes.array,
};

export default Tbody;
