import * as React from "react";
import Spinner from "components/atoms/Spinner";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import { deleteData } from "utils/fetchData";
import { useDispatch, useSelector } from "react-redux";
import { setNotif, fetchCategory } from "features/Category/actions";
import Banner from "components/atoms/Banner";
import CategoryEdit from "./edit";
import ProductShowInCategory from './showProduct'

export default function ComponentTableCategory({ data, status }) {
  const dispatch = useDispatch();
  const notif = useSelector((state) => state.category.notif);

  const [check, setCheck] = React.useState({
    id: "",
    name: "",
    productCount: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [isSweetAlert, setIsSweetAlert] = React.useState(false);
  const [isShowEdit, setIsShowEdit] = React.useState(false);
  const [categoryId, setCategoryId] = React.useState();
  const [isShowProduct, setIsShowProduct] = React.useState(false)

  const handleShowSweetAlert = (payload) => {
    setCheck({
      ...check,
      id: payload.id,
      name: payload.name,
      productCount: payload.productCount,
    });
    setIsSweetAlert(true);
  };

  const handleShowEdit = (payload) => {
    setCategoryId(payload.id);
    setIsShowEdit(true);
  };

  const handleDeleteCategory = async (id) => {
    setLoading(true);
    const res = await deleteData(`v2/category/${id}`);

    if (res.data?.code === 200) {
      setLoading(false);
      const notif = {
        message: `Kategori "${check.name}" berhasil di hapus`,
        type: "primary",
        status: "danger",
        toggle: true,
      };
      dispatch(setNotif(notif));
      dispatch(fetchCategory());
      setIsSweetAlert(false);
      setCheck({ id: "", name: "", productCount: "" });
    }
  };
  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <th>Nama kategori</th>
            <th colSpan={3}>Jumlah produk</th>
          </tr>
        </thead>
        <tbody>
          {status === "process" ? (
            <tr key="status">
              <td colSpan={4} style={{ textAlign: "center" }}>
                <div className="flex items-center justify-center">
                  <Spinner size={64} color="#859DFF" />
                </div>
              </td>
            </tr>
          ) : data.length ? (
            data.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{data.name}</td>
                    <td>{data.productCount}</td>
                    <td><Button type="link" color="blue" onClick={() => { setIsShowProduct(true); setCategoryId(data.id) }}>Pilih produk ke kategori</Button></td>
                    <td className="relative">
                      <div>
                        <Dropdown
                          variant="subtle"
                          color="blue"
                          content="icon only"
                        >
                          <DropdownList
                            type="button"
                            onClick={() => handleShowEdit(data)}
                            color="blue"
                          >
                            Ubah nama kategori
                          </DropdownList>
                          <DropdownList
                            type="button"
                            onClick={() => handleShowSweetAlert(data)}
                            color="red"
                          >
                            Hapus kategori
                          </DropdownList>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                  {notif.toggle && notif.id === data.id && (
                    <tr>
                      <td colSpan={4} className="p-0">
                        <Banner
                          status={notif.status}
                          type={notif.type}
                          message={notif.message}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                Tidak Ditemukan Data
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* START: ALERT DELETE */}
      <ModalDialog
        size={"alert"}
        show={isSweetAlert}
        onClose={() => setIsSweetAlert(false)}
        title={<p className="heading-3 text-dark">Hapus kategori</p>}
      >
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p className="text-2">
            Apakah anda yakin ingin menghapus kategori{" "}
            <span className="text-blue-03 bold-text-2">"{check.name}"</span> ?{" "}
            <span className="text-blue-03 bold-text-2">
              "{check.productCount}"
            </span>{" "}
            dalam kategori ini akan menjadi tidak ada kategori ketika kategori
            berhasil dihapus.{" "}
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
            onClick={() => setIsSweetAlert(false)}
          >
            Batal hapus kategori
          </Button>
          <Button
            variant="primary"
            color="red"
            className="my-5"
            type="button"
            loading={loading ? true : false}
            disabled={loading ? true : false}
            onClick={!loading ? () => handleDeleteCategory(check.id) : null}
          >
            Hapus kategori
          </Button>
        </div>
      </ModalDialog>
      {/* END: ALERT DELETE */}

      {/* START: EDIT CATEGORY */}
      {isShowEdit && (
        <CategoryEdit
          title="Ubah nama kategori"
          category={categoryId}
          show={isShowEdit}
          close={() => setIsShowEdit(false)}
        />
      )}
      {/* END: EDIT CATEGORY */}

      {isShowProduct && (
        <ProductShowInCategory
          title="Pilih product"
          category={categoryId}
          show={isShowProduct}
          close={() => setIsShowProduct(false)}
        />
      )}
    </div>
  );
}
