import * as React from 'react'

import IconDelete from "components/atoms/Icons/Custom/icon new/Delete";

export default function TableTransferStock({ data, onChange, onDelete }) {

  return (
    <table className="w-full mt-4">
      <thead>
        <tr>
          <th>Produk / variasi</th>
          <th>Stok di sumber</th>
          <th>Stok di tujuan</th>
          <th colSpan={2}>Jumlah transfer</th>
        </tr>
      </thead>
      <tbody>
        {data.map((data, i) => {
          return (
            <tr key={i}>
              <td>{data.name}</td>
              <td>{data.beforeStock}</td>
              <td>{data.targetStock}</td>
              <td>
                <div className="relative">
                  <input
                    type="number"
                    className="focus:outline-none outline-none text-right"
                    placeholder="0"
                    name="stock"
                    value={data.stock}
                    max={data.beforeStock}
                    min={1}
                    onChange={(e) => onChange(e, i)}
                  />
                  <span onClick={() => onDelete(data)}>
                    <IconDelete className="absolute -right-12 top-0" />
                  </span>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
