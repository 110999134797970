import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import TextCaption from "components/atoms/Text/TextCaption";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Toggle from "components/atoms/Toggle";
import IconDrag from "components/atoms/Icons/Custom/icon new/Drag";
import IconAdd from "components/atoms/Icons/Custom/icon new/Add";
import InputTextSmall from "components/atoms/Form/InputTextSmall";
import Switch from "components/atoms/Switch";
import "./variant.css";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { getData, putData } from "utils/fetchData";
import { setNotif, fetchVariant } from "features/Variant/actions";
import { useDispatch } from "react-redux";
import { fetchLog, setNotifLog } from "features/ReportLog/actions";

const VariantSchema = yup.object().shape({
  name: yup.string().required("nama variasi tidak boleh kosong."),
  website: yup.string().url(),
});

export default function EditProduct({ show, close, title, variant, logStatus }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [field, setField] = React.useState({
    type: 0,
    required: 0,
    name: "",
    option: [],
    products: [],
  });

  const { register, handleSubmit, errors, setError, clearError } = useForm({
    mode: "onBlur",
    validationSchema: VariantSchema,
  });

  const [type, setType] = React.useState([]);

  React.useEffect(() => {
    const getSingleVariant = async () => {
      const res = await getData(`v2/variant/${variant}`);
      if (res.data?.code === 200) {
        setField({
          type: res.data.variant.variantType,
          name: res.data.variant.variantName,
          required: res.data.variant.variantStatus ?? 0,
          option: res.data.variant.ProductVariantOptions,
          products: [],
        });

        let checkType = [];

        if (res.data.variant.variantType === 0) {
          checkType.push(
            {
              name: "Hanya satu opsi",
              status: true,
            },
            {
              name: "Pilih beberapa opsi",
              status: false,
            }
          );
        } else {
          checkType.push(
            {
              name: "Hanya satu opsi",
              status: false,
            },
            {
              name: "Pilih beberapa opsi",
              status: true,
            }
          );
        }

        setType(checkType);
      }
    };
    getSingleVariant();
  }, []);

  const onClick = (data) => {
    const typeOld = [...type];
    typeOld.forEach((type) => {
      if (type.name === data.name) {
        type.status = true;
      } else {
        type.status = false;
      }
    });

    setType(typeOld);
    setField({ ...field, type: data.name === "Hanya satu opsi" ? 0 : 1 });
  };

  const onChange = (e) => {
    let value = e.target.value,
      name = e.target.name;
    if (name === "required") {
      value = e.target.checked ? 1 : 0;
    }
    setField({ ...field, [name]: value });
  };

  const onChangeArray = (e, index) => {
    let _temp = [...field.option];
    _temp[index][e.target.name] = e.target.value;

    setField({ ...field, option: _temp });
  };

  const onCurrencyInput = (value, name, index) => {
    let _temp = [...field.option];
    _temp[index][name] = value;

    setField({ ...field, option: _temp });
  };

  const onPlusOption = () => {
    let no = 0;
    let option = [...field.option];
    option.forEach((data) => {
      no += data.id;
    });
    option.push({
      name: "",
      id: no,
      type: 1,
      price: 0,
      index: no,
    });

    setField({ ...field, option: option });
  };

  const onMinusOption = (data) => {
    let option = [...field.option];
    var removeIndex = option
      .map(function (item) {
        return item.index;
      })
      .indexOf(data.index);
    option.splice(removeIndex, 1);
    setField({ ...field, option: option });
  };

  const handleRLDDChange = (item) => {
    setField({ ...field, option: item });
  };

  const onSubmit = async () => {
    try {
      let error = false;
      setLoading(true);
      field.option.forEach((option) => {
        if (option.name === "") {
          setError("option", "option", "Nama opsi tidak boleh kosong.");
          error = true;
        }
      });
      if (field.option.length <= 1) {
        setError("option", "option", "Opsi variasi tidak harus lebih dari satu.");
        error = true;
      }

      if (!error) {
        const res = await putData(`v2/variant/${variant}`, field);

        const notif = {
          message: `Variasi "${res.data.variant.variantName}" berhasil diubah`,
          type: "primary",
          status: "success",
          id: variant,
          toggle: true,
        };
        dispatch(setNotif(notif));
        dispatch(fetchVariant());
        logStatus && dispatch(setNotifLog(notif));
        logStatus && dispatch(fetchLog());
        close();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err.response);
      setLoading(false);
    }
  };

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title={title}
      anotherAction={
        <>
          <span className="text-blue-700 font-semibold">{field.name}</span>
          <Button
            disabled={field.name === "" ? true : false}
            onClick={() => (!loading ? onSubmit() : null)}
            loading={loading}
            variant="primary"
            color="blue"
          >
            Selesai edit variasi
          </Button>
        </>
      }
    >
      <div className="container-body flex items-start justify-center bg-white">
        <div className="w-728px max-w-728px">
          <h3 className="heading-3 mb-4">Informasi variasi</h3>
          <div className="ml-14">
            <InputTextLargeWithLabel
              className="w-full"
              label="Nama variasi"
              status={errors?.name ? "error" : "default"}
              register={register}
              name="name"
              onChange={onChange}
              value={field.name}
            >
              {errors.name && (
                <div className="flex items-center mt-1">
                  <TextCaption status="error" message={errors.name?.message} />
                </div>
              )}
            </InputTextLargeWithLabel>
          </div>
          <div className="flex items-center mt-1 ml-14">
            <p className="bold-text-2 text-gray-02 mr-6 w-24 whitespace-nowrap">Jenis variasi</p>
            {/* component switch belum di masukin*/}
            <Switch
              className="w-full ml-1"
              size="md"
              type="primary"
              buttons={type}
              content="label"
              onClick={onClick}
            />
          </div>
          <div className="rounded-lg border border-gray-04 py-4 px-6 flex justify-between mt-1">
            <p className="text-gray-02 bold-text-2">Variasi wajib dipilih</p>
            <div className="relative right-8">
              <Toggle
                size="lg"
                type="primary"
                value={field.required}
                name="required"
                checked={field.required === 0 ? false : true}
                onChange={onChange}
              />
            </div>
          </div>
          <h3 className="heading-3 mb-4 mt-20">Pilihan opsi variasi</h3>
          <div className="p-6 bg-gray-05 rounded-lg">
            <div className="bg-white rounded-lg">
              <div className="px-6 py-4">
                <RLDD
                  items={field.option}
                  itemRenderer={(item, index) => {
                    return (
                      <div className={`flex items-center ${index !== 0 && "mt-2"}`} key={index}>
                        <IconDrag className="mr-4" />
                        <InputTextSmall
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                          className="mr-2"
                          placeholder="Nama opsi"
                          value={item.name}
                          onChange={(e) => onChangeArray(e, index)}
                          name="name"
                        />
                        <div className="relative">
                          <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-2 text-gray-03">
                            +
                          </span>
                          <InputTextSmall
                            className="mr-16 pl-14"
                            placeholder="Jumlah tambah harga"
                            value={item.price}
                            onChange={(e) => onCurrencyInput(e, "price", index)}
                            name="price"
                            currency={"rp"}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        </div>
                        <Button
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                          variant="secondary"
                          color="red"
                          onClick={() => onMinusOption(item)}
                        >
                          Hapus
                        </Button>
                      </div>
                    );
                  }}
                  onChange={handleRLDDChange}
                />
              </div>
              {errors.option && (
                <div className="flex items-center mt-1">
                  <TextCaption status="error" message={errors.option?.message} />
                </div>
              )}
            </div>
            <Button
              className="flex items-center w-full mt-4 justify-center"
              variant="primary"
              color="green"
              onClick={onPlusOption}
            >
              <IconAdd className="mr-2" />
              Tambah opsi baru
            </Button>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
}
