import React from "react";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Spinner from "components/atoms/Spinner";
import Banner from "components/atoms/Banner";
import ModalTrack from "./tracking";
import ModalStock from "./stock";
import ModalHistory from "./history";
import { useDispatch } from "react-redux";
import {
  setModal,
  setModalSecondary,
  setId,
  setName,
  fetchOneMaterial,
  updateMaterial,
  fetchOneMaterialToStock,
  fetchOneMaterialToTrack,
} from "features/Material/action";

import { fetchHistory, setModalHistory } from "features/MaterialHistory/actions";

function TableMeasure({ data, handleChange, generateStock, operator, history }) {
  const dispatch = useDispatch();
  const payload = {
    name: data.name,
    unitOfMeasureId: data.unitOfMeasureId,
    stocks: data.stocks,
  };

  return (
    <React.Fragment>
      <table className="w-full">
        <thead>
          <tr className="bg-gray-100 border-t border-b text-left border-gray-300">
            <th>Nama bahan baku</th>
            <th>Lokasi</th>
            <th>Stok tersedia</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {data.status === "process" ? (
            <tr key="status">
              <td colSpan="4" className="text-center">
                <div className="flex items-center justify-center">
                  <Spinner size={64} color="#859DFF" />
                </div>
              </td>
            </tr>
          ) : data.materials.length > 0 ? (
            data.materials.map((items) => {
              return (
                <React.Fragment key={items.id}>
                  <tr>
                    <td>{items.name}</td>
                    <td>
                      {items.allOutlet
                        ? "Semua lokasi"
                        : items.abstract.map((outlet) => {
                          return <span key={outlet.id}>{`${outlet.outlet.outletName}, `} </span>;
                        })}
                    </td>
                    <td>
                      {items.stock} {items.unit !== null ? items.unit.unit : ""}
                    </td>
                    <td className="relative flex justify-end">
                      <Dropdown variant="subtle" color="blue" content="icon only">
                        <DropdownList
                          onClick={() => dispatch(fetchOneMaterial(items.id))}
                          type="button"
                          color="blue"
                        >
                          Edit bahan baku
                        </DropdownList>
                        <DropdownList
                          onClick={() => dispatch(fetchOneMaterialToStock(items.id))}
                          type="button"
                          color="blue"
                        >
                          Edit stok bahan baku
                        </DropdownList>
                        <DropdownList
                          onClick={() => dispatch(fetchOneMaterialToTrack(items.id))}
                          type="button"
                          color="blue"
                        >
                          Edit pelacakan bahan baku
                        </DropdownList>
                        <DropdownList
                          // onClick={() => setIsShowHistory(true)}
                          onClick={() => dispatch(fetchHistory(items.id))}
                          type="button"
                          color="blue"
                        >
                          Lihat sejarah bahan baku
                        </DropdownList>
                        <DropdownList
                          onClick={() => {
                            dispatch(setId(items.id));
                            dispatch(setName(items.name));
                            dispatch(setModal("show-delete"));
                          }}
                          type="button"
                          color="red"
                        >
                          Hapus bahan baku
                        </DropdownList>
                      </Dropdown>
                    </td>
                  </tr>
                  {data.id === items.id && data.banner === "success" ? (
                    <tr>
                      <td colSpan="4" className="p-0">
                        <Banner
                          className="w-full"
                          status="success"
                          type="primary"
                          message={data.message}
                        />
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                <span className="text-gray-02">Tidak ditemukan data</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <ModalTrack
        handleChange={handleChange}
        handleSubmit={() => dispatch(updateMaterial(data.id, payload))}
        data={data}
        show={data.modal_secondary === "show-track-table" ? true : false}
        close={() => {
          generateStock();
          dispatch(setName(""));
          dispatch(setModalSecondary("hide-track-table"));
        }}
      />
      {history.modal === "show-history-material-table" && (
        <ModalHistory
          show={history.modal === "show-history-material-table" ? true : false}
          close={() => dispatch(setModalHistory("hide-history-material-table"))}
          data={history}
        />
      )}

      <ModalStock
        operator={operator}
        handleChange={handleChange}
        handleSubmit={() => dispatch(updateMaterial(data.id, payload))}
        data={data}
        show={data.modal_secondary === "show-stock-table" ? true : false}
        close={() => {
          generateStock();
          dispatch(setName(""));
          dispatch(setModalSecondary("hide-stock-table"));
        }}
      />
    </React.Fragment>
  );
}

export default TableMeasure;
