import * as React from "react";
import { useSelector } from "react-redux";
import Barcode from "react-barcode";
import Button from "components/atoms/Button";
import IconCheck from "components/atoms/Icons/Custom/icon new/Check";
import { useReactToPrint } from "react-to-print";

export default function BarcodeSuccess() {
  const { data } = useSelector((state) => state.barcode);
  const [show, setShow] = React.useState(false);

  console.log("data >>")
  console.log(data)


  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderBarcode = (data) => {
    var indents = [];
    for (var i = 0; i < data.length; i++) {
      if(data[i].totalStock){
        for (let x = 0; x < data[i].totalStock; x++) {
          indents.push(
            <div className="col-span-1 barcode-success">
              <Barcode value={data[i].sku} />
            </div>
          );
        }
      }else{
        if (data[i].stock > 0) {
          for (let x = 0; x < data[i].stock; x++) {
            indents.push(
              <div className="col-span-1 barcode-success">
                <Barcode value={data[i].sku} />
              </div>
            );
          }
        }
      }
    }
    return indents;
  };



  return (
    <div className="h-screen overflow-hidden">
      <h1>{data.count} barcode barhasil dibuat</h1>
      {/* <Button onClick={() => setShow(true)} variant="primary" color="blue">
        <p className="bold-text-2">Print barcode</p>
      </Button> */}

  
      <div className="grid grid-cols-2 h-full">
        <div className="col-span-1 h-screen">
          <div className="h-full flex flex-col items-center justify-center overflow-y-scroll">
            <IconCheck width="200" height="200" fill="#0AD674" />
            <h1 className="heading-1 my-6">
              {data.count} Barcode produk siap dipakai
            </h1>
            <Button variant="primary" color="blue" onClick={() => {handlePrint(); setShow(true);}}>
              Cetak Barcode
            </Button>
          </div>
        </div>

        <div className="col-span-1 h-full overflow-y-scroll flex justify-center" ref={componentRef} >
          <div className="h-full grid grid-cols-3 w-full gap-4 border-r p-8">
              {show && renderBarcode(data.generateBarcode)}
          </div>
        </div>
      </div>
    </div>
  );
}
