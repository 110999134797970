import {
  START_FETCHING_PRODUCT,
  ERROR_FETCHING_PRODUCT,
  SUCCESS_FETCHING_PRODUCT,
  SET_FILTER_LIST_VARIANT,
  SET_PAGE,
  SET_KEYWORD,
  SET_NOTIF,
  SET_LIMIT,
  SUCCESS_FETCHING_LOCATION,
  SUCCESS_FETCHING_CATEGORY,
  SUCCESS_FETCHING_VARIANT,
  SET_CATEGORY,
  SET_LOCATION,
  SET_VARIANT,
} from "./constants";
import { userLogout } from "features/Auth/actions";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchProduct = debounce(getData, 1000);

export const startFetchingProduct = () => {
  return {
    type: START_FETCHING_PRODUCT,
  };
};

export const errorFetchingProduct = () => {
  return {
    type: ERROR_FETCHING_PRODUCT,
  };
};

export const successFetchingProduct = ({ product, pages }) => {
  return {
    type: SUCCESS_FETCHING_PRODUCT,
    product,
    pages,
  };
};

export const fetchProduct = () => {
  return async (dispatch, getState) => {
    try {
      const notif = {
        message: "",
        type: "",
        status: "",
        toggle: false,
      };

      setTimeout(() => {
        dispatch(setNotif(notif));
      }, 5000);

      let outlets = [];
      let category = [];
      let location = getState().product.outlets;
      let categories = getState().product.category;

      for (let i = 0; i < location.length; i++) {
        if (location[i].isChecked === true) {
          outlets.push(location[i].value);
        }
      }

      for (let i = 0; i < categories.length; i++) {
        if (categories[i].isChecked === true) {
          category.push(categories[i].value);
        }
      }

      let keyword = getState().product.keyword || "";
      let page = getState().product.page || 1;
      let limit = getState().product.limit || 10;

      dispatch(startFetchingProduct());
      const params = {
        keyword,
        page,
        limit,
        category: category.join(","),
        location: outlets.join(","),
      };
      const res = await debouncedFetchProduct("v2/product", params);
      if (res.data?.code === 401) {
        localStorage.removeItem("auth");
        dispatch(userLogout());
      }

      dispatch(successFetchingProduct({ product: res.data.products, pages: res.data.pages }));
    } catch (err) {
      dispatch(errorFetchingProduct());
    }
  };
};

export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

export const setPage = (number = 1) => {
  return {
    type: SET_PAGE,
    page: number,
  };
};

export const setPerPage = (limit) => {
  return {
    type: SET_LIMIT,
    limit: limit,
  };
};

export const fetchLocationInProduct = () => {
  return async (dispatch) => {
    let {
      data: { outlets },
    } = await getData("outlets");

    let select_box = [];
    outlets.forEach((item) => {
      select_box.push({
        label: item.name,
        value: item.id,
        isChecked: true,
      });
    });
    dispatch(successFetchingCheckboxLocations({ outlets: select_box }));
  };
};

export const successFetchingCheckboxLocations = ({ outlets }) => {
  return {
    type: SUCCESS_FETCHING_LOCATION,
    outlets,
  };
};

export const fetchCategoryInProduct = () => {
  return async (dispatch) => {
    let res = await getData("categories");

    let select_box = [];
    res.data.categories.forEach((item) => {
      select_box.push({
        label: item.name,
        value: item.id,
        isChecked: true,
      });
    });
    dispatch(successFetchingCheckboxCategory({ category: select_box }));
  };
};

export const successFetchingCheckboxCategory = ({ category }) => {
  return {
    type: SUCCESS_FETCHING_CATEGORY,
    category,
  };
};

export const fetchVariantInProduct = () => {
  return async (dispatch) => {
    const notif = {
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      dispatch(setNotif(notif));
    }, 5000);

    let page = 1;
    let limit = 1000;
    let params = {
      page,
      limit,
    };
    let res = await getData("v2/variant", params);
    let select_box = [];
    res?.data?.variants.forEach((item) => {
      select_box.push({
        label: item.variantName,
        value: item.variantId,
        option: item.ProductVariantOptions,
        date: item.createdAt,
        type: item.variantType,
        isChecked: false,
      });
    });
    dispatch(successFetchingVariant({ variant: select_box }));
  };
};

export const successFetchingVariant = ({ variant }) => {
  return {
    type: SUCCESS_FETCHING_VARIANT,
    variant,
  };
};

export const setCategory = (category) => {
  return {
    type: SET_CATEGORY,
    category,
  };
};

export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};

export const setVariant = (variant) => {
  return {
    type: SET_VARIANT,
    variant,
  };
};

export const setFilterListVariant = (search) => {
  return {
    type: SET_FILTER_LIST_VARIANT,
    search,
  };
};
