import * as React from "react";
import Spinner from "components/atoms/Spinner";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import { deleteData } from "utils/fetchData";
import { useDispatch, useSelector } from "react-redux";
import { setNotif, fetchProduct, setPage, setPerPage } from "features/Product/actions";
import Banner from "components/atoms/Banner";
import Pagination from "components/atoms/Pagination";
import ProductEdit from "./edit";
import ProductDuplicate from "./duplicate";
import { formatRupiah } from "utils/formatRupiah";

export default function ComponentTableProduct({ data, status }) {
  const dispatch = useDispatch();
  const notif = useSelector((state) => state.product.notif);
  const product = useSelector((state) => state.product);
  const [check, setCheck] = React.useState({
    id: "",
    name: "",
    productCount: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [isSweetAlert, setIsSweetAlert] = React.useState(false);
  const [isShowEdit, setIsShowEdit] = React.useState(false);
  const [isShowDuplicate, setIsShowDuplicate] = React.useState(false);
  const [productId, setProductId] = React.useState();

  const handleShowSweetAlert = (payload) => {
    setCheck({
      ...check,
      id: payload.id,
      name: payload.name,
      productCount: payload.productCount,
    });
    setIsSweetAlert(true);
  };

  const handleShowEdit = (payload) => {
    setProductId(payload.id);
    setIsShowEdit(true);
  };
  const handleShowDuplicate = (payload) => {
    setProductId(payload.id);
    setIsShowDuplicate(true);
  };

  const handleDeleteProduct = async (id) => {
    setLoading(true);
    await deleteData(`v2/product/${id}`)
      .then((res) => {
        setLoading(false);
        console.log(res);
        if (res.data?.code === 200) {
          setLoading(false);
          const notif = {
            message: `Produk "${check.name}" berhasil di hapus`,
            type: "primary",
            status: "danger",
            toggle: true,
          };
          dispatch(setNotif(notif));
          dispatch(fetchProduct());
          setIsSweetAlert(false);
          setIsSweetAlert(false);
          setCheck({ id: "", name: "", productCount: "" });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  };
  return (
    <React.Fragment>
      <table className="w-full">
        <thead>
          <tr>
            <th>Nama produk</th>
            <th>Kategori</th>
            <th>Lokasi</th>
            <th>Harga jual</th>
            <th colSpan={2}>Biaya</th>
          </tr>
        </thead>
        <tbody>
          {status === "process" ? (
            <tr key="status">
              <td colSpan={6} style={{ textAlign: "center" }}>
                <div className="flex items-center justify-center">
                  <Spinner size={64} color="#859DFF" />
                </div>
              </td>
            </tr>
          ) : data?.length ? (
            data.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{data.name}</td>
                    <td>{data.category ? data.category.categoryName : "Uncategorized"}</td>
                    <td>{`${data.outlets.length} lokasi`}</td>
                    <td>{`${formatRupiah(data.price)}`}</td>
                    <td>{`${formatRupiah(data.cost)}`}</td>
                    <td className="relative">
                      <Dropdown variant="subtle" color="blue" content="icon only">
                        <DropdownList
                          type="button"
                          onClick={() => handleShowEdit(data)}
                          color="blue"
                        >
                          Ubah produk
                        </DropdownList>
                        <DropdownList
                          type="button"
                          onClick={() => handleShowDuplicate(data)}
                          color="blue"
                        >
                          Duplikat produk
                        </DropdownList>
                        <DropdownList
                          type="button"
                          onClick={() => handleShowSweetAlert(data)}
                          color="red"
                        >
                          Hapus produk
                        </DropdownList>
                      </Dropdown>
                    </td>
                  </tr>
                  {notif.toggle && notif.id === data.id && (
                    <tr>
                      <td colSpan={6} className="p-0">
                        <Banner status={notif.status} type={notif.type} message={notif.message} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                Tidak Ditemukan Data
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* START: PAGINATION */}
      <Pagination
        items={product.pages}
        currentPage={product.page}
        limit={product.limit}
        onPageChange={(page) => dispatch(setPage(page.selected + 1))}
        onChangePerPage={(value) => dispatch(setPerPage(value))}
        label="produk per halaman"
      />
      {/* END: PAGINATION */}

      {/* START: ALERT DELETE */}
      <ModalDialog
        size={"alert"}
        show={isSweetAlert}
        onClose={() => setIsSweetAlert(false)}
        title={<h1 className="text-dark heading-3">Hapus produk</h1>}
      >
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p className="text-2">
            Apakah anda yakin ingin menghapus produk &nbsp;
            <span className="text-blue-03 bold-text-2">“{check.name}”</span>? Aksi ini tidak bisa
            dibatalkan lagi jika produk telah berhasil dihapus.
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
            onClick={() => setIsSweetAlert(false)}
          >
            Batal hapus produk
          </Button>
          <Button
            variant="primary"
            color="red"
            className="my-5"
            type="button"
            loading={loading ? true : false}
            disabled={loading ? true : false}
            onClick={!loading ? () => handleDeleteProduct(check.id) : null}
          >
            Hapus produk
          </Button>
        </div>
      </ModalDialog>
      {/* END: ALERT DELETE */}

      {/* START: EDIT PRODUCT */}
      {isShowEdit && (
        <ProductEdit
          title="Ubah produk"
          product={productId}
          show={isShowEdit}
          close={() => setIsShowEdit(false)}
        />
      )}
      {/* END: EDIT PRODUCT */}

      {/* START: DUPLICATE PRODUCT */}
      {isShowDuplicate && (
        <ProductDuplicate
          title="Duplikat produk"
          product={productId}
          show={isShowDuplicate}
          close={() => setIsShowDuplicate(false)}
        />
      )}
      {/* END: DUPLICATE PRODUCT */}
    </React.Fragment>
  );
}
