import React from "react";
import PropTypes from "prop-types";
import "assets/stylesheets/checkbox.css";
/**
 * type =  default, indeterminate, disabled
 * size = sm, md
 */

export default function CheckBox(props) {
  const { value, name, checked, onChange, disabled, size, label, type } = props;
  const className = [props.className];

  if (size === "md") {
    return (
      <div className={`${className.join(" ")}`}>
        <label
          className={`${type === "default" && !disabled
            ? "wrap-checkbox-large"
            : type === "default" && disabled
              ? "wrap-checkbox-large-disabled"
              : type === "indeterminate" && !disabled
                ? "wrap-checkbox-large-dash"
                : "wrap-checkbox-large-dash-disabled"
            }`}
        >
          <input
            type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            value={value}
            name={name}
          />
          <span
            className={`${type === "default" && !disabled
              ? "ceklist-large"
              : type === "default" && disabled
                ? "ceklist-large-disabled"
                : type === "indeterminate" && !disabled
                  ? "ceklist-large-dash"
                  : "ceklist-large-dash-disabled"
              }`}
          ></span>
        </label>
        {label && (
          <span className="font-normal text-sm relative bottom-2px ml-6 text-gray-04">
            {label}
          </span>
        )}
      </div>
    );
  }

  return (
    <div className={`${className.join(" ")}`}>
      <label
        className={`${type === "default" && !disabled
          ? "wrap-checkbox"
          : type === "default" && disabled
            ? "wrap-checkbox-disabled"
            : type === "indeterminate" && !disabled
              ? "wrap-checkbox-dash"
              : "wrap-checkbox-dash-disabled"
          }`}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          value={value}
          name={name}
        />
        <span
          className={`${type === "default" && !disabled
            ? "ceklist"
            : type === "default" && disabled
              ? "ceklist-disabled"
              : type === "indeterminate" && !disabled
                ? "ceklist-dash"
                : "ceklist-dash-disabled"
            }`}
        ></span>
      </label>
      {label && (
        <span className={`font-normal text-sm relative bottom-2px ml-6 ${disabled ? 'text-gray-04' : 'text-dark hover:text-blue-02'} `}>
          {label}
        </span>
      )}
    </div>
  );
}

CheckBox.defaultProps = {
  disabled: false,
  size: "sm",
  type: "default",
};

CheckBox.propTypes = {
  type: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};
