import * as React from "react";
import PropTypes from "prop-types";
import Icon from "components/atoms/Icons";
import { RiErrorWarningLine } from "react-icons/ri";
export default function Banner(props) {
  const { status, type, message } = props;
  let className = [props.className],
    icon = "";
  if (type === "primary" && status === "success") {
    icon = "FaRegCheckCircle";
    className.push(" bg-green-02 text-white");
  }
  if (type === "secondary" && status === "success") {
    icon = "FaRegCheckCircle";
    className.push(" bg-green-05 text-black");
  }
  if (type === "primary" && status === "danger") {
    icon = "FaRegTrashAlt";
    className.push(" bg-red-02 text-white");
  }
  if (type === "secondary" && status === "danger") {
    icon = "FaRegTrashAlt";
    className.push(" bg-red-05 text-black");
  }
  if (type === "primary" && status === "warning") {
    className.push(" bg-orange-02 text-white");
  }
  if (type === "secondary" && status === "warning") {
    className.push(" bg-orange-05 text-black");
  }

  return (
    status && (
      <div className={`w-full flex items-center justify-center ${className.join(" ")}`}>
        {status === "warning" ? <RiErrorWarningLine /> : <Icon iconName={icon} />}
        <span className={`font-semibold bold-text-1 ml-1 p-2 text-sm`}>{message}</span>
      </div>
    )
  );
}

Banner.propTypes = {
  message: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
};
