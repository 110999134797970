import * as React from 'react'
import propTypes from 'prop-types'
import LogoDalenta from './dalenta'
import LogoSello from './sello'
import LogoVisuo from './visuo'


/**
 * type = dalenta, sello dan visuo
 * content = icon only dan icon wordmark
 * variant = blue, white and gray
 */
function Logo(props) {
  const { type, content, variant } = props
  let fill, width, height, className = [props.className];

  if (variant === 'blue') fill = 'text-blue-03'
  if (variant === 'gray') fill = 'text-gray-03'
  if (variant === 'white') fill = 'text-white'

  return (
    <div className={`${className.join(" ")} ${fill} flex items-center`}>
      {type === 'dalenta' && <LogoDalenta
        className={"mr-2"}
        fill={"currentColor"}
        width={width}
        height={height}
      />}
      {type === 'sello' && <LogoSello
        className={"mr-2"}
        fill={"currentColor"}
        width={width}
        height={height}
      />}
      {type === 'visuo' && <LogoVisuo
        className={"mr-2"}
        fill={"currentColor"}
        width={width}
        height={height}
      />}
      {content === 'icon wordmark' && <span className={`font-semibold currentColor`}>{type === 'dalenta' ? 'DALENTA' : type === 'sello' ? 'Sello' : 'Visuo'}</span>}
    </div>
  )
}

Logo.defaultProps = {
  type: 'dalenta',
  content: 'icon only',
  variant: 'blue',
}

Logo.propTypes = {
  type: propTypes.string,
  content: propTypes.string,
  variant: propTypes.string,
}

export default Logo