import {
  START_FETCHING_SEARCH,
  ERROR_FETCHING_SEARCH,
  SUCCESS_FETCHING_SEARCH,
  SET_KEYWORD,
} from "./constants";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchSearch = debounce(getData, 1000);

export const startFetchingSearch = () => {
  return {
    type: START_FETCHING_SEARCH,
  };
};

export const successFetchingSearch = ({ results }) => {
  return {
    type: SUCCESS_FETCHING_SEARCH,
    results,
  };
};

export const errorFetchingSearch = () => {
  return {
    type: ERROR_FETCHING_SEARCH,
  };
};

export const fetchSearching = () => {
  return async (dispatch, getState) => {
    dispatch(startFetchingSearch());
    let keyword = getState().search.keyword || "";
    const params = {};
    if (keyword !== "") params.keyword = keyword;
    try {
      let {
        data: { data },
      } = await debouncedFetchSearch("search", params);
      let results = data.slice(0, 3);
      dispatch(successFetchingSearch({ results }));
    } catch (error) {
      dispatch(errorFetchingSearch());
    }
  };
};
export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};
