import {
  START_FETCHING_DASHBOARD,
  SUCCESS_FETCHING_DASHBOARD,
  ERROR_FETCHING_DASHBOARD,
  SET_LOCATION,
  SET_DATE,
  SET_BRAND,
  SET_CHART_PAYMENT,
} from "./constants";
import moment from "moment";
import { userLogout } from "features/Auth/actions";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchDashboard = debounce(getData, 1000);

export const startFetchingDashboard = () => {
  return {
    type: START_FETCHING_DASHBOARD,
  };
};

export const errorFetchingDashboard = () => {
  return {
    type: ERROR_FETCHING_DASHBOARD,
  };
};

export const successFetchingDashboard = ({ dashboard, chartView }) => {
  return {
    type: SUCCESS_FETCHING_DASHBOARD,
    dashboard,
    chartView,
  };
};

export const setBrand = (brand) => {
  return {
    type: SET_BRAND,
    brand,
  };
};
export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};
export const setChartPayment = (chartPayment) => {
  return {
    type: SET_CHART_PAYMENT,
    chartPayment,
  };
};

export const setDate = (date) => {
  return {
    type: SET_DATE,
    date: date.selection ? date.selection : date,
  };
};

export const fetchDashboard = () => {
  return async (dispatch, getState) => {
    dispatch(startFetchingDashboard());

    try {
      let start = moment(getState().dashboard.date.startDate).format("YYYY-MM-DD") || "";
      let end = moment(getState().dashboard.date.endDate).format("YYYY-MM-DD") || "";
      let location = getState().dashboard.location || "";
      let limit = 5;

      const params = {
        start,
        end,
        limit,
        location,
      };

      let res = await debouncedFetchDashboard("v2/report/dashboard", params);
      let dashboard = res?.data;
      let color = ["#0099ff", "#28c437", "#db1111", "#e2b102", "#a944cc"];
      let _payment_label = [];
      let _payment_data = [];
      let _payment_color = [];
      let _total_payment = 0;

      //parsing chart top payment & data
      dashboard?.topPayment?.forEach((item, i) => {
        _payment_data.push(item?.total);
        _payment_label.push(item?.name);
        _payment_color.push(color[i]);
        _total_payment += item?.total;
      });
      dispatch(
        setChartPayment({
          label: _payment_label,
          data: _payment_data,
          color: _payment_color,
          total: _total_payment,
        })
      );
      let chartView = { label: [], data: [], count: [] };

      //parsing line chart view
      dashboard.chartView.forEach((chart) => {
        chartView.label.push(chart.x);
        chartView.data.push(chart.y);
        chartView.count.push(chart.count);
      });

      dispatch(successFetchingDashboard({ dashboard, chartView }));
      if (res.data?.code === 401) {
        localStorage.removeItem("auth");
        dispatch(userLogout());
      }
    } catch (err) {
      dispatch(errorFetchingDashboard());
    }
  };
};
