import React, { useEffect } from "react";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import { InputDateRange } from "components/atoms/Form/InputDate/index";
import Button from "components/atoms/Button";
import Table from "components/molecules/Table";
import { useDispatch, useSelector } from 'react-redux'
import { fetchReportTax, setLocation, setDate } from 'features/ReportTax/actions'
import { formatRupiah } from 'utils/formatRupiah'
import Spinner from "components/atoms/Spinner";

export default function PageReportTax() {

  const dispatch = useDispatch()
  const report = useSelector(state => state.reportTax)

  const onChangeDateCostum = (start, end) => {
    const ranges = {
      startDate: start,
      endDate: end,
      key: 'selection',
    }
    dispatch(setDate(ranges))
  }



  React.useEffect(() => {
    dispatch(fetchReportTax())

  }, [dispatch, report.location, report.date]);

  if (report.status === "process") {
    return (
      <div className="flex items-center justify-center mt-56">
        <Spinner size={64} color="#859DFF" />
      </div>
    );
  }


  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <InputDateRange
            onChangeDateCostum={(start, end) => onChangeDateCostum(start, end)}
            variant="range"
            position="items-start"
            type="custom right"
            onChange={(ranges) => dispatch(setDate(ranges))}
            name="date"
            value={report.date}
          />

          <div className="ml-4">
            <Dropdown
              variant="outlined"
              color="gray"
              className="ml-2"
              content="label only"
              label={report.location?.label}
            >
              {report?.outlets.map((outlet) => {
                return (
                  <React.Fragment key={outlet.value}>
                    <DropdownList color="blue" type="button" onClick={() => dispatch(setLocation(outlet))}>{outlet.label}</DropdownList>
                  </React.Fragment>
                )
              })}
            </Dropdown>

          </div>
        </div>
        {/* <Button variant="outlined" color="blue">
          Export laporan
        </Button> */}
      </div>

      <div className="mt-6 mb-6">
        <table className="w-full mt-4">
          <thead>
            <tr>
              <th>Pajak</th>
              <th>Jumlah kena pajak</th>
              <th>Persentase</th>
              <th>Pajak terkumpul</th>
            </tr>
          </thead>

          <tbody>
            {report.table.map((v, i) => (
              <tr key={i}>
                <td>{v.name}</td>
                <td>{formatRupiah(v.total)}</td>
                <td>{`${v.type === 1 ? `${formatRupiah(v.value)}` : `${v.value} %`}`}</td>
                <td>{formatRupiah(v.amount)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr style={{ backgroundColor: '#0066CC', color: 'white' }}>
              <td>Total</td>
              <td></td>
              <td></td>
              <td>{`${formatRupiah(report.table.reduce((a, b) => a + b.amount, 0))}`}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
