import * as React from "react";
import propTypes from "prop-types";
import PinInput from "react-pin-input";
import "assets/stylesheets/pinField.css";

/**
 * size = large, small
 * color = blue, green, red, orange, gray
 */

function Pin(props) {
  const {
    children,
    label,
    disabled,
    color,
    size,
    initialValue,
    length,
    focus,
    type,
    onChange,
  } = props;
  const className = [props.className];
  if (color === "blue" && size === "lg")
    className.push("default-field-input-large");
  if (color === "gray" && size === "lg")
    className.push("disabled-field-input-large");
  if (color === "red" && size === "lg")
    className.push("required-field-input-large");
  if (color === "green" && size === "lg")
    className.push("success-field-input-large");
  if (color === "orange" && size === "lg")
    className.push("notEnought-field-input-large");
  if (color === "blue" && size === "sm") className.push("default-field-input");
  if (color === "gray" && size === "sm") className.push("disabled-field-input");
  if (color === "red" && size === "sm") className.push("required-field-input");
  if (color === "green" && size === "sm") className.push("success-field-input");
  if (color === "orange" && size === "sm")
    className.push("notEnought-field-input");

  if (size === "lg") {
    return children ? (
      <div className={`${className.join(" ")} hovered-pin flex items-start`}>
        {label && (
          <span className="label-input-small-large bold-text-2 text-dark relative top-4 mr-5">
            {" "}
            PIN Label Field{" "}
          </span>
        )}
        <div className="flex flex-col">
          <PinInput
            length={length}
            focus={focus}
            disabled={disabled}
            initialValue={initialValue}
            type={type}
            onChange={onChange}
          />
          {children}
        </div>
      </div>
    ) : (
      <div className={`${className.join(" ")} hovered-pin flex items-center`}>
        {label && (
          <span className="label-input-small-large bold-text-2 text-dark mr-5">
            {" "}
            PIN Label Field{" "}
          </span>
        )}
        <PinInput
          length={length}
          focus={focus}
          disabled={disabled}
          initialValue={initialValue}
          type={type}
          onChange={onChange}
        />
        {children}
      </div>
    );
  }

  if (size === "sm") {
    return (
      <div className={`${className.join(" ")} hovered-pin`}>
        {label && (
          <span className="label-input-small mr-5 bold-text-3 text-dark">
            {" "}
            PIN Label Field{" "}
          </span>
        )}
        <PinInput
          length={length}
          focus={focus}
          initialValue={initialValue}
          type={type}
          onChange={onChange}
        />
        {children}
      </div>
    );
  }
}

Pin.defaultProps = {
  length: 4,
  focus: true,
  type: "numeric",
  size: "lg",
  content: "pin only",
  color: "blue",
  disabled: false,
  initialValue: "0000",
};

Pin.propTypes = {
  length: propTypes.number,
  initialValue: propTypes.string,
  focus: propTypes.bool,
  disabled: propTypes.bool,
  type: propTypes.string,
  variant: propTypes.string,
  size: propTypes.string,
  content: propTypes.string,
  color: propTypes.string,
  label: propTypes.string,
  onChange: propTypes.func,
};

export default Pin;
