import React, { useState } from "react";
import IconAlert from "components/atoms/Icons/Custom/Alert";
import IconCheck from "components/atoms/Icons/Custom/Check";
import IconSearch from "components/atoms/Icons/Custom/Search";
import IconClose from "components/atoms/Icons/Custom/Closes";
import IconArrowDown from "components/atoms/Icons/Custom/ArrowDown";
import IconArrowUp from "components/atoms/Icons/Custom/ArrowUp";
import IconDalenta from "components/atoms/Icons/Custom/LogoDalenta";
import IconSello from "components/atoms/Icons/Custom/LogoSello";
import IconVisio from "components/atoms/Icons/Custom/LogoVisio";
import "components/atoms/Button/style.css";

export const fill = {
  red02: "#B80006",
  green02: "#06884A",
  orange02: "#B84300",
  gray03: "#8F8F8F",
  black: "black",
  white: "white",
};

{
  /* start text field small */
}
function TextFieldSmall({ type, className, placeholder, disabled }) {
  return (
    <input
      className={`text-field-small ${className}`}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
}
{
  /* start text field small */
}

// start text caption
function CaptionRequired() {
  return (
    <div className="flex items-center mt-1">
      <IconAlert fill={fill.red02} />
      <span className="text-red-02 ml-2 text-sm"> This field is required</span>
    </div>
  );
}
function CaptionSuccess() {
  return (
    <div className="flex items-center mt-1">
      <IconCheck fill={fill.green02} />
      <span className="text-green-02 ml-2 text-sm">
        {" "}
        Field validation is successful
      </span>
    </div>
  );
}
function CaptionNotStrongEnought() {
  return (
    <div className="flex items-center mt-1">
      <IconAlert fill={fill.orange02} />
      <span className="text-orange-02 ml-2 text-sm">
        {" "}
        This field is not strong enough
      </span>
    </div>
  );
}
// end text caption

// text unit
function TextUnit({ className }) {
  return (
    <span
      className={`absolute top-1/2 transform -translate-y-1/2 right-4 text-small text-gray-03 font-semibold ${className}`}
    >
      unit
    </span>
  );
}
// icon search
function SearchIcon() {
  return (
    <span className="absolute top-1/2 transform -translate-y-1/2 right-4 text-small text-gray-03 font-semibold">
      <IconSearch />
    </span>
  );
}
// maybe btn swtich
function BtnSwitch({ textColor1, textColor2, bgColor, top }) {
  return (
    <div
      className={`absolute  top-1/2 ${top}  transform -translate-y-1/2 right-4 w-166 bg-blue-05 ${bgColor} flex items-center justify-center py-1 rounded-md`}
    >
      <div
        className={`w-80 bg-white text-blue-03 ${textColor1} text-sm font-semibold rounded-md text-center py-1`}
      >
        Switch 1
      </div>
      <div
        className={`w-80 bg-transparent text-blue-05 ${textColor2} text-sm font-semibold rounded-md text-center py-1`}
      >
        Switch 1
      </div>
    </div>
  );
}

// input area
function TextArea({ placeholder, rows, className, disabled }) {
  return (
    <textarea
      rows={rows}
      className={`text-field-small w-540 ${className}`}
      placeholder={placeholder}
      disabled={disabled}
    ></textarea>
  );
}

// banner
function Banners({ className, textColor, text, icon }) {
  return (
    <div
      className={`w-full flex items-center justify-center bg-green ${className}`}
    >
      {icon}
      <span className={`${textColor}  font-semibold text-xs ml-1 p-2`}>
        {text}
      </span>
    </div>
  );
}

Banners.defaultProps = {
  textColor: "text-white",
};

// modal
function Modal({
  maxWidth,
  width,
  height,
  title,
  content,
  className,
  textButton1,
  textButton2,
  onClick,
  position,
  id,
}) {
  return (
    <div
      id={id}
      className={`fixed ${position} left-0 right-0 z-30 bg-overlay-modal transition-all duration-1000`}
      style={{ backgroundColor: "#adadadd9" }}
    >
      <div
        className={`fixed left-1/2 transform -translate-x-1/2 flex flex-col bg-white-400 justify-between mx-auto ${className} ${maxWidth} ${width} ${height}`}
      >
        {/* header */}
        <div className="flex w-full items-center bg-white justify-center px-5 py-4">
          <div
            className="btn-icons absolute left-5 text-gray-03 hover:text-gray-02 active:text-gray-01 bg-gray-05 p-2"
            onClick={onClick}
          >
            <IconClose fill={"currentColor"} />
          </div>
          <span className="text-xl font-semibold">{title}</span>
        </div>
        {/* content */}
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>{content}</div>
        {/* footer */}
        <div className="px-5 flex justify-between items-center bg-white">
          <button
            className="btn-secondary bg-gray-05 text-blue-03 hover:text-blue-02
          active:text-blue-01 my-5"
          >
            {textButton1}
          </button>
          <button
            className="btn-bg bg-blue-03 text-white hover:bg-blue-02
          active:bg-blue-01 my-5"
          >
            {textButton2}
          </button>
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  width: "w-964",
  maxWidth: "max-w-964",
  title: "Text modal title",
  content: "Content modal",
  height: "h-screen",
  textButton1: "Seondary action",
  textButton2: "Primary action",
  position: "-top-full",
};

// Search Bar
function SeacrhBar({ placeholder, disabled, className }) {
  return (
    <div
      className={`relative w-full text-gray-03 hover:text-blue-03 focus:text-blue-03 border-gray-04 h-max-content ${className}`}
    >
      <IconSearch
        className="absolute transform top-1/2 -translate-y-1/2 left-5 duration-500"
        fill={"currentColor"}
      />
      <input
        className={`text-field-small border w-full pl-12 hover:border-blue-03 focus:border-blue-03 text-black`}
        disabled={disabled}
        placeholder={placeholder}
      />
      <IconClose className="absolute transform top-1/2 -translate-y-1/2 right-5" />
    </div>
  );
}
SeacrhBar.defaultProps = {
  placeholder: "SeacrhBar",
};

// Search Bar Disabled
function SeacrhBarDisabled({ placeholder, disabled, className }) {
  return (
    <div
      className={`relative w-full text-gray-03 border-gray-04 h-max-content${className}`}
    >
      <IconSearch
        className="absolute transform top-1/2 -translate-y-1/2 left-5 duration-500"
        fill={"currentColor"}
      />
      <input
        className={`text-field-small border w-full pl-12 text-black`}
        disabled={disabled}
        placeholder={placeholder}
      />
      <IconClose className="absolute transform top-1/2 -translate-y-1/2 right-5" />
    </div>
  );
}
SeacrhBarDisabled.defaultProps = {
  placeholder: "SeacrhBar",
};
// Select Field Drpdown
function SelectFieldDropdown({
  BtnAction,
  className,
  Alert,
  iconInfo,
  iconInField,
}) {
  // data dummy select
  const data = [
    { id: 1, nama: "data 1" },
    { id: 2, nama: "data 2" },
  ];
  // change border when selected border
  const [borderSelected, setborderSelected] = useState("border-gray-04");
  // change color-text when selected value
  const [textSelected, settextSelected] = useState("text-black");
  // set show select
  const [showSelect, setShowSelect] = useState(false);
  // change value when click
  const [valueDefaultSelect, setvalueDefaultSelect] = useState(
    "Select item(s)"
  );
  // function set show select
  const changestateSelect = () => {
    if (showSelect === true) {
      setShowSelect(false);
    } else {
      setShowSelect(true);
    }
  };
  // function selected value select
  const changeValueoption = (item) => {
    setvalueDefaultSelect(item.nama);
    setShowSelect(false);
    setborderSelected("border-blue-03");
    settextSelected("text-blue-03");
  };
  return (
    <div
      className={`${className} relative flex items-start hover:text-blue-03 group`}
    >
      <p
        className={`whitespace-nowrap group-hover:text-blue-03 mr-5 relative bold-text-2 top-3 currentColor ${textSelected} duration-500`}
      >
        Text field label
      </p>

      {iconInfo}

      <div className="flex flex-col w-full relative">
        <div className="relative w-full text-blue-03">
          <div
            className={`text-field-small group-hover:border-blue-03 w-full item-select text-2 cursor-pointer bg-white duration-500 py-3 flex items-center text-dark hover:text-blue-03 ${borderSelected}`}
            onClick={() => changestateSelect()}
          >
            <p className="currentColor">{iconInField}</p>
            <p className="currentColor">{valueDefaultSelect}</p>
          </div>

          {showSelect === false ? (
            <IconArrowDown
              fill={"currentColor"}
              className={"absolute top-1/2 transform -translate-y-1/2 right-5"}
            />
          ) : (
            <IconArrowUp
              fill={"currentColor"}
              className={"absolute top-1/2 transform -translate-y-1/2 right-5"}
            />
          )}
        </div>

        {showSelect === true ? (
          <ul className="item-list shadow-HeavyDropShadow absolute bg-white rounded-lg top-14 mb-11 w-full left-0 z-10">
            {data.map((item, key) => {
              return (
                <li
                  className="cursor-pointer hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-2"
                  onClick={() => changeValueoption(item)}
                >
                  {item.nama}
                </li>
              );
            })}
            {BtnAction}
          </ul>
        ) : (
          ""
        )}
        {Alert}
      </div>
    </div>
  );
}
SelectFieldDropdown.defaultProps = {
  BtnAction: (
    <button className="bg-green-03 text-white text-center rounded-lg w-full py-2 absolute -bottom-10 left-0 font-semibold text-sm right-0">
      Action
    </button>
  ),
};
//  Select Field Dropdown secondary

function SelectFieldDropdownSecondary({
  className,
  Alert,
  iconInfo,
  itemList1,
  itemList2,
  iconInField,
}) {
  // data dummy select
  const data = [
    { id: 1, nama: "data 1" },
    { id: 2, nama: "data 2" },
  ];
  // change border when selected border
  const [borderSelected, setborderSelected] = useState("border-gray-04");
  // change color-text when selected value
  const [textSelected, settextSelected] = useState("text-black");
  // set show select
  const [showSelect, setShowSelect] = useState(false);
  // change value when click
  const [valueDefaultSelect, setvalueDefaultSelect] = useState("Pilih lokasi");
  // function set show select
  const changestateSelect = () => {
    if (showSelect === true) {
      setShowSelect(false);
    } else {
      setShowSelect(true);
    }
  };
  // function selected value select
  const changeValueoption = (item) => {
    setvalueDefaultSelect(item.nama);
    setShowSelect(false);
    setborderSelected("border-blue-03");
    settextSelected("text-blue-03");
  };
  return (
    <div
      className={`${className} relative flex items-start hover:text-blue-03 group`}
    >
      <p
        className={`whitespace-nowrap group-hover:text-blue-03 mr-5 relative bold-text-2 top-3 currentColor ${textSelected} duration-500`}
      >
        Text field label
      </p>

      {iconInfo}

      <div className="flex flex-col w-full relative">
        <div className={`relative w-full  text-blue-03`}>
          <div
            className={`text-field-small group-hover:border-blue-03 w-full item-select text-2 cursor-pointer bg-white duration-500 py-3 text-dark hover:text-blue-03 ${borderSelected}`}
            onClick={() => changestateSelect()}
          >
            <p className="currentColor">{valueDefaultSelect}</p>

            <p className="currentColor">{iconInField}</p>
          </div>

          {showSelect === false ? (
            <IconArrowDown
              fill={"currentColor"}
              className={"absolute top-1/2 transform -translate-y-1/2 right-5"}
            />
          ) : (
            <IconArrowUp
              fill={"currentColor"}
              className={"absolute top-1/2 transform -translate-y-1/2 right-5"}
            />
          )}
        </div>

        {showSelect === true ? (
          <ul className="item-list shadow-HeavyDropShadow w-full overflow-hidden bg-white rounded-lg absolute top-14 mb-11 z-10">
            {data.map((item, key) => {
              return (
                <li className="flex justify-between cursor-pointer hover:bg-blue-05 duration-500 p-3 group active:text-blue-03 text-2">
                  {/* icon */}
                  {itemList1}

                  {/* input text */}
                  {itemList2}
                </li>
              );
            })}
          </ul>
        ) : (
          ""
        )}
        {Alert}
      </div>
    </div>
  );
}

SelectFieldDropdownSecondary.defaultProps = {
  itemList1: "Select Item",
  itemList2: (
    <input
      className="text-right focus:outline-none bg-transparent"
      placeholder="Input text here"
    />
  ),
};

function SwitchPrimary({ className, textBtn1, textBtn2 }) {
  const [istoggle, setIstoggle] = useState(false);
  return (
    <div
      className={`${className} w-166 h-8  rounded-lg bg-blue-05 flex items-center justify-between px-2px`}
    >
      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          istoggle
            ? "w-20 h-7 rounded-lg bg-white text-blue-03 font-semibold text-sm focus:outline-none outline-none"
            : "w-20 h-7 rounded-lg bg-transparent text-blue-04 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
      >
        {textBtn1}
      </button>

      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          !istoggle
            ? "w-20 h-7 rounded-lg bg-white text-blue-03 font-semibold text-sm focus:outline-none outline-none"
            : "w-20 h-7 rounded-lg bg-transparent text-blue-04 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
      >
        {textBtn2}
      </button>
    </div>
  );
}
SwitchPrimary.defaultProps = {
  textBtn1: "Switch 1",
  textBtn2: "Switch 2",
};

function SwitchSecondary({ className, textBtn1, textBtn2 }) {
  const [istoggle, setIstoggle] = useState(false);
  return (
    <div
      className={`${className} w-166 h-8  rounded-lg bg-blue-05 flex items-center justify-between px-2px`}
    >
      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          istoggle
            ? "w-20 h-7 rounded-lg bg-white text-blue-03 font-semibold text-sm focus:outline-none outline-none"
            : "w-20 h-7 rounded-lg bg-transparent text-blue-03 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
      >
        {textBtn1}
      </button>

      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          !istoggle
            ? "w-20 h-7 rounded-lg bg-white text-blue-03 font-semibold text-sm focus:outline-none outline-none"
            : "w-20 h-7 rounded-lg bg-transparent text-blue-03 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
      >
        {textBtn2}
      </button>
    </div>
  );
}
SwitchSecondary.defaultProps = {
  textBtn1: "Switch 1",
  textBtn2: "Switch 2",
};

function SwitchDisabled({ className, textBtn1, textBtn2 }) {
  const [istoggle, setIstoggle] = useState(false);
  return (
    <div
      className={`${className} w-166 h-8  rounded-lg bg-gray-05 flex items-center justify-between px-2px`}
    >
      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          istoggle
            ? "w-20 h-7 rounded-lg bg-white text-gray-04 font-semibold text-sm focus:outline-none outline-none"
            : "w-20 h-7 rounded-lg bg-transparent text-gray-04 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
        disabled
      >
        {textBtn1}
      </button>

      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          !istoggle
            ? "w-20 h-7 rounded-lg bg-white text-gray-04 font-semibold text-sm focus:outline-none outline-none"
            : "w-20 h-7 rounded-lg bg-transparent text-gray-04 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
        disabled
      >
        {textBtn2}
      </button>
    </div>
  );
}
SwitchDisabled.defaultProps = {
  textBtn1: "Switch 1",
  textBtn2: "Switch 2",
};

function SwitchPrimaryLarge({ className, textBtn1, textBtn2 }) {
  const [istoggle, setIstoggle] = useState(false);
  return (
    <div
      className={`${className} w-540 h-52  rounded-lg bg-blue-05 px-1 flex items-center justify-between`}
    >
      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          istoggle
            ? "w-264 h-44 rounded-lg bg-white text-blue-03 font-semibold text-sm focus:outline-none outline-none"
            : "w-264 h-44 rounded-lg bg-transparent text-blue-04 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
      >
        {textBtn1}
      </button>

      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          !istoggle
            ? "w-264 h-44 rounded-lg bg-white text-blue-03 font-semibold text-sm focus:outline-none outline-none"
            : "w-264 h-44 rounded-lg bg-transparent text-blue-04 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
      >
        {textBtn2}
      </button>
    </div>
  );
}
SwitchPrimaryLarge.defaultProps = {
  textBtn1: "Switch 1",
  textBtn2: "Switch 2",
};

function SwitchSecondaryLarge({ className, textBtn1, textBtn2 }) {
  const [istoggle, setIstoggle] = useState(false);
  return (
    <div
      className={`${className} w-540 h-52  rounded-lg bg-blue-05 px-1 flex items-center justify-between`}
    >
      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          istoggle
            ? "w-264 h-44 rounded-lg bg-white text-blue-03 font-semibold text-sm focus:outline-none outline-none"
            : "w-264 h-44 rounded-lg bg-transparent text-blue-03 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
      >
        {textBtn1}
      </button>

      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          !istoggle
            ? "w-264 h-44 rounded-lg bg-white text-blue-03 font-semibold text-sm focus:outline-none outline-none"
            : "w-264 h-44 rounded-lg bg-transparent text-blue-03 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
      >
        {textBtn2}
      </button>
    </div>
  );
}
SwitchSecondaryLarge.defaultProps = {
  textBtn1: "Switch 1",
  textBtn2: "Switch 2",
};

function SwitchDisabledLarge({ className, textBtn1, textBtn2 }) {
  const [istoggle, setIstoggle] = useState(false);
  return (
    <div
      className={`${className} w-540 h-52  rounded-lg bg-gray-05 px-1 flex items-center justify-between`}
    >
      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          istoggle
            ? "w-264 h-44 rounded-lg bg-white text-gray-04 font-semibold text-sm focus:outline-none outline-none"
            : "w-264 h-44 rounded-lg bg-transparent text-gray-04 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
        disabled
      >
        {textBtn1}
      </button>

      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          !istoggle
            ? "w-264 h-44 rounded-lg bg-white text-gray-04 font-semibold text-sm focus:outline-none outline-none"
            : "w-264 h-44 rounded-lg bg-transparent text-gray-04 font-semibold text-sm focus:outline-none duration-500 outline-none"
        }
        disabled
      >
        {textBtn2}
      </button>
    </div>
  );
}
SwitchDisabledLarge.defaultProps = {
  textBtn1: "Switch 1",
  textBtn2: "Switch 2",
};

function SwitchIconPrimary({ className }) {
  const [istoggle, setIstoggle] = useState(false);
  return (
    <div
      className={`${className} w-102 h-34  rounded-lg bg-blue-05 flex items-center justify-between px-2px`}
    >
      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          istoggle
            ? "flex items-center justify-center w-12 h-7 rounded-lg bg-white focus:outline-none outline-none text-blue-03"
            : "flex items-center justify-center w-12 h-7 text-blue-04 rounded-lg bg-transparent focus:outline-none duration-500 outline-none"
        }
      >
        <IconSearch fill={"currentColor"} />
      </button>

      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          !istoggle
            ? "flex items-center justify-center w-12 h-7 rounded-lg bg-white focus:outline-none outline-none text-blue-03"
            : "flex items-center justify-center w-12 h-7 text-blue-04 rounded-lg bg-transparent focus:outline-none duration-500 outline-none"
        }
      >
        <IconSearch fill={"currentColor"} />
      </button>
    </div>
  );
}

function SwitchIconSecondary({ className }) {
  const [istoggle, setIstoggle] = useState(false);
  return (
    <div
      className={`${className} w-102 h-34  rounded-lg bg-blue-05 flex items-center justify-between px-2px`}
    >
      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          istoggle
            ? "flex items-center justify-center w-12 h-7 rounded-lg bg-white focus:outline-none outline-none text-blue-03"
            : "flex items-center justify-center w-12 h-7 text-blue-03 rounded-lg bg-transparent focus:outline-none duration-500 outline-none"
        }
      >
        <IconSearch fill={"currentColor"} />
      </button>

      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          !istoggle
            ? "flex items-center justify-center w-12 h-7 rounded-lg bg-white focus:outline-none outline-none text-blue-03"
            : "flex items-center justify-center w-12 h-7 text-blue-03 rounded-lg bg-transparent focus:outline-none duration-500 outline-none"
        }
      >
        <IconSearch fill={"currentColor"} />
      </button>
    </div>
  );
}

function SwitchIconDisabled({ className }) {
  const [istoggle, setIstoggle] = useState(false);
  return (
    <div
      className={`${className} w-102 h-34  rounded-lg bg-gray-05 flex items-center justify-between px-2px`}
    >
      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          istoggle
            ? "flex items-center justify-center w-12 h-7 rounded-lg bg-white focus:outline-none outline-none text-gray-04"
            : "flex items-center justify-center w-12 h-7 text-gray-04 rounded-lg bg-transparent focus:outline-none duration-500 outline-none"
        }
        disabled
      >
        <IconSearch fill={"currentColor"} />
      </button>

      <button
        onClick={() => setIstoggle(!istoggle)}
        className={
          !istoggle
            ? "flex items-center justify-center w-12 h-7 rounded-lg bg-white focus:outline-none outline-none text-gray-04"
            : "flex items-center justify-center w-12 h-7 text-gray-04 rounded-lg bg-transparent focus:outline-none duration-500 outline-none"
        }
        disabled
      >
        <IconSearch fill={"currentColor"} />
      </button>
    </div>
  );
}

function ProgressBar({ progressBar, bgProgress, bgProgressBar, className }) {
  return (
    <div
      className={`relative w-full rounded-3px overflow-hidden h-2 ${className} ${bgProgress}`}
    >
      <div
        className={`absolute left-0 top-0 bottom-0 rounded-3px ${progressBar} ${bgProgressBar}`}
      ></div>
    </div>
  );
}

ProgressBar.defaultProps = {
  bgProgress: "bg-gray-05",
  progressBar: "w-1/2",
  bgProgressBar: "bg-gray-02",
};

function LogoDalenta({ className, width, height, label, fill }) {
  return (
    <div className={`${className} ${fill} flex items-center`}>
      <IconDalenta
        className={"mr-2"}
        fill={"currentColor"}
        width={width}
        height={height}
      />
      <span className={`font-semibold currentColor`}>{label}</span>
    </div>
  );
}

LogoDalenta.defaultProps = {
  fill: "text-blue-03",
};
function LogoSello({ className, width, height, label, fill }) {
  return (
    <div className={`${className} ${fill} flex items-center`}>
      <IconSello
        className={"mr-2"}
        fill={"currentColor"}
        width={width}
        height={height}
      />
      <span className={`font-semibold currentColor`}>{label}</span>
    </div>
  );
}

LogoSello.defaultProps = {
  fill: "text-blue-03",
};
function LogoVisio({ className, width, height, label, fill }) {
  return (
    <div className={`${className} ${fill} flex items-center`}>
      <IconVisio
        className={"mr-2"}
        fill={"currentColor"}
        width={width}
        height={height}
      />
      <span className={`font-semibold currentColor`}>{label}</span>
    </div>
  );
}

LogoVisio.defaultProps = {
  fill: "text-blue-03",
};

// Tags Icon

function Tags({
  className,
  placeholder,
  bgInput,
  colorText,
  iconSearch,
  iconClose,
  placeholderColor,
}) {
  const [value, setValue] = useState("Text");

  const [width, setWidth] = useState("42px");
  if (width === "24px") {
    setWidth("42px");
  }

  const handleChange = (e) => {
    setValue(e.target.value);
    setWidth((e.target.value.length + 3) * 8 + "px");
  };

  return (
    <div className="flex">
      <div
        className={`relative flex items-center justify-center rounded-lg py-1 px-1 ${className} ${bgInput}`}
      >
        {iconSearch}

        <input
          value={value}
          placeholder={placeholder}
          className={` rounded-lg text-sm font-semibold bg-transparent text-center focus:outline-none outline-none duration-200 ${colorText} ${placeholderColor} placeholder-current`}
          style={{ width: width }}
          onChange={(e) => handleChange(e)}
        />
        {iconClose}
      </div>
    </div>
  );
}

Tags.defaultProps = {
  placeholder: "Text",
  bgInput: "bg-blue-05",
  colorText: "text-blue-03",
};

// Tab Vertical
function VerticalTab({
  className,
  paddingLeft,
  SearchIcon,
  ArrowIcon,
  fontSize,
  Text,
  hoverBg,
  bgList,
}) {
  return (
    <ul
      className={`${className} w-272px min-w-272px max-w-272px flex flex-col`}
    >
      <li
        className={`pl-4 py-2 ${paddingLeft} rounded-lg hover:${hoverBg} text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center`}
      >
        {SearchIcon}
        <span className={`${fontSize} font-semibold currentColor duration-200`}>
          {Text}
        </span>
        {ArrowIcon}
      </li>
      <li
        className={`pl-4 py-2 ${paddingLeft} ${bgList} rounded-lg duration-200 cursor-pointer text-blue-03 flex items-center`}
      >
        {SearchIcon}
        <span className={`${fontSize} font-semibold currentColor duration-200`}>
          {Text}
        </span>
        {ArrowIcon}
      </li>
    </ul>
  );
}

VerticalTab.defaultProps = {
  fontSize: "text-xl",
  Text: "Tab",
  bgList: "bg-blue-05",
};

function HorizontalTab({ className, SearchIcon, Text, fontSize, active }) {
  return (
    <div
      className={`${className} flex item-center text-gray-03  ${active} hover:text-blue-02 active:border-blue-03 active:text-blue-03 pb-2 border-b-2 border-transparent duration-200 cursor-pointer relative top-2px`}
    >
      {SearchIcon}
      <span className={`${fontSize} font-semibold currentColor duration-200`}>
        {Text}
      </span>
    </div>
  );
}

HorizontalTab.defaultProps = {
  Text: "Tab",
  fontSize: "text-sm",
};

function Tooltips({
  className,
  Heading,
  Content,
  topArrow,
  leftArrow,
  rightArrow,
  bottomArrow,
  btn1,
  btn2,
}) {
  return (
    <div
      className={`absolute z-10 p-4 flex flex-col justify-center transition-all max-w-258px min-w-258px duration-200 rounded-lg bg-gray-02 ${className}`}
    >
      <div
        className={`absolute w-2 h-2 transform rotate-45 bg-gray-02 ${topArrow} ${leftArrow} ${rightArrow} ${bottomArrow}`}
      ></div>
      <h1 className="text-white font-semibold text-xl">{Heading}</h1>
      <p className="text-sm font-normal text-white mt-3">{Content}</p>
      <div className="flex items-center justify-end mt-3">
        {btn1}
        {btn2}
      </div>
    </div>
  );
}

Tooltips.defaultProps = {
  Heading: "Heading",
  Content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum scelerisque tristique praesent non ornare.",
};

function Flags({
  className,
  bgFlag,
  icon,
  titleFlag,
  contentFlag,
  textBtn,
  bgBtn,
}) {
  return (
    <div
      className={` ${className} min-352px max-352px w-352px p-4 flex items-start rounded-lg ${bgFlag}`}
    >
      {icon}
      <div className="ml-3">
        <h1 className="text-white bold-text-2">{titleFlag}</h1>
        <p className="text-white text-2 mt-1">{contentFlag}</p>
        <div className="flex w-full justify-end mt-4">
          <button className={`btn-bg  mr-2 ${bgBtn}`}>{textBtn}</button>
          <button className={`btn-bg  ${bgBtn}`}>{textBtn}</button>
        </div>
      </div>
    </div>
  );
}

Flags.defaultProps = {
  bgFlag: "bg-green-02",
  titleFlag: "Sukses",
  contentFlag:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum scelerisque tristique praesent non ornare. ",
  textBtn: "Action",
  bgBtn: "bg-green-03",
};

// button dropdown
function MenuDropdown({
  className,
  bgBtn,
  borderBtn,
  disabled,
  iconSearch,
  textBtn,
  iconToggle,
  iconArrow,
  paddingX,
  onClick,
  onBlur,
}) {
  return (
    <button
      id="#ccc"
      className={`flex items-center ${paddingX} py-2 rounded-lg outline-none duration-200 focus:outline-none ${bgBtn} ${borderBtn} ${className}`}
      disabled={disabled}
      onClick={onClick}
      onBlur={onBlur}
    >
      {iconSearch}
      <span className="currentColor font-semibold text-sm">{textBtn}</span>
      {iconToggle}
      {iconArrow}
    </button>
  );
}

MenuDropdown.defaultProps = {
  bgBtn: "bg-white",
  paddingX: "px-4",
};

// list dropdown

function ListDropdown({ className, icon, textLabel, Btn, onClick }) {
  return (
    <div className={`${className} max-w-max`} id="egaa">
      <ul
        className={`flex flex-col py-2 shadow-HeavyDropShadow bg-white rounded-lg`}
      >
        <li
          id="egaa"
          className="flex items-center px-4 py-2 text-black hover:text-blue-02 bg-white  hover:bg-blue-05 cursor-pointer duration-200"
          onClick={onClick}
        >
          {icon}
          <span className="currentColor font-normal text-sm">{textLabel}</span>
        </li>
      </ul>
      {Btn}
    </div>
  );
}

ListDropdown.defaultProps = {
  textLabel: "Dropdown Menu",
};

export {
  TextFieldSmall,
  CaptionRequired,
  CaptionSuccess,
  CaptionNotStrongEnought,
  TextUnit,
  SearchIcon,
  BtnSwitch,
  TextArea,
  Banners,
  Modal,
  SeacrhBar,
  SeacrhBarDisabled,
  SelectFieldDropdown,
  SelectFieldDropdownSecondary,
  SwitchPrimary,
  SwitchSecondary,
  SwitchDisabled,
  SwitchPrimaryLarge,
  SwitchSecondaryLarge,
  SwitchDisabledLarge,
  SwitchIconPrimary,
  SwitchIconSecondary,
  SwitchIconDisabled,
  ProgressBar,
  LogoDalenta,
  LogoSello,
  LogoVisio,
  Tags,
  VerticalTab,
  HorizontalTab,
  Tooltips,
  Flags,
  MenuDropdown,
  ListDropdown,
};
