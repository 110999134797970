import * as React from 'react'
import ModalDialog from 'components/molecules/Modal'
import Button from 'components/atoms/Button'

export default function Modal() {
  const [md, setShowMd] = React.useState(false)
  const [lg, setShowLg] = React.useState(false)
  const [sm, setShowSm] = React.useState(false)
  const [alert, setShowAlert] = React.useState(false)

  return (
    <div className="w-10/12 pt-16 mt-5">
      <div className="my-2">
        <Button variant="primary" color="blue" className="mt-3" onClick={() => setShowLg(true)}>
          Button Large
        </Button>
        <Button variant="primary" color="blue" className="mt-3 ml-3" onClick={() => setShowMd(true)}>
          Button Medium
        </Button>
        <Button variant="primary" color="blue" className="mt-3 ml-3" onClick={() => setShowSm(true)}>
          Button Small
        </Button>
        <Button variant="primary" color="blue" className="mt-3 ml-3" onClick={() => setShowAlert(true)}>
          Button Alert
        </Button>
      </div>
      <ModalDialog size={'large'} show={lg} onClose={() => setShowLg(false)} title='title'>
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo aliquam massa sagittis suspendisse. Mi lacus egestas dui accumsan at volutpat vitae magna. Pellentesque tincidunt aliquam pretium massa in aliquet ultricies.
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
          >
            Secondary action
          </Button>
          <Button
            variant="primary"
            color="blue"
            className="my-5"
            type="button"
          >
            Primary action
          </Button>
        </div>
      </ModalDialog>
      <ModalDialog size={'medium'} show={md} onClose={() => setShowMd(false)} title='title'>
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo aliquam massa sagittis suspendisse. Mi lacus egestas dui accumsan at volutpat vitae magna. Pellentesque tincidunt aliquam pretium massa in aliquet ultricies.
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
          >
            Secondary action
          </Button>
          <Button
            variant="primary"
            color="blue"
            className="my-5"
            type="button"
          >
            Primary action
          </Button>
        </div>
      </ModalDialog>
      <ModalDialog size={'small'} show={sm} onClose={() => setShowSm(false)} title='title'>
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo aliquam massa sagittis suspendisse. Mi lacus egestas dui accumsan at volutpat vitae magna. Pellentesque tincidunt aliquam pretium massa in aliquet ultricies.
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
          >
            Secondary action
          </Button>
          <Button
            variant="primary"
            color="blue"
            className="my-5"
            type="button"
          >
            Primary action
          </Button>
        </div>
      </ModalDialog>
      <ModalDialog size={'alert'} show={alert} onClose={() => setShowAlert(false)} title='title'>
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo aliquam massa sagittis suspendisse. Mi lacus egestas dui accumsan at volutpat vitae magna. Pellentesque tincidunt aliquam pretium massa in aliquet ultricies.
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
          >
            Secondary action
          </Button>
          <Button
            variant="primary"
            color="blue"
            className="my-5"
            type="button"
          >
            Primary action
          </Button>
        </div>
      </ModalDialog>
    </div>
  )
}
