import * as React from "react";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import "./style.css";
import propTypes from "prop-types";
import {
  UilAngleDown,
  UilAngleUp,
  UilEllipsisV,
} from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import Icon from "components/atoms/Icons/indexV2";
import "assets/stylesheets/checkbox.css";

/**
 * variant : primary, outlined, textual, subtle
 * color : blue, gray
 * content : label only, icon only, label with icon
 * dropdown list  : checkbox, radio button, label
 * type : button, link, click
 * disabled
 */

function Dropdown(props) {
  const {
    variant,
    color,
    content,
    label,
    children,
    disabled,
    iconName,
    relativePosition,
  } = props;
  const dropdownRef = React.useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const handleIsActive = () => setIsActive(!isActive);

  const className = [props.className];

  // label only and label with icon blue
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "label only" &&
    !disabled
  )
    className.push(
      "bg-blue-05 text-blue-03 hover:text-blue-02 hover:bg-blue-05  active:text-blue-01"
    );
  // label only and label with icon blue, disabled
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "label only" &&
    disabled
  )
    className.push("bg-blue-05 text-blue-04");
  // label only and label with icon blue, disabled
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "label with icon" &&
    !disabled
  )
    className.push(
      "bg-blue-05 text-blue-03 hover:text-blue-02 hover:bg-blue-05  active:text-blue-01"
    );
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "label with icon" &&
    disabled
  )
    className.push("bg-blue-05 text-blue-04");
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "label only" &&
    !disabled
  )
    className.push(
      "text-blue-03 hover:text-blue-02 hover:bg-blue-05  active:text-blue-01 border border-blue-03 hover:border-blue-02 active:border-blue-01"
    );
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "label only" &&
    disabled
  )
    className.push("text-blue-04 border border-blue-04");
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "label with icon" &&
    !disabled
  )
    className.push(
      "text-blue-03 hover:text-blue-02 hover:bg-blue-05  active:text-blue-01 border border-blue-03 hover:border-blue-02 active:border-blue-01"
    );
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "label with icon" &&
    disabled
  )
    className.push("text-blue-04 border border-blue-04");
  if (
    variant === "subtle" &&
    color === "blue" &&
    content === "label only" &&
    !disabled
  )
    className.push(
      "bg-white text-blue-03 hover:text-blue-02 hover:bg-blue-05  active:text-blue-01"
    );
  if (
    variant === "subtle" &&
    color === "blue" &&
    content === "label only" &&
    disabled
  )
    className.push("text-blue-04");
  if (
    variant === "subtle" &&
    color === "blue" &&
    content === "label with icon" &&
    !disabled
  )
    className.push(
      "bg-white text-blue-03 hover:text-blue-02 hover:bg-blue-05  active:text-blue-01"
    );
  if (
    variant === "subtle" &&
    color === "blue" &&
    content === "label with icon" &&
    disabled
  )
    className.push("text-blue-04");
  if (
    variant === "textual" &&
    color === "blue" &&
    content === "label only" &&
    !disabled
  )
    className.push("text-blue-03 hover:text-blue-02 active:text-blue-01");
  if (
    variant === "textual" &&
    color === "blue" &&
    content === "label only" &&
    disabled
  )
    className.push("text-blue-04");
  if (
    variant === "textual" &&
    color === "blue" &&
    content === "label with icon" &&
    !disabled
  )
    className.push("text-blue-03 hover:text-blue-02 active:text-blue-01");
  if (
    variant === "textual" &&
    color === "blue" &&
    content === "label with icon" &&
    disabled
  )
    className.push("text-blue-04");
  // icon only, color blue
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "icon only" &&
    !disabled
  )
    className.push(
      "text-blue-03 hover:text-blue-02 active:text-blue-01 bg-blue-05 px-2"
    );
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "icon only" &&
    disabled
  )
    className.push("bg-blue-05 text-blue-04 px-2");
  if (
    variant === "subtle" &&
    color === "blue" &&
    content === "icon only" &&
    !disabled
  )
    className.push(
      "text-blue-03 hover:text-blue-02 hover:bg-blue-05 active:text-blue-01 px-2"
    );
  if (
    variant === "subtle" &&
    color === "blue" &&
    content === "icon only" &&
    disabled
  )
    className.push("text-blue-04 px-2");
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "icon only" &&
    !disabled
  )
    className.push(
      "text-blue-03 hover:text-blue-02 hover:bg-blue-05  active:text-blue-01 border border-blue-03 hover:border-blue-02 active:border-blue-01 px-2"
    );
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "icon only" &&
    disabled
  )
    className.push("text-blue-04 border border-blue-04 px-2");
  if (
    variant === "textual" &&
    color === "blue" &&
    content === "icon only" &&
    !disabled
  )
    className.push("text-blue-03 hover:text-blue-02 active:text-blue-01 px-2");
  if (
    variant === "textual" &&
    color === "blue" &&
    content === "icon only" &&
    disabled
  )
    className.push("ext-blue-04 px-2");

  // start: gray
  // label only and label with icon gray
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "label only" &&
    !disabled
  )
    className.push(
      "bg-gray-05 text-gray-03 hover:text-gray-02 hover:bg-gray-05  active:text-gray-01"
    );
  // label only and label with icon gray, disabled
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "label only" &&
    disabled
  )
    className.push("bg-gray-05 text-gray-04");
  // label only and label with icon gray, disabled
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "label with icon" &&
    !disabled
  )
    className.push(
      "bg-gray-05 text-gray-03 hover:text-gray-02 hover:bg-gray-05  active:text-gray-01"
    );
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "label with icon" &&
    disabled
  )
    className.push("bg-gray-05 text-gray-04");
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "label only" &&
    !disabled
  )
    className.push(
      "text-gray-03 hover:text-gray-02 hover:bg-gray-05  active:text-gray-01 border border-gray-03 hover:border-gray-02 active:border-gray-01"
    );
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "label only" &&
    disabled
  )
    className.push("text-gray-04 border border-gray-04");
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "label with icon" &&
    !disabled
  )
    className.push(
      "text-gray-03 hover:text-gray-02 hover:bg-gray-05  active:text-gray-01 border border-gray-03 hover:border-gray-02 active:border-gray-01"
    );
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "label with icon" &&
    disabled
  )
    className.push("text-gray-04 border border-gray-04");
  if (
    variant === "subtle" &&
    color === "gray" &&
    content === "label only" &&
    !disabled
  )
    className.push(
      "bg-white text-gray-03 hover:text-gray-02 hover:bg-gray-05  active:text-gray-01"
    );
  if (
    variant === "subtle" &&
    color === "gray" &&
    content === "label only" &&
    disabled
  )
    className.push("text-gray-04");
  if (
    variant === "subtle" &&
    color === "gray" &&
    content === "label with icon" &&
    !disabled
  )
    className.push(
      "bg-white text-gray-03 hover:text-gray-02 hover:bg-gray-05  active:text-gray-01"
    );
  if (
    variant === "subtle" &&
    color === "gray" &&
    content === "label with icon" &&
    disabled
  )
    className.push("text-gray-04");
  if (
    variant === "textual" &&
    color === "gray" &&
    content === "label only" &&
    !disabled
  )
    className.push("text-gray-03 hover:text-gray-02 active:text-gray-01");
  if (
    variant === "textual" &&
    color === "gray" &&
    content === "label only" &&
    disabled
  )
    className.push("text-gray-04");
  if (
    variant === "textual" &&
    color === "gray" &&
    content === "label with icon" &&
    !disabled
  )
    className.push("text-gray-03 hover:text-gray-02 active:text-gray-01");
  if (
    variant === "textual" &&
    color === "gray" &&
    content === "label with icon" &&
    disabled
  )
    className.push("text-gray-04");
  // icon only, color gray
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "icon only" &&
    !disabled
  )
    className.push(
      "text-gray-03 hover:text-gray-02 active:text-gray-01 bg-gray-05 px-2"
    );
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "icon only" &&
    disabled
  )
    className.push("bg-gray-05 text-gray-04 px-2");
  if (
    variant === "subtle" &&
    color === "gray" &&
    content === "icon only" &&
    !disabled
  )
    className.push(
      "text-gray-03 hover:text-gray-02 hover:bg-gray-05 active:text-gray-01 px-2"
    );
  if (
    variant === "subtle" &&
    color === "gray" &&
    content === "icon only" &&
    disabled
  )
    className.push("text-gray-04 px-2");
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "icon only" &&
    !disabled
  )
    className.push(
      "text-gray-03 hover:text-gray-02 hover:bg-gray-05  active:text-gray-01 border border-gray-03 hover:border-gray-02 active:border-gray-01 px-2"
    );
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "icon only" &&
    disabled
  )
    className.push("text-gray-04 border border-gray-04 px-2");
  if (
    variant === "textual" &&
    color === "gray" &&
    content === "icon only" &&
    !disabled
  )
    className.push("text-gray-03 hover:text-gray-02 active:text-gray-01 px-2");
  if (
    variant === "textual" &&
    color === "gray" &&
    content === "icon only" &&
    disabled
  )
    className.push("text-gray-04 px-2");
  // end: gray

  if (content === "icon only") {
    return (
      <div className="menu-container">
        <button
          className={`flex items-center justify-between py-2 rounded-lg outline-none duration-200 focus:outline-none w-full ${className.join(
            " "
          )}`}
          disabled={disabled}
          onClick={handleIsActive}
        >
          <UilEllipsisV size={20} />
        </button>
        <nav
          ref={dropdownRef}
          className={`dropdown absolute z-20 top-12 ${relativePosition} ${
            isActive ? "active" : "inactive"
          }`}
        >
          <ul
            className={`flex flex-col py-2 shadow-HeavyDropShadow bg-white rounded-lg`}
          >
            {children}
          </ul>
        </nav>
      </div>
    );
  }

  return (
    <div className={`menu-container ${props.className}`}>
      <button
        className={`flex items-center px-4 py-2 rounded-lg outline-none duration-200 focus:outline-none w-full justify-between ${className.join(
          " "
        )}`}
        disabled={disabled}
        onClick={handleIsActive}
        style={{
          paddingTop: `${variant === "outlined" && "6px"}`,
          paddingBottom: `${variant === "outlined" && "6px"}`,
        }}
      >
        {content === "label with icon" && (
          <Icon
            className={"mr-2 wrap-size-icon"}
            iconName={iconName}
            iconSize={12}
          />
        )}
        <span className="currentColor font-semibold text-sm">{label}</span>
        {isActive ? (
          <UilAngleUp className="ml-3" />
        ) : (
          <UilAngleDown className="ml-3" />
        )}
      </button>
      <nav
        ref={dropdownRef}
        className={`dropdown absolute z-20 top-12 ${relativePosition} ${
          isActive ? "active" : "inactive"
        }`}
      >
        <ul
          className={`flex flex-col py-2 shadow-HeavyDropShadow bg-white rounded-lg`}
        >
          {children}
        </ul>
      </nav>
    </div>
  );
}

function DropdownList(props) {
  const { type, onClick, color, button, list, checked, width } = props;
  const className = [];
  if (color === "blue")
    className.push("hover:text-blue-02 bg-white  hover:bg-blue-05 text-black");
  if (color === "red")
    className.push("text-red-02 hover:text-red-02 bg-white  hover:bg-red-05");

  // list item
  if (list === "item") {
    return (
      <>
        {type === "link" && (
          <Link to={props.href}>
            <li
              className={`flex items-center px-4 py-2 ${className.join(
                " "
              )} cursor-pointer duration-200`}
            >
              {props.iconName && (
                <Icon iconName={props.iconName} className={"wrap-size-icon"} />
              )}
              <span className="currentColor font-normal text-sm">
                {props.children}
              </span>
            </li>
          </Link>
        )}
        {type === "button" && (
          <li
            onClick={onClick}
            className={`flex items-center px-4 py-2 ${className.join(
              " "
            )} cursor-pointer duration-200`}
          >
            {props.iconName && <Icon iconName={props.iconName} />}
            <span className={`currentColor font-normal text-sm`}>
              {props.children}
            </span>
          </li>
        )}

        {type === "click" && (
          <li
            onClick={onClick}
            className={`flex items-center px-4 py-2 ${className.join(
              " "
            )} cursor-pointer duration-200`}
          >
            {props.iconName && <Icon iconName={props.iconName} />}
            <span className={`currentColor font-normal text-sm`}>
              {props.children}
            </span>
          </li>
        )}
        {button}
      </>
    );
  }

  // list checkbox
  if (list === "checkbox") {
    return (
      <li
        className={`flex items-center px-4 py-2 ${width} ${className.join(
          " "
        )} cursor-pointer duration-200 z-9999`}
        onClick={onClick}
      >
        <div className={"relative bottom-2"}>
          <label className="wrap-checkbox">
            <input
              type="checkbox"
              checked={checked}
              value={checked}
              onChange={onClick}
            />
            <span className="ceklist"></span>
          </label>
        </div>
        <span className="currentColor font-normal text-sm ml-6">
          {props.children}
        </span>
      </li>
    );
  }

  // list radio
  if (list === "radio") {
    return (
      <li
        className={`flex items-center px-4 py-2  ${className.join(
          " "
        )} cursor-pointer duration-200 z-9999`}
        onClick={onClick}
      >
        <div className={`relative`}>
          <label className="wrap-radio">
            <span className="currentColor font-normal text-sm">
              {props.children}
            </span>
            <input
              type="radio"
              className="radio-cs"
              checked={checked}
              value={checked}
              onClick={onClick}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </li>
    );
  }

  // list toggle
  if (list === "toggle") {
    return (
      <li
        className={`flex items-center px-4 py-2  ${className.join(
          " "
        )} cursor-pointer duration-200 z-9999`}
        onClick={onClick}
      >
        <div className={`relative bottom-2`}>
          <label className="wrap-toggle-primary">
            <input
              type="checkbox"
              checked={checked}
              value={checked}
              onChange={onClick}
            />
            <span className="toggle-primary"></span>
            <span className="toggle-default-primary"></span>
          </label>
        </div>
        <span className="currentColor font-normal text-sm ml-10">
          {props.children}
        </span>
      </li>
    );
  }
}

Dropdown.defaultProps = {
  label: "Dropdown button",
  content: "label only",
  iconName: "UilSearch",
  variant: "primary",
  color: "blue",
  relativePosition: "right-0",
};

Dropdown.propTypes = {
  variant: propTypes.oneOf(["primary", "outlined", "textual", "subtle"]),
  color: propTypes.oneOf(["blue", "gray"]),
  content: propTypes.oneOf(["label only", "icon only", "label with icon"]),
  iconName: propTypes.string,
};

DropdownList.defaultProps = {
  type: "button",
  color: "blue",
  button: false,
  list: "item",
  // iconName: "UilSearch",
};

DropdownList.propTypes = {
  type: propTypes.oneOf(["button", "link", "click", "none"]),
  color: propTypes.oneOf(["red", "blue", "black"]),
  button: propTypes.bool,
  iconName: propTypes.string,
  href: propTypes.string,
};

export { Dropdown, DropdownList };
