import * as React from "react";
import { InputDateRange } from "components/atoms/Form/InputDate/index";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Button from "components/atoms/Button";
import { fetchReportPayment, setLocation, setDate } from 'features/ReportPayment/actions'
import { formatRupiah } from 'utils/formatRupiah'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from "components/atoms/Spinner";

export default function PageReportPayment() {

  const dispatch = useDispatch()
  const report = useSelector(state => state.reportPayment)
  const [filterLocation, setFilterLocation] = React.useState({ value: '', label: 'Semua lokasi' });

  React.useEffect(() => {
    dispatch(fetchReportPayment())
  }, [dispatch, report.location, report.date])

  const onSelectLocation = (data) => {
    setFilterLocation(data)
    dispatch(setLocation(data.value))
  }

  const onChangeDateCostum = (start, end) => {
    const ranges = {
      startDate: start,
      endDate: end,
      key: 'selection',
    }
    dispatch(setDate(ranges))
  }

  if (report.status === "process") {
    return (
      <div className="flex items-center justify-center mt-56">
        <Spinner size={64} color="#859DFF" />
      </div>
    );
  }




  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {/* header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <InputDateRange
            onChangeDateCostum={(start, end) => onChangeDateCostum(start, end)}
            variant="range"
            type="custom right"
            onChange={(ranges) => dispatch(setDate(ranges))}
            name="date"
            value={report.date}
          />
          <div className="ml-4">
            <Dropdown
              variant="outlined"
              color="gray"
              className="mr-1"
              content="label only"
              label={filterLocation.label}
            >
              {report.outlets.map((outlet) => {
                return (
                  <React.Fragment key={outlet.value}>
                    <DropdownList color="blue" type="button" onClick={() => onSelectLocation(outlet)}>{outlet.label}</DropdownList>
                  </React.Fragment>
                )
              })}

            </Dropdown>
          </div>
        </div>
        {/* <Button variant="outlined" color="blue">
          Export laporan
        </Button> */}
      </div>
      {/* table */}
      <div className="mt-6">
        <table className="w-full mt-4">
          <thead>
            <tr>
              <th>Metode pembayaran</th>
              <th>Jumlah transaksi</th>
              <th>Dana terkumpul</th>
            </tr>
          </thead>
          <tbody>
            {report.status === "process" ? (
              <tr key="status">
                <td colSpan="3" className="text-center">
                  <div className="flex items-center justify-center">
                    <Spinner size={64} color="#859DFF" />
                  </div>
                </td>
              </tr>
            ) : report.payment?.value ? (
              report?.payment?.value.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <tr>
                      <td>
                        {item.name}
                      </td>
                      <td>{item.trx_length} transaksi</td>
                      <td>
                        {formatRupiah(item.total)}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  <span className="text-gray-02">Tidak ditemukan data</span>
                </td>
              </tr>
            )}



          </tbody>
          <tfoot>
            <tr style={{ backgroundColor: '#0066CC', color: 'white' }}>
              <td>
                Total
              </td>
              <td>
                {report?.payment.total_trx} transaksi
              </td>
              <td>

                {formatRupiah(report?.payment?.gross)}
              </td>
            </tr>
          </tfoot>

        </table>

      </div>
    </div >
  );
}
