import { Dashboard } from "components/atoms/Icons/Custom/Dashboard";
import { Product } from "components/atoms/Icons/Custom/Product";
import { Device } from "components/atoms/Icons/Custom/Device";
import { Customer } from "components/atoms/Icons/Custom/Customer";
import { Transactions } from "components/atoms/Icons/Custom/Transactions";
import { Wallet } from "components/atoms/Icons/Custom/Wallet";
import { Settings } from "components/atoms/Icons/Custom/Settings";
import { Reports } from "components/atoms/Icons/Custom/Reports";
// import { Inventory } from "components/atoms/Icons/Custom/Inventory";
// import { TableManagement } from "components/atoms/Icons/Custom/TableManagement";
// import { Reservation } from "components/atoms/Icons/Custom/Reservation";
// import { MoreApps } from "components/atoms/Icons/Custom/MoreApps";
// import { Megaphone } from "components/atoms/Icons/Custom/Megaphone";

export const list = [
  {
    id: 1,
    name: "Beranda",
    link: "/dashboard",
    icon: <Dashboard />,
  },
  {
    id: 2,
    name: "Produk",
    link: "/product",
    icon: <Product />,
  },
  {
    id: 3,
    name: "Perangkat",
    link: "/device",
    icon: <Device />,
  },
  { id: 4, name: "Pelanggan", link: "/customer", icon: <Customer /> },
  { id: 5, name: "Transaksi", link: "/transaction", icon: <Transactions /> },
  { id: 6, name: "Laporan", link: "/report", icon: <Reports /> },
  {
    id: 7,
    name: "Saldo",
    link: "/balance",
    icon: <Wallet />,
  },
  {
    id: 8,
    name: "Pengaturan",
    link: "/setting",
    icon: <Settings />,
  },
  // {
  //   name: "Inventaris",
  //   link: "/inventory",
  //   icon: <Inventory />,
  // },
  // {
  //   name: "Marketing",
  //   link: "/marketing",
  //   icon: <Megaphone />,
  // },
  // {
  //   name: "Manajemen meja",
  //   link: "/devices",
  //   icon: <TableManagement />,
  // },
  // {
  //   name: "Reservasi",
  //   link: "/devices",
  //   icon: <Reservation />,
  // },
  // {
  //   name: "Fitur lainya",
  //   link: "/more",
  //   icon: <MoreApps />,
  // },
];
