//fetching
export const START_FETCHING_HISTORY = "features/MaterialHistory/START_FETCHING_HISTORY";
export const ERROR_FETCHING_HISTORY = "features/MaterialHistory/ERROR_FETCHING_HISTORY";
export const SUCCESS_FETCHING_HISTORY = "features/MaterialHistory/SUCCESS_FETCHING_HISTORY";

//filter
export const SET_DATE = "features/MaterialHistory/SET_DATE";
export const SET_LOCATION = "features/MaterialHistory/SET_LOCATION";
export const SET_CHANGE = "features/MaterialHistory/SET_CHANGE";
export const SET_PRODUCT = "features/MaterialHistory/SET_PRODUCT";
export const SET_PAGE = "features/MaterialHistory/SET_PAGE";
export const SET_LIMIT = "features/MaterialHistory/SET_LIMIT";
export const SET_TOTAL_PAGE = "features/MaterialHistory/SET_TOTAL_PAGE";
export const SET_MODAL = "features/MaterialHistory/SET_MODAL";
