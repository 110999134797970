import React, { useState } from "react";
import Spinner from "components/atoms/Spinner";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import DeleteAccount from "./delete";
import UpdateAccess from "./update";

export default function TableSettingTeam({ data }) {
  const [id, setId] = useState();
  const [isShowDelete, setisShowDelete] = useState(false);
  const [isShowUpdate, setisShowUpdate] = useState(false);

  const dropAccount = (id) => {
    setId(id);
    setisShowDelete(true);
  };

  const openAccount = (id) => {
    setId(id);
    setisShowUpdate(true);
  };

  return (
    <table className="w-full mt-4">
      <thead>
        <tr>
          <th>Username</th>
          <th>Email</th>
          <th>Akses</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.status === "process" ? (
          <tr key="status">
            <td colSpan="3" className="text-center">
              <div className="flex items-center justify-center">
                <Spinner size={64} color="#859DFF" />
              </div>
            </td>
          </tr>
        ) : data?.data?.length ? (
          data?.data.map((item) => {
            return (
              <tr key={item.id}>
                <td className="text-blue-03">{item?.username}</td>
                <td>{item?.email}</td>
                <td>
                  {item?.access?.map(
                    (current) => current.status && <span key={current.id}>{current.text}, </span>
                  )}
                </td>
                <td>
                  <Dropdown product="subtle" color="white" content="icon only">
                    <DropdownList onClick={() => openAccount(item.id)} type="button" color="blue">
                      Edit akses
                    </DropdownList>
                    <DropdownList onClick={() => dropAccount(item.id)} type="button" color="red">
                      Hapus akses
                    </DropdownList>
                  </Dropdown>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="3" className="text-center">
              <span className="text-gray-02">Tidak ditemukan data</span>
            </td>
          </tr>
        )}
      </tbody>
      {isShowDelete && (
        <DeleteAccount id={id} show={isShowDelete} close={() => setisShowDelete(false)} />
      )}
      {isShowUpdate && (
        <UpdateAccess show={isShowUpdate} close={() => setisShowUpdate(false)} idAccount={id} />
      )}
    </table>
  );
}
