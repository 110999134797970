import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchVariant, setKeyword } from "features/Variant/actions";
import Button from "components/atoms/Button";
import SearchBar from "components/atoms/SearchBar";
import EmptyPage from "components/templates/Empty";
import Banner from "components/atoms/Banner";
import Table from "./tableVariant";
import VariantCreate from "./create";
import EmptyVariant from "assets/icon/undraw_selection_92i4 1.svg";

export default function VariantPage() {
  const dispatch = useDispatch();
  const variant = useSelector((state) => state.variant);

  const [isShowSearch, setIsShowSearch] = React.useState(false);
  const [isShowCreate, setIsShowCreate] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchVariant());
  }, [dispatch, variant.keyword, variant.page, variant.limit]);

  // ambil data error dan convert ke view
  if (variant.status === "error") return <h1>internal server error</h1>;

  if (
    variant.keyword === "" &&
    variant?.data?.length === 0 &&
    variant.status === "success"
  )
    return (
      <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-2">
        <EmptyPage
          title="Variasi"
          text="Tambahkan variasi seperti ukuran, rasa, dan warna untuk berbagai jenis produk Anda. Variasi bisa bersifat yang tidak mempengaruhi harga seperti warna dan rasa, ataupun yang bisa mempegaruhi harga seperti tambah topping atau ukuran porsi."
          image={EmptyVariant}
          label="Tambah variasi baru"
          onClick={() => setIsShowCreate(true)}
        />
        {/* START: CREATE */}
        <VariantCreate
          title="Tambah variant baru"
          show={isShowCreate}
          close={() => setIsShowCreate(false)}
        />
        {/* END: CREATE */}
      </div>
    );

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {/* START: NOTIF */}
      {variant.notif?.toggle && variant.notif.status === "danger" && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          status={variant.notif.status}
          type={variant.notif.type}
          message={variant.notif.message}
        />
      )}
      {/* END: NOTIF */}

      {/* START:: HEADER */}
      <div className="flex items-center justify-between my-2">
        {/* START: HEADER */}
        {isShowSearch ? (
          <SearchBar
            name={variant.keyword}
            value={variant.keyword}
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            onClick={() => setIsShowSearch(!isShowSearch)}
          />
        ) : (
          <Button
            variant="outlined"
            color="gray"
            content="icon infront"
            iconName="UilSearch"
            onClick={() => setIsShowSearch(!isShowSearch)}
          >
            Cari variasi
          </Button>
        )}
        <Button
          variant="primary"
          color="blue"
          className="ml-2 whitespace-nowrap"
          onClick={() => setIsShowCreate(true)}
        >
          Tambah variasi baru
        </Button>
        {/* END: HEADER */}
      </div>
      {/* END:: HEADER */}

      {/* START: TABLE */}
      <div className="overflow-scroll scroll-hidden" style={{ height: "74vh" }}>
        <Table status={variant.status} data={variant.data} />
      </div>
      {/* END: TABLE */}

      {/* START: CREATE */}
      <VariantCreate
        title="Tambah variant baru"
        show={isShowCreate}
        close={() => setIsShowCreate(false)}
      />
      {/* END: CREATE */}
    </div>
  );
}
