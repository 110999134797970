import React from "react";
import propTypes from "prop-types";
import Info from "../InputTextLargeWithLabel/info";

/**
 * variant = default, success, error, warning
 * info = true, false
 */

function InputTextArea(props) {
  const {
    children,
    info,
    variant,
    label,
    onChange,
    onBlur,
    value,
    name,
    placeholder,
    disabled,
    rows,
    spacing,
  } = props;

  const className = [props.className];
  if (variant === "default") className.push("focus:border-blue-03 py-4 text-black");
  if (variant === "disabled") className.push("focus:border-blue-03 py-4 text-black");
  if (variant === "error") className.push("border-red-02 py-4 text-black");
  if (variant === "success") className.push("border-green-02 py-4 text-black");
  if (variant === "warning") className.push("border-orange-02 py-4 text-black");

  return (
    <div className={`flex items-start w-full`}>
      <span
        className={`${spacing} currentColor duration-500 mb-1 text-gray-02 relative bold-text-2 top-5 mr-5 whitespace-nowrap`}
      >
        {label}
      </span>
      {info && (
        <div className="relative top-5">
          <Info />
        </div>
      )}
      <div className="relative w-full">
        <textarea
          rows={rows}
          className={`text-field-small bold-text-2 w-full ${className.join(" ")} ${
            info && " pt-4 ml-5"
          }`}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          name={name}
          value={value}
          onBlur={onBlur}
        />
        {children}
      </div>
    </div>
  );
}

InputTextArea.defaultProps = {
  info: false,
  variant: "default",
  spacing: "mr-5",
  rows: 5,
};

InputTextArea.propTypes = {
  onChange: propTypes.func,
  onBlur: propTypes.func,
  value: propTypes.string,
  spacing: propTypes.string,
  name: propTypes.string,
  info: propTypes.bool,
  rows: propTypes.number,
  variant: propTypes.string,
  label: propTypes.string,
};

export default InputTextArea;
