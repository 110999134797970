import React from 'react'

export default function LogoSello({ width, fill, height, className }) {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.134589 7.02824C-0.0448632 6.84878 -0.0448627 6.55784 0.134589 6.37838L6.37746 0.13551C6.66694 -0.153969 7.1619 0.0510528 7.1619 0.460437L7.1619 12.9462C7.1619 13.3556 6.66694 13.5606 6.37746 13.2711L0.134589 7.02824Z" fill={fill} />
      <path d="M0.134589 7.02824C-0.0448632 6.84878 -0.0448627 6.55784 0.134589 6.37838L6.37746 0.13551C6.66694 -0.153969 7.1619 0.0510528 7.1619 0.460437L7.1619 12.9462C7.1619 13.3556 6.66694 13.5606 6.37746 13.2711L0.134589 7.02824Z" fill={fill} fillOpacity="0.5" />
      <path d="M0.134589 7.02824C-0.0448632 6.84878 -0.0448627 6.55784 0.134589 6.37838L6.37746 0.13551C6.66694 -0.153969 7.1619 0.0510528 7.1619 0.460437L7.1619 12.9462C7.1619 13.3556 6.66694 13.5606 6.37746 13.2711L0.134589 7.02824Z" fill={fill} fillOpacity="0.1" />
      <path d="M17.8654 7.02824C18.0449 6.84878 18.0449 6.55784 17.8654 6.37838L11.6225 0.13551C11.3331 -0.153969 10.8381 0.0510528 10.8381 0.460437V12.9462C10.8381 13.3556 11.3331 13.5606 11.6225 13.2711L17.8654 7.02824Z" fill={fill} />
      <path d="M17.8654 7.02824C18.0449 6.84878 18.0449 6.55784 17.8654 6.37838L11.6225 0.13551C11.3331 -0.153969 10.8381 0.0510528 10.8381 0.460437V12.9462C10.8381 13.3556 11.3331 13.5606 11.6225 13.2711L17.8654 7.02824Z" fill={fill} fillOpacity="0.5" />
      <path d="M17.8654 7.02824C18.0449 6.84878 18.0449 6.55784 17.8654 6.37838L11.6225 0.13551C11.3331 -0.153969 10.8381 0.0510528 10.8381 0.460437V12.9462C10.8381 13.3556 11.3331 13.5606 11.6225 13.2711L17.8654 7.02824Z" fill={fill} fillOpacity="0.1" />
      <defs>
        <linearGradient id="paint0_linear" x1="-4.7625" y1="0.335166" x2="14.1749" y2="15.0796" gradientUnits="userSpaceOnUse">
          <stop offset="0.270833" stopColor="#0025B8" />
          <stop offset="0.572917" stopColor="#1F4BFF" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="1.6082" y1="19.6264" x2="12.0115" y2="-2.81215" gradientUnits="userSpaceOnUse">
          <stop stopColor="#002952" />
          <stop offset="0.578615" stopColor="#1F4BFF" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1="18.0295" y1="-0.0219808" x2="12.5294" y2="14.7231" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EBEEFF" />
          <stop offset="0.123129" stopColor="#859DFF" />
        </linearGradient>
        <linearGradient id="paint3_linear" x1="-4.7625" y1="0.335166" x2="14.1749" y2="15.0796" gradientUnits="userSpaceOnUse">
          <stop offset="0.270833" stopColor="#0025B8" />
          <stop offset="0.572917" stopColor="#1F4BFF" />
        </linearGradient>
        <linearGradient id="paint4_linear" x1="1.6082" y1="19.6264" x2="12.0115" y2="-2.81215" gradientUnits="userSpaceOnUse">
          <stop stopColor="#002952" />
          <stop offset="0.578615" stopColor="#1F4BFF" />
        </linearGradient>
        <linearGradient id="paint5_linear" x1="18.0295" y1="-0.0219808" x2="12.5294" y2="14.7231" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EBEEFF" />
          <stop offset="0.123129" stopColor="#859DFF" />
        </linearGradient>
      </defs>
    </svg>

  )
}

LogoSello.defaultProps = {
  width: '18px',
  height: '18px',
  fill: '#1F4BFF'
}
