import React from "react";
import { VerticalTab } from "./ComponentInput/Index";
import IconSearch from "components/atoms/Icons/Custom/Search";
import IconArrow from "components/atoms/Icons/Custom/ArrowDown";

// icon very much new
import IconDashboard from "components/atoms/Icons/Custom/icon new/Dashboard";
import IconProduct from "components/atoms/Icons/Custom/icon new/Product";
import IconDevices from "components/atoms/Icons/Custom/icon new/Devices";
import IconCustomers from "components/atoms/Icons/Custom/icon new/Customers";
import IconTransactions from "components/atoms/Icons/Custom/icon new/Transactions";
import IconReports from "components/atoms/Icons/Custom/icon new/Reports";
import IconWallet from "components/atoms/Icons/Custom/icon new/Wallet";
import IconSettings from "components/atoms/Icons/Custom/icon new/Settings";
import IconInventory from "components/atoms/Icons/Custom/icon new/Inventory";
import IconMarketing from "components/atoms/Icons/Custom/icon new/Marketing";
import IconTableManagement from "components/atoms/Icons/Custom/icon new/TableManagement";
import IconReservation from "components/atoms/Icons/Custom/icon new/Reservation";
import IconMoreApps from "components/atoms/Icons/Custom/icon new/MoreApps";

export default function TabVertical() {
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="flex flex-col">
        {/* start List 1 */}

        <div className="flex items-center">
          <VerticalTab
            SearchIcon={
              <IconSearch
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
            }
          />
          <VerticalTab className={"ml-5"} />
        </div>
        {/* end List 1 */}
        {/* start List 2 */}
        <div className="flex items-center mt-10">
          <VerticalTab fontSize={"text-md"} />
          <VerticalTab
            bgList={"bg-transparent"}
            className={"ml-10"}
            fontSize={"text-md"}
            ArrowIcon={
              <IconArrow
                className={"ml-3"}
                width={"9px"}
                height={"5px"}
                fill={"currentColor"}
              />
            }
          />
          <VerticalTab
            className="ml-10"
            fontSize={"text-md"}
            Text={"Subtab"}
            paddingLeft={"pl-10"}
          />
        </div>
        {/* end List 2 */}
        {/* start List 3 */}
        <div className="flex items-center mt-10">
          <VerticalTab
            fontSize={"text-md"}
            SearchIcon={<IconSearch fill={"currentColor"} className="mr-3" />}
          />
          <VerticalTab
            bgList={"bg-transparent"}
            className={"ml-5"}
            fontSize={"text-md"}
            SearchIcon={<IconSearch fill={"currentColor"} className="mr-3" />}
            ArrowIcon={<IconArrow fill={"currentColor"} className="ml-3" />}
          />

          <VerticalTab
            className={"ml-5"}
            fontSize={"text-md"}
            paddingLeft={"pl-10"}
            SearchIcon={<IconSearch fill={"currentColor"} className="mr-3" />}
            Text={"Subtab"}
          />
        </div>
        {/* end List 3 */}

        <div className="flex items-start mt-20">
          {/* start list+icon search manual 1 */}
          <ul className="w-272px min-w-272px max-w-272px flex flex-col">
            {/* start list active */}
            <li className="pl-3 py-2  rounded-lg bg-blue-05  text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconSearch
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Tab 1
              </span>
            </li>
            {/* end list active */}
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconSearch
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Tab 1
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconSearch
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Tab 1
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconSearch
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Tab 1
              </span>
            </li>
          </ul>
          {/* end list+icon search manual 1 */}

          {/* start list manual small */}
          <ul className="w-272px min-w-272px max-w-272px flex flex-col ml-20">
            {/* start list active */}
            <li className="pl-3 py-2  rounded-lg bg-blue-05  text-blue-02  duration-200 cursor-pointer flex items-center">
              <span className="text-base font-semibold currentColor duration-200">
                Tab 1
              </span>
            </li>
            {/* end list active */}
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <span className="text-base font-semibold currentColor duration-200">
                Tab 1
              </span>
            </li>

            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <span className="text-base font-semibold currentColor duration-200">
                Tab 1
              </span>
            </li>

            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <span className="text-base font-semibold currentColor duration-200">
                Tab 1
              </span>
            </li>
          </ul>

          {/* end list manual small */}

          {/* start list manual 1 */}
          <ul className="w-272px min-w-272px max-w-272px flex flex-col ml-20">
            {/* start list active */}
            <li className="pl-3 py-2  rounded-lg bg-blue-05  text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconDashboard
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Dashboard
              </span>
            </li>
            {/* end list active */}
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconProduct
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Product
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconDevices
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Devices
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconCustomers
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Customers
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconTransactions
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Transactions
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconReports
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Reports
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconWallet
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Wallet
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconSettings
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Settings
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconInventory
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Inventory
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconMarketing
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Marketing
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconTableManagement
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                TableManagement
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconReservation
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Reservation
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconMoreApps
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                MoreApps
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconSearch
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Tab 14
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconSearch
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Tab 15
              </span>
            </li>
            <li className="pl-3 py-2  rounded-lg  text-gray-03 hover:text-blue-02  duration-200 cursor-pointer flex items-center">
              <IconSearch
                fill={"currentColor"}
                className="mr-3"
                width={"20px"}
              />
              <span className="text-xl font-semibold currentColor duration-200">
                Tab 16
              </span>
            </li>
          </ul>
          {/* end list manual 1 */}
        </div>
      </div>
    </div>
  );
}
