import React from "react";
import Button from "components/atoms/Button";
import SearchBar from "components/atoms/SearchBar";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Pagination from "components/atoms/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchStock, setKeyword, setPage, setLimit } from "features/Stock/actions";
import Table from "./tableStock";
import Banner from "components/atoms/Banner";

function Stock() {
  const dispatch = useDispatch();
  const [isShowSearch, setIsShowSearch] = React.useState(false);
  const inventory = useSelector((state) => state.inventory);

  React.useEffect(() => {
    dispatch(fetchStock());
  }, [dispatch, inventory.keyword, inventory.page, inventory.limit]);
  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {inventory.notif?.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={inventory.notif.message}
          status={inventory.notif.status}
          type={inventory.notif.type}
        />
      )}
      <div className="flex items-center justify-between my-5">
        <div className="w-8/12">
          {isShowSearch ? (
            <SearchBar
              onChange={(e) => dispatch(setKeyword(e.target.value))}
              onClick={() => setIsShowSearch(!isShowSearch)}
            />
          ) : (
            <Button
              variant="outlined"
              color="gray"
              content="icon infront"
              iconName="UilSearch"
              onClick={() => setIsShowSearch(!isShowSearch)}
            >
              Cari produk
            </Button>
          )}
        </div>
        <div className="w-2/12"></div>
        <div className="w-2/12">
          <Dropdown label="Aksi lainya" variant="outlined" color="blue" content="label only">
            <DropdownList type="link" href="/product/inventory/barcode">
              Buat barcode product
            </DropdownList>
            <DropdownList>Ekspor data stok</DropdownList>
            <DropdownList type="link" href="/product/inventory/transfer-stock">
              Transfer stok
            </DropdownList>
          </Dropdown>
        </div>
      </div>
      <Table data={inventory} />
      {inventory.data.length > 0 ? (
        <div className="mt-5">
          <Pagination
            items={inventory.totalPage}
            currentPage={inventory.page}
            limit={inventory.limit}
            onPageChange={(page) => dispatch(setPage(page.selected + 1))}
            onChangePerPage={(value) => dispatch(setLimit(value))}
            label="stock per halaman"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Stock;
