import React from 'react'

export default function LogoVisio({ width, fill, height, className }) {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.99987 1.51677C5.47991 0.527841 7.21997 0 9 0C10.1819 0 11.3522 0.232792 12.4442 0.685084C13.5361 1.13738 14.5282 1.80031 15.364 2.63604C16.1997 3.47177 16.8626 4.46392 17.3149 5.55585C17.7672 6.64778 18 7.8181 18 9C18 10.78 17.4722 12.5201 16.4832 14.0001C15.4943 15.4802 14.0887 16.6337 12.4442 17.3149C10.7996 17.9961 8.99002 18.1743 7.24419 17.8271C5.49836 17.4798 3.89471 16.6226 2.63604 15.364C1.37737 14.1053 0.520203 12.5016 0.172937 10.7558C-0.17433 9.00998 0.0038995 7.20038 0.685088 5.55585C1.36628 3.91131 2.51983 2.50571 3.99987 1.51677ZM5.79992 13.7893C6.74714 14.4222 7.86078 14.76 9 14.76C10.5276 14.76 11.9927 14.1531 13.0729 13.0729C14.1531 11.9927 14.76 10.5276 14.76 9C14.76 7.86078 14.4222 6.74714 13.7893 5.79991C13.1563 4.85269 12.2568 4.11441 11.2043 3.67845C10.1518 3.24249 8.99361 3.12842 7.87628 3.35068C6.75895 3.57293 5.73262 4.12151 4.92707 4.92706C4.12152 5.73261 3.57293 6.75895 3.35068 7.87628C3.12843 8.99361 3.2425 10.1517 3.67846 11.2043C4.11442 12.2568 4.85269 13.1563 5.79992 13.7893Z" fill={fill} />
      <path d="M12.6 9C12.6 10.9882 10.9882 12.6 9 12.6C7.01177 12.6 5.4 10.9882 5.4 9C5.4 7.01177 7.01177 5.4 9 5.4C10.9882 5.4 12.6 7.01177 12.6 9Z" fill={fill} />
    </svg>

  )
}

LogoVisio.defaultProps = {
  width: '18px',
  height: '18px',
  fill: '#1F4BFF'
}
