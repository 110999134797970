import React from "react";
import TextCaption from "components/atoms/Text/TextCaption";

export default function TextCaptions() {
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="my-4">
        <TextCaption status="error" message="This field is required" />
      </div>
      <div className="my-4">
        <TextCaption
          status="success"
          message="Field validation is successful"
        />
      </div>
      <div className="my-4">
        <TextCaption
          status="warning"
          message="This field is not strong enough"
        />
      </div>
      <div className="my-4">
        <TextCaption
          status="default"
          message="Additional text"
        />
      </div>
    </div>
  );
}
