import React from "react";
import "assets/stylesheets/checkbox.css";

function CheckboxPrimary({ className, label }) {
  return (
    <div className={className}>
      <label className="wrap-checkbox">
        <input type="checkbox" />
        <span className="ceklist"></span>
      </label>
      {label}
      {/* <span className="ml-5 font-normal text-sm relative bottom-2px ml-6">Checkbox</span> */}
    </div>
  );
}
function CheckboxSecondary({ className, label, checked, onChange, value, name }) {
  return (
    <div className={className}>
      <label className="wrap-checkbox-secondary">
        <input type="checkbox" checked={checked} onChange={onChange} value={value} name={name} />
        <span className="ceklist-secondary"></span>
      </label>
      {label}
      {/* <span className="ml-5 font-normal text-sm relative bottom-2px ml-6">Checkbox</span> */}
    </div>
  );
}
function CheckboxDisabled({ className, label }) {
  return (
    <div className={className}>
      <label className="wrap-checkbox-disabled">
        <input type="checkbox" disabled checked="checked" />
        <span className="ceklist-disabled"></span>
      </label>
      {label}
      {/* <span className="ml-5 font-normal text-sm relative bottom-2px ml-6">Checkbox</span> */}
    </div>
  );
}
function CheckboxDashPrimary({ className, label }) {
  return (
    <div className={className}>
      <label className="wrap-checkbox-dash">
        <input type="checkbox" />
        <span className="ceklist-dash"></span>
      </label>
      {label}
      {/* <span className="ml-5 font-normal text-sm relative bottom-2px ml-6">Checkbox</span> */}
    </div>
  );
}
function CheckboxDashSecondary({ className, label }) {
  return (
    <div className={className}>
      <label className="wrap-checkbox-dash-secondary">
        <input type="checkbox" />
        <span className="ceklist-dash-secondary"></span>
      </label>
      {label}
      {/* <span className="ml-5 font-normal text-sm relative bottom-2px ml-6">Checkbox</span> */}
    </div>
  );
}
function CheckboxDashSecondaryDisabled({ className, label }) {
  return (
    <div className={className}>
      <label className="wrap-checkbox-dash-disabled">
        <input type="checkbox" checked="checked" disabled />
        <span className="ceklist-dash-disabled"></span>
      </label>
      {label}
      {/* <span className="ml-5 font-normal text-sm relative bottom-2px ml-6">Checkbox</span> */}
    </div>
  );
}
function CheckboxPrimaryLarge({ className, label }) {
  return (
    <div className={className}>
      <label className="wrap-checkbox-large">
        <input type="checkbox" />
        <span className="ceklist-large"></span>
      </label>
      {label}
      {/* <span className="ml-5 font-normal text-sm relative bottom-2px ml-6">Checkbox</span> */}
    </div>
  );
}
function CheckboxSecondaryLarge({ className, label }) {
  return (
    <div className={className}>
      <label className="wrap-checkbox-large-secondary">
        <input type="checkbox" />
        <span className="ceklist-large-secondary"></span>
      </label>
      {label}
      {/* <span className="ml-5 font-normal text-sm relative bottom-2px ml-6">Checkbox</span> */}
    </div>
  );
}
function CheckboxDashPrimaryLarge({ className, label }) {
  return (
    <div className={className}>
      <label className="wrap-checkbox-large-dash">
        <input type="checkbox" />
        <span className="ceklist-large-dash"></span>
      </label>
      {label}
      {/* <span className="ml-5 font-normal text-sm relative bottom-2px ml-6">Checkbox</span> */}
    </div>
  );
}
function CheckboxDashSecondaryLarge({ className, label }) {
  return (
    <div className={className}>
      <label className="wrap-checkbox-large-dash-secondary">
        <input type="checkbox" />
        <span className="ceklist-large-dash-secondary"></span>
      </label>
      {label}
      {/* <span className="ml-5 font-normal text-sm relative bottom-2px ml-6">Checkbox</span> */}
    </div>
  );
}
function CheckboxDashDisabled({ className, label }) {
  return (
    <div className={className}>
      <label className="wrap-checkbox-large-disabled">
        <input type="checkbox" checked="checked" disabled />
        <span className="ceklist-large-disabled"></span>
      </label>
      {label}
      {/* <span className="ml-5 font-normal text-sm relative bottom-2px ml-6">Checkbox</span> */}
    </div>
  );
}

export {
  CheckboxPrimary,
  CheckboxSecondary,
  CheckboxDisabled,
  CheckboxDashPrimary,
  CheckboxDashSecondary,
  CheckboxDashSecondaryDisabled,
  CheckboxPrimaryLarge,
  CheckboxSecondaryLarge,
  CheckboxDashPrimaryLarge,
  CheckboxDashSecondaryLarge,
  CheckboxDashDisabled,
};
