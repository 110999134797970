export const START_FETCHING_REVIEW = "features/CustomerReview/START_FETCHING_REVIEW";
export const ERROR_FETCHING_REVIEW = "features/CustomerReview/ERROR_FETCHING_REVIEW";
export const SUCCESS_FETCHING_REVIEW = "features/CustomerReview/SUCCESS_FETCHING_REVIEW";

export const SET_DATE = "features/Transaction/SET_DATE";
export const SET_NOTIF = "features/CustomerReview/SET_NOTIF";
export const SET_PAGE = "features/CustomerReview/SET_PAGE";
export const SET_LIMIT = "features/CustomerReview/SET_LIMIT";
export const SET_TOTAL_PAGE = "features/CustomerReview/SET_TOTAL_PAGE";
export const SET_GROUP = "features/CustomerReview/SET_GROUP";
export const SET_ASPECT = "features/CustomerReview/SET_ASPECT";
export const SET_TYPE = "features/CustomerReview/SET_TYPE";
export const SET_LOCATION = "features/CustomerReview/SET_LOCATION";

export const SET_POSITIF = "features/CustomerReview/SET_POSITIF";
export const SET_NEGATIF = "features/CustomerReview/SET_NEGATIF";
