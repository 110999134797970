import React from "react";

export default function Arrow({ width, height, className, fill }) {
  return (
    <svg
      width={width}
      className={className}
      height={height}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.53974 6.28955L1.87974 0.639548C1.78677 0.54582 1.67617 0.471426 1.55431 0.420657C1.43246 0.369888 1.30175 0.34375 1.16974 0.34375C1.03773 0.34375 0.90702 0.369888 0.785161 0.420657C0.663302 0.471426 0.552701 0.54582 0.459738 0.639548C0.273487 0.826911 0.168945 1.08036 0.168945 1.34455C0.168945 1.60873 0.273487 1.86219 0.459738 2.04955L5.40974 7.04955L0.459738 11.9995C0.273487 12.1869 0.168945 12.4404 0.168945 12.7045C0.168945 12.9687 0.273487 13.2222 0.459738 13.4095C0.552352 13.504 0.662797 13.5792 0.784673 13.6307C0.906548 13.6822 1.03743 13.709 1.16974 13.7095C1.30204 13.709 1.43293 13.6822 1.5548 13.6307C1.67668 13.5792 1.78712 13.504 1.87974 13.4095L7.53974 7.75955C7.64124 7.66591 7.72225 7.55225 7.77766 7.42576C7.83307 7.29926 7.86167 7.16265 7.86167 7.02455C7.86167 6.88645 7.83307 6.74984 7.77766 6.62334C7.72225 6.49684 7.64124 6.38319 7.53974 6.28955Z"
        fill={fill}
      />
    </svg>
  );
}

Arrow.defaultProps = {
  width: "24",
  height: "24",
  fillL: "#0066CC",
};
