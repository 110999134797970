import * as React from "react";
import "./index.css";
import Logo, { size } from "components/atoms/Icons/Custom/Logo";
import Button from "components/atoms/Button";
import Leftbar from "components/organisms/Leftbar";
import DropDown from "components/atoms/Dropdown";
import Guide from "./guide";
import SearchBar from "components/atoms/SearchBar";
import Spinner from "components/atoms/Spinner";
import { getData } from "utils/fetchData";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchSearching, setKeyword } from "features/GlobalSearch/actions";

function TopBar({ location }) {
  const dispatch = useDispatch();

  const [brand, setBrand] = React.useState("");
  const [position, setPosition] = React.useState(false);
  const [isShowGuide, setIsShowGuide] = React.useState(false);
  const [isShowSearch, setIsShowSearch] = React.useState(false);
  const showLeftbar = () => setPosition(!position);

  const search = useSelector((state) => state.search);
  console.log("search?.results");
  console.log(search?.results);

  React.useEffect(() => {
    const getBrand = async () => {
      await getData(`accounts/brand`)
        .then((res) => {
          // console.log("TOPBAR", res);
          if (res.data.code === 401) {
            window.location.href = "/signin";
          } else {
            setBrand(res.data.data.name);
          }
        })
        .catch((err) => console.log(err));
    };
    getBrand();
  }, []);

  React.useEffect(() => {
    dispatch(fetchSearching());
  }, [dispatch, search.keyword]);

  return (
    <div className="topbar">
      <Leftbar position={position ? "leftbar-transition" : ""} setHide={showLeftbar} />
      <Guide show={isShowGuide} close={() => setIsShowGuide(false)} />
      <div className="flex pr-5 self-center">
        <Button
          variant="secondary"
          color="gray"
          content="icon only"
          iconName="UilBars"
          onClick={showLeftbar}
        />
        <p className="heading-3 ml-5 self-center">{location}</p>
      </div>
      {isShowSearch ? (
        <div className="relative w-full flex items-center">
          <SearchBar
            value={search.keyword}
            position="absolute top-1/2 transform -translate-y-1/2"
            zIndex="z-10"
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            onClick={() => setIsShowSearch(!isShowSearch)}
          />
          <ul
            className="absolute overflow-y-scroll scroll-hidden left-0 item-list shadow-HeavyDropShadow bg-white rounded-lg mt-3 mb-6 top-12"
            style={{ zIndex: "99999999", maxHeight: "14rem", width: "84%" }}
          >
            {search.status === "process" ? (
              <div className="flex items-center justify-center p-12">
                <Spinner size={64} color="#859DFF" />
              </div>
            ) : search.results.length === 0 && search.keyword.length !== 0 ? (
              <div className="flex items-center p-12 justify-center">
                <span>Tidak ditemukan hasil</span>
              </div>
            ) : (
              search?.results?.map((item) => {
                return (
                  <li
                    key={item?.id}
                    className="cursor-pointer hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm"
                  >
                    <Link to={item?.link}>
                      <div className="flex items-center justify-between">
                        <p>{item?.name}</p>
                        <p className="text-blue-03 font-semibold">{item?.module}</p>
                      </div>
                    </Link>
                  </li>
                );
              })
            )}
          </ul>
          <span className="ml-5">
            <DropDown type="header" value={brand} />
          </span>
        </div>
      ) : (
        <React.Fragment>
          <Link to="/dashboard" className="self-center ml-56">
            <Logo size={size.sm} />
          </Link>
          <div className="flex self-center justify-between">
            <Button
              onClick={() => setIsShowGuide(true)}
              content="label only"
              variant="primary"
              color="green"
            >
              Panduan Sello
            </Button>
            <div className="flex mx-3">
              <Button
                onClick={() => setIsShowSearch(!isShowSearch)}
                variant="secondary"
                color="gray"
                content="icon only"
                iconName="UilSearch"
                className="mr-2"
              />
              {/* <Button
            iconName="UilBell"
            variant="secondary"
            color="gray"
            content="icon only"
            className="mr-2"
          />
          <Button
            iconName="UilQuestionCircle"
            variant="secondary"
            color="gray"
            content="icon only"
          /> */}
            </div>
            <DropDown type="header" value={brand} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default TopBar;
