export const START_FETCHING_LOG = "features/Customer/START_FETCHING_LOG";
export const ERROR_FETCHING_LOG = "features/Customer/ERROR_FETCHING_LOG";
export const SUCCESS_FETCHING_LOG = "features/Customer/SUCCESS_FETCHING_LOG";
export const SET_DATE = "features/Customer/SET_DATE";
export const SET_ACTIVITY = "features/Customer/SET_ACTIVITY";
export const SET_USER = "features/Customer/SET_USER";
export const SET_NOTIF = "features/Customer/SET_NOTIF";
export const SET_PAGE = "features/Customer/SET_PAGE";
export const SET_LIMIT = "features/Customer/SET_LIMIT";
export const SET_TOTAL_PAGE = "features/Customer/SET_TOTAL_PAGE";
export const SET_MODULE = "features/Customer/SET_MODULE";
