import React from "react";
import PropTypes from "prop-types";

import { optionsForGraphic, typesGraphic } from "./constants";

function Graphic({ type, data, width, height, multi, dateOld, dateNow }) {
  const Graphic = type && typesGraphic[type];
  return (
    <Graphic
      data={data}
      width={width}
      height={height}
      options={optionsForGraphic(type, data.id, data.currency, multi, dateOld, dateNow)}
    />
  );
}

Graphic.propTypes = {
  data: PropTypes.shape({
    currency: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.number)
      })
    )
  }).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  type: PropTypes.string,
  id: PropTypes.number
};

export default Graphic;
