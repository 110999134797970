import * as React from "react";
import SelectBox from "components/atoms/SelectBox";
import IconSearch from "components/atoms/Icons/Custom/Search";
import TextCaption from "components/atoms/Text/TextCaption";
import Button from "components/atoms/Button";
import Checkbox from "components/atoms/Checkbox";

export default function SelectBoxs() {
  const [value, setValue] = React.useState({
    label: "Select item(s)",
    value: "",

  });
  const [valueCheckbox, setValueCheckbox] = React.useState({
    label: "Select item(s)",
    value: "",
    isChecked: true,
    all: true
  });
  const [checkbox, setCheckbox] = React.useState([
    {
      label: "name 1",
      value: "value 1",
      isChecked: true,
    },
    {
      label: "name 2",
      value: "value 2",
      isChecked: true,
    },
    {
      label: "name 3",
      value: "value 3",
      isChecked: true,
    },
    {
      label: "name 4",
      value: "value 4",
      isChecked: true,
    },
  ])

  console.log("checkbox")
  console.log(checkbox)
  const text = [
    {
      label: "name 1",
      value: "value 2",
    },
    {
      label: "name 2",
      value: "value 2",
    },
  ];

  const textadditional = [
    {
      label: "name 1",
      value: "value 1",
      sub: "text additional",
    },
    {
      label: "name 2",
      value: "value 2",
      sub: "text additional",
    },
  ];

  const onChange = (e) => {
    console.log(e)
  };

  const onChangeCheckBox = (data) => {
    let check = [];
    for (let i = 0; i < checkbox.length; i++) {
      if (checkbox[i].value === data.value) {
        check.push({
          ...checkbox[i],
          isChecked: data.isChecked
            ? (data.isChecked = false)
            : (data.isChecked = true),
        });
      } else {
        check.push(checkbox[i]);
      }
    }
    setCheckbox(check);
    let sum = 0;
    check.forEach(obj => {
      if (obj.isChecked === true) sum += 1
    })

    setValueCheckbox({
      label: sum === checkbox.length ?
        'Semua item(s)' : `${sum} checkbox`,
      value: 'sesuaikan kebutuhan',
      isChecked: sum === checkbox.length ? true : false
    })


  };

  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      {/* text */}
      <SelectBox type="text" value={value} options={text} onChange={onChange} />
      <SelectBox
        type="text"
        value={value}
        options={text}
        onChange={onChange}
        iconInput={
          <IconSearch width="16" height="16" fill="#1F4BFF" className="mr-4" />
        }
        className="mt-2"
      />
      <SelectBox
        type="text additional"
        value={value}
        options={textadditional}
        onChange={onChange}
        className="mt-2"
      />
      <SelectBox
        className="mt-2"
        border="border-red-03"
        colorText="text-red-03"
        type="text"
        value={value}
        options={text}
        onChange={onChange}
        alert={<TextCaption status="error" />}
      />
      <SelectBox
        className="mt-2"
        border="border-green-03"
        colorText="text-green-03"
        type="text"
        value={value}
        options={text}
        onChange={onChange}
        alert={<TextCaption status="success" />}
      />
      <SelectBox
        className="mt-2"
        border="border-orange-03"
        colorText="text-orange-03"
        type="text"
        value={value}
        options={text}
        onChange={onChange}
        alert={<TextCaption status="warning" />}
      />

      <SelectBox
        className="mt-2"
        type="text"
        value={value}
        options={text}
        onChange={onChange}
        btnAction={
          <Button variant="primary" color="green" className="w-full">
            Action
          </Button>
        }
      />
      {/* checkbox */}
      <SelectBox
        type="checkbox"
        value={valueCheckbox}
        options={checkbox}
        onChange={onChangeCheckBox}
        className="mt-2"
        btnAction={
          <Button variant="primary" color="green" className="w-full">
            Action
          </Button>
        }
      />
      <SelectBox
        type="text additional"
        value={value}
        options={textadditional}
        onChange={onChange}
        className="mt-2"
        iconListItem={<Checkbox />}
      />
    </div>
  );
}