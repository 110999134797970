import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import IconPlus from "components/atoms/Icons/Custom/Plus";
import IconMinus from "components/atoms/Icons/Custom/Minus";
import { formatRupiah } from "utils/formatRupiah";
import { getData, putData } from "utils/fetchData";
import { useDispatch } from "react-redux";
import { fetchTransaction } from "features/Transaction/actions";

export default function EditPelanggan({ show, close, transaction }) {
  const dispatch = useDispatch();
  const [product, setProduct] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const onChange = (status, index) => {
    let _temp = [...product];
    if (status === "minus") {
      if (_temp[index].qty !== 0) {
        let qty = _temp[index].qty - 1;
        _temp[index].qty -= 1;
        _temp[index].total = _temp[index].price * qty;
        setProduct(_temp);
      }
    } else {
      let qty = _temp[index].qty + 1;
      _temp[index].qty += 1;
      _temp[index].total = _temp[index].price * qty;
      setProduct(_temp);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        items: product,
      };
      const res = await putData(
        `v2/sales/product/${transaction}`,
        payload
      );
      if (res.data.code === 200) {
        setLoading(false);
        dispatch(fetchTransaction());
        close();
      }
    } catch (err) {
      console.log("err.response");
      console.log(err.response);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const getOneProductInTransaction = async () => {
      const res = await getData(`v2/sales/product/${transaction}`);

      setProduct(res.data.items);
    };

    getOneProductInTransaction();
  }, [transaction]);

  return (
    <>
      <ModalDialog
        size={"alert"}
        show={show}
        onClose={close}
        title={<h1 className="text-gray-02 heading-3">Edit transaksi</h1>}
      >
        <div className={`bg-white border-t h-full overflow-scroll`}>
          <ul
            className="scroll-hidden overflow-y-scroll"
            style={{ height: "45vh" }}
          >
            {product.map((data, i) => (
              <li className="flex items-center justify-between px-4 border p-4">
                <div className="flex items-center">
                  <p
                    className="text-blue-03 w-48"
                    style={{ maxWidth: "12rem" }}
                  >
                    {data.name}
                  </p>
                  <div className="relative h-7 w-28 flex items-center justify-between border-2 border-blue-03 rounded-lg overflow-hidden">
                    <button
                      className="w-7 h-8 bg-blue-03 hover:bg-blue-02 hov flex items-center justify-center outline-none focus:outline-none"
                      onClick={() => onChange("minus", i)}
                    >
                      <IconMinus />
                    </button>
                    {data.qty}
                    <button
                      className="w-7 h-8 bg-blue-03 hover:bg-blue-02 flex items-center justify-center outline-none focus:outline-none"
                      onClick={() => onChange("plus", i)}
                    >
                      <IconPlus />
                    </button>
                  </div>
                </div>
                <p className="font-semibold">
                  {formatRupiah(data.price * data.qty)}
                </p>
              </li>
            ))}
          </ul>

          <div className="px-5 flex justify-end items-center bg-white border-t">
            <Button
              variant="secondary"
              color="red"
              className="mr-4"
              type="button"
              onClick={close}
            >
              Batal
            </Button>
            <Button
              loading={loading}
              onClick={() => (!loading ? onSubmit() : null)}
              variant="primary"
              color="blue"
              className="my-4"
              type="button"
            >
              Edit transaksi
            </Button>
          </div>
        </div>
      </ModalDialog>
    </>
  );
}
