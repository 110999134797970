import {
  SUCCESS_FETCING_LOCATION,
  SUCCESS_FETCING_TRANSFERSTOCK,
  SET_TRANSFERSTOCK
} from "./constants";
import { userLogout } from 'features/Auth/actions'

import { getData } from 'utils/fetchData'
import debounce from "debounce-promise";

let debouncedFetchLocation = debounce(getData, 1000);

export const fetchLocation = () => {
  return async (dispatch) => {

    let res = await debouncedFetchLocation("outlets");

    if (res.data?.code === 401) {
      dispatch(userLogout())
    }

    let select_box = []
    res.data.outlets.forEach((item) => {
      select_box.push({
        label: item.name,
        value: item.id,
      });
    });

    dispatch(successFetchingCheckboxLocations({ outlets: select_box }));
  };
};

export const successFetchingCheckboxLocations = ({ outlets }) => {
  return {
    type: SUCCESS_FETCING_LOCATION,
    outlets
  };
};

export const fetchProductInTransferStock = (from, to) => {
  return async (dispatch) => {

    const params = {
      from, to
    }
    if (from !== "" && to !== "") {
      let res = await getData("v2/inventory/transfer", params);

      if (res.data?.code === 401) {
        return dispatch(userLogout())
      }
      const category = [], list = []

      res.data.inventories.forEach((data) => {
        category.push({
          categoryId: data.categoryId,
          categoryName: data.categoryName,
          isCheck: false
        })
        data.stock = ''
        data.afterStock = ''
        data.isCheck = false
        list.push(data)
      })

      var dataArr = category.map(item => {
        return [item.categoryName, item]
      }); // creates array of array
      var maparr = new Map(dataArr); // create key value pair from array of array

      var result = [...maparr.values()];//converting back to array from mapobject
      dispatch(successFetchingTransferStock({ category: result, list }));

    }
  };
};

export const successFetchingTransferStock = ({ category, list }) => {
  return {
    type: SUCCESS_FETCING_TRANSFERSTOCK,
    category,
    list
  };
};


export const setCategoryInTransferStock = (category) => {
  return {
    type: SET_TRANSFERSTOCK,
    category,
  };
};
