import * as React from 'react'
import InputTextLargeGroupWithLabel from 'components/atoms/Form/InputTextLargeGroupWithLabel'
import TextCaption from 'components/atoms/Text/TextCaption'
import Switch from 'components/atoms/Switch'

export default function InputGroupLargeContentSwitch() {
  const [label, setLabel] = React.useState([
    {
      name: 'switch1',
      status: false,
    },
    {
      name: 'switch2',
      status: true,
    },
  ])

  const onClick = (data) => {
    const labelOld = [...label]
    labelOld.forEach((label) => {
      if (label.name === data.name) {
        label.status = true
      } else {
        label.status = false
      }
    })
    setLabel(labelOld)
  }

  return (
    <>
      <div className="flex w-full items-center">
        <div className="flex flex-col">
          <div className="my-2">
            <InputTextLargeGroupWithLabel
              content="switch"
              label="Text field label" status="default"
              handleSwitch={<Switch size="sm" type="primary" buttons={label} content="label" onClick={onClick} />} />
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="switch" label="Text field label" status="disabled" disabled
              handleSwitch={<Switch size="sm" type="primary" buttons={label} content="label" onClick={onClick} />} />
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="switch" label="Text field label" status="error"
              handleSwitch={<Switch size="sm" type="primary" buttons={label} content="label" onClick={onClick} />}>
              <div className="mt-1">
                <TextCaption status="error" message="This field is required" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="switch" label="Text field label" status="success"
              handleSwitch={<Switch size="sm" type="primary" buttons={label} content="label" onClick={onClick} />} >
              <div className="mt-1">
                <TextCaption status="success" message="Field validation is successful" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="switch" label="Text field label" status="warning"
              handleSwitch={<Switch size="sm" type="primary" buttons={label} content="label" onClick={onClick} />} >
              <div className="mt-1">
                <TextCaption status="warning" message="This field is required" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center">
        <div className="flex flex-col ">
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="switch" label="Text field label" info status="default"
              handleSwitch={<Switch size="sm" type="primary" buttons={label} content="label" onClick={onClick} />} />
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="switch" label="Text field label" info status="disabled" disabled
              handleSwitch={<Switch size="sm" type="primary" buttons={label} content="label" onClick={onClick} />} />
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="switch" label="Text field label" info status="error"
              handleSwitch={<Switch size="sm" type="primary" buttons={label} content="label" onClick={onClick} />}>
              <div className="mt-1 ml-5">
                <TextCaption status="error" message="This field is required" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="switch" label="Text field label" info status="success"
              handleSwitch={<Switch size="sm" type="primary" buttons={label} content="label" onClick={onClick} />}>
              <div className="mt-1 ml-5">
                <TextCaption status="success" message="Field validation is successful" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="switch" label="Text field label" info status="warning"
              handleSwitch={<Switch size="sm" type="primary" buttons={label} content="label" onClick={onClick} />}>
              <div className="mt-1 ml-5">
                <TextCaption status="warning" message="This field is required" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
        </div>
      </div>
    </>
  )
}

