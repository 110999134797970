import React from "react";
import Button from "components/atoms/Button";
import SearchBar from "components/atoms/SearchBar";
import SelectBox from "components/molecules/SelectBox";
import Pagination from "components/atoms/Pagination";
import Banner from "components/atoms/Banner";
import Table from "./tableMaterial";
import MaterialCreate from "./create";
import MaterialUpdate from "./update";
import Materialdelete from "./delete";
import { CheckboxSecondary } from "pages/Component/ComponentInput/Checkbox";

import {
  fetchMaterial,
  setPage,
  setLimit,
  setKeyword,
  setLocation,
  fetchLocation,
  setOutlet as setOutlets,
  setOutletSelected,
  setAllSelected,
  setStocks as setStock,
  fetchReason,
  setModal,
  setStocks,
} from "features/Material/action";
import { fetchHistory, fetchHistoryToUpdate } from "features/MaterialHistory/actions";
import { fetchMeasure } from "features/Measure/action";
import { useDispatch, useSelector } from "react-redux";

function Material() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.materials);
  const history = useSelector((state) => state.material_history);

  const [isShowSearch, setIsShowSearch] = React.useState(false);
  const [isShowDelete, setIsShowDelete] = React.useState(false);
  const [operator, setOperator] = React.useState("PLUS");

  const filterLocation = (e) => {
    let checked = e.target.checked;
    let id = e.target.value;
    if (e.target.name === "checkAll") {
      dispatch(
        setOutlets(
          data.outlet.map((prev) => {
            prev.checked = checked;
            return prev;
          })
        )
      );
      dispatch(setAllSelected(checked));
    } else {
      dispatch(
        setOutlets(
          data.outlet.map((prev) => {
            if (prev.id === id) {
              prev.checked = checked;
            }
            return prev;
          })
        )
      );
    }
    let patch_selected = [];
    data.outlet.map((data) => {
      if (data.id && data.checked) {
        patch_selected.push(data.id);
      }
    });
    dispatch(setOutletSelected(patch_selected));
    dispatch(setLocation(patch_selected.join(",")));
    if (data.outlet.length === patch_selected.length) {
      dispatch(setAllSelected(true));
    } else {
      dispatch(setAllSelected(false));
    }
  };

  const generateStock = () => {
    let arr = [];
    data.outlet.forEach((item) => {
      arr.push({
        outletId: item.id,
        outletName: item.name,
        trackStock: false,
        trackingStatus: false,
        limitStock: 0,
        beforeStock: 0,
        afterStock: 0,
        changeStock: 0,
        reasonId: 1,
      });
    });
    dispatch(setStock(arr));
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let checked = e.target.checked;
    let name = e.target.name;
    Math.abs(value);
    let patch = [...data.stocks];

    if (name === "track-stock") {
      patch.forEach((prev) => {
        if (prev.outletId === value) prev.trackStock = checked;
      });
      dispatch(setStocks(patch));
    } else if (name === "track-limit") {
      patch.forEach((prev) => {
        if (prev.outletId === value) {
          prev.trackingStatus = checked;
          prev.trackStock = true;
        }
      });
      dispatch(setStocks(patch));
    } else if (name === "reasonId") {
      let reason = [...data.reason];
      reason.forEach((prev) => {
        if (parseInt(value) === prev.id) setOperator(prev.operator);
      });
      patch.forEach((prev) => {
        if (prev.outletId === e.target.dataset.value) {
          prev.reasonId = parseInt(value);
          prev.afterStock = 0;
          prev.changeStock = 0;
          prev.trackStock = true;
        }
      });
      dispatch(setStocks(patch));
    } else if (name === "changeStock") {
      if (operator === "PLUS") {
        patch.forEach((prev) => {
          if (prev.outletId === e.target.dataset.value) {
            prev.trackStock = true;
            prev.changeStock = parseInt(value);
            if (prev.changeStock > 0) {
              prev.afterStock = prev.beforeStock + parseInt(value);
            } else {
              prev.afterStock = 0;
            }
          }
        });
        dispatch(setStocks(patch));
      } else if (operator === "MINUS") {
        patch.forEach((prev) => {
          if (prev.outletId === e.target.dataset.value) {
            prev.trackStock = true;
            if (value <= prev.beforeStock) {
              prev.changeStock = value;
              prev.afterStock = prev.beforeStock - prev.changeStock;
            } else {
              prev.changeStock = 0;
              prev.afterStock = 0;
            }
          }
        });
        dispatch(setStocks(patch));
      } else if (operator === "RESET") {
        patch.forEach((prev) => {
          if (prev.outletId === e.target.dataset.value) {
            prev.trackStock = true;
            prev.changeStock = value;
            prev.beforeStock = 0;
            if (prev.changeStock > 0) {
              prev.afterStock = prev.beforeStock + prev.changeStock;
            } else {
              prev.afterStock = 0;
            }
          }
        });
        dispatch(setStocks(patch));
      }
    } else if (name === "limitStock") {
      dispatch(
        setStock(
          data.stocks.map((item) => {
            if (item.outletId === e.target.dataset.value) {
              item.limitStock = value;
              item.trackStock = true;
              item.trackingStatus = true;
            }
            return item;
          })
        )
      );
    } else if (name === "beforeStock") {
      dispatch(
        setStock(
          data.stocks.map((item) => {
            if (item.outletId === e.target.dataset.value) {
              item.afterStock = value;
              item.beforeStock = value;
              item.changeStock = value;
              item.trackStock = true;
            }
            return item;
          })
        )
      );
    } else if (name === "afterStock") {
      if (operator === "MINUS") {
        dispatch(
          setStock(
            data.stocks.map((item) => {
              if (item.outletId === e.target.dataset.value) {
                item.trackStock = true;
                if (value <= item.beforeStock) {
                  item.afterStock = parseInt(value);
                  item.changeStock = parseInt(value) - item.beforeStock;
                } else {
                  item.changeStock = 0;
                  item.afterStock = 0;
                }
              }
              return item;
            })
          )
        );
      } else if (operator === "PLUS") {
        dispatch(
          setStock(
            data.stocks.map((item) => {
              if (item.outletId === e.target.dataset.value) {
                item.trackStock = true;
                item.afterStock = parseInt(value);
                item.changeStock = item.beforeStock + parseInt(value);
              }
              return item;
            })
          )
        );
      } else if (operator === "RESET") {
        dispatch(
          setStock(
            data.stocks.map((item) => {
              if (item.outletId === e.target.dataset.value) {
                item.trackStock = true;
                item.afterStock = value;
                item.changeStock = value - item.beforeStock;
              }
              return item;
            })
          )
        );
      }
    }
  };

  React.useEffect(() => {
    dispatch(fetchLocation());
  }, []);
  React.useEffect(() => {
    dispatch(fetchHistory(data.id));
    // dispatch(fetchHistoryToUpdate(data.id));
  }, [history.location, history.page, history.limit, history.date, history.reason_stock]);
  React.useEffect(() => {
    generateStock();
    dispatch(fetchMaterial());
    dispatch(fetchMeasure());
    dispatch(fetchReason());
  }, [dispatch, data.page, data.limit, data.keyword, data.location, data.selectedOutlet]);
  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {data.banner === "danger" ? (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          status="warning"
          type="primary"
          message="Data berhasil dihapus"
        />
      ) : (
        ""
      )}
      <div className="flex items-center justify-between my-5">
        {isShowSearch ? (
          <SearchBar
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            onClick={() => setIsShowSearch(!isShowSearch)}
          />
        ) : (
          <div className="flex items-center w-1/2">
            <Button
              className="whitespace-nowrap mr-4"
              variant="outlined"
              color="gray"
              content="icon infront"
              iconName="UilSearch"
              onClick={() => setIsShowSearch(!isShowSearch)}
            >
              Cari bahan baku
            </Button>
            <SelectBox
              className="w-4/12 bold-text-2 border-gray-03 text-gray-03"
              defaultValue={
                data.selectedOutlet || data.selectedOutlet.length === 0
                  ? "Semua lokasi"
                  : `${data.selectedOutlet.length} lokasi`
              }
              spacingY="py-2"
            >
              <li className="cursor-pointer hover:bg-blue-05 duration-500 p-3  text-sm">
                <CheckboxSecondary
                  onChange={filterLocation}
                  checked={data.allSelected}
                  name="checkAll"
                  label={
                    <span className="font-normal text-sm relative ml-6 text-blue-02">
                      Semua lokasi
                    </span>
                  }
                />
              </li>
              {data.outlet.map((location) => {
                return (
                  <li
                    key={location.id}
                    className="cursor-pointer hover:bg-blue-05 duration-500 p-3  text-sm"
                  >
                    <CheckboxSecondary
                      checked={location.checked}
                      onChange={filterLocation}
                      value={location.id}
                      label={
                        <span className="font-normal text-sm relative ml-6 text-blue-02">
                          {location.name}
                        </span>
                      }
                    />
                  </li>
                );
              })}
            </SelectBox>
          </div>
        )}
        <Button
          variant="primary"
          color="blue"
          className="ml-2 whitespace-nowrap"
          onClick={() => dispatch(setModal("show-create"))}
        >
          Tambah bahan baku baru
        </Button>
      </div>
      <Table
        generateStock={generateStock}
        data={data}
        handleChange={handleChange}
        operator={operator}
        history={history}
      />
      {data.materials.length > 0 ? (
        <div className="mt-5">
          <Pagination
            items={data.totalPage}
            currentPage={data.page}
            limit={data.limit}
            onPageChange={(page) => dispatch(setPage(page.selected + 1))}
            onChangePerPage={(value) => dispatch(setLimit(value))}
            label="material per halaman"
          />
        </div>
      ) : (
        ""
      )}
      <MaterialCreate handleChange={handleChange} data={data} operator={operator} />
      <MaterialUpdate
        generateStock={generateStock}
        handleChange={handleChange}
        data={data}
        history={history}
        operator={operator}
      />
      <Materialdelete show={isShowDelete} close={() => setIsShowDelete(false)} />
    </div>
  );
}

export default Material;