import * as React from "react";
import propTypes from "prop-types";
import "assets/stylesheets/input.css";
import CurrencyInput from "react-currency-input-field";

/**
 * status : default, error, disable, success
 */
function InputTextSmall(props) {
  const {
    register,
    disabled,
    value,
    type,
    placeholder,
    name,
    onChange,
    size,
    status,
    currency,
    onBlur,
  } = props;

  const className = [props.className];
  if (status === "default" && size === "sm")
    className.push("focus:border-blue-03 text-2 hover:border-blue-03");
  if (status === "error" && size === "sm") className.push("border-red-02 text-2");
  if (status === "success" && size === "sm") className.push("border-green-02 text-2");
  if (status === "warning" && size === "sm") className.push("border-orange-02 text-2");

  if (currency === "%") {
    return (
      <CurrencyInput
        className={`text-field-small text-2 ${className.join(" ")} `}
        name={name}
        value={value}
        onMouseDown={props.onMouseDown}
        allowDecimals={false}
        allowNegativeValue={false}
        suffix=" %"
        placeholder="Cth: 10 %"
        maxLength={2}
        onValueChange={(value, name) => onChange(value, name)}
        disabled={disabled}
      />
    );
  }

  if (currency === "rp") {
    return (
      <CurrencyInput
        className={`text-field-small text-2 ${className.join(" ")} `}
        name={name}
        value={value}
        onMouseDown={props.onMouseDown}
        allowDecimals={false}
        allowNegativeValue={false}
        prefix="Rp. "
        placeholder="Cth: Rp. 10,000"
        groupSeparator=","
        decimalSeparator=";"
        onValueChange={(value, name) => onChange(value, name)}
        disabled={disabled}
      />
    );
  }

  return (
    <input
      className={`text-field-small text-2 ${className.join(" ")} `}
      ref={register}
      type={type}
      onMouseDown={props.onMouseDown}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
}

InputTextSmall.defaultProps = {
  type: "text",
  placeholder: "Please type here...",
  size: "sm",
  status: "default",
};

InputTextSmall.propTypes = {
  type: propTypes.string,
  name: propTypes.string,
  value: propTypes.string,
  placeholder: propTypes.string,
  className: propTypes.string,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  status: propTypes.string,
  size: propTypes.oneOf(["sm", "lg"]),
  disabled: propTypes.bool,
  currency: propTypes.string,
};

export default InputTextSmall;
