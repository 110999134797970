import React, { useEffect, useState } from "react";
import IconCustomerHappy from "components/atoms/Icons/Custom/icon new/CustomerHappy";
import IconSadFace from "components/atoms/Icons/Custom/icon new/SadFace";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import { InputDateRange } from "components/atoms/Form/InputDate/index";
import Table from "./tableCustomerReview";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchReview,
  setDate,
  setType,
  setAspect as setAspectReview,
  setLimit,
  setPage,
} from "features/CustomerReview/actions";
import { getData } from "utils/fetchData";
import Pagination from "components/atoms/Pagination";

export default function PageCustomerReview() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reviews);
  const [typeLabel, setTypeLabel] = useState("Semua tipe");
  const [typeValue, setTypeValue] = useState("1");

  console.log(data);

  const [aspect, setAspect] = useState([]);
  const [checkAllAspect, setCheckAllAspect] = useState({
    id: "all",
    name: "Semua aspek",
    isChecked: true,
  });
  const [aspectSelected, setAspectSelected] = useState([]);

  const filterAspect = (data) => {
    setAspect(
      aspect.map((item) => {
        if (item.id === data.id) {
          item.isChecked = !item.isChecked;
        }
        return item;
      })
    );
    let patch_selected = [];
    aspect.map((prev) => {
      if (prev.id && prev.isChecked) {
        patch_selected.push(prev.id);
      }
    });
    setAspectSelected(patch_selected);
    dispatch(setAspectReview(patch_selected.join(",")));
    if (aspect.length === patch_selected.length) {
      setCheckAllAspect({
        id: "all",
        name: "Semua aspek",
        isChecked: true,
      });
    } else {
      setCheckAllAspect({
        id: "all",
        name: "Semua aspek",
        isChecked: false,
      });
    }
  };
  const filterAllAspect = () => {
    setAspect(
      aspect.map((item) => {
        if (checkAllAspect.isChecked) {
          item.isChecked = false;
        } else {
          item.isChecked = true;
        }
        return item;
      })
    );
    setCheckAllAspect({
      id: "all",
      name: "Semua perubahan",
      isChecked: !checkAllAspect.isChecked,
    });
    let patch_selected = [];
    aspect.map((prev) => {
      if (prev.id && prev.isChecked) {
        patch_selected.push(prev.id);
      }
    });
    setAspectSelected(patch_selected);
    dispatch(setAspectReview(patch_selected.join(",")));
  };

  const onChangeDateCustom = (start, end) => {
    const ranges = {
      startDate: start,
      endDate: end,
      key: "selection",
    };
    dispatch(setDate(ranges));
  };

  const getAspect = async () => {
    await getData(`review/aspect?type=${typeValue}`).then((res) => {
      let _temp = [];
      console.log(res);
      res.data.aspect.forEach((current) => {
        _temp.push({
          id: current.id,
          name: current.name,
          isChecked: true,
        });
      });
      setAspect(_temp);
    });
  };

  useEffect(() => {
    getAspect();
  }, [typeValue]);

  useEffect(() => {
    dispatch(fetchReview());
  }, [dispatch, data.date, data.type, data.aspect, data.limit, data.page]);
  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      <div className="grid grid-cols-2 mt-4">
        <div className="col-span-1 flex items-center justify-between bg-green-02 text-white px-4 py-2">
          <div className="flex items-center">
            <IconCustomerHappy fill="#ffffff" className="mr-4" />
            <p>
              {" "}
              {data.positif} ulasan positif / {data.data.length} ulasan
            </p>
          </div>
          <p className="font-semibold">
            {data.positif > 0 && data.data.length > 0
              ? Math.floor((data.positif / data.data.length) * 100)
              : "0"}{" "}
            %
          </p>
        </div>
        <div className="col-span-1 flex items-center justify-between bg-red-02 text-white px-4 py-4">
          <p className="font-semibold">
            {data.negatif > 0 && data.data.length > 0
              ? Math.floor((data.negatif / data.data.length) * 100)
              : "0"}{" "}
            %
          </p>
          <div className="flex items-center">
            <p>
              {" "}
              {data.negatif} ulasan negatif / {data.data.length} ulasan
            </p>
            <IconSadFace fill="#ffffff" className="ml-4" />
          </div>
        </div>
      </div>
      <p className="text-center text-gray-02 my-4">Jumlah ulasan : {data.data.length} ulasan</p>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <InputDateRange
            onChangeDateCostum={(start, end) => onChangeDateCustom(start, end)}
            variant="range"
            type="custom right"
            onChange={(ranges) => dispatch(setDate(ranges))}
            name="date"
            value={data.date}
          />
          <Dropdown
            variant="primary"
            className="ml-2"
            color="blue"
            content="label only"
            label={typeLabel}
          >
            <DropdownList
              onClick={() => {
                setTypeLabel("Semua ulasan");
                setTypeValue("");
                dispatch(setType(""));
              }}
              color="blue"
            >
              Semua ulasan
            </DropdownList>
            <DropdownList
              onClick={() => {
                setTypeLabel("Ulasan positif");
                setTypeValue("1");
                dispatch(setType("1"));
              }}
              color="green"
            >
              Ulasan positif
            </DropdownList>
            <DropdownList
              onClick={() => {
                setTypeLabel("Ulasan negatif");
                setTypeValue("0");
                dispatch(setType("0"));
              }}
              color="red"
            >
              Ulasan negatif
            </DropdownList>
          </Dropdown>

          <Dropdown
            label={
              checkAllAspect.isChecked || aspectSelected.length === 0
                ? "Semua aspek"
                : `${aspectSelected.length} aspek`
            }
            variant="primary"
            className="ml-2"
            color="blue"
            content="label only"
          >
            <DropdownList
              onClick={filterAllAspect}
              checked={checkAllAspect.isChecked}
              list="checkbox"
            >
              Semua aspek
            </DropdownList>
            {aspect.map((data, i) => (
              <DropdownList
                key={i}
                onClick={() => filterAspect(data)}
                checked={data.isChecked}
                list="checkbox"
              >
                {data.name}
              </DropdownList>
            ))}
          </Dropdown>
        </div>
      </div>
      <Table data={data} />
      {data.data.length > 0 ? (
        <div className="mt-5">
          <Pagination
            items={data.totalPage}
            currentPage={data.page}
            limit={data.limit}
            onPageChange={(page) => dispatch(setPage(page.selected + 1))}
            onChangePerPage={(value) => {
              dispatch(setLimit(value));
              dispatch(setPage(1));
            }}
            label="review per halaman"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
