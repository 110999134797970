import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import IconHero from "assets/icon/undraw_selection_92i4 1.svg";
import Button from "components/atoms/Button";
import IconAdd from "components/atoms/Icons/Custom/icon new/Add";
import Checkbox from "components/atoms/Checkbox";
import SearcBar from "components/atoms/SearchBar";
import moment from "moment";
import VariantCreate from "pages/Variant/create";
import { fetchVariantInProduct } from "features/Product/actions";
import { useSelector, useDispatch } from "react-redux";
export default function ModalVariant({
  onSelectVariant,
  show,
  close,
  onCheckboxVariant,
  search,
  filterVariant,
}) {
  const dispatch = useDispatch();
  const variant = useSelector((state) => state.product.variant);
  const [isShowCreate, setIsShowCreate] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchVariantInProduct());
  }, [dispatch]);

  return (
    <React.Fragment>
      <ModalDialog
        size={"alert"}
        show={show}
        onClose={close}
        title={<h1 className="text-dark heading-3">Pilih variasi untuk produk</h1>}
      >
        <div className={`bg-gray-05 h-full`}>
          {variant.length === 0 && search === "" ? (
            <div className="px-4 py-18 flex items-center flex flex-col pb-6 pt-12">
              <img src={IconHero} style={{ maxWidth: "35%" }} />
              <p className="font-bold mt-4">Anda belum punya variasi sama sekali</p>
              <Button
                className="flex items-center w-full mt-8 justify-center"
                variant="primary"
                color="green"
                onClick={() => {
                  setIsShowCreate(true);
                }}
              >
                <IconAdd className="mr-2" />
                Tambah variasi baru
              </Button>
            </div>
          ) : (
            <div>
              <div className="flex justify-between items-center px-4 py-4">
                <div className="w-72 mr-4">
                  <SearcBar
                    className="w-full"
                    value={search}
                    name="search"
                    onChange={(e) => filterVariant(e)}
                    placeholder="Cari variasi"
                  />
                </div>

                <Button
                  className="whitespace-nowrap flex items-center"
                  variant="primary"
                  color="green"
                  onClick={() => {
                    setIsShowCreate(true);
                  }}
                >
                  <IconAdd className="mr-2" />
                  Tambah variasi baru
                </Button>
              </div>

              <div
                className="px-4 mx-4 pb-4 bg-white border rounded-lg overflow-y-scroll scroll-hidden"
                style={{ height: "45vh" }}
              >
                <ul>
                  {variant.map((data, i) => {
                    return (
                      <li key={i} className="flex items-center justify-between mt-4">
                        <div className="flex items-center relative">
                          <div className="relative -top-2">
                            <Checkbox
                              type="default"
                              checked={data.isChecked}
                              name="checkbox"
                              value={data.isChecked}
                              onChange={(e) => onCheckboxVariant(e, i)}
                            />
                          </div>
                          <p className="text-2 pl-6">
                            {data.label}
                            <span className="text-red-03">*</span>{" "}
                            <span className="text-gray-02">
                              ({data?.option?.length} opsi variasi)
                            </span>
                          </p>
                        </div>
                        <p className="text-green-02">
                          {moment(data.date).format("DD/MM/YYYY") ===
                          moment(new Date()).format("DD/MM/YYYY")
                            ? "Baru!"
                            : ""}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
          <div className="bg-white p-4 flex items-center w-full justify-end border-t mt-4">
            <Button
              className="flex items-center mt-4"
              variant="primary"
              color="green"
              onClick={() => {
                onSelectVariant();
                close();
              }}
            >
              <IconAdd className="mr-2" />
              Selesai pilih variasi
            </Button>
          </div>
        </div>
      </ModalDialog>
      {/* START: CREATE VARIANT */}
      <VariantCreate
        title="Tambah variant baru"
        show={isShowCreate}
        close={() => setIsShowCreate(false)}
        typeVariant="product"
      />
      {/* END: CREATE VARIANT */}
    </React.Fragment>
  );
}
