import React from "react";

export default function Shadow() {
  const styleTD = {
    padding: "15px",
  };
  return (
    <div className="pt-14 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <table className="w-full mt-10">
        <thead>
          <tr>
            <th>Class</th>
            <th>Properties</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={styleTD}>shadow-HorizontalGrayDivider</td>
            <td style={styleTD}>
              0px -0.5px 0px #C2C2C2, 0px 0.5px 0px #C2C2C2
            </td>
            <td style={styleTD}>
              <div className="shadow-HorizontalGrayDivider p-4">
                <h1 className="heading-3">Horizontal Gray Divider</h1>
                <p className="text-1 text-gray-03 mt-5">
                  A thin grey line on top and bottom of the component to divide
                  & segregate components visually.
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td style={styleTD}>shadow-VerticalGrayDivider</td>
            <td style={styleTD}>
              -0.5px 0px 0px #C2C2C2, 0.5px 0px 0px #C2C2C2
            </td>
            <td style={styleTD}>
              <div className="shadow-VerticalGrayDivider p-4">
                <h1 className="heading-3">Vertical Gray Divider</h1>
                <p className="text-1 text-gray-03 mt-5">
                  A thin grey line on left and right of the component to divide
                  & segregate components visually.
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td style={styleTD}>shadow-HorizontalBlueDivider</td>
            <td style={styleTD}>
              -0.5px 0px 0px #C2C2C2, 0.5px 0px 0px #C2C2C2
            </td>
            <td style={styleTD}>
              <div className="shadow-HorizontalBlueDivider p-4">
                <h1 className="heading-3">Horizontal Blue Divider</h1>
                <p className="text-1 text-gray-03 mt-5">
                  A thin blue line on top and bottom of the component used as a
                  hover state for the dividers.
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td style={styleTD}>shadow-VerticalBlueDivider</td>
            <td style={styleTD}>
              -0.5px 0px 0px #1F4BFF, 0.5px 0px 0px #1F4BFF
            </td>
            <td style={styleTD}>
              <div className="shadow-VerticalBlueDivider p-4">
                <h1 className="heading-3">Vertical Blue Divider</h1>
                <p className="text-1 text-gray-03 mt-5">
                  A thin blue line on left and right of the component used as a
                  hover state for the dividers.
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td style={styleTD}>shadow-HeavyDropShadow</td>
            <td style={styleTD}>0px 16px 48px rgba(194, 194, 194, 0.5)</td>
            <td style={styleTD}>
              <div className="shadow-HeavyDropShadow p-4">
                <h1 className="heading-3">Heavy Drop Shadow</h1>
                <p className="text-1 text-gray-03 mt-5">
                  Drop shadows used on modals and popups to show emphasis on the
                  component.
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td style={styleTD}>shadow-LightDropShadow</td>
            <td style={styleTD}>0px 8px 50px rgba(194, 194, 194, 0.2)</td>
            <td style={styleTD}>
              <div className="shadow-HeavyDropShadow p-4">
                <h1 className="heading-3">Light Drop Shadow</h1>
                <p className="text-1 text-gray-03 mt-5">
                  Drop shadows used on cards and other components for aesthetic
                  purposes.
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
