import React from "react";
import propTypes from "prop-types";

const Thead = (props) => {
  const { text, thStyle } = props;
  return (
    <thead>
      <tr>
        {text.map((text, index) => {
          return (
            <th key={index} className={thStyle} scope="col">
              {text}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

Thead.defaultProps = {
  text: [],
};

Thead.propTypes = {
  text: propTypes.array,
};

export default Thead;
