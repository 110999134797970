import * as React from 'react'
import Radio from 'components/atoms/Radio'

export default function Radios() {
  const [primary, setPrimary] = React.useState("kanan")
  const [secondary, setSecondary] = React.useState("secondary kanan")
  const [secondaryDisabled, setSecondaryDisabled] = React.useState("secondary kanan")
  const [primaryMd, setPrimaryMd] = React.useState("setPrimaryMd kanan")
  const [secondaryMd, setSecondaryMd] = React.useState("kanan")
  const [secondaryMdDisabled, setSecondaryMdDisabled] = React.useState("kanan")
  const [primaryLabelRadio, setPrimaryLabelRadio] = React.useState("kanan")
  const [secondaryLabelRadio, setSecondaryLabelRadio] = React.useState("kanan")
  const [disabledLabelRadio, setDisabledLabelRadio] = React.useState("kanan")
  return (
    <div className="w-10/12 pt-16 mt-5">
      <div className="w-full flex flex-col items-start">
        <div className="flex items-center">
          <Radio type="primary" name="primary" value="kiri" onChange={() => setPrimary('kiri')} checked={primary} />
          <Radio type="primary" name="primary" value="kanan" onChange={() => setPrimary('kanan')} checked={primary} />
        </div>
        <div className="flex items-center">
          <Radio type="secondary" name="secondary" value="secondary kiri" onChange={() => setSecondary('secondary kiri')} checked={secondary} />
          <Radio type="secondary" name="secondary" value="secondary kanan" onChange={() => setSecondary('secondary kanan')} checked={secondary} />
        </div>
        <div className="flex items-center">
          <Radio type="disabled" name="disabled" value="secondary kiri" onChange={() => setSecondaryDisabled('secondary kiri')} checked={secondaryDisabled} disabled />
          <Radio type="disabled" name="disabled" value="secondary kanan" onChange={() => setSecondaryDisabled('secondary kanan')} checked={secondaryDisabled} disabled />
        </div>
        <div className="flex items-center my-2">
          <Radio size="md" type="primary" name="primary md" value="setPrimaryMd kiri" onChange={() => setPrimaryMd('setPrimaryMd kiri')} checked={primaryMd} />
          <Radio size="md" type="primary" name="primary md" value="setPrimaryMd kanan" onChange={() => setPrimaryMd('setPrimaryMd kanan')} checked={primaryMd} />
        </div>
        <div className="flex items-center my-2">
          <Radio size="md" type="secondary" name="secondary md" value="kiri" onChange={() => setSecondaryMd('kiri')} checked={secondaryMd} />
          <Radio size="md" type="secondary" name="secondary md" value="kanan" onChange={() => setSecondaryMd('kanan')} checked={secondaryMd} />
        </div>
        <div className="flex items-center my-2">
          <Radio size="md" type="disabled" name="disabled md" value="kiri" onChange={() => setSecondaryMdDisabled('kiri')} checked={secondaryMdDisabled} disabled />
          <Radio size="md" type="disabled" name="disabled md" value="kanan" onChange={() => setSecondaryMdDisabled('kanan')} checked={secondaryMdDisabled} disabled />
        </div>
        <div className="flex items-center my-2">
          <Radio label="label radio" size="sm" type="primary" name="primary label radio" value="kiri" onChange={() => setPrimaryLabelRadio('kiri')} checked={primaryLabelRadio} />
          <Radio label="label radio" size="sm" type="primary" name="primary label radio" value="kanan" onChange={() => setPrimaryLabelRadio('kanan')} checked={primaryLabelRadio} />
        </div>
        <div className="flex items-center my-2">
          <Radio label="label radio" size="sm" type="secondary" name="secondary label radio" value="kiri" onChange={() => setSecondaryLabelRadio('kiri')} checked={secondaryLabelRadio} />
          <Radio label="label radio" size="sm" type="secondary" name="secondary label radio" value="kanan" onChange={() => setSecondaryLabelRadio('kanan')} checked={secondaryLabelRadio} />
        </div>
        <div className="flex items-center my-2">
          <Radio label="label radio" size="sm" type="disabled" name="disabled label radio" value="kiri" onChange={() => setDisabledLabelRadio('kiri')} checked={disabledLabelRadio} disabled />
          <Radio label="label radio" size="sm" type="disabled" name="disabled label radio" value="kanan" onChange={() => setDisabledLabelRadio('kanan')} checked={disabledLabelRadio} disabled />
        </div>
      </div>
    </div>
  )
}

