import React from "react";

function MoreApp() {
  return (
    <div className="pt-24 pb-10 h-screen w-3/5 m-auto">
      <p className="heading-2 mt-5">
        Lengkapi kebutuhan bisnis Anda dengan <br /> fitur-fitur tambahan Sello!
      </p>

      <div className="grid grid-cols-2 gap-5 mt-20">
        <div className="bg-gray-05 p-5 rounded-lg">
          <div className="flex">
            <svg
              width="60"
              height="60"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.49 7.51996C20.4843 7.49359 20.4843 7.46632 20.49 7.43996C20.4852 7.41688 20.4852 7.39304 20.49 7.36996V7.27996L20.43 7.12996C20.4056 7.08903 20.3753 7.05196 20.34 7.01996L20.25 6.93996H20.2L16.26 4.44996L12.54 2.14996C12.4539 2.08169 12.3555 2.03075 12.25 1.99996H12.17C12.0806 1.98504 11.9894 1.98504 11.9 1.99996H11.8C11.6839 2.02565 11.5725 2.06952 11.47 2.12996L4.00001 6.77996L3.91001 6.84996L3.82001 6.92996L3.72001 6.99996L3.67001 7.05996L3.61001 7.20996V7.29996V7.35996C3.60029 7.42627 3.60029 7.49364 3.61001 7.55996V16.29C3.60967 16.4599 3.65264 16.6271 3.73488 16.7759C3.81711 16.9246 3.93589 17.0499 4.08001 17.14L11.58 21.78L11.73 21.84H11.81C11.9792 21.8936 12.1608 21.8936 12.33 21.84H12.41L12.56 21.78L20 17.21C20.1441 17.1199 20.2629 16.9946 20.3451 16.8459C20.4274 16.6971 20.4703 16.5299 20.47 16.36V7.62996C20.47 7.62996 20.49 7.55996 20.49 7.51996ZM12 4.16996L13.78 5.26996L8.19001 8.72996L6.40001 7.62996L12 4.16996ZM11 19.17L5.50001 15.81V9.41996L11 12.82V19.17ZM12 11.06L10.09 9.90996L15.68 6.43996L17.6 7.62996L12 11.06ZM18.5 15.78L13 19.2V12.82L18.5 9.41996V15.78Z"
                fill="currentColor"
              />
            </svg>
            <div className="ml-4">
              <p className="heading-3">Inventaris</p>
              <p className="font-normal">Hitung stok, resep, dan bahan baku untuk produk.</p>
            </div>
          </div>
        </div>
        <div className="bg-gray-05 p-5 rounded-lg">
          <div className="flex">
            <svg
              width="60"
              height="60"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9912 2.00195C19.8599 2.00188 19.7298 2.02769 19.6084 2.07792C19.4871 2.12815 19.3768 2.20181 19.2839 2.29468C19.1911 2.38755 19.1174 2.49782 19.0672 2.61918C19.017 2.74054 18.9911 2.87061 18.9912 3.00195V3.63867C18.1478 4.68438 17.0819 5.52887 15.871 6.11067C14.66 6.69248 13.3346 6.99696 11.9912 7.00195H5.99121C5.19583 7.00282 4.43327 7.31917 3.87085 7.88159C3.30843 8.44401 2.99208 9.20657 2.99121 10.002V12.002C2.99208 12.7973 3.30843 13.5599 3.87085 14.1223C4.43327 14.6847 5.19583 15.0011 5.99121 15.002H6.475L4.07227 20.6084C4.00698 20.7604 3.98047 20.9263 3.99512 21.0911C4.00978 21.256 4.06514 21.4146 4.15624 21.5528C4.24734 21.6909 4.37133 21.8043 4.51706 21.8827C4.6628 21.9611 4.82572 22.0021 4.99121 22.002H8.99121C9.18696 22.0021 9.37843 21.9446 9.54182 21.8368C9.7052 21.729 9.83329 21.5755 9.91016 21.3955L12.6339 15.04C13.8646 15.1303 15.0636 15.472 16.157 16.0439C17.2505 16.6158 18.215 17.4058 18.9912 18.365V19.002C18.9912 19.2672 19.0966 19.5215 19.2841 19.7091C19.4716 19.8966 19.726 20.002 19.9912 20.002C20.2564 20.002 20.5108 19.8966 20.6983 19.7091C20.8859 19.5215 20.9912 19.2672 20.9912 19.002V3.00195C20.9913 2.87061 20.9655 2.74054 20.9152 2.61918C20.865 2.49782 20.7914 2.38755 20.6985 2.29468C20.6056 2.2018 20.4953 2.12815 20.374 2.07792C20.2526 2.02769 20.1226 2.00188 19.9912 2.00195ZM5.99121 13.002C5.72605 13.0018 5.4718 12.8964 5.2843 12.7089C5.0968 12.5214 4.99139 12.2671 4.99121 12.002V10.002C4.99139 9.73679 5.09681 9.48254 5.2843 9.29505C5.4718 9.10755 5.72605 9.00213 5.99121 9.00195H6.99121V13.002H5.99121ZM8.33203 20.002H6.50781L8.65039 15.002H10.4746L8.33203 20.002ZM18.9912 15.5238C17.0195 13.8994 14.5459 13.0082 11.9912 13.0019H8.99121V9.0019H11.9912C14.5459 8.99537 17.0195 8.10406 18.9912 6.47956V15.5238Z"
                fill="currentColor"
              />
            </svg>
            <div className="ml-4">
              <p className="heading-3">Marketing</p>
              <p className="font-normal">Buat dan kirim kampanye marketing berserta promosi.</p>
            </div>
          </div>
        </div>
        <div className="bg-gray-05 p-5 rounded-lg">
          <div className="flex">
            <svg
              width="60"
              height="60"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 13H14C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14V21C13 21.2652 13.1054 21.5196 13.2929 21.7071C13.4804 21.8946 13.7348 22 14 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V14C22 13.7348 21.8946 13.4804 21.7071 13.2929C21.5196 13.1054 21.2652 13 21 13ZM20 20H15V15H20V20ZM10 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2ZM9 9H4V4H9V9Z"
                fill="currentColor"
              />
              <path
                d="M3.31606 14.3006C2.47326 15.1471 2 16.2937 2 17.4891C2 18.6844 2.47326 19.831 3.31606 20.6776C3.94221 21.3057 4.73964 21.7346 5.60831 21.9105C6.47698 22.0865 7.3782 22.0017 8.1989 21.6667C9.01961 21.3317 9.72325 20.7615 10.2215 20.0276C10.7198 19.2937 10.9906 18.4288 10.9999 17.5414C11.0046 16.9488 10.8906 16.3614 10.6646 15.8137C10.4387 15.2661 10.1054 14.7693 9.68443 14.3529C9.27066 13.929 8.77728 13.5913 8.23267 13.3592C7.68807 13.127 7.10299 13.005 6.5111 13.0002C5.91922 12.9953 5.33222 13.1077 4.78389 13.3309C4.23556 13.5541 3.73671 13.8836 3.31606 14.3006ZM8.21239 19.1931C7.81677 19.5953 7.2939 19.8474 6.73319 19.9063C6.17248 19.9652 5.60874 19.8273 5.13836 19.5161C4.66798 19.2048 4.32017 18.7397 4.1544 18.2001C3.98862 17.6605 4.01517 17.08 4.22951 16.5578C4.44386 16.0356 4.83267 15.6042 5.32949 15.3374C5.82632 15.0705 6.40029 14.9847 6.95327 15.0947C7.50626 15.2047 8.00392 15.5036 8.36117 15.9404C8.71843 16.3771 8.91309 16.9245 8.91187 17.4891C8.89668 18.1344 8.62633 18.7473 8.1602 19.1931H8.21239Z"
                fill="currentColor"
              />
              <path
                d="M14.3161 3.30061C13.4733 4.14715 13 5.29374 13 6.48909C13 7.68445 13.4733 8.83104 14.3161 9.67758C14.9422 10.3057 15.7396 10.7346 16.6083 10.9105C17.477 11.0865 18.3782 11.0017 19.1989 10.6667C20.0196 10.3317 20.7232 9.76151 21.2215 9.0276C21.7198 8.29369 21.9906 7.42878 21.9999 6.54137C22.0046 5.94883 21.8906 5.36137 21.6646 4.81371C21.4387 4.26606 21.1054 3.76932 20.6844 3.35288C20.2707 2.92905 19.7773 2.59134 19.2327 2.35918C18.6881 2.12703 18.103 2.00501 17.5111 2.00015C16.9192 1.99529 16.3322 2.10769 15.7839 2.33088C15.2356 2.55406 14.7367 2.88363 14.3161 3.30061ZM19.2124 8.19311C18.8168 8.59532 18.2939 8.84743 17.7332 8.90634C17.1725 8.96525 16.6087 8.82729 16.1384 8.51606C15.668 8.20483 15.3202 7.73965 15.1544 7.20006C14.9886 6.66046 15.0152 6.07996 15.2295 5.5578C15.4439 5.03564 15.8327 4.60424 16.3295 4.33737C16.8263 4.0705 17.4003 3.98473 17.9533 4.09471C18.5063 4.2047 19.0039 4.50362 19.3612 4.94036C19.7184 5.3771 19.9131 5.92454 19.9119 6.48909C19.8967 7.13439 19.6263 7.74727 19.1602 8.19311H19.2124Z"
                fill="currentColor"
              />
            </svg>
            <div className="ml-4">
              <p className="heading-3">Manajemen meja</p>
              <p className="font-normal">Susun meja restoran untuk memudahkan pemesanan.</p>
            </div>
          </div>
        </div>
        <div className="bg-gray-05 p-5 rounded-lg">
          <div className="flex">
            <svg
              width="60"
              height="60"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 3C3.34315 3 2 4.34315 2 6V20C2 21.6569 3.34315 23 5 23H19C20.6569 23 22 21.6569 22 20V6C22 4.34315 20.6569 3 19 3H5ZM5 5C4.44772 5 4 5.44772 4 6V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V6C20 5.44772 19.5523 5 19 5H5Z"
                fill="currentColor"
              />
              <path
                d="M6 2C6 1.44772 6.44772 1 7 1C7.55228 1 8 1.44772 8 2V6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6V2Z"
                fill="currentColor"
              />
              <path
                d="M16 2C16 1.44772 16.4477 1 17 1C17.5523 1 18 1.44772 18 2V6C18 6.55228 17.5523 7 17 7C16.4477 7 16 6.55228 16 6V2Z"
                fill="currentColor"
              />
              <path
                d="M10.43 15.09L14.72 10.79C14.9074 10.6037 15.1608 10.4992 15.425 10.4992C15.6892 10.4992 15.9426 10.6037 16.13 10.79C16.2237 10.883 16.2981 10.9936 16.3489 11.1154C16.3997 11.2373 16.4258 11.368 16.4258 11.5C16.4258 11.632 16.3997 11.7627 16.3489 11.8846C16.2981 12.0064 16.2237 12.117 16.13 12.21L11.13 17.21C10.9437 17.3947 10.6923 17.4989 10.43 17.5C10.2984 17.5008 10.1679 17.4755 10.0461 17.4258C9.92425 17.376 9.81343 17.3027 9.71999 17.21L7.36999 14.85C7.26531 14.7604 7.18029 14.65 7.12027 14.526C7.06024 14.4019 7.02651 14.2668 7.02119 14.1291C7.01587 13.9913 7.03908 13.854 7.08935 13.7257C7.13963 13.5974 7.21589 13.4808 7.31334 13.3833C7.41079 13.2859 7.52734 13.2096 7.65567 13.1594C7.78399 13.1091 7.92132 13.0859 8.05904 13.0912C8.19676 13.0965 8.3319 13.1302 8.45596 13.1903C8.58003 13.2503 8.69034 13.3353 8.77999 13.44L10.43 15.09Z"
                fill="currentColor"
              />
            </svg>
            <div className="ml-4">
              <p className="heading-3">Reservasi</p>
              <p className="font-normal">Terima reservasi dan pemesanan dengan jadwal</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoreApp;
