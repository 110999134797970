import React from "react";
import ModalDialog from "components/molecules/Modal";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/atoms/Button";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import "./editStockVariant.css";
import { getData } from "utils/fetchData";

import SelectBox from "components/atoms/SelectBox";

import Toggle from "components/atoms/Toggle";

function EditStockVariant({ show, close }) {
  const dispatch = useDispatch();
  const inventory = useSelector((state) => state.inventory);
  const [primary, setPrimary] = React.useState(false);
  const [reason, setReason] = React.useState([]);
  const [reasonSelected, setReasonSelected] = React.useState({
    label: "Pilih alasan",
    value: 3,
    operator: "PLUS",
  });

  const onChange = (value) => {
    // let _temp = [...singleData.outlets];
    // inventory.variantWithlocation.forEach((current) => {
    //   singleData.outlets.forEach((prev) => {
    //     if (current.outletId === prev.outletId) {
    //       _temp.push({
    //         outletId: current.outletId,
    //         variants: inventory.withVariantOnly,
    //       });
    //     }
    //   });
    // });
    // setReasonSelected({
    //   label: value.label,
    //   value: value.value,
    //   operator: value.operator,
    // });
  };

  const getReason = async () => {
    await getData("v2/reason").then((res) => {
      let _temp = [];
      res.data.reasons.forEach((item) => {
        _temp.push({
          label: item.title,
          value: item.id,
          operator: item.operator,
        });
      });
      setReason(_temp);
    });
  };
  React.useEffect(() => {
    getReason();
  }, []);

  return (
    <ModalDialog
      size={"medium"}
      show={show}
      onClose={() => close()}
      title={<h1 className="text-dark heading-3">Edit stok variasi</h1>}
    >
      <div className="bg-gray-05 h-full overflow-scroll relative">
        <div className="px-5 pb-5 pt-2">
          <ul className="mt-2 edit-stock-variant">
            <li className="flex w-full">
              <InputTextLargeWithLabel
                className="w-full"
                label="Nama variasi produk"
                status="default"
                value={`${inventory.productName} - ${inventory.withMaterialOnlySingle.name}`}
                disabled
              />
            </li>
            <li className="flex w-full mt-1">
              <InputTextLargeWithLabel
                className="w-full"
                label="SKU variasi"
                status="default"
                value={inventory.withMaterialOnlySingle.sku}
              />
            </li>
          </ul>{" "}
          <ul className="overflow-y-scroll scroll-hidden" style={{ height: "50vh" }}>
            {Object.keys(inventory.withMaterialOnlySingle).length > 0
              ? inventory.withMaterialOnlySingle.outlets.map((item, index) => {
                  return (
                    <li key={index} className="mt-2">
                      <div className="rounded-lg p-4 bg-white border">
                        <div className="grid grid-cols-4">
                          <div className="col-span-1">
                            <p className="bold-text-1 text-dark relative top-3 ml-4">
                              {item.outletName}
                            </p>
                          </div>
                          <div className="col-span-3">
                            <div className="flex flex-col w-full">
                              <div className="border rounded-lg py-4 px-6 flex justify-between items-center">
                                <p className="bold-text-2 text-gray-02">Lacak & hitung stock</p>
                                <div className="relative -top-3 right-10">
                                  <Toggle
                                    type="primary"
                                    size="lg"
                                    checked={item.trackingStock}
                                    onChange={() => setPrimary(!primary)}
                                  />
                                </div>
                              </div>
                              <div className="mt-1 edit-stock-variant edit-stock-variant-p">
                                <InputTextLargeWithLabel
                                  className="w-full"
                                  label="Stok sekarang"
                                  status="default"
                                  value={item.beforeStock}
                                  disabled
                                />
                              </div>
                              <div className="mt-1 edit-stock-variant">
                                <SelectBox
                                  className="w-full edit-stock-variant-p"
                                  type="text"
                                  label="Alasan perubahan"
                                  value={reasonSelected}
                                  options={reason}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="border rounded-lg py-4 px-6 flex justify-between items-center mt-1">
                                <p className="bold-text-2 text-gray-02">Peringatan stok</p>
                                <div className="relative -top-3 right-10">
                                  <Toggle
                                    type="primary"
                                    size="lg"
                                    checked={item.trackingStatus}
                                    onChange={() => setPrimary(!primary)}
                                  />
                                </div>
                              </div>
                              <div className="mt-1 edit-stock-variant edit-stock-variant-p">
                                <InputTextLargeWithLabel
                                  className="w-full"
                                  label="Peringatan stok"
                                  status="default"
                                  value={item.limitStock}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
        <div className="px-5 flex justify-between items-center bg-white bottom-0 absolute w-full">
          <Button variant="secondary" color="blue" className="my-2" type="button">
            Buang perubahan
          </Button>
          <Button variant="primary" color="blue" className="my-5" type="button">
            Selesai edit stok variasi
          </Button>
        </div>
      </div>
    </ModalDialog>
  );
}

export default EditStockVariant;
