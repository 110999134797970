import React from "react";

export default function Minus({ width, fill, height, className }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8333 0.166672H1.16668C0.945663 0.166672 0.733702 0.254469 0.577421 0.41075C0.421141 0.56703 0.333344 0.778991 0.333344 1.00001C0.333344 1.22102 0.421141 1.43298 0.577421 1.58926C0.733702 1.74554 0.945663 1.83334 1.16668 1.83334H12.8333C13.0544 1.83334 13.2663 1.74554 13.4226 1.58926C13.5789 1.43298 13.6667 1.22102 13.6667 1.00001C13.6667 0.778991 13.5789 0.56703 13.4226 0.41075C13.2663 0.254469 13.0544 0.166672 12.8333 0.166672Z"
        fill={fill}
      />
    </svg>
  );
}

Minus.defaultProps = {
  width: "14px",
  height: "2px",
  fill: "white",
};
