import * as React from "react";
import Switch from "components/atoms/Switch";

export default function Switcht() {
  const [label, setLabel] = React.useState([
    {
      name: "switch1",
      status: false,
    },
    {
      name: "switch2",
      status: true,
    },
  ]);
  const [icon, setIcon] = React.useState([
    {
      name: "icon 1",
      icon: "FaSearch",
      status: false,
    },
    {
      name: "icon 2",
      icon: "FaSearch",
      status: true,
    },
  ]);

  const onClick = (data) => {
    const labelOld = [...label];
    labelOld.forEach((label) => {
      if (label.name === data.name) {
        label.status = true;
      } else {
        label.status = false;
      }
    });
    setLabel(labelOld);
  };

  const onClickIcon = (data) => {
    const iconOld = [...icon];
    iconOld.forEach((icon) => {
      if (icon.name === data.name) {
        icon.status = true;
      } else {
        icon.status = false;
      }
    });
    setIcon(iconOld);
  };

  return (
    <div className="pt-20 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="w-full flex flex-col items-start">
        <div className="flex items-center">
          <div className="flex flex-col max-w-max">
            <div className="my-2">
              <Switch
                type="primary"
                buttons={label}
                content="label"
                onClick={onClick}
              />
            </div>
            <div className="my-2">
              <Switch
                type="secondary"
                buttons={label}
                content="label"
                onClick={onClick}
              />
            </div>
            <div className="my-2">
              <Switch
                type="disabled"
                buttons={label}
                content="label"
                onClick={onClick}
              />
            </div>
          </div>
          <div className="flex flex-col max-w-max ml-2">
            <div className="my-2">
              <Switch
                type="primary"
                buttons={icon}
                content="icon"
                onClick={onClickIcon}
              />
            </div>
            <div className="my-2">
              <Switch
                type="secondary"
                buttons={icon}
                content="icon"
                onClick={onClickIcon}
              />
            </div>
            <div className="my-2">
              <Switch
                type="disabled"
                buttons={icon}
                content="icon"
                onClick={onClickIcon}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex flex-col max-w-max">
            <div className="my-2">
              <Switch
                size="md"
                type="primary"
                buttons={label}
                content="label"
                onClick={onClick}
              />
            </div>
            <div className="my-2">
              <Switch
                size="md"
                type="secondary"
                buttons={label}
                content="label"
                onClick={onClick}
              />
            </div>
            <div className="my-2">
              <Switch
                size="md"
                type="disabled"
                buttons={label}
                content="label"
                onClick={onClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
