import React from "react";
import "assets/stylesheets/table.css";
import Thead from "./thead";
import Tbody from "./tbody";

function TableWithAction(props) {
  const {
    withoutPagination,
    actionDisplay,
    handlePageClick,
    data,
    thead,
    tbody,
    editUrl,
    deleteAction,
    pages,
    customAction,
    loading,
    className,
    thStyle,
    tdStyle,
  } = props;

  return (
    <table className={`w-full ${className}`}>
      <Thead text={thead} thStyle={thStyle} />
      <Tbody
        tdStyle={tdStyle}
        data={data}
        display={tbody}
        loading={loading}
        actionDisplay={actionDisplay}
      />
    </table>
  );
}

export default TableWithAction;
