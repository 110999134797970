import React from "react";

export default function Image({ width, fill, height, className }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 54 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.6667 0.666641H8.33334C6.21161 0.666641 4.17678 1.5095 2.67649 3.00979C1.1762 4.51008 0.333344 6.54491 0.333344 8.66664V35.3333C0.333344 37.455 1.1762 39.4899 2.67649 40.9902C4.17678 42.4905 6.21161 43.3333 8.33334 43.3333H45.6667C47.7884 43.3333 49.8232 42.4905 51.3235 40.9902C52.8238 39.4899 53.6667 37.455 53.6667 35.3333V8.66664C53.6667 6.54491 52.8238 4.51008 51.3235 3.00979C49.8232 1.5095 47.7884 0.666641 45.6667 0.666641ZM8.33334 38C7.6261 38 6.94782 37.719 6.44773 37.2189C5.94763 36.7188 5.66668 36.0406 5.66668 35.3333V28.88L14.4667 20.1066C14.9652 19.618 15.6353 19.3444 16.3333 19.3444C17.0314 19.3444 17.7015 19.618 18.2 20.1066L36.0933 38H8.33334ZM48.3333 35.3333C48.3333 36.0406 48.0524 36.7188 47.5523 37.2189C47.0522 37.719 46.3739 38 45.6667 38H43.6133L33.4533 27.7866L35.8 25.44C36.2985 24.9514 36.9687 24.6777 37.6667 24.6777C38.3647 24.6777 39.0349 24.9514 39.5333 25.44L48.3333 34.2133V35.3333ZM48.3333 26.6933L43.32 21.7066C41.8002 20.2465 39.7743 19.431 37.6667 19.431C35.5591 19.431 33.5332 20.2465 32.0133 21.7066L29.6667 24.0533L21.9867 16.3733C20.4668 14.9131 18.441 14.0976 16.3333 14.0976C14.2257 14.0976 12.1999 14.9131 10.68 16.3733L5.66668 21.36V8.66664C5.66668 7.9594 5.94763 7.28112 6.44773 6.78102C6.94782 6.28093 7.6261 5.99997 8.33334 5.99997H45.6667C46.3739 5.99997 47.0522 6.28093 47.5523 6.78102C48.0524 7.28112 48.3333 7.9594 48.3333 8.66664V26.6933Z"
        fill={fill}
      />
    </svg>
  );
}
Image.defaultProps = {
  width: "53.33px",
  height: "42.67px",
  fill: "#8F8F8F",
};
