import * as React from "react";
import propTypes from "prop-types";
import Icon from "components/atoms/Icons/indexV2";

/**
 * status: success, warning, error
 */

function TextCaption({ status, message }) {
  const className = [];
  if (status === "error") className.push("text-red-02 ml-2 text-2 mt-1");
  if (status === "success") className.push("text-green-02 ml-2 text-2 mt-1");
  if (status === "warning") className.push("text-orange-02 ml-2 text-2 mt-1");
  if (status === "default") className.push("text-gray-02 ml-2 text-2 mt-1");

  return (
    <div className="flex items-center">
      {status === "error" && <Icon iconName="UilMultiply" iconColor="#B80006" />}
      {status === "warning" && (
        <Icon iconName="UilExclamationCircle" iconColor="#B84300" />
      )}
      {status === "success" && <Icon iconName="UilCheck" iconColor="#06884A" />}
      {status === "default" && (
        <Icon iconName="UilInfoCircle" iconColor="#5C5C5C" />
      )}

      <span className={className.join(" ")}>{message}</span>
    </div>
  );
}

TextCaption.defaultProps = {
  status: "default",
  message: "This field is required",
};

TextCaption.propTypes = {
  status: propTypes.string,
  message: propTypes.string,
};

export default TextCaption;
