import {
  START_FETCHING_REVIEW,
  ERROR_FETCHING_REVIEW,
  SUCCESS_FETCHING_REVIEW,
  SET_ASPECT,
  SET_GROUP,
  SET_LOCATION,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_NOTIF,
  SET_DATE,
  SET_NEGATIF,
  SET_POSITIF,
  SET_TYPE,
} from "./constants";
import moment from "moment";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchReview = debounce(getData, 1000);

export const startFetchingReview = () => {
  return {
    type: START_FETCHING_REVIEW,
  };
};

export const successFetchingReview = ({ reviews }) => {
  return {
    type: SUCCESS_FETCHING_REVIEW,
    reviews,
  };
};

export const errorFetchingReview = () => {
  return {
    type: ERROR_FETCHING_REVIEW,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

export const setType = (types) => {
  return {
    type: SET_TYPE,
    types,
  };
};
export const setPositif = (positif) => {
  return {
    type: SET_POSITIF,
    positif,
  };
};
export const setNegatif = (negatif) => {
  return {
    type: SET_NEGATIF,
    negatif,
  };
};
export const setLimit = (limit) => {
  return {
    type: SET_LIMIT,
    limit,
  };
};
export const setPage = (page = 1) => {
  return {
    type: SET_PAGE,
    page,
  };
};
export const setTotalPage = (totalPage) => {
  return {
    type: SET_TOTAL_PAGE,
    totalPage,
  };
};

export const setAspect = (aspect) => {
  return {
    type: SET_ASPECT,
    aspect,
  };
};
export const setGroup = (group) => {
  return {
    type: SET_GROUP,
    group,
  };
};
export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};

export const setDate = (date) => {
  return {
    type: SET_DATE,
    date: date.selection ? date.selection : date,
  };
};

export const fetchReview = () => {
  return async (dispatch, getState) => {
    const notif = {
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      dispatch(setNotif(notif));
    }, 5000);

    dispatch(startFetchingReview());
    let page = getState().reviews.page || "";
    let limit = getState().reviews.limit || "";
    let aspectId = getState().reviews.aspect || "";
    let type = getState().reviews.type || "";
    let startDate = moment(getState().reviews.date.startDate).format("YYYY-MM-DD") || "";
    let endDate = moment(getState().reviews.date.endDate).format("YYYY-MM-DD") || "";
    const params = {
      startDate,
      endDate,
      page,
      limit,
    };
    if (type !== "") params.type = type;
    if (aspectId !== "") params.aspectId = aspectId;
    try {
      let {
        data: { reviews, pages },
      } = await debouncedFetchReview("v2/review", params);
      let totalPage = pages;
      dispatch(setTotalPage(totalPage));
      let positif = [];
      let negatif = [];
      reviews.forEach((item) => {
        if (item.type) {
          positif.push(item);
        } else {
          negatif.push(item);
        }
      });
      dispatch(setPositif(positif.length));
      dispatch(setNegatif(negatif.length));

      // let totalPage = pages;
      dispatch(successFetchingReview({ reviews }));
      // dispatch(setTotalPage(totalPage));
    } catch (error) {
      dispatch(errorFetchingReview());
    }
  };
};
