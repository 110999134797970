import {
  SUCCESS_FETCING_BARCODE,
  SET_BARCODE,
  SET_GENERATE_BARCODE
} from "./constants";

const initialState = {
  category: [],
  list: [],
  data: {
    count: 0,
    generateBarcode: []
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case SUCCESS_FETCING_BARCODE:
      return { ...state, category: action.category, list: action.list };

    case SET_BARCODE:
      return { ...state, category: action.category };

    case SET_GENERATE_BARCODE:
      return { ...state, data: action.payload };

    default:
      return state;
  }
}
