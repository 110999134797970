export const START_FETCHING_MEASURE = "features/Measure/START_FETCHING_MEASURE";
export const ERROR_FETCHING_MEASURE = "features/Measure/ERROR_FETCHING_MEASURE";
export const SUCCESS_FETCHING_MEASURE = "features/Measure/SUCCESS_FETCHING_MEASURE";

export const SET_DATA = "features/Measure/SET_DATA";
export const SET_KEYWORD = "features/Measure/SET_KEYWORD";
export const SET_PAGE = "features/Measure/SET_PAGE";
export const SET_LIMIT = "features/Measure/SET_LIMIT";
export const SET_TOTAL_PAGE = "features/Measure/SET_TOTAL_PAGE";
export const SET_MODAL = "features/Measure/SET_MODAL";
export const SET_BANNER = "features/Measure/SET_BANNER";

export const SET_ID = "features/Measure/SET_ID";
export const SET_UNIT = "features/Measure/SET_UNIT";
export const SET_NAME = "features/Measure/SET_NAME";
export const SET_ERROR = "features/Measure/SET_ERROR";

export const SET_SELECTED = "features/Measure/SET_SELECTED";

// update elfin sanjaya
export const SET_NOTIF = "features/Measure/SET_NOTIF";

