import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setWithMaterialVariant,
  fetchStock,
  setIdStock,
  setToggleMaterial,
  setIdVariant,
  setNotif,
} from "features/Stock/actions";
import { setModalSecondary, setMaterialUsed, setCheckboxData } from "features/Material/action";
import MaterialChoice from "./materialChoice";
import { putData } from "utils/fetchData";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import "./reciptManage.css";
import IconAdd from "components/atoms/Icons/Custom/icon new/Add";
import IconDelete from "components/atoms/Icons/Custom/Delete";
import MaterialCreate from "./materialCreate";

import InputTextGroupWithLabel from "components/atoms/Form/InputTextSmallGroupWithLabel";

function ReciptManage({ show, close }) {
  const dispatch = useDispatch();
  const inventory = useSelector((state) => state.inventory);
  const materials = useSelector((state) => state.materials);

  //new State
  const [tempData, setTempData] = React.useState([]);
  const [showCreate, setShowCreate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const openMaterial = (id, material) => {
    let _used = [];
    material.forEach((item) => {
      _used.push({
        id: item.id,
        date: item.createdAt,
        isChecked: true,
        name: item.name,
        rawMaterialId: item.rawMaterialId,
        unit: item.unit,
        value: item.value,
      });
    });
    dispatch(setMaterialUsed(_used));
    dispatch(setIdVariant(id));
    dispatch(setModalSecondary("show-material-recipt-create"));
  };

  const changeHandler = (e, indexParent, indexChild, abstractId) => {
    let _temp = [...inventory.withMaterialVariant];
    _temp.forEach((parrent) => {
      parrent.rawMaterial.forEach((child) => {
        if (child.abstractId === abstractId) {
          _temp[indexParent]["rawMaterial"][indexChild][e.target.name] = parseInt(e.target.value);
        }
      });
    });
    // setTempData(_temp);
    dispatch(setWithMaterialVariant(_temp));
  };

  const onDelete = (idParrent, idChild) => {
    let _temp = [...inventory.withMaterialVariant];
    _temp.forEach((current) => {
      if (current.variantOptionId === idParrent) {
        current.rawMaterial.splice(
          current.rawMaterial.findIndex((e) => e.rawMaterialId === idChild),
          1
        );
      }
    });
    let _checkbox = [...materials.materials_checkbox];
    _checkbox.forEach((item) => {
      if (item.rawMaterialId === idChild) {
        item.isChecked = false;
        item.value = 0;
      }
    });
    dispatch(setCheckboxData(_checkbox));
    dispatch(setWithMaterialVariant(_temp));
  };

  const submitData = async () => {
    if (inventory.idStock !== 0 && inventory.modal_secondary === "show-reciptManage-update") {
      setIsLoading(true);
      let _tempSubmit = [];
      inventory.withMaterialVariant.forEach((item) => {
        if (item.rawMaterial.length !== 0) {
          _tempSubmit.push(item);
        }
      });
      let payload = {
        withVariant: inventory.withVariant,
        withMaterial: inventory.withMaterial,
        inventory: _tempSubmit,
      };
      await putData(`v2/inventory/${inventory.idStock}`, payload)
        .then((res) => {
          console.log("res putt");
          console.log(res);
          let notif = {
            message: `Berhasil mengubah stok ${res.data.productName} `,
            type: "primary",
            status: "success",
            id: parseInt(res.data.inventoryId),
            toggle: true,
          };
          // console.log(payload);
          dispatch(setNotif(notif));
          dispatch(fetchStock());
          dispatch(setIdStock(0));
          setIsLoading(false);
          setTempData([]);
          dispatch(setWithMaterialVariant([]));
          dispatch(setToggleMaterial(false));
          setIsLoading(false);
          close();
        })
        .catch((err) => {
          console.log(err.response);
          alert("Terjadi error server");
        });
    } else {
      let _tempSubmit = [];
      tempData.forEach((item) => {
        if (item.rawMaterial.length !== 0) {
          _tempSubmit.push(item);
        }
      });
      dispatch(setWithMaterialVariant(_tempSubmit));
      close();
    }
  };

  React.useEffect(() => {
    setTempData(inventory.withMaterialVariant);
  }, [inventory.withMaterialVariant]);

  return (
    <>
      <ModalDialog
        size={"medium"}
        show={show}
        onClose={() => close()}
        title={<p className="text-dark heading-3">Edit resep</p>}
      >
        <div className="bg-gray-05 h-full">
          <div style={{ height: "81vh" }} className="p-8 border">
            {/* <div className="flex items-center recipt-manage ">
              <SelectBox
                type="text"
                label=""
                value={value}
                options={text}
                // onChange={onChange}
              />
              <SelectBox
                className="ml-4"
                type="text"
                label=""
                value={value}
                options={text}
                // onChange={onChange}
              />
            </div>
            */}
            <div className="flex overflow-scroll">
              {inventory.withMaterialVariant.map((item, indexParent) => {
                return (
                  <div
                    key={indexParent}
                    className="recipt-manage-card bg-white rounded-lg border border-gray-04"
                  >
                    {/* head card */}
                    <div className="flex items-center justify-between py-2 px-3 border-b border-gray-04">
                      <p>{item.name}</p>
                      <button
                        onClick={() => openMaterial(item.variantOptionId, item.rawMaterial)}
                        className="bg-green-03 p-2 rounded-lg"
                      >
                        <IconAdd fill="white" />
                      </button>
                    </div>
                    {/* content card */}
                    <ul className="px-3 pb-3 scroll-hidden">
                      {item.rawMaterial.map((prev, indexChild) => {
                        return (
                          <li
                            key={prev.rawMaterialId}
                            className="flex items-center justify-between mt-3"
                          >
                            <p className="text-2">{prev.name}</p>
                            <div className="flex items-center">
                              <InputTextGroupWithLabel
                                name="value"
                                status="default"
                                content="text"
                                placeholder=""
                                category={prev?.unit.unit}
                                type="number"
                                width="w-32"
                                value={prev.value}
                                onChange={(e) =>
                                  changeHandler(e, indexParent, indexChild, prev.abstractId)
                                }
                              />
                              <span
                                onClick={() => onDelete(item.variantOptionId, prev.rawMaterialId)}
                              >
                                <IconDelete className="ml-4" />
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-end px-6 py-4 bg-white mt-6 absolute bottom-0 left-0 w-full">
            {/* <Button onClick={cancelChanging} variant="secondary" color="blue" type="button">
              Buang perubahan
            </Button> */}
            <Button
              loading={isLoading}
              onClick={submitData}
              variant="primary"
              color="blue"
              type="button"
            >
              Selesai edit resep
            </Button>
          </div>
        </div>
      </ModalDialog>
      <MaterialChoice
        create={() => {
          dispatch(setModalSecondary("hide-material-global"));
          setShowCreate(true);
        }}
        show={materials.modal_secondary === "show-material-recipt-create" ? true : false}
        close={() => dispatch(setModalSecondary("hide-material-recipt-create"))}
      />
      <MaterialCreate
        show={showCreate}
        close={() => {
          setShowCreate(false);
          dispatch(setModalSecondary("show-material-recipt-create"));
        }}
      />
    </>
  );
}

export default ReciptManage;
