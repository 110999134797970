import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import moment from "moment";
import "moment/locale/id";
import { useDispatch } from "react-redux";
import { setPageHistory, setLimitHistory } from "features/MaterialHistory/actions";
import Pagination from "components/atoms/Pagination";
import { InputDateRange } from "components/atoms/Form/InputDate";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import { getData } from "utils/fetchData";
import {
  setLocationHistory,
  setReason as setReasonHistory,
  setDate,
} from "features/MaterialHistory/actions";

function MaterialHistory({ show, close, data }) {
  const dispatch = useDispatch();

  console.log(data);

  //location state
  const [locations, setLocations] = React.useState([]);
  const [checkAllLocation, setCheckAllLocation] = React.useState({
    id: "all",
    name: "Semua lokasi",
    isChecked: true,
  });
  const [locationSelected, setLocationSelected] = React.useState([]);

  //reason state
  const [reason, setReason] = React.useState([]);
  const [checkAllReason, setCheckAllReason] = React.useState({
    id: "all",
    name: "Semua perubahan",
    isChecked: true,
  });
  const [reasonSelected, setReasonSelected] = React.useState([]);

  const filterReason = (data) => {
    setReason(
      reason.map((item) => {
        if (item.id === data.id) {
          item.isChecked = !item.isChecked;
        }
        return item;
      })
    );
    let patch_selected = [];
    reason.map((prev) => {
      if (prev.id && prev.isChecked) {
        patch_selected.push(prev.id);
      }
    });
    setReasonSelected(patch_selected);
    dispatch(setReasonHistory(patch_selected.join(",")));
    if (reason.length === patch_selected.length) {
      setCheckAllReason({
        id: "all",
        name: "Semua perubahan",
        isChecked: true,
      });
    } else {
      setCheckAllReason({
        id: "all",
        name: "Semua perubahan",
        isChecked: false,
      });
    }
  };
  const filterAllReason = () => {
    setReason(
      reason.map((item) => {
        if (checkAllReason.isChecked) {
          item.isChecked = false;
        } else {
          item.isChecked = true;
        }
        return item;
      })
    );
    setCheckAllReason({
      id: "all",
      name: "Semua perubahan",
      isChecked: !checkAllReason.isChecked,
    });
    let patch_selected = [];
    reason.map((prev) => {
      if (prev.id && prev.isChecked) {
        patch_selected.push(prev.id);
      }
    });
    setReasonSelected(patch_selected);
    dispatch(setReasonHistory(patch_selected.join(",")));
  };
  const filterLocation = (data) => {
    setLocations(
      locations.map((item) => {
        if (item.id === data.id) {
          item.isChecked = !item.isChecked;
        }
        return item;
      })
    );
    let patch_selected = [];
    locations.map((prev) => {
      if (prev.id && prev.isChecked) {
        patch_selected.push(prev.id);
      }
    });
    setLocationSelected(patch_selected);
    dispatch(setLocationHistory(patch_selected.join(",")));
    if (locations.length === patch_selected.length) {
      setCheckAllLocation({
        id: "all",
        name: "Semua lokasi",
        isChecked: true,
      });
    } else {
      setCheckAllLocation({
        id: "all",
        name: "Semua lokasi",
        isChecked: false,
      });
    }
  };
  const filterAllLocation = () => {
    setLocations(
      locations.map((item) => {
        if (checkAllLocation.isChecked) {
          item.isChecked = false;
        } else {
          item.isChecked = true;
        }
        return item;
      })
    );
    setCheckAllLocation({
      id: "all",
      name: "Semua lokasi",
      isChecked: !checkAllLocation.isChecked,
    });
    let patch_selected = [];
    locations.map((prev) => {
      if (prev.id && prev.isChecked) {
        patch_selected.push(prev.id);
      }
    });
    setLocationSelected(patch_selected);
    dispatch(setLocationHistory(patch_selected.join(",")));
  };

  //fetching location & reason
  const getLocation = async () => {
    let patch_location = [];
    let patch_selection = [];
    let res = await getData("outlets");
    res.data.outlets.forEach((location) => {
      patch_location.push({
        id: location.id,
        name: location.name,
        isChecked: true,
      });
      patch_selection.push(location.id);
    });
    setLocations(patch_location);
    setLocationSelected(patch_selection);
  };
  const getReason = async () => {
    let patch_reason = [];
    let patch_selection = [];
    let res = await getData("v2/reason");
    res.data.reasons.forEach((reason) => {
      patch_reason.push({
        id: reason.id,
        name: reason.title,
        isChecked: true,
      });
      patch_selection.push(reason.id);
    });
    setReason(patch_reason);
    setLocationSelected(patch_selection);
  };

  React.useEffect(() => {
    getLocation();
    getReason();
  }, []);
  return (
    <ModalDialog
      size={"default"}
      show={show}
      onClose={close}
      title="Sejarah bahan baku"
    >
      <div className="p-3 bg-white">
        <div className="flex mb-3">
          <InputDateRange
            onChange={(e) => dispatch(setDate(e.selection))}
            variant="range"
            type="default"
            name="date"
            value={data.date}
          />
          <Dropdown
            label={
              checkAllLocation.isChecked || locationSelected.length === 0
                ? "Semua lokasi"
                : `${locationSelected.length} lokasi`
            }
            variant="primary"
            className="ml-2"
            color="blue"
            content="label only"
          >
            <DropdownList
              onClick={filterAllLocation}
              checked={checkAllLocation.isChecked}
              list="checkbox"
            >
              Semua lokasi
            </DropdownList>
            {locations.map((data, i) => (
              <DropdownList
                key={i}
                onClick={() => filterLocation(data)}
                checked={data.isChecked}
                list="checkbox"
              >
                {data.name}
              </DropdownList>
            ))}
          </Dropdown>
          <Dropdown
            label={
              checkAllReason.isChecked || reasonSelected.length === 0
                ? "Semua perubahan"
                : `${reasonSelected.length} perubahan`
            }
            variant="primary"
            className="ml-2"
            color="blue"
            content="label only"
          >
            <DropdownList
              onClick={filterAllReason}
              checked={checkAllReason.isChecked}
              list="checkbox"
            >
              Semua perubahan
            </DropdownList>
            {reason.map((data, i) => (
              <DropdownList
                key={i}
                onClick={() => filterReason(data)}
                checked={data.isChecked}
                list="checkbox"
              >
                {data.name}
              </DropdownList>
            ))}
          </Dropdown>
        </div>
        <div style={{ minHeight: "14rem", maxHeight: "14rem", overflow: "scroll" }}>
          <table className="w-full">
            <thead>
              <tr>
                <th>Tanggal</th>
                <th>Produk</th>
                <th>Perubahan</th>
                <th>Alasan</th>
                <th>Lokasi</th>
              </tr>
            </thead>
            <tbody>
              {data.status === "process" ? (
                <td rowSpan="6" colSpan="6" className="p-0">
                  <div className="flex justify-center w-full py-20">
                    <span>Loading...</span>
                  </div>
                </td>
              ) : data.history.length > 0 ? (
                data.history.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{moment(item.createdAt).locale("id").format("DD/MM/YYY - LT")}</td>
                      <td>-</td>
                      <td>
                        {item?.rawMaterialReasonStok?.operator === "PLUS"
                          ? item?.afterStock - item?.beforeStock
                          : item?.rawMaterialReasonStok?.operator === "MINUS" ||
                            item?.rawMaterialReasonStok?.operator === "SALES"
                            ? item?.beforeStock - item?.afterStock
                            : item?.afterStock}
                      </td>
                      <td>{item?.rawMaterialReasonStok?.title}</td>
                      <td>
                        {item?.rawMaterialAbstract === null
                          ? "-"
                          : item?.rawMaterialAbstract?.outlet?.outletName}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td colSpan="6" className="p-0">
                  <div className="flex justify-center w-full py-5">
                    <span>Data not found</span>
                  </div>
                </td>
              )}
            </tbody>
          </table>
        </div>
        {data.history.length > 0 ? (
          <div className="mt-5">
            <Pagination
              items={data.totalPage}
              currentPage={data.page}
              limit={data.limit}
              onPageChange={(page) => dispatch(setPageHistory(page.selected + 1))}
              onChangePerPage={(value) => dispatch(setLimitHistory(value))}
              label="history per halaman"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="px-5 flex max-w-max absolute right-0 -top-1 justify-end items-center bg-transparent">
        <Button
          onClick={() => {
            close();
          }}
          variant="primary"
          color="blue"
          className="my-5"
          type="submit"
        >
          Selesai
        </Button>
      </div>
    </ModalDialog>
  );
}

export default MaterialHistory;
