import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import "assets/stylesheets/pinField.css";

export default function ShowPin({ show, close, fieldPin }) {
  const onChange = (value) => {
    console.log("value");
    console.log(value);
  };
  return (
    <ModalDialog
      size={"alert"}
      show={show}
      onClose={close}
      title={
        <h1 className="text-blue-03 heading-3">
          {fieldPin.name}
          <span className="text-gray-03">/ PIN perangkat</span>
        </h1>
      }
    >
      <div className={`bg-white p-5 h-full overflow-scroll scroll-hidden`}>
        <div className="flex justify-center">
          <div className={`default-field-input-large hovered-pin flex items-center`}>
            {fieldPin.pin}
          </div>
        </div>
        <div className="bg-blue-03 p-4 mt-4">
          <p className="text-white">
            Masukkan PIN ke halaman perangkat untuk selesai ditambahkan,
            disinkronisasikan, akses konfigurasi, dan login.
          </p>
        </div>
      </div>
      <div className="px-5 flex justify-end items-center bg-white border-t">
        <Button variant="primary" color="blue" className="my-5" type="button">
          Kembali
        </Button>
      </div>
    </ModalDialog>
  );
}

ShowPin.defaultProps = {
  fieldPin: {
    name: '',
    pin: '000000'
  }
}
