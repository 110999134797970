import React from 'react'

export default function Devices({width,fill,height,className}) {
    return (
            <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 7.62939e-06H3C2.20435 7.62939e-06 1.44129 0.316078 0.87868 0.878687C0.316071 1.4413 0 2.20436 0 3.00001V13C0 13.7957 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H6V18H3C2.73478 18 2.48043 18.1054 2.29289 18.2929C2.10536 18.4804 2 18.7348 2 19C2 19.2652 2.10536 19.5196 2.29289 19.7071C2.48043 19.8947 2.73478 20 3 20H17C17.2652 20 17.5196 19.8947 17.7071 19.7071C17.8946 19.5196 18 19.2652 18 19C18 18.7348 17.8946 18.4804 17.7071 18.2929C17.5196 18.1054 17.2652 18 17 18H14V16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7957 20 13V3.00001C20 2.20436 19.6839 1.4413 19.1213 0.878687C18.5587 0.316078 17.7956 7.62939e-06 17 7.62939e-06ZM12 18H8V16H12V18ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8947 17.2652 14 17 14H3C2.73478 14 2.48043 13.8947 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V12H18V13ZM18 10H2V3.00001C2 2.73479 2.10536 2.48044 2.29289 2.2929C2.48043 2.10536 2.73478 2.00001 3 2.00001H17C17.2652 2.00001 17.5196 2.10536 17.7071 2.2929C17.8946 2.48044 18 2.73479 18 3.00001V10Z" fill={fill}/>
</svg>


    )
}

Devices.defaultProps ={
    width:'20px',
    height:'20px',
    fill:'#8F8F8F'
}
