import * as React from "react";
import Button from "components/atoms/Button";
import IconClose from "components/atoms/Icons/Custom/Closes";
import SearcBar from "components/atoms/SearchBar";
import ModalCategory from "./pilihKategori";
import { InputTextSmall } from "components/atoms/Form";
import IconDelete from "components/atoms/Icons/Custom/icon new/Delete";
import ModalDialog from "components/molecules/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProductInBarcode,
  setCategoryInBarcode,
  setGenerateBarcode,
} from "features/Barcode/actions";
import Checkbox from "components/atoms/Checkbox";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import { useHistory } from "react-router-dom";
import "./style.css";

export default function PageBarcode() {
  const history = useHistory();
  const dispatch = useDispatch();
  const barcode = useSelector((state) => state.barcode);
  const [keyword, setKeyword] = React.useState("");
  const [isShowCategory, setIsShowCategory] = React.useState(false);
  const [modalBatal, setModalBatal] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [field, setField] = React.useState({ barcode: [] });
  const [loading, setLoading] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const [dropdown, setDropdown] = useDetectOutsideClick(dropdownRef, false);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    dispatch(fetchProductInBarcode());
  }, []);

  const onCheckboxCategory = (e, index) => {
    let _temp = [...barcode.category];
    _temp[index]["isCheck"] = e.target.checked;
    dispatch(setCategoryInBarcode(_temp));
  };

  const onSelectCategory = (all = false) => {
    let reset = [];
    list.forEach((data) => {
      data.isCheck = false;
      reset.push(data);
    });
    setList(reset);

    let check = [],
      sum = 0;
    if (!all) {
      barcode.category.forEach((data) => {
        if (data.isCheck === true) {
          sum += 1;
          barcode.list.forEach((list) => {
            if (list.category) {
              if (data.categoryId === list.category.id) {
                check.push(list);
              }
            }
          });
        }
      });
      setIsShowCategory(false);
    } else {
      barcode.list.forEach((list) => {
        check.push(list);
      });
      let _temp = [...barcode.category];
      _temp.forEach((data) => {
        data.isCheck = true;
      });
      dispatch(setCategoryInBarcode(_temp));
    }
    onCountGenerateBarcode(check);
    setField({ ...field, barcode: check });
  };

  const onCountGenerateBarcode = (data) => {
    if(data.length) {
      let countBarcode = data.reduce((a, b) => Number(a) + Number(b.stock), 0);
      setCount(countBarcode);
    }else{
      setCount(0);
    }

  };

  const onDelete = (i) => {
    let barcode = [...field.barcode];
    var removeIndex = barcode
      .map(function (item, index) {
        return index;
      })
      .indexOf(i);
    barcode.splice(removeIndex, 1);

    setField({ ...field, barcode: barcode });
    onCountGenerateBarcode(barcode);
  };

  const onChange = (e, index) => {
    let _temp = [...field.barcode];

    let { value, name } = e.target;

    _temp[index][name] = value;

    setField({ ...field, barcode: _temp });
    onCountGenerateBarcode(_temp);
  };

  const onSubmit = () => {
    setLoading(false)
    const payload = {
      count: count,
      generateBarcode: field.barcode,
    };
    dispatch(setGenerateBarcode(payload));
    history.push("/product/inventory/barcode/success");
  };

  const handleOptionCheckbox = (e, index) => {
    const check = [...list];
    check[index]["isCheck"] = e.target.checked;
    setList(check);
    if (e.target.checked) {
      let product = [...field.barcode];
      let plus = field.barcode.filter((data) => data.id === check[index].id);
      if (plus.length === 0) product.push(check[index]);
      setField({ ...field, barcode: product });
      onCountGenerateBarcode(product);
    } else {
      let minus = field.barcode.filter((data) => data.id !== check[index].id);
      setField({ ...field, barcode: minus });
      onCountGenerateBarcode(minus);
    }
  };

  const filterByProduct = (e) => {
    let check = [...barcode.list];
    const search = e.target.value.toLowerCase();
    check = check.filter(function (product) {
      return product.product
        ? product.product.toLowerCase().indexOf(search) !== -1
        : product.productName.toLowerCase().indexOf(search) !== -1;
    });
    setKeyword(e.target.value);
    setList(check);
  };

  return (
    <React.Fragment>
      {/* head */}
      <div className="flex items-center justify-between py-4 px-6 shadow-HeavyDropShadow border-b">
        <div className="flex items-center">
          <div
            className="btn-icons mr-4 text-gray-03 hover:text-gray-02 active:text-gray-01 bg-gray-05 p-2"
            onClick={() => setModalBatal(true)}
          >
            <IconClose fill={"currentColor"} />
          </div>
          <p className="heading-3">Buat barcode produk</p>
        </div>
        <h1>{count !== 0 && `${count} barcode akan dibuat`}</h1>
        <Button
          loading={loading ? true : false}
          onClick={!loading ? onSubmit : null}
          variant="primary"
          color="blue"
          disabled={field.barcode.length === 0 ? true : false}
        >
          <p className="bold-text-2">Selesai buat barcode</p>
        </Button>
      </div>
      {/* body */}
      <div
        className="flex items-start justify-center bg-white overflow-y-scroll"
        style={{ height: "84vh" }}
      >
        <div className="w-728px max-w-728px">
          <h3 className="heading-3 mb-4 mt-10">
            Pilih produk untuk dibuat barcode
          </h3>
          <div className="flex items-center w-full justify-between">
            <div className="relative w-full">
              <SearcBar
                value={keyword}
                name="keyword"
                onChange={(e) => {
                  filterByProduct(e);
                  setDropdown(true);
                }}
                placeholder="Cari produk"
              />
              {dropdown && (
                <ul
                  ref={dropdownRef}
                  className="w-full absolute top-10 z-50 left-0 item-list shadow-HeavyDropShadow overflow-hidden bg-white rounded-lg mt-3 mb-11"
                >
                  {list.map((list, i) => {
                    return (
                      <li
                        key={i}
                        className={`justify-between flex  cursor-pointer hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm`}
                      >
                        <Checkbox
                          type="default"
                          checked={list.isCheck}
                          name="checkbox"
                          onChange={(e) => handleOptionCheckbox(e, i)}
                          label={list.product ? list.product : list.productName}
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <Button
              variant="secondary"
              color="blue"
              className="ml-2"
              onClick={() => setIsShowCategory(true)}
            >
              <p className="whitespace-nowrap">Pilih berdasarkan kategori</p>
            </Button>
            <Button
              variant="secondary"
              color="blue"
              className="ml-2"
              onClick={() => onSelectCategory(true)}
            >
              <p className="whitespace-nowrap">Semua produk</p>
            </Button>
          </div>
          <div className="bg-gray-05 rounded-lg p-6 mt-6">
            <ul className="bg-white px-6 pb-6 rounded-lg">
              {field.barcode.length > 0 ? (
                field.barcode.map((data, i) => {
                  return (
                    <li className="pt-4" key={i}>
                      <div className="flex items-center justify-between">
                        <p className="text-2">
                          {data.productName ? data.productName : data.product}
                        </p>
                        <div className="flex items-center">
                          <InputTextSmall
                            status="default"
                            className="mr-4 text-center pl-0"
                            placeholder="300"
                            name="stock"
                            value={data.stock}
                            onChange={(e) => onChange(e, i)}
                          />
                          <span onClick={() => onDelete(i)}>
                            <IconDelete className="cursor-pointer" />
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <h1>Mulai cari produk untuk dipilih</h1>
              )}
            </ul>
          </div>
        </div>
        {/* Modal */}
        <ModalCategory
          show={isShowCategory}
          data={barcode.category}
          close={() => setIsShowCategory(false)}
          onCheckboxCategory={(e, i) => onCheckboxCategory(e, i)}
          onSelectCategory={() => onSelectCategory()}
        />
        {/* START: ALERT BATAL */}
        <ModalDialog
          show={modalBatal}
          onClose={() => setModalBatal(false)}
          size={"alert"}
          title={<h1 className="text-dark heading-3">Batal buat barcode?</h1>}
        >
          <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
            <p className="text-2">
              Semua perubahan dan akan hilang saat Anda keluar sebelum
              menyelesaikan pembuatan barcode untuk produk yang sudah dipilih.
            </p>
          </div>
          <div className="px-5 flex justify-between items-center bg-white">
            <Button
              variant="secondary"
              color="blue"
              className="my-5"
              type="button"
              onClick={() => setModalBatal(false)}
            >
              Kembali buat barcode
            </Button>
            <Button
              variant="primary"
              color="red"
              className="my-5"
              type="button"
              onClick={() => {setModalBatal(false); history.push('/product/inventory/default')}
              }
            >
              Batal dan buang perubahan
            </Button>
          </div>
        </ModalDialog>
        {/* END: ALERT BATAL */}
      </div>
    </React.Fragment>
  );
}
