import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDiscount, setKeyword } from "features/Discount/actions";
import Table from "./tableDiscount";
import Button from "components/atoms/Button";
import SearchBar from "components/atoms/SearchBar";
import EmptyPage from "components/templates/Empty";
import Banner from "components/atoms/Banner";
import DiscountCraete from "./create";
import EmptyDiskon from "assets/icon/undraw_discount_d4bd 1.svg";
import "./discount.css";

export default function DiscountPage() {
  const dispatch = useDispatch();
  const discount = useSelector((state) => state.discount);
  const [isShowSearch, setIsShowSearch] = React.useState(false);
  const [isShowCreate, setIsShowCreate] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchDiscount());
  }, [dispatch, discount.keyword]);

  // ambil data error dan convert ke view
  if (discount.status === "error") return <h1>internal server error</h1>;

  if (
    discount.keyword === "" &&
    discount.data.length === 0 &&
    discount.status === "success"
  )
    return (
      <React.Fragment>
        <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-2">
          <EmptyPage
            title="Diskon"
            text="Tambahkan diskon ke produk Anda untuk menarik perhatian pelanggan kamu. Diskon bisa dipasang ke produk berdasarkan kategori, lokasi, ataupun produk tertentu yang kamu pilih."
            image={EmptyDiskon}
            label="Tambah diskon baru"
            onClick={() => setIsShowCreate(true)}
          />
        </div>
        {/* START: CREATE */}
        <DiscountCraete
          title="Tambah diskon baru"
          show={isShowCreate}
          close={() => setIsShowCreate(false)}
        />
        {/* END: CREATE */}
      </React.Fragment>
    );

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {/* START: NOTIF */}
      {discount.notif?.toggle && discount.notif.status === "danger" && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          status={discount.notif.status}
          type={discount.notif.type}
          message={discount.notif.message}
        />
      )}
      {/* END: NOTIF */}

      {/* START:: HEADER */}
      <div className="flex items-center justify-between my-2">
        {/* START: HEADER */}
        {isShowSearch ? (
          <SearchBar
            name={discount.keyword}
            value={discount.keyword}
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            onClick={() => setIsShowSearch(!isShowSearch)}
          />
        ) : (
          <Button
            variant="outlined"
            color="gray"
            content="icon infront"
            iconName="UilSearch"
            onClick={() => setIsShowSearch(!isShowSearch)}
          >
            Cari diskon
          </Button>
        )}
        <Button
          variant="primary"
          color="blue"
          className="ml-2 whitespace-nowrap"
          onClick={() => setIsShowCreate(true)}
        >
          Tambah diskon baru
        </Button>
        {/* END: HEADER */}
      </div>
      {/* END:: HEADER */}

      {/* START: TABLE */}
      <div className="overflow-scroll scroll-hidden" style={{ height: "74vh" }}>
        <Table status={discount.status} data={discount.data} />
      </div>
      {/* END: TABLE */}

      {/* START: CREATE */}
      <DiscountCraete
        title="Tambah diskon baru"
        show={isShowCreate}
        close={() => setIsShowCreate(false)}
      />
      {/* END: CREATE */}
    </div>
  );
}
