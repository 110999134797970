export const START_FETCHING_PRODUCT = 'features/Product/START_FETCHING_PRODUCT';
export const ERROR_FETCHING_PRODUCT = 'features/Product/ERROR_FETCHING_PRODUCT';
export const SUCCESS_FETCHING_PRODUCT = 'features/Product/SUCCESS_FETCHING_PRODUCT';
export const SUCCESS_FETCHING_LOCATION = 'features/Product/SUCCESS_FETCHING_LOCATION';
export const SUCCESS_FETCHING_CATEGORY = 'features/Product/SUCCESS_FETCHING_CATEGORY';
export const SUCCESS_FETCHING_VARIANT = 'features/Product/SUCCESS_FETCHING_VARIANT';
export const SET_FILTER_LIST_VARIANT = 'features/Product/SET_FILTER_LIST_VARIANT';
export const SET_CATEGORY = 'features/Product/SET_CATEGORY';
export const SET_KEYWORD = 'features/Product/SET_KEYWORD';
export const SET_LOCATION = 'features/Product/SET_LOCATION';
export const SET_VARIANT = 'features/Product/SET_VARIANT';
export const SET_PAGE = 'features/Product/SET_PAGE';
export const SET_LIMIT = 'features/Product/SET_LIMIT';
export const SET_NOTIF = 'features/Product/SET_NOTIF';