import React, { useState } from "react";
import Button from "components/atoms/Button";
import Table from "./tableBalanceBill";
import ModalCreate from "./create";
import Banner from "components/atoms/Banner";
import "./balanceBill.css";
import { useSelector } from "react-redux";

export default function PageReportBalanceBill() {
  const [isShowCreate, setIsShowCreate] = useState(false);
  const data = useSelector((state) => state.balance);

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {data.notif?.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={data.notif.message}
          status={data.notif.status}
          type={data.notif.type}
        />
      )}
      <div>
        <h1 className="heading-3 text-dark mt-4">Rekening bank</h1>
        <div className="flex items-end justify-between">
          <p className="text-1 text-gray-02 mt-2 w-2/3">
            Dana hanya akan bisa ditransfer ke rekening yang sudah di verifikasi. Verifikasi
            biasanya memakan waktu 2-3 hari kerja. Proses transfer biasanya memakan waktu 1-2 hari
            kerja.
          </p>
          <Button variant="primary" color="blue" onClick={() => setIsShowCreate(true)}>
            <p className="whitespace-nowrap">Tambah rekening bank</p>
          </Button>
        </div>
      </div>

      <Table />

      {isShowCreate && <ModalCreate show={isShowCreate} close={() => setIsShowCreate(false)} />}
    </div>
  );
}
