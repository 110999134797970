import {
  START_FETCHING_REPORT_PRODUCT,
  SUCCESS_FETCHING_REPORT_PRODUCT,
  ERROR_FETCHING_REPORT_PRODUCT,
  SET_LOCATION,
  SET_DATE
} from "./constants";
import { userLogout } from 'features/Auth/actions'
import moment from 'moment'

import { getDatav2 } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchReport = debounce(getDatav2, 1000);

export const startFetchingReportProduct = () => {
  return {
    type: START_FETCHING_REPORT_PRODUCT,
  };
};

export const errorFetchingReportProduct = () => {
  return {
    type: ERROR_FETCHING_REPORT_PRODUCT,
  };
};

export const successFetchingReportProduct = ({ graphic, table, location }) => {
  return {
    type: SUCCESS_FETCHING_REPORT_PRODUCT,
    graphic,
    table,
    location
  };
};

export const fetchReportProduct = () => {
  return async (dispatch, getState) => {

    dispatch(startFetchingReportProduct());

    try {

      let start = moment(getState().reportProduct.date.startDate).format("YYYY-MM-DD") || ''
      let end = moment(getState().reportProduct.date.endDate).format("YYYY-MM-DD") || '';
      let location = getState().reportProduct.location.value || '';

      const params = {
        start,
        end,
        location
      }

      let { data: { outlets }, } = await debouncedFetchReport("outlets");

      let select_box = [{ label: "Semua lokasi", value: "", }]
      outlets.forEach((item) => {
        select_box.push({
          label: item.name,
          value: item.id,
        });
      });

      const res = await debouncedFetchReport('v2/report/product', params)
      if (res?.data?.code === 401) {
        dispatch(userLogout())
      }

      let
        labels = [],
        data = [],
        one = [],
        labelOne = '',
        two = [],
        labelTwo = '',
        three = [],
        labelThree = '',
        four = [],
        labelFour = '',
        five = [],
        labelFive = '';
      /**
       * check total chart
       */

      for (let i = 0; i < res.data.chart.length; i++) {
        for (let x = 0; x < res.data.chart[i].details.length; x++) {
          let total = 0;
          for (let y = 0; y < res.data.chart[i].details[x].item.length; y++) {
            total += res.data.chart[i].details[x].item[y].price;
          }
          if (i === 0) {
            labels.push(res.data.chart[i].details[x].date)
            one.push(total)
            labelOne = res.data.chart[i].name
          } else if (i === 1) {
            two.push(total)
            labelTwo = res.data.chart[i].name
          } else if (i === 2) {
            three.push(total)
            labelThree = res.data.chart[i].name
          } else if (i === 3) {
            four.push(total)
            labelFour = res.data.chart[i].name
          } else if (i === 4) {
            five.push(total)
            labelFive = res.data.chart[i].name
          }
        }
      }
      // res.data.chart.forEach((chart, i) => {
      //   chart.details.forEach(detail => {
      //     let total = 0, count = 0;
      //     detail.item.forEach(item => {
      //       console.log("item.price >>" + chart.name)
      //       console.log(item.price)
      //       total += item.price;
      //       count += item.qty;
      //     })

      //     console.log("count >>")
      //     console.log(count)
      //     if (i === 0) {
      //       labels.push(detail.date)
      //       one.push(total)
      //       labelOne = chart.name
      //       countOne.push(count)
      //     } else if (i === 1) {
      //       two.push(total)
      //       countTwo.push(count)
      //       labelTwo = chart.name
      //     } else if (i === 2) {
      //       three.push(total)
      //       countThree.push(count)
      //       labelThree = chart.name
      //     } else if (i === 3) {
      //       four.push(total)
      //       countFour.push(count)
      //       labelFour = chart.name
      //     } else if (i === 4) {
      //       five.push(total)
      //       countFive.push(count)
      //       labelFive = chart.name
      //     }
      //   })

      // })

      const graphic = {
        maintainAspectRatio: false,
        responsive: false,
        labels,
        datasets: [
          {
            label: labelOne,
            data: one,
            borderColor: "rgb(31, 75, 255)",
            backgroundColor: ["rgba(31, 75, 255, 0.3)"],
            lineTension: 0,
          },
          {
            label: labelTwo,
            data: two,
            borderColor: 'rgba(38,217,66)',
            backgroundColor: ['rgba(38,217,66, 0.3)'],
            lineTension: 0,
          },
          {
            label: labelThree,
            data: three,
            borderColor: '#DB1111',
            backgroundColor: ['rgba(219,17,17, 0.2)'],
            lineTension: 0,
          },
          {
            label: labelFour,
            data: four,
            borderColor: '#E2B102',
            backgroundColor: ['rgba(226,177,2, 0.2)'],
            lineTension: 0,
          },
          {
            label: labelFive,
            data: five,
            borderColor: '#A944CC',
            backgroundColor: ['rgba(169,68,204, 0.2)'],
            lineTension: 0,
          }
        ]
      }

      dispatch(successFetchingReportProduct({
        graphic: {
          type: "line",
          width: 300,
          height: 160,
          data: { id: 1, ...graphic }
        },
        table: res.data.table,
        location: select_box
      }))

    } catch (err) {
      dispatch(errorFetchingReportProduct());
    }
  };
};

export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};

export const setDate = date => {
  return {
    type: SET_DATE,
    date: date.selection ? date.selection : date
  }
}