import {
  START_FETCHING_TRANSACTION,
  SUCCESS_FETCHING_TRANSACTION,
  ERROR_FETCHING_TRANSACTION,
  SET_KEYWORD,
  SET_TRANSACTION,
  SET_DATE,
  SET_PAYMENT_METHOD,
  SET_LOCATION,
  SET_PAGE,
  SET_LIMIT
} from './constants';


const statuslist = {
  idle: 'idle',
  process: 'process',
  success: 'success',
  error: 'error',
}

const initialState = {
  data: [],
  outlets: [],
  salesInfo: {},
  pages: 0,
  page: 1,
  limit: 10,
  keyword: '',
  salesCount: 0,
  dataCollected: 0,
  netSales: 0,
  location: { value: '', label: 'Semua lokasi' },
  payment_method: { value: '', label: 'Semua metode pembayaran' },
  transaction: { value: '', label: 'Semua transaksi' },
  date: {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  },
  status: statuslist.idle
};

export default function reducer(state = initialState, action) {

  switch (action.type) {

    case START_FETCHING_TRANSACTION:
      return { ...state, status: statuslist.process }

    // tangani `ERROR_FETCHING_PRODUCT`
    case ERROR_FETCHING_TRANSACTION:
      return { ...state, status: statuslist.error }

    // tangani `SUCCESS_FETCHING_PRODUCT`
    case SUCCESS_FETCHING_TRANSACTION:
      return { ...state, salesInfo: action.salesInfo, status: statuslist.success, data: action.sales, salesCount: action.salesCount, dataCollected: action.dataCollected, netSales: action.netSales, pages: action.pages, outlets: action.outlets }

    case SET_LOCATION:
      return { ...state, location: action.location, page: 1 }

    case SET_KEYWORD:
      return { ...state, keyword: action.keyword, page: 1 }

    case SET_PAYMENT_METHOD:
      return { ...state, payment_method: action.payment, page: 1 }

    case SET_TRANSACTION:
      return { ...state, transaction: action.transaction, page: 1 }

    case SET_DATE:
      return { ...state, date: action.date, page: 1 }

    case SET_PAGE:
      return { ...state, page: action.page }

    case SET_LIMIT:
      return { ...state, limit: action.limit, page: 1 }

    default:
      return state;
  }

}
