import {
  SUCCESS_FETCING_BARCODE,
  SET_BARCODE,
  SET_GENERATE_BARCODE
} from "./constants";
import { userLogout } from 'features/Auth/actions'

import { getData } from 'utils/fetchData'


export const fetchProductInBarcode = () => {
  return async (dispatch) => {

    let res = await getData("v2/inventory/barcode");
    if (res.data?.code === 401) {
      return dispatch(userLogout())
    }
    const category = [], list = []

    res.data.inventories.forEach((data) => {
      if (data.category) {
        category.push({
          categoryId: data.category?.id,
          categoryName: data.category?.categoryName,
          isCheck: false
        })
      }
      data.isCheck = false
      list.push(data)
    })

    var dataArr = category.map(item => {
      return [item.categoryName, item]
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array

    var result = [...maparr.values()];//converting back to array from mapobject
    dispatch(successFetchingBarcode({ category: result, list }));
  };
};

export const successFetchingBarcode = ({ category, list }) => {
  return {
    type: SUCCESS_FETCING_BARCODE,
    category,
    list
  };
};


export const setCategoryInBarcode = (category) => {
  return {
    type: SET_BARCODE,
    category,
  };
};
export const setGenerateBarcode = (payload) => {
  return {
    type: SET_GENERATE_BARCODE,
    payload,
  };
};
