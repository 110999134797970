import React from "react";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Banner from "components/atoms/Banner";
import Spinner from "components/atoms/Spinner";
import MeasueEdit from "./edit";
import ModalDialog from "components/molecules/Modal";
import { fetchMeasure, setNotif } from "features/Measure/action";
import { useDispatch, useSelector } from "react-redux";
import { deleteData } from "utils/fetchData";
import Button from "components/atoms/Button";

function TableMeasure({ data }) {
  const dispatch = useDispatch();
  const notif = useSelector((state) => state.measure.notif);
  const [check, setCheck] = React.useState({
    id: "",
    name: "",
    productCount: "",
  });

  const [loading, setLoading] = React.useState(false);
  const [isSweetAlert, setIsSweetAlert] = React.useState(false);
  const [isShowEdit, setIsShowEdit] = React.useState(false);
  const [measureId, setMeasureId] = React.useState();

  const handleShowSweetAlert = (payload) => {
    setCheck({
      ...check,
      id: payload.id,
      name: payload.name,
      productCount: payload.productCount,
    });
    setIsSweetAlert(true);
  };

  const handleShowEdit = (payload) => {
    setMeasureId(payload.id);
    setIsShowEdit(true);
  };

  const handleDeleteMeasure = async (id) => {
    try {
      setLoading(true);
      const res = await deleteData(`v2/unitofmeasure/${id}`);

      if (res.data?.code === 200) {
        setLoading(false);
        const notif = {
          message: `Satuan akur "${check.name}" berhasil di hapus`,
          type: "primary",
          status: "danger",
          toggle: true,
        };
        dispatch(setNotif(notif));
        dispatch(fetchMeasure());
        setIsSweetAlert(false);
        setCheck({ id: "", name: "", productCount: "" });
      }

    } catch (err) {
      setLoading(true);
    }
  };

  return (
    <React.Fragment>
      <table className="w-full">
        <thead>
          <tr>
            <th>Nama satuan ukur</th>
            <th>Singkatan</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.status === "process" ? (
            <tr key="status">
              <td colSpan="3" className="text-center">
                <div className="flex items-center justify-center">
                  <Spinner size={64} color="#859DFF" />
                </div>
              </td>
            </tr>
          ) : data.data.length ? (
            data.data.map((items) => {
              return (
                <React.Fragment key={items.id}>
                  <tr>
                    <td>{items.name}</td>
                    <td>{items.unit}</td>
                    <td className="relative">
                      <Dropdown
                        variant="subtle"
                        color="blue"
                        content="icon only"
                      >
                        <DropdownList
                          onClick={() => handleShowEdit(items)}
                          type="button"
                          color="blue"
                        >
                          Edit satuan ukur
                        </DropdownList>
                        <DropdownList
                          onClick={() => handleShowSweetAlert(items)}
                          type="button"
                          color="red"
                        >
                          Hapus satuan ukur
                        </DropdownList>
                      </Dropdown>
                    </td>
                  </tr>
                  {notif.toggle && notif.id === items.id && (
                    <tr>
                      <td colSpan={3} className="p-0">
                        <Banner
                          status={notif.status}
                          type={notif.type}
                          message={notif.message}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <td colSpan="3" className="text-center">
                <span className="text-gray-02">Tidak ditemukan data</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* START: EDIT MEASURE */}
      {isShowEdit && (
        <MeasueEdit
          title="Ubah nama satuan ukur"
          measure={measureId}
          show={isShowEdit}
          close={() => setIsShowEdit(false)}
        />
      )}
      {/* END: EDIT MEASURE */}

      {/* START: ALERT DELETE */}
      <ModalDialog
        size={"alert"}
        show={isSweetAlert}
        onClose={() => setIsSweetAlert(false)}
        title="Hapus satuan ukur"
      >
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p>
            Apakah Anda yakin ingin menghapus “{check.name}” ? Bahan baku yang telah menggunakan satuan ukur
            ini tidak akan mempunyai satuan ukur dan akan dihitung secara biasa. Aksi ini tidak bisa
            dibatalkan lagi jika satuan ukur telah berhasil dihapus.{" "}
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
            onClick={() => setIsSweetAlert(false)}
          >
            Batal hapus satuan ukur
          </Button>
          <Button
            variant="primary"
            color="red"
            className="my-5"
            type="button"
            loading={loading ? true : false}
            disabled={loading ? true : false}
            onClick={!loading ? () => handleDeleteMeasure(check.id) : null}
          >
            Hapus satuan ukur
          </Button>
        </div>
      </ModalDialog>
      {/* END: ALERT DELETE */}
    </React.Fragment>
  );
}

export default TableMeasure;
