import React, { useState, useEffect } from "react";
import SelectBox from "components/atoms/SelectBox";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import TextCaption from "components/atoms/Text/TextCaption";
import Banner from "components/atoms/Banner";
import IconWebsite from "components/atoms/Icons/Custom/icon new/Website";
import IconInstagram from "components/atoms/Icons/Custom/icon new/Instagram";
import IconFacebook from "components/atoms/Icons/Custom/icon new/Facebook";
import Button from "components/atoms/Button";
import UpdatePin from "./pin";
import ModalNonaktiAkun from "./nonaktifakun";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { getData, putData, deleteData } from "utils/fetchData";
import "./settingAccount.css";
import ResetPassword from "./resetPassword";

const phoneCode = [
  {
    label: "Malaysia",
    value: "+60",
    code: "my-MY",
  },

  {
    label: "Singapore",
    value: "+65",
    code: "sg-SG",
  },
  {
    label: "Papua New Guinea",
    value: "+675",
    code: "pg-PG",
  },
  {
    label: "Timor-Leste",
    value: "+670",
    code: "tl-TL",
  },
];

const SettingSchema = yup.object().shape({
  phone: yup.string(),
});

export default function PageSettingAccount() {
  const { register, errors, setError } = useForm({
    mode: "onBlur",
    validationSchema: SettingSchema,
  });

  const [field, setField] = useState({
    brand_name: "",
    name: "",
    email: "",
    phone: "62",
    phoneCode: "id-ID",
    role: 3,
    pin_status: "1234",
    website: null,
    instagram: null,
    facebook: null,
  });

  const [notif, setNotif] = useState({
    message: "",
    type: "",
    status: "",
    toggle: false,
  });

  const [defaultPhone, setDefaultPhone] = useState({
    label: "Indonesia",
    value: "+62",
    code: "id-ID",
  });

  const [loading, setLoading] = useState(false);
  const [isShowPin, setIsShowPin] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [pinStatus, setPinStatus] = useState(false);

  const [nonaktiAkun, setNonaktiAkun] = React.useState(false);

  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };
  const onChangePin = (value) => {
    setField({ ...field, pin_status: value });
  };

  // const onChangePhone = (e) => {
  //   let value = e.value;
  //   setField({ ...field, phoneCode: e.code });
  //   setDefaultPhone({
  //     ...defaultPhone,
  //     label: e.label,
  //     value: value,
  //   });
  //   updateSettingAuto();
  // };

  const getSetting = async () => {
    let notif = {
      message: "",
      type: "",
      status: "",
      toggle: false,
    };
    setTimeout(() => {
      setNotif(notif);
    }, 5000);
    await getData("v2/setting/account").then((res) => {
      let results = res?.data?.setting;
      setField({
        brand_name: results.brand_name,
        name: results.name,
        email: results.email,
        phone: results?.phone,
        phoneCode: "id-ID",
        role: results.role,
        pin_status: results.pin_status,
        website: results.website,
        instagram: results.instagram,
        facebook: results.facebook,
      });
    });
  };

  const updateSettingAuto = async (payload) => {
    setLoading(true);
    await putData("v2/setting/account", payload)
      .then((res) => {
        if (res.status === 200) {
          let notif = {
            message: "Berhasil mengubah data",
            type: "primary",
            status: "success",
            toggle: true,
          };
          setNotif(notif);
          setLoading(false);
          setIsShowPin(false);
          getSetting();
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          err.response.data.forEach((item) => {
            setError(item.param, item.param, item.message);
          });
          setLoading(false);
        }
        setLoading(false);
      });
  };

  const updatePin = async () => {
    setLoading(true);
    await putData("v2/setting/pin", { pin: field?.pin_status })
      .then((res) => {
        if (res.status === 200) {
          let notif = {
            message: "Berhasil mengubah pin",
            type: "primary",
            status: "success",
            toggle: true,
          };
          setNotif(notif);
          setLoading(false);
          setIsShowPin(false);
          setPinStatus(true);
          getSetting();
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          alert("Pastikan pin 4 angka");
          setLoading(false);
        }
        setLoading(false);
      });
  };

  const dropPin = async () => {
    setLoading(true);
    await deleteData("v2/setting/pin")
      .then((res) => {
        console.log(res);
        setPinStatus(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  };

  console.log("field");
  console.log(field);

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {notif.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={notif.message}
          status={notif.status}
          type={notif.type}
        />
      )}
      <h1 className="font-semibold text-xl mt-3 mb-4">
        Informasi dasar&nbsp;
        <span className="text-gray-600 text-base font-normal">
          (ketuk diluar form untuk simpan otomatis)
        </span>
      </h1>
      <ul className="mt-4 create-customer">
        <li className="flex w-full   py-2">
          <InputTextLargeWithLabel
            value={field?.name}
            className="w-full"
            name="name"
            onChange={onChange}
            label="Nama pemilik"
            status="default"
            onBlur={() => updateSettingAuto({ name: field.name })}
          />
        </li>
        <li className="flex w-full   py-2">
          <InputTextLargeWithLabel
            value={field?.email}
            className="w-full"
            label="Email pemilik"
            status="default"
            name="email"
            onChange={onChange}
            onBlur={() => updateSettingAuto({ email: field.email })}
          />
        </li>
        <li className="flex w-full   py-2">
          <InputTextLargeWithLabel
            value={field?.brand_name}
            className="w-full"
            label="Nama bisnis"
            status="default"
            name="brand_name"
            onChange={onChange}
            onBlur={() => updateSettingAuto({ brand_name: field.brand_name })}
          />
        </li>
        <li className="flex w-full   py-2">
          {/* <div className="w-9/12">
            <SelectBox
              disabled
              label="Nomor telepon pemilik"
              className="w-full"
              type="text"
              name="phoneCode"
              value={defaultPhone}
              options={phoneCode}
              onChange={onChangePhone}
            />
          </div> */}
          {/* <div className="bg-gray-05 whitespace-nowrap bold-text-2 h-full p-2 px-4 flex items-center ml-4 mr-4">
            {defaultPhone?.value}
          </div> */}

          <InputTextLargeWithLabel
            className="w-full"
            label="Nomor telepon"
            type="number"
            name="phone"
            ref={register}
            value={field.phone}
            onChange={onChange}
            onBlur={() =>
              updateSettingAuto({
                phone: field.phone,
                phone_code: field.phoneCode,
              })
            }
          >
            {errors.phone && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.phone?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
        </li>
      </ul>
      <h1 className="heading-3 text-gray-02 mt-10">Media sosial</h1>
      <ul className="mt-4 create-customer setting-account">
        <li className="flex w-full  relative  py-2">
          <IconWebsite className="absolute left-5 top-6 " />
          <InputTextLargeWithLabel
            value={field?.website}
            className="w-full"
            label="Website"
            status="default"
            onChange={onChange}
            name="website"
            onBlur={() => updateSettingAuto({ website: field.website })}
          />
        </li>
        <li className="flex w-full  relative  py-2">
          <IconInstagram className="absolute left-5 top-6 " />
          <InputTextLargeWithLabel
            value={field?.instagram}
            className="w-full"
            label="Instagram"
            status="default"
            onChange={onChange}
            name="instagram"
            onBlur={() => updateSettingAuto({ instagram: field.instagram })}
          />
        </li>
        <li className="flex w-full  relative  py-2">
          <IconFacebook className="absolute left-5 top-6 " />
          <InputTextLargeWithLabel
            value={field?.facebook}
            className="w-full"
            label="Facebook"
            status="default"
            onChange={onChange}
            onBlur={() => updateSettingAuto({ facebook: field.facebook })}
            name="facebook"
          />
        </li>
      </ul>
      <h1 className="heading-3 text-gray-02 mt-10">PIN pemilik akun</h1>
      <p className="mt-2 text-gray-02">
        Pastikan kata sandi anda susah ditebak dan tidak mengandung unsur umum. Kata sandi <br />{" "}
        yang kuat harus mempunyai campuran huruf besar/kecil, angka, dan simbol.
      </p>
      <div className="flex w-full mt-5 py-2 nomor-telepon">
        <div className="w-10/12 flex items-center pr-10">
          <div className="flex items-center justify-between w-1/2">
            <input
              type="number"
              placeholder={pinStatus ? "*" : "0"}
              disabled
              className="w-16 text-center focus:outline-none border px-2 py-2 rounded-lg"
            />
            <p className="dash text-center">-</p>

            <input
              placeholder={pinStatus ? "*" : "0"}
              disabled
              type="number"
              className="w-16 text-center focus:outline-none border px-2 py-2 rounded-lg"
            />

            <p className="dash text-center">-</p>

            <input
              placeholder={pinStatus ? "*" : "0"}
              disabled
              type="number"
              className="w-16 text-center focus:outline-none border px-2 py-2 rounded-lg"
            />
            <p className="dash text-center">-</p>

            <input
              placeholder={pinStatus ? "*" : "0"}
              disabled
              type="number"
              className="w-16 text-center focus:outline-none border px-2 py-2 rounded-lg"
            />
          </div>
        </div>
        <div className="w-1/2 flex items-center justify-between">
          {pinStatus ? (
            <p className="text-green-03 font-semibold">Anda sudah membuat pin</p>
          ) : (
            <p className="text-red-03 font-semibold">Anda belum membuat pin</p>
          )}

          {pinStatus ? (
            <Button loading={loading} onClick={dropPin} variant="primary" color="red">
              Hapus
            </Button>
          ) : (
            ""
          )}

          <Button variant="primary" color="blue" onClick={() => setIsShowPin(true)}>
            {pinStatus ? "Ubah PIN" : "Buat PIN"}
          </Button>
        </div>
      </div>

      <h1 className="heading-3 text-gray-02 mt-10">Kata sandi</h1>
      <p className="mt-2 text-gray-02">
        Pastikan kata sandi anda susah ditebak dan tidak mengandung unsur umum. Kata sandi yang kuat
        harus mempunyai campuran huruf besar/kecil, angka, dan simbol.
      </p>

      <Button
        variant="primary"
        color="blue"
        className="my-4"
        onClick={() => setIsShowPassword(true)}
      >
        Ganti kata sandi
      </Button>

      {/* <h1 className="heading-3 text-gray-02 mt-10">Nonaktifkan akun</h1>
      <p className="mt-2 text-gray-02">
        Menonaktifkan akun anda berarti anda tidak akan dapat menerima atau memulihkan sejarah
        <br /> pembayaran atau informasi akun anda.
      </p>
      <Button
        variant="outlined"
        color="red"
        className="mt-4 mb-8"
        onClick={() => setNonaktiAkun(true)}
      >
        Nonaktifkan akun
      </Button> */}

      {isShowPin && (
        <UpdatePin
          show={isShowPin}
          close={() => setIsShowPin(false)}
          onChange={onChangePin}
          pin={field?.pin_status === null ? "" : field?.pin_status}
          loading={loading}
          updatePin={updatePin}
        />
      )}

      {isShowPassword && (
        <ResetPassword
          show={isShowPassword}
          close={() => setIsShowPassword(false)}
          setNotif={() => {
            let notif = {
              message: "Berhasil mengubah kata sandi",
              type: "primary",
              status: "success",
              toggle: true,
            };
            setNotif(notif);
            getSetting();
          }}
        />
      )}

      <ModalNonaktiAkun show={nonaktiAkun} close={() => setNonaktiAkun(false)} />
    </div>
  );
}
