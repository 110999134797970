import React from "react";
import Toggle from "components/atoms/Toggle";
import "./customerSetting.css";
import Button from "components/atoms/Button";

export default function PageCustomerSetting() {
  const [secondary, setSecondary] = React.useState(false);
  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {/* list 1 */}
      <ul>
        <li className="border-b">
          <div className="flex items-center">
            <div className="w-3/5 px-6 py-4">
              Lokasi yang bisa menerima ulasan
            </div>
            <div className="w-2/5 bg-blue-05 border border-blue-04 px-6 py-4">
              <div className="relative top-1 toggle-customer-setting">
                <Toggle
                  type="secondary"
                  checked={secondary}
                  onChange={() => setSecondary(!secondary)}
                />
              </div>
              <p className="font-semibold text-blue-03 pl-12">
                Restoran Nomor Satu
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <div className="w-3/5 px-6 py-4">
              Lokasi yang bisa menerima ulasan
            </div>
            <div className="w-2/5 bg-blue-05 border border-blue-04 px-6 py-4">
              <div className="relative top-1 toggle-customer-setting">
                <Toggle
                  type="secondary"
                  checked={secondary}
                  onChange={() => setSecondary(!secondary)}
                />
              </div>
              <p className="font-semibold text-blue-03 pl-12">
                Restoran Nomor Dua
              </p>
            </div>
          </div>
        </li>
      </ul>
      {/* list 2 */}
      <ul className="mt-6">
        <li className="border-b">
          <div className="flex items-center">
            <div className="w-3/5 px-6 py-4">Ikon ulasan positif / negatif</div>
            <div className="w-2/5 bg-blue-05 border border-blue-04 px-6 py-4 font-semibold text-blue-03">
              Restoran Nomor Satu
            </div>
          </div>
        </li>
      </ul>
      {/* list 3 */}
      <ul className="mt-6">
        <li className="border-b">
          <div className="flex items-center">
            <div className="w-3/5 px-6 py-4">
              Lokasi yang bisa menerima ulasan
            </div>
            <div className="w-2/5 bg-blue-05 border border-blue-04 px-6 py-4">
              <div className="relative top-1 toggle-customer-setting">
                <Toggle
                  type="secondary"
                  checked={secondary}
                  onChange={() => setSecondary(!secondary)}
                />
              </div>
              <p className="font-semibold text-blue-03 pl-12">
                Terima notifikasi ulasan
              </p>
            </div>
          </div>
        </li>
      </ul>
      {/* list 4 */}
      <ul className="mt-6">
        <li className="border-b">
          <div className="flex items-center">
            <div className="w-3/5 px-6 py-4">Penerima notifikasi ulasan</div>
            <div className="w-2/5 px-6 py-4">
              <p className="font-semibold">
                Alvin.lovanto@.com&nbsp;
                <span className="font-normal">(pemilik akun)</span>
              </p>
            </div>
          </div>
        </li>
        <li className="border-b">
          <div className="flex items-center">
            <div className="w-3/5 px-6 py-4"></div>
            <div className="w-2/5 px-6 py-4 flex justify-between items-center">
              <p className="font-semibold">Ricard.gas@com</p>
              <Button variant="primary" color="red">
                Hapus
              </Button>
            </div>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <div className="w-3/5 px-6 py-4"></div>
            <div className="w-2/5 px-6 py-4 flex justify-between items-center border-b">
              <button className="text-blue-03">Tambah penerima</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
