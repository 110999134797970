import {
  START_FETCHING_TAX,
  SUCCESS_FETCHING_TAX,
  ERROR_FETCHING_TAX,
  SET_NOTIF,
  SET_KEYWORD
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  // pages: 0,
  // page: 1,
  // limit: 10,
  // count: 0,
  keyword: '',
  status: statuslist.idle,
  notif: {
    id: '',
    message: '',
    type: '',
    status: '',
    toggle: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case START_FETCHING_TAX:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_TAX:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_TAX:
      return { ...state, status: statuslist.success, data: action.tax }

    case SET_KEYWORD:
      return { ...state, keyword: action.keyword, page: 1 }

    // case SET_PAGE:
    //   return { ...state, page: action.page }

    case SET_NOTIF:
      return { ...state, notif: action.notif }

    // case SET_LIMIT:
    //   return { ...state, limit: action.limit, page: 1 }

    default:
      return state;
  }
}
