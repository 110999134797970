import {
  START_FETCHING_LOCATION,
  SUCCESS_FETCHING_LOCATION,
  ERROR_FETCHING_LOCATION,
  SUCCESS_FETCHING_CHECKBOX_LOCATION,
  SET_NOTIF,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  checkboxs: [],
  select_box: [],
  notif: {
    message: "",
    type: "",
    status: "",
    toggle: false,
  },
  status: statuslist.idle,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_LOCATION:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_LOCATION:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_LOCATION:
      return {
        ...state,
        status: statuslist.success,
        data: action.outlets,
        select_box: action.select_box,
      };

    case SUCCESS_FETCHING_CHECKBOX_LOCATION:
      return { ...state, checkboxs: action.outlets };

    case SET_NOTIF:
      return { ...state, notif: action.notif };
    default:
      return state;
  }
}
