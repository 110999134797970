import React from 'react'
import propTyeps from 'prop-types'
import 'assets/stylesheets/radio.css'

function Radio(props) {
  const { type, label, name, value, checked, onChange, disabled, size } = props

  if (type === "primary") {
    return (
      <div className="relative">
        <label className={`${size === "sm" ? "wrap-radio" : "wrap-radio-large"}`}>
          {label && <span className="font-medium text-sm relative bottom-1px">{label}</span>}
          <input className={`${size === "sm" ? "radio-cs" : 'radio-cs-large'}`} type="radio" name={name} value={value} checked={value === checked} onChange={onChange} />
          <span className={`${size === "sm" ? "checkmark" : "checkmark-large"}`}></span>
        </label>
      </div>
    )
  }

  if (type === "secondary") {
    return (
      <div className="relative">
        <label className={`${size === "sm" ? "wrap-radio-secondary" : "wrap-radio-secondary-large"}`}>
          {label && <span className="font-medium text-sm relative bottom-1px text-blue-02">{label}</span>}
          <input className={`${size === "sm" ? "radio-cs-secondary" : 'radio-cs-secondary-large'}`} type="radio" name={name} value={value} checked={value === checked} onChange={onChange} />
          <span className={`${size === "sm" ? "checkmark-secondary" : "checkmark-secondary-large"}`}></span>
        </label>
      </div>
    )
  }

  if (type === "disabled") {
    return (
      <div className="relative">
        <label className={`${size === "sm" ? "wrap-radio-disabled" : "wrap-radio-disabled-large"}`}>
          {label && <span className="font-medium text-sm relative bottom-1px text-gray-04">{label}</span>}
          <input className={`${size === "sm" ? "radio-cs-disabled" : 'radio-cs-disabled-large'}`} type="radio" name={name} value={value} checked={value === checked} onChange={onChange} disabled />
          <span className={`${size === "sm" ? "checkmark-disabled" : "checkmark-disabled-large"}`}></span>
        </label>
      </div>
    )
  }

}

Radio.defaultProps = {
  type: 'primary',
  size: 'sm'
}

Radio.propTyeps = {
  onChange: propTyeps.func,
  value: propTyeps.string,
  name: propTyeps.string,
  checked: propTyeps.string,
  type: propTyeps.string,
  disabled: propTyeps.bool,
  size: propTyeps.string,
}

export default Radio