import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import Button from "components/atoms/Button";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextCaption from "components/atoms/Text/TextCaption";
import { putData, getData } from "utils/fetchData";
import { useDispatch } from "react-redux";
import { setNotif, fetchCategory } from "features/Category/actions";
import { fetchLog, setNotifLog } from "features/ReportLog/actions";

const CategorySchema = yup.object().shape({
  name: yup.string().required("nama kategori tidak boleh kosong."),
  website: yup.string().url(),
});

export default function CategoryCreate({ show, close, title, category, logStatus }) {
  const dispatch = useDispatch();
  const [field, setField] = React.useState({ name: "", products: [] });
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, errors, setError } = useForm({
    mode: "onBlur",
    validationSchema: CategorySchema,
  });

  React.useEffect(() => {
    const getSingleCategory = async () => {
      const res = await getData(`v2/category/${category}`);
      if (res.data?.code === 200) {
        setField({
          name: res.data.category.categoryName,
        });
      }
    };
    getSingleCategory();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const res = await putData(`v2/category/${category}`, field);
      if (res.data.code === 200) {
        setLoading(false);
        close();
        setField({ name: "" });
        const notif = {
          message: `Kategori "${res.data.category.categoryName}" berhasil diubah`,
          type: "primary",
          status: "success",
          id: Number(res.data.category.id),
          toggle: true,
        };

        dispatch(setNotif(notif));
        dispatch(fetchCategory());
        logStatus && dispatch(setNotifLog(notif));
        logStatus && dispatch(fetchLog());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const clearField = () => {
    setField({ name: "", product: [] });
  };

  return (
    <form onSubmit={!loading ? handleSubmit(onSubmit) : null}>
      {/* START: MODAL INPUT KATEGORI */}
      <ModalDialog
        size={"alert"}
        show={show}
        onClose={() => {
          clearField();
          close();
        }}
        title={<p className="text-dark heading-3">{title}</p>}
      >
        {/* START: CONTENT */}
        <div className={`bg-gray-05 p-5 h-full overflow-scroll pl-20`}>
          <InputTextLargeWithLabel
            className="w-full"
            label="Nama kategori"
            status={errors?.name ? "error" : "default"}
            register={register}
            name="name"
            onChange={onChange}
            value={field.name}
          >
            {errors.name && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.name?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
        </div>
        {/* END: CONTENT */}

        {/* START: FOOTER */}
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="red"
            className="my-5 mx-2"
            type="button"
            onClick={null}
            disabled={field.name === "" ? true : false}
          >
            Hapus kategori
          </Button>
          <Button
            variant="primary"
            color="blue"
            className="my-5"
            type="submit"
            loading={loading ? true : false}
            disabled={field.name === "" ? true : false}
          >
            Selesai edit kategori
          </Button>
          {/* END: FOOTER */}
        </div>
      </ModalDialog>
      {/* END: MODAL INPUT KATEGORI */}
    </form>
  );
}
