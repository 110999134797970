import React from "react";
import propTypes from "prop-types";
import InputTextSmall from "../InputTextSmall";
import "components/atoms/Button/style.css";
import Info from "./info";

function InputTextLargeGroupWithLabel(props) {
  const {
    register,
    info,
    label,
    disabled,
    value,
    type,
    placeholder,
    name,
    onChange,
    size,
    status,
    children,
    spacing,
    currency,
    onBlur,
  } = props;

  const className = [props.className];

  return (
    <div
      className={`flex w-full items-center text-gray-02 group ${
        status === "default" ? "focus-within:text-blue-03" : ""
      }`}
    >
      <span
        className={`${spacing} bold-text-2 currentColor duration-500 mb-1 mr-5 whitespace-nowrap group-hover:text-blue-03 ${
          !children ? "" : "relative -top-2"
        }`}
      >
        {label}
      </span>
      {info && <Info />}
      <div className={className}>
        <InputTextSmall
          register={register}
          className={`py-4 w-full ${info && "ml-5"}`}
          status={status}
          disabled={disabled}
          value={value}
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          size={size}
          currency={currency}
          onBlur={onBlur}
        />
        {children}
      </div>
    </div>
  );
}

InputTextLargeGroupWithLabel.defaultProps = {
  info: false,
  className: "w-540",
};

InputTextLargeGroupWithLabel.propTypes = {
  label: propTypes.string.isRequired,
  info: propTypes.bool,
};

export default InputTextLargeGroupWithLabel;
