import React from 'react'

export default function Marketing({width,fill,height,className}) {
    return (
            <svg className={className} width={width} height={height} viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.9912 0.00198381C17.8599 0.00190888 17.7298 0.0277234 17.6084 0.0779516C17.4871 0.12818 17.3768 0.201836 17.2839 0.29471C17.1911 0.387583 17.1174 0.497852 17.0672 0.619212C17.017 0.740571 16.9911 0.870641 16.9912 1.00198V1.6387C16.1478 2.68441 15.0819 3.5289 13.871 4.1107C12.66 4.69251 11.3346 4.99699 9.99121 5.00198H3.99121C3.19583 5.00285 2.43327 5.3192 1.87085 5.88162C1.30843 6.44404 0.992079 7.2066 0.991211 8.00198V10.002C0.992079 10.7974 1.30843 11.5599 1.87085 12.1223C2.43327 12.6848 3.19583 13.0011 3.99121 13.002H4.475L2.07227 18.6084C2.00698 18.7604 1.98047 18.9263 1.99512 19.0912C2.00978 19.256 2.06514 19.4146 2.15624 19.5528C2.24734 19.6909 2.37133 19.8043 2.51706 19.8827C2.6628 19.9611 2.82572 20.0021 2.99121 20.002H6.99121C7.18696 20.0021 7.37843 19.9447 7.54182 19.8369C7.7052 19.729 7.83329 19.5756 7.91016 19.3955L10.6339 13.04C11.8646 13.1303 13.0636 13.472 14.157 14.0439C15.2505 14.6158 16.215 15.4058 16.9912 16.3651V17.002C16.9912 17.2672 17.0966 17.5216 17.2841 17.7091C17.4716 17.8966 17.726 18.002 17.9912 18.002C18.2564 18.002 18.5108 17.8966 18.6983 17.7091C18.8859 17.5216 18.9912 17.2672 18.9912 17.002V1.00198C18.9913 0.870641 18.9655 0.74057 18.9152 0.61921C18.865 0.49785 18.7914 0.387581 18.6985 0.294707C18.6056 0.201833 18.4953 0.128177 18.374 0.077949C18.2526 0.0277213 18.1226 0.00190757 17.9912 0.00198381ZM3.99121 11.002C3.72605 11.0018 3.4718 10.8964 3.2843 10.7089C3.0968 10.5214 2.99139 10.2671 2.99121 10.002V8.00198C2.99139 7.73682 3.09681 7.48257 3.2843 7.29508C3.4718 7.10758 3.72605 7.00216 3.99121 7.00198H4.99121V11.002H3.99121ZM6.33203 18.002H4.50781L6.65039 13.002H8.47461L6.33203 18.002ZM16.9912 13.5238C15.0195 11.8994 12.5459 11.0083 9.99121 11.0019H6.99121V7.00194H9.99121C12.5459 6.99541 15.0195 6.10409 16.9912 4.47959V13.5238Z" fill={fill}/>
</svg>


    )
}

Marketing.defaultProps ={
    width:'18px',
    height:'20px',
    fill:'#8F8F8F'
}
