import {
  START_FETCHING_DEVICE,
  SUCCESS_FETCHING_DEVICE,
  ERROR_FETCHING_DEVICE,
  SET_KEYWORD,
  SET_NOTIF, SET_LOCATION,
  SET_PAGE,
  SET_LIMIT,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  error: '',
  outlets: [],
  status: statuslist.idle,
  keyword: '',
  location: '',
  total: 0,
  pages: 0,
  page: 1,
  limit: 10,
  notif: {
    id: '',
    message: '',
    type: '',
    status: '',
    toggle: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_DEVICE:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_DEVICE:
      return { ...state, status: statuslist.error, error: action.message };

    case SUCCESS_FETCHING_DEVICE:
      return { ...state, status: statuslist.success, pages: action.pages, total: action.total, data: action.device, outlets: action.location };

    case SET_KEYWORD:
      return { ...state, keyword: action.keyword, page: 1 }

    case SET_LOCATION:
      return { ...state, location: action.location, page: 1 }

    case SET_NOTIF:
      return { ...state, notif: action.notif }

    case SET_PAGE:
      return { ...state, page: action.page }

    case SET_LIMIT:
      return { ...state, limit: action.limit, page: 1 }

    default:
      return state;
  }
}
