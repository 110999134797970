import * as React from "react";
import { InputDateRange } from "components/atoms/Form/InputDate/index";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Button from "components/atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchSummary, setLocation, setDate } from "features/Report/actions";
import { formatRupiah } from "utils/formatRupiah";
import Spinner from "components/atoms/Spinner";
import "pages/Dashboard/style.css";
import "./style.css";
import Graphic from "components/molecules/Charts";
import moment from "moment";

export default function PageReport() {
  const dispatch = useDispatch();
  const report = useSelector((state) => state.report);

  const [filterLocation, setFilterLocation] = React.useState({
    value: "",
    label: "Semua lokasi",
  });

  React.useEffect(() => {
    dispatch(fetchSummary());
  }, [dispatch, report.location, report.date]);

  const onSelectLocation = (data) => {
    setFilterLocation(data);
    dispatch(setLocation(data.value));
  };

  const onChangeDateCostum = (start, end) => {
    const ranges = {
      startDate: start,
      endDate: end,
      key: "selection",
    };
    dispatch(setDate(ranges));
  };

  if (report.status === "process") {
    return (
      <div className="flex items-center justify-center mt-56">
        <Spinner size={64} color="#859DFF" />
      </div>
    );
  }

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {/* header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <InputDateRange
            onChangeDateCostum={(start, end) => onChangeDateCostum(start, end)}
            variant="range"
            type="custom right"
            onChange={(ranges) => dispatch(setDate(ranges))}
            name="date"
            value={report.date}
          />
          <div className="ml-4">
            <Dropdown
              variant="outlined"
              color="gray"
              className="mr-1"
              content="label only"
              label={filterLocation.label}
            >
              {report.outlets.map((outlet) => {
                return (
                  <React.Fragment key={outlet.value}>
                    <DropdownList
                      list="item"
                      color="blue"
                      type="button"
                      onClick={() => onSelectLocation(outlet)}
                    >
                      {outlet.label}
                    </DropdownList>
                  </React.Fragment>
                );
              })}
            </Dropdown>
          </div>
        </div>
        {/* <Button variant="outlined" color="blue">
          Export laporan
        </Button> */}
      </div>
      {/* content */}
      <h1 className="heading-3 mt-4">
        {moment(report.date.startDate).format("DD/MM/YYYY")} -{" "}
        {moment(report.date.endDate).format("DD/MM/YYYY")}
      </h1>
      <div className="overflow-x-scroll overflow-y-hidden spacing-graph">
        <div className="App relative">
          {report?.graphic?.data && (
            <Graphic
              type={report?.graphic?.type}
              width={2500}
              height={330}
              data={report?.graphic?.data}
              multi={false}
            />
          )}
        </div>
      </div>

      {/* penjualan dan lama */}
      <h1 className="heading-3 mt-8">Penjualan & laba</h1>
      <ul className="mt-4">
        <li className="border-b" style={{ minWidth: "50%", width: "50%" }}>
          <div className="flex justify-between items-center">
            <div className="p-4">
              <p className="bold-text-2">Penjualan kotor</p>
            </div>
            <div className="p-4 bg-gray-05 w-48 flex justify-end">
              <p className="bold-text-2">
                {formatRupiah(
                  report.salesInfo?.total + report.salesInfo?.refund?.total
                )}
              </p>
            </div>
          </div>
        </li>
        <li className="border-b" style={{ minWidth: "50%", width: "50%" }}>
          <div className="flex justify-between items-center">
            <div className="p-4">
              <p className="bold-text-2">Refund</p>
            </div>
            <div className="p-4 bg-gray-05 w-48 flex justify-end">
              <p className="bold-text-2">
                {formatRupiah(report.salesInfo?.refund?.total)}
              </p>
            </div>
          </div>
        </li>
        <li className="border-b" style={{ minWidth: "50%", width: "50%" }}>
          <div className="flex justify-between items-center">
            <div className="p-4">
              <p className="bold-text-2">Diskon</p>
            </div>
            <div className="p-4 bg-gray-05 w-48 flex justify-end">
              <p className="bold-text-2">
                {formatRupiah(
                  report.salesInfo?.discount +
                    report?.salesInfo?.refund?.discount
                )}
              </p>
            </div>
          </div>
        </li>
        <li className="border-b" style={{ minWidth: "50%", width: "50%" }}>
          <div className="flex justify-between items-center">
            <div className="p-4">
              <p className="bold-text-2">Pajak</p>
            </div>
            <div className="p-4 bg-gray-05 w-48 flex justify-end">
              <p className="bold-text-2">
                -{" "}
                {formatRupiah(
                  report.salesInfo?.tax + report?.salesInfo?.refund?.tax
                )}
              </p>
            </div>
          </div>
        </li>
        <li className="border-b" style={{ minWidth: "68%", width: "68%" }}>
          <div className="flex justify-between items-center">
            <div className="p-4">
              <p className="bold-text-2">Penjualan bersih setelah pajak</p>
            </div>
            <div className="p-4 bg-blue-05 w-48 flex justify-end">
              <p className="bold-text-2 text-blue-03">
                {formatRupiah(
                  report.salesInfo?.total +
                    report?.salesInfo?.refund?.total -
                    (report.salesInfo?.tax + report?.salesInfo?.refund?.tax)
                )}
              </p>
            </div>
          </div>
        </li>
        <li className="border-b" style={{ minWidth: "68%", width: "68%" }}>
          <div className="flex justify-between items-center">
            <div className="p-4">
              <p className="bold-text-2">Jumlah biaya produk</p>
            </div>
            <div className="p-4 bg-blue-05 w-48 flex justify-end">
              <p className="bold-text-2 text-blue-03">
                -{" "}
                {formatRupiah(
                  report?.salesInfo?.modal + report.salesInfo?.refund?.modal
                )}
              </p>
            </div>
          </div>
        </li>
        <li
          className="border-b border-blue-03"
          style={{ minWidth: "86%", width: "86%" }}
        >
          <div className="flex justify-between items-center">
            <div className="p-4">
              <p className="bold-text-2 text-blue-03">Laba</p>
            </div>
            <div className="p-4 bg-blue-03 w-48 flex justify-end">
              <p className="bold-text-2 text-white">
                {formatRupiah(
                  report?.salesInfo?.total +
                    report?.salesInfo?.refund?.total -
                    (report.salesInfo?.tax + report?.salesInfo?.refund?.tax) -
                    (report?.salesInfo?.modal + report.salesInfo?.refund?.modal)
                )}
              </p>
            </div>
          </div>
        </li>
      </ul>
      {/* Transaksi dana */}
      <h1 className="heading-3 mt-8">Transaksi dana</h1>
      <ul className="mt-4 pb-10">
        {report?.topPayment &&
          report?.topPayment.map((data, i) => {
            return (
              <li
                className="border-b"
                style={{ minWidth: "50%", width: "50%" }}
                key={i}
              >
                <div className="flex justify-between items-center">
                  <div className="p-4">
                    <p className="bold-text-2">{data.name}</p>
                  </div>
                  <div className="p-4 bg-gray-05 w-48 flex justify-end">
                    <p className="bold-text-2">{formatRupiah(data.total)}</p>
                  </div>
                </div>
              </li>
            );
          })}

        <li className="border-b" style={{ minWidth: "68%", width: "68%" }}>
          <div className="flex justify-between items-center">
            <div className="p-4">
              <p className="bold-text-2">Jumlah dana kotor</p>
            </div>
            <div className="p-4 bg-blue-05 w-48 flex justify-end">
              <p className="bold-text-2 text-blue-03">
                -{" "}
                {`${
                  report?.topPayment
                    ? formatRupiah(
                        report?.topPayment.reduce((a, b) => a + b?.total, 0)
                      )
                    : formatRupiah(0)
                } `}
              </p>
            </div>
          </div>
        </li>
        <li className="border-b" style={{ minWidth: "68%", width: "68%" }}>
          <div className="flex justify-between items-center">
            <div className="p-4">
              <p className="bold-text-2">Refund</p>
            </div>
            <div className="p-4 bg-blue-05 w-48 flex justify-end">
              <p className="bold-text-2 text-blue-03">
                -{" "}
                {`${
                  report?.topPayment
                    ? formatRupiah(
                        report?.topPayment.reduce(
                          (a, b) => a + b?.refund?.total,
                          0
                        )
                      )
                    : formatRupiah(0)
                } `}
              </p>
            </div>
          </div>
        </li>
        <li
          className="border-b border-blue-03"
          style={{ minWidth: "86%", width: "86%" }}
        >
          <div className="flex justify-between items-center">
            <div className="p-4">
              <p className="bold-text-2 text-blue-03">Jumlah dana bersih</p>
            </div>
            <div className="p-4 bg-blue-03 w-48 flex justify-end">
              <p className="bold-text-2 text-white">
                {report?.salesInfo?.total &&
                  formatRupiah(
                    report?.salesInfo?.total - report?.salesInfo?.refund.total
                  )}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
