import React, { useEffect } from "react";
import SearchBar from "components/atoms/SearchBar";
import Button from "components/atoms/Button";
import Table from "./tableCustomerGroup";
import Pagination from "components/atoms/Pagination";
import Banner from "components/atoms/Banner";
import ModalCreate from "./create";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchGroup,
  fetchCriteria,
  setKeyword,
  setLimit,
  setPage,
} from "features/CustomerGroup/actions";

export default function PageCustomerGroup() {
  const dispatch = useDispatch();
  const [modalCreate, setModalCreate] = React.useState(false);
  const [isShowSearch, setIsShowSearch] = React.useState(false);

  const data = useSelector((state) => state.customerGroup);

  // console.log(data);

  useEffect(() => {
    dispatch(fetchGroup());
    dispatch(fetchCriteria());
  }, [dispatch, data.limit, data.page, data.keyword]);

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {data.notif?.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={data.notif.message}
          status={data.notif.status}
          type={data.notif.type}
        />
      )}
      <div className="flex items-center justify-between mt-4">
        {isShowSearch ? (
          <SearchBar
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            className="mr-4"
            onClick={() => setIsShowSearch(!isShowSearch)}
          />
        ) : (
          <div className="flex items-center">
            <Button
              className="mr-2"
              variant="outlined"
              color="gray"
              content="icon infront"
              iconName="UilSearch"
              onClick={() => setIsShowSearch(!isShowSearch)}
            >
              Cari kelompok
            </Button>
          </div>
        )}
        <div className="flex items-center">
          <Button
            className="whitespace-nowrap"
            variant="primary"
            color="blue"
            onClick={() => setModalCreate(true)}
          >
            Tambah kelompok baru
          </Button>
        </div>
      </div>
      {/* table */}
      <Table data={data} />
      {/* Pagination */}
      {data.data.length > 0 ? (
        <div className="mt-5">
          <Pagination
            items={data.totalPage}
            currentPage={data.page}
            limit={data.limit}
            onPageChange={(page) => dispatch(setPage(page.selected + 1))}
            onChangePerPage={(value) => dispatch(setLimit(value))}
            label="group per halaman"
          />
        </div>
      ) : (
        ""
      )}
      {/* modal */}
      <ModalCreate show={modalCreate} close={() => setModalCreate(false)} />
    </div>
  );
}
