import {
  START_FETCHING_REPORT_TREND_DAY,
  SUCCESS_FETCHING_REPORT_TREND_DAY,
  ERROR_FETCHING_REPORT_TREND_DAY,
  SUCCESS_FETCHING_REPORT_TREND_WEEK,
  SUCCESS_FETCHING_REPORT_TREND_YEAR,
  SET_LOCATION,
  SET_DATE
} from "./constants";
import moment from 'moment'

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  graphicDay: {},
  totalDayNow: 0,
  totalDayOld: 0,
  graphicWeek: {},
  totalWeekNow: 0,
  totalWeekOld: 0,
  graphicYear: {},
  totalYearNow: 0,
  totalYearOld: 0,
  week: {},
  year: {},
  compare: new Date(moment().add(-1, "y").format()),
  status: statuslist.idle,
  date: {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  },
  location: '',
  outlets: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_REPORT_TREND_DAY:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_REPORT_TREND_DAY:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_REPORT_TREND_DAY:
      return {
        ...state,
        status: statuslist.success,
        graphicDay: action.graphicDay,
        totalDayNow: action.totalDayNow,
        totalDayOld: action.totalDayOld,
        graphicWeek: action.graphicWeek,
        totalWeekNow: action.totalWeekNow,
        totalWeekOld: action.totalWeekOld,
        graphicYear: action.graphicYear,
        totalYearNow: action.totalYearNow,
        totalYearOld: action.totalYearOld,
        outlets: action.location
      };
    case SUCCESS_FETCHING_REPORT_TREND_WEEK:
      return {
        ...state,
        status: statuslist.success,
        week: action.week,
        outlets: action.location
      };
    case SUCCESS_FETCHING_REPORT_TREND_YEAR:
      return {
        ...state,
        status: statuslist.success,
        year: action.year,
        outlets: action.location
      };

    case SET_LOCATION:
      return { ...state, location: action.location }

    case SET_DATE:
      return { ...state, compare: action.date }
    default:
      return state;
  }
}
