import * as React from "react";
import SearcBar from "components/atoms/SearchBar";

export default function SearchBars() {
  const [value, setValue] = React.useState("");
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="mr-3">
        <SearcBar
          value={value}
          name={value}
          onChange={(e) => setValue(e.target.value)}
          onClick={() => setValue("")}
        />
      </div>
      <div className="mt-5 mr-3">
        <SearcBar disabled />
      </div>
    </div>
  );
}
