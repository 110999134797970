import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import SelectBox from "components/atoms/SelectBox";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import { getData } from "utils/fetchData";

export default function LihatPelanggal({ show, close, transaction }) {
  const [value, setValue] = React.useState({
    label: "Restoran nomor 1",
    value: "",
  });
  const text = [
    {
      label: "name 1",
      value: "value 2",
    },
    {
      label: "name 2",
      value: "value 2",
    },
  ];
  const onChange = (value) => {
    console.log(value);
  };
  const [list, setList] = React.useState([
    {
      id: 1,
      name: "Aksi lainnya",
    },
    {
      id: 2,
      name: "Aksi lainnya",
    },
  ]);

  const onClick = (data) => {
    let check = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === data.id) {
        check.push({
          ...list[i],
          isChecked: data.isChecked
            ? (data.isChecked = false)
            : (data.isChecked = true),
        });
      } else {
        check.push(list[i]);
      }
    }

    setList(check);
  };

  const [customer, setCustomer] = React.useState({});

  React.useEffect(() => {
    const getOneTransaction = async () => {
      try {
        const res = await getData(`sales/${transaction}`);

        if (res.data.code === 200) {
          setCustomer(res.data.sales.customer);
        }
      } catch (err) {
        console.log("err.response");
        console.log(err.response);
      }
    };

    getOneTransaction();
  }, [transaction]);

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title={<h1 className="text-gray-02 heading-3">Pelanggan</h1>}
      textCenter={
        <p className="absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 heading-3 text-blue-03">
          {customer?.first_name ?? '-'} {customer?.last_name ?? '-'}
        </p>
      }
      anotherAction={
        <Button className="ml-4" variant="primary" color="blue" type="submit">
          Selesai tambah pelanggan baru
        </Button>
      }
    >
      <div
        className={`bg-white border-t p-5 h-full overflow-scroll flex justify-center`}
      >
        <div className="w-728px max-w-728px create-customer">
          <h1 className="heading-3 text-gray-02">Informasi pelanggan</h1>
          {/* form example don't delete */}
          <ul className="mt-4 create-customer">
            <li className="flex w-full border-b border-gray-04 py-2">
              <InputTextLargeWithLabel
                className="w-full"
                label="Nama depan pelanggan"
                status="default"
                value={customer?.first_name ?? '-'}
                disabled
              />
            </li>
            <li className="flex w-full border-b border-gray-04 py-2">
              <InputTextLargeWithLabel
                className="w-full"
                label="Nama belakang pelanggan"
                status="default"
                value={customer?.last_name ?? '-'}
                disabled
              />
            </li>
            {/* <li className="flex w-full border-b border-gray-04 py-2">
              <SelectBox
                className="w-full"
                label="Kelompok"
                type="text"
                value={value}
                options={text}
                onChange={onChange}
              />
            </li> */}
            <li className="flex w-full border-b border-gray-04 py-2">
              {/* <div className="w-9/12"> */}
              {/* <SelectBox
                  label="Nomor telepon"
                  className="w-full"
                  type="text"
                  value={value}
                  options={text}
                  onChange={onChange}
                /> */}
              {/* Nomor telepon
              </div> */}
              {/* <div className="flex items-center justify-between w-full">
                <input
                  type="number"
                  className="w-full text-center focus:outline-none border px-2 py-2 rounded-lg"
                  value={customer.phone}
                  disabled
                />
              </div> */}
              <InputTextLargeWithLabel
                className="w-full"
                label="Nomor telpon"
                status="default"
                value={customer?.phone ?? '-'}
                disabled
              />
            </li>
            <li className="flex w-full border-b border-gray-04 py-2">
              <InputTextLargeWithLabel
                className="w-full"
                label="Email"
                status="default"
                value={customer?.email ?? '-'}
                disabled
              />
            </li>
          </ul>{" "}
        </div>
      </div>{" "}
    </ModalDialog>
  );
}
