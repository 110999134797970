import React, { useState } from "react";
import ModalDialog from "components/molecules/Modal";

import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import { useDispatch } from "react-redux";

import Button from "components/atoms/Button";
import SelectBox from "components/atoms/SelectBox";
import "./materialCreate.css";
import { getData, postData } from "utils/fetchData";
import { fetchMaterialInStock } from "features/Material/action";

function MaterialCreate({ show, close }) {
  const dispatch = useDispatch();
  const [measure, setMeasure] = useState([]);
  const [field, setField] = useState({
    name: "",
    unitOfMeasureId: 0,
    stocks: [],
  });
  const [selected, setSelected] = React.useState({
    label: "Select item(s)",
    value: "",
  });

  const nameHandler = (e) => {
    setField({ ...field, name: e.target.value });
  };

  const measureHandler = (e) => {
    setField({ ...field, unitOfMeasureId: e.value });
    setSelected({
      label: e.label,
      value: e.value,
    });
  };

  const createMaterial = async () => {
    await postData("v2/rawmaterial", field).then(() => {
      dispatch(fetchMaterialInStock());
      setField({ ...field, name: "" });
      setSelected({
        label: "Select item(s)",
        value: "",
      });
      close();
    });
  };

  const getMeasure = async () => {
    await getData("v2/unitofmeasure").then((res) => {
      let _temp = [];
      res?.data?.units.forEach((item) => {
        _temp.push({
          label: item.name,
          value: item.id,
        });
      });
      setMeasure(_temp);
    });
  };
  React.useEffect(() => {
    getMeasure();
  }, []);
  return (
    <ModalDialog
      overflow="overflow-visible"
      size={"alert"}
      show={show}
      onClose={() => close()}
      title={<p className="text-dark heading-3">Tambah bahan baku</p>}
    >
      {/* form don't remove comment */}
      <form className="bg-gray-50 rounded-br-lg rounded-bl-lg">
        <div className="p-6 material-create">
          <InputTextLargeWithLabel
            name="name"
            value={field.name}
            onChange={nameHandler}
            label="Nama bahan baku"
            status="default"
            className="w-full"
          />
          <SelectBox
            className="mt-1"
            type="text"
            label="Satuan ukur"
            value={selected}
            options={measure}
            name="unitOfMeasureId"
            onChange={measureHandler}
          />
        </div>
        <div className="flex w-full justify-end py-4 bg-white px-6 rounded-br-lg rounded-bl-lg">
          <Button
            disabled={selected.value === "" || field.name.length === 0 ? true : false}
            onClick={createMaterial}
            variant="primary"
            color="blue"
            type="button"
          >
            Selesai tambah bahan baku
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
}

export default MaterialCreate;
