import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import TextCaption from "components/atoms/Text/TextCaption";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Toggle from "components/atoms/Toggle";
import Switch from "components/atoms/Switch";
import "./tax.css";
import Checkbox from "components/atoms/Checkbox";
import { getData, putData } from "utils/fetchData";
import { setNotif, fetchTax } from 'features/Tax/actions'
import { useDispatch } from "react-redux";

const TaxSchema = yup.object().shape({
  name: yup.string().required("nama pajak tidak boleh kosong."),
});

export default function EditTax({ show, close, title, tax }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false)
  const [currency, setCurrency] = React.useState('rp')
  const [field, setField] = React.useState({
    name: '',
    value: '0',
    type: 1,
    status: 0,
    outlets: []
  });

  const { register, handleSubmit, errors, setError } = useForm({
    mode: "onBlur",
    validationSchema: TaxSchema,
  });
  const [type, setType] = React.useState([]);



  React.useEffect(() => {
    const getOneTax = async () => {
      let { data: { outlets } } = await getData("outlets");
      let select_box = []
      outlets.forEach((item) => {
        select_box.push({
          label: item.name,
          value: item.id,
          isChecked: false
        });
      });

      const res = await getData(`tax/${tax}`)

      if (res.data.code === 200) {
        res.data.data.outlets.forEach(data => {
          // console.log("data")
          // console.log(data)
          select_box.forEach(select => {
            if (data.id === select.value) {
              select.isChecked = true
            }
          })
        })

        let checkType = []
        if (res.data.data.type === 1) {
          checkType.push(
            {
              name: "Rp",
              status: true,
            },
            {
              name: "%",
              status: false,
            })
          setCurrency('rp')
        } else {
          checkType.push({
            name: "Rp",
            status: false,
          },
            {
              name: "%",
              status: true,
            })
          setCurrency('%')
        }

        setType(checkType)

        setField({
          ...field,
          name: res.data.data.name,
          value: res.data.data.value,
          type: res.data.data.type,
          status: res.data.data.status,
          outlets: select_box
        })
      }
    }
    getOneTax()
  }, [tax])


  const onClick = (data) => {
    const typeOld = [...type];
    typeOld.forEach((type) => {
      if (type.name === data.name) {
        type.status = true;
      } else {
        type.status = false;
      }
    });
    setType(typeOld);

    let check = data.name === '%' ? '%' : 'rp'
    setCurrency(check)
    setField({ ...field, type: data.name === '%' ? 2 : 1, value: '0' })
  };

  const onCurrencyInput = (value, name) => {
    setField({ ...field, [name]: value })
  }

  const onChange = (e) => {
    let name = e.target.name, value = e.target.value
    if (name === 'status') {
      value = e.target.checked ? 1 : 0
    }
    setField({ ...field, [name]: value })
  }

  const onChecked = (e, index) => {
    let _temp = [...field.outlets];
    _temp[index]['isChecked'] = e.target.checked

    setField({ ...field, outlets: _temp })
  }

  const onSubmit = async () => {
    try {

      let outlets = []
      let form = { ...field }
      field.outlets.forEach(data => {
        if (data.isChecked) {
          outlets.push(data.value)
        }
      })

      setLoading(true)
      form.outlets = outlets
      const res = await putData(`tax/${tax}`, form)

      if (res.data.code === 200) {
        setLoading(false)
        close()
        const notif = {
          message: `${res.data.message}`,
          type: "primary",
          status: "success",
          id: res.data.id,
          toggle: true,
        };
        dispatch(setNotif(notif));
        dispatch(fetchTax());

      }
    } catch (err) {
      const notif = {
        message: `Terjadi kesalah saat mengubah pajak.`,
        type: "primary",
        status: "danger",
        toggle: true,
      };
      console.log(err.response)
      dispatch(setNotif(notif));
      setLoading(false)
    }

  }

  return (
    <ModalDialog
      size={"small"}
      show={show}
      onClose={close}
      title={<h1 className="heading-3 text-dark">{title}</h1>}
    >
      {/* content */}
      <div className={`bg-gray-05 p-5 h-full overflow-scroll scroll-hidden`}>
        <div className="tax">
          <InputTextLargeWithLabel
            className="w-full input-text"
            label="Nama pajak"
            status={errors?.name ? "error" : "default"}
            register={register}
            name="name"
            onChange={onChange}
            value={field.name}
            placeholder=""
          >
            {errors.name && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.name?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
          <div className="relative input-text">
            <InputTextLargeWithLabel
              onChange={(e) => onCurrencyInput(e, 'value')}
              currency={currency}
              placeholder={currency === '%' ? 'Cth: 10 %' : 'Cth: Rp. 10,000'}
              spacing="w-36"
              className="w-full mt-1"
              label="Jumlah diskon"
              status={errors?.value ? "error" : "default"}
              register={register}
              name="name"
              value={field.value}
            >
              {errors.value && (
                <div className="flex items-center mt-1">
                  <TextCaption status="error" message={errors.value?.message} />
                </div>
              )}
            </InputTextLargeWithLabel>
            <Switch
              className="absolute top-1/2 transform -translate-y-1/2 right-5"
              type="primary"
              buttons={type}
              content="label"
              onClick={onClick}
            />
          </div>
          <div className="rounded-lg border-gray-04 bold-text-2 flex justify-between relative bg-white border py-4 px-6 text-gray-02 mt-6">
            <p>Status pajak</p>
            <div className="flex items-center relative">
              <p className="pr-14">{field.status === 0 ? 'Sedang tidak aktif' : 'Sedang aktif'}</p>
              <div className="absolute right-10 -top-1">
                <Toggle
                  value={field.status === 1 ? true : false}
                  name="status"
                  type="primary"
                  size="lg"
                  checked={field.status === 1 ? true : false}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          {/* wrap lokasi scrol */}
          <p className="bold-text-1 mt-6 text-gray-02">
            lokasi yang dikenakan pajak
          </p>
          <ul
            className="scroll-hidden mt-2 bg-white border rounded-lg border-gray-04 pb-4 overflow-y-scroll"
            style={{ height: "30vh" }}
          >
            {field?.outlets.map((data, index) => {
              return (
                <li className="px-6 pt-4" key={index}>
                  <Checkbox
                    checked={data.isChecked}
                    label={data.label}
                    value={data.isChecked}
                    onChange={(e) => onChecked(e, index)}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {/* footer */}
      <div className="px-5 flex justify-end items-center bg-white">
        <Button

          disabled={field.name === "" ? true : false}
          onClick={() => !loading ? onSubmit() : null}
          variant="primary"
          loading={loading}
          color="blue"
          className="my-5"
          type="submit"
        >
          Selesai edit pajak
        </Button>
      </div>
    </ModalDialog>
  );
}
