import {
  START_FETCHING_CATEGORY,
  ERROR_FETCHING_CATEGORY,
  SUCCESS_FETCHING_CATEGORY,
  SET_KEYWORD,
  SET_NOTIF,
  SUCCESS_FETCHING_CHECKBOX_CATEGORY
} from "./constants";
import { userLogout } from 'features/Auth/actions'

import { getData } from 'utils/fetchData'
import debounce from "debounce-promise";

let debouncedFetchCategory = debounce(getData, 1000);

export const startFetchingCategory = () => {
  return {
    type: START_FETCHING_CATEGORY,
  };
};

export const errorFetchingCategory = () => {
  return {
    type: ERROR_FETCHING_CATEGORY,
  };
};

export const successFetchingCategory = ({ category }) => {
  return {
    type: SUCCESS_FETCHING_CATEGORY,
    category
  };
};

export const fetchCategory = () => {
  return async (dispatch, getState) => {
    try {

      const notif = {
        message: '',
        type: '',
        status: '',
        toggle: false
      }

      setTimeout(() => {
        dispatch(setNotif(notif))
      }, 6000)

      let keyword = getState().category.keyword || '';
      dispatch(startFetchingCategory());
      const params = { name: keyword }
      const res = await debouncedFetchCategory('categories', params);

      if (res.data?.code === 401) {
        dispatch(userLogout())
      }

      /** convert data dari api sesuai kebutuhan table */
      let category = []
      res.data.categories.forEach(data => {
        category.push({
          id: data.id,
          name: data.name,
          productCount: `${data.productCount} produk`
        })
      });
      dispatch(successFetchingCategory({ category }));

    } catch (err) {
      dispatch(errorFetchingCategory());
    }
  };
};

export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

/** fetch data category */
export const fetchCheckboxCategory = () => {
  return async (dispatch) => {
    try {

      const res = await debouncedFetchCategory('categories');

      if (res.data?.code === 401) {
        dispatch(userLogout())
      }

      /** convert data dari api sesuai kebutuhan table */
      let category = []
      res.data.categories.forEach(data => {
        category.push({
          value: data.id,
          label: data.name,
        })
      });
      dispatch(successFetchingCheckboxCategory({ category }));

    } catch (err) {
      dispatch(errorFetchingCategory());
    }
  };
};


export const successFetchingCheckboxCategory = ({ category }) => {
  return {
    type: SUCCESS_FETCHING_CHECKBOX_CATEGORY,
    category
  };
};

