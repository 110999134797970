import {
  START_FETCHING_CUSTOMER,
  ERROR_FETCHING_CUSTOMER,
  SUCCESS_FETCHING_CUSTOMER,
  SET_CUSTOMER,
  SET_GROUP,
  SET_KEYWORD,
  SET_CRITERIA,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_NOTIF,
  SET_CHECKBOX,
  SET_CUSTOMER_SELECTED,
} from "./constants";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchCustomers = debounce(getData, 1000);

export const startFetchingCustomers = () => {
  return {
    type: START_FETCHING_CUSTOMER,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

export const successFetchingCustomers = ({ customers }) => {
  return {
    type: SUCCESS_FETCHING_CUSTOMER,
    customers,
  };
};
export const setCheckboxData = (checkbox_data) => {
  return {
    type: SET_CHECKBOX,
    checkbox_data,
  };
};
export const setCustomerSelected = (selected) => {
  return {
    type: SET_CUSTOMER_SELECTED,
    selected,
  };
};

export const errorFetchingCustomers = () => {
  return {
    type: ERROR_FETCHING_CUSTOMER,
  };
};

export const fetchCustomer = () => {
  return async (dispatch, getState) => {
    const notif = {
      id: 0,
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      dispatch(setNotif(notif));
    }, 5000);

    dispatch(startFetchingCustomers());
    let keyword = getState().customer.keyword || "";
    let page = getState().customer.page || "";
    let limit = getState().customer.limit || "";
    let groupId = getState().customer.group || "";
    const params = {
      keyword,
      page,
      limit,
    };
    if (groupId !== "") params.groupId = groupId;
    try {
      let {
        data: { customers, pages },
      } = await debouncedFetchCustomers("v2/customer", params);
      let totalPage = pages;
      dispatch(successFetchingCustomers({ customers }));
      dispatch(setTotalPage(totalPage));
    } catch (error) {
      dispatch(errorFetchingCustomers());
    }
  };
};

export const fetchCustomerInGroup = () => {
  return async (dispatch) => {
    let limit = 100;
    const params = {
      limit,
    };
    let {
      data: { customers },
    } = await getData("v2/customer", params);
    let checkbox_data = [];
    customers.forEach((item) => {
      checkbox_data.push({
        id: item.id,
        name: `${item.first_name} ${item.last_name}`,
        isChecked: false,
      });
    });
    dispatch(setCheckboxData(checkbox_data));
  };
};

export const setLimit = (limit) => {
  return {
    type: SET_LIMIT,
    limit,
  };
};
export const setPage = (page = 1) => {
  return {
    type: SET_PAGE,
    page,
  };
};
export const setTotalPage = (totalPage) => {
  return {
    type: SET_TOTAL_PAGE,
    totalPage,
  };
};

export const setCustomer = (customer) => {
  return {
    type: SET_CUSTOMER,
    customer,
  };
};
export const setGroup = (group) => {
  return {
    type: SET_GROUP,
    group,
  };
};

export const setCriteria = (criteria) => {
  return {
    type: SET_CRITERIA,
    criteria,
  };
};
export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};
