import React from "react";
import propTypes from "prop-types";
import Button from "components/atoms/Button";

function TemplateEmpty(props) {
  const {
    image,
    title,
    text,
    onClick,
    label,
    importButton,
    labelImportButton,
    onClickImport
  } = props;
  return (
    <div className="h-full">
      <div className="flex items-center justify-between px-24 bg-gray-05 h-78vh overflow-y-scroll">
        <div>
          <h1 className="heading-1">{title}</h1>
          <p className="text-2 mt-2">{text}</p>
          <div className="mt-12">
            {label && <Button variant="primary" color="blue" onClick={onClick}>
              {label}
            </Button>}

            {importButton && (
              <Button variant="outlined" className="ml-2" color="blue" onClick={onClickImport}>
                {labelImportButton}
              </Button>
            )}
          </div>
        </div>
        <img src={image} alt="image empty" />
      </div>
    </div>
  );
}

TemplateEmpty.propTypes = {
  title: propTypes.string,
  text: propTypes.string,
  importButton: propTypes.bool,
};

export default TemplateEmpty;
