import React from "react";

export default function ArrowLeft({ width, fill, height, className }) {
  return (
    <svg
      className={className}
      width={"14"}
      height={"14"}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.08337 6.66699L5.83337 11.3337C6.16671 11.667 6.66671 11.667 7.00004 11.3337C7.33337 11.0003 7.33337 10.5003 7.00004 10.167L2.91671 6.00033L7.00004 1.83366C7.33337 1.50033 7.33337 1.00033 7.00004 0.666992C6.83337 0.500325 6.66671 0.416992 6.41671 0.416992C6.16671 0.416992 6.00004 0.500325 5.83337 0.666992L1.08337 5.33366C0.750041 5.75033 0.750041 6.25033 1.08337 6.66699C1.08337 6.58366 1.08337 6.58366 1.08337 6.66699Z"
        fill={fill}
      />
    </svg>
  );
}

ArrowLeft.defaultProps = {
  width: "17",
  height: "17",
  fill: "#8F8F8F",
};
