import React from "react";
import Logo from "components/atoms/Logo";

export default function BrandLogo() {
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="grid grid-cols-3">
        <div className="grid-cols-1 bg-blue-05 p-7">
          <div className="flex items-center w-full">
            <Logo
              type="dalenta"
              content="icon"
              variant="blue"
              className="mr-5"
            />
            <Logo type="dalenta" content="icon wordmark" variant="blue" />
          </div>
          <div className="flex items-center w-full">
            <Logo
              type="sello"
              content="icon"
              variant="blue"
              className="mr-5 mt-5"
            />
            <Logo
              type="sello"
              content="icon wordmark"
              variant="blue"
              className="mt-5"
            />
          </div>
          <div className="flex items-center w-full">
            <Logo
              type="visuo"
              content="icon"
              variant="blue"
              className="mr-5 mt-5"
            />
            <Logo
              type="visuo"
              content="icon wordmark"
              variant="blue"
              className="mt-5"
            />
          </div>
        </div>
        <div className="grid-cols-1 bg-gray-05 p-7">
          <div className="flex items-center w-full">
            <Logo
              type="dalenta"
              content="icon"
              variant="gray"
              className="mr-5"
            />
            <Logo type="dalenta" content="icon wordmark" variant="gray" />
          </div>
          <div className="flex items-center w-full">
            <Logo
              type="sello"
              content="icon"
              variant="gray"
              className="mr-5 mt-5"
            />
            <Logo
              type="sello"
              content="icon wordmark"
              variant="gray"
              className="mt-5"
            />
          </div>
          <div className="flex items-center w-full">
            <Logo
              type="visuo"
              content="icon"
              variant="gray"
              className="mr-5 mt-5"
            />
            <Logo
              type="visuo"
              content="icon wordmark"
              variant="gray"
              className="mt-5"
            />
          </div>
        </div>
        <div className="grid-cols-1 bg-blue-03 p-7">
          <div className="flex items-center w-full">
            <Logo
              type="dalenta"
              content="icon"
              variant="white"
              className="mr-5"
            />
            <Logo type="dalenta" content="icon wordmark" variant="white" />
          </div>
          <div className="flex items-center w-full">
            <Logo
              type="sello"
              content="icon"
              variant="white"
              className="mr-5 mt-5"
            />
            <Logo
              type="sello"
              content="icon wordmark"
              variant="white"
              className="mt-5"
            />
          </div>
          <div className="flex items-center w-full">
            <Logo
              type="visuo"
              content="icon"
              variant="white"
              className="mr-5 mt-5"
            />
            <Logo
              type="visuo"
              content="icon wordmark"
              variant="white"
              className="mt-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="flex flex-col bg-blue-05 px-5 py-2">
      <div className="flex items-center w-full">
        <LogoDalenta className={"mr-5"} />
        <LogoDalenta label={"DALENTA"} />
      </div>
      <div className="flex items-center w-full mt-5">
        <LogoSello className={"mr-5"} />
        <LogoSello label={"Sello"} />
      </div>
      <div className="flex items-center w-full mt-5">
        <LogoVisio className={"mr-5"} />
        <LogoVisio label={"Visio"} />
      </div>
    </div> */
}
