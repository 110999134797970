import * as React from "react";
import Button from "components/atoms/Button";
import Banner from "components/atoms/Banner";
import TextCaption from "components/atoms/Text/TextCaption";
import IconSello from "assets/icon/LogoSello.svg";
import { useHistory } from "react-router-dom";
import {
  InputTextSmall,
  InputGroupSmall,
} from "components/atoms/Form";
import { postData } from "utils/fetchData";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { userLogin } from "features/Auth/actions";

const SigninSchema = yup.object().shape({
  password: yup.string().required("masukan kata sandi anda."),
  username: yup
    .string()
    .required("email tidak boleh kosong.")
    .email("email yang anda masukan tidak valid."),
  website: yup.string().url(),
});

function SignIn() {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { register, handleSubmit, errors, setError } = useForm({
    mode: "onBlur",
    validationSchema: SigninSchema,
  });

  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [field, setField] = React.useState({
    username: "",
    password: "",
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await postData("accounts/signin", data);

    if (res.data.code === 403 || res.data.code === 226) {
      setError("server", "server", res.data.message);
      setLoading(false);
    } else {
      const tour = res.data.account.tour;
      const token = res.data.account.token;
      const access = res.data.account.access;
      const email = res.data.account.email;
      const role = res.data.account.role;
      const username = res.data.account.username;
      const created_at = new Date();
      dispatch(
        userLogin(
          tour,
          token,
          access,
          username,
          email,
          created_at,
          role
        )
      );
      setLoading(false);

      if (res.data.account.status === 2) {
        // history.push("/first-signin");
        window.location.href = "/first-signin";
      } else {
        // history.push("/dashboard");
        window.location.href = "/dashboard";
      }
    }
  };

  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };
  return (
    <div className="flex justify-center bg-gray-05 h-screen">
      <div className="rounded-xl bg-white px-10 self-center h-2/3 shadow-gray flex justify-center">
        <form
          className="self-center px-2"
          onSubmit={!loading ? handleSubmit(onSubmit) : null}
        >
          <div className="flex justify-center mb-10">
            <img src={IconSello} alt={IconSello} />
            {/* <p className="text-xl text-gray-200">logo</p>
            <p className="text-2xl font-semibold text-gray-400">Sello</p> */}
          </div>

          {errors.server && (
            <Banner
              className="my-2"
              status="warning"
              type="primary"
              message={errors.server?.message}
            />
          )}

          <InputTextSmall
            register={register}
            className={"focus:border-blue-03 w-full mb-2"}
            type="text"
            name="username"
            placeholder="Email"
            value={field.username}
            onChange={onChange}
            status={errors?.username ? "error" : "default"}
          />
          {errors.username && (
            <TextCaption
              status="error"
              message={errors.username?.message}
            />
          )}

          <InputGroupSmall
            register={register}
            className={"focus:border-blue-03 w-full mb-2 mt-2"}
            name="password"
            status={errors?.password ? "error" : "default"}
            value={field.password}
            onChange={onChange}
            content="icon"
            placeholder="Kata sandi"
            type={showPassword ? "text" : "password"}
            onClick={() => setShowPassword(!showPassword)}
            iconName={showPassword ? "UilEyeSlash" : "UilEye"}
          />
          {errors.password && (
            <TextCaption
              status="error"
              message={errors.password?.message}
            />
          )}

          <Button
            variant="link"
            color="blue"
            type="link"
            href="/forgot"
            className="float-right mb-2"
          >
            Lupa kata sandi?
          </Button>

          <Button
            type="submit"
            variant="primary"
            color="blue"
            loading={loading ? true : false}
            disabled={loading ? true : false}
            isBlock
          >
            Log in
          </Button>

          <div className="flex justify-center mt-3">
            <p className="text-sm">
              Belum punya akun?
              <Button
                variant="link"
                color="blue"
                type="submit"
                onClick={() =>
                  (window.location.href =
                    "https://www.dalenta.com/sello")
                }
              >
                Demo gratis sekarang
              </Button>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignIn;
