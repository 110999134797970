import * as React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { list } from "./Menu";
import { Verification } from "components/atoms/Icons/Custom/Verification";
import Button from "components/atoms/Button";
import { getData } from "utils/fetchData";
import { useSelector } from "react-redux";

function Index({ setHide, position }) {
  const [field, setField] = React.useState({});
  const user = useSelector((state) => state.auth);
  const [menu, setMenu] = React.useState([]);

  const createMenu = () => {
    let _menu = [];
    list.forEach((item) => {
      user.access.forEach((current) => {
        if (item.id === current.id && current.status) {
          _menu.push(item);
        }
      });
    });
    setMenu(_menu);
  };

  React.useEffect(() => {
    createMenu();
  }, [user]);

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await getData(`accounts/brand`);
      setField(res.data.data);
    };

    fetchData();
  }, []);

  return (
    <>
      {position === "" ? (
        ""
      ) : (
        <div onClick={setHide} className="overlay"></div>
      )}
      <div className={`leftbar ${position}`}>
        <div className="leftbar-header">
          <div className="flex pr-5 self-center">
            <Button
              variant="secondary"
              color="gray"
              content="label only"
              onClick={setHide}
            >
              X
            </Button>
          </div>
        </div>

        <ul className="mt-4 h-5/6 overflow-y-scroll px-6">
          <Link
            className={`verification ${
              field?.verification === 0
                ? "bg-red-02 hover:bg-red-800"
                : field?.verification === 1
                ? " bg-green-02 hover:bg-green-03"
                : field?.verification === 2
                ? " bg-red-02 hover:bg-red-800"
                : " bg-orange-02 hover:bg-orange-03"
            }
          `}
            to={field?.verification === 2 && "/verification"}
          >
            <Verification />
            <span className="ml-4 whitespace-nowrap">
              {field?.verification === 0
                ? "Verifikasi ditolak"
                : field?.verification === 1
                ? "Telah Terverifikasi"
                : field?.verification === 2
                ? "Verifikasi identitas"
                : "Verifikasi sedang diproses"}
            </span>
          </Link>
          {menu.map((item, i) => {
            return (
              <Link to={item.link} className="menu" key={i}>
                {item.icon}
                <span className="ml-4">{item.name}</span>
              </Link>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Index;
