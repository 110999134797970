// (1) import constant
import { USER_LOGIN, USER_LOGOUT } from "./constant";

export function userLogin(tour, token, access, username, email, created_at, role) {
  return {
    type: USER_LOGIN,
    tour,
    token,
    access,
    username,
    email,
    created_at,
    role
  }
}

export function userLogout() {
  localStorage.removeItem('auth');
  return {
    type: USER_LOGOUT
  }
}