import * as React from "react";
import Pagination from "components/atoms/Pagination";

export default function Paginate() {
  const [pages] = React.useState(10)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [limit, setLimit] = React.useState(10)

  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <Pagination
        items={pages}
        currentPage={currentPage}
        onPageChange={(page) => setCurrentPage(page.selected + 1)}
        label="label example"
        limit={limit}
        onChangePerPage={(value) => setLimit(value)}
      />
    </div>
  );
}
