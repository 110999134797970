import * as React from "react";
import {
  InputDateRange,
  InputDate,
} from "components/atoms/Form/InputDate/index";

// import {
//   InputDate1,
//   InputDate2,
//   InputDate3,
//   InputDate4,
// } from "components/atoms/Form/InputDate/InputDate";

export default function InputDates() {
  const [date, setDate] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [dateSingle, setDateSingle] = React.useState(new Date())

  const updateData = (e) => {
    setDate(e.selection);
  };

  const onChangeDateCostum = (start, end) => {
    console.log(start, end)
  }
  return (
    <div className="pt-24 pl-10 pb-20 h-screen overflow-scroll scroll-hidden">
      <div className="flex items-center">
        <div className="mx-2">
          <InputDate variant="single" type="default" date={dateSingle} onChange={(date) => setDateSingle(date)} />
        </div>
        <div className="mx-2">
          <InputDateRange variant="range" type="default" onChange={updateData} name="date" value={date} />
        </div>
        <div className="mx-2">
          <InputDate variant="single" type="label" date={dateSingle} onChange={(date) => setDateSingle(date)} />
        </div>
      </div>
      <div className="flex items-center">
        <div className="mx-2 my-2">
          <InputDateRange onChangeDateCostum={(start, end) => onChangeDateCostum(start, end)} variant="range" type="custom right" onChange={updateData} name="date" value={date} />
        </div>
        {/* <InputDateRange2 variant="range" type="default" onChange={updateData} name="date" value={date} /> */}
        {/* <InputDate3 /> */}
        {/* <InputDate4 /> */}
      </div>
    </div>
  );
}
