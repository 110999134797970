import {
  START_FETCHING_GROUP,
  SUCCESS_FETCHING_GROUP,
  ERROR_FETCHING_GROUP,
  SET_KEYWORD,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_NOTIF,
  SET_CRITERIA,
} from "./constants";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchGroup = debounce(getData, 1000);

export const startFetchingGroup = () => {
  return {
    type: START_FETCHING_GROUP,
  };
};

export const successFetchingGroup = ({ customersGroup }) => {
  return {
    type: SUCCESS_FETCHING_GROUP,
    customersGroup,
  };
};

export const errorFetchingGroup = () => {
  return {
    type: ERROR_FETCHING_GROUP,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

export const setCriteria = (criteria) => {
  return {
    type: SET_CRITERIA,
    criteria,
  };
};

export const fetchCriteria = () => {
  return async (dispatch) => {
    try {
      let {
        data: { data },
      } = await getData("customers/criteria");
      dispatch(setCriteria(data));
    } catch (error) {
      console.log(error.response);
    }
  };
};

export const fetchGroup = () => {
  return async (dispatch, getState) => {
    const notif = {
      id: 0,
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      dispatch(setNotif(notif));
    }, 5000);

    dispatch(startFetchingGroup());
    let keyword = getState().customerGroup.keyword || "";
    let page = getState().customerGroup.page || "";
    let limit = getState().customerGroup.limit || "";
    const params = {
      keyword,
      page,
      limit,
    };
    try {
      let {
        data: { customersGroup, pages },
      } = await debouncedFetchGroup("v2/group", params);
      let totalPage = pages;

      dispatch(successFetchingGroup({ customersGroup }));
      dispatch(setTotalPage(totalPage));
    } catch (error) {
      dispatch(errorFetchingGroup());
    }
  };
};

export const setLimit = (limit) => {
  return {
    type: SET_LIMIT,
    limit,
  };
};
export const setPage = (page = 1) => {
  return {
    type: SET_PAGE,
    page,
  };
};
export const setTotalPage = (totalPage) => {
  return {
    type: SET_TOTAL_PAGE,
    totalPage,
  };
};

export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};
