import React from "react";
import "assets/stylesheets/typography.css";

export default function Typography() {
  const styleTD = {
    padding: "15px",
  };
  return (
    <div className="pt-14 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <table className="w-full mt-10">
        <thead>
          <tr>
            <th>Class</th>
            <th>Properties</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={styleTD}>title</td>
            <td style={styleTD}>
              font-weight: 700, font-size: 48px, line-height: 56px,
              letter-spacing: -0.96px,
            </td>
            <td className="title">Title</td>
          </tr>
          <tr>
            <td style={styleTD}>heading-0</td>
            <td style={styleTD}>
              font-size: 40px, line-height: 48px, letter-spacing: -0.8px ,
              font-weight: 600,
            </td>
            <td className="heading-0 whitespace-nowrap">Heading 0</td>
          </tr>
          <tr>
            <td style={styleTD}>heading-1</td>
            <td style={styleTD}>
              font-size: 32px, line-height: 40px, letter-spacing: -0.64px ,
              font-weight: 600,
            </td>
            <td className="heading-1">Heading-1</td>
          </tr>
          <tr>
            <td style={styleTD}>heading-2</td>
            <td style={styleTD}>
              font-size: 32px, line-height: 40px, letter-spacing: -0.56px ,
              font-weight: 600,
            </td>
            <td className="heading-2">Heading-2</td>
          </tr>
          <tr>
            <td style={styleTD}>heading-3</td>
            <td style={styleTD}>
              font-size: 20px, line-height: 28px, letter-spacing: -0.4px ,
              font-weight: 600,
            </td>
            <td className="heading-3">Heading 3</td>
          </tr>
          <tr>
            <td style={styleTD}>bold-text-1</td>
            <td style={styleTD}>
              font-size: 16px, line-height: 24px, letter-spacing: -0.32px ,
              font-weight: 600,
            </td>
            <td className="bold-text-1">Bold Text 1</td>
          </tr>
          <tr>
            <td style={styleTD}>text-1</td>
            <td style={styleTD}>
              font-size: 16px, line-height: 24px, letter-spacing: -0.32px ,
              font-weight: 400,
            </td>
            <td className="text-1">Text 1</td>
          </tr>
          <tr>
            <td style={styleTD}>bold-text-2</td>
            <td style={styleTD}>
              font-size: 14px, line-height: 20px, letter-spacing: -0.28px ,
              font-weight: 600,
            </td>
            <td className="bold-text-2">Bold Text 2</td>
          </tr>
          <tr>
            <td style={styleTD}>Regular - 400</td>
            <td style={styleTD}>
              font-size: 14px, line-height: 20px, letter-spacing: -0.28px ,
              font-weight: 400,
            </td>
            <td className="text-2">Text 2</td>
          </tr>
          <tr>
            <td style={styleTD}>Semibold - 600</td>
            <td style={styleTD}>
              font-size: 12px, line-height: 14px, letter-spacing: -0.24px ,
              font-weight: 600,
            </td>
            <td className="bold-text-3">Bold Text 3</td>
          </tr>
          <tr>
            <td style={styleTD}>Regular - 400</td>
            <td style={styleTD}>
              font-size: 12px, line-height: 14px, letter-spacing: -0.24px ,
              font-weight: 400,
            </td>
            <td className="text-3">Text 3</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
