import {
  START_FETCHING_TRANSACTION,
  SUCCESS_FETCHING_TRANSACTION,
  ERROR_FETCHING_TRANSACTION,
  SET_KEYWORD,
  SET_TRANSACTION,
  SET_DATE,
  SET_PAYMENT_METHOD,
  SET_LOCATION,
  SET_PAGE,
  SET_LIMIT
} from './constants';

import moment from 'moment'

import { getData } from 'utils/fetchData';

export const startFetchingTransactions = () => {
  return {
    type: START_FETCHING_TRANSACTION
  }
}

export const errorFetchingTransactions = () => {
  return {
    type: ERROR_FETCHING_TRANSACTION
  }
}

export const successFetchingTransactions = ({ sales, salesCount, netSales, dataCollected, pages, outlets, salesInfo }) => {
  return {
    type: SUCCESS_FETCHING_TRANSACTION,
    sales,
    salesCount,
    netSales,
    dataCollected,
    pages,
    outlets,
    salesInfo
  }
}

export const fetchTransaction = () => {

  return async (dispatch, getState) => {

    dispatch(startFetchingTransactions());

    let keyword = getState().transaction.keyword || '';
    let location = getState().transaction.location.value || '';
    let payment_method = getState().transaction.payment_method.value || '';
    let transaction = getState().transaction.transaction.value || '';
    let start = moment(getState().transaction.date.startDate).format("YYYY-MM-DD") || '';
    let end = moment(getState().transaction.date.endDate).format("YYYY-MM-DD") || '';
    let page = getState().transaction.page || 1;
    let limit = getState().transaction.limit || 9;


    let params = {
      start,
      end,
      page,
      limit
    }

    if (payment_method !== "") {
      params = {
        ...params,
        method: Number(payment_method),
      }
    }

    if (keyword !== "") {
      params = {
        ...params,
        keyword
      }
    }
    if (location !== "") {
      params = {
        ...params,
        location
      }
    }
    if (transaction !== "") {
      params = {
        ...params,
        transaction
      }
    }

    console.log(params)

    try {

      let { data: { outlets }, } = await getData("outlets");

      let select_box = [{ label: "Semua lokasi", value: "", }]
      outlets.forEach((item) => {
        select_box.push({
          label: item.name,
          value: item.id,
        });
      });

      let { data: { sales, total, pages, salesInfo } } = await getData('v2/sales', params);

      console.log("sales >>")
      console.log(sales)

      let value = 0;
      let service = 0;
      let refund = 0;

      await Object.keys(sales).map((key) => {
        sales[key].map((item) => {
          service += item.service
          item.status == 'COMPLETED' ? value += item.value : refund += item.value
        })
      })

      let netSales = value + refund - service;
      let dataCollected = value // + service;

      dispatch(successFetchingTransactions({
        sales, salesCount: total, netSales, dataCollected, pages,
        outlets: select_box,
        salesInfo
      }));
    } catch (err) {
      dispatch(errorFetchingTransactions());
    }

  }
}

export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location
  }
}

export const setKeyword = keyword => {
  return {
    type: SET_KEYWORD,
    keyword
  }
}

export const setPayment = payment => {
  return {
    type: SET_PAYMENT_METHOD,
    payment
  }
}
export const setTransaction = transaction => {
  return {
    type: SET_TRANSACTION,
    transaction
  }
}

export const setDate = date => {
  return {
    type: SET_DATE,
    date: date.selection ? date.selection : date
  }
}

export const setPage = (number = 1) => {
  return {
    type: SET_PAGE,
    page: number
  }
}


export const setPerPage = (limit) => {
  return {
    type: SET_LIMIT,
    limit
  }
}
