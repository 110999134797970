import React from 'react'
import InputText from '../InputTextSmall'
import propTypes from 'prop-types'

function InputTextWithLabel(props) {
  const { label, disabled, value, type, placeholder, name, onChange, size, status } = props
  return (
    <div className={`relative flex flex-col text-gray-02 ${status === 'default' ? 'focus-within:text-blue-03' : ''} `}>
      <label className="font-semibold text-xs currentColor duration-500 mb-1">
        {label}
      </label>
      <InputText
        disabled={disabled}
        value={value}
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        size={size}
        status={status}
      />
    </div>
  )
}

InputTextWithLabel.propTypes = {
  label: propTypes.string.isRequired
}

export default InputTextWithLabel