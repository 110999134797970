import { Radar, HorizontalBar, Line, Bar } from "react-chartjs-2";

import { formatRupiah } from "utils/formatRupiah";

export const graphicRadar = "radar";
export const graphicBar = "bar";
export const graphicHorizontalBar = "horizontalBar";
export const graphicLine = "line";

export const typesGraphic = {
  [graphicRadar]: Radar,
  [graphicBar]: Bar,
  [graphicHorizontalBar]: HorizontalBar,
  [graphicLine]: Line,
};

const GRAPHIC_IDS = {
  LINE: 1,
  BAR: 2,
};

export const tooltipsGraphic = (graphicId, multi, dateOld, dateNow) => {
  if (graphicId === GRAPHIC_IDS.LINE) {
    if (multi === true) {
      return {
        title: (tooltipItem, data) => {
          return "";
        },
        label: (tooltipItem, data) => {
          return `${
            data?.datasets[tooltipItem.datasetIndex]?.label[tooltipItem.index]
              ?.date
              ? data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index]
                  .date
              : data.datasets[tooltipItem.datasetIndex].label
          } : ${formatRupiah(tooltipItem.yLabel)}`;
        },
      };
    } else {
      return {
        title: () => {
          return "Penjualan bersih";
        },
        label: (tooltipItem, data) => {
          return formatRupiah(tooltipItem.yLabel);
        },
        afterLabel: (tooltipItem, data) => {
          const count = data.datasets.map((dataSet) =>
            parseInt(dataSet.count[tooltipItem.index], 10)
          );

          return `______________________ \n Jumlah ransaksi \n ${count}`;
        },
      };
    }
  } else {
    return [];
  }
};

export const graphicOptionsLine = (graphicId, multi, dateOld, dateNow) => {
  const optionGraphic = {
    tooltips: {
      mode: "x",
      intersect: false,
      callbacks: tooltipsGraphic(graphicId, multi, dateOld, dateNow),
    },
    legend: {
      display: false,
    },
    bezierCurve: false,
    responsive: false,
    hover: {
      mode: "x",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return formatRupiah(value);
            },
          },
        },
      ],
    },
  };
  return optionGraphic;
};

export function optionsForGraphic(
  type,
  graphicId,
  currency,
  multi,
  dateOld,
  dateNow
) {
  switch (type) {
    case graphicLine: {
      return graphicOptionsLine(graphicId, multi, dateOld, dateNow);
    }
    default: {
      return {};
    }
  }
}
