import React from 'react'

export default function Reservation({width,fill,height,className}) {
    return (
            <svg className={className} width={width} height={height} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3 2C1.34315 2 0 3.34315 0 5V19C0 20.6569 1.34315 22 3 22H17C18.6569 22 20 20.6569 20 19V5C20 3.34315 18.6569 2 17 2H3ZM3 4C2.44772 4 2 4.44772 2 5V19C2 19.5523 2.44772 20 3 20H17C17.5523 20 18 19.5523 18 19V5C18 4.44772 17.5523 4 17 4H3Z" fill={fill}/>
<path d="M4 1C4 0.447715 4.44772 0 5 0C5.55228 0 6 0.447715 6 1V5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5V1Z" fill={fill}/>
<path d="M14 1C14 0.447715 14.4477 0 15 0C15.5523 0 16 0.447715 16 1V5C16 5.55228 15.5523 6 15 6C14.4477 6 14 5.55228 14 5V1Z" fill={fill}/>
<path d="M8.42999 14.09L12.72 9.79C12.9074 9.60375 13.1608 9.49921 13.425 9.49921C13.6892 9.49921 13.9426 9.60375 14.13 9.79C14.2237 9.88296 14.2981 9.99356 14.3489 10.1154C14.3997 10.2373 14.4258 10.368 14.4258 10.5C14.4258 10.632 14.3997 10.7627 14.3489 10.8846C14.2981 11.0064 14.2237 11.117 14.13 11.21L9.12999 16.21C8.94373 16.3947 8.69233 16.4989 8.42999 16.5C8.29838 16.5008 8.16792 16.4755 8.04608 16.4258C7.92425 16.376 7.81343 16.3027 7.71999 16.21L5.36999 13.85C5.26531 13.7604 5.18029 13.65 5.12027 13.526C5.06024 13.4019 5.02651 13.2668 5.02119 13.1291C5.01587 12.9913 5.03908 12.854 5.08935 12.7257C5.13963 12.5974 5.21589 12.4808 5.31334 12.3833C5.41079 12.2859 5.52734 12.2096 5.65567 12.1594C5.78399 12.1091 5.92132 12.0859 6.05904 12.0912C6.19676 12.0965 6.3319 12.1302 6.45596 12.1903C6.58003 12.2503 6.69034 12.3353 6.77999 12.44L8.42999 14.09Z" fill={fill}/>
</svg>


    )
}

Reservation.defaultProps ={
    width:'20px',
    height:'22px',
    fill:'#8F8F8F'
}
