import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";

import { fetchBank, setNotif } from "features/Bank/actions";
import { deleteData } from "utils/fetchData";
import { useDispatch } from "react-redux";

function DeleteBank({ show, close, id }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const onDelete = async () => {
    setLoading(true);
    await deleteData(`balance/bank/${id}`)
      .then((res) => {
        if (res.data.code === 200) {
          let notif = {
            message: "Rekening bank berhasil dihapus",
            type: "primary",
            status: "danger",
            toggle: true,
          };
          dispatch(setNotif(notif));
          dispatch(fetchBank());
          setLoading(false);
          close();
        }
      })
      .catch(() => {
        alert("error server");
        setLoading(false);
      });
  };
  return (
    <ModalDialog size={"alert"} show={show} onClose={close} title="Hapus">
      <div className={`bg-white p-5 h-full overflow-scroll`}>
        <p>
          {`Apakah Anda yakin ingin menghapus “rekening bank” ? Aksi ini tidak bisa dibatalkan lagi jika telah berhasil dihapus.`}
        </p>
      </div>
      <div className="px-5 flex justify-between items-center bg-white">
        <Button
          onClick={() => close()}
          variant="primary"
          color="gray"
          className="my-4 mr-4"
          type="button"
        >
          Batal hapus rekening
        </Button>
        <Button
          loading={loading}
          onClick={onDelete}
          variant="primary"
          color="red"
          className="my-4"
          type="button"
        >
          Hapus rekening
        </Button>
      </div>
    </ModalDialog>
  );
}

export default DeleteBank;
