import {
  START_FETCHING_REPORT_DISCOUNT,
  SUCCESS_FETCHING_REPORT_DISCOUNT,
  ERROR_FETCHING_REPORT_DISCOUNT,
  SET_LOCATION,
  SET_DATE
} from "./constants";
import { userLogout } from 'features/Auth/actions'
import moment from 'moment'

import { getDatav2 } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchReport = debounce(getDatav2, 1000);

export const startFetchingReportDiscount = () => {
  return {
    type: START_FETCHING_REPORT_DISCOUNT,
  };
};

export const errorFetchingReportDiscount = () => {
  return {
    type: ERROR_FETCHING_REPORT_DISCOUNT,
  };
};

export const successFetchingReportDiscount = ({ chart, table, location }) => {
  return {
    type: SUCCESS_FETCHING_REPORT_DISCOUNT,
    chart,
    table,
    location
  };
};

export const fetchReportDiscount = () => {
  return async (dispatch, getState) => {

    dispatch(startFetchingReportDiscount());

    try {

      let start = moment(getState().reportDiscount.date.startDate).format("YYYY-MM-DD") || ''
      let end = moment(getState().reportDiscount.date.endDate).format("YYYY-MM-DD") || '';
      let location = getState().reportDiscount.location.value || '';

      const params = {
        start,
        end,
        location
      }

      let { data: { outlets }, } = await debouncedFetchReport("outlets");

      let select_box = [{ label: "Semua lokasi", value: "", }]
      outlets.forEach((item) => {
        select_box.push({
          label: item.name,
          value: item.id,
        });
      });

      const res = await debouncedFetchReport('v2/report/discount', params)

      const data = [], label = [];
      res.data.chart.forEach(chart => {
        data.push(chart.value)
        label.push(chart.name)

      })

      dispatch(successFetchingReportDiscount({
        chart: {
          data,
          label
        },
        table: res.data.table,
        location: select_box
      }))

    } catch (err) {
      dispatch(errorFetchingReportDiscount());
    }
  };
};

export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};

export const setDate = date => {
  console.log("date")
  console.log(date)
  return {
    type: SET_DATE,
    date: date.selection ? date.selection : date
  }
}