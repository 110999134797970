import React from "react";
import ModalDialog from "components/molecules/Modal";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import Button from "components/atoms/Button";
import SelectBox from "components/molecules/SelectBox/Select";
import Toggle from "components/atoms/Toggle";
import ModalStock from "./stock";
import ModalTrack from "./tracking";
import ModalHistory from "./history";
import ModalMeasure from "pages/Inventory/Measure/create";
import { useSelector, useDispatch } from "react-redux";
import {
  setName,
  setUnit,
  updateMaterial,
  setModal,
  setModalSecondary,
  setMeasureName,
  setMeasureUnit,
} from "features/Material/action";
import { setModal as setModalMeasure } from "features/Measure/action";
import { fetchHistoryToUpdate, setModalHistory } from "features/MaterialHistory/actions";
import "./style.css";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextCaption from "components/atoms/Text/TextCaption";

const MeasureSchema = yup.object().shape({
  name: yup.string()
    .required("nama bahan baku tidak boleh kosong."),
  unit: yup.string().required("singkatan satuan ukur tidak boleh kosong."),
});


function MaterialUpdate({ data, handleChange, operator, generateStock, history }) {
  //redux state
  const dispatch = useDispatch();
  const material = useSelector((state) => state.materials);
  const measure = useSelector((state) => state.measure);
  const [isShowCreateMeasure, setIsShowCreateMeasure] = React.useState(false)

  const { register, handleSubmit, errors, setError } = useForm({
    mode: "onBlur",
    validationSchema: MeasureSchema,
  });

  //payload data
  const payload = {
    name: material.name,
    unitOfMeasureId: material.unitOfMeasureId,
    stocks: material.stocks,
  };
  return (
    <>
      <ModalDialog
        size={"large"}
        show={material.modal === "show-update" ? true : false}
        onClose={() => {
          dispatch(setModal("hide-update"));
          dispatch(setName(""));
          dispatch(setUnit(0));
          dispatch(setMeasureName("Pilih satuan ukur"));
          dispatch(setMeasureUnit(""));
          generateStock();
        }}
        title="Edit bahan baku"
        anotherAction={
          <>
            <span className="text-blue-700 font-semibold">{material.name}</span>
            <Button
              disabled={material.name.length > 0 && material.unitOfMeasureId !== 0 ? false : true}
              onClick={() => dispatch(updateMaterial(material.id, payload))}
              variant="primary"
              color="blue"
              type="submit"
            >
              Selesai edit bahan baku
            </Button>
          </>
        }
      >
        <div className="container-body">
          <div className="w-3/5 mx-auto">
            <p className="my-5 font-medium text-lg">Informasi bahan baku</p>

            <div className="ml-14 mt-4">
              <InputTextLargeWithLabel
                className="w-full"
                placeholder=""
                label="Nama bahan baku"
                status="default"
                value={material.name}
                type="Text"
                name="name"
                status={errors?.name ? "error" : "default"}
                register={register}
                onChange={(e) => dispatch(setName(e.target.value))}>
                {errors.name && <div className="flex items-center mt-1">
                  <TextCaption status="error" message={errors.name?.message} />
                </div>}
              </InputTextLargeWithLabel>

            </div>

            <div className="flex w-full justify-between items-center mt-5">
              <div className="w-3/12">
                <p className="text-sm text-gray-02 text-right mr-5 font-medium">Satuan ukur</p>
              </div>
              <div className="w-9/12">
                <SelectBox
                  defaultValue={material.measure_name}
                  items={measure.select_box}
                  onClick={(id, value, title) => {
                    dispatch(setUnit(id));
                    dispatch(setMeasureName(`${title} (${value})`));
                    dispatch(setMeasureUnit(value));
                  }}
                  anotherAction={
                    <li
                      onClick={() => setIsShowCreateMeasure(true)}
                      className="cursor-pointer text-sm font-medium text-center text-white bg-green-03 hover:bg-green-02 duration-500 p-3 "
                    >
                      Tambah satuan ukur lainya
                    </li>
                  }
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-2">
              <Button
                onClick={() => dispatch(setModalSecondary("show-stock-update"))}
                variant="secondary"
                color="blue"
                className="my-5"
              >
                Edit stok bahan baku
              </Button>
              <Button
                onClick={() => dispatch(setModalSecondary("show-track-update"))}
                variant="secondary"
                color="blue"
                className="my-5"
              >
                Edit pelacakan bahan baku
              </Button>
              <Button
                onClick={() => dispatch(fetchHistoryToUpdate(data.id))}
                variant="secondary"
                color="blue"
                className="my-5"
              >
                Lihat sejarah bahan baku
              </Button>
            </div>

            {data.stocks.length
              ? data.stocks.map((items) => {
                return (
                  <div key={items.outletId} className="frame-parent">
                    <div className="w-3/12">
                      <p className="mt-7 font-semibold">{items.outletName}</p>
                    </div>
                    <div className="w-9/12">
                      <div className="frame-children">
                        <span className="font-medium text-sm">
                          Lacak & hitung stok bahan baku
                        </span>
                        <div className="mr-10">
                          <Toggle
                            size="lg"
                            checked={items.trackStock}
                            name="track-stock"
                            value={items.outletId}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {items.trackStock ? (
                        <>
                          {/* FIELD STOCK */}
                          <div className="flex items-center w-full">
                            <div className="w-4/12">
                              <p className="font-small-gray">Stok sekarang</p>
                            </div>
                            <div className="w-8/12">
                              <div className="frame-children">
                                <input
                                  type="number"
                                  disabled
                                  value={items.beforeStock}
                                  placeholder="1,000"
                                  className="input-material"
                                />
                                <span className="font-small-gray">{material.measure_unit}</span>
                              </div>
                            </div>
                          </div>
                          {/* FIELD REASON */}
                          <div className="flex items-center w-full mt-3">
                            <div className="w-4/12">
                              <p className="font-small-gray">Alasan perubahan</p>
                            </div>
                            <div className="w-8/12">
                              <div className="frame-children">
                                <select
                                  onChange={handleChange}
                                  name="reasonId"
                                  data-value={items.outletId}
                                  className="w-full focus:outline-none focus:shadow-none"
                                  value={items.reasonId}
                                >
                                  <option>Pilih alasan</option>
                                  {data.reason.map((reason) => {
                                    return (
                                      <option value={reason.id} key={reason.id}>
                                        {reason.title}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center w-full">
                            <div className="w-4/12">
                              <p className="font-small-gray">Jumlah perubahan</p>
                            </div>
                            <div className="w-8/12">
                              <div className="frame-children">
                                <span>
                                  {items.changeStock > 0 && operator === "MINUS" ? "-" : ""}
                                  <input
                                    name="changeStock"
                                    type="number"
                                    onChange={handleChange}
                                    data-value={items.outletId}
                                    value={items.changeStock}
                                    placeholder="1,000"
                                    className="input-material"
                                    disabled={
                                      operator === "RESET" || items.reasonId === 0 ? true : false
                                    }
                                  />
                                </span>
                                <span className="font-small-gray">{material.measure_unit}</span>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center w-full">
                            <div className="w-4/12">
                              <p className="font-small-gray">Jumlah stok baru</p>
                            </div>
                            <div className="w-8/12">
                              <div className="frame-children">
                                <input
                                  name="afterStock"
                                  type="number"
                                  onChange={handleChange}
                                  data-value={items.outletId}
                                  value={items.afterStock}
                                  placeholder="1,000"
                                  className="input-material"
                                  disabled={items.reasonId === 0 ? true : false}
                                />
                                <span className="font-small-gray">{material.measure_unit}</span>
                              </div>
                            </div>
                          </div>

                          {/* FIELD TRACKING LIMIT */}
                          <div className="frame-children">
                            <span className="font-small-gray">Peringatan stok</span>
                            <div className="mr-10">
                              <Toggle
                                size="lg"
                                checked={items.trackingStatus}
                                name="track-limit"
                                value={items.outletId}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {items.trackingStatus ? (
                            <div className="flex items-center w-full">
                              <div className="w-4/12">
                                <p className="font-small-gray">Peringatan sewaktu</p>
                              </div>
                              <div className="w-8/12">
                                <div className="frame-children">
                                  <input
                                    type="number"
                                    onChange={handleChange}
                                    name="limitStock"
                                    data-value={items.outletId}
                                    value={items.limitStock}
                                    placeholder="1,000"
                                    className="input-material"
                                  />
                                  <span className="font-small-gray">{material.measure_unit}</span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })
              : ""}
          </div>
        </div>
      </ModalDialog>

      <ModalHistory
        show={history.modal === "show-history-material-update" ? true : false}
        close={() => dispatch(setModalHistory("hide-history-material-update"))}
        data={history}
      />


      <ModalStock
        handleChange={handleChange}
        operator={operator}
        data={material}
        handleSubmit={() => dispatch(setModalSecondary("hide-stock-update"))}
        show={data.modal_secondary === "show-stock-update" ? true : false}
        close={() => {
          // generateStock();
          // dispatch(setName(""));
          dispatch(setModalSecondary("hide-stock-update"));
        }}
      />
      <ModalTrack
        handleChange={handleChange}
        handleSubmit={() => dispatch(setModalSecondary("hide-track-update"))}
        data={data}
        show={data.modal_secondary === "show-track-update" ? true : false}
        close={() => {
          // generateStock();
          // dispatch(setName(""));
          dispatch(setModalSecondary("hide-track-update"));
        }}
      />
      <ModalMeasure close={() => setIsShowCreateMeasure(false)} open={isShowCreateMeasure} type="meterial" />
    </>
  );
}

export default MaterialUpdate;
