import React from "react";
import Tag from "components/molecules/Tag";

export default function Tags() {
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="flex items-center">
        <Tag
          className="mr-5"
          variant="blue"
          content="icon right left"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="gray"
          content="icon right left"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="green"
          content="icon right left"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="red"
          content="icon right left"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="orange"
          content="icon right left"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="purple"
          content="icon right left"
          iconName="UilSearch"
        />
      </div>
      <div className="flex items-center mt-5">
        <Tag
          className="mr-5"
          variant="blue"
          content="icon right"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="gray"
          content="icon right"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="green"
          content="icon right"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="red"
          content="icon right"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="orange"
          content="icon right"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="purple"
          content="icon right"
          iconName="UilSearch"
        />
      </div>
      <div className="flex items-center mt-5">
        <Tag
          className="mr-5"
          variant="blue"
          content="icon left"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="gray"
          content="icon left"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="green"
          content="icon left"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="red"
          content="icon left"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="orange"
          content="icon left"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="purple"
          content="icon left"
          iconName="UilSearch"
        />
      </div>
      <div className="flex items-center mt-5">
        <Tag
          className="mr-5"
          variant="blue"
          content="label only"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="gray"
          content="label only"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="green"
          content="label only"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="red"
          content="label only"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="orange"
          content="label only"
          iconName="UilSearch"
        />
        <Tag
          className="mr-5"
          variant="purple"
          content="label only"
          iconName="UilSearch"
        />
      </div>
    </div>
  );
}
