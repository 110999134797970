import React from "react";
import Banner from "components/atoms/Banner";

export default function Banners() {
  return (
    <div className="pt-28 pb-20 h-screen overflow-scroll scroll-hidden px-10">
      <Banner
        message={"Product “Dalenta” has been successfully added"}
        type="primary"
        status="success"
      />

      <Banner
        className="mt-3"
        message={"Product “Dalenta” has been successfully added"}
        type="secondary"
        status="success"
      />

      <Banner
        className="mt-10"
        message={"Product “Dalenta” has been successfully deleted"}
        type="primary"
        status="danger"
      />

      <Banner
        className="mt-3"
        message={"Product “Dalenta” has been successfully deleted"}
        type="secondary"
        status="danger"
      />

      <Banner
        className="mt-10"
        message={"Please be careful upon proceeding"}
        type="primary"
        status="warning"
      />

      <Banner
        className="mt-3"
        message={"Please be careful upon proceeding"}
        type="secondary"
        status="warning"
      />
    </div>
  );
}
