import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import { deleteData } from "utils/fetchData";
import { fetchAccounts, setNotif } from "features/Accounts/actions";
import { useDispatch } from "react-redux";

function DeleteAccount({ show, close, id }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const dropAccount = async () => {
    setLoading(true);
    await deleteData(`v2/account/${id}`)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          let notif = {
            message: res.data.message,
            type: "primary",
            status: "danger",
            toggle: true,
          };
          dispatch(fetchAccounts());
          dispatch(setNotif(notif));
          setLoading(false);
          close();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.code === 422) {
          alert(err.response.data.message);
          setLoading(false);
        }
        setLoading(false);
      });
  };
  return (
    <ModalDialog size={"alert"} show={show} onClose={close} title="Hapus">
      <div className={`bg-white p-5 h-full overflow-scroll`}>
        <p>
          {`Apakah Anda yakin ingin menghapus “Akun” ? Aksi ini tidak bisa dibatalkan lagi jika bahan baku telah berhasil dihapus.`}
        </p>
      </div>
      <div className="px-5 flex justify-between items-center bg-white">
        <Button onClick={close} variant="primary" color="gray" className="my-4 mr-4" type="button">
          Batal hapus akun
        </Button>
        <Button
          loading={loading}
          onClick={dropAccount}
          variant="primary"
          color="red"
          className="my-4"
          type="button"
        >
          Hapus akun
        </Button>
      </div>
    </ModalDialog>
  );
}

export default DeleteAccount;
