import {
  START_FETCHING_REPORT,
  SUCCESS_FETCHING_REPORT,
  ERROR_FETCHING_REPORT,
  SET_LOCATION,
  SET_DATE
} from "./constants";
import { userLogout } from 'features/Auth/actions'

import { getDatav2 } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchReport = debounce(getDatav2, 1000);

export const startFetchingSummary = () => {
  return {
    type: START_FETCHING_REPORT,
  };
};

export const errorFetchingLocations = () => {
  return {
    type: ERROR_FETCHING_REPORT,
  };
};

export const successFetchingReport = ({ graphic, salesInfo, topPayment, location }) => {
  return {
    type: SUCCESS_FETCHING_REPORT,
    graphic,
    salesInfo,
    topPayment,
    location
  };
};

export const fetchSummary = () => {
  return async (dispatch, getState) => {

    dispatch(startFetchingSummary());

    try {

      let start = getState().report.date.startDate || '';
      let end = getState().report.date.endDate || '';
      let outlet_id = getState().report.location || '';

      const params = {
        start,
        end,
        outlet_id
      }

      let { data: { outlets }, } = await debouncedFetchReport("outlets");

      let select_box = [{ label: "Semua lokasi", value: "", }]
      outlets.forEach((item) => {
        select_box.push({
          label: item.name,
          value: item.id,
        });
      });

      const summary = await debouncedFetchReport('v2/report/summary', params)

      if (summary.data?.code === 401) {
        dispatch(userLogout())
      }

      let labels = [], data = [], count = [];

      summary.data.chartView.forEach(chart => {
        labels.push(chart.x)
        data.push(chart.y)
        count.push(chart.count)
      });
      // style graphic
      const graphic = {
        maintainAspectRatio: false,
        responsive: false,
        labels,
        datasets: [
          {
            data,
            count,
            borderColor: "rgb(31, 75, 255)",
            backgroundColor: ["rgba(31, 75, 255, 0.3)"],
            lineTension: 0,
          }
        ]
      }

      dispatch(successFetchingReport({
        graphic: {
          type: "line",
          width: 300,
          height: 160,
          data: { id: 1, ...graphic, currency: "$" }
        },
        salesInfo: summary.data.salesInfo,
        topPayment: summary.data.topPayment,
        location: select_box
      }))

    } catch (err) {
      console.log(err.response)
      dispatch(errorFetchingLocations());
    }
  };
};

export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};

export const setDate = date => {
  return {
    type: SET_DATE,
    date: date.selection ? date.selection : date
  }
}