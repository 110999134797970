import * as React from "react";
import Logo from "components/atoms/Logo";
import IconImage from "components/atoms/Icons/Custom/Image";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import IconClose from "components/atoms/Icons/Custom/Closes";
import { useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextArea from "components/atoms/Form/InputTextArea";
import Button from "components/atoms/Button";
import "./verifikasi.css";
import axios from 'axios'
import TextCaption from "components/atoms/Text/TextCaption";
import { postData } from "utils/fetchData";

const VerificationSchema = yup.object().shape({
  nik: yup.string().required('nik tidak boleh kosong.'),
  fullname: yup.string().required('nama tidak boleh kosong.'),
  birthplace: yup.string().required('tempat lahir tidak boleh kosong.'),
  birthday: yup.date().required('tanggal lahir tidak boleh kosong.'),
  address: yup.date().required('alamat tidak boleh kosong.'),
});

export default function Verification({ action = false }) {
  const history = useHistory()
  const [value, setValue] = React.useState("");
  const [field, setField] = React.useState({
    imageURL: "",
    identity: "",
    nik: "",
    fullname: "",
    birthplace: "",
    birthday: "",
    address: ""
  })

  const { register, handleSubmit, errors, setError, clearError } = useForm({
    mode: "onBlur",
    validationSchema: VerificationSchema
  });

  const [loading, setLoading] = React.useState(false);

  const onChange = async (e) => {
    const { value, name } = e.target
    if (name === "imageURL") {
      if (e.target.files[0] !== undefined) {
        if (
          e.target.files[0].type === 'image/jpg' ||
          e.target.files[0].type === 'image/png' ||
          e.target.files[0].type === 'image/jpeg'
        ) {
          const formData = new FormData();
          formData.append("image", e.target.files[0]);
          const res = await postData(`upload/single`, formData);

          setField({ ...field, imageURL: e.target.files[0], identity: res.data.data.path })
          clearError('imageURL')
        } else {
          setField({ ...field, imageURL: "" })
          setError('imageURL', 'imageURL', 'Format image harus jpg, png, jpeg')
        }
      }

    }
    else setField({ ...field, [name]: value })

  }

  const onSubmit = async () => {
    try {
      setLoading(true)
      const payload = {
        identity: field.identity,
        nik: field.nik,
        full_name: field.fullname,
        born: field.birthplace,
        dob: field.birthday,
        address: field.address
      }

      const res = await postData(`brands/verify`, payload)
      if (res.data.code === 200) {
        setLoading(false)
        history.push('/')
      }
    } catch (err) {
      console.log(err.response)
    }
  }
  return (
    <div>
      {/* header */}
      <div>
        <div className="flex justify-between shadow-HeavyDropShadow py-6 px-6 fixed top-0 left-0 right-0 w-full bg-white z-50">
          {/* icon */}
          <span onClick={() => history.push('/')}><IconClose className="cursor-pointer" /></span>

          <Logo
            type="sello"
            className="absolute left-1/2 transform -translate-x-1/2"
            content="icon wordmark"
            variant="blue"
          />
        </div>
      </div>
      {/* content */}
      <div className="h-screen px-40 pt-28 w-full">
        <h1 className="text-center heading-1 text-gray-02">
          Verifikasi Identitas
        </h1>
        <p className="text-center text-3 mt-8 text-gray-03">
          Upload foto KTP anda dengan jelas untuk memulai verifikasi identitas.
          Setelah identitas anda telah diverifikasi, akun anda akan bisa
          menerima pembayaran dan melakukan transfer saldo ke rekening.
        </p>
        <div className="border p-4 rounded-lg my-6">
          {/* image */}
          <div
            className={`group flex flex-col items-center text-gray-04 hover:text-blue-02`}
          >
            <div className="group-hover:bg-blue-05 group-hover:border-blue-03 relative flex items-center justify-center w-166 h-166 rounded-lg border border-gray-04 overflow-hidden cursor-pointer p-6">
              {field.imageURL === "" || field.imageURL === undefined ? (
                <IconImage
                  className={
                    "absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-53 min-w-53 w-53 duration-200 cursor-pointer"
                  }
                  fill={"currentColor"}
                />
              ) : (
                <img
                  className="w-5/6"
                  src={URL.createObjectURL(field.imageURL)}
                />
              )}
              <input
                className="absolute inset-0 opacity-0 z-10 cursor-pointer"
                name="imageURL"
                type="file"
                onChange={onChange}
              />
            </div>
            {field.imageURL === "" || field.imageURL === undefined ? (
              <input
                className="inputBtn mt-6 relative h-14"
                type="file"
                name="imageURL"
                onChange={onChange}
              />
            ) : (
              <input
                className="inputEditBtn mt-6 relative h-14"
                name="imageURL"
                type="file"
                onChange={onChange}
              />
            )}
          </div>
          {errors.imageURL && (
            <div className="flex items-center mt-1">
              <TextCaption status="error" message={errors.imageURL?.message} />
            </div>
          )}
        </div>
        <div className="input-verifikasi">
          <InputTextLargeWithLabel
            className="w-full mt-3"
            label="NIK"
            type="number"
            status={errors?.nik ? "error" : "default"}
            register={register}
            name="nik"
            placeholder="NIK sesua KTP"
            onChange={onChange}
            value={field.nik}
          >
            {errors.nik && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.nik?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
        </div>
        <div className="input-verifikasi">
          <InputTextLargeWithLabel
            className="w-full mt-3"
            label="Nama lengkap"
            status={errors?.fullname ? "error" : "default"}
            register={register}
            name="fullname"
            placeholder="Nama lengkap sesuai KTP"
            onChange={onChange}
            value={field.fullname}
          >
            {errors.fullname && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.fullname?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
        </div>
        <div className="input-verifikasi">
          <InputTextLargeWithLabel
            className="w-full mt-3"
            label="Tempat lahir"
            status={errors?.birthplace ? "error" : "default"}
            register={register}
            name="birthplace"
            placeholder="Tempat lahir sesuai KTP"
            onChange={onChange}
            value={field.birthplace}
          >
            {errors.birthplace && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.birthplace?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
        </div>
        <div className="flex items-center mt-2">
          <p className="bold-text-2 text-gray-02 whitespace-nowrap w-32 mr-5">
            Tanggal lahir
          </p>
          <div className="w-full border-2 rounded-lg p-4">
            <input
              ref={register}
              type="date"
              name="birthday"
              value={field.birthday}
              onChange={onChange}
              className="w-full text-sm text-gray-02 outline-none focus:outline-none"
              placeholder="Masukan tanggal"
            />
            {errors.birthday && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.birthday?.message} />
              </div>
            )}
          </div>
        </div>
        <TextArea
          className="mt-3"
          spacing="w-32"
          variant="default"
          rows={5}
          placeholder="Alamat sesuai KTP"
          label="Alamat"
          name="address"
          value={field.address}
          onChange={onChange}
        >
          {errors.address && (
            <div className="flex items-center mt-1">
              <TextCaption
                status="error"
                message={errors.address?.message}
              />
            </div>
          )}
        </TextArea>

        <div className="flex w-full justify-center mt-6 pb-6">
          <Button
            loading={loading}
            disabled={field.imageURL === "" || field.nik === "" || field.fullname === "" || field.birthday === "" || field.birthplace === "" || field.address === "" ? true : false}
            onClick={() => !loading ? onSubmit() : null}
            variant="primary" color={field.imageURL === "" || field.nik === "" || field.fullname === "" || field.birthday === "" || field.birthplace === "" || field.address === "" ? "gray" : "blue"}>
            Mulai verifikasi identitas
          </Button>
        </div>
      </div>
    </div>
  );
}
