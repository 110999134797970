import * as React from "react";
import Stepper from "components/atoms/Stepper";

export default function Steppers() {
  const [value, setValue] = React.useState(0);
  return (
    <div className="pt-20 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <Stepper
        items={value}
        onItemDec={() => setValue(value + 1)}
        onItemInc={() => setValue(value - 1)}
      />
    </div>
  );
}
