import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import Checkbox from "components/atoms/Checkbox";
import Button from "components/atoms/Button";
import { InputTextSmall } from "components/atoms/Form";
import {
  setGenerateBarcode,
} from "features/Barcode/actions";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function ShowBarcode({ show, close, data, status }) {
  const dispatch = useDispatch();
  const [list, setList] = React.useState([])
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  

  React.useEffect(()=>{
    const getProduct = () =>{
      let temp = []

      if(status === "true false"){

        data.forEach(data => {
          data.isCheck = true
          data.stock = 10
          temp.push(data)
        });
        setList(temp)
      }else{
        data.forEach(data => {
          data.isCheck = true
          data.stock = 10
          data.name = data.outletName
          temp.push(data)
        });
        setList(temp)
      }

    }

    getProduct()
  },[data])


  const onCheckboxCategory = (e, index) => {
    let _temp = [...list];
    _temp[index]["isCheck"] = e.target.checked;
    setList(_temp)
  }

  const onChange  = (e, i) =>{

  }


  const onSubmit = () => {
    const payload = {
      count: list.reduce((a, b) => Number(a) + Number(b.totalStock), 0),
      generateBarcode: list,
    };
    dispatch(setGenerateBarcode(payload));
    history.push("/product/inventory/barcode/success");
  };

  return (
    <ModalDialog
      size={"alert"}
      show={show}
      onClose={close}
      title={<h1 className="heading-3 text-dark">Buat barcode produk</h1>}
    >
      <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
      
        <div
          className="bg-white border rounded-lg p-4 overflow-y-scroll"
          style={{ maxHeight: "48vh" }}
        >
          <ul>
          {list.length ? data.map((data, index) => {
              return (
                <li key={index}>
                  <div className="flex items-center">
                    <div className="relative">
                      <Checkbox
                        className="absolute top-2px"
                        type="default"
                        checked={data.isCheck}
                        name="checkbox"
                        value={data.isCheck}
                        onChange={(e) => onCheckboxCategory(e, index)}
                      />
                      <p className="text-2 pl-8">{data.name}</p>
                      <InputTextSmall
                        status="default"
                        className="text-center pl-0"
                        placeholder="300"
                        name="stock"
                        value={data.totalStock}
                        // disabled={data.isCheck ? false : true}
                        disabled
                        onChange={(e) => onChange(e, index)}
                      />
                    </div>
                  </div>
                </li>
              )
            }): <h3>tidak ada produk</h3>}
          </ul>
        </div>
      </div>
      {list.length && <div className="px-5 flex justify-end items-center bg-white">
          <p>{list.reduce((a, b) => Number(a) + Number(b.totalStock), 0)} barcode yang akan dibuat</p>

          <Button
            variant="primary"
            color="blue"
            className="my-5"
            loading={loading ? true : false}
            onClick={!loading ? onSubmit : null}
          
          >
            Buat barcode produk
          </Button>
        </div>
      }
    </ModalDialog>
  );
}
