export const START_FETCHING_GROUP = "features/CustomerGroup/START_FETCHING_GROUP";
export const ERROR_FETCHING_GROUP = "features/CustomerGroup/ERROR_FETCHING_GROUP";
export const SUCCESS_FETCHING_GROUP = "features/CustomerGroup/SUCCESS_FETCHING_GROUP";

export const SET_KEYWORD = "features/CustomerGroup/SET_KEYWORD";
export const SET_PAGE = "features/CustomerGroup/SET_PAGE";
export const SET_LIMIT = "features/CustomerGroup/SET_LIMIT";
export const SET_TOTAL_PAGE = "features/CustomerGroup/SET_TOTAL_PAGE";

export const SET_NOTIF = "features/Customer/SET_NOTIF";
export const SET_CRITERIA = "features/Customer/SET_CRITERIA";
