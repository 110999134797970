import {
  START_FETCHING_DISCOUNT,
  ERROR_FETCHING_DISCOUNT,
  SUCCESS_FETCHING_DISCOUNT,
  SUCCESS_FETCHING_PRODUCTINDISCOUNT,
  SET_KEYWORD,
  SET_PRODUCT,
  SET_NOTIF,
  SUCCESS_FETCHING_CATEGORYINDISCOUNT,
  SET_CATEGORY
} from "./constants";
import { userLogout } from 'features/Auth/actions'

import { getData } from 'utils/fetchData'
import debounce from "debounce-promise";

let debouncedFetchDiscount = debounce(getData, 1000);

export const startFetchingDiscount = () => {
  return {
    type: START_FETCHING_DISCOUNT,
  };
};

export const errorFetchingDiscount = () => {
  return {
    type: ERROR_FETCHING_DISCOUNT,
  };
};

export const successFetchingDiscount = ({ discount }) => {
  return {
    type: SUCCESS_FETCHING_DISCOUNT,
    discount
  };
};

export const fetchDiscount = () => {
  return async (dispatch, getState) => {
    try {

      const notif = {
        message: '',
        type: '',
        status: '',
        toggle: false
      }

      setTimeout(() => {
        dispatch(setNotif(notif))
      }, 5000)

      let keyword = getState().discount.keyword || '';
      dispatch(startFetchingDiscount());
      const params = { name: keyword }
      const res = await debouncedFetchDiscount('discount', params);

      if (res.data?.code === 401) {
        dispatch(userLogout())
      }

      dispatch(successFetchingDiscount({ discount: res.data.data }));

    } catch (err) {
      dispatch(errorFetchingDiscount());
    }
  };
};

export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};


export const fetchProductInDiscount = () => {
  return async (dispatch) => {
    try {
      const params = {
        page: 1,
        limit: 10000000, // sementara =>
      }
      const res = await getData('v2/product', params);

      if (res.data?.code === 401) {
        dispatch(userLogout())
      }

      const product = []

      res.data.products.forEach(data => {
        data.isChecked = false
        product.push(data)
      });

      dispatch(successFetchingProductInDiscount({ product }));

    } catch (err) {
      // dispatch(errorFetchingDiscount());
    }
  };
};

export const successFetchingProductInDiscount = ({ product }) => {
  return {
    type: SUCCESS_FETCHING_PRODUCTINDISCOUNT,
    product
  };
};

export const setProduct = (product) => {
  return {
    type: SET_PRODUCT,
    product,
  };
};


export const fetchCategoryInProduct = () => {
  return async (dispatch) => {
    let res = await getData("categories");

    let select_box = [];
    res.data.categories.forEach((item) => {
      select_box.push({
        label: item.name,
        value: item.id,
        isChecked: true,
      });
    });
    dispatch(successFetchingCategoryInDiscount({ category: select_box }));
  };
};

export const successFetchingCategoryInDiscount = ({ category }) => {
  return {
    type: SUCCESS_FETCHING_CATEGORYINDISCOUNT,
    category,
  };
};

export const setCategory = (category) => {
  return {
    type: SET_CATEGORY,
    category,
  };
};