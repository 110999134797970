const menus = [
  {
    id: 123123123,
    name: "Daftar Perangkat",
    link: "/device",
    prefix: "Device",
  },
  // {
  //   id: 1231231212312,
  //   name: "Logo",
  //   link: "/device/logo",
  //   prefix: "Device",
  // },
  {
    id: 12312390,
    name: "Screensaver",
    link: "/device/screensaver",
    prefix: "Device",
  },
  {
    id: 10,
    prefix: "Dashbaord",
    name: "Dashboard",
    isDropDown: false,
    link: "/dahsboard",
  },
  {
    id: 123,
    name: "Daftar Pelanggan",
    link: "/customer",
    prefix: "Customer",
  },
  {
    id: 123123123123123435,
    name: "Ulasan Pelanggan",
    link: "/customer/review",
    prefix: "Customer",
  },
  {
    id: 12312312983217931233123123435,
    name: "Kelompok Pelanggan",
    link: "/customer/group",
    prefix: "Customer",
  },
  // {
  //   id: 12312312983217931233123123435,
  //   name: "Pengaturan Ulasan",
  //   link: "/customer/setting",
  //   prefix: 'Customer',
  // },
  {
    id: 10128309183019238,
    name: "Transaction",
    link: "/transaction",
  },
  {
    id: 8917398127391,
    name: "Ringkasan Penjualan",
    link: "/report",
    prefix: "Report",
  },
  {
    id: 891739812739123123,
    name: "Tren Penjualan",
    link: "/report/trend",
    prefix: "Report",
  },
  {
    id: 123198798,
    name: "Metode Pembayaran",
    link: "/report/payment-method",
    prefix: "Report",
  },
  {
    id: 12319879234328,
    name: "Produk",
    link: "/report/product",
    prefix: "Report",
  },
  {
    id: 18923712987985943759483,
    name: "Variasi",
    link: "/report/variation",
    prefix: "Report",
  },
  {
    id: 1231273981273,
    name: "Kategori",
    link: "/report/category",
    prefix: "Report",
  },
  {
    id: 9382492308,
    name: "Diskon",
    link: "/report/discount",
    prefix: "Report",
  },
  {
    id: 12381278361873123,
    name: "Perpajakan",
    link: "/report/impost",
    prefix: "Report",
  },
  {
    id: 4237864238746234,
    name: "Refund",
    link: "/report/refund",
    prefix: "Report",
  },
  {
    id: 123891267312896319823,
    name: "Catatan Aktivitas",
    link: "/report/noteactivity",
    prefix: "Report",
  },
  {
    id: 120917983712983,
    name: "Informasi Saldo",
    link: "/balance",
    prefix: "Balance",
  },
  {
    id: 38129381354385734,
    name: "Rekening Bank",
    link: "/balance/bill",
    prefix: "Balance",
  },
  {
    id: 983120938103,
    name: "Informasi Akun",
    link: "/setting",
    prefix: "Setting",
  },
  {
    id: 28937498214712,
    name: "Notifikasi Email",
    link: "/setting/email-notification",
    prefix: "Setting",
  },
  {
    id: 874584358345,
    name: "Akses Akun Tim",
    link: "/setting/team-account-access",
    prefix: "Setting",
  },
  {
    id: 8923913495834,
    name: "Lokasi",
    link: "/setting/locations",
    prefix: "Setting",
  },
  {
    id: 2391849023849147,
    name: "Struk digital",
    link: "/setting/digital-receipt",
    prefix: "Setting",
  },
  {
    id: 2391849023849147,
    name: "Struk fisik",
    link: "/setting/physical-receipt",
    prefix: "Setting",
  },

  {
    id: 1,
    name: "Daftar produk",
    link: "/product",
    prefix: "Product",
  },
  {
    id: 2,
    name: "Variasi",
    link: "/product/variant",
    prefix: "Product",
  },
  {
    id: 3,
    name: "Kategori",
    link: "/product/category",
    prefix: "Product",
  },
  {
    id: 4,
    name: "Diskon",
    link: "/product/discount",
    prefix: "Product",
  },
  {
    id: 5,
    name: "Pajak",
    link: "/product/tax",
    prefix: "Product",
  },
  {
    id: 6,
    name: "Inventaris",
    prefix: "Product",
    status: true,
    submenu: [
      {
        id: 1,
        name: "Daftar stok",
        link: "/product/inventory/default",
      },
      {
        id: 2,
        name: "Bahan baku",
        link: "/product/inventory/material",
      },
      {
        id: 3,
        name: "Satuan ukur",
        link: "/product/inventory/measure",
      },
    ],
  },
  {
    id: 9991,
    name: "Color",
    link: "/component/color",
    prefix: "Component",
  },
  {
    id: 9992,
    name: "Page Grid",
    link: "/component/grid",
    prefix: "Component",
  },
  {
    id: 9993,
    name: "Shadow",
    link: "/component/shadow",
    prefix: "Component",
  },
  {
    id: 9994,
    name: "Typography",
    link: "/component/typography",
    prefix: "Component",
  },
  {
    id: 1231390,
    name: "Avatar",
    link: "/component/avatar",
    prefix: "Component",
  },
  {
    id: 123234,
    name: "Banner",
    link: "/component/banner",
    prefix: "Component",
  },
  {
    id: 123235,
    name: "Button",
    link: "/component/button",
    prefix: "Component",
  },
  {
    id: 123236,
    name: "Calender",
    link: "/component/calender",
    prefix: "Component",
  },
  {
    id: 123237,
    name: "Checkbox",
    link: "/component/checkbox",
    prefix: "Component",
  },
  {
    id: 12327877,
    name: "Color Picker",
    link: "/component/color-picker",
    prefix: "Component",
  },
  {
    id: 123238,
    name: "Dropdown",
    link: "/component/dropdown",
    prefix: "Component",
  },
  {
    id: 123123139127839,
    name: "Flag",
    link: "/component/flag",
    prefix: "Component",
  },
  {
    id: 123239,
    name: "Image Field",
    link: "/component/image-field",
    prefix: "Component",
  },
  {
    id: 123240,
    name: "Logo",
    link: "/component/logo",
    prefix: "Component",
  },
  {
    id: 123241,
    name: "Modal Dialog",
    link: "/component/modal",
    prefix: "Component",
  },
  {
    id: 123242,
    name: "Pagination",
    link: "/component/pagination",
    prefix: "Component",
  },
  {
    id: 123243,
    name: "Pin Field Label",
    link: "/component/pin",
    prefix: "Component",
  },
  {
    id: 123244,
    name: "Progress Bar",
    link: "/component/progress-bar",
    prefix: "Component",
  },
  {
    id: 123245,
    name: "Radio",
    link: "/component/radio",
    prefix: "Component",
  },
  {
    id: 123213123123123,
    name: "SearchBar",
    link: "/component/searchbar",
    prefix: "Component",
  },
  {
    id: 123246,
    name: "Select Field Dropdown",
    link: "/component/select-field-dropdown",
    prefix: "Component",
  },
  {
    id: 123247,
    name: "Spacing",
    link: "/component/spacing",
    prefix: "Component",
  },
  {
    id: 123248,
    name: "Spinner Loader",
    link: "/component/spinner",
    prefix: "Component",
  },
  {
    id: 123249,
    name: "Stepper",
    link: "/component/stepper",
    prefix: "Component",
  },
  {
    id: 123250,
    name: "Switch",
    link: "/component/switch",
    prefix: "Component",
  },
  {
    id: 123251,
    name: "Table",
    link: "/component/table",
    prefix: "Component",
  },
  {
    id: 123252,
    name: "Tabs Horizontal",
    link: "/component/tab-horizontal",
    prefix: "Component",
  },
  {
    id: 123253,
    name: "Tabs Vertical",
    link: "/component/tab-vertical",
    prefix: "Component",
  },
  {
    id: 123254,
    name: "Tag",
    link: "/component/tag",
    prefix: "Component",
  },
  {
    id: 123255,
    name: "Text Area",
    link: "/component/text-area",
    prefix: "Component",
  },
  {
    id: 123256,
    name: "Text Caption",
    link: "/component/text-caption",
    prefix: "Component",
  },
  {
    id: 123257,
    name: "Text Field (Large)",
    link: "/component/text-field-large",
    prefix: "Component",
  },
  {
    id: 123258,
    name: "Text Field (small)",
    link: "/component/text-field-small",
    prefix: "Component",
  },
  {
    id: 123259,
    name: "Toggle",
    link: "/component/toggle",
    prefix: "Component",
  },
  {
    id: 123260,
    name: "Tooltip",
    link: "/component/tooltip",
    prefix: "Component",
  },
];

export default menus;
