import React from "react";
import Logo from "components/atoms/Logo";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import Pin from "components/atoms/Pin";
import Button from "components/atoms/Button";
import EmptyPage from "components/templates/Empty";
import ImageEmpty from "assets/icon/undraw_Onboarding_re_6osc 1.png";

export default function BuatPINakun() {
  const onChange = (value) => {
    console.log(value);
  };
  const [modal, setModal] = React.useState(false);
  return (
    <div>
      {/* header */}
      <div className="flex justify-center shadow-HeavyDropShadow py-6 fixed top-0 left-0 right-0 w-full bg-white">
        {/* icon */}
        <Logo type="sello" content="icon" variant="blue" />
      </div>
      {/* content */}
      <div className="bg-white pt-24 h-screen flex justify-center">
        <div className="w-728px max-w-728px">
          <h3 className="heading-3 mb-4">Buat PIN akun</h3>
          <p className="mb-4 mt-1 text-2 text-gray-03">
            PIN akan digunakan nanti untuk akses perangkat dan lainnya.
          </p>
          <div className="form-first-login">
            <InputTextLargeWithLabel
              spacing="w-32"
              className="w-full mt-3"
              label="Nama lengkap"
              //   status={errors?.name ? "error" : "default"}
              //   register={register}
              name="name"
              placeholder=""
              //   onChange={onChange}
              //   value={field.name}
            >
              {/* {errors.name && (
            <div className="flex items-center mt-1">
              <TextCaption status="error" message={errors.name?.message} />
            </div>
          )} */}
            </InputTextLargeWithLabel>
          </div>
          <div className="form-first-login">
            <InputTextLargeWithLabel
              spacing="w-32"
              className="w-full mt-3"
              label="Posisi"
              //   status={errors?.name ? "error" : "default"}
              //   register={register}
              name="name"
              placeholder=""
              //   onChange={onChange}
              //   value={field.name}
            >
              {/* {errors.name && (
            <div className="flex items-center mt-1">
              <TextCaption status="error" message={errors.name?.message} />
            </div>
          )} */}
            </InputTextLargeWithLabel>
          </div>
          <div className="mt-2 form-first-login pin">
            <Pin
              focus
              color="blue"
              label="Pin Field label"
              onChange={onChange}
            />
          </div>
          <div className="flex justify-end mt-24">
            <Button
              variant="primary"
              color="blue"
              onClick={() => setModal(true)}
            >
              Selesai
            </Button>
          </div>
        </div>
      </div>
      {/* modal */}
      {modal ? (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center">
          <div className="bg-gray-05 rounded-lg max-w-screen-lg overflow-hidden first-login">
            <EmptyPage
              title="Kenali Sello"
              text="Sello penuh dengan fitur dan kemampuan dan fungsi yang dapat membantu bisnis Anda berjalan lebih lancar, cepat, dan mudah. Kenali Sello dengen singkat untuk mengetahui cara memakainya dengan mulus."
              image={ImageEmpty}
              label="Mulai introduksi singkat"
              // onClick={() => setIsShowCreate(true)}
              // importButton
              // labelImportButton="Import produk"
              // onClickImport={() => alert("onprogress")}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
