import * as React from "react";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";

export default function ComponentDropdownMenu() {
  const [value, setValue] = React.useState({
    label: "Select item(s)",
    value: "",
    isChecked: true,
    all: true
  });
  const [list, setList] = React.useState([
    {
      value: 1,
      label: "Dropdown 1",
      isChecked: false,
    },
    {
      value: 2,
      label: "Dropdown 2",
      isChecked: true,
    },
  ]);

  const onClick = (data, allCheckbox) => {
    let check = [];
    if (allCheckbox) {
      if (value.isChecked) {
        for (let i = 0; i < list.length; i++) {
          check.push({ ...list[i], isChecked: false })
        }
        setValue({
          label: "0 Checkbox",
          value: "",
          isChecked: false,
          all: false
        })

        setList(check);

      } else {
        for (let i = 0; i < list.length; i++) {
          check.push({ ...list[i], isChecked: true })
        }
        setValue({
          label: "Semua lokasi",
          value: "",
          isChecked: true,
          all: true
        })
        setList(check);
      }
    } else {
      for (let i = 0; i < list.length; i++) {
        if (list[i].value === data.value) {
          check.push({
            ...list[i],
            isChecked: data.isChecked
              ? (data.isChecked = false)
              : (data.isChecked = true),
          });
        } else {
          check.push(list[i]);
        }
      }

      setList(check);
      let sum = 0;
      check.forEach(obj => {
        if (obj.isChecked === true) sum += 1
      })

      setValue({
        label: sum === list.length ?
          'Semua item(s)' : `${sum} checkbox`,
        value: 'sesuaikan kebutuhan',
        isChecked: sum === list.length ? true : false
      })
    }
  };

  return (
    <div className="w-full pt-16 mt-5">
      {/* START: BLUE */}
      <div className="flex items-center">
        <div className="mx-2">
          <Dropdown
            variant="primary"
            color="blue"
            className="my-2"
            content="label only"
            label={value.label}
          >
            <DropdownList onClick={() => onClick(value, true)} list="checkbox" checked={value.isChecked}>Semua lokasi</DropdownList>
            {list.map((data, i) => (
              <DropdownList
                key={i}
                onClick={() => onClick(data)}
                checked={data.isChecked}
                list="checkbox"
              >
                {data.label}
              </DropdownList>
            ))}
          </Dropdown>
          <Dropdown
            variant="primary"
            color="blue"
            disabled
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="primary"
            color="blue"
            className="my-2"
            content="label with icon"
          >
            {list.map((data, i) => (
              <DropdownList
                key={i}
                onClick={() => onClick(data)}
                checked={data.isChecked}
                list="radio"
              >
                {data.label}
              </DropdownList>
            ))}
          </Dropdown>
          <Dropdown
            variant="primary"
            color="blue"
            disabled
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="outlined"
            color="blue"
            className="my-2"
            content="label only"
          >
            {list.map((data, i) => (
              <DropdownList
                key={i}
                onClick={() => onClick(data)}
                checked={data.isChecked}
                list="toggle"
              >
                {data.label}
              </DropdownList>
            ))}
          </Dropdown>
          <Dropdown
            variant="outlined"
            color="blue"
            disabled
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="outlined"
            color="blue"
            className="my-2"
            content="label with icon"
          >
            <DropdownList list="item" iconName="UilSearch">
              dropdown list
            </DropdownList>
          </Dropdown>
          <Dropdown
            variant="outlined"
            color="blue"
            disabled
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="primary"
            color="blue"
            content="icon only"
            className="my-2"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>

          <Dropdown variant="primary" color="blue" content="icon only" disabled>
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="subtle"
            color="blue"
            content="icon only"
            className="my-2"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown variant="subtle" color="blue" content="icon only">
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
      </div>

      <div className="flex items-center">
        <div className="mx-2">
          <Dropdown
            variant="subtle"
            color="blue"
            className="my-2"
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown variant="subtle" color="blue" disabled content="label only">
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="subtle"
            color="blue"
            className="my-2"
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown
            variant="subtle"
            color="blue"
            disabled
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="textual"
            color="blue"
            className="my-2"
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown
            variant="textual"
            color="blue"
            disabled
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="textual"
            color="blue"
            className="my-2"
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown
            variant="textual"
            color="blue"
            disabled
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="outlined"
            color="blue"
            content="icon only"
            className="my-2"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown variant="outlined" color="blue" content="icon only">
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="textual"
            color="blue"
            content="icon only"
            className="my-2"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown variant="textual" color="blue" content="icon only">
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
      </div>
      {/* END: BLUE */}

      {/* START: GRAY */}
      <div className="flex items-center">
        <div className="mx-2">
          <Dropdown
            variant="primary"
            color="gray"
            className="my-2"
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown
            variant="primary"
            color="gray"
            disabled
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="primary"
            color="gray"
            className="my-2"
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown
            variant="primary"
            color="gray"
            disabled
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="outlined"
            color="gray"
            className="my-2"
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown
            variant="outlined"
            color="gray"
            disabled
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="outlined"
            color="gray"
            className="my-2"
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown
            variant="outlined"
            color="gray"
            disabled
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="primary"
            color="gray"
            content="icon only"
            className="my-2"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>

          <Dropdown variant="primary" color="gray" content="icon only" disabled>
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="subtle"
            color="gray"
            content="icon only"
            className="my-2"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown variant="subtle" color="gray" content="icon only">
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
      </div>

      <div className="flex items-center">
        <div className="mx-2">
          <Dropdown
            variant="subtle"
            color="gray"
            className="my-2"
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown variant="subtle" color="gray" disabled content="label only">
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="subtle"
            color="gray"
            className="my-2"
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown
            variant="subtle"
            color="gray"
            disabled
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="textual"
            color="gray"
            className="my-2"
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown
            variant="textual"
            color="gray"
            disabled
            content="label only"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="textual"
            color="gray"
            className="my-2"
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown
            variant="textual"
            color="gray"
            disabled
            content="label with icon"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="outlined"
            color="gray"
            content="icon only"
            className="my-2"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown variant="outlined" color="gray" content="icon only">
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
        <div className="mx-2">
          <Dropdown
            variant="textual"
            color="gray"
            content="icon only"
            className="my-2"
          >
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
          <Dropdown variant="textual" color="gray" content="icon only">
            <DropdownList>dropdown list</DropdownList>
          </Dropdown>
        </div>
      </div>
      {/* END: GRAY */}
    </div>
  );
}
