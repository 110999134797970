import React from 'react'
import propTypes from 'prop-types'
import Icon from 'components/atoms/Icons'
import IconClose from "components/atoms/Icons/Custom/Closes";

function Tag(props) {
  const { content, value, placeholder, disabled, onChange, name, iconName, variant } = props

  const className = [props.className]

  if (variant === 'blue') className.push('bg-blue-05 text-blue-03 hover:text-blue-02')
  if (variant === 'gray') className.push('bg-gray-05 text-gray-03 hover:text-gray-02')
  if (variant === 'green') className.push('bg-green-05 text-green-03 hover:text-green-02')
  if (variant === 'red') className.push('bg-red-05 text-red-03 hover:text-red-02')
  if (variant === 'orange') className.push('bg-orange-05 text-orange-03 hover:text-orange-02')
  if (variant === 'purple') className.push('bg-purple-05 text-purple-03 hover:text-purple-02')


  return (
    <div className="flex">
      <div className={`relative flex items-center justify-center rounded-lg py-1 px-1 ${className.join(" ")}`}>
        {content === 'icon right left' && <Icon iconName={iconName} />}
        {content === 'icon right' && <Icon iconName={iconName} />}
        <input
          name={name}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          style={{ width: '42px' }}
          className={`rounded-lg text-sm font-semibold bg-transparent text-center focus:outline-none outline-none duration-200 placeholder-current`}
        />
        {content === 'icon right left' && <IconClose className="ml-3 cursor-pointer" />}
        {content === 'icon left' && <IconClose className="ml-3 cursor-pointer" />}
      </div>
    </div>
  )
}



Tag.defaultProps = {
  variant: 'blue',
  placeholder: 'text',
  disabled: true,
  iconName: 'FaSearch',
  content: 'icon right left'
}
Tag.propTypes = {
  variant: propTypes.string,
  placeholder: propTypes.string,
  disabled: propTypes.bool,
  name: propTypes.string,
  iconName: propTypes.string,
  content: propTypes.string,
  onChange: propTypes.func
}


export default Tag