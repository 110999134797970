import React from "react";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Spinner from "components/atoms/Spinner";
import { deleteData } from "utils/fetchData";
import { fetchCustomer, setNotif } from "features/Customer/actions";
import { useDispatch } from "react-redux";
import UpdateCustomer from "./update";

export default function TableCustomer({ onClickActivasiCustomer, data }) {
  const dispatch = useDispatch();
  const [id, setId] = React.useState();
  const [showUpdate, setShowUpdate] = React.useState(false);

  const dropCustomer = async (id) => {
    await deleteData(`v2/customer/${id}`).then((res) => {
      let notif = {
        message: res.data.message,
        type: "primary",
        status: "danger",
        toggle: true,
      };
      dispatch(setNotif(notif));
      dispatch(fetchCustomer());
    });
  };

  const openUpdate = (id) => {
    setId(id);
    setShowUpdate(true);
  };
  return (
    <React.Fragment>
      <table className="w-full mt-4">
        <thead>
          <tr>
            <th>Nama pelanggan</th>
            <th>Telepon</th>
            <th>Email</th>
            <th colSpan={2}>Kelompok</th>
          </tr>
        </thead>
        <tbody>
          {data.status === "process" ? (
            <tr key="status">
              <td colSpan="4" className="text-center">
                <div className="flex items-center justify-center">
                  <Spinner size={64} color="#859DFF" />
                </div>
              </td>
            </tr>
          ) : data.data.length ? (
            data.data.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <tr>
                    <td className="text-blue-03">
                      {item?.first_name} {item?.last_name}
                    </td>
                    <td>{item?.phone === "" ? "-" : item?.phone}</td>
                    <td>{item?.email === "" ? "-" : item?.email}</td>
                    <td>{item?.customersGroup?.name}</td>
                    <td>
                      <Dropdown variant="subtle" color="blue" content="icon only">
                        <DropdownList
                          onClick={() => openUpdate(item.id)}
                          type="button"
                          color="blue"
                        >
                          Edit informasi pelanggan
                        </DropdownList>
                        {/* <DropdownList type="button" color="blue" onClick={onClickActivasiCustomer}>
                          Aktivasi pelanggan
                        </DropdownList> */}
                        <DropdownList
                          onClick={() => dropCustomer(item.id)}
                          type="button"
                          color="red"
                        >
                          Hapus pelanggan
                        </DropdownList>
                      </Dropdown>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                <span className="text-gray-02">Tidak ditemukan data</span>
              </td>
            </tr>
          )}

          {/* <tr>
          <td colSpan="5" className="bg-green-03">
            <div className="flex items-center text-white justify-center">
              <IconCheck className="mr-4" />
              Pelanggan “Sutrisno Wijaya” berhasil ditambahkan
            </div>
          </td>
        </tr> */}
        </tbody>
      </table>
      {showUpdate && (
        <UpdateCustomer id={id} show={showUpdate} close={() => setShowUpdate(false)} />
      )}
    </React.Fragment>
  );
}
