import React, { useState, useEffect } from "react";
// Component import
import Spinner from "components/atoms/Spinner";
import Banner from "components/atoms/Banner";
import Pagination from "components/atoms/Pagination";
import IconArrow from "components/atoms/Icons/Custom/icon new/Arrow";
import IconTransaction from "components/atoms/Icons/Custom/icon new/Transactions";
import IconProduct from "components/atoms/Icons/Custom/icon new/Product";
import IconDevices from "components/atoms/Icons/Custom/icon new/Devices";
import IconCustomerHappy from "components/atoms/Icons/Custom/icon new/CustomerHappy";
import IconSettings from "components/atoms/Icons/Custom/icon new/Settings";
import IconWallet from "components/atoms/Icons/Custom/icon new/Wallet";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import { InputDateRange } from "components/atoms/Form/InputDate/index";
// Redux & action import
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLog,
  setDate,
  setModule,
  setPage,
  setLimit,
  setUser as setUserRedux,
} from "features/ReportLog/actions";
// Utils import
import { formatRupiah } from "utils/formatRupiah";
import { getData } from "utils/fetchData";
// Module import
import ModalTransaction from "pages/Transaction/modal/faktur";
import ModalCustomer from "pages/Customer/update";
import ModalCustomerGroup from "pages/CustomerGroup/update";
import ModalProduct from "pages/Product/edit";
import ModalVariant from "pages/Variant/edit";
import ModalCategory from "pages/Category/edit";
import ModalDiscount from "pages/Discount/edit";

export default function PageReportNoteActivity() {
  const dispatch = useDispatch();
  // Global state
  const log = useSelector((state) => state.log);
  const [logStatus, setLogStatus] = useState(false);

  // Local state (default)
  const [user, setUser] = useState([]);
  const [userSelected, setUserSelected] = useState([]);
  const [checkAllUser, setCheckAllUser] = useState({
    label: "Semua pengguna",
    value: "all",
    isChecked: true,
  });
  const [modulLabel, setModulLabel] = useState("Semua jenis aktivitas");

  // Local state (module)
  // #1. Transaction
  const [isShowTransaction, setIsShowTransaction] = useState(false);
  const [idTransaction, setIdTransaction] = useState("");
  // #2. Customer
  const [isShowCustomer, setIsShowCustomer] = useState(false);
  const [idCustomer, setIdCustomer] = useState("");
  // #3. Customer Group
  const [isShowGroup, setIsShowGroup] = useState(false);
  const [idGroup, setIdGroup] = useState("");
  // #4. Product
  const [isShowProduct, setIsShowProduct] = useState(false);
  const [idProduct, setIdProduct] = useState("");
  // #4. Product Variant
  const [isShowVariant, setIsShowVariant] = useState(false);
  const [idVariant, setIdVariant] = useState("");
  // #4. Product Category
  const [isShowCategory, setIsShowCategory] = useState(false);
  const [idCategory, setIdCategory] = useState("");
  // #4. Product Discount
  const [isShowDiscount, setIsShowDiscount] = useState(false);
  const [idDiscount, setIdDiscount] = useState("");

  // Function filter date
  const onChangeDateCustom = (start, end) => {
    const ranges = {
      startDate: start,
      endDate: end,
      key: "selection",
    };
    dispatch(setDate(ranges));
  };

  // Function filter user
  const onFilterUser = (data) => {
    let check = [];
    setUser(
      user.map((item) => {
        if (item.id === data.id) {
          item.isChecked = !item.isChecked;
        }
        return item;
      })
    );
    user.forEach((prev) => {
      if (prev.id && prev.isChecked) {
        check.push(prev.id);
      }
    });
    dispatch(setUserRedux(check.join(",")));
    setUserSelected(check);

    if (user.length === check.length) {
      setCheckAllUser({
        id: "all",
        name: "Semua pengguna",
        isChecked: true,
      });
      dispatch(setUserRedux(""));
    } else {
      setCheckAllUser({
        id: "all",
        name: "Semua pengguna",
        isChecked: false,
      });
    }
  };

  // Function filter all user
  const onFilterAllUser = () => {
    setUser(
      user.map((item) => {
        if (checkAllUser.isChecked) {
          item.isChecked = false;
        } else {
          item.isChecked = true;
        }
        return item;
      })
    );

    setCheckAllUser({ ...checkAllUser, isChecked: !checkAllUser.isChecked });
    dispatch(setUserRedux(""));
  };

  // Function filter module
  const onFilterModule = (param) => {
    setModulLabel(param[1]);
    dispatch(setModule(param[0]));
  };

  // Function to show detail log
  const detailLog = (target, module) => {
    setLogStatus(true);
    // transaction module handler
    module === "SALES" && setIsShowTransaction(true);
    module === "SALES" && setIdTransaction(target);
    // customer module handler
    module === "CUSTOMER" && setIsShowCustomer(true);
    module === "CUSTOMER" && setIdCustomer(target);
    // customer group module handler
    module === "CUSTOMER_GROUP" && setIsShowGroup(true);
    module === "CUSTOMER_GROUP" && setIdGroup(target);
    // product module handler
    module === "PRODUCT" && setIsShowProduct(true);
    module === "PRODUCT" && setIdProduct(target);
    // product variant module handler
    module === "VARIANT" && setIsShowVariant(true);
    module === "VARIANT" && setIdVariant(target);
    // product variant module handler
    module === "CATEGORY" && setIsShowCategory(true);
    module === "CATEGORY" && setIdCategory(target);
    // product variant module handler
    module === "DISCOUNT" && setIsShowDiscount(true);
    module === "DISCOUNT" && setIdDiscount(target);
  };

  console.log("log==>");
  console.log(log);

  // fetching log effect
  useEffect(() => {
    dispatch(fetchLog());
  }, [dispatch, log.date, log.limit, log.page, log.user, log.module]);

  // fetching user effect
  useEffect(() => {
    const getUser = async () => {
      try {
        let res = await getData("v2/account/");

        let _temp = [];
        res.data.accounts.forEach((item) => {
          _temp.push({
            id: item.id,
            username: item?.username,
            email: item?.email,
            isChecked: true,
          });
        });
        setUser(_temp);
      } catch (error) {
        console.log(error.response);
      }
    };
    getUser();
  }, []);

  return (
    <>
      {/* CONTENT */}
      <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
        {log.notif?.toggle && (
          <Banner
            className="fixed top-14 z-50 left-0 right-0"
            message={log.notif.message}
            status={log.notif.status}
            type={log.notif.type}
          />
        )}
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {/* FILTER DATE */}
            <InputDateRange
              onChangeDateCostum={(start, end) => onChangeDateCustom(start, end)}
              variant="range"
              type="custom right"
              onChange={(ranges) => dispatch(setDate(ranges))}
              name="date"
              value={log.date}
            />

            {/* FILTER MODULE */}
            <div className="ml-4">
              <Dropdown
                variant="outlined"
                color="gray"
                className="mr-1"
                content="label only"
                label={modulLabel}
              >
                <DropdownList onClick={() => onFilterModule(["", "Semua jenis aktivitas"])}>
                  Semua jenis aktivitas
                </DropdownList>
                <DropdownList onClick={() => onFilterModule(["PRODUCT", "Aktivitas produk"])}>
                  <div className="flex">
                    <IconProduct fill="#0066CC" />
                    <span className="ml-3">Produk</span>
                  </div>
                </DropdownList>
                <DropdownList onClick={() => onFilterModule(["DEVICE", "Aktivitas perangkat"])}>
                  <div className="flex">
                    <IconDevices fill="#0066CC" />
                    <span className="ml-3">Perangkat</span>
                  </div>
                </DropdownList>
                <DropdownList>
                  <div
                    className="flex"
                    onClick={() => onFilterModule(["CUSTOMER", "Aktivitas pelanggan"])}
                  >
                    <IconCustomerHappy fill="#0066CC" />
                    <span className="ml-3">Pelanggan</span>
                  </div>
                </DropdownList>
                <DropdownList onClick={() => onFilterModule(["SALES", "Aktivitas transaksi"])}>
                  <div className="flex">
                    <IconTransaction fill="#0066CC" />
                    <span className="ml-3">Transaksi</span>
                  </div>
                </DropdownList>
                <DropdownList
                  onClick={() => onFilterModule(["BALANCE", "Aktivitas saldo & dana bisnis"])}
                >
                  <div className="flex">
                    <IconWallet fill="#0066CC" />
                    <span className="ml-3">Saldo & dana bisnis</span>
                  </div>
                </DropdownList>
                <DropdownList onClick={() => onFilterModule(["SETTING", "Aktivitas pengaturan"])}>
                  <div className="flex">
                    <IconSettings fill="#0066CC" />
                    <span className="ml-3">Pengaturan</span>
                  </div>
                </DropdownList>
              </Dropdown>
            </div>

            {/* FILTER USER */}
            <div className="ml-4">
              <Dropdown
                variant="outlined"
                color="gray"
                className="mr-1"
                content="label only"
                label={
                  checkAllUser.isChecked || userSelected.length === 0
                    ? "Semua pengguna"
                    : `${userSelected.length} pengguna`
                }
              >
                <DropdownList
                  onClick={() => onFilterAllUser()}
                  checked={checkAllUser.isChecked}
                  list="checkbox"
                  value="value"
                >
                  Semua pengguna
                </DropdownList>
                {user.length &&
                  user.map((item, i) => {
                    return (
                      <DropdownList
                        onClick={() => onFilterUser(item)}
                        checked={item.isChecked}
                        key={item.id}
                        list="checkbox"
                        value="value"
                      >
                        {item.email}
                      </DropdownList>
                    );
                  })}
              </Dropdown>
            </div>
          </div>
          {/* <Button variant="outlined" color="blue">
          Export laporan
        </Button> */}
        </div>

        {log.status === "process" ? (
          <div className="flex items-center justify-center mt-10">
            <Spinner size={64} color="#859DFF" />
          </div>
        ) : log.data.length ? (
          log.data.map((item) => {
            return (
              <ul key={item?.date}>
                <li>
                  <div className="grid grid-cols-6 items-start mt-10">
                    <div className="col-span-1 bg-gray-05">
                      <div className="pl-4 py-4">
                        <p className="bold-text-2 whitespace-nowrap">{item.date}</p>
                      </div>
                    </div>

                    <div className="col-span-5">
                      {item?.items?.map((child) => {
                        return (
                          <div
                            key={child.id}
                            className="grid grid-cols-5 border"
                            onClick={() => detailLog(child.target_id, child.module)}
                          >
                            <div className="col-span-4 flex items-center p-4">
                              <div className="flex items-center">
                                <div className="w-12" style={{ minWidth: "3rem" }}>
                                  {child.module === "CUSTOMER" ||
                                    child.module === "CUSTOMER_GROUP" ||
                                    child.module === "ACCOUNT" ? (
                                    <IconCustomerHappy fill="#0066CC" />
                                  ) : child.module === "PRODUCT" ||
                                    child.module === "TAX" ||
                                    child.module === "DISCOUNT" ||
                                    child.module === "VARIANT" ||
                                    child.module === "CATEGORY" ||
                                    child.module === "UNITOFMEASURE" ||
                                    child.module === "RAW_MATERIAL" ||
                                    child.module === "INVENTORY" ? (
                                    <IconProduct fill="#0066CC" />
                                  ) : child.module === "DEVICE" ||
                                    child.module === "REQUEST_PAYOUT" ? (
                                    <IconDevices fill="#0066CC" />
                                  ) : child.module === "BANK" ? (
                                    <IconWallet fill="#0066CC" />
                                  ) : child.module === "SETTING" ? (
                                    <IconSettings fill="#0066CC" />
                                  ) : (
                                    <IconTransaction fill="#0066CC" />
                                  )}
                                </div>
                                <h4 className="bold-text-2 text-dark">
                                  {child.type === "DELETE"
                                    ? "Menghapus data"
                                    : child.type === "UPDATE"
                                      ? "Perubahan"
                                      : child.type === "CREATE"
                                        ? "Penambahan"
                                        : child.type === "SIGNIN"
                                          ? "Melakukan login"
                                          : child.type === "SALES"
                                            ? "Melakukan penjualan"
                                            : child.module === "REQUEST_PAYOUT"
                                              ? "Permintaan penarikan dana"
                                              : ""}{" "}
                                  {child.type === "UPDATE" && child.module === "CUSTOMER_GROUP"
                                    ? "kelompok pelanggan"
                                    : child.type === "UPDATE" && child.module === "CUSTOMER"
                                      ? "pelanggan"
                                      : child.type === "CREATE" && child.module === "CUSTOMER_GROUP"
                                        ? "kelompok pelanggan"
                                        : child.type === "CREATE" && child.module === "CUSTOMER"
                                          ? "pelanggan"
                                          : child.module === "BALANCE"
                                            ? "saldo"
                                            : ""}{" "}
                                  <span className="text-azzure">
                                    {child.module === "BALANCE"
                                      ? formatRupiah(`${child.label}`)
                                      : `${child.label === "SUCCESS "
                                        ? `${child.target_id} Sukses`
                                        : child.label === "FAILED"
                                          ? `${child.target_id} Gagal`
                                          : child.label
                                      }`}
                                  </span>{" "}
                                </h4>
                              </div>
                            </div>
                            <div className="col-span-1 flex items-center w-full">
                              <div className="flex items-center justify-between w-full pr-4">
                                <p className="bold-text-2 text-dark">
                                  {child?.account?.accessUsername}
                                </p>
                                <IconArrow
                                  fill="#0066CC"
                                  width="12"
                                  height="12"
                                  className="transform ml-2"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </li>
              </ul>
            );
          })
        ) : (
          <div className="flex items-center justify-center mt-10">
            <span className="text-gray-02">Tidak ditemukan data</span>
          </div>
        )}

        {log.data.length > 0 ? (
          <div className="mt-5">
            <Pagination
              items={log.totalPage}
              currentPage={log.page}
              limit={log.limit}
              onPageChange={(page) => dispatch(setPage(page.selected + 1))}
              onChangePerPage={(value) => dispatch(setLimit(value))}
              label="log per halaman"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {/* MODULE  */}
      {isShowTransaction && (
        <ModalTransaction
          show={isShowTransaction}
          close={() => setIsShowTransaction(false)}
          transaction={idTransaction}
        />
      )}
      {isShowCustomer && (
        <ModalCustomer
          show={isShowCustomer}
          close={() => setIsShowCustomer(false)}
          id={idCustomer}
        />
      )}
      {isShowGroup && (
        <ModalCustomerGroup id={idGroup} close={() => setIsShowGroup(false)} show={isShowGroup} />
      )}
      {isShowProduct && (
        <ModalProduct
          title="Ubah produk"
          product={idProduct}
          show={isShowProduct}
          close={() => setIsShowProduct(false)}
          logStatus={logStatus}
        />
      )}
      {isShowVariant && (
        <ModalVariant
          show={isShowVariant}
          close={() => setIsShowVariant(false)}
          variant={idVariant}
          logStatus={logStatus}
        />
      )}
      {isShowCategory && (
        <ModalCategory
          title="Kategori produk"
          show={isShowCategory}
          close={() => setIsShowCategory(false)}
          category={idCategory}
          logStatus={logStatus}
        />
      )}
      {isShowDiscount && (
        <ModalDiscount
          title="Diskon produk"
          show={isShowDiscount}
          close={() => setIsShowDiscount(false)}
          discount={idDiscount}
          logStatus={logStatus}
        />
      )}
    </>
  );
}
