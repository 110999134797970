import {
  START_FETCHING_REPORT_TAX,
  SUCCESS_FETCHING_REPORT_TAX,
  ERROR_FETCHING_REPORT_TAX,
  SET_LOCATION,
  SET_DATE
} from "./constants";
import { userLogout } from 'features/Auth/actions'
import moment from 'moment'

import { getDatav2 } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchReport = debounce(getDatav2, 1000);

export const startFetchingReportTax = () => {
  return {
    type: START_FETCHING_REPORT_TAX,
  };
};

export const errorFetchingReportTax = () => {
  return {
    type: ERROR_FETCHING_REPORT_TAX,
  };
};

export const successFetchingReportTax = ({ chart, table, location }) => {
  return {
    type: SUCCESS_FETCHING_REPORT_TAX,
    chart,
    table,
    location
  };
};

export const fetchReportTax = () => {
  return async (dispatch, getState) => {

    dispatch(startFetchingReportTax());

    try {

      let start = moment(getState().reportTax.date.startDate).format("YYYY-MM-DD") || ''
      let end = moment(getState().reportTax.date.endDate).format("YYYY-MM-DD") || '';
      let location = getState().reportTax.location.value || '';

      const params = {
        start,
        end,
        location
      }

      let { data: { outlets }, } = await debouncedFetchReport("outlets");

      let select_box = [{ label: "Semua lokasi", value: "", }]
      outlets.forEach((item) => {
        select_box.push({
          label: item.name,
          value: item.id,
        });
      });

      const res = await debouncedFetchReport('v2/report/tax', params)

      const data = [], label = [];
      res.data.chart.forEach(chart => {
        data.push(chart.value)
        label.push(chart.name)
      })

      res.data.table.forEach(v => {
        v.amount = `${v.type === 1 ? `${v.total - v.value}`
          : `${v.total - (v.total - v.value / 100 * v.total)}`}`
      })

      dispatch(successFetchingReportTax({
        chart: {
          data,
          label
        },
        table: res.data.table,
        location: select_box
      }))

    } catch (err) {
      dispatch(errorFetchingReportTax());
    }
  };
};

export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};

export const setDate = date => {
  return {
    type: SET_DATE,
    date: date.selection ? date.selection : date
  }
}