import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import ImageField from "components/atoms/ImageField";
import TextCaption from "components/atoms/Text/TextCaption";
import SelectBox from "components/atoms/SelectBox";
import TextArea from "components/atoms/Form/InputTextArea";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ArrowRight from "components/atoms/Icons/Custom/ArrowRight";
import Toggle from "components/atoms/Toggle";
import { postData, getData, putData } from "utils/fetchData";
import { fetchCheckboxCategory } from "features/Category/actions";
import {
  fetchVariantInProduct,
  setVariant,
  setFilterListVariant,
  fetchProduct,
  setNotif,
} from "features/Product/actions";
import { useSelector, useDispatch } from "react-redux";
import CreateCategory from "pages/Category/create";
import ShowVariant from "./modalVariant";
import IconDrag from "components/atoms/Icons/Custom/icon new/Drag";

//inventory
import {
  setToggleMaterial,
  setToggleVariant,
  setProductName,
  setModalPrimary,
  setWithMaterialVariant,
  setWithoutMaterialVariant,
  setMaterialOnly,
  setVariantWithLocation,
  setVariantOnly,
  setIdStock,
  setDisabledVariant,
  createInventory,
} from "features/Stock/actions";

import { setMaterialUsed } from "features/Material/action";
import { fetchLog, setNotifLog } from "features/ReportLog/actions";

const ProductSchema = yup.object().shape({
  name: yup.string().required("nama produk tidak boleh kosong."),
});

export default function CreateProduct({ show, close, product, logStatus }) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const variant = useSelector((state) => state.product.variant);
  const inventory = useSelector((state) => state.inventory);
  // const materials = useSelector((state) => state.materials);
  // console.log("inventory");
  // console.log(inventory);
  // console.log("materials");
  // console.log(materials);

  const [isShowCreate, setIsShowCreate] = React.useState(false);
  const [isShowVariant, setIsShowVariant] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");

  //status update
  const [productOnly, setProductOnly] = React.useState("NONACTIVE");

  //inventory
  const [subscription, setSubscription] = React.useState(false);
  const [idInventory, setIdInventory] = React.useState(0);
  const [statusInventory, setStatusInventory] = React.useState(false);
  const [outlets, setOutlets] = React.useState([]);

  const [field, setField] = React.useState({
    name: "",
    code: "",
    cost: "0",
    price: "0",
    imageId: "",
    category: { value: "", label: "Pilih kategori" },
    variants: [],
    outlets: [],
    image: "",
    description: "",
    toggleInventory: "NONACTIVE",
  });
  console.log("PRODUCT FIELDS");
  console.log(field);
  const [valueCheckbox, setValueCheckbox] = React.useState({
    label: "Semua lokasi",
    value: "",
    isChecked: true,
    require: false,
  });

  const { register, handleSubmit, errors, setError, clearError } = useForm({
    mode: "onBlur",
    validationSchema: ProductSchema,
  });

  const getOutlet = async () => {
    await getData("outlets").then((res) => {
      let _temp = [];
      res.data.outlets.forEach((item) => {
        _temp.push({
          label: item.name,
          value: item.id,
        });
      });
      setOutlets(_temp);
    });
  };

  React.useEffect(() => {
    getSubscriptionStatus();
    dispatch(fetchCheckboxCategory());
    dispatch(fetchVariantInProduct());
    getOutlet();
  }, [dispatch]);

  React.useEffect(() => {
    const getOneProduct = async () => {
      const res = await getData(`v2/product/${product}`);
      console.log("res==>>>>");
      console.log(res);
      setProductOnly("ACTIVE");
      setStatusInventory(res.data.product.inventory.status);
      //start: get all variant
      let {
        data: { outlets },
      } = await getData("outlets");
      let select_box = [];
      outlets.forEach((item) => {
        select_box.push({
          label: item.name,
          value: item.id,
          isChecked: false,
        });
      });

      res.data.product.outlet.forEach((outlet) => {
        select_box.forEach((data) => {
          if (data.value === outlet.id) {
            data.isChecked = true;
          }
        });
      });

      //end: get all variant
      let checkVariant = [];
      res.data.product.variant.forEach((item) => {
        checkVariant.push({
          label: item.name,
          value: item.id,
          option: item.options,
          date: item.createdAt,
          isChecked: true,
        });
      });

      let _temp = [...variant];

      checkVariant.forEach((item) => {
        variant.forEach((data, index) => {
          if (item.value === data.value) {
            _temp[index]["isChecked"] = true;
            dispatch(setVariant(_temp));
          }
        });
      });

      let sum = 0;
      select_box.forEach((obj) => {
        if (obj.isChecked === true) sum += 1;
      });

      setValueCheckbox({
        label: sum === select_box.length ? "Semua lokasi" : `${sum} lokasi`,
        value: "sesuaikan kebutuhan",
        isChecked: sum === select_box.length ? true : false,
        require: sum === 0 ? true : false,
      });

      if (res.data.code === 200) {
        if (res.data.product.inventory.status === true) {
          setField({
            ...field,
            name: res.data.product.name,
            code: res.data.product.code,
            cost: res.data.product.cost,
            price: res.data.product.price,
            imageId: "",
            category: {
              value: res.data.product.category?.id,
              label: res.data.product.category?.name,
            },
            variants: checkVariant,
            outlets: select_box,
            image: res.data.product.image,
            description: res.data.product.description,
            toggleInventory: "ACTIVE",
          });
        } else {
          setField({
            ...field,
            name: res.data.product.name,
            code: res.data.product.code,
            cost: res.data.product.cost,
            price: res.data.product.price,
            imageId: "",
            category: {
              value: res.data.product.category?.id,
              label: res.data.product.category?.name,
            },
            variants: checkVariant,
            outlets: select_box,
            image: res.data.product.image,
            description: res.data.product.description,
            toggleInventory: "NONACTIVE",
          });
        }
        setIdInventory(res.data.product.inventory.id);
      }
    };
    getOneProduct();
  }, [product]);

  const onCheckboxVariant = (e, index) => {
    let _temp = [...variant];
    _temp[index]["isChecked"] = e.target.checked;
    dispatch(setVariant(_temp));
  };

  const filterVariant = (e) => {
    setSearch(e.target.value);
    dispatch(setFilterListVariant(e.target.value));
  };

  const onSelectVariant = () => {
    let check = [];
    variant.forEach((data) => {
      if (data.isChecked) {
        check.push(data);
      }
    });
    setField({ ...field, variants: check });
  };

  const onRemoveVariant = (data) => {
    /** start uncheckbox */
    let check = [];
    for (let i = 0; i < variant.length; i++) {
      if (variant[i].value === data.value) {
        check.push({
          ...variant[i],
          isChecked: false,
        });
      } else {
        check.push(variant[i]);
      }
    }
    dispatch(setVariant(check));

    let variants = [...field.variants];
    var removeIndex = variants
      .map(function (item) {
        return item.value;
      })
      .indexOf(data.value);
    variants.splice(removeIndex, 1);
    setField({ ...field, variants: variants });
    /** end delete variant  */
  };

  /** handle change field form */
  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const onCurrencyInput = (value, name) => {
    setField({ ...field, [name]: value });
  };

  /** handle save image */
  const onSubmidImage = async (e) => {
    if (
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      clearError("image");
      const res = await postData(`upload/single`, formData);
      setField({
        ...field,
        image: e.target.files[0],
        imageId: res.data.data.id,
      });
    } else {
      setError("image", "image", "Format gambar yang anda masukan tidak sesuai");
    }
  };

  /** handle reset image */
  const onResetImage = () => {
    clearError("image");
    setField({ ...field, image: "", imageId: "" });
  };

  const onSelectCategory = (data) => {
    if (data.value === "") {
      setError("category", "category", "kategori tidak boleh kosong.");
    } else {
      clearError("category");
    }
    setField({ ...field, category: data });
  };

  const onSelectLocation = (data) => {
    let check = [];
    for (let i = 0; i < field.outlets.length; i++) {
      if (field.outlets[i].value === data.value) {
        check.push({
          ...field.outlets[i],
          isChecked: data.isChecked ? (data.isChecked = false) : (data.isChecked = true),
        });
      } else {
        check.push(field.outlets[i]);
      }
    }
    setField({ ...field, outlets: check });
    let sum = 0;
    check.forEach((obj) => {
      if (obj.isChecked === true) sum += 1;
    });

    setValueCheckbox({
      label: sum === field.outlets.length ? "Semua lokasi" : `${sum} lokasi`,
      value: "sesuaikan kebutuhan",
      isChecked: sum === field.outlets.length ? true : false,
      require: sum === 0 ? true : false,
    });

    if (sum === 0) {
      setError("outlets", "outlets", "lokasi tidak boleh kosong.");
    } else {
      clearError("outlets");
    }
  };

  const isValidation = () => {
    if (field.outlets.length === 0) return true;
  };

  const onSubmit = async () => {
    setLoading(true);
    if (!isValidation()) {
      let outlets = [];
      let variants = [];

      field.outlets.forEach((data) => {
        if (data.isChecked === true) {
          outlets.push(data.value);
        }
      });
      let no = 0;
      field.variants.forEach((data) => {
        variants.push({
          id: data.value,
          index: (no += 1),
        });
      });

      let payload = {};
      if (field.toggleInventory === "ACTIVE" && productOnly === "NONAVTIVE") {
        if (inventory.withVariant && inventory.withMaterial) {
          let _tempSubmit = [];
          inventory.withMaterialVariant.forEach((item) => {
            if (item.rawMaterial.length !== 0) {
              _tempSubmit.push(item);
            }
          });
          payload = {
            name: field.name,
            code: field.code,
            price: Number(field.price),
            cost: Number(field.cost),
            categoryId: field.category.value,
            description: field.description,
            imageId: field.imageId,
            outlets,
            variants,
            toggleInventory: "ACTIVE",
            withVariant: inventory.withVariant,
            withMaterial: inventory.withMaterial,
            inventory: _tempSubmit,
          };
        } else if (!inventory.withVariant && !inventory.withMaterial) {
          payload = {
            name: field.name,
            code: field.code,
            price: Number(field.price),
            cost: Number(field.cost),
            categoryId: field.category.value,
            description: field.description,
            imageId: field.imageId,
            outlets,
            variants,
            toggleInventory: "ACTIVE",
            withVariant: inventory.withVariant,
            withMaterial: inventory.withMaterial,
            locations: inventory.withoutMaterialVariant,
          };
        } else if (!inventory.withVariant && inventory.withMaterial) {
          payload = {
            name: field.name,
            code: field.code,
            price: Number(field.price),
            cost: Number(field.cost),
            categoryId: field.category.value,
            description: field.description,
            imageId: field.imageId,
            outlets,
            variants,
            toggleInventory: "ACTIVE",
            withVariant: inventory.withVariant,
            withMaterial: inventory.withMaterial,
            rawMaterial: inventory.withMaterialOnly,
          };
        } else if (inventory.withVariant && !inventory.withMaterial) {
          payload = {
            name: field.name,
            code: field.code,
            price: Number(field.price),
            cost: Number(field.cost),
            categoryId: field.category.value,
            description: field.description,
            imageId: field.imageId,
            outlets,
            variants,
            toggleInventory: "ACTIVE",
            withVariant: inventory.withVariant,
            withMaterial: inventory.withMaterial,
            locations: inventory.variantWithlocation,
          };
        }
      } else {
        payload = {
          name: field.name,
          code: field.code,
          price: Number(field.price),
          cost: Number(field.cost),
          categoryId: field.category.value,
          description: field.description,
          imageId: field.imageId,
          outlets,
          variants,
          productOnly: productOnly,
        };
      }

      await putData(`v2/product/${product}`, payload)
        .then((res) => {
          if (res.data.code === 200) {
            const notif = {
              message: `Produk "${res.data.product.productName}" berhasil diubah`,
              type: "primary",
              status: "success",
              id: res.data.product.productId,
              toggle: true,
            };
            console.log("res");
            console.log(res);
            console.log("payload");
            console.log(payload);
            dispatch(setNotif(notif));
            dispatch(fetchProduct());
            logStatus && dispatch(setNotifLog(notif));
            logStatus && dispatch(fetchLog());
            dispatch(setMaterialOnly([]));
            dispatch(setWithMaterialVariant([]));
            dispatch(setToggleMaterial(false));
            dispatch(setToggleVariant(false));
            dispatch(setWithoutMaterialVariant([]));
            dispatch(setVariantOnly([]));
            dispatch(setVariantWithLocation([]));
            dispatch(setMaterialUsed([]));
            localStorage.removeItem("variantUsed");
            close();
            setField({
              ...field,
              name: "",
              code: "",
              cost: "0",
              price: "0",
              imageId: "",
              category: { value: "", label: "Pilih kategori" },
              variants: [],
              outlets: [],
              image: "",
              description: "",
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.response);
          console.log(payload);
          setLoading(false);
        });
    } else {
      setError("outlets", "outlets", "lokasi tidak boleh kosong.");
      setLoading(false);
    }
  };

  //inventory update
  const openInventory = async () => {
    setProductOnly("NONACTIVE");
    await getData(`v2/inventory/${idInventory}`).then((res) => {
      let results = res?.data?.inventory;
      dispatch(setIdStock(idInventory));
      dispatch(setToggleMaterial(results?.withMaterial));
      dispatch(setToggleVariant(results?.withVariant));
      dispatch(setProductName(results?.productName));
      dispatch(setModalPrimary("show-update-stock-product"));
      console.log("results");
      console.log(res);
      if (!results?.withMaterial && !results?.withVariant) {
        let _location = [];
        results?.locations?.forEach((items) => {
          _location.push({
            abstractId: items?.abstractId,
            outletId: items?.outletId,
            outletName: items?.outletName,
            name: items?.name,
            sku: items?.sku,
            trackStock: items?.trackStock,
            trackingStatus: items.trackingStatus,
            limitStock: items?.limitStock,
            beforeStock: items?.stock,
            changeStock: 0,
            afterStock: items?.stock,
            reasonId: 1,
            isUpdate: false,
          });
        });
        dispatch(setWithoutMaterialVariant(_location));
        dispatch(setWithMaterialVariant([]));
        dispatch(setDisabledVariant(true));
      } else if (results?.withMaterial && results?.withVariant) {
        let _variantMaterials = [];
        results?.inventory?.forEach((items) => {
          _variantMaterials.push({
            id: items?.id,
            sku: items?.sku,
            name: `${results?.productName} - ${items?.name}`,
            variantOptionId: items?.variantOptionId,
            isChecked: false,
            rawMaterial: items?.rawMaterials,
          });
        });

        let _tempVariantLocation = [];
        let _tempWithoutBoth = [];
        outlets.forEach((current, i) => {
          //without both
          _tempWithoutBoth.push({
            abstractId: null,
            outletId: current?.value,
            outletName: current?.label,
            name: results?.productName,
            sku: `${results?.productName.replace(/\s/g, "").toUpperCase()}${i + 1}`,
            trackStock: true,
            trackingStatus: true,
            limitStock: 0,
            beforeStock: 0,
            changeStock: 0,
            afterStock: 0,
            reasonId: 1,
            isUpdate: false,
          });
          //generate variant only
          let _tempVariantOnly = [];
          results.variantUsed.forEach((current) => {
            let id = [];
            let name = [];
            current?.ProductVariantOptions.forEach((item) => {
              if (item?.type === 1) {
                id.push(item?.id);
                name.push(item?.name);
              }
            });
            _tempVariantOnly.push({
              abstractId: null,
              name: `${results?.productName} - ${name.join(", ")}`,
              sku: `${results?.productName.replace(/\s/g, "").toUpperCase()}${i + 1}`,
              trackStock: true,
              trackingStatus: true,
              limitStock: 0,
              beforeStock: 0,
              changeStock: 0,
              afterStock: 0,
              reasonId: 1,
              variantOption: id,
              isUpdate: false,
            });
          });
          //generate variant with outlet
          _tempVariantLocation.push({
            outletId: current?.value,
            outletName: current?.label,
            isUpdate: false,
            variants: _tempVariantOnly,
          });
          dispatch(setVariantOnly(_tempVariantOnly));
        });

        dispatch(setVariantWithLocation(_tempVariantLocation));
        dispatch(setWithoutMaterialVariant(_tempWithoutBoth));
        dispatch(setWithMaterialVariant(_variantMaterials));
        dispatch(setDisabledVariant(false));
      } else if (results.withMaterial && !results.withVariant) {
        let _materials = [];
        results.rawMaterial.forEach((prev) => {
          _materials.push({
            id: prev?.id,
            rawMaterialId: prev?.rawMaterialId,
            name: prev?.rawMaterialName,
            value: prev?.value,
            unit: prev?.unit,
          });
        });
        dispatch(setMaterialOnly(_materials));
        dispatch(setDisabledVariant(true));
      } else if (!results.withMaterial && results.withVariant) {
        let _outlets = [];
        let _tempWithoutBoth = [];
        results.locations.forEach((item) => {
          let _variants = [];
          item?.variants?.forEach((child) => {
            _variants.push({
              abstractId: child?.abstractId,
              variantAbstractId: child?.variantAbstractId,
              limitStock: child?.limitStock,
              name: child?.name,
              sku: child?.sku,
              beforeStock: child?.stock,
              trackStock: child?.trackStock,
              trackingStatus: child?.trackingStatus,
              variantOption: child?.optionId,
              changeStock: 0,
              afterStock: child?.stock,
              reasonId: 1,
              isUpdate: false,
            });
          });
          _outlets.push({
            outletId: item?.outletId,
            outletName: item?.outletName,
            isUpdate: false,
            variants: _variants,
          });
        });
        let _variantTable = [];
        results.defaultVariant.forEach((table) => {
          _variantTable.push({
            id: table?.id,
            name: table?.name,
            sku: table?.sku,
            totalStock: table?.totalStock,
          });
        });
        dispatch(setVariantOnly(_variantTable));
        outlets.forEach((current, i) => {
          //without both
          _tempWithoutBoth.push({
            outletId: current?.value,
            outletName: current?.label,
            name: results.productName,
            sku: `${results.productName.replace(/\s/g, "").toUpperCase()}${i + 1}`,
            trackStock: true,
            trackingStatus: true,
            limitStock: 0,
            beforeStock: 0,
            changeStock: 0,
            afterStock: 0,
            reasonId: 1,
            isUpdate: false,
            abstractId: null,
          });
        });
        dispatch(setVariantWithLocation(_outlets));
        dispatch(setWithoutMaterialVariant(_tempWithoutBoth));
        dispatch(setModalPrimary("show-update-stock-product"));
      }
    });
  };

  /** handle inventory */
  const getSubscriptionStatus = async () => {
    let res = await getData("v2/subscription/status?module=INVENTORY");

    setSubscription(res.data.subscription ? res.data.subscription.status : false);
  };
  const onRoadToInventory = () => {
    setProductOnly("NONACTIVE");
    dispatch(createInventory(field));
    if (field.variants.length > 0) {
      dispatch(setToggleVariant(true));
      dispatch(setDisabledVariant(false));
    } else {
      dispatch(setDisabledVariant(true));
    }
  };

  const clearField = () => {
    dispatch(fetchProduct());
    dispatch(setMaterialUsed([]));
    setField({
      ...field,
      name: "",
      code: "",
      cost: "0",
      price: "0",
      imageId: "",
      category: { value: "", label: "Pilih kategori" },
      variants: [],
      outlets: [],
      image: "",
      description: "",
    });
    close();
  };

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={clearField}
      title="Edit produk"
      anotherAction={
        <React.Fragment>
          <span className="text-blue-700 font-semibold">{field.name}</span>

          <Button
            loading={loading}
            disabled={field.category.value === "" || field.name === "" ? true : false}
            onClick={() => (!loading ? onSubmit() : null)}
            variant="primary"
            color="blue"
          >
            Selesai edit produk
          </Button>
        </React.Fragment>
      }
    >
      <div className="container-body flex items-start justify-center bg-white">
        <div className="w-728px max-w-728px">
          <h3 className="heading-3 mb-4">Informasi produk</h3>
          <div className="w-full py-5">
            <ImageField
              spacing="w-32"
              labelStyle={{ minWidth: "8rem" }}
              type={`${errors?.image ? "error" : "default"}`}
              label="Foto produk"
              action
              value={field.image}
              name="image"
              onChange={onSubmidImage}
              onClick={() => onResetImage()}
            >
              {errors.image && <TextCaption status="error" message={errors.image?.message} />}
            </ImageField>
          </div>

          <InputTextLargeWithLabel
            spacing="w-32"
            className="w-full mt-3"
            label="Nama produk"
            status={errors?.name ? "error" : "default"}
            register={register}
            name="name"
            placeholder=""
            onChange={onChange}
            value={field.name}
          >
            {errors.name && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.name?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
          <InputTextLargeWithLabel
            className="w-full mt-3"
            spacing="w-32"
            label="Kode produk"
            status={errors?.code ? "error" : "default"}
            register={register}
            name="code"
            onChange={onChange}
            placeholder=""
            value={field.code}
          >
            {errors.code && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.code?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
          <InputTextLargeWithLabel
            className="w-full mt-3"
            spacing="w-32"
            label="Harga jual"
            status={errors?.price ? "error" : "default"}
            placeholder="Rp."
            name="price"
            currency="rp"
            onChange={onCurrencyInput}
            value={field.price}
          >
            {errors.price && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.price?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
          <InputTextLargeWithLabel
            className="w-full mt-3"
            label="Biaya"
            spacing="w-32"
            status={errors?.cost ? "error" : "default"}
            placeholder="Rp."
            name="cost"
            currency="rp"
            onChange={onCurrencyInput}
            value={field.cost}
          >
            {errors.cost && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.cost?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>

          <SelectBox
            className="mt-3"
            type="text"
            spacing="w-32"
            value={field.category}
            label="Kategori"
            options={category.checkboxs}
            onChange={onSelectCategory}
            btnAction={
              <Button
                onClick={() => setIsShowCreate(true)}
                variant="primary"
                color="green"
                className="w-full"
              >
                Tambah Kategori
              </Button>
            }
          >
            {errors.category && <TextCaption status="error" message={errors.category?.message} />}
          </SelectBox>
          {/* MODAL CREATE CATEGORY */}
          <CreateCategory
            title="Tambah kategori baru"
            show={isShowCreate}
            close={() => setIsShowCreate(false)}
            type="product"
            fetchCheckboxCategory={() => dispatch(fetchCheckboxCategory())}
          />
          {/* MODAL CREATE CATEGORY */}
          <SelectBox
            className="mt-3"
            spacing="w-32"
            type="text"
            value={valueCheckbox}
            label="Lokasi tersedia"
            options={field.outlets}
            onChange={onSelectLocation}
            type="checkbox"
          >
            {errors.outlets && <TextCaption status="error" message={errors.outlets?.message} />}
          </SelectBox>
          <TextArea
            className="mt-3"
            spacing="w-32"
            variant="default"
            rows={5}
            placeholder=""
            label="Deskripsi"
            name="description"
            value={field.description}
            onChange={onChange}
          >
            {errors.description && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.description?.message} />
              </div>
            )}
          </TextArea>

          <div className="w-full mt-20">
            <h3 className="heading-3 mb-4">Variasi produk</h3>
            <p className="text-2 text-gray-03 mt-1 mb-4">
              Tambahkan variasi seperti ukuran, rasa, dan warna.
            </p>

            <div className="p-6 w-full mt-4 bg-gray-05 rounded-lg">
              <div className="bg-white rounded-lg px-8 pt-2 pb-4">
                <ul>
                  {field.variants.map((data, i) => {
                    return (
                      <li className="flex items-center justify-between mt-4" key={i}>
                        <div className="flex items-center">
                          <IconDrag className="mr-4" />
                          <p className="text-2">
                            {data.label}
                            <span className="text-red-03">*</span>{" "}
                            <span className="text-gray-02">{data.option.length} opsi variasi)</span>
                          </p>
                        </div>
                        <div className="flex items-center">
                          <Button
                            variant="secondary"
                            color="red"
                            onClick={() => onRemoveVariant(data)}
                          >
                            Hapus
                          </Button>
                          {/* <Button variant="secondary" color="blue" className="ml-2">
                            Edit opsi
                          </Button> */}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <Button
                onClick={() => setIsShowVariant(true)}
                className="w-full bold-text-2 mt-4"
                variant="primary"
                color="green"
              >
                Pilih variasi
              </Button>
              {/*  START: SHOW MODAL VARIANT */}
              <ShowVariant
                search={search}
                filterVariant={filterVariant}
                onCheckboxVariant={(e, i) => onCheckboxVariant(e, i)}
                show={isShowVariant}
                onSelectVariant={() => onSelectVariant()}
                close={() => setIsShowVariant(false)}
              />
              {/* END: SHOW MODAL VARIANT */}
            </div>
          </div>
          {/* TOGGLE INVENTORY */}
          {subscription && field.name.length !== 0 ? (
            <div className="w-full mt-20">
              <h3 className="heading-3 mb-4">Inventaris produk</h3>
              <p className="text-2 text-gray-03 mt-1 mb-4 flex items-center">
                Hitung stok, resep, dan bahan baku untuk produk.
                {field.toggleInventory === "ACTIVE" ? (
                  <>
                    <span
                      className="text-blue-03 ml-3"
                      onClick={
                        statusInventory && field.toggleInventory === "ACTIVE"
                          ? () => openInventory()
                          : () => onRoadToInventory()
                      }
                    >
                      Klik disini untuk manajemen inventaris
                    </span>
                    <ArrowRight width="16" fill="#859DFF" className="ml-4" height="16" />
                  </>
                ) : (
                  ""
                )}
              </p>
              <div className="rounded-lg flex justify-between py-4 px-6 border border-gray-04">
                <p className="bold-text-2 text-gray-02">Hitung inventaris produk</p>
                <div className="relative right-6">
                  <Toggle
                    onChange={() => {
                      if (field.toggleInventory === "ACTIVE") {
                        setField({ ...field, toggleInventory: "NONACTIVE" });
                      } else {
                        setField({ ...field, toggleInventory: "ACTIVE" });
                      }
                    }}
                    checked={field.toggleInventory === "ACTIVE" ? true : false}
                    disabled={field.name.length === 0 ? true : false}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </ModalDialog>
  );
}
