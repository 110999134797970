import {
  START_FETCHING_CUSTOMER,
  SUCCESS_FETCHING_CUSTOMER,
  ERROR_FETCHING_CUSTOMER,
  SET_CUSTOMER,
  SET_GROUP,
  SET_KEYWORD,
  SET_CRITERIA,
  SET_ACTIVITY,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_NOTIF,
  SET_CHECKBOX,
  SET_CUSTOMER_SELECTED,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  checkbox_data: [],
  selected: [],
  review: [],
  sales: [],
  page: 1,
  limit: 10,
  totalPage: 1,
  customer: "",
  keyword: "",
  group: "",
  criteria: "",
  status: statuslist.idle,
  notif: {
    message: "",
    type: "",
    status: "",
    toggle: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_CUSTOMER:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_CUSTOMER:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_CUSTOMER:
      return {
        ...state,
        status: statuslist.success,
        data: action.customers,
      };

    case SET_NOTIF:
      return { ...state, notif: action.notif };

    case SET_CUSTOMER:
      return { ...state, customer: action.customer };

    case SET_CHECKBOX:
      return { ...state, checkbox_data: action.checkbox_data };

    case SET_CUSTOMER_SELECTED:
      return { ...state, selected: action.selected };

    case SET_KEYWORD:
      return { ...state, keyword: action.keyword };

    case SET_GROUP:
      return { ...state, group: action.group };

    case SET_CRITERIA:
      return { ...state, criteria: action.criteria };

    case SET_ACTIVITY:
      return { ...state, review: action.review, sales: action.sales };

    case SET_LIMIT:
      return {
        ...state,
        limit: action.limit,
      };

    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case SET_TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.totalPage,
      };

    default:
      return state;
  }
}
