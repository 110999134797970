import * as React from "react";
import Button from "components/atoms/Button";
import TextCaption from "components/atoms/Text/TextCaption";
import { InputGroupSmall } from "components/atoms/Form";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { config } from "../../config";
import axios from "axios";
import * as yup from "yup";
import { useHistory } from "react-router-dom";

const ResetSchema = yup.object().shape({
  password: yup.string().required("passwod tidak cocok"),
  passwordConfirmation: yup.string().required("passwod tidak cocok"),
});

function ResetPassword() {
  const history = useHistory();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const param = useLocation();
  const email = param.pathname.split("/");
  const token = param.search.split("=");
  const [field, setField] = React.useState({
    new_password: "",
    retype_password: "",
  });

  const { register, errors, handleSubmit, setError } = useForm({
    mode: "onSubmit",
    validationSchema: ResetSchema,
  });

  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  console.log(errors);
  const onSubmit = async () => {
    setLoading(true);
    let payload = {
      password: field.new_password,
      passwordConfirmation: field.retype_password,
      token: token[1],
    };
    await axios
      .put(`${config.api_host}/v2/auth/forgotpassword`, payload, {
        headers: { "x-access-token": token[1] },
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          localStorage.setItem("email_user", res.data.account);
          history.push(`/forgot/redirect`);
          setLoading(false);
        } else if (res.data.code === 402) {
          alert(
            "kata sandi ini pernah digunakan, gunakan kata sandi lain untuk keamanan akun anda"
          );
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 422) {
          err.response.data.forEach((item) => {
            setError(item.param, item.param, item.message);
          });
          setLoading(false);
        }
        setLoading(false);
      });

    setLoading(false);
  };

  return (
    <div className="flex justify-center bg-gray-05 h-screen">
      <div className="rounded-xl bg-white px-10 self-center w-2/5 h-2/3 shadow-gray">
        <div className="mb-16 mt-10 text-center">
          <span>Masukan kata sandi yang baru untuk email</span> <br />
          <span className="text-blue-03">{email[3]}</span>
        </div>
        {/* <form onSubmit={handleSubmit(onSubmit)}>
        </form> */}
        <InputGroupSmall
          className={"focus:border-blue-03 w-full mb-1 mt-2"}
          placeholder="Kata sandi baru"
          register={register}
          status={errors?.password ? "error" : "default"}
          value={field.new_password}
          onChange={onChange}
          name="new_password"
          content="icon"
          type={showPassword ? "text" : "password"}
          onClick={() => setShowPassword(!showPassword)}
          iconName={showPassword ? "UilEyeSlash" : "UilEye"}
        />
        {errors.password && (
          <div className="flex items-center mt-1">
            <TextCaption status="error" message={errors.password?.message} />
          </div>
        )}
        <InputGroupSmall
          className={"focus:border-blue-03 w-full mb-1 mt-2"}
          type="text"
          placeholder="Masukan ulang sandi"
          register={register}
          status={errors?.passwordConfirmation ? "error" : "default"}
          value={field.retype_password}
          onChange={onChange}
          name="retype_password"
          content="icon"
          type={showPassword2 ? "text" : "password"}
          onClick={() => setShowPassword2(!showPassword2)}
          iconName={showPassword2 ? "UilEyeSlash" : "UilEye"}
        />
        {errors.passwordConfirmation && (
          <div className="flex items-center mt-1">
            <TextCaption status="error" message={errors.passwordConfirmation?.message} />
          </div>
        )}

        <Button
          loading={loading}
          onClick={onSubmit}
          disabled={field.new_password.length && field.retype_password.length ? false : true}
          className="mb-3 mt-10 w-full"
          type="submit"
          variant="primary"
          color="blue"
          isBlock
        >
          Reset kata sandi
        </Button>
      </div>
    </div>
  );
}

export default ResetPassword;
