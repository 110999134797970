//fetching data
export const START_FETCHING_MATERIAL = "features/Material/START_FETCHING_MATERIAL";
export const ERROR_FETCHING_MATERIAL = "features/Material/ERROR_FETCHING_MATERIAL";
export const SUCCESS_FETCHING_MATERIAL = "features/Material/SUCCESS_FETCHING_MATERIAL";

//show data & filter
export const SET_DATA = "features/Material/SET_DATA";
export const SET_KEYWORD = "features/Material/SET_KEYWORD";
export const SET_LOCATION = "features/Material/SET_LOCATION";
export const SET_PAGE = "features/Material/SET_PAGE";
export const SET_LIMIT = "features/Material/SET_LIMIT";
export const SET_TOTAL_PAGE = "features/Material/SET_TOTAL_PAGE";

//message & modal
export const SET_MODAL = "features/Material/SET_MODAL";
export const SET_MODAL_SECONDARY = "features/Material/SET_MODAL_SECONDARY";
export const SET_BANNER = "features/Material/SET_BANNER";
export const SET_ERROR = "features/Material/SET_ERROR";
export const SET_MESSAGE = "features/Material/SET_MESSAGE";

//another action
export const SET_STOCKS = "features/Material/SET_STOCKS";
export const SET_OUTLET = "features/Material/SET_OUTLET";
export const SET_OUTLET_SELECTED = "features/Material/SET_OUTLET_SELECTED";
export const SET_ALL_SELECTED = "features/Material/SET_ALL_SELECTED";
export const SET_REASON = "features/Material/SET_REASON";

//form data
export const SET_ID = "features/Material/SET_ID";
export const SET_NAME = "features/Material/SET_NAME";
export const SET_MEASURE_ID = "features/Material/SET_MEASURE_ID";
export const SET_MEASURE_NAME = "features/Material/SET_MEASURE_NAME";
export const SET_MEASURE_UNIT = "features/Material/SET_MEASURE_UNIT";

//material to stock
export const SET_MATERIAL_USED = "features/Material/SET_MATERIAL_USED";
export const SET_MATERIAL_CHECKBOX = "features/Material/SET_MATERIAL_CHECKBOX";
