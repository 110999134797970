import React from 'react'
import propTypes from 'prop-types'
import IconPlus from "components/atoms/Icons/Custom/Plus";
import IconMinus from "components/atoms/Icons/Custom/Minus";

function Stepper(props) {
  const { items, onItemInc, onItemDec } = props
  return (
    <div className="relative h-7 w-28 flex items-center justify-between border-2 border-blue-03 rounded-lg overflow-hidden">
      <button
        className="w-7 h-8 bg-blue-03 hover:bg-blue-02 hov flex items-center justify-center outline-none focus:outline-none"
        onClick={onItemInc}
      >
        <IconMinus />
      </button>
      {items}

      <button
        className="w-7 h-8 bg-blue-03 hover:bg-blue-02 flex items-center justify-center outline-none focus:outline-none"
        onClick={onItemDec}
      >
        <IconPlus />
      </button>
    </div>
  )
}

Stepper.propTypes = {
  items: propTypes.number,
  onItemInc: propTypes.func,
  onItemDec: propTypes.func
}

export default Stepper