import * as React from "react";
import IconClose from "components/atoms/Icons/Custom/Closes";
import Button from "components/atoms/Button";
import "./transferStock.css";
import SelectBox from "components/atoms/SelectBox";
import SearcBar from "components/atoms/SearchBar";
import Table from "./tableTransferStock";
import ModalDialog from "components/molecules/Modal";
import ModalCategory from "./pilihCategory";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchLocation,
  fetchProductInTransferStock,
  setCategoryInTransferStock,
} from "features/TransferStock/actions";
import { setNotif } from "features/Stock/actions";
import { useForm } from "react-hook-form";
import TextCaption from "components/atoms/Text/TextCaption";
import Checkbox from "components/atoms/Checkbox";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import { putData } from "utils/fetchData";
import { useHistory } from "react-router-dom";

export default function PageTransferStock() {
  const history = useHistory();
  const dispatch = useDispatch();
  const transferStock = useSelector((state) => state.transferStock);
  const [modalCategory, setModalCategory] = React.useState(false);
  const [modalBatal, setModalBatal] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const [dropdown, setDropdown] = useDetectOutsideClick(dropdownRef, false);
  const [keyword, setKeyword] = React.useState("");
  const [list, setList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const { errors, setError, clearError } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async () => {
    try {
      setLoading(true);
      let payload = {
        data: [],
      };
      field.transferStock.forEach((data) => {
        if (data.stock !== 0) {
          payload.data.push({
            abstractId: data.abstractId,
            targetId: data.targetId,
            beforeStock: data.beforeStock,
            afterStock: data.beforeStock - data.stock,
          });
        }
      });

      const res = await putData(`v2/inventory/transfer`, payload);

      if (res.data.code === 200) {
        const notif = {
          message: `Transfer stok telah berhasil`,
          type: "primary",
          status: "success",
          toggle: true,
        };
        dispatch(setNotif(notif));
        history.push("/product/inventory/default");
      }
    } catch (err) {
      alert("Internal server error");
    }
  };

  const [labelFilterCategory, setLabelFilterCategory] = React.useState({
    label: "Pilih berdasarkan kategori",
    value: "",
  });

  const [field, setField] = React.useState({
    source: { value: "", label: "Pilih lokasi" },
    destination: { value: "", label: "Pilih lokasi" },
    transferStock: [],
  });

  const onCheckboxCategory = (e, index) => {
    let _temp = [...transferStock.category];
    _temp[index]["isCheck"] = e.target.checked;
    dispatch(setCategoryInTransferStock(_temp));
  };

  const onSelectCategory = (all = false) => {
    let reset = [];
    list.forEach((data) => {
      data.isCheck = false;
      reset.push(data);
    });
    setList(reset);

    let check = [],
      sum = 0;
    if (!all) {
      transferStock.category.forEach((data) => {
        if (data.isCheck === true) {
          sum += 1;
          transferStock.list.forEach((list) => {
            if (data.categoryId === list.categoryId) {
              check.push(list);
            }
          });
        }
      });
      setModalCategory(false);
    } else {
      transferStock.list.forEach((list) => {
        check.push(list);
      });
      let _temp = [...transferStock.category];
      _temp.forEach((data) => {
        data.isCheck = true;
      });
      dispatch(setCategoryInTransferStock(_temp));
    }

    setField({ ...field, transferStock: check });
  };

  const onChange = (e, index) => {
    let _temp = [...field.transferStock];

    let { value, min, max, name } = e.target;

    if (Number(value) === 0) {
      _temp[index][name] = 1;
    } else if (Number(value) > Number(max)) {
      _temp[index][name] = _temp[index]["stock"];
      setError("max", "max", "jumlah tranfer tidak boleh lebih dari stok sumber");
    } else if (Number(value) < Number(min)) {
      _temp[index][name] = 1;
      setError("mix", "mix", "jumlah tranfer tidak boleh kurang dari stok sumber");
    } else {
      _temp[index][name] = value;
    }

    setField({ ...field, transferStock: _temp });
  };

  const onDelete = (data) => {
    let transferStock = [...field.transferStock];
    let myArray = transferStock.filter(function (obj) {
      return obj.stockId !== data.stockId;
    });

    setField({ ...field, transferStock: myArray });
  };

  React.useEffect(() => {
    dispatch(fetchLocation());
  }, []);

  const onSelectLocationSource = (data) => {
    if (data.value === "") {
      setError("source", "source", "lokasi sumber tidak boleh kosong.");
    } else if (data.value === field.destination.value) {
      setError("source", "source", "lokasi sumber tidak boleh sama dengan lokasi tujuan.");
      dispatch(setCategoryInTransferStock([]));
    } else {
      clearError("source");
      clearError("destination");
      dispatch(fetchProductInTransferStock(data.value, field.destination.value));
    }
    let _temp = [...transferStock.category];
    _temp.forEach((data) => {
      data.isCheck = false;
    });
    dispatch(setCategoryInTransferStock(_temp));
    setField({ ...field, source: data, transferStock: [] });
  };

  const onSelectLocationDestination = (data) => {
    if (data.value === "") {
      setError("destination", "destination", "lokasi tujaun tidak boleh kosong.");
    } else if (data.value === field.source.value) {
      setError(
        "destination",
        "destination",
        "lokasi tujuan tidak boleh sama dengan lokasi sumber."
      );
      dispatch(setCategoryInTransferStock([]));
    } else {
      clearError("source");
      clearError("destination");
      dispatch(fetchProductInTransferStock(field.source.value, data.value));
    }
    let _temp = [...transferStock.category];
    _temp.forEach((data) => {
      data.isCheck = false;
    });
    dispatch(setCategoryInTransferStock(_temp));
    setField({ ...field, destination: data, transferStock: [] });
  };

  const filterByProduct = (e) => {
    let check = [...transferStock.list];
    const search = e.target.value.toLowerCase();
    check = check.filter(function (product) {
      return product.name.toLowerCase().indexOf(search) !== -1;
    });
    setKeyword(e.target.value);
    setList(check);
  };

  const handleOptionCheckbox = (e, index) => {
    const check = [...list];
    check[index]["isCheck"] = e.target.checked;
    setList(check);
    if (e.target.checked) {
      let product = [...field.transferStock];
      let plus = field.transferStock.filter((data) => data.stockId === check[index].stockId);
      if (plus.length === 0) product.push(check[index]);
      setField({ ...field, transferStock: product });
    } else {
      let minus = field.transferStock.filter((data) => data.stockId !== check[index].stockId);
      setField({ ...field, transferStock: minus });
    }
  };

  return (
    <React.Fragment>
      {/* head */}
      <div className="flex items-center justify-between py-4 px-6 shadow-HeavyDropShadow border-b">
        <div className="flex items-center">
          <div
            className="btn-icons mr-4 text-gray-03 hover:text-gray-02 active:text-gray-01 bg-gray-05 p-2"
            onClick={() => setModalBatal(true)}
          >
            <IconClose fill={"currentColor"} />
          </div>
          <p className="heading-3">Transfer stok</p>
        </div>
        <Button
          loading={loading ? true : false}
          onClick={!loading ? onSubmit : null}
          variant="primary"
          color="blue"
          disabled={field.transferStock.length === 0 ? true : false}
        >
          <p className="bold-text-2">Selesai transfer stok</p>
        </Button>
      </div>
      {/* body */}
      <div
        className="flex items-start justify-center bg-white overflow-y-scroll"
        style={{ height: "84vh" }}
      >
        <div className="w-728px max-w-728px">
          <div className="transfer-stock">
            <h3 className="heading-3 mb-4 mt-10">Informasi produk</h3>
            <SelectBox
              className="mt-3"
              type="text"
              spacing="w-32"
              value={field.source}
              label="Lokasi number"
              onChange={onSelectLocationSource}
              options={transferStock.locations}
            >
              {errors.source && <TextCaption status="error" message={errors.source?.message} />}
            </SelectBox>
            <SelectBox
              className="mt-3"
              type="text"
              spacing="w-32"
              value={field.destination}
              label="Lokasi tujuan"
              onChange={onSelectLocationDestination}
              options={transferStock.locations}
            >
              {errors.destination && (
                <TextCaption status="error" message={errors.destination?.message} />
              )}
            </SelectBox>
          </div>

          <h3 className="heading-3 mb-4 mt-10">Stok untuk ditransfer</h3>
          <div className="flex items-center w-full justify-between">
            <div className="relative w-full">
              <SearcBar
                value={keyword}
                name="keyword"
                onChange={(e) => {
                  filterByProduct(e);
                  setDropdown(true);
                }}
                disabled={
                  field.source.value !== "" && field.destination.value !== "" ? false : true
                }
                placeholder="Cari produk"
              />
              {dropdown && (
                <ul
                  ref={dropdownRef}
                  className="w-full absolute top-10 z-50 left-0 item-list shadow-HeavyDropShadow overflow-hidden bg-white rounded-lg mt-3 mb-11"
                >
                  {list.map((list, i) => {
                    return (
                      <li
                        key={i}
                        className={`justify-between flex  cursor-pointer hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm`}
                      >
                        <Checkbox
                          type="default"
                          checked={list.isCheck}
                          name="checkbox"
                          onChange={(e) => handleOptionCheckbox(e, i)}
                          label={list.name}
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <Button
              variant="secondary"
              color="blue"
              className="ml-2"
              disabled={field.source.value !== "" && field.destination.value !== "" ? false : true}
              onClick={() => setModalCategory(true)}
            >
              <p className="whitespace-nowrap">{labelFilterCategory.label}</p>
            </Button>
            <Button
              disabled={field.source.value !== "" && field.destination.value !== "" ? false : true}
              variant="secondary"
              color="blue"
              className="ml-2"
              onClick={() => onSelectCategory(true)}
            >
              <p className="whitespace-nowrap">Semua produk</p>
            </Button>
          </div>
          <div className="table-transfer-stock">
            <Table
              className="mt-6"
              data={field.transferStock}
              onChange={onChange}
              onDelete={onDelete}
            />
          </div>
        </div>
      </div>
      {/* modal */}
      <ModalCategory
        show={modalCategory}
        data={transferStock.category}
        close={() => setModalCategory(false)}
        onCheckboxCategory={(e, i) => onCheckboxCategory(e, i)}
        onSelectCategory={() => onSelectCategory()}
      />

      {/* START: ALERT BATAL */}
      <ModalDialog
        show={modalBatal}
        onClose={() => setModalBatal(false)}
        size={"alert"}
        title={<h1 className="text-dark heading-3">Batal transfer stok?</h1>}
      >
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p className="text-2">
            Semua perubahan dan akan hilang saat Anda keluar sebelum menyelesaikan transfer stok.
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
            onClick={() => setModalBatal(false)}
          >
            Kembali transfer stok
          </Button>
          <Button
            onClick={() => history.push("/product/inventory/default")}
            variant="primary"
            color="red"
            className="my-5"
            type="button"
          >
            Batal dan buang perubahan
          </Button>
        </div>
      </ModalDialog>
      {/* END: ALERT BATAL */}
    </React.Fragment>
  );
}
