import * as React from "react";
import propTypes from "prop-types";
import Icon from "components/atoms/Icons/indexV2";

function SearchBar(props) {
  const {
    value,
    name,
    placeholder,
    onClick,
    onChange,
    disabled,
    position,
    zIndex,
  } = props;
  const className = [props.className];
  return (
    <div
      className={`relative w-full ${
        disabled ? "border-gray-04" : "hover:text-blue-03 focus:text-blue-03"
      } text-gray-03  h-max-content ${className.join(" ")}`}
    >
      <div
        className={`absolute transform top-1/2 ${zIndex} -translate-y-1/2 left-5 duration-500`}
      >
        <Icon iconName="UilSearch" iconSize={10} />
      </div>
      <input
        className={`text-field-small border w-full pl-12 ${position} ${
          disabled ? "" : "hover:border-blue-03 focus:border-blue-03"
        }  text-black`}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
      />
      <div
        className="absolute transform top-1/2 -translate-y-1/2 right-5"
        onClick={onClick}
      >
        <Icon iconName="UilMultiply" iconSize={10} />
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  value: propTypes.string,
  disabled: propTypes.bool,
  name: propTypes.string,
  placeholder: propTypes.string,
  onChange: propTypes.func,
  onClick: propTypes.func,
};

export default SearchBar;
