import {
  START_FETCHING_SEARCH,
  ERROR_FETCHING_SEARCH,
  SUCCESS_FETCHING_SEARCH,
  SET_KEYWORD,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  results: [],
  keyword: "",
  status: statuslist.idle,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_SEARCH:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_SEARCH:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_SEARCH:
      return {
        ...state,
        status: statuslist.success,
        results: action.results,
      };

    case SET_KEYWORD:
      return { ...state, keyword: action.keyword };

    default:
      return state;
  }
}
