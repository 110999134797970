import React from "react";

export default function Check({ className, width, fill, height }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.81333 4.86001L5.95333 7.72668L4.85333 6.62668C4.79357 6.55689 4.72002 6.50021 4.63731 6.46019C4.5546 6.42018 4.46451 6.39769 4.3727 6.39415C4.28089 6.3906 4.18933 6.40607 4.10378 6.43959C4.01823 6.4731 3.94053 6.52394 3.87556 6.58891C3.81059 6.65388 3.75976 6.73158 3.72624 6.81713C3.69272 6.90268 3.67725 6.99423 3.6808 7.08605C3.68435 7.17786 3.70683 7.26795 3.74685 7.35066C3.78686 7.43337 3.84354 7.50691 3.91333 7.56668L5.48 9.14001C5.54229 9.2018 5.61617 9.25068 5.69739 9.28386C5.77862 9.31703 5.86559 9.33385 5.95333 9.33334C6.12822 9.33261 6.29582 9.26317 6.42 9.14001L9.75333 5.80668C9.81582 5.7447 9.86541 5.67097 9.89926 5.58973C9.9331 5.50849 9.95053 5.42135 9.95053 5.33334C9.95053 5.24534 9.9331 5.1582 9.89926 5.07696C9.86541 4.99572 9.81582 4.92199 9.75333 4.86001C9.62842 4.73584 9.45945 4.66615 9.28333 4.66615C9.10721 4.66615 8.93824 4.73584 8.81333 4.86001ZM7 0.333344C5.68146 0.333344 4.39252 0.724337 3.2962 1.45688C2.19987 2.18942 1.34539 3.23061 0.840801 4.44879C0.336217 5.66696 0.204195 7.00741 0.461429 8.30061C0.718664 9.59382 1.3536 10.7817 2.28595 11.7141C3.2183 12.6464 4.40619 13.2813 5.6994 13.5386C6.9926 13.7958 8.33305 13.6638 9.55122 13.1592C10.7694 12.6546 11.8106 11.8001 12.5431 10.7038C13.2757 9.60748 13.6667 8.31855 13.6667 7.00001C13.6667 6.12453 13.4942 5.25762 13.1592 4.44879C12.8242 3.63995 12.3331 2.90502 11.714 2.28596C11.095 1.66691 10.3601 1.17584 9.55122 0.840813C8.74238 0.505782 7.87548 0.333344 7 0.333344ZM7 12.3333C5.94516 12.3333 4.91402 12.0205 4.03696 11.4345C3.15989 10.8485 2.47631 10.0155 2.07264 9.04099C1.66897 8.06645 1.56336 6.99409 1.76914 5.95953C1.97493 4.92496 2.48288 3.97465 3.22876 3.22877C3.97464 2.48289 4.92495 1.97494 5.95952 1.76916C6.99408 1.56337 8.06644 1.66899 9.04098 2.07265C10.0155 2.47632 10.8485 3.15991 11.4345 4.03697C12.0205 4.91403 12.3333 5.94518 12.3333 7.00001C12.3333 8.4145 11.7714 9.77105 10.7712 10.7712C9.77104 11.7714 8.41449 12.3333 7 12.3333Z"
        fill={fill}
      />
    </svg>
  );
}

Check.defaultProps = {
  width: "13.33px",
  height: "13.33px",
  fill: "gray",
};
