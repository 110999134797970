import * as React from "react";
import Button from "components/atoms/Button";
import TextCaption from "components/atoms/Text/TextCaption";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextSmall";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postData } from "utils/fetchData";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { config } from "../../config";
import axios from "axios";

function ForgotPassword() {
  let history = useHistory();
  const [email, setEmail] = React.useState("");

  const EmailSchema = yup.object().shape({
    email: yup.string().required("email tidak boleh kosong"),
  });
  const { register, errors, handleSubmit, setError } = useForm({
    mode: "onSubmit",
    validationSchema: EmailSchema,
  });
  console.log("errors====>");
  console.log(errors);

  const [loading, setLoading] = React.useState(false);

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = async () => {
    setLoading(true);
    let payload = {
      email: email,
    };
    try {
      let res = await axios.post(`${config.api_host}/v2/auth/forgotpassword`, payload);
      console.log(res);
      if (res.status === 200) {
        setLoading(false);
        localStorage.setItem("reset_password", email);
        history.push("/forgot/finish");
      }
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 422) {
        error.response.data.forEach((item) => {
          setError(item.param, item.param, item.message);
        });
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex justify-center bg-gray-05 h-screen">
      <div className="rounded-xl bg-white px-10 self-center h-2/4 shadow-gray">
        <div className="mb-10 mt-10 text-center">
          <span>Masukkan email anda yang terdaftar untuk</span> <br />
          <span>mengatur kembali kata sandi akun Anda</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputTextLargeWithLabel
            type="email"
            className={"focus:border-blue-03 w-full mb-1"}
            name="email"
            placeholder="Email"
            register={register}
            status={errors?.email ? "error" : "default"}
            value={email}
            onChange={onChange}
          />
          {errors.email && (
            <div className="flex items-center mb-2">
              <TextCaption status="error" message={errors.email?.message} />
            </div>
          )}

          <Button
            loading={loading}
            onClick={onSubmit}
            disabled={email.length ? false : true}
            className="mb-3 mt-10 w-full"
            type="submit"
            variant="primary"
            color="blue"
            isBlock
          >
            Reset
          </Button>
          <Link to="/logout">
            <Button className="text-center" variant="primary" color="white" isBlock>
              Kembali ke login
            </Button>
          </Link>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
