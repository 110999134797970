import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import ImageField from "components/atoms/ImageField";
import TextCaption from "components/atoms/Text/TextCaption";
import SelectBox from "components/atoms/SelectBox";
import TextArea from "components/atoms/Form/InputTextArea";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ArrowRight from "components/atoms/Icons/Custom/ArrowRight";
import Toggle from "components/atoms/Toggle";
import { postData, getData } from "utils/fetchData";
import { fetchCheckboxCategory } from "features/Category/actions";
import {
  fetchVariantInProduct,
  setVariant,
  setFilterListVariant,
  fetchProduct,
  setNotif,
} from "features/Product/actions";
import {
  createInventory,
  setToggleVariant,
  setDisabledVariant,
  setModalPrimary,
  setMaterialOnly,
  setWithMaterialVariant,
  setToggleMaterial,
  setVariantWithLocation,
  setVariantOnly,
  setWithoutMaterialVariant,
} from "features/Stock/actions";
import { setCheckboxData, setMaterialUsed } from "features/Material/action";
import { useSelector, useDispatch } from "react-redux";
import CreateCategory from "pages/Category/create";
import ShowVariant from "./modalVariant";
import IconDrag from "components/atoms/Icons/Custom/icon new/Drag";
import "./createProduct.css";
const ProductSchema = yup.object().shape({
  name: yup.string().required("nama produk tidak boleh kosong."),
});

export default function CreateProduct({ show, close }) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const variant = useSelector((state) => state.product.variant);
  const inventory = useSelector((state) => state.inventory);

  //subs status
  const [subscription, setSubscription] = React.useState(false);

  const [isShowCreate, setIsShowCreate] = React.useState(false);
  const [isShowVariant, setIsShowVariant] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const [field, setField] = React.useState({
    name: "",
    code: "",
    cost: "0",
    price: "0",
    imageId: "",
    category: { value: "", label: "Pilih kategori" },
    variants: [],
    outlets: [],
    image: "",
    description: "",
    toggleInventory: "NONACTIVE",
  });
  const [valueCheckbox, setValueCheckbox] = React.useState({
    label: "Semua lokasi",
    value: "",
    isChecked: true,
    require: false,
  });

  const { register, handleSubmit, errors, setError, clearError } = useForm({
    mode: "onBlur",
    validationSchema: ProductSchema,
  });

  const getLocation = async () => {
    let {
      data: { outlets },
    } = await getData("outlets");
    let select_box = [];
    outlets.forEach((item) => {
      select_box.push({
        label: item.name,
        value: item.id,
        isChecked: true,
      });
    });
    setField({
      ...field,
      name: "",
      code: "",
      cost: "0",
      price: "0",
      imageId: "",
      category: { value: "", label: "Pilih kategori" },
      variants: [],
      image: "",
      description: "",
      outlets: select_box,
    });
  };

  React.useEffect(() => {
    dispatch(fetchCheckboxCategory());
    dispatch(fetchVariantInProduct());
    getLocation();
    getSubscriptionStatus();
  }, [dispatch]);

  const onCheckboxVariant = (e, index) => {
    let _temp = [...variant];
    _temp[index]["isChecked"] = e.target.checked;
    dispatch(setVariant(_temp));
  };

  const filterVariant = (e) => {
    setSearch(e.target.value);
    dispatch(setFilterListVariant(e.target.value));
  };

  const onSelectVariant = () => {
    let check = [];
    variant.forEach((data) => {
      if (data.isChecked) {
        check.push(data);
      }
    });
    setField({ ...field, variants: check });
  };

  const onRemoveVariant = (data) => {
    /** start uncheckbox */
    let check = [];
    for (let i = 0; i < variant.length; i++) {
      if (variant[i].value === data.value) {
        check.push({
          ...variant[i],
          isChecked: false,
        });
      } else {
        check.push(variant[i]);
      }
    }
    dispatch(setVariant(check));

    let variants = [...field.variants];
    var removeIndex = variants
      .map(function (item) {
        return item.value;
      })
      .indexOf(data.value);
    variants.splice(removeIndex, 1);
    setField({ ...field, variants: variants });
    /** end delete variant  */
  };

  /** handle change field form */
  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const onCurrencyInput = (value, name) => {
    setField({ ...field, [name]: value });
  };

  /** handle save image */
  const onSubmidImage = async (e) => {
    if (
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      clearError("image");
      const res = await postData(`upload/single`, formData);
      setField({
        ...field,
        image: e.target.files[0],
        imageId: res.data.data.id,
      });
    } else {
      setError("image", "image", "Format gambar yang anda masukan tidak sesuai");
    }
  };

  /** handle reset image */
  const onResetImage = () => {
    clearError("image");
    setField({ ...field, image: "", imageId: "" });
  };

  const onSelectCategory = (data) => {
    if (data.value === "") {
      setError("category", "category", "kategori tidak boleh kosong.");
    } else {
      clearError("category");
    }
    setField({ ...field, category: data });
  };

  const onSelectLocation = (data) => {
    let check = [];
    for (let i = 0; i < field.outlets.length; i++) {
      if (field.outlets[i].value === data.value) {
        check.push({
          ...field.outlets[i],
          isChecked: data.isChecked ? (data.isChecked = false) : (data.isChecked = true),
        });
      } else {
        check.push(field.outlets[i]);
      }
    }
    setField({ ...field, outlets: check });
    let sum = 0;
    check.forEach((obj) => {
      if (obj.isChecked === true) sum += 1;
    });

    setValueCheckbox({
      label: sum === field.outlets.length ? "Semua lokasi" : `${sum} lokasi`,
      value: "sesuaikan kebutuhan",
      isChecked: sum === field.outlets.length ? true : false,
      require: sum === 0 ? true : false,
    });

    if (sum === 0) {
      setError("outlets", "outlets", "lokasi tidak boleh kosong.");
    } else {
      clearError("outlets");
    }
  };

  const isValidation = () => {
    if (field.outlets.length === 0) return true
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      if (!isValidation()) {
        let outlets = [];
        let variants = [];

        field.outlets.forEach((data) => {
          if (data.isChecked === true) {
            outlets.push(data.value);
          }
        });
        let no = 0;
        field.variants.forEach((data) => {
          variants.push({
            id: data.value,
            index: (no += 1),
          });
        });

        let payload = {};
        if (field.toggleInventory === "ACTIVE") {
          if (inventory.withVariant && inventory.withMaterial) {
            let _tempSubmit = [];
            inventory.withMaterialVariant.forEach((item) => {
              if (item.rawMaterial.length !== 0) {
                _tempSubmit.push(item);
              }
            });
            payload = {
              name: field.name,
              code: field.code,
              price: Number(field.price),
              cost: Number(field.cost),
              categoryId: field.category.value,
              description: field.description,
              imageId: field.imageId,
              outlets,
              variants,
              toggleInventory: "ACTIVE",
              withVariant: inventory.withVariant,
              withMaterial: inventory.withMaterial,
              inventory: _tempSubmit,
            };
          } else if (!inventory.withVariant && !inventory.withMaterial) {
            payload = {
              name: field.name,
              code: field.code,
              price: Number(field.price),
              cost: Number(field.cost),
              categoryId: field.category.value,
              description: field.description,
              imageId: field.imageId,
              outlets,
              variants,
              toggleInventory: "ACTIVE",
              withVariant: inventory.withVariant,
              withMaterial: inventory.withMaterial,
              locations: inventory.withoutMaterialVariant,
            };
          } else if (!inventory.withVariant && inventory.withMaterial) {
            payload = {
              name: field.name,
              code: field.code,
              price: Number(field.price),
              cost: Number(field.cost),
              categoryId: field.category.value,
              description: field.description,
              imageId: field.imageId,
              outlets,
              variants,
              toggleInventory: "ACTIVE",
              withVariant: inventory.withVariant,
              withMaterial: inventory.withMaterial,
              rawMaterial: inventory.withMaterialOnly,
            };
          } else if (inventory.withVariant && !inventory.withMaterial) {
            payload = {
              name: field.name,
              code: field.code,
              price: Number(field.price),
              cost: Number(field.cost),
              categoryId: field.category.value,
              description: field.description,
              imageId: field.imageId,
              outlets,
              variants,
              toggleInventory: "ACTIVE",
              withVariant: inventory.withVariant,
              withMaterial: inventory.withMaterial,
              locations: inventory.variantWithlocation,
            };
          }
        } else {
          payload = {
            name: field.name,
            code: field.code,
            price: Number(field.price),
            cost: Number(field.cost),
            categoryId: field.category.value,
            description: field.description,
            imageId: field.imageId,
            outlets,
            variants,
          };
        }
        console.log("payload");
        console.log(payload);
        let res = await postData("v2/product", payload);
        console.log("res");
        console.log(res);
        if (res.data.code === 200) {
          const notif = {
            message: `Produk "${res.data.product.productName}" berhasil ditambahkan`,
            type: "primary",
            status: "success",
            id: res.data.product.productId,
            toggle: true,
          };
          setLoading(false);
          dispatch(setNotif(notif));
          dispatch(setMaterialOnly([]));
          dispatch(setWithMaterialVariant([]));
          dispatch(setToggleMaterial(false));
          dispatch(setToggleVariant(false));
          dispatch(setWithoutMaterialVariant([]));
          dispatch(setVariantOnly([]));
          dispatch(setVariantWithLocation([]));
          dispatch(setMaterialUsed([]));
          localStorage.removeItem("variantUsed");
          close();
          dispatch(fetchProduct());
          setField({
            ...field,
            name: "",
            code: "",
            cost: "0",
            price: "0",
            imageId: "",
            category: { value: "", label: "Pilih kategori" },
            variants: [],
            image: "",
            description: "",
            toggleInventory: "NONACTIVE",
          });
        }
      } else {
        setError("outlets", "outlets", "lokasi tidak boleh kosong.");
        setLoading(false);
      }
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      alert("Terjadi error server");
    }
  };

  /** handle inventory */
  const getSubscriptionStatus = async () => {
    let res = await getData("v2/subscription/status?module=INVENTORY");

    setSubscription(res.data.subscription ? res.data.subscription.status : false);
  };
  const onRoadToInventory = () => {
    if (field.variants.length === 0) {
      dispatch(setToggleVariant(false));
      dispatch(setDisabledVariant(true));
    } else {
      dispatch(setToggleVariant(true));
      dispatch(setDisabledVariant(false));
    }
    if (
      inventory.withoutMaterialVariant.length > 0 ||
      inventory.withVariantOnly.length > 0 ||
      inventory.withMaterialVariant.length > 0 ||
      inventory.withMaterialOnly.length > 0 ||
      inventory.variantWithlocation.length > 0
    ) {
      dispatch(setModalPrimary("show-create-stock"));
    } else {
      dispatch(createInventory(field));
    }
  };

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title="Tambah produk"
      anotherAction={
        <React.Fragment>
          <span className="text-blue-700 font-semibold">{field.name}</span>

          <Button
            loading={loading}
            disabled={field.category.value === "" || field.name === "" ? true : false}
            onClick={() => (!loading ? onSubmit() : null)}
            variant="primary"
            color="blue"
          >
            Selesai tambah produk
          </Button>
        </React.Fragment>
      }
    >
      <div className="container-body flex items-start justify-center bg-white">
        <div className="w-728px max-w-728px create-product">
          <h3 className="heading-3 mb-4">Informasi produk</h3>
          <div className="w-full py-5">
            <ImageField
              spacing="w-32"
              type={`${errors?.image ? "error" : "default"}`}
              label="Foto produk"
              action
              value={field.image}
              name="image"
              onChange={onSubmidImage}
              onClick={() => onResetImage()}
            >
              {errors.image && <TextCaption status="error" message={errors.image?.message} />}
            </ImageField>
          </div>

          <InputTextLargeWithLabel
            spacing="w-32"
            className="w-full mt-3"
            label="Nama produk"
            status={errors?.name ? "error" : "default"}
            register={register}
            name="name"
            placeholder=""
            onChange={onChange}
            value={field.name}
          >
            {errors.name && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.name?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
          <InputTextLargeWithLabel
            className="w-full mt-3"
            spacing="w-32"
            label="Kode produk"
            status={errors?.code ? "error" : "default"}
            register={register}
            name="code"
            onChange={onChange}
            placeholder=""
            value={field.code}
          >
            {errors.code && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.code?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
          <InputTextLargeWithLabel
            className="w-full mt-3"
            spacing="w-32"
            label="Harga jual"
            status={errors?.price ? "error" : "default"}
            placeholder="Rp."
            name="price"
            currency="rp"
            onChange={onCurrencyInput}
            value={field.price}
          >
            {errors.price && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.price?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>
          <InputTextLargeWithLabel
            className="w-full mt-3"
            label="Biaya"
            spacing="w-32"
            status={errors?.cost ? "error" : "default"}
            placeholder="Rp."
            name="cost"
            currency="rp"
            onChange={onCurrencyInput}
            value={field.cost}
          >
            {errors.cost && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.cost?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>

          <SelectBox
            className="mt-3"
            type="text"
            spacing="w-32"
            value={field.category}
            label="Kategori"
            options={category.checkboxs}
            onChange={onSelectCategory}
            btnAction={
              <Button
                onClick={() => setIsShowCreate(true)}
                variant="primary"
                color="green"
                className="w-full"
              >
                Tambah Kategori
              </Button>
            }
          >
            {errors.category && <TextCaption status="error" message={errors.category?.message} />}
          </SelectBox>
          {/* MODAL CREATE CATEGORY */}
          <CreateCategory
            title="Tambah kategori baru"
            show={isShowCreate}
            close={() => setIsShowCreate(false)}
            type="product"
            fetchCheckboxCategory={() => dispatch(fetchCheckboxCategory())}
          />
          {/* MODAL CREATE CATEGORY */}
          <SelectBox
            className="mt-3"
            spacing="w-32"
            type="text"
            value={valueCheckbox}
            label="Lokasi tersedia"
            options={field.outlets}
            onChange={onSelectLocation}
            type="checkbox"
          >
            {errors.outlets && <TextCaption status="error" message={errors.outlets?.message} />}
          </SelectBox>
          <TextArea
            className="mt-3"
            spacing="w-32"
            variant="default"
            rows={5}
            placeholder=""
            label="Deskripsi"
            name="description"
            value={field.description}
            onChange={onChange}
          >
            {errors.description && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.description?.message} />
              </div>
            )}
          </TextArea>

          <div className="w-full mt-20">
            <h3 className="heading-3 mb-4">Variasi produk</h3>
            <p className="text-2 text-gray-03 mt-1 mb-4">
              Tambahkan variasi seperti ukuran, rasa, dan warna.
            </p>

            <div className="p-6 w-full mt-4 bg-gray-05 rounded-lg">
              <div className="bg-white rounded-lg px-8 pt-2 pb-4">
                <ul>
                  {field.variants.map((data, i) => {
                    return (
                      <li className="flex items-center justify-between mt-4" key={i}>
                        <div className="flex items-center">
                          <IconDrag className="mr-4" />
                          <p className="text-2">
                            {data.label}
                            <span className="text-red-03">*</span>{" "}
                            <span className="text-gray-02">{data.option.length} opsi variasi)</span>
                          </p>
                        </div>
                        <div className="flex items-center">
                          <Button
                            variant="secondary"
                            color="red"
                            onClick={() => onRemoveVariant(data)}
                          >
                            Hapus
                          </Button>
                          {/* <Button variant="secondary" color="blue" className="ml-2">
                            Edit opsi
                          </Button> */}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <Button
                onClick={() => setIsShowVariant(true)}
                className="w-full bold-text-2 mt-4"
                variant="primary"
                color="green"
              >
                Pilih variasi
              </Button>
              {/*  START: SHOW MODAL VARIANT */}
              <ShowVariant
                search={search}
                filterVariant={filterVariant}
                onCheckboxVariant={(e, i) => onCheckboxVariant(e, i)}
                show={isShowVariant}
                onSelectVariant={() => onSelectVariant()}
                close={() => setIsShowVariant(false)}
              />
              {/* END: SHOW MODAL VARIANT */}
            </div>
          </div>
          {/* TOGGLE INVENTORY */}
          {subscription && field.name.length !== 0 ? (
            <div className="w-full mt-20">
              <h3 className="heading-3 mb-4">Inventaris produk</h3>
              <p className="text-2 text-gray-03 mt-1 mb-4 flex items-center">
                Hitung stok, resep, dan bahan baku untuk produk.
                {field.toggleInventory === "ACTIVE" ? (
                  <>
                    <span className="text-blue-03 ml-3" onClick={onRoadToInventory}>
                      Klik disini untuk manajemen inventaris
                    </span>
                    <ArrowRight width="16" fill="#859DFF" className="ml-4" height="16" />
                  </>
                ) : (
                  ""
                )}
              </p>
              <div className="rounded-lg flex justify-between py-4 px-6 border border-gray-04">
                <p className="bold-text-2 text-gray-02">Hitung inventaris produk</p>
                <div className="relative right-6">
                  <Toggle
                    onChange={() => {
                      if (field.toggleInventory === "ACTIVE") {
                        setField({ ...field, toggleInventory: "NONACTIVE" });
                      } else {
                        setField({ ...field, toggleInventory: "ACTIVE" });
                      }
                    }}
                    checked={field.toggleInventory === "ACTIVE" ? true : false}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </ModalDialog>
  );
}
