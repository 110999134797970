import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProduct,
  setKeyword,
  fetchLocationInProduct,
  fetchCategoryInProduct,
  setCategory,
  setLocation,
} from "features/Product/actions";
import { setModalPrimary } from "features/Stock/actions";
import Table from "./tableProduct";
import Button from "components/atoms/Button";
import SearchBar from "components/atoms/SearchBar";
import EmptyPage from "components/templates/Empty";
import Banner from "components/atoms/Banner";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import ProductCreate from "./create";
import ModalCreate from "pages/Inventory/Stock/create";
import ModalUpdate from "pages/Inventory/Stock/create";
import EmptyProduk from "assets/icon/undraw_empty_xct9 1.svg";

export default function ProductPage() {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const inventory = useSelector((state) => state.inventory);
  // console.log("inventory");
  // console.log(inventory);

  const [filterLocation, setFilterLocation] = React.useState({
    label: "Semua Lokasi",
    value: "",
    isChecked: true,
    all: true,
  });
  const [filterCategory, setFilterCategory] = React.useState({
    label: "Semua kategori",
    value: "",
    isChecked: true,
    all: true,
  });
  const [isShowSearch, setIsShowSearch] = React.useState(false);
  const [isShowCreate, setIsShowCreate] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchLocationInProduct());
    dispatch(fetchCategoryInProduct());
  }, []);

  React.useEffect(() => {
    dispatch(fetchProduct());
  }, [
    dispatch,
    product.keyword,
    product.page,
    product.limit,
    product.outlets,
    product.category,
  ]);

  const handleFilterCategory = (data, allCheckbox) => {
    let check = [];
    if (allCheckbox) {
      if (filterCategory.isChecked) {
        for (let i = 0; i < product.category.length; i++) {
          check.push({ ...product.category[i], isChecked: false });
        }
        setFilterCategory({
          ...filterCategory,
          label: "0 Kategori",
          value: "",
          isChecked: false,
          all: false,
        });
        dispatch(setCategory(check));
      } else {
        for (let i = 0; i < product.category.length; i++) {
          check.push({ ...product.category[i], isChecked: true });
        }
        setFilterCategory({
          ...filterCategory,
          label: "Semua kategori",
          value: "",
          isChecked: true,
          all: true,
        });
        dispatch(setCategory(check));
      }
    } else {
      for (let i = 0; i < product.category.length; i++) {
        if (product.category[i].value === data.value) {
          check.push({
            ...product.category[i],
            isChecked: data.isChecked
              ? (data.isChecked = false)
              : (data.isChecked = true),
          });
        } else {
          check.push(product.category[i]);
        }
      }

      dispatch(setCategory(check));
      let sum = 0;
      check.forEach((obj) => {
        if (obj.isChecked === true) sum += 1;
      });

      setFilterCategory({
        label:
          sum === product.category.length
            ? "Semua kategori"
            : `${sum} kategori`,
        value: "sesuaikan kebutuhan",
        isChecked: sum === product.category.length ? true : false,
      });
    }
  };

  const handleFilterLocation = (data, allCheckbox) => {
    let check = [];
    if (allCheckbox) {
      if (filterLocation.isChecked) {
        for (let i = 0; i < product.outlets.length; i++) {
          check.push({ ...product.outlets[i], isChecked: false });
        }
        setFilterLocation({
          ...filterLocation,
          label: "0 lokasi",
          value: "",
          isChecked: false,
          all: false,
        });
        dispatch(setLocation(check));
      } else {
        for (let i = 0; i < product.outlets.length; i++) {
          check.push({ ...product.outlets[i], isChecked: true });
        }
        setFilterLocation({
          ...filterLocation,
          label: "Semua lokasi",
          value: "",
          isChecked: true,
          all: true,
        });
        dispatch(setLocation(check));
      }
    } else {
      for (let i = 0; i < product.outlets.length; i++) {
        if (product.outlets[i].value === data.value) {
          check.push({
            ...product.outlets[i],
            isChecked: data.isChecked
              ? (data.isChecked = false)
              : (data.isChecked = true),
          });
        } else {
          check.push(product.outlets[i]);
        }
      }

      dispatch(setLocation(check));
      let sum = 0;
      check.forEach((obj) => {
        if (obj.isChecked === true) sum += 1;
      });

      setFilterLocation({
        label:
          sum === product.outlets.length ? "Semua lokasi" : `${sum} lokasi`,
        value: "sesuaikan kebutuhan",
        isChecked: sum === product.outlets.length ? true : false,
      });
    }
  };

  // ambil data error dan convert ke view
  if (product.status === "error") return <h1>internal server error</h1>;

  if (product.keyword === "" && product.outlets.length === 0 && product?.data?.length === 0 && product.status === "success") {
    return (
      <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-2">
        <EmptyPage
          title="Daftar produk"
          text="Disini adalah dimana semua produk Anda akan ditampilkan. Tambah produk untuk mulai berjualan dengan Dalenta. Anda juga bisa impor produk dengan file .csv jika ingin tambah dalam jumlah banyak sekaligus."
          image={EmptyProduk}
          label="Tambah produk baru"
          onClick={() => setIsShowCreate(true)}
          importButton
          labelImportButton="Import produk"
          onClickImport={() => alert("onprogress")}
        />
        {/* START: CREATE */}
        <ProductCreate
          title="Tambah produk baru"
          show={isShowCreate}
          close={() => setIsShowCreate(false)}
        />
        {/* END: CREATE */}
      </div>
    );
  }

  return (
    <div className="w-full h-screen overflow-hidden  mt-20 px-5">
      {/* START: NOTIF */}
      {product.notif?.toggle && product.notif.status === "danger" && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          status={product.notif.status}
          type={product.notif.type}
          message={product.notif.message}
        />
      )}
      {/* END: NOTIF */}

      {/* START:: HEADER */}
      <div className="flex items-center justify-between my-2">
        {/* START: HEADER */}
        {isShowSearch ? (
          <SearchBar
            name={product.keyword}
            value={product.keyword}
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            onClick={() => setIsShowSearch(!isShowSearch)}
          />
        ) : (
          <div className="flex items-center">
            <Button
              className="mr-2"
              variant="outlined"
              color="gray"
              content="icon infront"
              iconName="UilSearch"
              onClick={() => setIsShowSearch(!isShowSearch)}
            >
              Cari produk
            </Button>
            <Dropdown
              variant="outlined"
              color="gray"
              className="mr-1"
              content="label only"
              relativePosition="left-0"
              label={filterCategory.label}
            >
              <DropdownList
                onClick={() => handleFilterCategory(filterCategory, true)}
                list="checkbox"
                value={filterCategory.isChecked}
                checked={filterCategory.isChecked}
              >
                Semua kategori
              </DropdownList>
              {product?.category.map((data, i) => (
                <DropdownList
                  key={i}
                  onClick={() => handleFilterCategory(data)}
                  checked={data.isChecked}
                  value={data.isChecked}
                  list="checkbox"
                >
                  {data.label}
                </DropdownList>
              ))}
            </Dropdown>
            <Dropdown
              variant="outlined"
              color="gray"
              className="mr-1"
              content="label only"
              label={filterLocation.label}
            >
              <DropdownList
                onClick={() => handleFilterLocation(filterLocation, true)}
                list="checkbox"
                checked={filterLocation.isChecked}
                value={filterLocation.isChecked}
              >
                Semua lokasi
              </DropdownList>
              {product?.outlets.map((data, i) => (
                <DropdownList
                  key={i}
                  onClick={() => handleFilterLocation(data)}
                  checked={data.isChecked}
                  list="checkbox"
                >
                  {data.label}
                </DropdownList>
              ))}
            </Dropdown>
          </div>
        )}
        <Button
          variant="primary"
          color="blue"
          className="ml-2 whitespace-nowrap"
          onClick={() => setIsShowCreate(true)}
        >
          Tambah produk baru
        </Button>
        {/* END: HEADER */}
      </div>
      {/* END:: HEADER */}

      {/* START: TABLE */}
      <div className="overflow-scroll scroll-hidden" style={{ height: "74vh" }}>
        <Table status={product.status} data={product.data} />
      </div>
      {/* END: TABLE */}

      {/* START: CREATE */}

      <ProductCreate
        title="Tambah produk baru"
        show={isShowCreate}
        close={() => setIsShowCreate(false)}
      />
      {inventory.modal_primary === "show-create-stock" && (
        <ModalCreate
          show={inventory.modal_primary === "show-create-stock" ? true : false}
          inventory={inventory}
          hide={() => dispatch(setModalPrimary("hide-create-stock"))}
        />
      )}
      {inventory.modal_primary === "show-update-stock" && (
        <ModalCreate
          show={inventory.modal_primary === "show-update-stock" ? true : false}
          hide={() => dispatch(setModalPrimary("hide-update-stock"))}
        />
      )}
      {inventory.modal_primary === "show-update-stock-product" && (
        <ModalUpdate
          show={
            inventory.modal_primary === "show-update-stock-product"
              ? true
              : false
          }
          hide={() => dispatch(setModalPrimary("hide-update-stock-product"))}
        />
      )}

      {/* END: CREATE */}
    </div>
  );
}
