import React from "react";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import TextCaption from "components/atoms/Text/TextCaption";
import InputGroupLargeContentText from "./InputGroupLargeContentText";
import InputGroupLargeContentIcon from "./InputGroupLargeContentIcon";
import InputGroupLargeContentTextIcon from "./InputGroupLargeContentTextIcon";
import InputGroupLargeContentSwitch from "./InputGroupLargeContentSwitch";
import InputGroupLargeContentTextSwitch from "./InputGroupLargeContentTextSwitch";

export default function InputFieldLarge() {
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="flex w-full items-center">
        <div className="flex flex-col items-center">
          <div className="my-2">
            <InputTextLargeWithLabel
              label="Text field label"
              status="default"
            />
          </div>
          <div className="my-2">
            <InputTextLargeWithLabel
              label="Text field label"
              status="disabled"
              disabled
            />
          </div>
          <div className="my-2">
            <InputTextLargeWithLabel label="Text field label" status="error">
              <div className="flex items-center mt-1">
                <TextCaption status="error" message="This field is required" />
              </div>
            </InputTextLargeWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeWithLabel label="Text field label" status="success">
              <div className="flex items-center mt-1">
                <TextCaption
                  status="success"
                  message="Field validation is successful"
                />
              </div>
            </InputTextLargeWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeWithLabel label="Text field label" status="warning">
              <div className="flex items-center mt-1">
                <TextCaption
                  status="warning"
                  message="This field is required"
                />
              </div>
            </InputTextLargeWithLabel>
          </div>
        </div>
      </div>

      <div className="flex w-full items-center">
        <div className="flex flex-col items-center">
          <div className="my-2">
            <InputTextLargeWithLabel
              label="Text field label"
              info
              status="default"
            />
          </div>
          <div className="my-2">
            <InputTextLargeWithLabel
              label="Text field label"
              info
              status="disabled"
              disabled
            />
          </div>
          <div className="my-2">
            <InputTextLargeWithLabel
              label="Text field label"
              info
              status="error"
            >
              <div className="flex items-center mt-1 ml-5">
                <TextCaption status="error" message="This field is required" />
              </div>
            </InputTextLargeWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeWithLabel
              label="Text field label"
              info
              status="success"
            >
              <div className="flex items-center mt-1 ml-5">
                <TextCaption
                  status="success"
                  message="Field validation is successful"
                />
              </div>
            </InputTextLargeWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeWithLabel
              label="Text field label"
              info
              status="warning"
            >
              <div className="flex items-center mt-1 ml-5">
                <TextCaption
                  status="warning"
                  message="This field is required"
                />
              </div>
            </InputTextLargeWithLabel>
          </div>
        </div>
      </div>

      {/* START: INPUT LARGE GROUP CONTENT TEXT */}
      <InputGroupLargeContentText />
      {/* END: INPUT LARGE GROUP CONTENT TEXT */}

      {/* START: INPUT LARGE GROUP CONTENT ICON */}
      <InputGroupLargeContentIcon />
      {/* END: INPUT LARGE GROUP CONTENT ICON */}

      {/* START: INPUT LARGE GROUP CONTENT TEXT ICON */}
      <InputGroupLargeContentTextIcon />
      {/* END: INPUT LARGE GROUP CONTENT TEXT ICON */}

      {/* START: INPUT LARGE GROUP CONTENT SWITCH */}
      <InputGroupLargeContentSwitch />
      {/* END: INPUT LARGE GROUP CONTENT SWITCH */}

      {/* START: INPUT LARGE GROUP CONTENT SWITCH */}
      <InputGroupLargeContentTextSwitch />
      {/* END: INPUT LARGE GROUP CONTENT SWITCH */}
    </div>
  );
}
