import React from "react";
import ModalDialog from "components/molecules/Modal";
import IconArrowDown from "components/atoms/Icons/Custom/icon new/Arrow";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import TextCaption from "components/atoms/Text/TextCaption";
import Button from "components/atoms/Button";
import SelectBox from "components/atoms/SelectBox";
import "./index.css";
import TextArea from "components/atoms/Form/InputTextArea";
import Checkbox from "components/atoms/Checkbox";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchRegions, fetchCity } from "features/Region/actions";
import { fetchLocations, setNotif } from "features/Location/actions";
import { putData, getData } from "utils/fetchData";

const phoneCode = [
  {
    label: "Malaysia",
    value: "+60",
    code: "my-MY",
  },
  {
    label: "Indonesia",
    value: "+62",
    code: "id-ID",
  },
  {
    label: "Singapore",
    value: "+65",
    code: "sg-SG",
  },
  {
    label: "Papua New Guinea",
    value: "+675",
    code: "pg-PG",
  },
  {
    label: "Timor-Leste",
    value: "+670",
    code: "tl-TL",
  },
];

const type = [
  {
    label: "Lokasi fisik",
    value: 1,
  },
  {
    label: "Lokasi digital",
    value: 2,
  },
];

const LocationSchema = yup.object().shape({
  name: yup.string().required("nama lokasi tidak boleh kosong."),
  email: yup.string().required("email tidak boleh kosong."),
  phone: yup.string().required("no telepon boleh kosong."),
  address: yup.string().required("alamat tidak boleh kosong."),
  website: yup.string().url(),
});

export default function CreateLocation({ show, close, idLocation }) {
  const dispatch = useDispatch();

  const { register, errors } = useForm({
    mode: "onBlur",
    validationSchema: LocationSchema,
  });

  const region = useSelector((state) => state.region);

  // console.log(region);
  const [loading, setLoading] = React.useState(false);
  const [isShowProvince, setIsShowProvince] = React.useState(false);
  const [isShowCity, setIsShowCity] = React.useState(false);
  const [field, setField] = React.useState({
    name: "",
    email: "",
    phone: "62",
    type: 1,
    phoneRegion: "id-ID",
    address: "",
    description: "-",
    provinceId: 3,
    cityId: 106,
    postalCode: 0,
    operational: [],
  });

  const days = ["SENIN", "SELASA", "RABU", "KAMIS", "JUMAT", "SABTU", "MINGGU"];
  const loadOperational = () => {
    let arr = [];
    days.forEach((item, i) => {
      arr.push({
        idSelected: i + 1,
        id: item,
        day: item,
        startTime: "07:00",
        endTime: "17:00",
        status: true,
      });
    });
    setField({ ...field, operational: arr });
  };

  const [defaultType, setDefaultType] = React.useState({
    label: "Pilih jenis lokasi",
    value: "",
  });

  const [defaultPhone, setDefaultPhone] = React.useState({
    label: "Indonesia",
    value: "+62",
  });

  const [defaultProvince, setDefaultProvince] = React.useState("");
  const [defaultCity, setDefaultCity] = React.useState("");

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setField({ ...field, [name]: value });
  };

  const onChangeType = (e) => {
    setDefaultType({ label: e.label, value: e.value });
    setField({ ...field, type: e.value });
  };

  const onChangeProvince = (value) => {
    setDefaultProvince(value.label);
    setField({ ...field, provinceId: parseInt(value.value) });
    dispatch(fetchCity(value.value));
    setIsShowProvince(false);
    setIsShowCity(true);
  };

  const onChangeCity = (value) => {
    setDefaultCity(value.label);
    setField({ ...field, cityId: parseInt(value.value), postalCode: parseInt(value.post_code) });
    setIsShowCity(false);
  };

  const onChangePhone = (e) => {
    let value = e.value;
    setField({ ...field, phoneRegion: e.code });
    setDefaultPhone({ ...defaultPhone, label: e.label, value: value });
  };

  const onChangeOpeerational = (e) => {
    let op = [...field.operational];
    op[e.target.dataset.id][e.target.name] = e.target.value;
    setField({ ...field, operational: op });
  };

  const onChecked = (e) => {
    let checked = e.target.checked;
    let id = e.target.value;
    setField({
      ...field,
      operational: field.operational.map((data) => {
        if (data.id === id) {
          data.status = checked;
        }
        return data;
      }),
    });
  };

  console.log(field);
  const onSubmit = async () => {
    setLoading(true);
    await putData(`v2/location/${idLocation}`, field)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          const notif = {
            message: `${res.data.message} - ${res.data.location.name}`,
            type: "primary",
            status: "success",
            toggle: true,
          };
          dispatch(setNotif(notif));
          dispatch(fetchLocations());
          setLoading(false);
          close();
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  };

  const getOneLocation = async () => {
    await getData(`v2/location/${idLocation}`)
      .then((res) => {
        let result = res?.data?.outlet;
        console.log(res);
        let phone_reg = "id-ID";
        let desc = "";
        if (result.outletDescriptionAddress === null) desc = "";
        if (res.data.code === 200) {
          setField({
            name: result.outletName,
            email: result.outletEmail,
            phone: result.outletPhone,
            phoneRegion: phone_reg,
            address: result.outletAddress,
            description: desc,
            provinceId: result.outletProvinceId,
            cityId: result.outletCityId,
            postalCode: parseInt(result.outletPostalCode),
            operational: result?.operationalTime,
          });
          setDefaultCity(result.outletCityName);
          setDefaultProvince(result.outletProvinceName);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  //search province
  const provinceOnfilter = React.useMemo(() => {
    if (!defaultProvince) return region?.province;

    return region?.province.filter((province) => {
      return province.label.toLowerCase().includes(defaultProvince.toLowerCase());
    });
  }, [defaultProvince, region?.province]);

  //search city
  const cityOnfilter = React.useMemo(() => {
    if (!defaultCity) return region?.city;

    return region?.city.filter((city) => {
      return city.label.toLowerCase().includes(defaultCity.toLowerCase());
    });
  }, [defaultCity, region?.city]);

  React.useEffect(() => {
    dispatch(fetchRegions());
    loadOperational();
  }, [dispatch]);

  React.useEffect(() => {
    getOneLocation();
  }, [dispatch]);

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title={<h1 className="text-gray-02 heading-3">Edit lokasi</h1>}
      anotherAction={
        <Button
          loading={loading}
          onClick={onSubmit}
          disabled={
            field.name.length ||
            field.email.length ||
            field.phone.length > 2 ||
            field.address.length
              ? false
              : true
          }
          className="ml-4"
          variant="primary"
          color="blue"
          type="submit"
        >
          Selesai edit lokasi
        </Button>
      }
    >
      <div className={`bg-white p-5 h-full overflow-scroll border-t`}>
        <div className="w-728px max-w-728px m-auto">
          <h1 className="heading-3 text-gray-02 flex items-center">Informasi lokasi</h1>
          <ul className="mt-4 setting-location">
            {/* NAME FIELD */}
            <li className="flex w-full  border-gray-04 py-2">
              <InputTextLargeWithLabel
                register={register}
                status={errors?.name ? "error" : "default"}
                value={field.name}
                onChange={onChange}
                name="name"
                className="w-full"
                label="Nama lokasi"
              >
                {errors.name && (
                  <div className="flex items-center mt-1">
                    <TextCaption status="error" message={errors.name?.message} />
                  </div>
                )}
              </InputTextLargeWithLabel>
            </li>
            {/* TYPE FIELD */}
            <li className="flex w-full  border-gray-04 py-2">
              <SelectBox
                label="Jenis lokasi"
                className="w-full"
                type="text"
                value={defaultType}
                options={type}
                onChange={onChangeType}
              />
            </li>
            {/* DESC FIELD */}
            <li className="flex w-full  border-gray-04 py-2 nomor-telepon">
              <TextArea
                variant="default"
                rows={5}
                label="Deskripsi lokasi"
                value={field.description}
                onChange={onChange}
                name="description"
              />
            </li>
          </ul>
          <h1 className="heading-3 text-gray-02 flex items-center mt-10">
            Alamat lokasi
            <IconArrowDown
              fill="#0099FF"
              width="13"
              height="13"
              className="transform rotate-90 ml-4"
            />
          </h1>
          <ul className="mt-4 setting-location">
            {/* ADDRESS FIELD */}
            <li className="flex w-full  border-gray-04 py-2">
              <TextArea
                variant="default"
                rows={5}
                label="Alamat"
                name="address"
                register={register}
                status={errors?.address ? "error" : "default"}
                value={field.address}
                onChange={onChange}
              >
                {errors.address && (
                  <div className="flex items-center mt-1">
                    <TextCaption status="error" message={errors.address?.message} />
                  </div>
                )}
              </TextArea>
            </li>

            {/* PROVINCE FIELD */}
            <li className="w-full  border-gray-04 py-2 relative">
              <InputTextLargeWithLabel
                className="w-full"
                label="Pilih provinsi"
                status="default"
                onChange={(e) => {
                  setDefaultProvince(e.target.value);
                  setIsShowProvince(true);
                }}
                value={defaultProvince}
              />
              {isShowProvince && (
                <div className="relative -top-3">
                  <ul
                    className="absolute overflow-y-scroll scroll-hidden right-0 item-list shadow-HeavyDropShadow bg-white rounded-lg mt-3 mb-6 top-2"
                    style={{
                      zIndex: "99999999",
                      maxHeight: "14rem",
                      width: "75%",
                    }}
                  >
                    {provinceOnfilter?.map((item) => {
                      return (
                        <li
                          onClick={() => onChangeProvince(item)}
                          key={item.value}
                          className="cursor-pointer hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm"
                        >
                          {item.label}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </li>

            {/* CITY FIELD */}
            {defaultProvince && (
              <li className="w-full  border-gray-04 py-2 relative">
                <InputTextLargeWithLabel
                  className="w-full"
                  label="Pilih kota"
                  status="default"
                  onChange={(e) => {
                    setDefaultCity(e.target.value);
                    setIsShowCity(true);
                  }}
                  value={defaultCity}
                />
                {isShowCity && (
                  <div className="relative -top-3">
                    <ul
                      className="absolute overflow-y-scroll scroll-hidden right-0 item-list shadow-HeavyDropShadow bg-white rounded-lg mt-3 mb-6 top-2"
                      style={{
                        zIndex: "99999999",
                        maxHeight: "14rem",
                        width: "75%",
                      }}
                    >
                      {region?.city &&
                        cityOnfilter?.map((item) => {
                          return (
                            <li
                              onClick={() => onChangeCity(item)}
                              key={item.value}
                              className="cursor-pointer hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm"
                            >
                              {item.label}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
              </li>
            )}
            {/* POST CODE FIELD */}
            <li className="flex w-full  border-gray-04 py-2">
              <InputTextLargeWithLabel
                disabled
                value={field.postalCode}
                className="w-full"
                label="Kode Pos"
                status="default"
              />
            </li>
          </ul>
          <h1 className="heading-3 text-gray-02 flex items-center mt-10">
            Informasi kontak lokasi
            <IconArrowDown
              fill="#0099FF"
              width="13"
              height="13"
              className="transform rotate-90 ml-4"
            />
          </h1>
          <ul className="mt-4 setting-location">
            {/* PHONE CODE FIELD */}
            <li className="flex w-full  border-gray-04 py-2 nomor-telepon">
              <div className="w-9/12">
                <SelectBox
                  className="w-full"
                  type="text"
                  label="Kode telepon"
                  value={defaultPhone}
                  options={phoneCode}
                  onChange={onChangePhone}
                />
              </div>
              <div className="w-2/3 flex items-center">
                <div className="bg-gray-05 whitespace-nowrap bold-text-2 h-full p-2 px-4 flex items-center ml-4 mr-4">
                  {field.phoneRegion}
                </div>
                <div className="flex items-center justify-between w-full">
                  <input
                    name="phone"
                    value={field.phone}
                    onChange={onChange}
                    placeholder="ex: 628xxxxxx"
                    type="number"
                    className="w-full focus:outline-none border px-2 py-2 rounded-lg"
                  />
                </div>
              </div>
            </li>
            {/* EMAIL FIELD */}
            <li className="flex w-full  border-gray-04 py-2">
              <InputTextLargeWithLabel
                onChange={onChange}
                className="w-full"
                label="Email"
                name="email"
                value={field.email}
                status="default"
              />
            </li>
          </ul>

          <h1 className="heading-3 text-gray-02 flex items-center mt-10">
            Jam operasional
            <IconArrowDown
              fill="#0099FF"
              width="13"
              height="13"
              className="transform rotate-90 ml-4"
            />
            {/* icon arrow down */}
          </h1>

          <ul className="mt-4">
            {field.operational &&
              field?.operational?.map((op, i) => {
                return (
                  <li key={op.id}>
                    <div className="grid grid-cols-6">
                      <div className="col-span-2">
                        <div className="flex items-center pl-5 py-3">
                          {/* checkbox */}
                          <div className="relative -top-2">
                            <Checkbox
                              type="default"
                              checked={op.status}
                              name="checkbox"
                              value={op.id}
                              onChange={onChecked}
                            />
                          </div>
                          <p className="text-blue-03 pl-7">{op.day}</p>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="bg-gray-05 p-3">
                          <p className="font-semibold pl-3">Dari jam</p>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="flex items-center justify-center h-full">
                          <input
                            type="time"
                            name="startTime"
                            data-id={i}
                            onChange={onChangeOpeerational}
                            className="cursor-pointer"
                          />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="bg-gray-05 p-3">
                          <p className="font-semibold pl-3">Sampai jam</p>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="flex items-center justify-center h-full">
                          <input
                            name="endTime"
                            data-id={i}
                            onChange={onChangeOpeerational}
                            type="time"
                            className="cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </ModalDialog>
  );
}
