import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import Radio from "components/atoms/Radio";
import TextArea from "components/atoms/Form/InputTextArea";
import "./nonaktifkanAkun.css";
import Logo from "components/atoms/Logo";
export default function NonaktifkanAkun({ show, close }) {
  const [textarea, setTextarea] = React.useState("");
  const [primary, setPrimary] = React.useState("kanan");
  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title={
        <Logo
          type="sello"
          content="icon wordmark"
          variant="blue"
          className="relative -left-8"
        />
      }
    >
      <div
        className={`bg-white border-t relative h-full overflow-scroll flex justify-center`}
      >
        <div className="max-w-728px">
          <div
            className="overflow-y-scroll scroll-hidden"
            style={{ height: "68vh" }}
          >
            {/* title */}
            <h1 className="font-semibold text-lg text-center mt-6">
              Nonaktifkan akun Dalenta
            </h1>
            {/* content */}
            <p className="text-gray-02 mt-4 mb-4 text-center">
              Silahkan pilih alasan untuk menontaktifkan akun Dalenta anda
            </p>
            <ul>
              <li className="flex items-center border-t border-b py-4">
                <div className="relative">
                  <Radio
                    type="primary"
                    name="primary"
                    value="kiri"
                    onChange={() => setPrimary("kiri")}
                    checked={primary}
                  />
                </div>
                <p className="text-gray-02">Bisnis sudah tidak beroperasi</p>
              </li>
              <li className="flex items-center border-t border-b py-4">
                <div className="relative">
                  <Radio
                    type="primary"
                    name="primary"
                    value="kiri"
                    onChange={() => setPrimary("kiri")}
                    checked={primary}
                  />
                </div>
                <p className="text-gray-02">Masalah perangkat</p>
              </li>
              <li className="flex items-center border-t border-b py-4">
                <div className="relative">
                  <Radio
                    type="primary"
                    name="primary"
                    value="kiri"
                    onChange={() => setPrimary("kiri")}
                    checked={primary}
                  />
                </div>
                <p className="text-gray-02">Harga & penagihan tidak cocok</p>
              </li>
              <li className="flex items-center border-t border-b py-4">
                <div className="relative">
                  <Radio
                    type="primary"
                    name="primary"
                    value="kiri"
                    onChange={() => setPrimary("kiri")}
                    checked={primary}
                  />
                </div>
                <p className="text-gray-02">
                  Fitur Dalanta tidak memenuhi kebutuhan bisnis
                </p>
              </li>
              <li className="flex items-center border-t border-b py-4">
                <div className="relative">
                  <Radio
                    type="primary"
                    name="primary"
                    value="kiri"
                    onChange={() => setPrimary("kiri")}
                    checked={primary}
                  />
                </div>
                <p className="text-gray-02">Rekening Bank tidak didukung</p>
              </li>
              <li className="flex items-center border-t border-b py-4">
                <div className="relative">
                  <Radio
                    type="primary"
                    name="primary"
                    value="kiri"
                    onChange={() => setPrimary("kiri")}
                    checked={primary}
                  />
                </div>
                <p className="text-gray-02">
                  Dalanta terlalu sulit untuk digunakan
                </p>
              </li>
              <li className="flex items-center border-t border-b py-4">
                <div className="relative">
                  <Radio
                    type="primary"
                    name="primary"
                    value="kiri"
                    onChange={() => setPrimary("kiri")}
                    checked={primary}
                  />
                </div>
                <p className="text-gray-02">
                  Tidak sengaja membuat akun duplikat
                </p>
              </li>
              <li className="flex items-center border-t border-b py-4">
                <div className="relative">
                  <Radio
                    type="primary"
                    name="primary"
                    value="kiri"
                    onChange={() => setPrimary("kiri")}
                    checked={primary}
                  />
                </div>
                <p className="text-gray-02">Lainnya</p>
              </li>
              <li className="flex items-center border-t border-b py-4 nonaktifkan-akun">
                <TextArea
                  variant="default"
                  rows={5}
                  placeholder="Tambah komentar dan penjelasan disini"
                  label=""
                  name="textarea"
                  value={textarea}
                  onChange={(e) => setTextarea(e.target.value)}
                />
              </li>
            </ul>
          </div>
          <div
            className="flex absolute bottom-0 justify-between w-full items-center bg-white border-t"
            style={{ maxWidth: "420px" }}
          >
            <Button
              variant="secondary"
              color="red"
              className="mr-4"
              type="button"
            >
              Batal
            </Button>
            <Button
              variant="primary"
              color="blue"
              className="my-4"
              type="button"
            >
              Lanjut
            </Button>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
}
