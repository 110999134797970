import {
  START_FETCHING_DEVICE,
  ERROR_FETCHING_DEVICE,
  SUCCESS_FETCHING_DEVICE,
  SET_KEYWORD,
  SET_NOTIF,
  SET_LOCATION,
  SET_LIMIT,
  SET_PAGE
} from "./constants";
import { userLogout } from 'features/Auth/actions'

import { getData } from 'utils/fetchData'
import debounce from "debounce-promise";

let debouncedFetchDevice = debounce(getData, 1000);

export const startFetchingDevice = () => {
  return {
    type: START_FETCHING_DEVICE,
  };
};

export const errorFetchingDevice = ({ message }) => {
  return {
    type: ERROR_FETCHING_DEVICE,
    message
  };
};

export const successFetchingDevice = ({ device, location, pages, total }) => {
  return {
    type: SUCCESS_FETCHING_DEVICE,
    device,
    location,
    pages,
    total
  };
};

export const fetchDevice = () => {
  return async (dispatch, getState) => {
    try {

      const notif = {
        message: '',
        type: '',
        status: '',
        toggle: false
      }

      setTimeout(() => {
        dispatch(setNotif(notif))
      }, 5000)

      let keyword = getState().device.keyword || '';
      let location = getState().device.location || '';
      let page = getState().product.page || 1;
      let limit = getState().product.limit || 10;
      dispatch(startFetchingDevice());
      const params = {
        keyword,
        location,
        page,
        limit
      }


      let { data: { outlets }, } = await getData("outlets");

      let select_box = [{ label: "Semua lokasi", value: "", }]
      outlets.forEach((item) => {
        select_box.push({
          label: item.name,
          value: item.id,
        });
      });

      const res = await debouncedFetchDevice('v2/device', params);

      if (res.data?.code === 401) {
        dispatch(userLogout())
      }

      if (res.data.code === 500) {
        return dispatch(errorFetchingDevice({ message: res.data.message }));
      }

      dispatch(successFetchingDevice({ device: res.data.devices, location: select_box, pages: res.data.pages, total: res.data.total }));

    } catch (err) {
      console.log("err.response >>")
      console.log(err.response)
      dispatch(errorFetchingDevice({ message: 'internal server error' }))
    }
  };
};

export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};



export const setPage = (number = 1) => {
  return {
    type: SET_PAGE,
    page: number,
  };
};

export const setPerPage = (limit) => {
  return {
    type: SET_LIMIT,
    limit: limit,
  };
};
