import {
  START_FETCHING_MEASURE,
  SUCCESS_FETCHING_MEASURE,
  ERROR_FETCHING_MEASURE,
  SET_KEYWORD,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_MODAL,
  SET_ID,
  SET_NAME,
  SET_UNIT,
  SET_BANNER,
  SET_ERROR,
  SET_SELECTED,
  SET_NOTIF
} from "./constant";

import { postData, putData, deleteData, getData } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchMeasure = debounce(getData, 700);

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

















/** 
 * jika sudah aman semua yang ditidak dipakai silahkan delete elfin sanjaya
 */

export const startFetchingMeasure = () => {
  return {
    type: START_FETCHING_MEASURE,
  };
};

export const errorFetchingMeasure = () => {
  return {
    type: ERROR_FETCHING_MEASURE,
  };
};

export const successFetchingMeasure = ({ measure, select_box }) => {
  return {
    type: SUCCESS_FETCHING_MEASURE,
    measure,
    select_box,
  };
};

export const setId = (id) => {
  return {
    type: SET_ID,
    id,
  };
};
export const setName = (name) => {
  return {
    type: SET_NAME,
    name,
  };
};
export const setUnit = (unit) => {
  return {
    type: SET_UNIT,
    unit,
  };
};
export const setTotalPage = (totalPage) => {
  return {
    type: SET_TOTAL_PAGE,
    totalPage,
  };
};
export const setModal = (modal) => {
  return {
    type: SET_MODAL,
    modal,
  };
};
export const setBanner = (banner) => {
  return {
    type: SET_BANNER,
    banner,
  };
};
export const setError = (error) => {
  return {
    type: SET_ERROR,
    error,
  };
};

export const fetchMeasure = () => {
  return async (dispatch, getState) => {
    dispatch(startFetchingMeasure());
    const notif = {
      message: '',
      type: '',
      status: '',
      toggle: false
    }

    setTimeout(() => {
      dispatch(setNotif(notif))
    }, 6000)

    let keyword = getState().measure.keyword || "";
    let page = getState().measure.page || 1;
    let limit = getState().measure.limit || 10;
    const params = {
      keyword,
      page,
      limit,
    };
    try {
      let {
        data: { units, pages },
      } = await debouncedFetchMeasure("v2/unitofmeasure", params);
      let measure = units;
      let totalPage = pages;
      let select_box = [];
      units.forEach((item) => {
        select_box.push({
          id: item.id,
          value: item.unit,
          title: item.name,
        });
      });
      dispatch(successFetchingMeasure({ measure, select_box }));
      dispatch(setTotalPage(totalPage));
    } catch (err) {
      dispatch(errorFetchingMeasure());
    }
  };
};

export const fetchOneMeasure = (id) => {
  return async (dispatch) => {
    await getData(`v2/unitofmeasure/${id}`).then((res) => {
      let id = res.data.unit.id;
      let name = res.data.unit.name;
      let unit = res.data.unit.unit;
      dispatch(setId(id));
      dispatch(setName(name));
      dispatch(setUnit(unit));
      dispatch(setModal("update"));
      dispatch(setBanner("idle"));
    });
  };
};

export const createMeasure = (payload) => {
  return async (dispatch) => {
    await postData("v2/unitofmeasure", payload)
      .then((res) => {
        dispatch(fetchMeasure());
        dispatch(setId(res.data.unit.id));
        dispatch(setName(res.data.unit.name));
        dispatch(setBanner("success-create"));
        dispatch(setSelected("Pilih satuan ukur"));
        dispatch(setError([]));
        setTimeout(() => {
          dispatch(setId(0));
          dispatch(setBanner("idle"));
          dispatch(setName(""));
          dispatch(setUnit(""));
        }, 4000);
        dispatch(setModal("hide"));
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 422) {
          dispatch(setError(err.response.data));
        }
      });
  };
};

export const updateMeasure = (id, payload) => {
  return async (dispatch) => {
    await putData(`v2/unitofmeasure/${id}`, payload)
      .then((res) => {
        dispatch(fetchMeasure());
        dispatch(setError([]));
        dispatch(setId(res.data.unit.id));
        dispatch(setName(res.data.unit.name));
        dispatch(setBanner("success-update"));
        setTimeout(() => {
          dispatch(setId(0));
          dispatch(setBanner("idle"));
          dispatch(setName(""));
          dispatch(setUnit(""));
        }, 4000);
        dispatch(setModal("hide"));
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};

export const dropMeasure = (id) => {
  return async (dispatch) => {
    await deleteData(`v2/unitofmeasure/${id}`).then(() => {
      dispatch(setModal("hide"));
      dispatch(setBanner("danger"));
      setTimeout(() => {
        dispatch(setBanner("idle"));
      }, 3000);
      dispatch(fetchMeasure());
    });
  };
};

export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};
export const setLimit = (limit) => {
  return {
    type: SET_LIMIT,
    limit,
  };
};
export const setPage = (page = 1) => {
  return {
    type: SET_PAGE,
    page,
  };
};
export const setSelected = (selected) => {
  return {
    type: SET_SELECTED,
    selected,
  };
};
