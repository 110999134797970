import {
  START_FETCHING_REPORT_PAYMENT,
  SUCCESS_FETCHING_REPORT_PAYMENT,
  ERROR_FETCHING_REPORT_PAYMENT,
  SET_LOCATION,
  SET_DATE
} from "./constants";

import { getDatav2 } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchReport = debounce(getDatav2, 1000);

export const startFetchingReportPayment = () => {
  return {
    type: START_FETCHING_REPORT_PAYMENT,
  };
};

export const errorFetchingPayment = () => {
  return {
    type: ERROR_FETCHING_REPORT_PAYMENT,
  };
};

export const successFetchingReportPayment = ({ payment, location }) => {
  return {
    type: SUCCESS_FETCHING_REPORT_PAYMENT,
    payment,
    location
  };
};

export const fetchReportPayment = () => {
  return async (dispatch, getState) => {

    dispatch(startFetchingReportPayment());

    try {

      let start = getState().reportPayment.date.startDate || '';
      let end = getState().reportPayment.date.endDate || '';
      let outlet_id = getState().reportPayment.location || '';

      const params = {
        start,
        end,
        outlet_id
      }

      let { data: { outlets }, } = await debouncedFetchReport("outlets");

      let select_box = [{ label: "Semua lokasi", value: "", }]
      outlets.forEach((item) => {
        select_box.push({
          label: item.name,
          value: item.id,
        });
      });



      const res = await debouncedFetchReport('v2/report/payment', params)
      let value = []
      let gross = 0
      let total_trx = 0
      for (let i = 0; i < res.data.topPayment.length; i++) {

        gross += res.data.topPayment[i].total
        total_trx += res.data.topPayment[i].count
        value.push({
          name: res.data.topPayment[i].name,
          trx_length: res.data.topPayment[i].count,
          total: res.data.topPayment[i].total,
        })
      }

      dispatch(successFetchingReportPayment({
        payment: {
          value,
          gross,
          total_trx
        },
        location: select_box
      }))

    } catch (err) {
      dispatch(errorFetchingPayment());
    }
  };
};

export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};

export const setDate = date => {
  return {
    type: SET_DATE,
    date: date.selection ? date.selection : date
  }
}