import {
  START_FETCHING_MEASURE,
  SUCCESS_FETCHING_MEASURE,
  ERROR_FETCHING_MEASURE,
  SET_KEYWORD,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_MODAL,
  SET_ID,
  SET_NAME,
  SET_UNIT,
  SET_BANNER,
  SET_ERROR,
  SET_SELECTED,
  SET_NOTIF
} from "./constant";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  select_box: [],
  id: 0,
  name: "",
  unit: "",
  keyword: "",
  selected: "Pilih satuan ukur",
  page: 1,
  limit: 10,
  totalPage: 1,
  modal: "hide",
  banner: "idle",
  error: [],
  status: statuslist.idle,
  // new update elfin sanjaya
  notif: {
    id: '',
    message: '',
    type: '',
    status: '',
    toggle: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case SET_NOTIF:
      return { ...state, notif: action.notif }

    case START_FETCHING_MEASURE:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_MEASURE:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_MEASURE:
      return {
        ...state,
        status: statuslist.success,
        data: action.measure,
        select_box: action.select_box,
      };
    case SET_ID:
      return {
        ...state,
        id: action.id,
      };
    case SET_NAME:
      return {
        ...state,
        name: action.name,
      };
    case SET_UNIT:
      return {
        ...state,
        unit: action.unit,
      };

    case SET_KEYWORD:
      return {
        ...state,
        keyword: action.keyword,
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: action.limit,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case SET_TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.totalPage,
      };
    case SET_MODAL:
      return {
        ...state,
        modal: action.modal,
      };
    case SET_BANNER:
      return {
        ...state,
        banner: action.banner,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_SELECTED:
      return {
        ...state,
        selected: action.selected,
      };

    default:
      return state;
  }
}
