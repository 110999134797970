import React from "react";

export default function BankAccount({ width, height, className, fill }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 9.99951C21.1313 9.99954 21.2614 9.9737 21.3827 9.92346C21.5041 9.87322 21.6143 9.79956 21.7072 9.70669C21.8 9.61383 21.8737 9.50357 21.9239 9.38223C21.9742 9.26089 22 9.13084 22 8.99951V5.99951C22.0001 5.78969 21.9341 5.58516 21.8114 5.41495C21.6887 5.24475 21.5155 5.1175 21.3164 5.05127L12.3164 2.05127C12.111 1.98291 11.889 1.98291 11.6836 2.05127L2.68359 5.05127C2.48449 5.1175 2.31131 5.24475 2.18861 5.41495C2.0659 5.58516 1.99991 5.78969 2 5.99951V8.99951C1.99997 9.13084 2.02581 9.26089 2.07605 9.38223C2.12629 9.50357 2.19995 9.61383 2.29282 9.70669C2.38568 9.79956 2.49594 9.87322 2.61728 9.92346C2.73862 9.9737 2.86867 9.99954 3 9.99951H4V17.1838C3.41674 17.3892 2.91137 17.7702 2.55327 18.2743C2.19517 18.7785 2.0019 19.3811 2 19.9995V21.9995C1.99997 22.1308 2.02581 22.2609 2.07605 22.3822C2.12629 22.5036 2.19995 22.6138 2.29282 22.7067C2.38568 22.7996 2.49594 22.8732 2.61728 22.9235C2.73862 22.9737 2.86867 22.9995 3 22.9995H21C21.1313 22.9995 21.2614 22.9737 21.3827 22.9235C21.5041 22.8732 21.6143 22.7996 21.7072 22.7067C21.8 22.6138 21.8737 22.5036 21.9239 22.3822C21.9742 22.2609 22 22.1308 22 21.9995V19.9995C21.9981 19.3811 21.8048 18.7785 21.4467 18.2743C21.0886 17.7702 20.5833 17.3892 20 17.1838V9.99951H21ZM20 20.9995H4V19.9995C4.00026 19.7344 4.10571 19.4802 4.29319 19.2927C4.48066 19.1052 4.73486 18.9998 5 18.9995H19C19.2651 18.9998 19.5193 19.1052 19.7068 19.2927C19.8943 19.4802 19.9997 19.7344 20 19.9995V20.9995ZM6 16.9995V9.99951H8V16.9995H6ZM10 16.9995V9.99951H14V16.9995H10ZM16 16.9995V9.99951H18V16.9995H16ZM4 7.99951V6.72021L12 4.05321L20 6.72021V7.99951H4Z"
        fill={fill}
      />
    </svg>
  );
}

BankAccount.defaultProps = {
  width: "19px",
  height: "20px",
  fill: "#8F8F8F",
};
