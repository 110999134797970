import * as  React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import Checkbox from "components/atoms/Checkbox";
import { getData } from "utils/fetchData";
import { formatRupiah } from 'utils/formatRupiah'
import ModalReason from './reason'

export default function Refund({ show, close, transaction }) {
  const [product, setProduct] = React.useState([])
  const [isReason, setIsReason] = React.useState(false)

  const onChange = (e, index) => {
    let _temp = [...product];
    _temp[index]["isChecked"] = e.target.checked;
    setProduct(_temp)
  }

  React.useEffect(() => {
    const getOneProductInTransaction = async () => {
      const res = await getData(`sales/refund/products/${transaction}`)
      let products = []
      res.data.items.forEach((data) => {
        products.push({
          id: data.id,
          name: data.name,
          total: data.total,
          isChecked: false,
        })
      })
      setProduct(products)
    }

    getOneProductInTransaction()
  }, [transaction])

  return (
    <>
      <ModalDialog
        size={"alert"}
        show={show}
        onClose={close}
        title={<h1 className="text-gray-02 heading-3">Refund</h1>}
      >
        <div className={`bg-white border-t h-full overflow-scroll`}>
          <h1 className="font-normal p-4">
            <span className="font-semibold">
              Pilih produk yang akan direfund&nbsp;
          </span>
          (Refund akan termasuk dengan jumlah variasi, diskon, dan pajak).
        </h1>
          <ul
            className="scroll-hidden overflow-y-scroll"
            style={{ height: "45vh" }}
          >
            {product.map((data, i) => (

              <li className="flex items-center justify-between px-4 border p-4">
                <div className="flex items-center">
                  {/* icon check */}
                  <div className="relative -top-3">
                    <Checkbox
                      type="default"
                      checked={data.isCheckbox}
                      name="checkbox"
                      value={data.isCheckbox}
                      size="md"
                      onChange={(e) => onChange(e, i)}
                    />
                  </div>
                  <p className="text-blue-03 ml-10">{data.name}</p>
                  {/* text */}
                </div>
                <p className="font-semibold">{formatRupiah(data.total)}</p>
              </li>

            ))}
            {/* <li className="flex items-center justify-between px-4 border p-4">
            <div className="flex items-center">
              <div className="relative -top-3">
                <Checkbox
                  type="default"
                  checked={isCheckbox}
                  name="checkbox"
                  value={isCheckbox}
                  size="md"
                  onChange={(e) => setIsCheckbox(e.target.checked)}
                />
              </div>
              <p className="text-blue-03 ml-10">Martabak variant diskon</p>

            </div>
            <p className="font-semibold">Rp. 20.900</p>
          </li> */}
          </ul>

          <div className="px-5 flex justify-end items-center bg-white border-t">
            <Button
              variant="secondary"
              color="red"
              className="mr-4"
              type="button"
              onClick={close}
            >
              Batal
            </Button>
            <Button
              onClick={() => setIsReason(true)}
              variant="primary" color="blue" className="my-4" type="button">
              Refund produk
            </Button>
          </div>
        </div>

      </ModalDialog>

      <ModalReason
        show={isReason}
        close={() => setIsReason(false)}
        closeRefund={close}
        transaction={transaction}
        productRefund={product}
      />
    </>
  );
}
