import * as React from "react";
import Button from "components/atoms/Button";
import { Link } from "react-router-dom";

function RedirectForgot() {
  const [email, setEmail] = React.useState("");
  const data = localStorage.getItem("email_user");

  React.useEffect(() => {
    setEmail(data);
  }, [data]);
  return (
    <div className="flex justify-center bg-gray-05 h-screen">
      <div className="rounded-xl bg-white px-10 self-center h-2/4 shadow-gray">
        <div className="mb-10 mt-24 text-center">
          <span>Kata sandi telah berhasil direset untuk</span>
          <br />
          <span>
            <span className="text-blue-03">{email}</span> Silahkan
          </span>
          <br />
          <span>kembali ke halaman log in untuk mengakses sello.</span>
        </div>
        <Link to="/logout">
          <Button type="submit" variant="primary" color="blue" isBlock>
            Kembali ke log in
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default RedirectForgot;
