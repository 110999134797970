import {
  START_FETCHING_LOG,
  SUCCESS_FETCHING_LOG,
  ERROR_FETCHING_LOG,
  SET_ACTIVITY,
  SET_DATE,
  SET_NOTIF,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_USER,
  SET_MODULE,
} from "./constants";
import moment from "moment";

import { getDatav2 } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchLog = debounce(getDatav2, 1000);

export const startFetchingLog = () => {
  return {
    type: START_FETCHING_LOG,
  };
};

export const successFetchingLog = ({ activities }) => {
  return {
    type: SUCCESS_FETCHING_LOG,
    activities,
  };
};

export const errorFetchingLog = () => {
  return {
    type: ERROR_FETCHING_LOG,
  };
};

export const setNotifLog = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};
export const setUser = (user) => {
  return {
    type: SET_USER,
    user,
  };
};

export const setDate = (date) => {
  return {
    type: SET_DATE,
    date: date.selection ? date.selection : date,
  };
};

export const setLimit = (limit) => {
  return {
    type: SET_LIMIT,
    limit,
  };
};
export const setPage = (page = 1) => {
  return {
    type: SET_PAGE,
    page,
  };
};
export const setTotalPage = (totalPage) => {
  return {
    type: SET_TOTAL_PAGE,
    totalPage,
  };
};
export const setActivity = (activity) => {
  return {
    type: SET_ACTIVITY,
    activity,
  };
};
export const setModule = (module) => {
  return {
    type: SET_MODULE,
    module,
  };
};

export const fetchLog = () => {
  return async (dispatch, getState) => {
    const notif = {
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      dispatch(setNotifLog(notif));
    }, 5000);

    dispatch(startFetchingLog());
    let page = getState().log.page || "";
    let limit = getState().log.limit || "";
    let module = getState().log.module || "";
    let account = getState().log.user || "";
    let start = moment(getState().log.date.startDate).format("YYYY-MM-DD") || "";
    let end = moment(getState().log.date.endDate).format("YYYY-MM-DD") || "";
    const params = {
      start,
      end,
      page,
      limit,
    };
    if (module !== "") params.module = module;
    if (account !== "") params.account = account;
    try {
      let {
        data: { activities, pages },
      } = await debouncedFetchLog("v2/report/activities", params);
      let totalPage = pages;
      dispatch(setTotalPage(totalPage));

      dispatch(successFetchingLog({ activities }));
    } catch (error) {
      dispatch(errorFetchingLog());
    }
  };
};
