import React from "react";
import Logo from "components/atoms/Logo";
import IconCheck from "components/atoms/Icons/Custom/icon new/Check";

export default function Finish() {
  return (
    <div className="h-screen w-screen relative overflow-hidden">
      {/* header */}
      <div className="fixed left-0 right-0 top-0 bg-white z-10 flex justify-center p-4">
        <div className="flex items-center">
          <Logo type="dalenta" content="icon wordmark" variant="blue" />
        </div>
      </div>
      {/* conntent */}
      <div className="h-screen w-screen overflow-hidden flex items-center justify-center bg-blue-03 fixed top-0">
        <div className="flex flex-col items-center justify-center">
          <IconCheck fill="white" width="10rem" height="10rem" />
          <p className="text-2 font-semibold mt-4 text-white text-center">
            Terima kasih atas waktu anda! Ulasan anda akan <br />
            sangat membantu untuk meningkatkan performa dan
            <br /> kinerja kami.
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
