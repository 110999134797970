import React, { useState, useEffect } from "react";
import { getData, putData } from "utils/fetchData";
import Banner from "components/atoms/Banner";

import Toggle from "components/atoms/Toggle";
import "./settingNotification.css";

export default function PageSettingNotification() {
  const [notifications, setNotifications] = useState({
    sales: false,
    report: false,
    feature: false,
    confrence: false,
  });

  const [notif, setNotif] = useState({
    message: "",
    type: "",
    status: "",
    toggle: false,
  });

  const gtSetting = async () => {
    let notif = {
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      setNotif(notif);
    }, 5000);
    await getData("v2/setting").then((res) => {
      console.log(res);
      setNotifications(res.data.setting.notifications);
    });
  };

  const updateSetting = async (payload) => {
    await putData("v2/setting", payload).then((res) => {
      console.log(res);
      if (res.data.code == 200) {
        let notif = {
          message: "Berhasil merubah setting notifikasi",
          type: "primary",
          status: "success",
          toggle: true,
        };
        setNotif(notif);
        gtSetting();
      }
    });
  };
  useEffect(() => {
    gtSetting();
  }, []);

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {notif?.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={notif.message}
          status={notif.status}
          type={notif.type}
        />
      )}
      <h1 className="font-semibold mt-4">Notifikasi email</h1>
      <p className="text-gray-02 my-2">
        Pilihlah notifikasi dan informasi apa saja yang anda ingin dikirim ke email anda
      </p>
      <ul className="bg-gray-05 mt-8">
        <li className="border p-4 flex items-start">
          {/* icon toggle */}
          <div className="relative top-1 toggle-setting-notif">
            <Toggle
              type="primary"
              checked={notifications.sales}
              onChange={() => updateSetting({ notifSales: !notifications.sales })}
            />
          </div>
          <div className="relative left-12">
            <h1 className="font-semibold">Ringkasan penjualan harian & mingguan</h1>
            <p>Terima ringkasan penjualan bisnis kamu setiap akhir hari & minggu.</p>
          </div>
          {/* paragraf */}
        </li>
        <li className="border p-4 flex items-start">
          {/* icon toggle */}
          <div className="relative top-1 toggle-setting-notif">
            <Toggle
              type="primary"
              checked={notifications.report}
              onChange={() => updateSetting({ notifReport: !notifications.report })}
            />
          </div>
          <div className="relative left-12">
            <h1 className="font-semibold">Ringkasan penjualan bulanan & tahunan</h1>
            <p>Terima ringkasan penjualan bisnis kamu setiap akhir bulan & tahun.</p>
          </div>
          {/* paragraf */}
        </li>
        <li className="border p-4 flex items-start">
          {/* icon toggle */}
          <div className="relative top-1 toggle-setting-notif">
            <Toggle
              type="primary"
              checked={notifications.feature}
              onChange={() => updateSetting({ notifNewFeature: !notifications.feature })}
            />
          </div>
          <div className="relative left-12">
            <h1 className="font-semibold">Fitur baru</h1>
            <p>
              Terus ikuti perkembangan produk dan fitur baru, pengumuman untuk penjual, dan peluang
              pengujian beta.
            </p>
          </div>
          {/* paragraf */}
        </li>
        <li className="border p-4 flex items-start">
          {/* icon toggle */}
          <div className="relative top-1 toggle-setting-notif">
            <Toggle
              type="primary"
              checked={notifications.confrence}
              onChange={() => updateSetting({ notifMeet: !notifications.confrence })}
            />
          </div>
          <div className="relative left-12">
            <h1 className="font-semibold">Program & acara</h1>
            <p>
              Dengarkan tentang penawaran khusus, acara mendatang, dan terima pesan khusus bisnis
              dari Dalenta.
            </p>
          </div>
          {/* paragraf */}
        </li>
      </ul>
    </div>
  );
}
