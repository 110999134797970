import React, { useState, useEffect, useMemo } from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";

import { getData, postData } from "utils/fetchData";
import { setNotif, fetchBank, fetchBankInBalance } from "features/Bank/actions";
import { useDispatch } from "react-redux";
import { RiAlertLine } from "react-icons/ri";

function CreateBank({ show, close }) {
  const dispatch = useDispatch();
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [bank, setBank] = useState([]);
  const [field, setField] = useState({
    name: "",
    code: "",
    number: "",
  });

  const onChange = (e) => {
    setField({
      ...field,
      number: e.target.value,
    });
  };

  const onSelect = (item) => {
    setKeyword(item.label);
    setField({
      ...field,
      name: item.label,
      code: item.value,
    });
    setIsShowDialog(false);
  };

  const searchBank = (e) => {
    setKeyword(e.target.value);
  };

  const bankOnfilter = useMemo(() => {
    if (!keyword) return bank;

    return bank.filter((bank) => {
      return bank.label.toLowerCase().includes(keyword.toLowerCase());
    });
  }, [keyword, bank]);

  const onSubmit = async () => {
    setLoading(true);
    await postData("balance/bank", field)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          let notif = {
            message: res.data.message,
            type: "primary",
            status: "success",
            toggle: true,
          };
          dispatch(setNotif(notif));
          setField({
            name: "",
            code: "",
            number: 0,
          });
          dispatch(fetchBank());
          dispatch(fetchBankInBalance());
          setLoading(false);
          close();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        if (err.response.data.code === 404) {
          alert(err.response.data.message);
        }
      });
  };

  useEffect(() => {
    const getBankAvailable = async () => {
      await getData("balance/available_bank").then((res) => {
        let temp = [];
        res.data.data.forEach((item) => {
          temp.push({
            label: item.name,
            value: item.code,
          });
        });
        setBank(temp);
      });
    };
    getBankAvailable();
  }, []);

  return (
    <ModalDialog
      hidden_overflow=""
      size={"alert"}
      show={show}
      onClose={close}
      title="Tambah rekening bank"
    >
      <div className={`bg-white p-5 h-full`}>
        <ul className="balance-bill">
          <li className="bg-gray-05 bold-text-1 py-4 px-6">Nomor rekening</li>

          <li className="bold-text-1 py-4 px-6">
            <input
              type="number"
              placeholder="Masukan nomor rekening"
              className="focus:outline-none w-full pl-2"
              value={field.number}
              onChange={onChange}
            />
          </li>

          <li className="bg-gray-05 bold-text-1 py-4 px-6 mt-4">Bank</li>

          <li className="bold-text-1 py-4 px-6">
            <input
              onClick={() => setIsShowDialog(true)}
              onChange={searchBank}
              type="text"
              placeholder="Pilih bank"
              value={keyword}
              className="focus:outline-none w-full pl-2"
            />
          </li>
          {/* show */}

          {isShowDialog ? (
            <div className="relative -top-3">
              <ul
                className="w-full absolute overflow-y-scroll scroll-hidden left-0 item-list shadow-HeavyDropShadow bg-white rounded-lg mt-3 mb-6"
                style={{ zIndex: "99999999", maxHeight: "14rem" }}
              >
                {bankOnfilter.length
                  ? bankOnfilter.map((item, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => onSelect(item)}
                        className="cursor-pointer hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm"
                      >
                        {item.label}
                      </li>
                    );
                  })
                  : ""}
              </ul>
            </div>
          ) : (
            ""
          )}
          {/* end show */}
        </ul>
      </div>
      <div className="px-5 flex justify-end items-center bg-white border-t">
        <Button
          onClick={() => close()}
          variant="primary"
          color="red"
          className="my-4 mr-4"
          type="button"
        >
          Batal
        </Button>
        <Button
          onClick={onSubmit}
          variant="primary"
          color="blue"
          loading={loading}
          disabled={field.name === "" && field.code === "" ? true : false}
          className="my-4"
          type="button"
        >
          Tambah rekening
        </Button>
      </div>
    </ModalDialog>
  );
}

export default CreateBank;
