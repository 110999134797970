import * as React from 'react'
import propTypes from 'prop-types'
import Icon from 'components/atoms/Icons/indexV2'
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import Checkbox from 'components/atoms/Checkbox'
// variant="Default"; Info Icon=False; Icon inside=False;
// variant="Default"; Info Icon=True; Icon inside=False;
// variant="Default"; Info Icon=False; Icon inside=True;
// variant="Default"; Info Icon=True; Icon inside=True;

/**
 * type select = text, disabled, text additional, checkbox, checkbox additional, radio, radio additional, toggle, toggle additional, icon, icon additional
 */

function Select(props) {
  const { label, options, onChange, variant, type, children, spacing } = props
  const [value, setValue] = React.useState(props.value)
  const dropdownRef = React.useRef(null);
  const [isShow, setIsShow] = useDetectOutsideClick(dropdownRef, false);
  const [select] = React.useState(props.value)
  const [name, setName] = React.useState('')
  const [list, setList] = React.useState(options)

  const className = [props.className]

  // select
  if (variant === 'default') className.push("hover:text-blue-03")
  if (variant === 'error') className.push("")
  if (variant === 'success') className.push("")
  if (variant === 'warning') className.push("")

  // logic select text
  const handleOptions = (item) => {
    setValue({ label: item.label, value: item.value })
    setIsShow(false)
    onChange(item)
  }

  // logic select checbox
  const handleOptionCheckbox = (item) => {
    onChange(item)
  }

  const filter = (e) => {
    let data = [...options]
    data = data.filter((option) => {
      return (
        option.label.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      )
    })
    setList(data)
    setName(e.target.value)
  }

  const handleDefaultValue = () => {
    setValue(select)
    setIsShow(false)
    onChange(select)
  }



  if (variant === 'default') {
    return (
      <div className={`${className.join(" ")} relative flex items-start `}>
        <p className={`whitespace-nowrap mr-5 relative text-sm font-semibold top-3 currentColor duration-500 ${spacing}`}>
          {label}
        </p>

        {/* {iconInfo} */}

        <div className="relative flex flex-col w-full">
          <div className={`relative w-full ${value.value === '' ? 'text-blue-03' : 'text-dark'} hover:text-dark active:text-dark`}>
            <div className={`text-field-small w-full item-select cursor-pointer bg-white duration-500 py-3 `}
              onClick={() => type === 'disabled' ? null : setIsShow(!isShow)}>
              {props.value.label}
            </div>

            {isShow === false ? (
              <Icon iconName="UilAngleDown" className={"absolute top-1/2 transform -translate-y-1/2 right-5"} />
            ) : (
              <Icon iconName="UilAngleUp" className={"absolute top-1/2 transform -translate-y-1/2 right-5"} />
            )}
          </div>

          {/* select item */}
          {isShow && (
            <ul ref={dropdownRef} className="w-full absolute top-10 z-50 left-0 item-list shadow-HeavyDropShadow overflow-hidden bg-white rounded-lg mt-3 mb-11">
              {/* START: TEXT */}
              {type === 'text' || type === 'text additional' ? <li
                className="cursor-pointer hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm"
                onClick={() => handleDefaultValue()}
              >
                {select.label}
              </li> : ''}
              {type === 'text' || type === 'text additional' ? options.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={`cursor-pointer ${type === 'text additional' && 'justify-between'} hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm`}
                    onClick={() => handleOptions(item)}
                  >
                    {item.label}
                    {type === 'text additional' && <input value={item.sub} disabled className="text-right focus:outline-none bg-transparent" placeholder="Input text here" />}
                  </li>
                );
              }) : ''}
              {/* END: TEXT */}
              {/* START: CHECKBOX */}
              {type === 'checkbox' || type === 'checkbox additional' ? options.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={`cursor-pointer ${type === 'checkbox additional' && 'justify-between'} flex  cursor-pointer hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm`}
                    onClick={() => handleOptionCheckbox(item)}
                  >
                    <Checkbox
                      type="default"
                      checked={item.isChecked}
                      name="checkbox"
                      value={item.isChecked}
                      onChange={() => handleOptionCheckbox(item)}
                      label={item.label}
                    />
                    {type === 'checkbox additional' && <input value={item.sub} disabled className="text-right focus:outline-none bg-transparent" placeholder="Input text here" />}
                  </li>
                );
              }) : ''}
              {props.btnAction}

            </ul>
          )}
          {children}
        </div>
      </div >
    )
  }

  if (variant === 'search') {
    return (
      <div className={`${className.join(" ")} relative flex items-start `}>
        <p className={`whitespace-nowrap mr-5 relative text-sm font-semibold top-3 currentColor `}>
          {label}
        </p>

        {/* {iconInfo} */}

        <div className="relative flex flex-col w-full">
          <div className={`relative w-full ${value.value === '' ? 'text-blue-03' : 'text-dark'} hover:text-dark active:text-dark`}>
            {isShow ? <div ref={dropdownRef}>
              <div className="absolute transform top-1/2 -translate-y-1/2 left-5">
                <Icon iconName="UilSearch" iconSize={10} />
              </div>
              <input
                className={`text-field-small border w-full pl-12 py-3  hover:border-blue-03 focus:border-blue-03  text-black`}
                value={name}
                name={name}
                onChange={filter}
              />
            </div>
              :
              <div className={`text-field-small w-full item-select cursor-pointer bg-white py-3 transition-none animate-none`}
                onClick={() => type === 'disabled' ? null : setIsShow(!isShow)}>
                {value.label}
              </div>
            }

            {isShow === false ? (
              <Icon iconName="UilAngleDown" className={"absolute top-1/2 transform -translate-y-1/2 right-5"} />
            ) : (
              <Icon iconName="UilAngleUp" className={"absolute top-1/2 transform -translate-y-1/2 right-5"} />
            )}
          </div>

          {/* select item */}
          {isShow && (
            <ul className="w-full absolute top-10 z-50 left-0 item-list shadow-HeavyDropShadow overflow-hidden bg-white rounded-lg mt-3 mb-11">
              <li
                className="cursor-pointer hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm"
                onClick={() => handleDefaultValue()}
              >
                {select.label}
              </li>
              {type === 'text' || type === 'text additional' ? list.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={`cursor-pointer ${type === 'text additional' && 'justify-end'} hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm`}
                    onClick={() => handleOptions(item)}
                  >
                    {item.label}
                    {type === 'text additional' && <input value={item.sub} disabled className="text-right focus:outline-none bg-transparent" placeholder="Input text here" />}
                  </li>
                );
              }) : ''}

              {type === 'checkbox' || type === 'checkbox additional' ? list.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={`cursor-pointer ${type === 'checkbox additional' && 'justify-between'} flex  cursor-pointer hover:bg-blue-05 duration-500 p-3 active:text-blue-03 text-sm`}
                    onClick={() => handleOptions(item)}
                  >
                    <Checkbox
                      type="default"
                      checked={item.isChecked}
                      name="checkbox"
                      value={item.isChecked}
                      onChange={() => handleOptions(item)}
                      label={item.label}
                    />
                    {type === 'checkbox additional' && <input value={item.sub} disabled className="text-right focus:outline-none bg-transparent" placeholder="Input text here" />}
                  </li>
                );
              }) : ''}

              {/* {BtnAction} */}
            </ul>
          )}
          {children}
        </div>
      </div>
    )
  }

}

Select.defaultProps = {
  label: 'Text field label',
  options: [{}],
  value: [{ label: 'Select item(s)', value: '' }],
  variant: 'default',
  type: 'text'
};

Select.propTypes = {
  variant: propTypes.string,
  type: propTypes.oneOf(['text', 'disabled', 'text additional', 'checkbox', 'checkbox additional', 'radio', 'radio additional', 'toggle', 'toggle additional', 'icon', 'icon additional']),
  infoIcon: propTypes.bool,
  iconInside: propTypes.bool,
  label: propTypes.string,
  onChange: propTypes.func
};

export default Select;