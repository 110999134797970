import React from "react";

export default function Home({ width, height, className, fill }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6601 8.24996L10.6601 0.249955C10.4776 0.0896359 10.243 0.0012207 10.0001 0.0012207C9.75721 0.0012207 9.52261 0.0896359 9.34012 0.249955L0.340119 8.24996C0.187155 8.38527 0.0792868 8.56417 0.0310182 8.76261C-0.0172504 8.96104 -0.00360533 9.1695 0.0701189 9.35995C0.142637 9.54789 0.270224 9.70954 0.436168 9.82374C0.602113 9.93794 0.798678 9.99936 1.00012 9.99996H2.00012V19C2.00012 19.2652 2.10548 19.5195 2.29301 19.7071C2.48055 19.8946 2.7349 20 3.00012 20H17.0001C17.2653 20 17.5197 19.8946 17.7072 19.7071C17.8948 19.5195 18.0001 19.2652 18.0001 19V9.99996H19.0001C19.2016 9.99936 19.3981 9.93794 19.5641 9.82374C19.73 9.70954 19.8576 9.54789 19.9301 9.35995C20.0038 9.1695 20.0175 8.96104 19.9692 8.76261C19.9209 8.56417 19.8131 8.38527 19.6601 8.24996ZM11.0001 18H9.00012V15C9.00012 14.7347 9.10548 14.4804 9.29301 14.2928C9.48055 14.1053 9.7349 14 10.0001 14C10.2653 14 10.5197 14.1053 10.7072 14.2928C10.8948 14.4804 11.0001 14.7347 11.0001 15V18ZM16.0001 18H13.0001V15C13.0001 14.2043 12.684 13.4412 12.1214 12.8786C11.5588 12.316 10.7958 12 10.0001 12C9.20447 12 8.44141 12.316 7.8788 12.8786C7.31619 13.4412 7.00012 14.2043 7.00012 15V18H4.00012V9.99996H16.0001V18ZM3.63012 7.99996L10.0001 2.33996L16.3701 7.99996H3.63012Z"
        fill={fill}
      />
    </svg>
  );
}

Home.defaultProps = {
  width: "20",
  height: "20",
  fill: "black",
};
