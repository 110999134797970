import * as React from "react";
import Pin from "components/atoms/Pin";
import TextCaption from "components/atoms/Text/TextCaption";

export default function PinField() {
  const onChange = (value) => {
    console.log(value);
  };

  return (
    <div className="pt-20 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="flex w-full flex-col">
        <div className="flex items-start">
          <div className="flex flex-col">
            <div className="my-2">
              <Pin
                focus
                color="blue"
                label="Pin Field label"
                onChange={onChange}
              />
            </div>
            <div className="my-2">
              <Pin
                focus
                color="gray"
                disabled
                label="Pin Field label"
                onChange={onChange}
              />
            </div>
            <div className="my-2">
              <Pin
                focus
                color="red"
                label="Pin Field label"
                onChange={onChange}
              >
                <TextCaption status="error" message="This field is required" />
              </Pin>
            </div>
            <div className="my-2">
              <Pin
                focus
                color="green"
                label="Pin Field label"
                onChange={onChange}
              >
                <TextCaption
                  status="success"
                  message="Field validation is successful"
                />
              </Pin>
            </div>
            <div className="my-2">
              <Pin
                focus
                color="orange"
                label="Pin Field label"
                onChange={onChange}
              >
                <TextCaption
                  status="warning"
                  message="This field is required"
                />
              </Pin>
            </div>
          </div>
          <div className="flex flex-col ml-20">
            <div className="my-2">
              <Pin focus color="blue" onChange={onChange} />
            </div>
            <div className="my-2">
              <Pin focus color="gray" disabled onChange={onChange} />
            </div>
            <div className="my-2">
              <Pin focus color="red" onChange={onChange}>
                <TextCaption status="error" message="This field is required" />
              </Pin>
            </div>
            <div className="my-2">
              <Pin focus color="green" onChange={onChange}>
                <TextCaption
                  status="success"
                  message="Field validation is successful"
                />
              </Pin>
            </div>
            <div className="my-2">
              <Pin focus color="orange" onChange={onChange}>
                <TextCaption
                  status="warning"
                  message="This field is required"
                />
              </Pin>
            </div>
          </div>
        </div>

        <div className="flex items-start">
          <div className="flex flex-col">
            <div className="my-2">
              <Pin
                focus
                size="sm"
                color="blue"
                label="Pin Field label"
                onChange={onChange}
              />
            </div>
            <div className="my-2">
              <Pin
                focus
                size="sm"
                color="gray"
                disabled
                label="Pin Field label"
                onChange={onChange}
              />
            </div>
            <div className="my-2">
              <Pin
                focus
                size="sm"
                color="red"
                label="Pin Field label"
                onChange={onChange}
              >
                <TextCaption status="error" message="This field is required" />
              </Pin>
            </div>
            <div className="my-2">
              <Pin
                focus
                size="sm"
                color="green"
                label="Pin Field label"
                onChange={onChange}
              >
                <TextCaption
                  status="success"
                  message="Field validation is successful"
                />
              </Pin>
            </div>
            <div className="my-2">
              <Pin
                focus
                size="sm"
                color="orange"
                label="Pin Field label"
                onChange={onChange}
              >
                <TextCaption
                  status="warning"
                  message="This field is required"
                />
              </Pin>
            </div>
          </div>
          <div className="flex flex-col ml-20">
            <div className="my-2">
              <Pin focus size="sm" color="blue" onChange={onChange} />
            </div>
            <div className="my-2">
              <Pin focus size="sm" color="gray" disabled onChange={onChange} />
            </div>
            <div className="my-2">
              <Pin focus size="sm" color="red" onChange={onChange}>
                <TextCaption status="error" message="This field is required" />
              </Pin>
            </div>
            <div className="my-2">
              <Pin focus size="sm" color="green" onChange={onChange}>
                <TextCaption
                  status="success"
                  message="Field validation is successful"
                />
              </Pin>
            </div>
            <div className="my-2">
              <Pin focus size="sm" color="orange" onChange={onChange}>
                <TextCaption
                  status="warning"
                  message="This field is required"
                />
              </Pin>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
