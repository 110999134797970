import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchCustomerInGroup } from "features/Customer/actions";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import IconEnterOnIn from "components/atoms/Icons/Custom/icon new/EnterOnIn";
import IconArrowRight from "components/atoms/Icons/Custom/icon new/Arrow";
import Spinner from "components/atoms/Spinner";

//modal
import ModalUpdate from "./update";
import ModalDelete from "./delete";
import ModalUpdateRarely from "./updateInRarely";
import ModalUpdateInPermaent from "./updateInPermanent";

export default function TableCustomerGroup({ data }) {
  const dispatch = useDispatch();
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [isShowUpdateRarely, setIsShowUpdateRarely] = useState(false);
  const [isShowUpdateInPermanent, setIsShowUpdateInPermanent] = useState(false);

  //define id group
  const [id, setId] = useState();

  const getOneGroup = (id) => {
    setId(id);
    dispatch(fetchCustomerInGroup());
    setIsShowUpdate(true);
  };

  const onDelete = (id) => {
    setId(id);
    setIsShowDelete(true);
  };

  return (
    <React.Fragment>
      <table className="w-full mt-4">
        <thead>
          <tr>
            <th>Nama Kelompok</th>
            <th colSpan={2}>Jumlah pelanggan</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-1/3">
              <div className="flex items-center">
                <IconEnterOnIn className="mr-4" />
                <p className="text-blue-03">Pembelian</p>
              </div>
            </td>
            <td colSpan="2" className="w-3/5">
              {data.criteria.inSales} pelanggan
            </td>
          </tr>
          <tr>
            <td>
              <div className="flex items-center">
                <IconEnterOnIn className="mr-4" />
                <p className="text-blue-03">Langganan tetap</p>
              </div>
            </td>
            <td colSpan="2">
              <div className="flex items-center justify-between">
                <p>{data.criteria.inPermanent} pelanggan</p>
                <div className="flex items-center">
                  <p className="text-blue-03" onClick={() => setIsShowUpdateInPermanent(true)}>
                    Edit kriteria
                  </p>
                  <IconArrowRight fill="#0066CC" width="10" height="10" className="ml-4" />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="flex items-center">
                <IconEnterOnIn className="mr-4" />
                <p className="text-blue-03">Jarang datang</p>
              </div>
            </td>
            <td colSpan="2">
              <div className="flex items-center justify-between">
                <p>{data.criteria.inRarely} pelanggan</p>
                <div className="flex items-center">
                  <p className="text-blue-03" onClick={() => setIsShowUpdateRarely(true)}>
                    Edit kriteria
                  </p>
                  <IconArrowRight fill="#0066CC" width="10" height="10" className="ml-4" />
                </div>
              </div>
            </td>
          </tr>
          {data.status === "process" ? (
            <tr key="status">
              <td colSpan="4" className="text-center">
                <div className="flex items-center justify-center">
                  <Spinner size={64} color="#859DFF" />
                </div>
              </td>
            </tr>
          ) : data.data.length ? (
            data.data.map((item) => {
              return (
                <tr key={item.id}>
                  <td>
                    <p className="text-blue-03">{item?.name}</p>
                  </td>
                  <td colSpan="2">
                    <div className="flex items-center justify-between">
                      <p>{item?.customers?.length} pelanggan</p>

                      <Dropdown variant="subtle" color="blue" content="icon only">
                        <DropdownList
                          type="button"
                          color="blue"
                          onClick={() => getOneGroup(item.id)}
                        >
                          Edit kelompok
                        </DropdownList>
                        <DropdownList type="button" color="red" onClick={() => onDelete(item.id)}>
                          Hapus kelompok
                        </DropdownList>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                <span className="text-gray-02">Tidak ditemukan data</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {isShowUpdate && (
        <ModalUpdate id={id} show={isShowUpdate} close={() => setIsShowUpdate(false)} />
      )}
      {isShowDelete && (
        <ModalDelete id={id} show={isShowDelete} close={() => setIsShowDelete(false)} />
      )}

      <ModalUpdateInPermaent
        show={isShowUpdateInPermanent}
        close={() => setIsShowUpdateInPermanent(false)}
      />
      <ModalUpdateRarely show={isShowUpdateRarely} close={() => setIsShowUpdateRarely(false)} />
    </React.Fragment>
  );
}
