import React, { useState } from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import "./index.css";
import SelectBox from "components/atoms/SelectBox";

import { useDispatch } from "react-redux";
import { fetchGroup, setNotif } from "features/CustomerGroup/actions";
import { putData } from "utils/fetchData";

const minimumSale = [
  {
    label: "3 kali",
    value: 3,
  },
  {
    label: "4 kali",
    value: 4,
  },
  {
    label: "5 kali",
    value: 5,
  },
  {
    label: "6 kali",
    value: 6,
  },
  {
    label: "7 kali",
    value: 7,
  },
  {
    label: "8 kali",
    value: 8,
  },
  {
    label: "9 kali",
    value: 9,
  },
  {
    label: "10 kali",
    value: 10,
  },
];
const minimumMonth = [
  {
    label: "1 Bulan",
    value: 1,
  },
  {
    label: "2 Bulan",
    value: 2,
  },
  {
    label: "3 Bulan",
    value: 3,
  },
  {
    label: "4 Bulan",
    value: 4,
  },
  {
    label: "5 Bulan",
    value: 5,
  },
  {
    label: "6 Bulan",
    value: 6,
  },
  {
    label: "7 Bulan",
    value: 7,
  },
  {
    label: "8 Bulan",
    value: 8,
  },
  {
    label: "9 Bulan",
    value: 9,
  },
  {
    label: "10 Bulan",
    value: 10,
  },
];

export default function UpdateInPermanent({ show, close }) {
  const dispatch = useDispatch();

  const [defaultSale, setDefaultSale] = useState({
    label: "Pilih minimum pembelian",
    value: 0,
  });

  const [defaultMonth, setDefaultMonth] = useState({
    label: "Pilih minimum durasi",
    value: 0,
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    let payload = {
      permanent_checkout: defaultSale.value,
      permanent_duration: defaultMonth.value,
    };
    await putData("setting", payload)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(fetchGroup());
          setLoading(false);
          let notif = {
            message: "Berhasil mengubah kriteria langganan tetap",
            type: "primary",
            status: "success",
            toggle: true,
          };
          dispatch(setNotif(notif));
          close();
        }
      })
      .catch((err) => console.log(err.response));
  };

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title={
        <h1 className="text-gray-02 heading-3">
          <span className="text-blue-03">Langganan tetap</span>/ Edit kriteria kelompok otomatis
        </h1>
      }
      anotherAction={
        <Button
          loading={loading}
          disabled={defaultMonth.value === 0 && defaultSale.value === 0 ? true : false}
          onClick={onSubmit}
          className="ml-4"
          variant="primary"
          color="blue"
          type="submit"
        >
          Selesai edit kriteria kelompok
        </Button>
      }
    >
      <div
        className={`bg-white border-t p-5 h-full overflow-scroll flex items-start justify-center`}
      >
        <div className="w-728px max-w-728px">
          <p className="text-gray-02 mt-9">
            Anda bisa menetapkan berapa lama langganan anda telah tidak melakukan pembelian untuk
            bisa dikelompokkan sebagai langganan yang sudah jarang datang
          </p>
          {/* start form input */}
          <ul className="mt-4 create-customer edit-langganan-tetap">
            <li className="flex w-full py-2">
              <SelectBox
                className="w-full"
                type="text"
                label="Minimal pembelian"
                value={defaultSale}
                options={minimumSale}
                onChange={(e) =>
                  setDefaultSale({
                    label: e.label,
                    value: e.value,
                  })
                }
              />
            </li>
            <li className="flex w-full py-2">
              <SelectBox
                className="w-full"
                type="text"
                label="Dalam jangka waktu"
                value={defaultMonth}
                options={minimumMonth}
                onChange={(e) =>
                  setDefaultMonth({
                    label: e.label,
                    value: e.value,
                  })
                }
              />
            </li>
          </ul>
        </div>
      </div>
    </ModalDialog>
  );
}
