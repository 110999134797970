import React from "react";
import IconArrowRight from "components/atoms/Icons/Custom/ArrowRight";
import SearchBar from "components/atoms/SearchBar";
import Button from "components/atoms/Button";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Table from "./tableDevice";
import DeviceCreate from "./create";
import { useSelector, useDispatch } from "react-redux";
import { fetchDevice, setKeyword, setLocation } from "features/Device/actions";
import EmptyCategory from "assets/icon/undraw_dropdown_menu_vv9j 1.svg";
import EmptyPage from "components/templates/Empty";
import Banner from "components/atoms/Banner";

export default function PageDevice() {
  const dispatch = useDispatch();
  const device = useSelector((state) => state.device);
  console.log("device >>")
  console.log(device)
  const [isShowCreate, setIsShowCreate] = React.useState(false);
  const [isShowSearch, setIsShowSearch] = React.useState(false);
  const [filterLocation, setFilterLocation] = React.useState({ value: '', label: 'Semua lokasi' });

  React.useEffect(() => {
    dispatch(fetchDevice());
  }, [dispatch, device.keyword, device.location, device.limit, device.page]);

  // ambil data error dan convert ke view
  if (device.status === "error") return <h1 className="mt-166">{device.error}</h1>;

  if (
    device.keyword === "" &&
    device.data.length === 0 &&
    device.status === "success"
  )
    return (
      <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-2">
        <EmptyPage
          title="Perangkat"
          text="Anda belum ada perangkat sama sekali."
          image={EmptyCategory}
          label="Tambah perangkat baru"
          onClick={() => setIsShowCreate(true)}
        />
        {/* START: CREATE */}
        <DeviceCreate
          title="Tambah perangkat baru"
          show={isShowCreate}
          close={() => setIsShowCreate(false)}
        />
        {/* END: CREATE */}
      </div>
    );


  const onSelectLocation = (data) => {
    setFilterLocation(data)
    dispatch(setLocation(data.value))
  }

  return (
    <div className="w-full h-screen overflow-hidden  mt-20 px-5">
      {/* START: NOTIF */}
      {device.notif?.toggle && device.notif.status === "danger" && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          status={device.notif.status}
          type={device.notif.type}
          message={device.notif.message}
        />
      )}
      {/* END: NOTIF */}
      {/* head content */}
      <div>
        <h1 className="heading-3 text-dark">Daftar perangkat</h1>
        <p className="text-1 text-gray-02 mt-4">
          Daftar dan status perangkat anda akan ditampilkan disini. Hanya akun
          yang mempunyai
        </p>
        <p className="text-1 text-gray-02 flex items-center">
          {" "}
          akses bisa menambahkan atau edit perangkat.
          <span className="text-blue-03 bold-text-1 flex items-center cursor-pointer">
            &nbsp; Lihat akses akun
            <IconArrowRight
              width="17"
              fill="#0066CC"
              height="17"
              className="ml-2"
            />
          </span>
        </p>
      </div>
      {/* list btn action */}
      <div className="flex items-center justify-between mt-4">
        {isShowSearch ? (
          <SearchBar
            name={device.keyword}
            value={device.keyword}
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            onClick={() => setIsShowSearch(!isShowSearch)} />
        ) : (
          <div className="flex items-center">
            <Button
              className="mr-2"
              variant="outlined"
              color="gray"
              content="icon infront"
              iconName="UilSearch"
              onClick={() => setIsShowSearch(!isShowSearch)}
            >
              Cari perangkat
            </Button>
            <Dropdown
              variant="outlined"
              color="gray"
              className="mr-1"
              content="label only"
              label={filterLocation.label}
            >
              {device.outlets.map((outlet) => {
                return (
                  <React.Fragment key={outlet.value}>
                    <DropdownList color="blue" type="button" onClick={() => onSelectLocation(outlet)}>{outlet.label}</DropdownList>
                  </React.Fragment>
                )
              })}
            </Dropdown>
          </div>
        )}
        {/* <Button
          variant="primary"
          color="blue"
          className="ml-2 whitespace-nowrap"
          onClick={() => setIsShowCreate(true)}
        >
          Tambah perangkat baru
        </Button> */}
      </div>

      {/* START: TABLE */}
      <Table status={device.status} data={device.data} />
      {/* END: TABLE */}

      <DeviceCreate show={isShowCreate} close={() => setIsShowCreate(false)} />

      {/* <h1>PageDevice</h1> */}
    </div>
  );
}
