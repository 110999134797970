import React from "react";

export default function ArrowRight({ width, fill, height, className }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3599 7.52673L6.58657 3.76007C6.5246 3.69758 6.45086 3.64798 6.36962 3.61414C6.28838 3.58029 6.20125 3.56287 6.11324 3.56287C6.02523 3.56287 5.93809 3.58029 5.85686 3.61414C5.77562 3.64798 5.70188 3.69758 5.63991 3.76007C5.51574 3.88497 5.44604 4.05394 5.44604 4.23007C5.44604 4.40619 5.51574 4.57516 5.63991 4.70007L8.93991 8.0334L5.63991 11.3334C5.51574 11.4583 5.44604 11.6273 5.44604 11.8034C5.44604 11.9795 5.51574 12.1485 5.63991 12.2734C5.70165 12.3364 5.77528 12.3865 5.85653 12.4208C5.93778 12.4552 6.02504 12.473 6.11324 12.4734C6.20144 12.473 6.2887 12.4552 6.36995 12.4208C6.4512 12.3865 6.52483 12.3364 6.58657 12.2734L10.3599 8.50673C10.4276 8.4443 10.4816 8.36854 10.5185 8.2842C10.5555 8.19987 10.5745 8.1088 10.5745 8.01673C10.5745 7.92466 10.5555 7.83359 10.5185 7.74926C10.4816 7.66493 10.4276 7.58916 10.3599 7.52673Z"
        fill={fill}
      />
    </svg>
  );
}

ArrowRight.defaultProps = {
  width: "9",
  height: "6",
  fill: "#8F8F8F",
};
