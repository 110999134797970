import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWithMaterialVariant } from "features/Stock/actions";
import { setModalSecondary, setMaterialUsed, setCheckboxData } from "features/Material/action";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import IconDelete from "components/atoms/Icons/Custom/Delete";
import { DropdownList } from "components/atoms/Dropdown/dropdown";
import MaterialChoice from "./materialChoice";
import MaterialCreate from "./materialCreate";
import _ from "lodash";

import InputTextGroupWithLabel from "components/atoms/Form/InputTextSmallGroupWithLabel";

function MaterialManage({ show, close }) {
  const dispatch = useDispatch();
  // const variant = useSelector((state) => state.inventory);
  const materials = useSelector((state) => state.materials);
  const [showCreate, setShowCreate] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState([]);

  const openMaterialChoose = () => {
    let _localData = localStorage.getItem("variantUsed");
    setData(JSON.parse(_localData));
    dispatch(setModalSecondary("show-material-global"));
  };

  const onChecked = (items) => {
    let _abs = Math.floor(Math.random() * 100 + 1);
    let _arr = [];

    materials?.material_used.forEach((items) => {
      _arr.push({
        id: null,
        abstractId: _abs,
        name: items.name,
        rawMaterialId: items.rawMaterialId,
        unit: items.unit,
        value: items.value,
      });
    });
    setData(
      data?.map((current) => {
        if (items.variantOptionId === current.variantOptionId) {
          current.isChecked = !current.isChecked;
          current.rawMaterial = _arr;
        }
        return current;
      })
    );
    setDisabled(false);
  };

  const onSubmit = () => {
    dispatch(setWithMaterialVariant(data));
    close();
  };

  const onChange = (e, index) => {
    let _temp = [...materials.material_used];
    _temp[index].value = parseInt(e.target.value);
    dispatch(setMaterialUsed(_temp));
  };

  const onDelete = (id) => {
    let _temp = [...materials.material_used];

    _temp.splice(
      _temp.findIndex((e) => e.rawMaterialId === id),
      1
    );

    let _checkbox = [...materials.materials_checkbox];
    _checkbox.forEach((item) => {
      if (item.rawMaterialId === id) {
        item.isChecked = false;
        item.value = 0;
      }
    });

    dispatch(setCheckboxData(_checkbox));
    dispatch(setMaterialUsed(_temp));
  };

  // console.log("variant");
  // console.log(variant);
  // console.log("data");
  // console.log(data);

  return (
    <>
      <ModalDialog
        size={"medium"}
        show={show}
        onClose={() => close()}
        title={<p className="text-dark heading-3">Tambah bahan baku</p>}
      >
        <div className="bg-gray-05 h-full relative overflow-scroll">
          <div className="grid grid-cols-2 p-6 gap-6 bg-transparent" style={{ height: "73vh" }}>
            <div className="col-span-1">
              {/* head title */}
              <div style={{ height: "10%" }}>
                <p className="bold-text-1 text-gray-02">Bahan baku untuk ditambah:</p>
              </div>
              <div className="relative" style={{ height: "90%" }}>
                <div
                  className="bg-white overflow-y-scroll rounded-lg border border-gray-04 px-6 py-4"
                  style={{ height: "50vh" }}
                >
                  <ul>
                    {materials?.material_used?.map((current, index) => {
                      return (
                        <li
                          key={current?.rawMaterialId}
                          className="flex items-center justify-between mt-4"
                        >
                          <p className="text-2">{current?.name}</p>
                          <div className="flex items-center">
                            <InputTextGroupWithLabel
                              name={current?.id}
                              status="default"
                              content="text"
                              placeholder=""
                              category={current?.unit?.unit}
                              type="number"
                              width="w-32"
                              value={current?.value}
                              onChange={(e) => onChange(e, index)}
                            />
                            <span
                              onClick={() => {
                                onDelete(current.rawMaterialId);
                              }}
                            >
                              <IconDelete className="ml-6" />
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <Button
                  variant="primary"
                  color="green"
                  type="button"
                  className="absolute bottom-0 left-0 w-full"
                  onClick={openMaterialChoose}
                >
                  Pilih bahan baku
                </Button>
              </div>
            </div>
            <div className="col-span-1">
              {/* head title */}
              <div style={{ height: "10%" }}>
                <div className="flex justify-between">
                  <p className="bold-text-1 text-gray-02">Tambah bahan baku ke:</p>
                  {/* <div className="relative right-10 top-1">
              <Checkbox
                type="default"
                // checked={isCheckbox}
                name="checkbox"
                // value={isCheckbox}
                // onChange={(e) => setIsCheckbox(e.target.checked)}
              />
            </div> */}
                </div>
              </div>
              <div className="relative" style={{ height: "90%" }}>
                <div
                  className="bg-white overflow-y-scroll rounded-lg border border-gray-04"
                  style={{ height: "59vh" }}
                >
                  <ul>
                    {materials.material_used.length
                      ? data?.map((items) => {
                          return (
                            <li key={items.id} className="flex justify-between pl-6 py-4">
                              <DropdownList
                                onClick={() => onChecked(items)}
                                checked={items.isChecked}
                                list="checkbox"
                                width="w-full"
                              >
                                {items.name}
                              </DropdownList>
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end px-6 py-4 bg-white mt-6 absolute bottom-0 left-0 w-full">
            <Button
              disabled={disabled}
              onClick={onSubmit}
              variant="primary"
              color="blue"
              type="button"
            >
              Selesai tambah bahan baku
            </Button>
          </div>
        </div>
      </ModalDialog>
      <MaterialChoice
        close={() => dispatch(setModalSecondary("hide-material-global"))}
        show={materials.modal_secondary === "show-material-global" ? true : false}
        create={() => {
          dispatch(setModalSecondary("hide-material-global"));
          setShowCreate(true);
        }}
      />
      <MaterialCreate
        show={showCreate}
        close={() => {
          setShowCreate(false);
          dispatch(setModalSecondary("show-material-global"));
        }}
      />
    </>
  );
}

export default MaterialManage;
