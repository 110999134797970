import React from "react";
import Table from "components/molecules/Table";

const data = [
  {
    label1: "label 1",
    label2: "label 2",
    label3: "label 3",
    label4: "label 4",
    label5: "label 5",
  },
  {
    label1: "label 1",
    label2: "label 2",
    label3: "label 3",
    label4: "label 4",
    label5: "label 5",
  },
  {
    label1: "label 1",
    label2: "label 2",
    label3: "label 3",
    label4: "label 4",
    label5: "label 5",
  },
  {
    label1: "label 1",
    label2: "label 2",
    label3: "label 3",
    label4: "label 4",
    label5: "label 5",
  },
  {
    label1: "label 1",
    label2: "label 2",
    label3: "label 3",
    label4: "label 4",
    label5: "label 5",
  },
];

export default function Tables() {
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="mr-5">
        <Table
          thead={["label 1", "label 2", "label 3", "label 4", "label 5"]}
          data={data}
          tbody={["label1", "label2", "label3", "label4", "label5"]}
        />
      </div>
    </div>
  );
}
