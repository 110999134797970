import {
  START_FETCHING_DASHBOARD,
  SUCCESS_FETCHING_DASHBOARD,
  ERROR_FETCHING_DASHBOARD,
  SET_LOCATION,
  SET_DATE,
  SET_BRAND,
  SET_CHART_PAYMENT,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: {},
  chart: { label: [], data: [], count: [] },
  chartPayment: { label: [], data: [], color: [], total: 0 },
  status: statuslist.idle,
  date: {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  },
  location: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_DASHBOARD:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_DASHBOARD:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_DASHBOARD:
      return {
        ...state,
        status: statuslist.success,
        chart: action.chartView,
        data: action.dashboard,
      };
    case SET_LOCATION:
      return { ...state, location: action.location };
    case SET_BRAND:
      return { ...state, brand: action.brand };
    case SET_CHART_PAYMENT:
      return { ...state, chartPayment: action.chartPayment };

    case SET_DATE:
      return { ...state, date: action.date };
    default:
      return state;
  }
}
