import * as React from "react";
import ProgressBar from "components/atoms/ProgressBar";

export default function ProgressBars() {
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div>
        <ProgressBar color="gray" progress={"w-1/3"} />
      </div>
      <div className="mt-5">
        <ProgressBar color="blue" progress={"w-1/3"} />
      </div>
      <div className="mt-5">
        <ProgressBar color="green" progress={"w-1/3"} />
      </div>
      <div className="mt-5">
        <ProgressBar color="red" progress={"w-1/3"} />
      </div>
      <div className="mt-5">
        <ProgressBar color="orange" progress={"w-1/3"} />
      </div>
      <div className="mt-5">
        <ProgressBar color="purple" progress={"w-1/3"} />
      </div>
    </div>
  );
}
