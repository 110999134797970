import React from "react";
import Snipper from "components/atoms/Spinner";

export default function Spinners() {
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="flex items-center">
        <div className="my-2">
          <Snipper size={24} color="#859DFF" />
        </div>
        <div className="my-2">
          <Snipper size={24} color="#C2C2C2" />
        </div>
        <div className="my-2">
          <Snipper size={24} color="#62F8B0" />
        </div>
        <div className="my-2">
          <Snipper size={24} color="#FF8589" />
        </div>
        <div className="my-2">
          <Snipper size={24} color="#FFB185" />
        </div>
        <div className="my-2">
          <Snipper size={24} color="#DA85FF" />
        </div>
      </div>
      <div className="flex items-center mt-5">
        <div className="my-2">
          <Snipper size={32} color="#859DFF" />
        </div>
        <div className="my-2">
          <Snipper size={32} color="#C2C2C2" />
        </div>
        <div className="my-2">
          <Snipper size={32} color="#62F8B0" />
        </div>
        <div className="my-2">
          <Snipper size={32} color="#FF8589" />
        </div>
        <div className="my-2">
          <Snipper size={32} color="#FFB185" />
        </div>
        <div className="my-2">
          <Snipper size={32} color="#DA85FF" />
        </div>
      </div>
      <div className="flex items-center mt-5">
        <div className="my-2">
          <Snipper size={64} color="#859DFF" />
        </div>
        <div className="my-2">
          <Snipper size={64} color="#C2C2C2" />
        </div>
        <div className="my-2">
          <Snipper size={64} color="#62F8B0" />
        </div>
        <div className="my-2">
          <Snipper size={64} color="#FF8589" />
        </div>
        <div className="my-2">
          <Snipper size={64} color="#FFB185" />
        </div>
        <div className="my-2">
          <Snipper size={64} color="#DA85FF" />
        </div>
      </div>
    </div>
  );
}
