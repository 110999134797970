import * as  React from 'react'
import { getData, postData, putData } from 'utils/fetchData'
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import ImageField from "components/atoms/ImageField";
import Button from "components/atoms/Button";
import Banner from "components/atoms/Banner";

export default function DeviceScreensaver() {

  const [field, setField] = React.useState({
    welcome_text: '',
    instruction_text: '',
    res: [{ id: '', image: '' }]
  })

  console.log("field >>")
  console.log(field)

  const [notif, setNotif] = React.useState({
    message: "",
    type: "",
    status: "",
    toggle: false,
  })

  const fetchSettingDevice = async () => {
    let notif = {
      message: "",
      type: "",
      status: "",
      toggle: false,
    };
    setTimeout(() => {
      setNotif(notif);
    }, 5000);

    const res = await getData(`v2/setting/`)
    if (res.data.code === 200) {
      let image = []
      res.data.setting.device.screensaver.res.forEach((res, i) => {
        image.push({
          id: i += 1,
          image: res
        })

      });

      console.log("res >>")
      console.log(res)
      setField({
        welcome_text: res?.data?.setting?.device?.screensaver.welcome_text,
        instruction_text: res?.data?.setting?.device?.screensaver.instruction_text,
        res: image,

      })
    }
  }
  React.useEffect(() => {
    fetchSettingDevice()
  }, [])

  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value })
  }

  const updateScreensaver = async (payload) => {

    handleUpdateSetting()
  }

  const onDeleteImage = async (id) => {
    let apps = [...field.res];
    var removeIndex = apps
      .map(function (item) {
        return item.id;
      })
      .indexOf(id);
    apps.splice(removeIndex, 1);

    setField({ ...field, res: apps })


    let image = []

    for (let i = 0; i < apps.length; i++) {
      image.push(apps[i].image)
    }

    let screensaver = { screensaver: { welcome_text: field.welcome_text, instruction_text: field.instruction_text, res: image } }

    const res = await putData("v2/setting", screensaver)

    if (res.data.code === 200) {
      setNotif({
        ...notif, message: "Berhasil hapus gambar screeensaver",
        type: "primary",
        status: "success",
        toggle: true,
      })

      fetchSettingDevice()
    }

  };


  const handleChangeImage = async (e, i) => {
    if (
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      e.persist();
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      const res = await postData('upload/single', formData)

      const _temp = [...field.res];

      _temp[i][e.target.name] = `${res.data.data.path}`;

      setField({ ...field, res: _temp })

      let image = []
      _temp.forEach(data => {
        image.push(data.image)
      })

      let screensaver = { screensaver: { welcome_text: field.welcome_text, instruction_text: field.instruction_text, res: image } }

      const response = await putData("v2/setting", screensaver)

      if (response.data.code === 200) {
        setNotif({
          ...notif, message: "Berhasil ubah gambar screeensaver",
          type: "primary",
          status: "success",
          toggle: true,
        })

        window.location.reload();
      }
    } else {
      alert('format tidak harus jpg, png dan jpeg')
    }


  }


  const handlePlusImage = async () => {
    let res = [...field.res]
    let no = 1

    res.forEach(data => {
      no = data.id
    })

    let data = {
      id: (no + 1),
      image: 'https://api.dalenta.tech/public/default.jpg'
    }
    res.push(data)
    setField({ ...field, res: res })

    let image = []
    res.forEach(data => {
      image.push(data.image)
    })

    let screensaver = { screensaver: { welcome_text: field.welcome_text, instruction_text: field.instruction_text, res: image } }

    const response = await putData("v2/setting", screensaver)

    if (response.data.code === 200) {
      setNotif({
        ...notif, message: "Berhasil tambah gambar screeensaver",
        type: "primary",
        status: "success",
        toggle: true,
      })

      fetchSettingDevice()
    }

  }

  const handleUpdateSetting = async () => {

    let image = []

    for (let i = 0; i < field.res.length; i++) {
      image.push(field.res[i].image)
    }


    let screensaver = { screensaver: { welcome_text: field.welcome_text, instruction_text: field.instruction_text, res: image } }

    const res = await putData("v2/setting", screensaver)

    if (res.data.code === 200) {
      setNotif({
        ...notif, message: "Berhasil ubah screeensaver",
        type: "primary",
        status: "success",
        toggle: true,
      })

      fetchSettingDevice()
    }
  }



  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {notif.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={notif.message}
          status={notif.status}
          type={notif.type}
        />
      )}
      <div>
        <h1 className="heading-3 text-dark">Screensaver</h1>
        <p className="text-1 text-gray-02 mt-4">
          Screensaver akan ditampilkan sewaktu kondisi perangkat sedang diam dan tidak digunakan. Disini anda bisa kustomisasi apa yang bisa ditampilkan screensaver seperti kalimat dan foto promosi.
        </p>
      </div>
      <div className="mt-10">
        <InputTextLargeWithLabel
          value={field?.welcome_text}
          className="w-full"
          name="welcome_text"
          placeholder="Cth: Selamat datang di Dalenta Restaurant! "
          onChange={onChange}
          label="Kalimat sapaan"
          status="default"
          onBlur={() => updateScreensaver()}
        />
      </div>
      <div className="mt-5">
        <InputTextLargeWithLabel
          value={field?.instruction_text}
          className="w-full"
          name="instruction_text"
          placeholder="Ketuk di mana saja di layar untuk memesan"
          onChange={onChange}
          label="Instruksi  mulai"
          status="default"
          onBlur={() => updateScreensaver()}
        />
      </div>
      {field?.res.map((res, i) => (
        <div className="mt-5">
          <ImageField
            type="default"
            label=""
            action
            value={res.image}
            name="image"
            onChange={(e) => handleChangeImage(e, i)}
            onClick={() => onDeleteImage(res.id)}
          />
        </div>))}
      <Button
        className="my-5"
        variant="primary"
        color="blue"
        onClick={() => { handlePlusImage() }}
      >
        Tambah
      </Button>
    </div >
  )
}
