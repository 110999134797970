import {
  START_FETCHING_TAX,
  ERROR_FETCHING_TAX,
  SUCCESS_FETCHING_TAX,
  SET_PAGE,
  SET_KEYWORD,
  SET_NOTIF,
  SET_LIMIT
} from "./constants";
import { userLogout } from 'features/Auth/actions'

import { getData } from 'utils/fetchData'
import debounce from "debounce-promise";

let debouncedFetchTax = debounce(getData, 1000);

export const startFetchingTax = () => {
  return {
    type: START_FETCHING_TAX,
  };
};

export const errorFetchingTax = () => {
  return {
    type: ERROR_FETCHING_TAX,
  };
};

export const successFetchingTax = ({ tax }) => {
  return {
    type: SUCCESS_FETCHING_TAX,
    tax

  };
};

export const fetchTax = () => {
  return async (dispatch, getState) => {
    try {

      const notif = {
        message: '',
        type: '',
        status: '',
        toggle: false
      }

      setTimeout(() => {
        dispatch(setNotif(notif))
      }, 5000)

      let keyword = getState().tax.keyword || ''

      dispatch(startFetchingTax());
      const params = {
        name: keyword,
      }
      const res = await debouncedFetchTax('tax', params);

      if (res.data?.code === 401) {
        dispatch(userLogout())
      }

      dispatch(successFetchingTax({ tax: res.data.data }));

    } catch (err) {
      dispatch(errorFetchingTax());
    }
  };
};

export const setKeyword = (keyword) => {
  return {
    type: SET_KEYWORD,
    keyword,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

export const setPage = (number = 1) => {
  return {
    type: SET_PAGE,
    page: number
  }
}

export const setPerPage = (limit) => {
  return {
    type: SET_LIMIT,
    limit: limit
  }
}

