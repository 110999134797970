import React, { useState } from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import TextCaption from "components/atoms/Text/TextCaption";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { postData, putData } from "utils/fetchData";

const PasswordSchema = yup.object().shape({
  password: yup.string().required("Kata sandi tidak cocok"),
  new_password: yup.string().required("Kata sandi tidak cocok"),
  retype_password: yup.string().required("Kata sandi tidak cocok"),
});

export default function ResetPassword({ show, close, setNotif }) {
  const { register, errors, setError } = useForm({
    mode: "onClick",
    validationSchema: PasswordSchema,
  });

  const [loading, setLoading] = useState(false);

  const [oldPassword, setoldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");
  const [isValidate, setIsValidate] = useState(false);

  const validatePassword = async () => {
    setLoading(true);
    let payload = { password: oldPassword };
    await postData("setting/password", payload)
      .then((res) => {
        if (res.data.code === 422) {
          setError("password", "password", "Kata sandi tidak cocok");
          setIsValidate(false);
          setLoading(false);
        } else {
          setIsValidate(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  };

  const reNewPassword = async () => {
    setLoading(true);
    let payload = { password: newPassword };
    await putData("v2/setting/password", payload)
      .then((res) => {
        if (res.data.code === 422) {
          setError(
            "new_password",
            "new_password",
            "Kata sandi tidak cocok"
          );
          setLoading(false);
        } else {
          setNotif();
          close();
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          err.response.data.forEach((item) => {
            setError("new_password", "new_password", item.message);
            setError(
              "retype_password",
              "retype_password",
              item.message
            );
          });
          setLoading(false);
        }
        console.log(err.response);
        setLoading(false);
      });
  };

  return (
    <ModalDialog
      size={"alert"}
      show={show}
      onClose={close}
      title={
        <h1 className="text-gray-02 heading-3">Ubah kata sandi</h1>
      }
    >
      <div
        className={`bg-white p-5 h-full overflow-scroll scroll-hidden`}
      >
        <div className="p-5">
          {!isValidate && (
            <InputTextLargeWithLabel
              name="old_password"
              label="Kata sandi sekarang"
              register={register}
              status={errors?.password ? "error" : "default"}
              onChange={(e) => setoldPassword(e.target.value)}
              value={oldPassword}
            >
              {errors.password && (
                <div className="flex items-center mt-1">
                  <TextCaption
                    status="error"
                    message={errors.password?.message}
                  />
                </div>
              )}
            </InputTextLargeWithLabel>
          )}
          {isValidate && (
            <>
              <InputTextLargeWithLabel
                label="Kata sandi baru"
                register={register}
                status={errors?.new_password ? "error" : "default"}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full"
                value={newPassword}
              >
                {errors.new_password && (
                  <div className="flex items-center mt-1">
                    <TextCaption
                      status="error"
                      message={errors.new_password?.message}
                    />
                  </div>
                )}
              </InputTextLargeWithLabel>
              <InputTextLargeWithLabel
                label="Ulangi kata sandi baru"
                register={register}
                status={errors?.retype_password ? "error" : "default"}
                onChange={(e) => setRetypeNewPassword(e.target.value)}
                className="mt-5 w-full"
                value={retypeNewPassword}
              >
                {errors.retype_password && (
                  <div className="flex items-center mt-1">
                    <TextCaption
                      status="error"
                      message={errors.retype_password?.message}
                    />
                  </div>
                )}
              </InputTextLargeWithLabel>
            </>
          )}
        </div>
      </div>
      <div className="px-5 flex justify-end items-center bg-white border-t">
        <Button
          onClick={close}
          variant="primary"
          color="red"
          className="my-5 mr-4"
          type="button"
        >
          Batal
        </Button>
        {isValidate ? (
          <Button
            loading={loading}
            disabled={
              newPassword === retypeNewPassword &&
              newPassword.length > 0
                ? false
                : true
            }
            onClick={reNewPassword}
            variant="primary"
            color="blue"
            className="my-5"
            type="button"
          >
            Selesai ubah kata sandi
          </Button>
        ) : (
          <Button
            loading={loading}
            onClick={validatePassword}
            variant="primary"
            color="blue"
            className="my-5"
            type="button"
          >
            Lanjut
          </Button>
        )}
      </div>
    </ModalDialog>
  );
}
