import React from "react";
import IconClose from "components/atoms/Icons/Custom/Closes";
import propTypes from "prop-types";
import Button from "components/atoms/Button";

function ModalDialog(props) {
  const {
    title,
    show,
    children,
    onClose,
    size,
    anotherAction,
    overflow,
    backgroundColor,
    labelClose,
    justify,
    textCenter,
    hidden_overflow,
  } = props;
  const className = [];
  if (size === "large") className.push("max-w-screen w-screen h-screen");
  if (size === "medium") className.push("max-w-964 w-964 h-screen rounded-lg");
  if (size === "small") className.push("max-w-540 w-540 h-screen rounded-lg");
  if (size === "alert")
    className.push("max-w-540 w-540 transform -translate-y-1/2 top-1/2 rounded-lg");
  if (size === "default")
    className.push(
      "max-w-2/3 w-2/3 top-1/2 transform -translate-y-1/2 overflow-y-scroll  scroll-hidden max-h-78vh rounded-lg"
    );

  return (
    <div
      className={`fixed left-0 right-0 z-50 bg-dark bg-opacity-50 duration-300 transition-all ${
        show ? "top-0 bottom-0" : "-top-full"
      } `}
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className={`fixed left-1/2 transform -translate-x-1/2 flex flex-col bg-white-400 justify-between mx-auto duration-300 transition-all rounded-lg ${hidden_overflow} ${overflow} ${
          show ? "top-0" : "-top-full"
        } ${className.join(" ")}`}
      >
        <div
          className={`flex w-full items-center bg-white  px-6 py-4 rounded-tr-lg  rounded-tl-lg relative ${
            anotherAction ? "justify-between" : "justify-center"
          }`}
        >
          <div
            className="btn-icons absolute left-5 text-gray-03 hover:text-gray-02 active:text-gray-01 bg-gray-05 p-2"
            onClick={onClose}
          >
            <IconClose fill={"currentColor"} />
          </div>
          {textCenter}

          <span className={`text-lg text-gray-02 font-semibold ${anotherAction ? "ml-12" : ""}`}>
            {title}
          </span>
          {anotherAction}
        </div>
        {children}
      </div>
    </div>
  );
}

ModalDialog.defaultProps = {
  backgroundColor: "#adadadd9",
  width: "w-964",
  maxWidth: "max-w-964",
  title: "Text modal title",
  content: "Content modal",
  height: "h-screen",
  textButton1: "Seondary action",
  textButton2: "Primary action",
  position: "-top-full",
  hidden_overflow: "overflow-hidden",
};

ModalDialog.propTypes = {
  header: propTypes.bool,
  content: propTypes.string,
  footer: propTypes.bool,
};

export default ModalDialog;
