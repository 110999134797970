import React, { useState } from "react";
import Spinner from "components/atoms/Spinner";
import ModalUpdate from "./update";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";

import { deleteData } from "utils/fetchData";
import { fetchLocations, setNotif } from "features/Location/actions";
import { useDispatch } from "react-redux";

// 1. lokasi fisik, 2 lokasi digital

export default function TableTambahLokasi({ data }) {
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const [isShowUpdate, setIsShowUpdate] = useState(false);

  const openSingleLocation = (id) => {
    setId(id);
    setIsShowUpdate(true);
  };

  const dropLocation = async (id) => {
    await deleteData(`v2/location/${id}`).then((res) => {
      let notif = {
        message: res.data.message,
        type: "primary",
        status: "danger",
        toggle: true,
      };
      dispatch(setNotif(notif));
      dispatch(fetchLocations());
    });
  };

  return (
    <React.Fragment>
      <table className="w-full mt-4">
        <thead>
          <tr>
            <th>Nama lokasi</th>
            <th>Alamat lokasi</th>
            <th>Nomor telepon</th>
            <th>Email</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {data?.status === "process" ? (
            <tr key="status">
              <td colSpan="4" className="text-center">
                <div className="flex items-center justify-center">
                  <Spinner size={64} color="#859DFF" />
                </div>
              </td>
            </tr>
          ) : data?.data?.length ? (
            data.data.map((item) => {
              return (
                <tr key={item.outletId}>
                  <td onClick={() => openSingleLocation(item.outletId)} className="text-blue-03">
                    {item.outletName}
                  </td>
                  <td>-</td>
                  <td>{item.outletPhone}</td>
                  <td>{item.outletEmail}</td>
                  <td>
                    <Dropdown product="subtle" color="blue" content="icon only">
                      <DropdownList
                        onClick={() => openSingleLocation(item.outletId)}
                        type="button"
                        color="blue"
                      >
                        Edit lokasi
                      </DropdownList>

                      <DropdownList
                        onClick={() => dropLocation(item.outletId)}
                        type="button"
                        color="red"
                      >
                        Hapus lokasi
                      </DropdownList>
                    </Dropdown>
                  </td>
                </tr>
              );
            })
          ) : (
            ""
          )}
        </tbody>
      </table>
      {isShowUpdate && (
        <ModalUpdate idLocation={id} show={isShowUpdate} close={() => setIsShowUpdate(false)} />
      )}
    </React.Fragment>
  );
}
