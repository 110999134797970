import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";

function StockUpdate({ show, close, data, handleChange, operator, handleSubmit }) {
  return (
    <ModalDialog size={"default"} show={show} onClose={() => close()} title="Edit stok bahan baku">
      <div className="p-3 bg-white">
        <table>
          <thead>
            <tr>
              <th>Lokasi stok</th>
              <th>Stok sekarang</th>
              <th>Alasan</th>
              <th>Perubahan</th>
              <th>Stok baru</th>
            </tr>
          </thead>
          <tbody className="font-12 bg-white">
            {data.stocks.length > 0 ? (
              data.stocks.map((item) => {
                return (
                  <tr key={item.outletId}>
                    <td>
                      <span className="d-flex" style={{ width: 150 }}>
                        {item.outletName}
                      </span>
                    </td>
                    <td>
                      <input
                        data-value={item.outletId}
                        value={item.beforeStock}
                        onChange={handleChange}
                        className="input-material"
                        disabled
                      />
                    </td>
                    <td>
                      <span className="d-flex" style={{ width: 150 }}>
                        <select
                          name="reasonId"
                          data-value={item.outletId}
                          onChange={handleChange}
                          className="input-material"
                          value={item.reasonId}
                        >
                          {data.reason.map((reason) => (
                            reason.title !== 'Sales' && (
                              <option value={reason.id} key={reason.id}>
                                {reason.title}
                              </option>)
                          ))}
                        </select>
                      </span>
                    </td>
                    <td>
                      <span className="d-flex" style={{ width: 150 }}>
                        {item.changeStock > 0 && operator === "MINUS" ? "-" : ""}{" "}
                        <input
                          name="changeStock"
                          onChange={handleChange}
                          type="number"
                          data-value={item.outletId}
                          value={item.changeStock}
                          className="input-material"
                          disabled={operator === "RESET" ? true : false}
                        />
                      </span>
                    </td>
                    <td>
                      <input
                        onChange={handleChange}
                        name="afterStock"
                        type="number"
                        data-value={item.outletId}
                        value={item.afterStock}
                        className="input-material"
                        disabled={operator === "PLUS" ? true : false}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan="6" className="p-0">
                <div className="d-flex justify-content-center w-100 py-5">
                  <span>Data not found</span>
                </div>
              </td>
            )}
          </tbody>
        </table>
      </div>
      <div className="px-5 flex justify-between items-center bg-white ">
        <Button
          onClick={() => close()}
          variant="secondary"
          color="blue"
          className="my-5 mx-2"
          type="button"
        >
          Buang perubahan
        </Button>
        <Button
          onClick={handleSubmit}
          variant="primary"
          color="blue"
          className="my-5"
          type="submit"
        >
          Selesai edit stok bahan baku
        </Button>
      </div>
    </ModalDialog>
  );
}

export default StockUpdate;
