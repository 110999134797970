import React from "react";
import { InputDate } from "components/atoms/Form/InputDate";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Button from "components/atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReportTrend,
  setLocation,
  setDate,
} from "features/ReportTrend/actions";
import { formatRupiah } from "utils/formatRupiah";
import moment from "moment";
import Spinner from "components/atoms/Spinner";
import "pages/Dashboard/style.css";
import Graphic from "components/molecules/Charts";

export default function PageReportTrend() {
  const dispatch = useDispatch();
  const report = useSelector((state) => state.reportTrend);
  console.log("report >>");
  console.log(report);

  const [filterLocation, setFilterLocation] = React.useState({
    value: "",
    label: "Semua lokasi",
  });
  const [filterDay, setFilterDay] = React.useState({
    value: "Hari yang sama tahun lalu",
    label: "Hari yang sama tahun lalu",
  });
  const [filterWeek, setFilterWeek] = React.useState({
    value: "",
    label: "Pilih minggu",
  });

  React.useEffect(() => {
    dispatch(fetchReportTrend());
  }, [dispatch, report.location, report.compare]);

  const onSelectLocation = (data) => {
    setFilterLocation(data);
    dispatch(setLocation(data.value));
  };

  if (report.status === "process") {
    return (
      <div className="flex items-center justify-center mt-56">
        <Spinner size={64} color="#859DFF" />
      </div>
    );
  }

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {/* header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <InputDate
            variant="single"
            type="default"
            date={report.compare}
            onChange={(date) => dispatch(setDate(date))}
          />
          <div className="ml-4">
            <Dropdown
              variant="outlined"
              color="gray"
              className="mr-1"
              content="label only"
              label={filterLocation.label}
            >
              {report.outlets.map((outlet) => {
                return (
                  <React.Fragment key={outlet.value}>
                    <DropdownList
                      color="blue"
                      type="button"
                      onClick={() => onSelectLocation(outlet)}
                    >
                      {outlet.label}
                    </DropdownList>
                  </React.Fragment>
                );
              })}
            </Dropdown>
          </div>
        </div>
        {/* <Button variant="outlined" color="blue">
          Export laporan
        </Button> */}
      </div>

      {/* Tren penjualan harian */}
      <div className="mt-4 bg-blue-03">
        <h1 className="bold-text-1 text-white p-4">Tren penjualan harian</h1>
      </div>
      <div className="flex items-center my-4">
        <h1 className="bold-text-1 flex items">
          {moment(report.compare).format("DD/MM/YYYY")} dibandingkan dengan
        </h1>
        <Dropdown
          variant="textual"
          color="blue"
          className="mr-1"
          content="label only"
          label={filterDay.label}
        >
          <DropdownList
            onClick={() => {
              setFilterDay({
                label: "Hari yang sama tahun lalu",
                value: "Hari yang sama tahun lalu",
              });
              dispatch(setDate(new Date(moment().add(-1, "y").format())));
            }}
          >
            Hari yang sama tahun lalu
          </DropdownList>
          <DropdownList
            onClick={() => {
              setFilterDay({
                label: "Hari yang sama minggu lalu",
                value: "Hari yang sama minggu lalu",
              });
              dispatch(setDate(new Date(moment().add(-1, "week").format())));
            }}
          >
            Hari yang sama minggu lalu
          </DropdownList>
        </Dropdown>
      </div>
      <ul>
        <li>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {/* <IconArrowR className="mr-2" /> */}
              <p
                className={`text-1 ${
                  report.totalDayNow > report.totalDayOld
                    ? `text-green-03`
                    : `text-red-03`
                }`}
              >
                {formatRupiah(
                  `${
                    report.totalDayNow > report.totalDayOld
                      ? report.totalDayNow - report.totalDayOld
                      : report.totalDayOld - report.totalDayNow
                  }`
                )}
              </p>
            </div>
            <div className="flex items-center w-64 justify-end">
              <div className="w-4 h-4 rounded-full bg-blue-03 mr-2"></div>
              <p className="text-1">
                {moment(report.compare).format("DD/MM/YYYY")} (
                {formatRupiah(report.totalDayNow)})
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {/* <IconArrowR className="mr-2" /> */}
              <p
                className={`text-1 ${
                  report.totalDayNow > report.totalDayOld
                    ? `text-green-03`
                    : `text-red-03`
                }`}
              >
                {report.totalDayNow > report.totalDayOld
                  ? Math.floor(
                      ((report.totalDayNow - report.totalDayOld) /
                        report.totalDayNow) *
                        100
                    )
                  : report.totalDayOld > report.totalDayNow
                  ? Math.floor(
                      ((report.totalDayOld - report.totalDayNow) /
                        report.totalDayOld) *
                        100
                    )
                  : 0}{" "}
                {"%"}
              </p>
            </div>
            <div className="flex items-center w-64  justify-end">
              <div className="w-4 h-4 rounded-full bg-green-03 mr-2"></div>
              <p className="text-1">
                {moment(report.compare).add(-1, "w").format("DD/MM/YYYY")} (
                {formatRupiah(report.totalDayOld)})
              </p>
            </div>
          </div>
        </li>
      </ul>
      <div className="overflow-x-scroll overflow-y-hidden spacing-graph">
        <div className="App relative">
          {report?.graphicDay?.data && (
            <Graphic
              type={report?.graphicDay?.type}
              width={2500}
              height={330}
              data={report?.graphicDay?.data}
              multi={true}
            />
          )}
        </div>
      </div>

      {/* Tren penjualan mingguan */}
      <div className="mt-10 bg-blue-03">
        <h1 className="bold-text-1 text-white p-4">Tren penjualan mingguan</h1>
      </div>
      <div className="flex items-center my-4">
        <h1 className="bold-text-1 flex items">
          {moment(report.compare).format("DD/MM/YYYY")} dibandingkan dengan
        </h1>
        <Dropdown
          variant="textual"
          color="blue"
          className="mr-1"
          content="label only"
          label={filterWeek.label}
        >
          <DropdownList
            onClick={() => {
              setFilterWeek({ label: "Pilih minggu", value: "Pilih minggu" });
              dispatch(setDate(new Date()));
            }}
          >
            Pilih minggu
          </DropdownList>
          <DropdownList
            onClick={() => {
              setFilterWeek({
                label: "Minggu yang sama tahun lalu",
                value: "Minggu yang sama tahun lalu",
              });
              dispatch(setDate(new Date(moment().add(-1, "y").format())));
            }}
          >
            Minggu yang sama tahun lalu
          </DropdownList>
          <DropdownList
            onClick={() => {
              setFilterWeek({ label: "Minggu lalu", value: "Minggu lalu" });
              dispatch(setDate(new Date(moment().add(-1, "w").format())));
            }}
          >
            Minggu lalu
          </DropdownList>
        </Dropdown>
      </div>
      <ul>
        <li>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {/* <IconArrowR className="mr-2" /> */}
              <p
                className={`text-1 ${
                  report.totalWeekNow > report.totalWeekOld
                    ? `text-green-03`
                    : `text-red-03`
                }`}
              >
                {formatRupiah(
                  `${
                    report.totalWeekNow > report.totalWeekOld
                      ? report.totalWeekNow - report.totalWeekOld
                      : report.totalWeekOld - report.totalWeekNow
                  }`
                )}
              </p>
            </div>
            <div className="flex items-center w-64 justify-end">
              <div className="w-4 h-4 rounded-full bg-blue-03 mr-2"></div>
              <p className="text-1">
                {moment(report.compare).format("DD/MM/YYYY")} (
                {formatRupiah(report.totalWeekNow)})
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {/* <IconArrowR className="mr-2" /> */}
              <p
                className={`text-1 ${
                  report.totalWeekNow > report.totalWeekOld
                    ? `text-green-03`
                    : `text-red-03`
                }`}
              >
                {report.totalWeekNow > report.totalWeekOld
                  ? Math.floor(
                      ((report.totalWeekNow - report.totalWeekOld) /
                        report.totalWeekNow) *
                        100
                    )
                  : report.totalWeekOld > report.totalWeekNow
                  ? Math.floor(
                      ((report.totalWeekOld - report.totalWeekNow) /
                        report.totalWeekOld) *
                        100
                    )
                  : 0}{" "}
                {"%"}
              </p>
            </div>
            <div className="flex items-center w-64 justify-end">
              <div className="w-4 h-4 rounded-full bg-green-03 mr-2"></div>
              <p className="text-1">
                {moment(report.compare).add(-1, "w").format("DD/MM/YYYY")} (
                {formatRupiah(report.totalWeekOld)})
              </p>
            </div>
          </div>
        </li>
      </ul>

      <div className="overflow-x-scroll overflow-y-hidden spacing-graph">
        <div className="App relative">
          {report?.graphicWeek?.data && (
            <Graphic
              type={report?.graphicWeek?.type}
              width={2500}
              height={330}
              data={report?.graphicWeek?.data}
              multi={true}
            />
          )}
        </div>
      </div>

      {/* Tren penjualan mingguan */}
      <div className="mt-10 bg-blue-03">
        <h1 className="bold-text-1 text-white p-4">Tren penjualan tahunan</h1>
      </div>
      <div className="flex items-center my-4">
        <h1 className="bold-text-1 flex items">
          Tahun ini dibandingkan dengan tahun lalu (
          {moment(report.compare).format("YYYY")} v{" "}
          {moment(report.compare).add(-1, "years").format("YYYY")})
        </h1>
      </div>
      <ul>
        <li>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {/* <IconArrowR className="mr-2" /> */}
              <p className="text-1 text-green-03">
                {formatRupiah(report.totalYearNow - report.totalYearOld)}
              </p>
            </div>
            <div className="flex items-center w-64 justify-end">
              <div className="w-4 h-4 rounded-full bg-blue-03 mr-2"></div>
              <p className="text-1">
                {moment(report.compare).format("YYYY")} (
                {formatRupiah(report.totalYearNow)})
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {/* <IconArrowR className="mr-2" /> */}
              <p className="text-1 text-green-03">
                {report.totalYearNow > report.totalYearOld
                  ? Math.floor(
                      ((report.totalYearNow - report.totalYearOld) /
                        report.totalYearNow) *
                        100
                    )
                  : report.totalYearOld > report.totalYearNow
                  ? Math.floor(
                      ((report.totalYearOld - report.totalYearNow) /
                        report.totalYearOld) *
                        100
                    )
                  : 0}{" "}
                {"%"}
              </p>
            </div>
            <div className="flex items-center w-64 justify-end">
              <div className="w-4 h-4 rounded-full bg-green-03 mr-2"></div>
              <p className="text-1">
                {moment(report.compare).add(-1, "years").format("YYYY")} (
                {formatRupiah(report.totalYearOld)})
              </p>
            </div>
          </div>
        </li>
      </ul>

      <div className="overflow-x-scroll overflow-y-hidden spacing-graph">
        <div className="App relative">
          {report?.graphicYear?.data && (
            <Graphic
              type={report?.graphicYear?.type}
              width={2500}
              height={330}
              data={report?.graphicYear?.data}
              multi={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}
