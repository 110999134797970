import React from 'react'

export default function Spacing() {
  return (
    <div className="flex flex-wrap items-start">
      <div className="w-1 h-1 rounded-1px bg-blue-05 ml-3 mt-5"></div>
      <div className="w-2 h-2 rounded-2px bg-blue-05 ml-3 mt-5"></div>
      <div className="w-4 h-4 rounded flex items-center justify-center text-xs font-semibold text-blue-03 bg-blue-05 ml-3 mt-5">16</div>
      <div className="w-6 h-6 rounded flex items-center justify-center text-xs font-semibold text-blue-03 bg-blue-05 ml-3 mt-5">24</div>
      <div className="w-8 h-8 rounded flex items-center justify-center text-base font-semibold text-blue-03 bg-blue-05 ml-3 mt-5">32</div>
      <div className="w-12 h-12 rounded-lg flex items-center justify-center text-base font-semibold text-blue-03 bg-blue-05 ml-3 mt-5">48</div>
      <div className="w-16 h-16 rounded-lg flex items-center justify-center text-base font-semibold text-blue-03 bg-blue-05 ml-3 mt-5">64</div>
      <div className="w-20 h-20 rounded-lg flex items-center justify-center text-base font-semibold text-blue-03 bg-blue-05 ml-3 mt-5">80</div>
      <div className="w-24 h-24 rounded-lg flex items-center justify-center text-base font-semibold text-blue-03 bg-blue-05 ml-3 mt-5">96</div>
      <div className="w-32 h-32 rounded-lg flex items-center justify-center text-base font-semibold text-blue-03 bg-blue-05 ml-3 mt-5">128</div>
      <div className="w-176px h-176px rounded-lg flex items-center justify-center text-base font-semibold text-blue-03 bg-blue-05 ml-3 mt-5">176</div>
    </div>
  )
}
