import React from "react";
import SearchBar from "components/atoms/SearchBar";
import Button from "components/atoms/Button";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Table from "./tableCustomer";
import ModalCreate from "./create";
import ModalActivity from "./customerActivity";
import Pagination from "components/atoms/Pagination";
import Banner from "components/atoms/Banner";
import { fetchCustomer, setPage, setLimit, setKeyword, setGroup } from "features/Customer/actions";
import { useSelector, useDispatch } from "react-redux";
import { getData } from "utils/fetchData";

export default function PageCustomer() {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  // console.log("customer master");
  // console.log(customer);
  const [modalCreate, setModalCreate] = React.useState(false);
  const [modalActivasi, setModalActivasi] = React.useState(false);
  const [isShowSearch, setIsShowSearch] = React.useState(false);

  const [filterGroup, setFilterGroup] = React.useState([]);
  const [groupSelected, setGroupSelected] = React.useState([]);
  const [checkAllGroup, setCheckAllGroup] = React.useState({
    label: "Semua kelompok",
    value: "all",
    isChecked: true,
  });

  const onFilterGroup = (data) => {
    let check = [];
    setFilterGroup(
      filterGroup.map((item) => {
        if (item.value === data.value) {
          item.isChecked = !item.isChecked;
        }
        return item;
      })
    );
    filterGroup.forEach((prev) => {
      if (prev.value && prev.isChecked) {
        check.push(prev.value);
      }
    });
    dispatch(setGroup(check.join(",")));
    setGroupSelected(check);

    if (filterGroup.length === check.length) {
      setCheckAllGroup({
        id: "all",
        name: "Semua kelompok",
        isChecked: true,
      });
      dispatch(setGroup(""));
    } else {
      setCheckAllGroup({
        id: "all",
        name: "Semua kelompok",
        isChecked: false,
      });
    }
  };

  const onFilterAllGroup = () => {
    setFilterGroup(
      filterGroup.map((item) => {
        if (checkAllGroup.isChecked) {
          item.isChecked = false;
        } else {
          item.isChecked = true;
        }
        return item;
      })
    );

    setCheckAllGroup({ ...checkAllGroup, isChecked: !checkAllGroup.isChecked });
    dispatch(setGroup(""));
  };

  const fetchGroup = async () => {
    let _temp = [];
    await getData("v2/group").then((res) => {
      res.data.customersGroup.forEach((item) => {
        _temp.push({
          label: item.name,
          value: item.id,
          isChecked: true,
        });
      });
    });
    setFilterGroup(_temp);
  };

  React.useEffect(() => {
    fetchGroup();
  }, []);

  React.useEffect(() => {
    dispatch(fetchCustomer());
  }, [dispatch, customer.limit, customer.page, customer.keyword, customer.group]);

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-6">
      {customer.notif?.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={customer.notif.message}
          status={customer.notif.status}
          type={customer.notif.type}
        />
      )}
      <div className="flex items-center justify-between mt-4">
        {isShowSearch ? (
          <SearchBar
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            onClick={() => setIsShowSearch(!isShowSearch)}
          />
        ) : (
          <div className="flex items-center">
            <Button
              className="mr-2"
              variant="outlined"
              color="gray"
              content="icon infront"
              iconName="UilSearch"
              onClick={() => setIsShowSearch(!isShowSearch)}
            >
              Cari pelanggan
            </Button>
            <Dropdown
              variant="outlined"
              color="gray"
              className="mr-1"
              content="label only"
              label={
                checkAllGroup.isChecked || groupSelected.length === 0
                  ? "Semua kelompok"
                  : `${groupSelected.length} kelompok`
              }
            >
              <DropdownList
                onClick={onFilterAllGroup}
                checked={checkAllGroup.isChecked}
                list="checkbox"
              >
                Semua kelompok
              </DropdownList>
              {filterGroup.map((item, i) => {
                return (
                  <DropdownList
                    color="blue"
                    key={i}
                    onClick={() => onFilterGroup(item)}
                    checked={item.isChecked}
                    list="checkbox"
                  >
                    {item.label}
                  </DropdownList>
                );
              })}
            </Dropdown>
          </div>
        )}
        <div className="flex items-center">
          <Button
            variant="primary"
            color="blue"
            className="ml-2 whitespace-nowrap"
            onClick={() => setModalCreate(true)}
          >
            Tambah pelanggan baru
          </Button>
        </div>
      </div>

      {/* table */}
      <Table data={customer} onClickActivasiCustomer={() => setModalActivasi(true)} />

      {/* Pagination */}
      {customer.data.length > 0 ? (
        <div className="mt-5">
          <Pagination
            items={customer.totalPage}
            currentPage={customer.page}
            limit={customer.limit}
            onPageChange={(page) => dispatch(setPage(page.selected + 1))}
            onChangePerPage={(value) => dispatch(setLimit(value))}
            label="pelanggan per halaman"
          />
        </div>
      ) : (
        ""
      )}

      {/* modal */}
      <ModalCreate show={modalCreate} close={() => setModalCreate(false)} />
      <ModalActivity show={modalActivasi} close={() => setModalActivasi(false)} />
    </div>
  );
}
