import React from "react";
import Button from "components/atoms/Button";
import SearchBar from "components/atoms/SearchBar";
import Pagination from "components/atoms/Pagination";
import Banner from "components/atoms/Banner";
import Table from "./tableMeasure";
import MeasueCreate from "./create";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMeasure,
  setPage,
  setLimit,
  setKeyword,
} from "features/Measure/action";

function Measure() {
  const dispatch = useDispatch();
  const measure = useSelector((state) => state.measure);
  const [isShowSearch, setIsShowSearch] = React.useState(false);
  const [isShowCreate, setIsShowCreate] = React.useState(false)


  React.useEffect(() => {
    dispatch(fetchMeasure());
  }, [dispatch, measure.limit, measure.page, measure.keyword]);

  // ambil data error dan convert ke view
  if (measure.status === "error") return <h1>internal server error</h1>;

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {measure.notif?.toggle && measure.notif.status === "danger" && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          status="danger"
          type="primary"
          message="Data berhasil dihapus"
        />
      )}

      <div className="flex items-center justify-between my-5">
        {isShowSearch ? (
          <SearchBar
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            onClick={() => setIsShowSearch(!isShowSearch)}
          />
        ) : (
          <Button
            variant="outlined"
            color="gray"
            content="icon infront"
            iconName="UilSearch"
            onClick={() => setIsShowSearch(!isShowSearch)}
          >
            Cari satuan ukur
          </Button>
        )}
        <Button
          variant="primary"
          color="blue"
          className="ml-2 whitespace-nowrap"
          onClick={() => setIsShowCreate(true)}
        >
          Tambah satuan ukur baru
        </Button>
      </div>
      <Table data={measure} />
      {measure.data.length && (
        <div className="mt-5">
          <Pagination
            items={measure.totalPage}
            currentPage={measure.page}
            limit={measure.limit}
            onPageChange={(page) => dispatch(setPage(page.selected + 1))}
            onChangePerPage={(value) => dispatch(setLimit(value))}
            label="satuan ukur per halaman"
          />
        </div>
      )}

      {/* START: MODAL CREATE */}
      {isShowCreate && <MeasueCreate
        open={isShowCreate}
        close={() => setIsShowCreate(false)}
      />}
      {/* END: MODAL CREATE */}



    </div>
  );
}

export default Measure;
