import React from "react";
import { InputTextSmall } from "components/atoms/Form";
import TextCaption from "components/atoms/Text/TextCaption";
import InputGroupContentText from "./InputGroupSmallContentText";
import InputGroupContentIcon from "./InputGroupSmallContentIcon";
import InputTextWithLable from "components/atoms/Form/InputTextSmallWithLabel";
import InputTextGroupWithLabel from "components/atoms/Form/InputTextSmallGroupWithLabel";

export default function InputField() {
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="flex w-full items-center">
        {/* START:INPUT FIELD SMALL */}
        <div className="flex flex-col items-center">
          <div className="my-2">
            <InputTextSmall status="default" />
          </div>
          <div className="my-2">
            <InputTextSmall status="disabled" disabled />
          </div>
          <div className="my-2">
            <InputTextSmall status="error" />
            <TextCaption status="error" message="This field is required" />
          </div>
          <div className="my-2">
            <InputTextSmall status="success" />
            <TextCaption
              status="success"
              message="Field validation is successful"
            />
          </div>
          <div className="my-2">
            <InputTextSmall status="warning" />
            <TextCaption status="warning" message="This field is required" />
          </div>
        </div>
        {/* END:INPUT FIELD SMALL */}
        {/* START:INPUT FIELD SMALL group (CATEGORY)*/}
        <div className="ml-3">
          <InputGroupContentText />
        </div>
        {/* END:INPUT FIELD SMALL group (CATEGORY)*/}
        {/* START:INPUT FIELD SMALL group(ICON)*/}
        <div className="ml-3">
          <InputGroupContentIcon />
        </div>
        {/* END:INPUT FIELD SMALL group (ICON)*/}
      </div>

      <div className="flex w-full items-center mt-4">
        {/* START: INPUT FIELD SMALL WITH LABEL */}
        <div className="flex flex-col items-center">
          <div className="my-2">
            <InputTextWithLable status="default" label="Text input field" />
          </div>
          <div className="my-2">
            <InputTextWithLable
              status="disabled"
              disabled
              label="Text input field"
            />
          </div>
          <div className="my-2">
            <InputTextWithLable status="error" label="Text input field" />
            <TextCaption status="error" message="This field is required" />
          </div>
          <div className="my-2">
            <InputTextWithLable status="success" label="Text input field" />
            <TextCaption
              status="success"
              message="Field validation is successful"
            />
          </div>
          <div className="my-2">
            <InputTextWithLable status="warning" label="Text input field" />
            <TextCaption status="warning" message="This field is required" />
          </div>
        </div>
        {/* END: INPUT FIELD SMALL WITH LABEL */}

        {/* START: INPUT FIELD GROUP SMALL WITH LABEL  */}
        <div className="flex flex-col items-center ml-3">
          <div className="my-2">
            <InputTextGroupWithLabel
              status="default"
              content="text"
              iconName="unit"
              label="Text input field"
            />
          </div>
          <div className="my-2">
            <InputTextGroupWithLabel
              status="disabled"
              disabled
              content="text"
              category="gr"
              label="Text input field"
            />
          </div>
          <div className="my-2">
            <InputTextGroupWithLabel
              status="error"
              content="text"
              category="kg"
              label="Text input field"
            />
            <TextCaption status="error" message="This field is required" />
          </div>
          <div className="my-2">
            <InputTextGroupWithLabel
              status="success"
              content="text"
              category="ml"
              label="Text input field"
            />
            <TextCaption
              status="success"
              message="Field validation is successful"
            />
          </div>
          <div className="my-2">
            <InputTextGroupWithLabel
              status="warning"
              content="text"
              category="unit"
              label="Text input field"
            />
            <TextCaption status="warning" message="This field is required" />
          </div>
        </div>
        {/* END: INPUT FIELD GROUP SMALL WITH LABEL  */}

        <div className="flex flex-col items-center ml-3">
          <div className="my-2">
            <InputTextGroupWithLabel
              status="default"
              content="icon"
              iconName="UilSearch"
              label="Text input field"
            />
          </div>
          <div className="my-2">
            <InputTextGroupWithLabel
              status="disabled"
              disabled
              content="icon"
              iconName="UilSearch"
              label="Text input field"
            />
          </div>
          <div className="my-2">
            <InputTextGroupWithLabel
              status="error"
              content="icon"
              iconName="UilSearch"
              label="Text input field"
            />
            <TextCaption status="error" message="This field is required" />
          </div>
          <div className="my-2">
            <InputTextGroupWithLabel
              status="success"
              content="icon"
              iconName="UilSearch"
              label="Text input field"
            />
            <TextCaption
              status="success"
              message="Field validation is successful"
            />
          </div>
          <div className="my-2">
            <InputTextGroupWithLabel
              status="warning"
              content="icon"
              iconName="UilSearch"
              label="Text input field"
            />
            <TextCaption status="warning" message="This field is required" />
          </div>
        </div>
      </div>
    </div>
  );
}
