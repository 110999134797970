import * as React from "react";
import Button from "components/atoms/Button";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setNotif, fetchDevice, setPage, setPerPage } from "features/Device/actions";
import Spinner from "components/atoms/Spinner";
import Banner from "components/atoms/Banner";
import ModalPin from "./showPin";
import ModalDialog from "components/molecules/Modal";
import { deleteData } from "utils/fetchData";
import DeviceEdit from './edit'
import Pagination from "components/atoms/Pagination";

export default function TableDevice({ data, status }) {
  const dispatch = useDispatch();
  const [isShowEdit, setIsShowEdit] = React.useState(false);
  const device = useSelector((state) => state.device);
  const [deviceId, setDeviceId] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [showPin, setShowPin] = React.useState(false);
  const [fieldPin, setFieldPin] = React.useState({
    name: '',
    pin: ''
  })
  const [check, setCheck] = React.useState({
    id: "",
    name: "",
  });
  const [isSweetAlert, setIsSweetAlert] = React.useState(false);

  const handleShowSweetAlert = (payload) => {

    setCheck({
      ...check,
      id: payload.deviceId,
      name: payload.deviceName,
    });
    setIsSweetAlert(true);
  };

  const handleShowEdit = (payload) => {
    setDeviceId(payload.id);
    setIsShowEdit(true);
  };


  const handleDeleteDevice = async (id) => {

    try {
      setLoading(true);
      const res = await deleteData(`v2/device/${id}`);

      if (res.data?.code === 200) {
        setLoading(false);
        const notif = {
          message: `Perangkat "${check.name}" berhasil di hapus`,
          type: "primary",
          status: "danger",
          toggle: true,
        };
        dispatch(setNotif(notif));
        dispatch(fetchDevice());
        setIsSweetAlert(false);
        setCheck({ id: "", name: "" });
      }
    } catch (err) {
      let error = []
      if (err.response.status === 422) {
        err.response.data.forEach(err => {
          error.push(err.id)
        });
        setLoading(false);
        setIsSweetAlert(false);
        const notif = {
          message: `${error.join(", ")}`,
          type: "primary",
          status: "danger",
          toggle: true,
        };
        dispatch(setNotif(notif));
        dispatch(fetchDevice());
      }
    }
  };

  return (
    <table className="w-full mt-4">
      <thead>
        <tr>
          <th>Lokasi</th>
          <th>Nama perangkat</th>
          <th>Imei</th>
          <th>Tipe</th>
          <th colSpan={2}>Status </th>
        </tr>
      </thead>
      <tbody>
        {status === "process" ? (
          <tr key="status">
            <td colSpan={6} style={{ textAlign: "center" }}>
              <div className="flex items-center justify-center">
                <Spinner size={64} color="#859DFF" />
              </div>
            </td>
          </tr>
        ) : data.length ? (
          data.map((data, index) => {
            return (
              <tr key={index}>
                <td className="border-l-2 text-blue-03">{data.outlet?.outletName}</td>
                <td>{data.deviceName}</td>
                <td>{data.deviceImei}</td>
                <td>{data.type}</td>
                <td className={`${data.deviceCondition === 1 ? 'text-green-02' : 'text-red-02'}`}>{data.deviceCondition === 1 ? 'Aktif' : 'Tidak aktif'}</td>
                {/* <td className={`${data.devices[0].status === 0 ?
                  ' text-red-02' : data.devices[0].status === 1 ? ' text-green-02' : 'text-orange-02'}`}>{data.devices[0].status === 0 ?
                    'Tidak aktif' : data.devices[0].status === 1 ? 'Aktif' : 'Belum terhubung'}
                </td> */}
                <td className="flex justify-end">
                  <Dropdown variant="subtle" color="blue" content="icon only">

                    <DropdownList
                      type="button"
                      onClick={() => handleShowSweetAlert(data)}
                      color="red"
                    >
                      Hapus perangkat
                    </DropdownList>
                  </Dropdown>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              Tidak Ditemukan Data
            </td>
          </tr>
        )}
        {/* {status === "process" ? (
          <tr key="status">
            <td colSpan={4} style={{ textAlign: "center" }}>
              <div className="flex items-center justify-center">
                <Spinner size={64} color="#859DFF" />
              </div>
            </td>
          </tr>
        ) : data.length ? (
          data.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <div className="flex items-center justify-between">
                      <p>{data.name}</p>
                      <Button
                        variant="primary"
                        color="green"
                        onClick={() => {
                          setShowPin(true);
                          setFieldPin({ ...fieldPin, name: data.name, pin: data.pin })

                        }}
                      >
                        Tampilkan PIN
                        
                      </Button>
                    </div>
                  </td>
                  <td className="border-l-2 text-blue-03">{data.devices[0].name}</td>
                  <td className={`${data.devices[0].status === 0 ?
                    ' text-red-02' : data.devices[0].status === 1 ? ' text-green-02' : 'text-orange-02'}`}>{data.devices[0].status === 0 ?
                      'Tidak aktif' : data.devices[0].status === 1 ? 'Aktif' : 'Belum terhubung'}
                  </td>
                  <td className="flex justify-end">
                    <Dropdown variant="subtle" color="blue" content="icon only">
                      <DropdownList type="button" color="blue" onClick={() => handleShowEdit(data.devices[0])}>
                        Edit perangkat
                      </DropdownList>
                      <DropdownList
                        type="button"
                        onClick={() => handleShowSweetAlert(data.devices[0])}
                        color="red"
                      >
                        Hapus perangkat
                      </DropdownList>
                    </Dropdown>
                  </td>
                </tr>
                {notif.toggle && notif.id === data.devices[0].id && (
                    <tr>
                      <td colSpan={4} className="p-0">
                        <Banner
                          status={notif.status}
                          type={notif.type}
                          message={notif.message}
                        />
                      </td>
                    </tr>
                  )}
                {
                  data.devices.map((device, i) => {
                    return i !== 0 && (<React.Fragment><tr style={{ boxShadow: "none" }} key={device.id}>
                      <td style={{ boxShadow: "none" }}></td>
                      <td className="border-l-2 text-blue-03">{device.name}</td>
                      <td className={`${device.status === 0 ?
                        ' text-red-02' : device.status === 1 ? ' text-green-02' : 'text-orange-02'}`}>
                        {device.status === 0 ?
                          'Tidak aktif' : device.status === 1 ? 'Aktif' : 'Belum terhubung'}
                      </td>
                      <td className="flex justify-end">
                        {" "}
                        <Dropdown variant="subtle" color="blue" content="icon only">
                          <DropdownList type="button" color="blue" onClick={() => handleShowEdit(device)}>
                            Edit perangkat
                          </DropdownList>
                          <DropdownList 
                          type="button"
                          onClick={() => handleShowSweetAlert(device)}
                          color="red">
                            Hapus perangkat
                          </DropdownList>
                        </Dropdown>
                      </td>
                    </tr>
                    {notif.toggle && notif.id === device.id && (
                    <tr>
                      <td colSpan={4} className="p-0">
                        <Banner
                          status={notif.status}
                          type={notif.type}
                          message={notif.message}
                        />
                      </td>
                    </tr>
                  )}
                    </React.Fragment>)
                  })
                }
                

              </React.Fragment>
            );
          })
        ) : (
          <tr>
            <td colSpan={4} style={{ textAlign: "center" }}>
              Tidak Ditemukan Data
              </td>
          </tr>
        )} */}


      </tbody>

      {/* START: PAGINATION */}
      <Pagination
        items={device.pages}
        currentPage={device.page}
        limit={device.limit}
        onPageChange={(page) => dispatch(setPage(page.selected + 1))}
        onChangePerPage={(value) => dispatch(setPerPage(value))}
        label={`perangkat per halaman`}
      />
      {/* END: PAGINATION */}
      {/* modal tampilkan PIN */}
      <ModalPin
        show={showPin}
        fieldPin={fieldPin}
        close={() => setShowPin(false)}
      />
      {/* modal delete */}
      {/* START: ALERT DELETE */}
      <ModalDialog
        show={isSweetAlert}
        onClose={() => setIsSweetAlert(false)}
        size={"alert"}
        title={<h1 className="text-dark heading-3">Hapus perangkat</h1>}
      >
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p className="text-2">
            Apakah anda yakin ingin menghapus perangkat &nbsp;
            <span className="text-blue-03 bold-text-2">“{check.name} ”</span>?
            Aksi ini tidak bisa dibatalkan lagi jika perangkat telah berhasil
            dihapus.
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
            onClick={() => setIsSweetAlert(false)}
          >
            Batal hapus perangkat
          </Button>
          <Button
            loading={loading ? true : false}
            disabled={loading ? true : false}
            onClick={!loading ? () => handleDeleteDevice(check.id) : null}
            variant="primary" color="red" className="my-5" type="button">
            Hapus perangkat
          </Button>
        </div>
      </ModalDialog>
      {/* END: ALERT DELETE */}


      {/* START: EDIT CATEGORY */}
      {isShowEdit && (
        <DeviceEdit
          title="Ubah nama perangkat"
          device={deviceId}
          show={isShowEdit}
          close={() => setIsShowEdit(false)}
        />
      )}
      {/* END: EDIT CATEGORY */}

    </table >
  );
}

TableDevice.propTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
};
