import React from "react";
import IconUser from "components/atoms/Icons/Custom/icon new/User";
import IconAV from "components/atoms/Icons/Custom/icon new/AV";

export default function Avatar() {
  return (
    <div className="pt-36 flex items-start">
      <div className="flex flex-col items-center justify-center">
        <IconUser width="128" height="128" />
        <IconUser className="mt-10" width="32" height="32" />
      </div>
      <div className="flex flex-col items-center justify-center ml-14">
        <IconAV width="128" height="128" />
        <IconAV className="mt-10" width="32" height="32" />
      </div>
    </div>
  );
}
