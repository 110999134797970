import React, { useEffect } from "react";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import { InputDateRange } from "components/atoms/Form/InputDate/index";
import Button from "components/atoms/Button";
import { useDispatch, useSelector } from 'react-redux'
import { fetchReportRefund, setLocation, setDate } from 'features/ReportRefund/actions'
import { formatRupiah } from 'utils/formatRupiah'
import { Line } from 'react-chartjs-2';
import Spinner from "components/atoms/Spinner";

export default function PageReportRefuncd() {
  const dispatch = useDispatch()
  const report = useSelector(state => state.reportRefund)

  const onChangeDateCostum = (start, end) => {
    const ranges = {
      startDate: start,
      endDate: end,
      key: 'selection',
    }
    dispatch(setDate(ranges))
  }

  React.useEffect(() => {
    dispatch(fetchReportRefund())

  }, [dispatch, report.location, report.date]);

  if (report.status === "process") {
    return (
      <div className="flex items-center justify-center mt-56">
        <Spinner size={64} color="#859DFF" />
      </div>
    );
  }

  const options = {
    layout: {
      padding: {
        top: 50,
      },
    },
    tooltips: {
      enabled: true,
      custom: function (tooltip) {
        if (!tooltip) return;
        // disable displaying the color box;
        tooltip.displayColors = false;
      },
      yAlign: "bottom",
      callbacks: {
        label: function (tooltipItem, data) {
          var indice = tooltipItem.index;
          let value = formatRupiah(data.datasets[0].data[indice]);
          return [value];
        },
      },
    },
    legend: {
      display: false,
    },
    bezierCurve: false,
    responsive: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return formatRupiah(value);
            },
          },
        },
      ],
    },
  };

  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: report?.chart?.label,
    datasets: [
      {
        data: report?.chart?.data,

        borderColor: "rgb(31, 75, 255)",
        backgroundColor: ["rgba(31, 75, 255, 0.3)"],
        lineTension: 0,
      },
    ],
  };



  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <InputDateRange
            onChangeDateCostum={(start, end) => onChangeDateCostum(start, end)}
            variant="range"
            position="items-start"
            type="custom right"
            onChange={(ranges) => dispatch(setDate(ranges))}
            name="date"
            value={report.date}
          />

          <div className="ml-4">
            <Dropdown
              variant="outlined"
              color="gray"
              className="ml-2"
              content="label only"
              label={report.location?.label}
            >
              {report?.outlets.map((outlet) => {
                return (
                  <React.Fragment key={outlet.value}>
                    <DropdownList color="blue" type="button" onClick={() => dispatch(setLocation(outlet))}>{outlet.label}</DropdownList>
                  </React.Fragment>
                )
              })}
            </Dropdown>

          </div>
        </div>
        {/* <Button variant="outlined" color="blue">
          Export laporan
        </Button> */}
      </div>



      <div className="flex items-center flex-wrap">
        {report.table.map((v, i) => (
          <div className="flex items-center min-w-min mr-4">
            <div
              className={`w-4 h-4 rounded-full ${i === 0 ? 'bg-blue-03 mr-2' : i === 1 ? 'bg-green-03' : i === 2 ? 'bg-red-03' : i === 3 ? 'bg-orange-03' : 'bg-purple-03'}`}
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            ></div>
            <p className="whitespace-nowrap text-2 text-gray-03">
              Jumlah refund: {formatRupiah(v.value)}
            </p>
          </div>
        ))}


      </div>
      <div className="w-full chartjs-style">
        <Line height={300} width={1250} data={data} options={options} />
      </div>
      <div className="mt-6 mb-6">
        <table className="w-full mt-4">
          <thead>
            <tr>
              <th>Kode Refund</th>
              <th>Tanggal refund</th>
              <th>Alasan refund</th>
              <th>Jumlah refund</th>
            </tr>
          </thead>

          <tbody>
            {report.table.map((v, i) => (
              <tr key={i}>
                <td>REF-{v.number}</td>
                <td>{v.createdAt}</td>
                <td>{v.message}</td>
                <td>{formatRupiah(v.value)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr style={{ backgroundColor: '#0066CC', color: 'white' }}>
              <td>Total</td>
              <td></td>
              <td></td>
              <td>{`${formatRupiah(report.table.reduce((a, b) => a + b.value, 0))}`}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
