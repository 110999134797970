import {
  START_FETCHING_CATEGORY,
  SUCCESS_FETCHING_CATEGORY,
  ERROR_FETCHING_CATEGORY,
  SUCCESS_FETCHING_CHECKBOX_CATEGORY,
  SET_KEYWORD,
  SET_NOTIF
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  checkboxs: [],
  status: statuslist.idle,
  keyword: '',
  notif: {
    id: '',
    message: '',
    type: '',
    status: '',
    toggle: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_CATEGORY:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_CATEGORY:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_CATEGORY:
      return { ...state, status: statuslist.success, data: action.category };

    case SET_KEYWORD:
      return { ...state, keyword: action.keyword }

    case SET_NOTIF:
      return { ...state, notif: action.notif }

    case SUCCESS_FETCHING_CHECKBOX_CATEGORY:
      return { ...state, checkboxs: action.category }

    default:
      return state;
  }
}
