import * as React from "react";
import Topbar from "components/organisms/Topbar";
import { useLocation } from "react-router-dom";
import Tab from "components/organisms/Tab";
import menus from "./menus";
import { getData } from "utils/fetchData";

function App({ children }) {
  let location = useLocation();
  location = location.pathname.split("/");
  location = location[1].charAt(0).toUpperCase() + location[1].substr(1).toLowerCase();
  let [tab, setTab] = React.useState([]);

  const [header, setHeader] = React.useState("");

  React.useEffect(() => {
    const checkMenu = async () => {
      const res = await getData(`v2/subscription/status?module=INVENTORY`);

      const menu = [];
      menus.forEach((data) => {
        if (res.data?.subscription?.status) {
          if (data.prefix === location && data.status) {
            menu.push(data);
          }
        }

        if (data.prefix === location && !data.status) {
          menu.push(data);
        }
      });

      if (location === "Product") setHeader("Produk");
      if (location === "Dashboard") setHeader("Beranda");
      if (location === "Device") setHeader("Perangkat");
      if (location === "Customer") setHeader("Pelanggan");
      if (location === "Report") setHeader("Laporan");
      if (location === "Balance") setHeader("Saldo");
      if (location === "Setting") setHeader("Pengaturan");
      if (location === "Transaction") setHeader("Transaksi");
      if (location === "More") setHeader("Fitur lainya");

      setTab(menu);
    };
    checkMenu();
  }, []);

  return (
    <div className="relative w-full h-screen">
      <div className="absolute w-full z-30">
        <Topbar location={header} />
      </div>
      <div className="flex h-screen overflow-hidden">
        <div
          className={`${
            location === "Transaction" || location === "Dashboard" || location === "More"
              ? ""
              : "w-1/5 h-screen overflow-scroll scroll-hidden pl-4"
          }`}
        >
          <Tab children={tab} />
        </div>
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
}

export default App;
