import * as React from 'react'
import IconInvoice from "components/atoms/Icons/Custom/icon new/Invoice";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Pagination from "components/atoms/Pagination";
import { setPerPage, setPage } from "features/Transaction/actions";
import { useSelector, useDispatch } from "react-redux";
import { formatRupiah } from 'utils/formatRupiah'
import ModalFakturRefund from './modal/fakturRefund'
import ModalFaktur from './modal/faktur'
import ModalSendFaktur from './modal/kirimFaktur'
import ModalRefund from './modal/refund'
import ModalCustomer from './modal/lihatPelanggan'
import ModalEdit from './modal/editTransaction'
import Button from "components/atoms/Button";
import ModalDialog from "components/molecules/Modal";
import { deleteData } from 'utils/fetchData'
import { fetchTransaction } from "features/Transaction/actions";
import Spinner from "components/atoms/Spinner";

export default function TableTransaction({ data, status }) {
  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.transaction);
  const [isShowFakturRefund, setIsShowFakturRefund] = React.useState(false);
  const [isShowFaktur, setIsShowFaktur] = React.useState(false);
  const [isShowSendFaktur, setisShowSendFaktur] = React.useState(false);
  const [isShowRefund, setIsShowRefund] = React.useState(false);
  const [isShowCustomer, setIsShowCustomer] = React.useState(false);
  const [isShowEdit, setIsShowEdit] = React.useState(false);
  const [isCheckbox, setIsCheckbox] = React.useState(true);
  const [transactionId, setTransactionId] = React.useState()
  const [isSweetAlert, setIsSweetAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [check, setCheck] = React.useState({
    id: "",
    name: ""
  });

  const handleShowRefund = (id) => {
    setTransactionId(id)
    setIsShowRefund(true)

  }

  const handleShowFaktur = (id, status) => {
    setTransactionId(id)

    return status === 'REFUND'
      ? setIsShowFakturRefund(true)
      : setIsShowFaktur(true)
  }

  const handleShowSendFaktur = (id) => {
    setTransactionId(id)
    setisShowSendFaktur(true)
  }

  const handleShowCustomer = (id) => {
    setTransactionId(id)
    setIsShowCustomer(true)
  }

  const handleShowEdit = (id) => {
    setTransactionId(id)
    setIsShowEdit(true)
  }

  const handleShowSweetAlert = (payload) => {
    setCheck({
      ...check,
      id: payload.id,
      name: payload.externalId
    });
    setIsSweetAlert(true);
  };

  const handleDeleteTransaksi = async (id) => {
    setLoading(true);
    const res = await deleteData(`v2/sales/${id}`)
    if (res.data?.code === 200) {
      setLoading(false);
      // const notif = {
      //   message: `Transaksi "${check.name}" berhasil di hapus`,
      //   type: "primary",
      //   status: "danger",
      //   toggle: true,
      // };
      // dispatch(setNotif(notif));
      dispatch(fetchTransaction());
      setIsSweetAlert(false);
      setCheck({ id: "", name: "", });

    }
  }

  return (
    <div>
      <ul>
        {status === "process" ? (
          <div className="flex items-center justify-center">
            <Spinner size={64} color="#859DFF" />
          </div>

        ) : Object.keys(data).length ? Object.keys(data).map((key, i) => {
          return (
            <li key={i}>
              <div className="grid grid-cols-6 items-start mt-10">
                <div className="col-span-1 bg-gray-05">
                  <div className="pl-4 py-4">
                    <p>{key}</p>
                  </div>
                </div>


                <div className="col-span-5">
                  {data[key].map((item, index) => {
                    return (
                      <div className="grid grid-cols-5 border" key={index}>
                        <div className="col-span-4 flex items-center p-4">
                          <div className="flex items-center">
                            {/* text icon set width */}
                            <div className="flex items-center w-32">
                              <IconInvoice className="mr-2" />
                              <p className={`${item.status === 'COMPLETED' ? 'text-blue-03' : 'text-red-03'}`}> {item.status === 'COMPLETED'
                                ? `INV-${item.number}`
                                : `${item.number}`}
                              </p>
                            </div>
                            {/* penjualan set width */}
                            <p className="w-24  ml-4 font-medium">{item.status === 'COMPLETED'
                              ? `Penjualan`
                              : `Refund`}
                            </p>
                            {/* text length much set width over width */}
                            <div style={{ width: "555px" }}>
                              <p
                                className="w-full font-medium"
                                style={{ overflowWrap: "break-word" }}
                              >
                                {item.details && item.details.map((detail, index2) => {
                                  return (
                                    <span key={index2}>{detail.name}{", "}</span>
                                  )
                                })}
                              </p>

                            </div>
                          </div>
                        </div>
                        <div className="col-span-1 flex items-center w-full">
                          <div className="flex items-center justify-between w-full pr-4">
                            {/* text with icon more option / dropdown */}
                            <p>  {item.status === 'COMPLETED' ? `+` : `-`}{' '}
                              {formatRupiah(item.value)}
                            </p>
                            <Dropdown variant="subtle" color="blue" content="icon only">
                              <DropdownList
                                type="button"
                                color="blue"
                                onClick={() => handleShowFaktur(item.id, item.status)}>
                                {item.status === 'COMPLETED'
                                  ? 'Faktur penjualan'
                                  : 'Faktur refund'}
                              </DropdownList>
                              <DropdownList
                                type="button" color="blue"
                                onClick={() => handleShowSendFaktur(item.id)}
                              >
                                Kirim faktur
                              </DropdownList>
                              {item.status === 'COMPLETED' && <DropdownList onClick={() => handleShowRefund(item.id)} type="button" color="blue">
                                Refund
                              </DropdownList>}
                              <DropdownList type="button" color="blue" onClick={() => handleShowCustomer(item.id)}>
                                Lihat pelanggan

                              </DropdownList>
                              {item.paymentMethod.origin === "other" && <DropdownList onClick={() => handleShowEdit(item.id)} type="button" color="blue">
                                Edit transaksi

                              </DropdownList>}
                              {item.paymentMethod.origin === "other" && <DropdownList onClick={() => handleShowSweetAlert(item)} type="button" color="blue">

                                Hapus transaksi

                              </DropdownList>}
                            </Dropdown>
                          </div>
                        </div>
                      </div>

                    )
                  })}
                </div>
              </div>
            </li>
          )
        }) : <div className="flex items-center justify-center -mb-964 mt-6">
          <h1>data transaksi tidak ada.</h1>
        </div>}
      </ul>
      {/* START: PAGINATION */}
      {
        Object.keys(data).length && <Pagination
          items={transaction.pages}
          currentPage={transaction.page}
          limit={transaction.limit}
          onPageChange={(page) => dispatch(setPage(page.selected + 1))}
          onChangePerPage={(value) => dispatch(setPerPage(value))}
          label="transaksi per halaman"
        />
      }


      <ModalFakturRefund
        show={isShowFakturRefund}
        close={() => setIsShowFakturRefund(false)}
        transaction={transactionId}
      />
      <ModalFaktur
        show={isShowFaktur}
        close={() => setIsShowFaktur(false)}
        transaction={transactionId}
      />

      <ModalSendFaktur
        show={isShowSendFaktur}
        close={() => setisShowSendFaktur(false)}
        transaction={transactionId}
      />

      <ModalRefund
        show={isShowRefund}
        close={() => setIsShowRefund(false)}
        transaction={transactionId}
      />
      <ModalCustomer
        show={isShowCustomer}
        close={() => setIsShowCustomer(false)}
        transaction={transactionId}
      />

      <ModalEdit
        show={isShowEdit}
        close={() => setIsShowEdit(false)}
        transaction={transactionId}
      />

      {/* START: ALERT DELETE */}
      <ModalDialog
        size={"alert"}
        show={isSweetAlert}
        onClose={() => setIsSweetAlert(false)}
        title={<p className="heading-3 text-dark">Hapus transaksi</p>}
      >
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p className="text-2">
            Apakah anda yakin ingin menghapus transaksi{" "}
            <span className="text-blue-03 bold-text-2">"{check.name}"</span> ?{" "}
            <span className="text-blue-03 bold-text-2">

            </span>{" "}
            dalam transaksi ini akan menjadi tidak ada transaksi ketika transaksi
            berhasil dihapus.{" "}
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
            onClick={() => setIsSweetAlert(false)}
          >
            Batal hapus transksi
          </Button>
          <Button
            variant="primary"
            color="red"
            className="my-5"
            type="button"
            loading={loading ? true : false}
            disabled={loading ? true : false}
            onClick={!loading ? () => handleDeleteTransaksi(check.id) : null}
          >
            Hapus transksi
          </Button>
        </div>
      </ModalDialog>
      {/* END: ALERT DELETE */}
    </div >

  )
}
