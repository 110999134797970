import {
  START_FETCHING_MATERIAL,
  SUCCESS_FETCHING_MATERIAL,
  ERROR_FETCHING_MATERIAL,
  SET_KEYWORD,
  SET_LOCATION,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_STOCKS,
  SET_OUTLET,
  SET_OUTLET_SELECTED,
  SET_ALL_SELECTED,
  SET_REASON,
  SET_ID,
  SET_NAME,
  SET_MEASURE_ID,
  SET_MEASURE_NAME,
  SET_MEASURE_UNIT,
  SET_BANNER,
  SET_MODAL,
  SET_MODAL_SECONDARY,
  SET_MESSAGE,
  SET_MATERIAL_USED,
  SET_MATERIAL_CHECKBOX,
} from "./constant";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  id: "",
  name: "",
  measure_name: "Pilih satuan ukur",
  measure_unit: "",
  unitOfMeasureId: 0,
  materials: [],
  materials_checkbox: [],
  stocks: [],
  outlet: [],
  selectedOutlet: [],
  reason: [],
  material_used: [],
  allSelected: true,
  keyword: "",
  location: "",
  page: 1,
  limit: 10,
  totalPage: 1,
  status: statuslist.idle,
  banner: "idle",
  modal: "hide",
  modal_secondary: "hide",
  message: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_MATERIAL:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_MATERIAL:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_MATERIAL:
      return {
        ...state,
        status: statuslist.success,
        materials: action.materials,
      };

    case SET_KEYWORD:
      return {
        ...state,
        keyword: action.keyword,
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.location,
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: action.limit,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case SET_TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.totalPage,
      };
    case SET_STOCKS:
      return {
        ...state,
        stocks: action.stocks,
      };
    case SET_OUTLET_SELECTED:
      return {
        ...state,
        selectedOutlet: action.selectedOutlet,
      };
    case SET_OUTLET:
      return {
        ...state,
        outlet: action.outlet,
      };
    case SET_ALL_SELECTED:
      return {
        ...state,
        allSelected: action.allSelected,
      };
    case SET_REASON:
      return {
        ...state,
        reason: action.reason,
      };
    case SET_ID:
      return {
        ...state,
        id: action.id,
      };
    case SET_NAME:
      return {
        ...state,
        name: action.name,
      };
    case SET_MEASURE_ID:
      return {
        ...state,
        unitOfMeasureId: action.unitOfMeasureId,
      };
    case SET_MEASURE_NAME:
      return {
        ...state,
        measure_name: action.measure_name,
      };
    case SET_MEASURE_UNIT:
      return {
        ...state,
        measure_unit: action.measure_unit,
      };
    case SET_BANNER:
      return {
        ...state,
        banner: action.banner,
      };
    case SET_MODAL:
      return {
        ...state,
        modal: action.modal,
      };
    case SET_MODAL_SECONDARY:
      return {
        ...state,
        modal_secondary: action.modal_secondary,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case SET_MATERIAL_USED:
      return {
        ...state,
        material_used: action.material_used,
      };
    case SET_MATERIAL_CHECKBOX:
      return {
        ...state,
        materials_checkbox: action.materials_checkbox,
      };

    default:
      return state;
  }
}
