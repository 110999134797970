import {
  START_FETCHING_STOCK,
  SUCCESS_FETCHING_STOCK,
  ERROR_FETCHING_STOCK,
  SET_KEYWORD,
  SET_PAGE,
  SET_LIMIT,
  SET_TOTAL_PAGE,
  SET_EXPAND,
  SET_MODAL_PRIMARY,
  SET_WITH_MATERIAL_VARIANT,
  SET_WITH_MATERIAL_VARIANT_SINGLE,
  SET_MODAL_SECONDARY,
  SET_WITHOUT_MATERIAL_VARIANT,
  SET_WITH_MATERIAL_ONLY,
  SET_WITH_VARIANT_ONLY,
  SET_TOGGLE_MATERIAL,
  SET_TOGGLE_VARIANT,
  SET_PRODUCT_NAME,
  SET_OUTLETS,
  SET_VARIANT_WITH_OUTLETS,
  SET_WITH_VARIANT_ONLY_SINGLE,
  SET_ID_STOCK,
  SET_DISABLED_VARIANT,
  SET_NOTIF,
  SET_DATA_TEMPORARY,
  SET_ID_VARIANT,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  idStock: 0,
  idVariant: 0,
  productName: "",
  withVariant: false,
  withMaterial: false,
  data: [],
  temporary: [],
  withMaterialVariant: [],
  withMaterialVariantSingle: {},
  withoutMaterialVariant: [],
  withMaterialOnly: [],
  withMaterialOnlySingle: {},
  withVariantOnly: [],
  variantWithlocation: [],
  outlets: [],
  page: 1,
  limit: 10,
  totalPage: 1,
  keyword: "",
  modal_primary: "hide-create-stock",
  modal_secondary: "hide-material-manage",
  disabledVariant: false,
  status: statuslist.idle,
  notif: {
    id: 0,
    message: "",
    type: "",
    status: "",
    toggle: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_STOCK:
      return { ...state, status: statuslist.process };

    case SET_PRODUCT_NAME:
      return { ...state, productName: action.productName };

    case ERROR_FETCHING_STOCK:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_STOCK:
      return {
        ...state,
        status: statuslist.success,
        data: action.stocks,
      };
    case SET_TOGGLE_MATERIAL:
      return {
        ...state,
        withMaterial: action.withMaterial,
      };
    case SET_DISABLED_VARIANT:
      return {
        ...state,
        disabledVariant: action.disabledVariant,
      };
    case SET_TOGGLE_VARIANT:
      return {
        ...state,
        withVariant: action.withVariant,
      };
    case SET_EXPAND:
      return {
        ...state,
        data: action.stocks,
      };
    case SET_KEYWORD:
      return {
        ...state,
        keyword: action.keyword,
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: action.limit,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case SET_TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.totalPage,
      };
    case SET_MODAL_PRIMARY:
      return {
        ...state,
        modal_primary: action.modal_primary,
      };
    case SET_MODAL_SECONDARY:
      return {
        ...state,
        modal_secondary: action.modal_secondary,
      };
    case SET_WITH_MATERIAL_VARIANT:
      return {
        ...state,
        withMaterialVariant: action.withMaterialVariant,
      };
    case SET_WITH_MATERIAL_VARIANT_SINGLE:
      return {
        ...state,
        withMaterialVariantSingle: action.withMaterialVariantSingle,
      };
    case SET_WITHOUT_MATERIAL_VARIANT:
      return {
        ...state,
        withoutMaterialVariant: action.withoutMaterialVariant,
      };
    case SET_WITH_MATERIAL_ONLY:
      return {
        ...state,
        withMaterialOnly: action.withMaterialOnly,
      };
    case SET_WITH_VARIANT_ONLY:
      return {
        ...state,
        withVariantOnly: action.withVariantOnly,
      };
    case SET_OUTLETS:
      return {
        ...state,
        outlets: action.outlets,
      };
    case SET_VARIANT_WITH_OUTLETS:
      return {
        ...state,
        variantWithlocation: action.variantWithlocation,
      };
    case SET_WITH_VARIANT_ONLY_SINGLE:
      return {
        ...state,
        withMaterialOnlySingle: action.withMaterialOnlySingle,
      };
    case SET_ID_STOCK:
      return {
        ...state,
        idStock: action.idStock,
      };
    case SET_ID_VARIANT:
      return {
        ...state,
        idVariant: action.idVariant,
      };
    case SET_DATA_TEMPORARY:
      return {
        ...state,
        temporary: action.temporary,
      };

    case SET_NOTIF:
      return { ...state, notif: action.notif };

    default:
      return state;
  }
}
