import React, { useEffect } from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import Toggle from "components/atoms/Toggle";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import TextCaption from "components/atoms/Text/TextCaption";
import IconProduct from "components/atoms/Icons/Custom/icon new/Product";
import IconDevices from "components/atoms/Icons/Custom/icon new/Devices";
import IconCustomerHappy from "components/atoms/Icons/Custom/icon new/CustomerHappy";
import IconTransactions from "components/atoms/Icons/Custom/icon new/Transactions";
import IconReports from "components/atoms/Icons/Custom/icon new/Reports";
import IconSettings from "components/atoms/Icons/Custom/icon new/Settings";
import IconHome from "components/atoms/Icons/Custom/icon new/Home";
import IconWallet from "components/atoms/Icons/Custom/icon new/Wallet";
import { putData, getData } from "utils/fetchData";
import { fetchAccounts, setNotif } from "features/Accounts/actions";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const AccessSchema = yup.object().shape({
  username: yup.string().required("email tidak boleh kosong."),
  name: yup.string().required("nama tidak boleh kosong."),
});

export default function UpdateAccess({ show, close, idAccount }) {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit, setError } = useForm({
    mode: "onSubmit",
    validationSchema: AccessSchema,
  });
  const [loading, setLoading] = React.useState(false);
  const [field, setField] = React.useState({
    name: "",
    username: "",
    access: [],
  });

  const desc = [
    "Berikan akses untuk melihat dashboard",
    "Berikan akses untuk melihat, mengedit, membuat dan menghapus di bagian produk",
    "Berikan akses untuk melihat, mengedit, membuat dan menghapus di bagian perangkat",
    "Berikan akses untuk melihat, mengedit, membuat dan menghapus di bagian pelanggan",
    "Berikan akses untuk melihat dan mengembalikan dana di bagian transaksi",
    "Berikan akses untuk melihat laporan",
    "Berikan akses untuk melakukan payout saldo",
    "Berikan akses untuk melihat, mengedit, membuat dan menghapus  di bagian pengaturan",
  ];
  let icon = [
    <IconHome fill="#0066CC" className="mr-3" />,
    <IconProduct fill="#0066CC" className="mr-3" />,
    <IconDevices fill="#0066CC" className="mr-3" />,
    <IconCustomerHappy fill="#0066CC" className="mr-3" />,
    <IconTransactions fill="#0066CC" className="mr-3" />,
    <IconReports fill="#0066CC" className="mr-3" />,
    <IconWallet fill="#0066CC" className="mr-3" />,
    <IconSettings fill="#0066CC" className="mr-3" />,
  ];

  const onChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const onChangeAccess = (id) => {
    let _temp = [...field.access];

    _temp.forEach((item) => {
      if (item.id === id) item.status = !item.status;
    });

    setField({ ...field, access: _temp });
  };

  const createAccess = () => {
    let joinAccess = [];
    let id = [1, 2, 3, 4, 5, 6, 7, 8];
    let path = [
      "/dashboard",
      "/products",
      "/devices",
      "/customers",
      "/transaction",
      "/reports",
      "/balance",
      "/settings",
    ];
    let icon = [
      "home.svg",
      "products.svg",
      "devices.svg",
      "customer.svg",
      "transactions.svg",
      "reports.svg",
      "wallet.svg",
      "settings.svg",
    ];

    let text = [
      "Dashboard",
      "Produk",
      "Perangkat",
      "Pelanggan",
      "Transaksi",
      "Laporan",
      "Saldo",
      "Pengaturan",
    ];
    let classes = ["primary", "primary", "primary", "primary", "light", "light", "light", "light"];
    for (let i = 0; i < id.length; i++) {
      joinAccess.push({
        id: id[i],
        path: path[i],
        text: text[i],
        icon: `https://api-demo.dalenta.tech/public/icons/${icon[i]}`,
        class: classes[i],
        status: true,
      });
    }
    setField({ ...field, access: joinAccess });
  };

  const onSubmit = async () => {
    let payload = {
      name: field.name,
      access: field.access,
    };
    setLoading(true);
    await putData(`v2/account/${idAccount}`, payload)
      .then((res) => {
        console.log(res);
        console.log(payload);
        if (res.data.code === 301) {
          setError("email", "email", "Email already exists");
          setLoading(false);
        } else if (res.data.code === 200) {
          let notif = {
            message: res.data.message,
            type: "primary",
            status: "success",
            toggle: true,
          };
          dispatch(setNotif(notif));
          setLoading(false);
          dispatch(fetchAccounts());
          setField({ ...field, username: "", name: "" });
          close();
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 422) {
          err.response.data.forEach((error) => {
            setError(error.param, error.param, error.message);
            setLoading(false);
          });
        }
        setLoading(false);
      });
  };

  const getOneAccount = async () => {
    await getData(`v2/account/${idAccount}`).then((res) => {
      setField({
        username: res?.data?.account.email,
        name: res?.data?.account.username,
        access: res?.data?.account.access,
      });
    });
  };
  useEffect(() => {
    createAccess();
    getOneAccount();
  }, []);

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title={<h1 className="text-gray-02 heading-3">Edit akses akun</h1>}
      textCenter={
        <p className="absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 heading-3 text-blue-03">
          {field.username}
        </p>
      }
      anotherAction={
        <Button
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          className="ml-4"
          variant="primary"
          color="blue"
          type="submit"
        >
          Selesai edit akses
        </Button>
      }
    >
      <div className={`bg-white border-t p-5 h-full overflow-scroll`}>
        <div className="w-728px max-w-728px m-auto">
          <h1 className="heading-3 text-gray-02">Informasi akun</h1>
          <ul className="mt-4 create-customer">
            <li className="flex w-full py-2">
              <InputTextLargeWithLabel
                className="w-full"
                label="Nama pemilik akun"
                register={register}
                status={errors?.name ? "error" : "default"}
                value={field.name}
                onChange={onChange}
                name="name"
              >
                {errors.name && (
                  <div className="flex items-center mt-1">
                    <TextCaption status="error" message={errors?.name?.message} />
                  </div>
                )}
              </InputTextLargeWithLabel>
            </li>
            <li className="flex w-full py-2">
              <InputTextLargeWithLabel
                className="w-full"
                label="Email akun"
                register={register}
                status={errors?.username ? "error" : "default"}
                value={field.username}
                onChange={onChange}
                name="username"
                type="email"
                disabled
              >
                {errors.username && (
                  <div className="flex items-center mt-1">
                    <TextCaption status="error" message={errors?.username?.message} />
                  </div>
                )}
              </InputTextLargeWithLabel>
            </li>
          </ul>
          <h1 className="heading-3 text-gray-02 mt-10">Akses</h1>

          <ul className="mt-6">
            {field?.access?.map((item, i) => {
              return (
                <li key={item.id} className="flex items-center w-full">
                  <div className="flex items-center border border-gray-03 w-72 bg-gray-05">
                    <div className="flex items-center relative p-7 border-r border-gray-03">
                      <div className="absolute top-5 right-11">
                        <Toggle
                          type="secondary"
                          checked={item.status}
                          onChange={() => onChangeAccess(item.id)}
                        />
                      </div>
                    </div>
                    <div className="flex items-center w-full pl-5 font-semibold">
                      {/* icon product */}
                      {icon[i]}
                      {item.text}
                      {/* text */}
                    </div>
                  </div>
                  <div className="border-b w-full border-gray-03 text-gray-02 pl-6 py-4 ">
                    {desc[i]}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </ModalDialog>
  );
}
