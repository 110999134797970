import {
  START_FETCHING_ACCOUNTS,
  SUCCESS_FETCHING_ACCOUNTS,
  ERROR_FETCHING_ACCOUNTS,
  SET_NOTIF,
} from "./constants";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchAccounts = debounce(getData, 1000);

export const startFetchingAccounts = () => {
  return {
    type: START_FETCHING_ACCOUNTS,
  };
};

export const successFetchingAccounts = ({ accounts }) => {
  return {
    type: SUCCESS_FETCHING_ACCOUNTS,
    accounts,
  };
};

export const errorFetchingAccounts = () => {
  return {
    type: ERROR_FETCHING_ACCOUNTS,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

export const fetchAccounts = () => {
  return async (dispatch) => {
    const notif = {
      id: 0,
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      dispatch(setNotif(notif));
    }, 5000);

    dispatch(startFetchingAccounts());
    try {
      let {
        data: { accounts },
      } = await debouncedFetchAccounts("v2/account");
      dispatch(successFetchingAccounts({ accounts }));
    } catch (error) {
      dispatch(errorFetchingAccounts());
    }
  };
};
