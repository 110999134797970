import React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import SelectBox from "components/atoms/SelectBox";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import TextCaption from "components/atoms/Text/TextCaption";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { getData, putData } from "utils/fetchData";
import { fetchCustomer, setNotif } from "features/Customer/actions";
import { fetchLog, setNotifLog } from "features/ReportLog/actions";
import { useDispatch } from "react-redux";
import "./index.css";

const phoneCode = [
  {
    label: "Malaysia",
    value: "+60",
    code: "my-MY",
  },
  {
    label: "Indonesia",
    value: "+62",
    code: "id-ID",
  },
  {
    label: "Singapore",
    value: "+65",
    code: "sg-SG",
  },
  {
    label: "Papua New Guinea",
    value: "+675",
    code: "pg-PG",
  },
  {
    label: "Timor-Leste",
    value: "+670",
    code: "tl-TL",
  },
];

export default function UpdateCustomer({ show, close, id }) {
  const dispatch = useDispatch();

  const CustomerSchema = yup.object().shape({
    first_name: yup
      .string()
      .required("nama depan tidak boleh kosong."),
    last_name: yup
      .string()
      .required("nama belakang tidak boleh kosong."),
    email: yup.string().required("email tidak boleh kosong."),
    website: yup.string().url(),
  });

  const [field, setField] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "62",
    phoneCode: "id-ID",
    groupId: 3,
  });

  const [code, setCode] = React.useState("+62");
  const [loading, setLoading] = React.useState(false);

  const [group, setGroup] = React.useState([]);
  const [defaultGroup, setDefaultGroup] = React.useState({
    label: "Pilih kelompok",
    value: "",
  });

  const [defaultPhone, setDefaultPhone] = React.useState({
    label: "Pilih kode",
    value: "",
  });

  const { register, errors, handleSubmit, setError } = useForm({
    mode: "onSubmit",
    validationSchema: CustomerSchema,
  });

  const onChange = (e) => {
    let value = e.target.value,
      name = e.target.name;
    setField({ ...field, [name]: value });
  };

  const onChangeGroup = (e) => {
    let value = e.value;
    setField({ ...field, groupId: e.value });
    setDefaultGroup({
      ...defaultGroup,
      label: e.label,
      value: value,
    });
  };

  const onChangePhone = (e) => {
    let value = e.value;
    setField({ ...field, phoneCode: e.code });
    setDefaultPhone({
      ...defaultPhone,
      label: e.label,
      value: value,
    });
    setCode(e.value);
  };

  const clearField = () => {
    setField({
      first_name: "",
      last_name: "",
      email: "",
      phone: "62",
      phoneCode: "id-ID",
      groupId: 3,
    });
    dispatch(fetchCustomer());
    dispatch(fetchLog());
    setLoading(false);
    close();
  };

  const onSubmit = async () => {
    setLoading(true);
    let payload = {
      first_name: field.first_name,
      last_name: field.last_name,
      email: field.email,
      phone: `62` + field.phone,
      phoneCode: field.phoneCode,
      groupId: field.groupId,
    };
    await putData(`v2/customer/${id}`, payload)
      .then((res) => {
        if (res.data.code === 200) {
          let notif = {
            message: res.data.message,
            type: "primary",
            status: "success",
            toggle: true,
          };
          dispatch(setNotif(notif));
          dispatch(setNotifLog(notif));

          clearField();
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          err.response.data.forEach((item) => {
            setError(item.param, item.param, item.message);
          });
          setLoading(false);
        }
      });
  };

  const fetchGroup = async () => {
    let _temp = [];
    await getData("v2/group").then((res) => {
      res.data.customersGroup.forEach((item) => {
        _temp.push({
          label: item.name,
          value: item.id,
        });
      });
    });
    setGroup(_temp);
  };

  const getOneCustomer = async () => {
    await getData(`v2/customer/${id}`).then((res) => {
      const result = res.data.customer;
      if (res.data.code === 200) {
        setField({
          first_name: result.first_name,
          last_name: result.last_name,
          email: result.email,
          phone: result.phone,
          phoneCode: result.phoneCode,
          groupId: result?.customersGroup?.id,
        });
        if (result.customersGroup) {
          setDefaultGroup({
            label: result?.customersGroup?.name,
            value: result?.customersGroup?.id,
          });
        } else {
          setDefaultGroup({
            label: "Pilih kelompok",
            value: 0,
          });
        }
        phoneCode.forEach((item) => {
          if (item.code === result.phoneCode) {
            setDefaultPhone({
              label: item.label,
              value: result.phoneCode,
            });
          }
        });
      }
    });
  };

  React.useEffect(() => {
    fetchGroup();
    getOneCustomer();
  }, []);

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title={
        <h1 className="text-gray-02 heading-3">Edit pelanggan</h1>
      }
      textCenter={
        <p className="absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 heading-3 text-blue-03">
          {field.first_name} {field.last_name}
        </p>
      }
      anotherAction={
        <Button
          disabled={
            field.first_name === "" ||
            field.last_name === "" ||
            field.email === "" ||
            field.phone === ""
              ? true
              : false
          }
          loading={loading}
          className="ml-4"
          variant="primary"
          color="blue"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Selesai edit pelanggan
        </Button>
      }
    >
      <div
        className={`bg-white border-t p-5 h-full overflow-scroll flex justify-center`}
      >
        <div className="w-728px max-w-728px create-customer">
          <h1 className="heading-3 text-gray-02">
            Informasi pelanggan
          </h1>
          {/* form example don't delete */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className="mt-4 create-customer">
              <li className="flex w-full border-b border-gray-04 py-2">
                <InputTextLargeWithLabel
                  className="w-full"
                  label="Nama depan pelanggan"
                  register={register}
                  status={errors?.first_name ? "error" : "default"}
                  value={field.first_name}
                  onChange={onChange}
                  name="first_name"
                >
                  {errors.first_name && (
                    <div className="flex items-center mt-1">
                      <TextCaption
                        status="error"
                        message={errors.first_name?.message}
                      />
                    </div>
                  )}
                </InputTextLargeWithLabel>
              </li>
              <li className="flex w-full border-b border-gray-04 py-2">
                <InputTextLargeWithLabel
                  className="w-full"
                  label="Nama belakang pelanggan"
                  register={register}
                  status={errors?.last_name ? "error" : "default"}
                  value={field.last_name}
                  onChange={onChange}
                  name="last_name"
                >
                  {errors.last_name && (
                    <div className="flex items-center mt-1">
                      <TextCaption
                        status="error"
                        message={errors.last_name?.message}
                      />
                    </div>
                  )}
                </InputTextLargeWithLabel>
              </li>
              <li className="flex w-full border-b border-gray-04 py-2">
                <SelectBox
                  label="Kelompok"
                  className="w-full"
                  type="text"
                  value={defaultGroup}
                  options={group}
                  onChange={onChangeGroup}
                  name="groupId"
                />
              </li>
              <li className="flex w-full border-b border-gray-04 py-2 nomor-telepon">
                <div className="w-9/12">
                  <SelectBox
                    label="Telepon"
                    className="w-full"
                    type="text"
                    value={defaultPhone}
                    options={phoneCode}
                    onChange={onChangePhone}
                  />
                </div>
                <div className="w-1/2 flex items-center">
                  <div className="bg-white border rounded-lg bold-text-2 h-full p-2 px-4 flex items-center ml-4 mr-4">
                    {code}
                  </div>
                  <div className="flex items-center w-full">
                    <input
                      name="phone"
                      onChange={onChange}
                      placeholder="ex: 628xxxxxx"
                      type="number"
                      className="focus:outline-none border pl-3 py-3 rounded-lg text-sm"
                      value={field.phone}
                    />
                  </div>
                </div>
              </li>
              <li className="flex w-full border-b border-gray-04 py-2">
                <InputTextLargeWithLabel
                  register={register}
                  status={errors?.email ? "error" : "default"}
                  value={field.email}
                  onChange={onChange}
                  name="email"
                  className="w-full"
                  label="Email"
                  status="default"
                >
                  {errors.email && (
                    <div className="flex items-center mt-1">
                      <TextCaption
                        status="error"
                        message={errors.email?.message}
                      />
                    </div>
                  )}
                </InputTextLargeWithLabel>
              </li>
            </ul>{" "}
          </form>
        </div>
      </div>{" "}
    </ModalDialog>
  );
}
