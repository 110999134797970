import * as React from "react";
import Spinner from "components/atoms/Spinner";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import { deleteData } from "utils/fetchData";
import { useDispatch, useSelector } from "react-redux";
import { setNotif, fetchTax } from "features/Tax/actions";
import Banner from "components/atoms/Banner";
// import Pagination from 'components/atoms/Pagination'
import TaxEdit from "./edit";

export default function ComponentTableTax({ data, status }) {
  const dispatch = useDispatch();
  const notif = useSelector((state) => state.tax.notif);

  const [check, setCheck] = React.useState({
    id: "",
    name: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [isSweetAlert, setIsSweetAlert] = React.useState(false);
  const [isShowEdit, setIsShowEdit] = React.useState(false);
  const [taxId, setTaxId] = React.useState();

  const handleShowSweetAlert = (payload) => {
    setCheck({
      ...check,
      id: payload.id,
      name: payload.name,
    });
    setIsSweetAlert(true);
  };

  const handleShowEdit = (payload) => {
    setTaxId(payload.id);
    setIsShowEdit(true);
  };

  const handleDeleteTax = async (id) => {
    setLoading(true);
    const res = await deleteData(`tax/${id}`);

    if (res.data?.code === 200) {
      setLoading(false);
      const notif = {
        message: `Pajak "${check.name}" berhasil di hapus`,
        type: "primary",
        status: "danger",
        toggle: true,
      };
      dispatch(setNotif(notif));
      dispatch(fetchTax());
      setIsSweetAlert(false);
      setIsSweetAlert(false);
      setCheck({ id: "", name: "" });
    }
  };
  return (
    <React.Fragment>
      <table className="w-full">
        <thead>
          <tr>
            <th>Nama pajak</th>
            <th>Jumlah pajak</th>
            <th>Lokasi</th>
            <th colSpan={2}>Status</th>
          </tr>
        </thead>
        <tbody>
          {status === "process" ? (
            <tr key="status">
              <td colSpan={5} style={{ textAlign: "center" }}>
                <div className="flex items-center justify-center">
                  <Spinner size={64} color="#859DFF" />
                </div>
              </td>
            </tr>
          ) : data.length ? (
            data?.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{data.name}</td>
                    <td>
                      {data.type === 1
                        ? `Rp. ${data.value}`
                        : `${data.value} %`}
                    </td>
                    <td>{`${data.outlets.length} lokasi`}</td>
                    <td
                      className={`${data.status === 1 ? "text-blue-03" : "text-red-03"
                        }`}
                    >{`${data.status === 1 ? `Sedang aktif` : "Sedang tidak aktif"
                      }`}</td>
                    <td className="relative">
                      <Dropdown
                        variant="subtle"
                        color="blue"
                        content="icon only"
                      >
                        {/* wrong url edit i don't now */}
                        <DropdownList
                          type="button"
                          onClick={() => handleShowEdit(data)}
                          color="blue"
                        >
                          Edit pajak
                        </DropdownList>
                        <DropdownList
                          type="button"
                          onClick={() => handleShowSweetAlert(data)}
                          color="red"
                        >
                          Hapus pajak
                        </DropdownList>
                      </Dropdown>
                    </td>
                  </tr>
                  {notif.toggle && notif.id === data.id && (
                    <tr>
                      <td colSpan={5} className="p-0">
                        <Banner
                          status={notif.status}
                          type={notif.type}
                          message={notif.message}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                Tidak Ditemukan Data
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* START: ALERT DELETE */}
      <ModalDialog
        size={"alert"}
        show={isSweetAlert}
        onClose={() => setIsSweetAlert(false)}
        title={<h1 className="text-dark heading-3">Hapus pajak</h1>}
      >
        <div className={`bg-gray-05 p-5 h-full overflow-scroll`}>
          <p className="text-2">
            Apakah anda yakin ingin menghapus pajak &nbsp;
            <span className="text-blue-03 bold-text-2">“{check.name}”</span>? Aksi ini
            tidak bisa dibatalkan lagi jika pajak telah berhasil dihapus.
          </p>
        </div>
        <div className="px-5 flex justify-between items-center bg-white">
          <Button
            variant="secondary"
            color="blue"
            className="my-5"
            type="button"
            onClick={() => setIsSweetAlert(false)}
          >
            Batal hapus pajak
          </Button>
          <Button
            variant="primary"
            color="red"
            className="my-5"
            type="button"
            loading={loading ? true : false}
            disabled={loading ? true : false}
            onClick={!loading ? () => handleDeleteTax(check.id) : null}
          >
            Hapus pajak
          </Button>
        </div>
      </ModalDialog>
      {/* END: ALERT DELETE */}

      {/* START: EDIT TAX */}
      {isShowEdit && (
        <TaxEdit
          title="Ubah pajak"
          tax={taxId}
          show={isShowEdit}
          close={() => setIsShowEdit(false)}
        />
      )}
      {/* END: EDIT TAX */}
    </React.Fragment>
  );
}
