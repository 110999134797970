import React from "react";
import Spacing from "components/atoms/Spacing";

export default function Spacings() {
  return (
    <div className="pt-20 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <Spacing />
    </div>
  );
}
