import React from 'react'

export default function ArrowUp({width,fill,height,className}) {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33334 0.733325L9.06668 4.53333C9.33334 4.79999 9.33334 5.19999 9.06668 5.46666C8.80001 5.73333 8.40001 5.73333 8.13334 5.46666L4.80001 2.19999L1.46668 5.46666C1.20001 5.73333 0.80001 5.73333 0.533343 5.46666C0.40001 5.33333 0.333343 5.19999 0.333344 4.99999C0.333344 4.79999 0.40001 4.66666 0.533343 4.53332L4.26668 0.733325C4.60001 0.466658 5.00001 0.466659 5.33334 0.733325C5.26668 0.733325 5.26668 0.733325 5.33334 0.733325Z" fill={fill}/>
</svg>

    )
}

ArrowUp.defaultProps ={
     width:'9px',
    height:'6px',
    fill:'#8F8F8F'
}
