import React from "react";
import ModalDialog from "components/molecules/Modal";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import Button from "components/atoms/Button";
import SelectBox from "components/molecules/SelectBox/Select";
import Toggle from "components/atoms/Toggle";
import ModalStock from "./stock";
import ModalTrack from "./tracking";
import ModalMeasure from "pages/Inventory/Measure/create";
import { useSelector, useDispatch } from "react-redux";
import {
  setName,
  setUnit,
  createMaterial,
  setModal,
  setModalSecondary,
  setMeasureName,
  setMeasureUnit,
} from "features/Material/action";
import { setModal as setModalMeasure } from "features/Measure/action";
import "./style.css";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextCaption from "components/atoms/Text/TextCaption";

const MeasureSchema = yup.object().shape({
  name: yup.string()
    .required("nama bahan baku tidak boleh kosong."),
  unit: yup.string().required("singkatan satuan ukur tidak boleh kosong."),
});

function MaterialCreate({ data, handleChange, operator }) {
  //redux state
  const dispatch = useDispatch();
  const material = useSelector((state) => state.materials);
  const measure = useSelector((state) => state.measure);
  const [isShowCreateMeasure, setIsShowCreateMeasure] = React.useState(false)

  const { register, handleSubmit, errors, setError } = useForm({
    mode: "onBlur",
    validationSchema: MeasureSchema,
  });


  //payload data
  const payload = {
    name: material.name,
    unitOfMeasureId: material.unitOfMeasureId,
    stocks: material.stocks,
  };

  return (
    <>
      <ModalDialog
        size={"large"}
        show={
          material.modal === "show-create" || material.modal === "show-stock"
            ? true
            : false
        }
        onClose={() => {
          dispatch(setModal("hide-create"));
          dispatch(setName(""));
          dispatch(setUnit(0));
          dispatch(setMeasureName("Pilih satuan ukur"));
          dispatch(setMeasureUnit(""));
        }}
        title="Tambah bahan baku"
        anotherAction={
          <>
            <span className="text-blue-700 font-semibold">{material.name}</span>
            <Button
              disabled={
                material.name.length > 0 && material.unitOfMeasureId !== 0
                  ? false
                  : true
              }
              onClick={() => dispatch(createMaterial(payload))}
              variant="primary"
              color="blue"
              type="submit"
            >
              Selesai tambah bahan baku
            </Button>
          </>
        }
      >
        <div className="container-body flex items-start justify-center bg-white">
          <div className="w-728px max-w-728px">
            <p className="heading-3">Informasi bahan baku</p>
            {/* <InputTextSmall
                  className="w-full"
                  placeholder=""
                  value={material.name}
                  type="Text"
                  name="name"
                  onChange={(e) => dispatch(setName(e.target.value))}
                /> */}
            <div className="ml-14 mt-4">
              <InputTextLargeWithLabel
                className="w-full"
                placeholder=""
                label="Nama bahan baku"
                status="default"
                value={material.name}
                type="Text"
                name="name"
                status={errors?.name ? "error" : "default"}
                register={register}
                onChange={(e) => dispatch(setName(e.target.value))}>
                {errors.name && <div className="flex items-center mt-1">
                  <TextCaption status="error" message={errors.name?.message} />
                </div>}
              </InputTextLargeWithLabel>
            </div>

            <div className="flex w-full justify-between items-center mt-1 ml-5">
              <p className="bold-text-2 w-48 text-gray-02 mr-5 text-right">
                Satuan ukur
              </p>
              <div className="w-full">
                <SelectBox
                  defaultValue={material.measure_name}
                  items={measure.select_box}
                  onClick={(id, value, title) => {
                    dispatch(setUnit(id));
                    dispatch(setMeasureName(`${title} (${value})`));
                    dispatch(setMeasureUnit(value));
                  }}
                  anotherAction={
                    <li
                      onClick={() => setIsShowCreateMeasure(true)}
                      className="cursor-pointer text-sm font-medium text-center text-white bg-green-03 hover:bg-green-02 duration-500 p-3 "
                    >
                      Tambah satuan ukur lainya
                    </li>
                  }
                />
              </div>
            </div>

            <div className="grid gap-2 mt-20 grid-cols-2">
              <Button
                onClick={() => dispatch(setModalSecondary("show-stock-create"))}
                variant="secondary"
                color="blue"
                className="cols-span-1"
              >
                Edit stok bahan baku
              </Button>
              <Button
                onClick={() => dispatch(setModalSecondary("show-track-create"))}
                variant="secondary"
                color="blue"
                className="cols-span-1"
              >
                Edit pelacakan bahan baku
              </Button>
            </div>
            <ul>
              {data.stocks.length
                ? data.stocks.map((items) => {
                  return (
                    <li
                      key={items.outletId}
                      className="p-6 rounded-lg bg-gray-05 flex items-start mt-6"
                    >
                      <p className="bold-text-1 w-36 mr-5 whitespace-nowrap relative top-3">
                        {items.outletName}
                      </p>
                      <div className="flex flex-col w-full justify-between">
                        <div className="py-4 px-6 border rounded-lg bg-white  w-full flex justify-between">
                          <span className="bold-text-2">
                            Lacak & hitung stok bahan baku
                          </span>
                          <div className="relative right-10">
                            <Toggle
                              size="lg"
                              checked={items.trackStock}
                              name="track-stock"
                              value={items.outletId}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {items.trackStock ? (
                          <>
                            {/* FIELD STOCK */}
                            <div className="flex items-center w-full">
                              <div className="w-4/12">
                                <p className="bold-text-2 text-gray-02 text-right mr-4">
                                  Stok
                                </p>
                              </div>
                              <div className="w-8/12">
                                <div className="frame-children">
                                  <input
                                    name="afterStock"
                                    type="number"
                                    onChange={handleChange}
                                    data-value={items.outletId}
                                    value={items.afterStock}
                                    placeholder="1,000"
                                    className="input-material"
                                  />
                                  <span className="font-small-gray">
                                    {material.measure_unit}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* FIELD REASON */}
                            <div className="flex items-center w-full">
                              <div className="w-4/12">
                                <p className="bold-text-2 text-gray-02 text-right mr-4">
                                  Alasan perubahan
                                </p>
                              </div>
                              <div className="w-8/12">
                                <div className="frame-children">
                                  <select
                                    onChange={handleChange}
                                    name="reasonId"
                                    data-value={items.outletId}
                                    className="w-full focus:outline-none focus:shadow-none"
                                    value={items.reasonId}
                                  >
                                    <option>Pilih alasan</option>
                                    {data.reason.map((reason) => {
                                      return (
                                        <option
                                          value={reason.id}
                                          key={reason.id}
                                        >
                                          {reason.title}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* FIELD TRACKING LIMIT */}
                            <div className="frame-children">
                              <span className="font-small-gray">
                                Peringatan stok
                              </span>
                              <div className="mr-10">
                                <Toggle
                                  size="lg"
                                  checked={items.trackingStatus}
                                  name="track-limit"
                                  value={items.outletId}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            {items.trackingStatus ? (
                              <div className="flex items-center w-full">
                                <div className="w-4/12">
                                  <p className="font-small-gray">
                                    Peringatan sewaktu
                                  </p>
                                </div>
                                <div className="w-8/12">
                                  <div className="frame-children">
                                    <input
                                      type="number"
                                      onChange={handleChange}
                                      name="limitStock"
                                      data-value={items.outletId}
                                      value={items.limitStock}
                                      placeholder="1,000"
                                      className="input-material"
                                    />
                                    <span className="font-small-gray">
                                      {material.measure_unit}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                  );
                })
                : ""}
            </ul>
          </div>
        </div>
      </ModalDialog>
      <ModalStock
        handleSubmit={() => dispatch(setModalSecondary("hide-stock-table"))}
        handleChange={handleChange}
        operator={operator}
        data={data}
        show={data.modal_secondary === "show-stock-create" ? true : false}
        close={() => dispatch(setModalSecondary("hide-stock-create"))}
      />
      <ModalTrack
        handleChange={handleChange}
        handleSubmit={() => dispatch(setModalSecondary("hide-track-create"))}
        data={data}
        show={data.modal_secondary === "show-track-create" ? true : false}
        close={() => dispatch(setModalSecondary("hide-track-create"))}
      />
      <ModalMeasure open={isShowCreateMeasure} close={() => setIsShowCreateMeasure(false)} type="material" />
    </>
  );
}

export default MaterialCreate;