export const START_FETCHING_STOCK = "features/Stock/START_FETCHING_STOCK";
export const ERROR_FETCHING_STOCK = "features/Stock/ERROR_FETCHING_STOCK";
export const SUCCESS_FETCHING_STOCK = "features/Stock/SUCCESS_FETCHING_STOCK";

//show data & filter
export const SET_DATA = "features/Stock/SET_DATA";
export const SET_DATA_TEMPORARY = "features/Stock/SET_DATA_TEMPORARY";
export const SET_KEYWORD = "features/Stock/SET_KEYWORD";
export const SET_PAGE = "features/Stock/SET_PAGE";
export const SET_LIMIT = "features/Stock/SET_LIMIT";
export const SET_TOTAL_PAGE = "features/Stock/SET_TOTAL_PAGE";
export const SET_EXPAND = "features/Stock/SET_EXPAND";
export const SET_MATERIAL_SELECTED = "features/Stock/SET_MATERIAL_SELECTED";

export const SET_WITH_MATERIAL_ONLY = "features/Stock/SET_WITH_MATERIAL_ONLY";
export const SET_WITH_VARIANT_ONLY = "features/Stock/SET_WITH_VARIANT_ONLY";
export const SET_WITH_VARIANT_ONLY_SINGLE = "features/Stock/SET_WITH_VARIANT_ONLY_SINGLE";
export const SET_WITH_MATERIAL_VARIANT = "features/Stock/SET_WITH_MATERIAL_VARIANT";
export const SET_WITH_MATERIAL_VARIANT_SINGLE = "features/Stock/SET_WITH_MATERIAL_VARIANT_SINGLE";
export const SET_WITHOUT_MATERIAL_VARIANT = "features/Stock/SET_WITHOUT_MATERIAL_VARIANT";

//modal & another action
export const SET_MODAL_PRIMARY = "features/Stock/SET_MODAL_PRIMARY";
export const SET_MODAL_SECONDARY = "features/Stock/SET_MODAL_SECONDARY";
export const PANCE_ARRAY = "features/Stock/PANCE_ARRAY";

//toggle
export const SET_TOGGLE_MATERIAL = "features/Stock/SET_TOGGLE_MATERIAL";
export const SET_TOGGLE_VARIANT = "features/Stock/SET_TOGGLE_VARIANT";
export const SET_DISABLED_VARIANT = "features/Stock/SET_DISABLED_VARIANT";

export const SET_PRODUCT_NAME = "features/Stock/SET_PRODUCT_NAME";
export const SET_OUTLETS = "features/Stock/SET_OUTLETS";
export const SET_VARIANT_WITH_OUTLETS = "features/Stock/SET_VARIANT_WITH_OUTLETS";
export const SET_ID_STOCK = "features/Stock/SET_ID_STOCK";
export const SET_ID_VARIANT = "features/Stock/SET_ID_VARIANT";
export const SET_NOTIF = "features/Stock/SET_NOTIF";
