import {
  START_FETCHING_BANK,
  SUCCESS_FETCHING_BANK,
  ERROR_FETCHING_BANK,
  SET_SELECTBOX,
  SET_NOTIF,
} from "./constants";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchBank = debounce(getData, 1000);

export const startFetchingBank = () => {
  return {
    type: START_FETCHING_BANK,
  };
};

export const successFetchingBank = ({ bank }) => {
  return {
    type: SUCCESS_FETCHING_BANK,
    bank,
  };
};

export const errorFetchingBank = () => {
  return {
    type: ERROR_FETCHING_BANK,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

export const setSelectBox = (select_box) => {
  return {
    type: SET_SELECTBOX,
    select_box,
  };
};

export const fetchBank = () => {
  return async (dispatch) => {
    const notif = {
      id: 0,
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      dispatch(setNotif(notif));
    }, 5000);

    dispatch(startFetchingBank());
    try {
      let {
        data: { data },
      } = await debouncedFetchBank("balance/bank");
      let bank = data;
      dispatch(successFetchingBank({ bank }));
    } catch (error) {
      dispatch(errorFetchingBank());
    }
  };
};

export const fetchBankInBalance = () => {
  return async (dispatch) => {
    try {
      let {
        data: { data },
      } = await getData("balance/bank");
      let select_box = [];
      data.forEach((item) => {
        select_box.push({
          value: item.id,
          label: `${item.bank_name} - ${item.holder_name} - ${item.number}`,
        });
      });
      dispatch(setSelectBox(select_box));
    } catch (error) {
      console.log(error.response);
    }
  };
};
