import {
  START_FETCHING_REPORT_TREND_DAY,
  SUCCESS_FETCHING_REPORT_TREND_DAY,
  ERROR_FETCHING_REPORT_TREND_DAY,
  SUCCESS_FETCHING_REPORT_TREND_WEEK,
  SUCCESS_FETCHING_REPORT_TREND_YEAR,
  SET_LOCATION,
  SET_DATE
} from "./constants";
import { userLogout } from 'features/Auth/actions'
import moment from 'moment'

import { getDatav2 } from "utils/fetchData";
import debounce from "debounce-promise";

export const startFetchingReportDay = () => {
  return {
    type: START_FETCHING_REPORT_TREND_DAY,
  };
};

export const errorFetchingReportDay = () => {
  return {
    type: ERROR_FETCHING_REPORT_TREND_DAY,
  };
};

export const successFetchingReportTrend = ({
  graphicDay,
  totalDayNow,
  totalDayOld,
  graphicWeek,
  totalWeekNow,
  totalWeekOld,
  graphicYear,
  totalYearNow,
  totalYearOld,
  location
}) => {
  return {
    type: SUCCESS_FETCHING_REPORT_TREND_DAY,
    graphicDay,
    totalDayNow,
    totalDayOld,
    graphicWeek,
    totalWeekNow,
    totalWeekOld,
    graphicYear,
    totalYearNow,
    totalYearOld,
    location
  };
};

export const fetchReportTrend = () => {
  return async (dispatch, getState) => {

    dispatch(startFetchingReportDay());

    try {

      let compare = moment(getState().reportTrend.compare).format('YYYY-MM-DD') ? moment(getState().reportTrend.compare).format('YYYY-MM-DD') : getState().reportTrend.compare || "";

      let outlet_id = getState().reportTrend.location || '';

      const params = {
        compare,
        outlet_id
      }

      let { data: { outlets }, } = await getDatav2("outlets");

      let select_box = [{ label: "Semua lokasi", value: "", }]
      outlets.forEach((item) => {
        select_box.push({
          label: item.name,
          value: item.id,
        });
      });

      const res = await getDatav2('v2/report/trend', params)
      if (res.data?.code === 401) {
        dispatch(userLogout())
      }

      let labels = [],
        dataDayNow = [],
        dataDayOld = [],
        totalDayNow = res?.data?.day?.now?.value,
        totalDayOld = res?.data?.day?.old?.value,
        labelWeek = [],
        dataWeekNow = [],
        dataWeekOld = [],
        totalWeekNow = res?.data?.week?.now?.value,
        totalWeekOld = res?.data?.week?.old?.value,
        labelYear = [],
        dataYearNow = [],
        dataYearOld = [],
        totalYearNow = res?.data?.year?.now?.value,
        totalYearOld = res?.data?.year?.old?.value;

      // start chart day
      res.data.day.now.data.forEach(chartDayNow => {
        labels.push(chartDayNow.x)
        dataDayNow.push(chartDayNow.y)
      });
      res.data.day.old.data.forEach(chartDayOld => {
        dataDayOld.push(chartDayOld.y)
      });

      const graphicDay = {
        maintainAspectRatio: false,
        responsive: false,
        labels,
        datasets: [
          {
            label: res.data.day.now.date,
            data: dataDayNow,
            borderColor: "rgb(31, 75, 255)",
            backgroundColor: ["rgba(31, 75, 255, 0.3)"],
            lineTension: 0,
          },
          {
            label: res.data.day.old.date,
            data: dataDayOld,
            borderColor: 'rgba(38,217,66)',
            backgroundColor: ['rgba(38,217,66, 0.3)'],
            lineTension: 0,
          }
        ]
      }
      // end chart day

      // start chart week
      res.data.week.now.data.forEach(chartWeekNow => {
        labelWeek.push(chartWeekNow.x)
        dataWeekNow.push(chartWeekNow.y)
      });

      res.data.week.old.data.forEach(chartWeekOld => {
        dataWeekOld.push(chartWeekOld.y)
      });

      const graphicWeek = {
        maintainAspectRatio: false,
        responsive: false,
        labels: labelWeek,
        datasets: [
          {
            label: res.data.week.now.date,
            data: dataWeekNow,
            borderColor: "rgb(31, 75, 255)",
            backgroundColor: ["rgba(31, 75, 255, 0.3)"],
            lineTension: 0,
          },
          {
            label: res.data.week.old.date,
            data: dataWeekOld,
            borderColor: 'rgba(38,217,66)',
            backgroundColor: ['rgba(38,217,66, 0.3)'],
            lineTension: 0,
          }
        ]
      }
      // end chart week

      // start chart year
      res.data.year.now.data.forEach(chartYearNow => {
        labelYear.push(chartYearNow.x)
        dataYearNow.push(chartYearNow.y)
      });

      res.data.year.old.data.forEach(chartYearOld => {
        dataYearOld.push(chartYearOld.y)
      });

      const graphicYear = {
        maintainAspectRatio: false,
        responsive: false,
        labels: labelYear,
        datasets: [
          {
            label: res.data.year.now.date,
            data: dataYearNow,
            borderColor: "rgb(31, 75, 255)",
            backgroundColor: ["rgba(31, 75, 255, 0.3)"],
            lineTension: 0,
          },
          {
            label: res.data.year.old.date,
            data: dataYearOld,
            borderColor: 'rgba(38,217,66)',
            backgroundColor: ['rgba(38,217,66, 0.3)'],
            lineTension: 0,
          }
        ]
      }
      // end chart year

      dispatch(successFetchingReportTrend({
        graphicDay: {
          type: "line",
          width: 300,
          height: 160,
          data: { id: 1, ...graphicDay }
        },
        totalDayNow,
        totalDayOld,
        graphicWeek: {
          type: "line",
          width: 300,
          height: 160,
          data: { id: 1, ...graphicWeek }
        },
        totalWeekNow,
        totalWeekOld,
        graphicYear: {
          type: "line",
          width: 300,
          height: 160,
          data: { id: 1, ...graphicYear }
        },
        totalYearNow,
        totalYearOld,
        location: select_box
      }))

    } catch (err) {
      dispatch(errorFetchingReportDay());
    }
  };
};

export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};

export const setDate = date => {
  return {
    type: SET_DATE,
    date
  }
}
