import * as  React from "react";
import ModalDialog from "components/molecules/Modal";
import IconArrowDown from "components/atoms/Icons/Custom/icon new/Arrow";
import { getData } from "utils/fetchData";
import { formatRupiah } from 'utils/formatRupiah'

export default function Faktur({ show, close, transaction }) {

  let [faktur, setFaktur] = React.useState({
    invoice: {},
    subTotalProduct: [],
    subTax: 0,
    totalPaid: 0,
    total: 0,
  })

  const [customer, setCustomer] = React.useState({})

  React.useEffect(() => {
    const getOneTransaction = async () => {
      try {

        const res = await getData(`sales/${transaction}`)


        if (res.data.code === 200) {
          setCustomer(res.data.sales.customer)

          let subTotalProduct = [],
            subTax = 0,
            total = 0

          if (res.data.sales.status === 'COMPLETED') {
            res.data.sales.details.forEach((product) => {
              let totalProduct = 0,
                totalVariant = 0,
                totalDiscount = 0
              totalProduct = totalProduct + product.price * product.qty
              product.detailVariants.forEach((variant) => {
                totalVariant = totalVariant + variant.price * product.qty
              })

              product.detailDiscounts.forEach((discount) => {
                totalDiscount += discount.amount
              })
              subTotalProduct.push({
                value: totalProduct + totalVariant - totalDiscount,
                status: product.status,
                product_id: product.product_id,
              })
            })

            res.data.sales.detailTaxes.forEach((tax) => {
              subTax += tax.amount
            })

            total = subTotalProduct.reduce(function (sum, record) {
              if (record.status == 'COMPLETED') {
                return sum + record.value
              } else {
                return sum
              }
            }, 0)
          } else {
            res.data.sales.details.forEach((product) => {
              let totalProduct = 0,
                totalVariant = 0,
                totalDiscount = 0
              totalProduct = totalProduct + product.price * product.qty
              product.detailVariants.forEach((variant) => {
                totalVariant = totalVariant + variant.price * product.qty
              })

              product.detailDiscounts.forEach((discount) => {
                totalDiscount += discount.amount
              })
              subTotalProduct.push({
                value: totalProduct + totalVariant - totalDiscount,
                status: product.status,
                product_id: product.product_id,
              })
            })

            res.data.sales.detailTaxes.forEach((tax) => {
              subTax += tax.amount
            })

            total = subTotalProduct.reduce(function (sum, record) {
              if (record.status == 'REFUND') {
                return sum + record.value
              } else {
                return sum
              }
            }, 0)
          }

          setFaktur({
            ...faktur,
            invoice: res.data.sales,
            total: total,
            totalPaid: total + subTax,
            subTax: subTax,
            subTotalProduct: subTotalProduct,
          })
        }
      } catch (err) {
        console.log(err.response)

      }
    }

    getOneTransaction()
  }, [transaction])

  return (
    <ModalDialog
      size={"medium"}
      show={show}
      onClose={close}
      title={
        <h1 className="text-gray-02 heading-3 relative -left-72">
          <span className={`${faktur?.invoice?.status === 'COMPLETED' ? 'text-blue-03' : 'text-red-03'}`}>
            {faktur?.invoice?.status === 'COMPLETED'
              ? `INV-${faktur.invoice.number}`
              : `REF-${faktur.invoice.number}`}
          </span> / {faktur?.invoice?.status === 'COMPLETED' ? 'Faktur penjualan' : 'Faktur refund'}
        </ h1>
      }
    >
      <div className={`bg-white border-t p-5 h-full overflow-scroll`}>

        {faktur?.invoice?.refrence?.status === 'REFUND' ? (
          <div
            className="bg-red py-2 position-fixed col-12 col-xl-10"
            style={{ zIndex: 1 }}
          >
            <div className="d-flex align-items-center justify-content-center">
              <p className="mb-0 ml-2 text-white fw-500">
                `Penjualan ada refund yang terkait. Lihat:`
                {/* <a onClick={props.openModalFaktur}>
                  {' '}
                  {props.faktur.invoice.refrence.number}
                </a> */}
              </p>
            </div>
          </div>
        ) : (
          ''
        )}
        <h1 className="heading-3 text-gray-02 flex items-center">
          Informasi penjualan
          <IconArrowDown
            fill="#0099FF"
            width="13"
            height="13"
            className="transform rotate-90 ml-4"
          />
        </h1>
        <div className="w-full mt-6">
          {faktur?.invoice?.details && faktur?.invoice?.details.map((detail, i) => {
            return (
              <React.Fragment key={i}>
                <div className="flex" style={{ width: "50%" }}>
                  <div
                    className="bg-blue-05 p-4 flex items-center font-semibold"
                    style={{ width: "60%" }}
                  >
                    {detail.name} x {detail.qty}
                    <IconArrowDown
                      fill="#0099FF"
                      width="13"
                      height="13"
                      className="transform rotate-90 ml-4"
                    />
                  </div>
                  {faktur?.subTotalProduct[i]?.status === 'REFUND' && <div className="pr-2 d-flex align-items-center">
                    <p
                      className="mb-0 ws-nowrap font-weight-bold font-12"
                      style={{ color: 'red' }}
                    >
                      Telah direfund
                      </p>
                  </div>}

                  <div
                    className="bg-gray-05 flex items-center justify-center font-semibold"
                    style={{ width: "40%" }}
                  >
                    {formatRupiah(
                      faktur?.subTotalProduct[i]?.value,
                    )}
                  </div>
                </div>
                <div className="flex border" style={{ width: "50%" }}>
                  <div className="py-4 pl-8" style={{ width: "60%" }}>
                    {detail.name}
                  </div>
                  <div
                    className="flex items-center justify-center"
                    style={{ width: "40%" }}
                  >
                    {formatRupiah(detail.price * detail.qty)}
                  </div>
                </div>
                {detail.detailVariants.map((variant, index1) => {
                  return (
                    <div className="flex border" style={{ width: "50%" }} key={index1}>
                      <div className="py-4 pl-8" style={{ width: "60%" }}>
                        {variant.option}
                      </div>
                      <div
                        className="flex items-center justify-center"
                        style={{ width: "40%" }}
                      >
                        + {formatRupiah(variant.price * detail.qty)}
                      </div>
                    </div>
                  )
                })}
                {detail.detailDiscounts.map((discount, index2) => {
                  return (
                    <div className="flex border" style={{ width: "50%" }} key={index2}>
                      <div className="py-4 pl-8" style={{ width: "60%" }}>
                        {discount.title}
                      </div>
                      <div className="py-4 pl-8" style={{ width: "60%" }}>
                        {discount.type === 1
                          ? `(${formatRupiah(
                            parseInt(discount?.value),
                          )})`
                          : `(${discount?.value} %)`
                        }
                      </div>
                      <div
                        className="flex items-center justify-center"
                        style={{ width: "40%" }}
                      >
                        - {formatRupiah(discount?.amount)}
                      </div>
                    </div>
                  )
                })}
              </React.Fragment>
            )
          })}

          <div className="flex border" style={{ width: "75%" }}>

            <div className="p-4 font-semibold" style={{ width: "67%" }}>
              Total produk
            </div>

            <div
              className="bg-blue-05 flex justify-center items-center font-semibold text-blue-03"
              style={{ width: "33%" }}
            >
              {formatRupiah(faktur.total)}
            </div>
            {/* end list right */}
          </div>
          {faktur.invoice.detailTaxes && faktur.invoice.detailTaxes.map((tax, index3) => {
            return (
              <div className="flex border" style={{ width: "75%" }} key={index3}>
                <div className="font-semibold p-4" style={{ width: "67%" }}>
                  {tax.title} <span className="font-normal">{`${tax.type === 1
                    ? `(${formatRupiah(parseInt(tax.value))})`
                    : `(${tax.value} %)`
                    }`}</span>
                </div>
                <div
                  className="bg-blue-05 font-semibold text-blue-03 flex items-center justify-center"
                  style={{ width: "33%" }}
                >
                  + {formatRupiah(tax.amount)}
                </div>
              </div>
            )
          })}

          {faktur.invoice.detailTaxes && faktur?.invoice?.detailTaxes.length > 0 ? <div className="flex border" style={{ width: "75%" }}>

            <div className="font-semibold p-4" style={{ width: "67%" }}>
              Total pajak
            </div>
            <div
              className="bg-blue-05 font-semibold text-blue-03 flex items-center justify-center"
              style={{ width: "33%" }}
            >
              + {formatRupiah(faktur.subTax)}
            </div>
          </div> : ""}

          {faktur.invoice.detailDiscounts && faktur?.invoice?.detailDiscounts.map((discount, index4) => {
            return (
              <div className="flex border" style={{ width: "75%" }} key={index4}>

                <div className="font-semibold p-4" style={{ width: "67%" }}>
                  {discount.title} <span className="font-normal">{`${discount.type === 1
                    ? `(${formatRupiah(parseInt(discount.value))})`
                    : `(${discount.value} %)`
                    }`}</span>

                </div>
                <div
                  className="bg-blue-05 font-semibold text-blue-03 flex items-center justify-center"
                  style={{ width: "33%" }}
                >
                  - {formatRupiah(discount?.amount)}
                </div>
              </div>
            )
          })}

          <div className="flex border" style={{ width: "100%" }}>
            <div
              className="text-blue-03 font-semibold p-4"
              style={{ width: "75%" }}
            >
              Total penjualan <span className="mb-0 font-14 text-gray-03 ml-2"> dibayar dengan {faktur?.invoice?.paymentMethod && faktur.invoice.paymentMethod.name}</span>
            </div>
            <div
              className="bg-blue-03 text-white font-semibold flex justify-center items-center"
              style={{ width: "25%" }}
            >
              {formatRupiah(parseInt(faktur?.totalPaid))}
            </div>
          </div>
        </div>
        {/* <h1 className="heading-3 text-gray-02 flex items-center mt-10">
          Informasi lainnya
          <IconArrowDown
            fill="#0099FF"
            width="13"
            height="13"
            className="transform rotate-90 ml-4"
          />

        </h1>
        <ul className="mt-4">
          <li className="border-b flex items-center">
            <div className="w-96">
              <p className="pl-4 whitespace-nowrap text-gray-02">
                Tanggal & waktu refund
              </p>
            </div>
            <div className="bg-gray-05 pl-4 py-4 w-full">
              <p>Rabu, April 28 2021, 10:00:14 pagi</p>
            </div>
          </li>
          <li className="border-b flex items-center">
            <div className="w-96">
              <p className="pl-4 whitespace-nowrap text-gray-02">
                Yang melakukan refund
              </p>
            </div>
            <div className="bg-gray-05 pl-4 py-4 w-full">
              <p>Medina</p>
            </div>
          </li>
          <li className="border-b flex items-center">
            <div className="w-96">
              <p className="pl-4 whitespace-nowrap text-gray-02">
                Penjualan terkait
              </p>
            </div>
            <div className="bg-gray-05 pl-4 py-4 w-full">
              <p>INV-065 lihat transaksi</p>
            </div>
          </li>
        </ul> */}
      </div>
    </ModalDialog>
  );
}
