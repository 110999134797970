import * as React from "react";
import PropsType from "prop-types";
import classnames from "classnames";

export const size = {
  sm: "24",
  md: "36",
  lg: "48",
};

const Logo = (props) => {
  const { size, className } = props;

  const weight = classnames(size);
  const classProps = classnames(className);

  return (
    <svg
      className={classProps}
      width={weight}
      height={weight}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.13459 12.0282C2.95514 11.8488 2.95514 11.5578 3.13459 11.3784L9.37746 5.13551C9.66694 4.84603 10.1619 5.05105 10.1619 5.46044L10.1619 17.9462C10.1619 18.3556 9.66694 18.5606 9.37746 18.2711L3.13459 12.0282Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M3.13459 12.0282C2.95514 11.8488 2.95514 11.5578 3.13459 11.3784L9.37746 5.13551C9.66694 4.84603 10.1619 5.05105 10.1619 5.46044L10.1619 17.9462C10.1619 18.3556 9.66694 18.5606 9.37746 18.2711L3.13459 12.0282Z"
        fill="url(#paint1_linear)"
        fillOpacity="0.5"
      />
      <path
        d="M3.13459 12.0282C2.95514 11.8488 2.95514 11.5578 3.13459 11.3784L9.37746 5.13551C9.66694 4.84603 10.1619 5.05105 10.1619 5.46044L10.1619 17.9462C10.1619 18.3556 9.66694 18.5606 9.37746 18.2711L3.13459 12.0282Z"
        fill="url(#paint2_linear)"
        fillOpacity="0.1"
      />
      <path
        d="M20.8654 12.0282C21.0449 11.8488 21.0449 11.5578 20.8654 11.3784L14.6225 5.13551C14.3331 4.84603 13.8381 5.05105 13.8381 5.46044V17.9462C13.8381 18.3556 14.3331 18.5606 14.6225 18.2711L20.8654 12.0282Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M20.8654 12.0282C21.0449 11.8488 21.0449 11.5578 20.8654 11.3784L14.6225 5.13551C14.3331 4.84603 13.8381 5.05105 13.8381 5.46044V17.9462C13.8381 18.3556 14.3331 18.5606 14.6225 18.2711L20.8654 12.0282Z"
        fill="url(#paint4_linear)"
        fillOpacity="0.5"
      />
      <path
        d="M20.8654 12.0282C21.0449 11.8488 21.0449 11.5578 20.8654 11.3784L14.6225 5.13551C14.3331 4.84603 13.8381 5.05105 13.8381 5.46044V17.9462C13.8381 18.3556 14.3331 18.5606 14.6225 18.2711L20.8654 12.0282Z"
        fill="url(#paint5_linear)"
        fillOpacity="0.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-1.7625"
          y1="5.33517"
          x2="17.1749"
          y2="20.0796"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.270833" stopColor="#0025B8" />
          <stop offset="0.572917" stopColor="#1F4BFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="4.6082"
          y1="24.6264"
          x2="15.0115"
          y2="2.18785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002952" />
          <stop offset="0.578615" stopColor="#1F4BFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="21.0295"
          y1="4.97802"
          x2="15.5294"
          y2="19.7231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBEEFF" />
          <stop offset="0.123129" stopColor="#859DFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="-1.7625"
          y1="5.33517"
          x2="17.1749"
          y2="20.0796"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.270833" stopColor="#0025B8" />
          <stop offset="0.572917" stopColor="#1F4BFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="4.6082"
          y1="24.6264"
          x2="15.0115"
          y2="2.18785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002952" />
          <stop offset="0.578615" stopColor="#1F4BFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="21.0295"
          y1="4.97802"
          x2="15.5294"
          y2="19.7231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBEEFF" />
          <stop offset="0.123129" stopColor="#859DFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

Logo.defaultProps = {
  size: size.sm,
};
Logo.defaultProps = {
  size: PropsType.string,
};

export default Logo;
