import React from 'react'
import { InputGroupSmall } from 'components/atoms/Form'
import TextCaption from 'components/atoms/Text/TextCaption'

export default function InputGroupContentIcon() {
  return (
    <div className="flex flex-col items-center">
      <div className="my-2">
        <InputGroupSmall status="default" content="icon" iconName="UilSearch" />
      </div>
      <div className="my-2">
        <InputGroupSmall status="disabled" disabled content="icon" iconName="UilSearch" />
      </div>
      <div className="my-2">
        <InputGroupSmall status="error" content="icon" iconName="UilSearch" />
        <TextCaption status="error" message="This field is required" />
      </div>
      <div className="my-2">
        <InputGroupSmall status="success" content="icon" iconName="UilSearch" />
        <TextCaption status="success" message="Field validation is successful" />
      </div>
      <div className="my-2">
        <InputGroupSmall status="warning" content="icon" iconName="UilSearch" />
        <TextCaption status="warning" message="This field is required" />
      </div>
    </div>
  )
}
