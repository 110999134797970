import React from "react";
import InputGroupSmall from "../InputGroupSmall";
import propTypes from "prop-types";

function InputTextSmallGroupWithLabel(props) {
  const {
    label,
    value,
    type,
    placeholder,
    name,
    onChange,
    onClick,
    size,
    status,
    content,
    category,
    iconName,
    iconColor,
    iconSize,
    className,
    disabled,
    width,
    dataValue,
  } = props;
  return (
    <div
      className={`relative flex flex-col text-gray-02 ${className} ${
        status === "default" ? "focus-within:text-blue-03" : ""
      } `}
    >
      <label className="font-semibold text-xs currentColor duration-500 mb-1">{label}</label>
      <InputGroupSmall
        iconName={iconName}
        iconColor={iconColor}
        iconSize={iconSize}
        category={category}
        onClick={onClick}
        content={content}
        disabled={disabled}
        value={value}
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        size={size}
        status={status}
        width={width}
        data-value={dataValue}
      />
    </div>
  );
}

InputTextSmallGroupWithLabel.propTypes = {
  label: propTypes.string.isRequired,
};

export default InputTextSmallGroupWithLabel;
