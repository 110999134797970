import React from "react";
import Button from "components/atoms/Button";

export default function Buttons() {
  return (
    <div className="pt-28 pl-10 pb-20 h-screen overflow-scroll scroll-hidden">
      <h1 className="mb-2 text-2xl">Button Blue</h1>

      {/* START:BUTTON BLUE */}
      <div className="w-full flex flex-col items-start">
        <div className="flex items-center">
          <div className="flex flex-col max-w-max">
            <Button variant="primary" color="blue" className="mt-3">
              Button
            </Button>
            <Button variant="primary" color="blue" disabled className="mt-3">
              Button
            </Button>
            <Button variant="primary" color="blue" loading className="mt-3">
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button variant="secondary" color="blue" className="mt-3">
              Button
            </Button>
            <Button variant="secondary" color="blue" disabled className="mt-3">
              Button
            </Button>
            <Button variant="secondary" color="blue" loading className="mt-3">
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button variant="outlined" color="blue" className="mt-3">
              Button
            </Button>
            <Button variant="outlined" color="blue" disabled className="mt-3">
              Button
            </Button>
            <Button variant="outlined" color="blue" loading className="mt-3">
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button variant="link" color="blue" className="mt-3">
              Button
            </Button>
            <Button variant="link" color="blue" disabled className="mt-3">
              Button
            </Button>
            <Button variant="link" color="blue" loading className="mt-3">
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="primary"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              disabled
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="secondary"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              disabled
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              loading
            >
              Button
            </Button>
          </div>
        </div>
        <div className="flex items-center mt-10">
          <div className="flex flex-col max-w-max">
            <Button
              variant="outlined"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              disabled
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="link"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
            >
              Button
            </Button>
            <Button
              variant="link"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              disabled
            >
              Button
            </Button>
            <Button
              variant="link"
              color="blue"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="primary"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              disabled
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="secondary"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              disabled
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="outlined"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              disabled
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="link"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
            >
              Button
            </Button>
            <Button
              variant="link"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              disabled
            >
              Button
            </Button>
            <Button
              variant="link"
              color="blue"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              iconSize={24}
              iconColor="#fff"
              loading
            >
              Button
            </Button>
          </div>
        </div>
      </div>

      {/* END:BUTTON BLUE */}

      {/* START:BUTTON GRAY */}
      <h1 className="mt-10 m2-2 text-2xl">Button Gray</h1>
      <div className="w-full flex flex-col items-start">
        <div className="flex items-center">
          <div className="flex flex-col max-w-max">
            <Button variant="primary" color="gray" className="mt-3">
              Button
            </Button>
            <Button variant="primary" color="gray" disabled className="mt-3">
              Button
            </Button>
            <Button variant="primary" color="gray" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button variant="secondary" color="gray" className="mt-3">
              Button
            </Button>
            <Button variant="secondary" color="gray" disabled className="mt-3">
              Button
            </Button>
            <Button variant="secondary" color="gray" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button variant="outlined" color="gray" className="mt-3">
              Button
            </Button>
            <Button variant="outlined" color="gray" disabled className="mt-3">
              Button
            </Button>
            <Button variant="outlined" color="gray" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button variant="link" color="gray" className="mt-3">
              Button
            </Button>
            <Button variant="link" color="gray" disabled className="mt-3">
              Button
            </Button>
            <Button variant="link" color="gray" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="primary"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="secondary"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>
        </div>
        <div className="flex items-center mt-5">
          {" "}
          <div className="flex flex-col max-w-max">
            <Button
              variant="outlined"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="link"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="link"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="link"
              color="gray"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="primary"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="secondary"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="outlined"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="link"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="link"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="link"
              color="gray"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>
        </div>
      </div>
      {/* END:BUTTON GRAY */}

      {/* START:BUTTON GREEN */}
      <h1 className="mt-10 m2-2 text-2xl">Button Green</h1>
      <div className="w-full flex flex-col items-start">
        <div className="flex items-center">
          <div className="flex flex-col max-w-max">
            <Button variant="primary" color="green" className="mt-3">
              Button
            </Button>
            <Button variant="primary" color="green" disabled className="mt-3">
              Button
            </Button>
            <Button variant="primary" color="green" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button variant="secondary" color="green" className="mt-3">
              Button
            </Button>
            <Button variant="secondary" color="green" disabled className="mt-3">
              Button
            </Button>
            <Button variant="secondary" color="green" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button variant="outlined" color="green" className="mt-3">
              Button
            </Button>
            <Button variant="outlined" color="green" disabled className="mt-3">
              Button
            </Button>
            <Button variant="outlined" color="green" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button variant="link" color="green" className="mt-3">
              Button
            </Button>
            <Button variant="link" color="green" disabled className="mt-3">
              Button
            </Button>
            <Button variant="link" color="green" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="primary"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="secondary"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>
        </div>
        <div className="flex items-center mt-5">
          <div className="flex flex-col max-w-max">
            <Button
              variant="outlined"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="link"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="link"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="link"
              color="green"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="primary"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="secondary"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="outlined"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="link"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="link"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="link"
              color="green"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>
        </div>
      </div>
      {/* END:BUTTON GREEN */}

      {/* START:BUTTON RED */}
      <h1 className="mt-10 m2-2 text-2xl">Button Red</h1>
      <div className="w-full flex flex-col items-start">
        <div className="flex items-center">
          <div className="flex flex-col max-w-max">
            <Button variant="primary" color="red" className="mt-3">
              Button
            </Button>
            <Button variant="primary" color="red" disabled className="mt-3">
              Button
            </Button>
            <Button variant="primary" color="red" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button variant="secondary" color="red" className="mt-3">
              Button
            </Button>
            <Button variant="secondary" color="red" disabled className="mt-3">
              Button
            </Button>
            <Button variant="secondary" color="red" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button variant="outlined" color="red" className="mt-3">
              Button
            </Button>
            <Button variant="outlined" color="red" disabled className="mt-3">
              Button
            </Button>
            <Button variant="outlined" color="red" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button variant="link" color="red" className="mt-3">
              Button
            </Button>
            <Button variant="link" color="red" disabled className="mt-3">
              Button
            </Button>
            <Button variant="link" color="red" loading className="mt-3">
              Button
            </Button>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="primary"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="secondary"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>
        </div>
        <div className="flex items-center mt-5">
          <div className="flex flex-col max-w-max">
            <Button
              variant="outlined"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="link"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="link"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="link"
              color="red"
              content="icon infront"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="primary"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="primary"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="secondary"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="secondary"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="outlined"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="outlined"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <Button
              variant="link"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
            >
              Button
            </Button>
            <Button
              variant="link"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              disabled
            >
              Button
            </Button>
            <Button
              variant="link"
              color="red"
              content="icon behind"
              className="mt-3"
              iconName="UilSearch"
              loading
            >
              Button
            </Button>
          </div>
        </div>
      </div>
      <h1 className="mt-10 text-2xl">Button Icon</h1>
      <div className="flex flex-col items-start">
        <div className="flex items-center">
          {/* START:ICON BLUE */}
          <div className="flex items-center">
            <div className="flex flex-col max-w-max">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="primary"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="primary"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="primary"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
            <div className="flex flex-col max-w-max ml-5">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="secondary"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="secondary"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="secondary"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
            <div className="flex flex-col max-w-max ml-5">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="outlined"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="outlined"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="outlined"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
            <div className="flex flex-col max-w-max ml-5">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="link"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="link"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="link"
                  color="blue"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
          </div>
          {/* END:ICON BLUE */}
          {/* START:ICON GRAY */}
          <div className="flex items-center">
            <div className="flex flex-col max-w-max ml-5">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="primary"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="primary"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="primary"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
            <div className="flex flex-col max-w-max ml-5">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="secondary"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="secondary"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="secondary"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
            <div className="flex flex-col max-w-max ml-5">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="outlined"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="outlined"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="outlined"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
            <div className="flex flex-col max-w-max ml-5">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="link"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="link"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="link"
                  color="gray"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
          </div>
        </div>
        {/* END:ICON GRAY */}
        <div className="flex items-center">
          {/* START:ICON GREEN */}
          <div className="flex items-center mt-5">
            <div className="flex flex-col max-w-max">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="primary"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="primary"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="primary"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
            <div className="flex flex-col max-w-max ml-5">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="secondary"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="secondary"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="secondary"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
            <div className="flex flex-col max-w-max ml-5">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="outlined"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="outlined"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="outlined"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
            <div className="flex flex-col max-w-max ml-5">
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="link"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="link"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                  disabled
                />
              </div>
              <div className="flex flex-col" className="mt-3">
                <Button
                  variant="link"
                  color="green"
                  content="icon only"
                  iconName="UilSearch"
                  loading
                />
              </div>
            </div>
          </div>

          {/* END:ICON GREEN */}
          {/* START:ICON RED */}
          <div className="flex flex-col max-w-max ml-5">
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="primary"
                color="red"
                content="icon only"
                iconName="UilSearch"
              />
            </div>
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="primary"
                color="red"
                content="icon only"
                iconName="UilSearch"
                disabled
              />
            </div>
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="primary"
                color="red"
                content="icon only"
                iconName="UilSearch"
                loading
              />
            </div>
          </div>

          <div className="flex flex-col max-w-max ml-5">
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="secondary"
                color="red"
                content="icon only"
                iconName="UilSearch"
              />
            </div>
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="secondary"
                color="red"
                content="icon only"
                iconName="UilSearch"
                disabled
              />
            </div>
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="secondary"
                color="red"
                content="icon only"
                iconName="UilSearch"
                loading
              />
            </div>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="outlined"
                color="red"
                content="icon only"
                iconName="UilSearch"
              />
            </div>
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="outlined"
                color="red"
                content="icon only"
                iconName="UilSearch"
                disabled
              />
            </div>
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="outlined"
                color="red"
                content="icon only"
                iconName="UilSearch"
                loading
              />
            </div>
          </div>
          <div className="flex flex-col max-w-max ml-5">
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="link"
                color="red"
                content="icon only"
                iconName="UilSearch"
              />
            </div>
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="link"
                color="red"
                content="icon only"
                iconName="UilSearch"
                disabled
              />
            </div>
            <div className="flex flex-col" className="mt-3">
              <Button
                variant="link"
                color="red"
                content="icon only"
                iconName="UilSearch"
                loading
              />
            </div>
          </div>
        </div>
      </div>
      {/* END:BUTTON RED */}
    </div>
  );
}
