import React from "react";
function WithMaterialVariant({ data, openMaterial }) {
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th scope="col">Produk ({data.length} variasi)</th>
          <th scope="col">SKU</th>
          <th scope="col">Bahan baku</th>
        </tr>
      </thead>
      <tbody>
        {data.map((current) => {
          return (
            <React.Fragment key={current.variantOptionId}>
              <tr>
                <td>{current.name}</td>
                <td>{current.sku}</td>
                <td>
                  {current.rawMaterial.length ? (
                    current.rawMaterial.map((prev, i) => {
                      return (
                        <span
                          onClick={() => openMaterial(current.variantOptionId, current.rawMaterial)}
                          key={i}
                        >
                          {prev.name},
                        </span>
                      );
                    })
                  ) : (
                    <span
                      onClick={() => openMaterial(current.variantOptionId)}
                      className="text-sm text-red-600"
                    >
                      Tidak ada bahan baku
                    </span>
                  )}
                </td>
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

export default WithMaterialVariant;
