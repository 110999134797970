import React from "react";
import propTypes from "prop-types";
import "assets/stylesheets/toggle.css";

function Toggle(props) {
  const { label, checked, onChange, type, size, disabled, name, value, className } = props;

  if (type === "secondary") {
    return (
      <label
        className={`${size === "lg" ? "wrap-toggle-secondary-large" : "wrap-toggle-secondary"}`}
      >
        <input
          value={value}
          name={name}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className={className}
        />
        <span className={`${size === "lg" ? "toggle-secondary-large" : "toggle-secondary"}`}></span>
        <span
          className={`${
            size === "lg" ? "toggle-default-secondary-large" : "toggle-default-secondary"
          }`}
        ></span>
        {label && size === "sm" && (
          <span className="font-normal text-sm relative bottom-1 ml-10 text-blue-02">{label}</span>
        )}
      </label>
    );
  }

  if (type === "primary") {
    return (
      <label className={`${size === "lg" ? "wrap-toggle-primary-large" : "wrap-toggle-primary"}`}>
        <input
          value={value}
          name={name}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className={className}
        />
        <span className={`${size === "lg" ? "toggle-primary-large" : "toggle-primary"}`}></span>
        <span
          className={`${size === "lg" ? "toggle-default-primary-large" : "toggle-default-primary"}`}
        ></span>
        {label && size === "sm" && (
          <span className="font-normal text-sm relative bottom-1 ml-10">{label}</span>
        )}
      </label>
    );
  }

  if (type === "disabled") {
    return (
      <label className={`${size === "lg" ? "wrap-toggle-disabled-large" : "wrap-toggle-disabled"}`}>
        <input
          value={value}
          className={className}
          name={name}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <span className={`${size === "lg" ? "toggle-disabled-large" : "toggle-disabled"}`}></span>
        <span
          className={`${
            size === "lg" ? "toggle-default-disabled-large" : "toggle-default-disabled"
          }`}
        ></span>
        {label && size === "sm" && (
          <span className="font-normal text-sm relative bottom-1 text-gray-04">{label}</span>
        )}
      </label>
    );
  }
}

Toggle.defaultProps = {
  type: "primary",
  size: "sm",
};

Toggle.propTypes = {
  checked: propTypes.bool,
  type: propTypes.string,
  size: propTypes.string,
  disabled: propTypes.bool,
  onChange: propTypes.func,
};

export default Toggle;
