import React from "react";
import { HorizontalTab } from "../Component/ComponentInput/Index";
import IconSearch from "components/atoms/Icons/Custom/Search";
export default function TabHorizontal() {
  return (
    <div className="pt-24 px-10 pb-20 w-full h-screen overflow-scroll scroll-hidden">
      <div className="flex items-center">
        <HorizontalTab />
        <HorizontalTab className={"ml-14"} fontSize={"text-xl"} />
      </div>
      <div className="flex items-center mt-2">
        <HorizontalTab
          SearchIcon={
            <IconSearch fill={"currentColor"} className={"mr-2 duration-200"} />
          }
        />
        <HorizontalTab
          className={"ml-14"}
          fontSize={"text-xl"}
          SearchIcon={
            <IconSearch
              fill={"currentColor"}
              className={"mr-2 duration-200"}
              width={"20px"}
            />
          }
        />
      </div>

      <ul className="flex w-full items-center border-b-2 mt-10">
        <li>
          <HorizontalTab Text={"Tab 1"} />
        </li>
        <li className="ml-4">
          <HorizontalTab Text={"Tab 2"} />
        </li>
        <li className="ml-4">
          <HorizontalTab Text={"Tab 3"} />
        </li>
        <li className="ml-4">
          <HorizontalTab Text={"Tab 4"} />
        </li>
      </ul>
      <ul className="flex items-center border-b-2 mt-5">
        <li>
          <HorizontalTab fontSize={"font-xl"} Text={"Tab 1"} />
        </li>
        <li className="ml-4">
          <HorizontalTab fontSize={"font-xl"} Text={"Tab 2"} />
        </li>
        <li className="ml-4">
          <HorizontalTab fontSize={"font-xl"} Text={"Tab 3"} />
        </li>
        <li className="ml-4">
          <HorizontalTab fontSize={"font-xl"} Text={"Tab 4"} />
        </li>
      </ul>
    </div>
  );
}
