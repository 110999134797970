import * as React from "react";
import Button from "components/atoms/Button";
import { Link } from "react-router-dom";

function FinishForgot() {
  const [email, setEmail] = React.useState("");
  const data = localStorage.getItem("reset_password");

  React.useEffect(() => {
    setEmail(data);
  }, [data]);
  return (
    <div className="flex justify-center bg-gray-05 h-screen">
      <div className="rounded-xl bg-white px-10 self-center h-2/4 shadow-gray">
        <div className="mb-10 mt-24 text-center">
          <span>Panduan untuk mengatur ulang kata sandi</span>
          <br />
          <span>
            telah dikirim ke <span className="text-blue-03">{email}.</span> Silahkan
          </span>
          <br />
          <span>cek email anda dan ikuti petunjuk selanjutnya.</span>
        </div>
        <Link to="/signin">
          <Button type="submit" variant="primary" color="white" isBlock>
            <span className="text-blue-03">Kembali ke halaman login</span>
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default FinishForgot;
