import React from "react";
import ImageField from "components/atoms/ImageField";
import "./index.css";
import IconCheck from "components/atoms/Icons/Custom/icon new/Check";
import Toggle from "components/atoms/Toggle";
import IconArrow from "components/atoms/Icons/Custom/icon new/Arrow";
import TextArea from "components/atoms/Form/InputTextArea";
import IconCustomerHappy from "components/atoms/Icons/Custom/icon new/CustomerHappy";
import IconSadFace from "components/atoms/Icons/Custom/icon new/SadFace";
import Banner from "components/atoms/Banner";
import { postData, getData, putData } from "utils/fetchData";
import { Link } from "react-router-dom";

export default function PageSettingDigital() {
  const [field, setField] = React.useState({
    logo: "",
    color: "",
    show_location: false,
    show_contact: false,
    show_media_social: false,
    term_condition: "",
    outro_text: "",
  });

  const [notif, setNotif] = React.useState({
    message: "",
    type: "",
    status: "",
    toggle: false,
  });

  const [brand, setBrand] = React.useState({});

  const [colorData, setColorData] = React.useState([
    {
      id: 0,
      color: "primary",
      visible: false,
    },
    {
      id: 1,
      color: "blue",
      visible: false,
    },
    {
      id: 2,
      color: "soft_blue",
      visible: false,
    },
    {
      id: 3,
      color: "tosca",
      visible: false,
    },
    {
      id: 4,
      color: "green",
      visible: false,
    },
    {
      id: 5,
      color: "soft_green",
      visible: false,
    },
    {
      id: 6,
      color: "red",
      visible: false,
    },
    {
      id: 7,
      color: "pink",
      visible: false,
    },
    {
      id: 8,
      color: "soft_red",
      visible: false,
    },
    {
      id: 9,
      color: "purple",
      visible: false,
    },
    {
      id: 10,
      color: "coffee",
      visible: false,
    },
    {
      id: 11,
      color: "orange",
      visible: false,
    },
    {
      id: 12,
      color: "gold",
      visible: false,
    },
    {
      id: 13,
      color: "sun",
      visible: false,
    },
    {
      id: 14,
      color: "black",
      visible: false,
    },
    {
      id: 15,
      color: "dark_grey",
      visible: false,
    },
    {
      id: 16,
      color: "grey",
      visible: false,
    },
    {
      id: 17,
      color: "light_grey",
      visible: false,
    },
  ]);

  const getSetting = async () => {
    let notif = {
      message: "",
      type: "",
      status: "",
      toggle: false,
    };
    setTimeout(() => {
      setNotif(notif);
    }, 5000);

    let {
      data: { data },
    } = await getData("v2/setting/receipt/digital");
    console.log(data);

    setColorData(
      colorData.map((item) => {
        if (item.color === data.color) {
          item.visible = true;
        } else {
          item.visible = false;
        }
        return item;
      })
    );

    setField(data);
  };

  const getBrand = async () => {
    await getData(`accounts/brand`)
      .then((res) => {
        if (res.data.code === 401) {
          window.location.href = "/signin";
        } else {
          setBrand(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const onChangeLogo = async (e) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    await postData("upload/single", formData).then((res) => {
      if (res.data.code === 200) {
        setField({ ...field, logo: res.data.data.path });
        let payload = { logo: res.data.data.path };
        putData(`v2/setting/receipt/digital`, payload).then((resChild) => {
          console.log(resChild);
          if (resChild.data.code === 200) {
            let notif = {
              message: resChild.data.message,
              type: "primary",
              status: "success",
              toggle: true,
            };
            setNotif(notif);
            getSetting();
          }
        });
      }
    });
  };

  const onUpdate = (payload) => {
    putData(`v2/setting/receipt/digital`, payload).then((res) => {
      console.log(res);
      if (res.data.code === 200) {
        let notif = {
          message: res.data.message,
          type: "primary",
          status: "success",
          toggle: true,
        };
        setNotif(notif);
        getSetting();
      }
    });
  };

  React.useEffect(() => {
    getSetting();
    getBrand();
  }, []);

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {notif.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={notif.message}
          status={notif.status}
          type={notif.type}
        />
      )}
      <div className="grid grid-cols-10 gap-7">
        <div className="col-span-7">
          {/* struk digital */}
          <h1 className="font-semibold text-xl">Struk digital</h1>
          <p className="text-gray-700 mt-2">
            Ubah dan personalisirkan struk digital bisnis kamu yang akan di kirimkan ke email
            pelanggan kamu setelah mereka melakukan pembayaran.
          </p>
          {/* branding */}
          <h1 className="font-semibold text-xl mt-10 mb-4">Branding</h1>
          <ul>
            <li className="border-b">
              <div className="flex">
                <div className="bg-gray-05" style={{ minWidth: "12rem" }}>
                  <p className="font-semibold pl-6 pt-6">Logo Bisnis</p>
                </div>
                <div className="p-6">
                  <ImageField
                    type="default"
                    label=""
                    action
                    value={field.logo}
                    name="image"
                    onChange={onChangeLogo}
                  />
                </div>
              </div>
            </li>
            <li className="border-b">
              <div className="flex">
                <div className="bg-gray-05" style={{ minWidth: "12rem" }}>
                  <p className="font-semibold pl-6 pt-6">Warna merek</p>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-9 gap-7">
                    {colorData.map((item, i) => {
                      return (
                        <div key={item.id} className="col-span-1">
                          <div
                            onClick={() => {
                              setColorData(
                                colorData.map((item) => {
                                  if (item.id === i) {
                                    item.visible = true;
                                  } else {
                                    item.visible = false;
                                  }
                                  return item;
                                })
                              );
                              onUpdate({ color: item.color });
                            }}
                            className={`w-8 h-8 rounded-full flex items-center justify-center ${item.color}`}
                          >
                            {item.visible ? <IconCheck fill="white" /> : ""}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </li>
          </ul>
          {/* branding */}
          <h1 className="font-semibold text-xl mt-10 mb-4">Informasi tambahan</h1>
          <ul>
            <li className="border">
              <div className="flex items-start p-4">
                <div className="relative top-1">
                  <Toggle
                    type="primary"
                    checked={field.show_location}
                    onChange={() => onUpdate({ show_location: !field.show_location })}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div className="pl-12">
                    <p className="font-semibold">Nama & alamat lokasi</p>
                    <p className="mt-4 text-sm">
                      Struk digital akan menampilkan nama dan alamat lokasi sesuai dengan apa yang
                      sudah dimasukkan di bagian pengaturan lokasi.
                    </p>
                  </div>
                  <Link to="/setting/locations">
                    <div className="flex items-center ml-3 ">
                      <p className="whitespace-nowrap text-sm font-semibold mr-2">
                        Lihat pengaturan lokasi
                      </p>
                      {/* icon arrow */}
                      <IconArrow fill="#000000" width="10" height="10" />
                    </div>
                  </Link>
                </div>
              </div>
            </li>
            <li className="border">
              <div className="flex items-start p-4">
                <div className="relative top-1">
                  <Toggle
                    type="primary"
                    checked={field.show_contact}
                    onChange={() => onUpdate({ show_contact: !field.show_contact })}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div className="pl-12">
                    <p className="font-semibold">Informasi kontak lokasi</p>
                    <p className="mt-4 text-sm">
                      Struk digital akan menampilkan nomor telepon dan email lokasi sesuai dengan
                      apa yang telah dimasukkan di bagian pengaturan lokasi.
                    </p>
                  </div>
                  <Link to="/setting/locations">
                    <div className="flex items-center ml-3 ">
                      <p className="whitespace-nowrap text-sm font-semibold mr-2">
                        Lihat pengaturan lokasi
                      </p>
                      {/* icon arrow */}
                      <IconArrow fill="#000000" width="10" height="10" />
                    </div>
                  </Link>
                </div>
              </div>
            </li>
            <li className="border">
              <div className="flex items-start p-4">
                <div className="relative top-1">
                  <Toggle
                    type="primary"
                    checked={field.show_media_social}
                    onChange={() => onUpdate({ show_media_social: !field.show_media_social })}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div className="pl-12">
                    <p className="font-semibold">Media sosial</p>
                    <p className="mt-4 text-sm">
                      Struk digital akan menampilkan semua media sosial yang sudah ada di bagian
                      halaman informasi akun.
                    </p>
                  </div>
                  <Link to="/setting">
                    <div className="flex items-center ml-3 ">
                      <p className="whitespace-nowrap text-sm font-semibold mr-2">
                        Lihat informasi akun
                      </p>
                      {/* icon arrow */}
                      <IconArrow fill="#000000" width="10" height="10" />
                    </div>
                  </Link>
                </div>
              </div>
            </li>
          </ul>
          <h1 className="font-semibold text-xl mt-10 mb-4">
            Tambah kalimat&nbsp;
            <span className="text-gray-600 text-base font-normal">
              (ketuk diluar form untuk simpan otomatis)
            </span>
          </h1>
          <ul className="setting-location">
            <li className="flex w-full border-gray-04 py-2 ">
              <TextArea
                variant="default"
                rows={5}
                placeholder="placeholder"
                label="Kebijakan pengambilan"
                name="textarea"
                value={field.term_condition}
                onChange={(e) => setField({ ...field, term_condition: e.target.value })}
                onBlur={() => onUpdate({ term_condition: field.term_condition })}
              />
            </li>
            <li className="flex w-full border-b border-gray-04 py-2">
              <TextArea
                variant="default"
                rows={5}
                placeholder="placeholder"
                label="Kalimat apresiasi"
                name="textarea"
                value={field.outro_text}
                onChange={(e) => setField({ ...field, outro_text: e.target.value })}
                onBlur={() => onUpdate({ outro_text: field.outro_text })}
              />
            </li>
          </ul>
        </div>
        <div className="col-span-3">
          <div className={`flex flex-col w-full justify-center items-center  py-7 ${field.color}`}>
            <div className="w-20 h-20 rounded-full bg-white flex items-center justify-center setting-struktur-digital logo-sello">
              {/* image here */}
              <img src={field.logo} alt={field.logo} />
            </div>
            <h1 className="mt-2 font-semibold text-white">{brand?.name}</h1>
            <p className="mt-1 font-semibold text-white">#INV-001</p>
          </div>
          <div className="flex flex-col w-full items-center justify-center bg-gray-05 py-5">
            <p className="mb-3 font-semibold text-lg">Bagaimana pengalaman anda?</p>
            <div className="flex items-center">
              <IconSadFace width="60" height="60" className="mr-5" fill="#DB1111" />
              <IconCustomerHappy width="69" height="69" />
            </div>
          </div>
          <div className="py-7 border-b">
            <p className="text-center text-3xl font-semibold">Rp. 110,000</p>
          </div>
          <ul className="mt-5">
            {/* title */}
            <li className="flex items-center justify-between font-semibold text-lg mt-2">
              <p>Nama produk</p>
              <p>Rp. 50,000</p>
            </li>
            {/* list items */}
            <li className="flex items-center justify-between mt-2">
              <p className="pl-4 text-sm">Nama produk</p>
              <p>Rp. 50,000</p>
            </li>
            <li className="flex items-center justify-between mt-2">
              <p className="pl-4 text-sm">Nama produk</p>
              <p>Rp. 50,000</p>
            </li>
          </ul>
          <ul className="pt-3 mt-4 border-t">
            {/* title */}
            <li className="flex items-center justify-between font-semibold text-lg mt-2">
              <p>Subtotal</p>
              <p>Rp. 100,000</p>
            </li>
            <li className="flex items-center justify-between font-semibold text-lg mt-2">
              <p>GST (10%)</p>
              <p>Rp. 10,000</p>
            </li>
          </ul>
          <div className="text-center mt-5 pt-5 border-t">
            {field.show_location && (
              <>
                <p className="font-semibold text-lg">{brand?.name}</p>
                <p className="mt-3 text-xs text-gray-600">{brand?.address}</p>
              </>
            )}

            {field.show_contact && (
              <p className="mt-3 text-xs text-gray-600">
                {brand?.phone}
                <br />
                {brand?.email}
              </p>
            )}
          </div>
          <div className="text-left mt-5 pt-5 border-t">
            <p className="font-semibold text-lg">Kebijakan pengembalian</p>
            <p className="mt-3 text-sm text-gray-600">{field.term_condition}</p>
          </div>
          <p className="font-semibold text-lg text-center mt-5 pt-5 border-t">{field.outro_text}</p>
        </div>
      </div>
    </div>
  );
}
