import React from "react";

export default function ComponentColor() {
  return (
    <div className="pt-28 pl-10 pb-20 h-screen overflow-scroll scroll-hidden">
      <div className="grid grid-cols-7 gap-5">
        <div className="col-span-1"></div>
        <div className="col-span-1 border p-3 text-xs rounded-md">
          <p className="font-semibold">No. 1</p>
          <p className="text-10px mt-2">#Dark colors for On press states.</p>
        </div>
        <div className="col-span-1 border p-3 text-xs rounded-md">
          <p className="font-semibold">No. 2</p>
          <p className="text-10px mt-2">
            Shaded colors for hover states and most !colored texts.
          </p>
        </div>
        <div className="col-span-1 border p-3 text-xs rounded-md">
          <p className="font-semibold">No. 3</p>
          <p className="text-10px mt-2">
            Shaded colors for hover states and most !colored texts.
          </p>
        </div>
        <div className="col-span-1 border p-3 text-xs rounded-md">
          <p className="font-semibold">No. 4</p>
          <p className="text-10px mt-2">
            Toned colors for disabled states and supporting elements.
          </p>
        </div>
        <div className="col-span-1 border p-3 text-xs rounded-md">
          <p className="font-semibold">No. 5</p>
          <p className="text-10px mt-2">
            Tinted colors for backgrounds and complementary elements.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-5  mt-5">
        <div className="col-span-1 rounded-md border p-1">
          <div className="bg-white flex items-center justify-center">
            <div className="text-10px">
              <p className="mt-2 font-semibold">GRAY</p>
              <p className="mt-1">
                Neutral color used for supporting texts, and complementary
                components.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-gray-01 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">01</p>
            <p>#292929</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-gray-02 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">02</p>
            <p>#5C5C5C</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-gray-03 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">03</p>
            <p>#8F8F8F</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-gray-04 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">04</p>
            <p>#C2C2C2</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-gray-05 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">05</p>
            <p>#F5F5F5</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-5 mt-10">
        <div className="col-span-1 rounded-md border p-1">
          <div className="bg-white flex items-center justify-center">
            <div className="text-10px text-blue-02">
              <p className="mt-2 font-semibold">BLUE</p>
              <p className="mt-1">
                Primary and brand color for primary CTAs, heading, texts, and
                components.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-blue-01 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">01</p>
            <p>#002952</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-blue-02 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">02</p>
            <p>#0025B8</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-blue-03 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">03</p>
            <p>#1F4BFF</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-blue-04 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">04</p>
            <p>#859DFF</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-blue-05 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">05</p>
            <p>#EBEEFF</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-5 mt-10">
        <div className="col-span-1 rounded-md border p-1">
          <div className="bg-white flex items-center justify-center">
            <div className="text-10px text-green-02">
              <p className="mt-2 font-semibold">GREEN</p>
              <p className="mt-1">
                Secondary color used to indicate a state or message of success.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-green-01 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">01</p>
            <p>#033A20</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-green-02 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">02</p>
            <p>#06884A</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-green-03 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">03</p>
            <p>#0AD674</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-green-04 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">04</p>
            <p>#62F8B0</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-green-05 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">05</p>
            <p>#ECFEF5</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-5 mt-10">
        <div className="col-span-1 rounded-md border p-1">
          <div className="bg-white flex items-center justify-center">
            <div className="text-10px text-red-02">
              <p className="mt-2 font-semibold">RED</p>
              <p className="mt-1">
                Secondary color used to indicate a state or message of
                destructive and error.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-red-01 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">01</p>
            <p>#52001B</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-red-02 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">02</p>
            <p>#B80006</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-red-03 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">03</p>
            <p>#FF1F26</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-red-04 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">04</p>
            <p>#FF8589</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-red-05 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">05</p>
            <p>#FFEBEB</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-5 mt-10">
        <div className="col-span-1 rounded-md border p-1">
          <div className="bg-white flex items-center justify-center">
            <div className="text-10px text-orange-02">
              <p className="mt-2 font-semibold">ORANGE</p>
              <p className="mt-1">
                Secondary color used to indicate a state or message of warning.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-orange-01 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">01</p>
            <p>#52001B</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-orange-02 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">02</p>
            <p>#522700</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-orange-03 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">03</p>
            <p>#FF711F</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-orange-04 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">04</p>
            <p>#FFB185</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-orange-05 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">05</p>
            <p>#FFF3EB</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-5 mt-10">
        <div className="col-span-1 rounded-md border p-1">
          <div className="bg-white flex items-center justify-center">
            <div className="text-10px text-purple-02">
              <p className="mt-2 font-semibold">PURPLE</p>
              <p className="mt-1">
                Secondary color used for other states and/or properties, etc:
                charts & tags.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-purple-01 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">01</p>
            <p>#210052</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-purple-02 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">02</p>
            <p>#8100B8</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-purple-03 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">03</p>
            <p>#BC1FFF</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-purple-04 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">04</p>
            <p>#DA85FF</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-purple-05 h-16 flex items-center justify-center rounded-md"></div>
          <div className="text-xs flex justify-between mt-2 px-1">
            <p className="font-semibold">05</p>
            <p>#FAEBFF</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-5 mt-10">
        <div className="col-span-2 border rounded-md p-3">
          <p className="font-semibold">Neutrals</p>
          <p className="text-10px mt-2">
            Basic colors used for majority of headings, texts, and backgrounds.
          </p>
        </div>
        <div className="col-span-2 border p-3 rounded-md bg-dark text-white">
          <p className="font-semibold">Dark</p>
          <p className="text-10px mt-2">#000D23</p>
          <p className="text-10px mt-2">
            Primarily used for typographies and other forms of texts.
          </p>
        </div>
        <div className="col-span-2 border p-3  rounded-md text-dark">
          <p className="font-semibold">LIGHT</p>
          <p className="text-10px mt-2">#FFFFFF</p>
          <p className="text-10px mt-2">
            The only color for background of a page and other forms of canvas.
          </p>
        </div>
      </div>
    </div>
  );
}
