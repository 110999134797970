import * as React from "react";
import ModalDialog from "components/molecules/Modal";
import SearchBars from "components/atoms/SearchBar";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Button from "components/atoms/Button";
import Checkbox from "components/atoms/Checkbox";
import { formatRupiah } from 'utils/formatRupiah'

export default function PilihProduk({
  handleFilterCategory,
  filterCategory,
  categoryInDiscount,
  productOnfilter,
  search,
  filterProduct,
  onChangeSelectAllProduct,
  isCheckedAll,
  show,
  close,
  onCheckboxProduct,
  onSelectProduct
}) {

  return (
    <ModalDialog
      size={"large"}
      show={show}
      onClose={close}
      title={
        <h1 className="heading-3 text-dark">Pilih produk ke dalam diskon</h1>
      }
    >
      <div className="container-body flex items-start justify-center bg-gray-05">
        <div className="w-full">
          <div className="flex items-center">
            <div className="w-1/5 mr-4">
              <SearchBars
                placeholder="Cari produk"
                onChange={filterProduct}
                name="search"
                value={search}
              />
            </div>
            <div className="dropdown-color">
              <Dropdown
                variant="outlined"
                color="gray"
                className="mr-1"
                content="label only"
                label={filterCategory.label}
              >
                <DropdownList
                  onClick={() => handleFilterCategory(filterCategory, true)}
                  list="checkbox"
                  checked={filterCategory.isChecked}
                >
                  Semua lokasi
                </DropdownList>
                {categoryInDiscount.map((data, i) => (
                  <DropdownList
                    key={i}
                    onClick={() => handleFilterCategory(data)}
                    checked={data.isChecked}
                    value={data.isChecked}
                    list="checkbox"
                  >
                    {data.label}
                  </DropdownList>))}
              </Dropdown>
            </div>
          </div>
          <table className="w-full mt-2 table-pilih-produk">
            <thead>
              <tr>
                <th>
                  {/* checkbox */}
                  <div className="relative top-1px">
                    <Checkbox
                      type="default"
                      checked={isCheckedAll.status}
                      value={isCheckedAll.status}
                      name="status"
                      disabled={productOnfilter.length === 0 ? true : false}
                      onChange={e => onChangeSelectAllProduct(e)}
                    />
                  </div>
                  <p className="pl-8">Nama produk</p>
                </th>
                <th>Kategori</th>
                <th>Jumlah variasi</th>
                <th>Harga jual</th>
              </tr>
            </thead>
            <tbody>
              {productOnfilter.length > 0 ? (productOnfilter.map((data, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {/* checkbox */}
                      <div className="relative top-1px">
                        <Checkbox
                          type="default"
                          checked={data.isChecked}
                          name="checkbox"
                          value={data.id}
                          onChange={(e) => onCheckboxProduct(e)}
                        />
                      </div>
                      <p className="pl-8">{data.name}</p>
                    </td>
                    <td>{data.category?.categoryName}</td>
                    <td>{data?.variants?.length} variasi</td>
                    <td>{formatRupiah(data.price)}</td>
                  </tr>
                )
              })) : (<tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  Tidak Ditemukan Data
              </td>
              </tr>)}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center justify-between absolute left-0 bottom-0 w-full py-4 px-6 bg-white border-t">
        <Button variant="secondary" color="blue" onClick={close}>
          Buang perubahan
        </Button>
        <div className="flex items-center">
          <p className="bold-text-2 mr-4">{isCheckedAll.count} produk dipilih</p>
          <Button variant="primary" color="blue" onClick={() => { onSelectProduct(); close() }}>
            Selesai pilih perubahan
          </Button>
        </div>
      </div>
    </ModalDialog>
  );
}
