import {
  START_FETCHING_LOCATION,
  SUCCESS_FETCHING_LOCATION,
  ERROR_FETCHING_LOCATION,
  SUCCESS_FETCHING_CHECKBOX_LOCATION,
  SET_NOTIF,
} from "./constants";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";

// (1) bungkus `getLocation` dengan `debounce`
let debouncedFetchLocations = debounce(getData, 1000);

export const startFetchingLocations = () => {
  return {
    type: START_FETCHING_LOCATION,
  };
};

export const errorFetchingLocations = () => {
  return {
    type: ERROR_FETCHING_LOCATION,
  };
};

export const successFetchingLocations = ({ outlets, select_box }) => {
  return {
    type: SUCCESS_FETCHING_LOCATION,
    outlets,
    select_box,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};

export const fetchLocations = () => {
  return async (dispatch) => {
    const notif = {
      id: 0,
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      dispatch(setNotif(notif));
    }, 5000);
    dispatch(startFetchingLocations());

    try {
      let {
        data: { outlets },
      } = await debouncedFetchLocations("v2/location");
      let select_box = [{ label: "Semua lokasi", value: "", name: "outlet" }];
      outlets.forEach((item) => {
        select_box.push({ label: item.outletName, value: item.outletId, name: "outlet" });
      });
      dispatch(successFetchingLocations({ outlets, select_box }));
    } catch (err) {
      dispatch(errorFetchingLocations());
    }
  };
};

export const fetchCheckboxLocations = () => {
  return async (dispatch) => {
    dispatch(startFetchingLocations());

    try {
      let {
        data: { outlets },
      } = await debouncedFetchLocations("v2/location");

      let select_box = [];
      outlets.forEach((item) => {
        select_box.push({
          label: item.outletName,
          value: item.outletId,
          isChecked: true,
        });
      });
      dispatch(successFetchingCheckboxLocations({ outlets: select_box }));
    } catch (err) {
      dispatch(errorFetchingLocations());
    }
  };
};

export const successFetchingCheckboxLocations = ({ outlets }) => {
  return {
    type: SUCCESS_FETCHING_CHECKBOX_LOCATION,
    outlets,
  };
};
