import {
  START_FETCHING_PAYOUT,
  SUCCESS_FETCHING_PAYOUT,
  ERROR_FETCHING_PAYOUT,
  SET_BALANCE,
  SET_NOTIF,
} from "./constants";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";

let debouncedFetchPayout = debounce(getData, 1000);

export const startFetchingPayout = () => {
  return {
    type: START_FETCHING_PAYOUT,
  };
};

export const successFetchingPayout = ({ payout }) => {
  return {
    type: SUCCESS_FETCHING_PAYOUT,
    payout,
  };
};

export const errorFetchingPayout = () => {
  return {
    type: ERROR_FETCHING_PAYOUT,
  };
};

export const setNotif = (notif) => {
  return {
    type: SET_NOTIF,
    notif,
  };
};
export const setBalance = (balance) => {
  return {
    type: SET_BALANCE,
    balance,
  };
};

export const fetchPayout = () => {
  return async (dispatch) => {
    const notif = {
      id: 0,
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      dispatch(setNotif(notif));
    }, 5000);

    dispatch(startFetchingPayout());
    try {
      let {
        data: { data },
      } = await debouncedFetchPayout("v2/balance/payout");
      let payout = data;
      dispatch(successFetchingPayout({ payout }));
    } catch (error) {
      dispatch(errorFetchingPayout());
    }
  };
};

export const fetchBalance = () => {
  return async (dispatch) => {
    const notif = {
      id: 0,
      message: "",
      type: "",
      status: "",
      toggle: false,
    };

    setTimeout(() => {
      dispatch(setNotif(notif));
    }, 5000);
    try {
      let {
        data: { balance },
      } = await getData("v2/balance");
      dispatch(setBalance(balance));
    } catch (error) {
      console.log(error.response);
    }
  };
};
