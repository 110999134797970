import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MaterialEditHandler,
  setModalSecondary,
  ReciptManageHandler,
  EditStockHandler,
  EditTrackStockHandler,
  setToggleMaterial,
  setToggleVariant,
  setVariantOnly,
  setVariantWithLocation,
  setMaterialOnly,
  setWithMaterialVariant,
  setWithoutMaterialVariant,
  fetchStock,
  setIdStock,
  setNotif,
} from "features/Stock/actions";
import { putData } from "utils/fetchData";

//component
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import Toggle from "components/atoms/Toggle";

//option
import MaterialOnly from "./option/option_2";
import VariantOnly from "./option/option_1";
import MaterialVariant from "./option/option_3";
import WithoutMaterialVariant from "./option/option_4";

//modal import
import MaterialCreate from "./modal/materialCreate";
import MaterialManage from "./modal/materialManage";
import ReciptManage from "./modal/reciptManage";
import EditStockVariant from "./modal/editStockVariant";
import EditTrackStockVariant from "./modal/editTrackStockVariant";
import MaterialEdit from "./modal/materialEdit";
import ProductCreate from "pages/Product/create";
import ModalBarcode from "./modal/barcode";

function InventoryCreate({ hide, show }) {
  const dispatch = useDispatch();
  //indux
  const inventory = useSelector((state) => state.inventory);
  // const materials = useSelector((state) => state.materials);
  const [isProductBarcode, setIsProductBarcode] = React.useState(false);
  console.log("inventory");
  console.log(inventory);
  // console.log("materials");
  // console.log(materials);
  const [isShowCreate, setIsShowCreate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  //1. toggle handler
  const toggleVariant = useSelector((state) => state.inventory.withVariant);
  const toggleMaterial = useSelector((state) => state.inventory.withMaterial);
  // const disabledVariant = useSelector((state) => state.inventory.disabledVariant);

  const handleToggle = (e) => {
    if (e.target.name === "variant") {
      dispatch(setToggleVariant(!toggleVariant));
      // dispatch(setDisabledVariant(!disabledVariant));
    } else {
      dispatch(setToggleMaterial(!toggleMaterial));
    }
  };

  const clearModal = () => {
    dispatch(setIdStock(0));
    dispatch(setVariantOnly([]));
    dispatch(setMaterialOnly([]));
    dispatch(setWithMaterialVariant([]));
    dispatch(setVariantWithLocation([]));
    dispatch(setWithoutMaterialVariant([]));
    dispatch(fetchStock());
    setIsLoading(false);
    hide();
  };

  const submitData = async () => {
    let payload = {};
    if (!toggleVariant & !toggleMaterial) {
      payload = {
        withVariant: toggleVariant,
        withMaterial: toggleMaterial,
        locations: inventory.withoutMaterialVariant,
      };
    } else if (toggleVariant & toggleMaterial) {
      payload = {
        withVariant: toggleVariant,
        withMaterial: toggleMaterial,
        inventory: inventory.withMaterialVariant,
      };
    } else if (!toggleVariant & toggleMaterial) {
      payload = {
        withVariant: toggleVariant,
        withMaterial: toggleMaterial,
        rawMaterial: inventory.withMaterialOnly,
      };
    } else if (toggleVariant & !toggleMaterial) {
      let _tempUpdate = [];
      inventory.variantWithlocation.forEach((item) => {
        if (item.isUpdate) {
          let _trueUpdate = [];
          item.variants.forEach((child) => {
            if (child.isUpdate) {
              _trueUpdate.push(child);
            }
          });
          _tempUpdate.push({
            outletId: item.outletId,
            outletName: item.outletName,
            variants: _trueUpdate,
          });
        }
      });
      payload = {
        withVariant: toggleVariant,
        withMaterial: toggleMaterial,
        locations: _tempUpdate,
      };
    }
    console.log(payload);
    if (inventory.idStock === 0 || inventory.modal_primary === "show-update-stock-product") {
      hide();
    } else {
      setIsLoading(true);
      await putData(`v2/inventory/${inventory.idStock}`, payload)
        .then((res) => {
          console.log("res putt");
          console.log(res);
          console.log(payload);
          let notif = {
            message: `Berhasil mengubah stok ${res.data.productName} `,
            type: "primary",
            status: "success",
            id: parseInt(res.data.inventoryId),
            toggle: true,
          };

          dispatch(setNotif(notif));
          dispatch(fetchStock());
          setIsLoading(false);
          dispatch(setIdStock(0));
          hide();
        })
        .catch((err) => {
          console.log(err.response);
          console.log(payload);
          setIsLoading(false);
          alert("Terjadi error server");
        });
    }
  };

  return (
    <>
      <ModalDialog
        size={"large"}
        show={show}
        onClose={() => clearModal()}
        title="Edit stok"
        anotherAction={
          <>
            <span className="text-blue-700 font-semibold">{inventory.productName}</span>
            <Button
              loading={isLoading}
              variant="primary"
              color="blue"
              type="submit"
              onClick={submitData}
            >
              Selesai edit stok
            </Button>
          </>
        }
      >
        <div className="container-body bg-white">
          <div className="w-3/5 mx-auto">
            <p className="my-5 font-medium text-lg">Metode perhitungan stok</p>

            {/* TOGGLE OPTION */}
            <div
              className={`w-full border border-gray-400 rounded-md p-4 flex justify-between ${
                inventory.disabledVariant ? "bg-gray-100" : ""
              }`}
            >
              <span
                className={`${
                  inventory.disabledVariant ? "text-gray-400" : "text-gray-600"
                } text-sm font-medium`}
              >
                Hitung berdasarkan variasi
              </span>
              <div className="mr-12">
                <Toggle
                  disabled={
                    inventory.disabledVariant && inventory.withVariantOnly.length === 0
                      ? true
                      : false
                  }
                  size="lg"
                  checked={toggleVariant}
                  name="variant"
                  onChange={handleToggle}
                />
              </div>
            </div>
            <div className="w-full border border-gray-400 rounded-md p-4 flex justify-between mb-14 mt-2">
              <span className="text-sm font-medium text-gray-600">
                Hitung berdasarkan resep dan bahan baku
              </span>
              <div className="mr-12">
                <Toggle
                  size="lg"
                  checked={toggleMaterial}
                  name="material"
                  onChange={handleToggle}
                />
              </div>
            </div>

            
            {toggleMaterial && !toggleVariant ? (
              ""
            ) : (
              <div className={`grid grid-cols-3 gap-2`}>
                {toggleMaterial && toggleVariant ? (
                  <Button
                    onClick={() => dispatch(ReciptManageHandler())}
                    variant="secondary"
                    color="blue"
                    className="my-5"
                  >
                    Edit resep
                  </Button>
                ) : (
                  <Button
                    onClick={() => dispatch(EditStockHandler())}
                    variant="secondary"
                    color="blue"
                    className="my-5"
                  >
                    Edit jumlah stok
                  </Button>
                )}
                {toggleMaterial && toggleVariant ? (
                  <Button
                    onClick={() => {
                      localStorage.setItem(
                        "variantUsed",
                        JSON.stringify(inventory.withMaterialVariant)
                      );
                      dispatch(setModalSecondary("show-materialManage-create"));
                    }}
                    variant="secondary"
                    color="blue"
                    className="my-5"
                  >
                    Tambah bahan baku
                  </Button>
                ) : (
                  <Button
                    onClick={() => dispatch(EditTrackStockHandler())}
                    variant="secondary"
                    color="blue"
                    className="my-5"
                  >
                    Edit pelacakan stok
                  </Button>
                )}

                <Button
                  onClick={() => setIsProductBarcode(true)}
                  variant="secondary"
                  color="blue"
                  className="my-5 whitespace-nowrap"
                >
                  Buat barcode produk
                </Button>
              </div>
            )}

            {toggleMaterial && toggleVariant ? (
              <MaterialVariant
                data={inventory.withMaterialVariant}
                openMaterial={(id, material) =>
                  dispatch(MaterialEditHandler(id, inventory.withMaterialVariant, material))
                }
              />
            ) : toggleMaterial && !toggleVariant ? (
              <MaterialOnly />
            ) : !toggleMaterial && toggleVariant ? (
              <VariantOnly />
            ) : (
              <WithoutMaterialVariant data={inventory} />
            )}
          </div>
        </div>
      </ModalDialog>
      <MaterialCreate
        close={() => dispatch(setModalSecondary("hide-materialCreate-create"))}
        show={inventory.modal_secondary === "show-materialCreate-create" ? true : false}
      />
      <MaterialEdit
        data={inventory}
        variant={inventory.withMaterialVariant}
        show={inventory.modal_secondary === "show-materialEdit-create" ? true : false}
        close={() => dispatch(setModalSecondary("hide-materialEdit-create"))}
      />
      <MaterialManage
        close={() => dispatch(setModalSecondary("hide-materialManage-create"))}
        show={inventory.modal_secondary === "show-materialManage-create" ? true : false}
      />
      <ReciptManage
        close={() => dispatch(setModalSecondary("hide-reciptManage-create"))}
        show={inventory.modal_secondary === "show-reciptManage-create" ? true : false}
      />
      {inventory.modal_secondary === "show-editStockVariant-create" && (
        <EditStockVariant
          close={() => dispatch(setModalSecondary("hide-editStockVariant-create"))}
          show={inventory.modal_secondary === "show-editStockVariant-create" ? true : false}
        />
      )}

      <EditTrackStockVariant
        close={() => dispatch(setModalSecondary("hide-editTrackStockVariant-create"))}
        show={inventory.modal_secondary === "show-editTrackStockVariant-create" ? true : false}
      />
      <ProductCreate
        title="Tambah produk baru"
        show={isShowCreate}
        close={() => setIsShowCreate(false)}
      />

      {/*
      * yang baru di handle bagian true/false 
      */}
      <ModalBarcode 
        status={!toggleVariant & !toggleMaterial ? 'false false' : 'true false'} 
        data={!toggleVariant & !toggleMaterial ? inventory.withoutMaterialVariant : inventory.withVariantOnly}  
        show={isProductBarcode} 
        close={() => setIsProductBarcode(false)} 
      />
    </>
  );
}

export default InventoryCreate;
