import * as React from "react";
import Toggle from "components/atoms/Toggle";

export default function Toggles() {
  const [primary, setPrimary] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  return (
    <div className="w-10/12 pt-16 mt-5">
      <div className="w-full flex flex-col items-start">
        <div className="flex items-center">
          <div className="flex flex-col max-w-max">
            <div className="m-4">
              <Toggle
                type="primary"
                checked={primary}
                onChange={() => setPrimary(!primary)}
              />
            </div>
            <div className="m-4">
              <Toggle
                type="secondary"
                checked={secondary}
                onChange={() => setSecondary(!secondary)}
              />
            </div>
            <div className="m-4">
              <Toggle
                type="disabled"
                checked={secondary}
                onChange={() => setSecondary(!secondary)}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <div className="flex flex-col max-w-max">
            <div className="m-4">
              <Toggle
                type="primary"
                size="lg"
                checked={primary}
                onChange={() => setPrimary(!primary)}
              />
            </div>
            <div className="m-4">
              <Toggle
                type="secondary"
                size="lg"
                checked={secondary}
                onChange={() => setSecondary(!secondary)}
              />
            </div>
            <div className="m-4">
              <Toggle
                type="disabled"
                size="lg"
                checked={secondary}
                onChange={() => setSecondary(!secondary)}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <div className="flex flex-col max-w-max">
            <div className="m-4">
              <Toggle
                label="Toggle"
                type="primary"
                checked={primary}
                onChange={() => setPrimary(!primary)}
              />
            </div>
            <div className="m-4">
              <Toggle
                label="Toggle"
                type="secondary"
                checked={secondary}
                onChange={() => setSecondary(!secondary)}
              />
            </div>
            <div className="m-4">
              <Toggle
                label="Toggle"
                type="disabled"
                checked={secondary}
                onChange={() => setSecondary(!secondary)}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
