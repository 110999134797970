import React from "react";
import { useForm } from "react-hook-form";
import { formatRupiah } from "utils/formatRupiah";
import { postData } from "utils/fetchData";
import { useDispatch } from "react-redux";
import { fetchPayout, setNotif, fetchBalance } from "features/Balance/actions";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import InputTextLargeWithLabel from "components/atoms/Form/InputTextLargeWithLabel";
import TextCaption from "components/atoms/Text/TextCaption";
import * as yup from "yup";
import SelectBox from "components/atoms/SelectBox";

export default function RequestPayout({ show, close, bank, balance }) {
  const dispatch = useDispatch();
  const PayoutSchema = yup.object().shape({
    value: yup.string().required("Jumlah transfer tidak boleh kosong."),
  });
  const { errors, register, handleSubmit, setError } = useForm({
    mode: "onSubmit",
    validationSchema: PayoutSchema,
  });

  const [defaultSelect, setDefaultSelect] = React.useState({
    label: "Pilih bank",
    value: "",
  });

  const [loading, setLoading] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [value, setValue] = React.useState(0);
  console.log("errors====>>>");
  console.log(errors);
  const onSubmit = async () => {
    setLoading(true);
    let payload = {
      bank_id: defaultSelect.value,
      value: value,
      description: description,
    };
    await postData("v2/balance", payload)
      .then((res) => {
        console.log("res=====>");
        console.log(res);
        if (res.data.code === 200) {
          let notif = {
            message: `Transfer dana ke ${res.data.data.holder_name} - ${res.data.data.number} akan diproses`,
            type: "primary",
            status: "success",
            toggle: true,
          };
          dispatch(setNotif(notif));
          dispatch(fetchPayout());
          dispatch(fetchBalance());
          setLoading(false);
          close();
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err.response====>>>");
        console.log(err.response);
        if (err.response.status === 422) {
          err.response.data.forEach((err) => {
            setError(err.param, err.param, err.message);
          });
          setLoading(false);
        }
        setLoading(false);
        console.log(err.response);
      });
  };

  return (
    <ModalDialog size={"alert"} show={show} onClose={close} title="Transfer Dana">
      <div className={`bg-white p-5 h-full overflow-scroll`}>
        <ul>
          <li className="bg-gray-05 bold-text-1 py-4 px-6">Destinasi rekening bank</li>
          <li className="text-bold-1 py-4 px-6 transfer-dana border-b border-gray-04">
            <SelectBox
              type="text"
              value={defaultSelect}
              options={bank}
              label=""
              onChange={(value) =>
                setDefaultSelect({
                  label: value.label,
                  value: value.value,
                })
              }
            />
          </li>

          <InputTextLargeWithLabel
            className="w-full mt-3"
            label="Jumlah transfer"
            status={errors?.value ? "error" : "default"}
            placeholder="Min. Rp. 10.000"
            name="price"
            currency="rp"
            register={register}
            onChange={(value) => setValue(value)}
            value={value}
          >
            {errors.value && (
              <div className="flex items-center mt-1">
                <TextCaption status="error" message={errors.value?.message} />
              </div>
            )}
          </InputTextLargeWithLabel>

          <InputTextLargeWithLabel
            className="w-full my-3"
            label="Deskripsi"
            name="description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />

          <li className="bg-blue-03 border-b border-white text-white text-1 py-4 px-6 border-opacity-50">
            Jumlah dana anda: {`${formatRupiah(balance)}`}
          </li>
          <li className="bg-blue-03 text-white text-1 py-4 px-6">
            Maksimal jumlah transfer: Rp. 20,000,000
          </li>
        </ul>
      </div>
      <div className="px-5 flex justify-end items-center bg-white">
        <Button onClick={close} variant="primary" color="red" className="my-4 mr-4" type="button">
          Batal
        </Button>
        <Button
          loading={loading}
          onClick={onSubmit}
          disabled={value === 0 || defaultSelect.value.length === 0 ? true : false}
          variant="primary"
          color="blue"
          className="my-4"
          type="button"
        >
          Transfer Dana
        </Button>
      </div>
    </ModalDialog>
  );
}
