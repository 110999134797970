import React from "react";
import InputGroupSmall from "../InputGroupSmall";
import propTypes from "prop-types";
import Info from "../InputTextLargeWithLabel/info";

function InputTextLargeGroupWithLabel(props) {
  const {
    label,
    value,
    type,
    placeholder,
    name,
    onChange,
    onClick,
    size,
    status,
    content,
    category,
    iconName,
    iconColor,
    iconSize,
    disabled,
    info,
    children,
    handleSwitch,
  } = props;

  if (content === "switch" || content === "text switch") {
    return (
      <div
        className={`flex items-center group text-gray-02 ${
          status === "default" ? "focus-within:text-blue-03" : ""
        }`}
      >
        <span
          className={`bold-text-2 currentColor duration-500 mb-1 mr-5 group-hover:text-blue-03 ${
            !children ? "" : "relative -top-2"
          }`}
        >
          {label}
        </span>
        {info && <Info />}

        <div className="relative">
          <div className="relative">
            <div className="absolute right-2 z-50 top-1/2 transform -translate-y-1/2">
              {handleSwitch}
            </div>
            <InputGroupSmall
              className={`w-540 py-4 ${info && "ml-5"}`}
              iconName={iconName}
              iconColor={iconColor}
              iconSize={iconSize}
              category={category}
              onClick={onClick}
              content={content}
              disabled={disabled}
              value={value}
              type={type}
              placeholder={placeholder}
              name={name}
              onChange={onChange}
              size={size}
              status={status}
            />
          </div>
          {children}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex items-center text-gray-02 ${
        status === "default" ? "focus-within:text-blue-03" : ""
      }`}
    >
      <span
        className={`bold-text-2 currentColor duration-500 mb-1 mr-5 ${
          !children ? "" : "relative -top-2"
        }`}
      >
        {label}
      </span>
      {info && <Info />}
      <div className="relative">
        <InputGroupSmall
          className={`w-540 py-4 ${info && "ml-5"}`}
          iconName={iconName}
          iconColor={iconColor}
          iconSize={iconSize}
          category={category}
          onClick={onClick}
          content={content}
          disabled={disabled}
          value={value}
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          size={size}
          status={status}
        />
        {children}
      </div>
    </div>
  );
}

InputTextLargeGroupWithLabel.defaultProps = {
  info: false,
};
InputTextLargeGroupWithLabel.propTypes = {
  label: propTypes.string.isRequired,
  info: propTypes.bool,
};

export default InputTextLargeGroupWithLabel;
