import React from "react";

export default function AV({ className, width, height, fillText, fillBg }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 64C0 28.6538 28.6538 0 64 0C99.3462 0 128 28.6538 128 64C128 99.3462 99.3462 128 64 128C28.6538 128 0 99.3462 0 64Z"
        fill={fillBg}
      />
      <path
        d="M38.98 78L48.62 51.6H55.22L64.86 78H59.14L53.1 60.04L51.9 56.04H51.82L50.7 59.96L44.62 78H38.98ZM44.66 71.56V67.48H59.14V71.56H44.66ZM88.0416 51.6L78.4416 78H72.0016L62.3616 51.6H68.2016L73.7616 68.4L75.2816 73.6L76.7616 68.44L82.3616 51.6H88.0416Z"
        fill={fillText}
      />
    </svg>
  );
}

AV.defaultProps = {
  width: "32",
  height: "32",
  fillText: "white",
  fillBg: "#1F4BFF",
};
