import React from 'react'
import InputTextLargeGroupWithLabel from 'components/atoms/Form/InputTextLargeGroupWithLabel'
import TextCaption from 'components/atoms/Text/TextCaption'

export default function InputGroupLargeContentText() {
  return (
    <>
      <div className="flex w-full items-center">
        <div className="flex flex-col items-center">
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="text" category="unit" label="Text field label" status="default" />
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="text" category="kg" label="Text field label" status="disabled" disabled />
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="text" label="Text field label" status="error">
              <div className="flex items-center mt-1">
                <TextCaption status="error" message="This field is required" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="text" category="unit" label="Text field label" status="success">
              <div className="flex items-center mt-1">
                <TextCaption status="success" message="Field validation is successful" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="text" category="kg" label="Text field label" status="warning">
              <div className="flex items-center mt-1">
                <TextCaption status="warning" message="This field is required" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center">
        <div className="flex flex-col items-center">
          <div className="my-2">
            <InputTextLargeGroupWithLabel content="text" category="unit" label="Text field label" info status="default" />
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel label="Text field label" info status="disabled" disabled />
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel label="Text field label" category="unit" info status="error">
              <div className="flex items-center mt-1 ml-5">
                <TextCaption status="error" message="This field is required" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel label="Text field label" category="unit" info status="success">
              <div className="flex items-center mt-1 ml-5">
                <TextCaption status="success" message="Field validation is successful" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
          <div className="my-2">
            <InputTextLargeGroupWithLabel label="Text field label" category="unit" info status="warning">
              <div className="flex items-center mt-1 ml-5">
                <TextCaption status="warning" message="This field is required" />
              </div>
            </InputTextLargeGroupWithLabel>
          </div>
        </div>
      </div>
    </>
  )
}
