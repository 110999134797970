import {
  START_FETCHING_HISTORY,
  ERROR_FETCHING_HISTORY,
  SUCCESS_FETCHING_HISTORY,
  SET_LIMIT,
  SET_PAGE,
  SET_TOTAL_PAGE,
  SET_PRODUCT,
  SET_CHANGE,
  SET_LOCATION,
  SET_DATE,
  SET_MODAL,
  SET_ID,
} from "./constants";

import { getData } from "utils/fetchData";
import debounce from "debounce-promise";
import moment from "moment";

let debouncedFetchHistory = debounce(getData, 1000);

export const startFetchingHistory = () => {
  return {
    type: START_FETCHING_HISTORY,
  };
};

export const errorFetchingHistory = () => {
  return {
    type: ERROR_FETCHING_HISTORY,
  };
};

export const successFetchingHistory = ({ history }) => {
  return {
    type: SUCCESS_FETCHING_HISTORY,
    history,
  };
};
export const setId = (id) => {
  return {
    type: SET_ID,
    id,
  };
};

export const fetchHistory = () => {
  return async (dispatch, getState) => {
    dispatch(startFetchingHistory());
    let id = getState().inventory_history.id || 0;
    let start = getState().inventory_history.date.startDate || "";
    let end = getState().inventory_history.date.endDate || "";
    let start_date = moment(start).format("YYYY-MM-DD");
    let end_date = moment(end).format("YYYY-MM-DD");
    let location = getState().inventory_history.location || "";
    let reason = getState().inventory_history.reason_stock || "";
    let page = getState().inventory_history.page || 1;
    let limit = getState().inventory_history.limit || 10;

    const params = {
      start_date,
      end_date,
      page,
      limit,
    };
    if (reason !== "") params.reason = reason
    if (location !== "") params.location = location

    try {
      let {
        data: { histories, pages },
      } = await debouncedFetchHistory(`v2/inventory/history/${id}`, params);
      let totalPage = pages;
      let history = histories;

      dispatch(successFetchingHistory({ history }));
      dispatch(setTotalPage(totalPage));
    } catch (err) {
      console.log(err.response);
      dispatch(errorFetchingHistory());
    }
  };
};

export const showModalHistory = (id) => {
  return (dispatch) => {
    dispatch(setId(id));
    dispatch(setModalHistory("show-historyStock-update"));

    dispatch(fetchHistory());
  };
};

export const setDate = (date) => {
  return {
    type: SET_DATE,
    date: date.selection ? date.selection : date,
  };
};

export const setLocationHistory = (location) => {
  return {
    type: SET_LOCATION,
    location,
  };
};
export const setReason = (reason_stock) => {
  return {
    type: SET_CHANGE,
    reason_stock,
  };
};
export const setProduct = (product) => {
  return {
    type: SET_PRODUCT,
    product,
  };
};

export const setLimitHistory = (limit) => {
  return {
    type: SET_LIMIT,
    limit,
  };
};
export const setPageHistory = (page = 1) => {
  return {
    type: SET_PAGE,
    page,
  };
};
export const setTotalPage = (totalPage) => {
  return {
    type: SET_TOTAL_PAGE,
    totalPage,
  };
};
export const setModalHistory = (modal) => {
  return {
    type: SET_MODAL,
    modal,
  };
};
