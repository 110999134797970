import React, { useEffect, useState } from "react";
import Spinner from "components/atoms/Spinner";
import Button from "components/atoms/Button";

import ModalDelete from "./delete";

import { useDispatch, useSelector } from "react-redux";
import { fetchBank } from "features/Bank/actions";

export default function TableBalanceBill() {
  const dispatch = useDispatch();
  const bank = useSelector((state) => state.bank);

  const [id, setId] = useState(0);
  const [isShowDelete, setIsShowDelete] = useState(false);

  const dropBank = (id) => {
    setId(id);
    setIsShowDelete(true);
  };

  useEffect(() => {
    dispatch(fetchBank());
  }, [dispatch]);
  return (
    <table className="w-full mt-4">
      <thead>
        <tr>
          <th>Nama pemilik</th>
          <th>Bank</th>
          <th>Rekening bank</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        {bank.status === "process" ? (
          <tr key="status">
            <td colSpan={3} style={{ textAlign: "center" }}>
              <div className="flex items-center justify-center">
                <Spinner size={64} color="#859DFF" />
              </div>
            </td>
          </tr>
        ) : bank.data.length ? (
          bank.data.map((item) => {
            return (
              <tr key={item.id}>
                <td className="text-blue-03">{item.holder_name}</td>
                <td>{item.bank_name}</td>
                <td>{item.number}</td>
                <td className="flex justify-end">
                  <Button variant="primary" color="red" onClick={() => dropBank(item.id)}>
                    Hapus
                  </Button>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              Tidak Ditemukan Data
            </td>
          </tr>
        )}
      </tbody>

      {isShowDelete && (
        <ModalDelete show={isShowDelete} close={() => setIsShowDelete(false)} id={id} />
      )}

      {/* modal tambah rekening bank */}
    </table>
  );
}
