import React from "react";
import ImageField from "components/atoms/ImageField";
import Toggle from "components/atoms/Toggle";
import IconArrow from "components/atoms/Icons/Custom/icon new/Arrow";
import TextArea from "components/atoms/Form/InputTextArea";
import Banner from "components/atoms/Banner";
import { postData, getData, putData } from "utils/fetchData";
import { Link } from "react-router-dom";

export default function PageSettingKertas() {
  const [field, setField] = React.useState({
    logo: "",
    show_location: true,
    show_contact: true,
    show_media_social: true,
    show_qrcode: true,
    term_condition: "",
    outro_text: "",
  });

  const [notif, setNotif] = React.useState({
    message: "",
    type: "",
    status: "",
    toggle: false,
  });

  const getSetting = async () => {
    let notif = {
      message: "",
      type: "",
      status: "",
      toggle: false,
    };
    setTimeout(() => {
      setNotif(notif);
    }, 5000);

    let {
      data: { data },
    } = await getData("v2/setting/receipt/print");

    setField(data);
  };

  const onChangeLogo = async (e) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    await postData("upload/single", formData).then((res) => {
      if (res.data.code === 200) {
        setField({ ...field, logo: res.data.data.path });
        let payload = { logo: res.data.data.path };
        putData(`v2/setting/receipt/print`, payload).then((resChild) => {
          if (resChild.data.code === 200) {
            let notif = {
              message: resChild.data.message,
              type: "primary",
              status: "success",
              toggle: true,
            };
            setNotif(notif);
            getSetting();
          }
        });
      }
    });
  };

  const onUpdate = (payload) => {
    putData(`v2/setting/receipt/print`, payload).then((res) => {
      console.log(res);
      if (res.data.code === 200) {
        let notif = {
          message: res.data.message,
          type: "primary",
          status: "success",
          toggle: true,
        };
        setNotif(notif);
        getSetting();
      }
    });
  };

  React.useEffect(() => {
    getSetting();
  }, []);

  return (
    <div className="w-full h-screen overflow-scroll scroll-hidden pt-20 px-5">
      {notif.toggle && (
        <Banner
          className="fixed top-14 z-50 left-0 right-0"
          message={notif.message}
          status={notif.status}
          type={notif.type}
        />
      )}
      <div className="grid grid-cols-10 gap-7">
        <div className="col-span-10">
          {/* struk digital */}
          <h1 className="font-semibold text-xl">Struk kertas</h1>
          <p className="text-gray-700 mt-2">
            Ubah dan personalisirkan struk kertas bisnis kamu yang akan di cetakkan untuk pelanggan
            <br /> kamu setelah mereka melakukan pembayaran.
          </p>
          {/* branding */}
          <h1 className="font-semibold text-xl mt-10 mb-4">Branding</h1>
          <ul>
            <li className="border-b">
              <div className="flex">
                <div className="bg-gray-05" style={{ minWidth: "12rem" }}>
                  <p className="font-semibold pl-6 pt-6">Logo Bisnis</p>
                </div>
                <div className="p-6">
                  <ImageField
                    type="default"
                    label=""
                    action
                    value={field.logo}
                    name="image"
                    onChange={onChangeLogo}
                  />
                </div>
              </div>
            </li>
          </ul>
          {/* branding */}
          <h1 className="font-semibold text-xl mt-10 mb-4">Informasi tambahan</h1>
          <ul>
            <li className="border">
              <div className="flex items-start p-4">
                <div className="relative top-1">
                  <Toggle
                    type="primary"
                    checked={field.show_location}
                    onChange={() => onUpdate({ show_location: !field.show_location })}
                  />
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="pl-12">
                    <p className="font-semibold">Nama & alamat lokasi</p>
                    <p className="mt-4 text-sm">
                      Struk digital akan menampilkan nama dan alamat lokasi sesuai dengan apa yang
                      sudah dimasukkan di bagian pengaturan lokasi.
                    </p>
                  </div>
                  <Link to="/setting/locations">
                    <div className="flex items-center ml-3 ">
                      <p className="whitespace-nowrap text-sm font-semibold mr-2">
                        Lihat pengaturan lokasi
                      </p>
                      {/* icon arrow */}
                      <IconArrow fill="#000000" width="10" height="10" />
                    </div>
                  </Link>
                </div>
              </div>
            </li>
            <li className="border">
              <div className="flex items-start p-4">
                <div className="relative top-1">
                  <Toggle
                    type="primary"
                    checked={field.show_contact}
                    onChange={() => onUpdate({ show_contact: !field.show_contact })}
                  />
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="pl-12">
                    <p className="font-semibold">Informasi kontak lokasi</p>
                    <p className="mt-4 text-sm">
                      Struk digital akan menampilkan nomor telepon dan email lokasi sesuai dengan
                      apa yang telah dimasukkan di bagian pengaturan lokasi.
                    </p>
                  </div>
                  <Link to="/setting/locations">
                    <div className="flex items-center ml-3 ">
                      <p className="whitespace-nowrap text-sm font-semibold mr-2">
                        Lihat pengaturan lokasi
                      </p>
                      {/* icon arrow */}
                      <IconArrow fill="#000000" width="10" height="10" />
                    </div>
                  </Link>
                </div>
              </div>
            </li>
            <li className="border">
              <div className="flex items-start p-4">
                <div className="relative top-1">
                  <Toggle
                    type="primary"
                    checked={field.show_media_social}
                    onChange={() => onUpdate({ show_media_social: !field.show_media_social })}
                  />
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="pl-12">
                    <p className="font-semibold">Media sosial</p>
                    <p className="mt-4 text-sm">
                      Struk digital akan menampilkan semua media sosial yang sudah ada di bagian
                      halaman informasi akun.
                    </p>
                  </div>
                  <Link to="/setting">
                    <div className="flex items-center ml-3 ">
                      <p className="whitespace-nowrap text-sm font-semibold mr-2">
                        Lihat informasi akun
                      </p>
                      {/* icon arrow */}
                      <IconArrow fill="#000000" width="10" height="10" />
                    </div>
                  </Link>
                </div>
              </div>
            </li>
            <li className="border">
              <div className="flex items-start p-4">
                <div className="relative top-1">
                  <Toggle
                    type="primary"
                    checked={field.show_qrcode}
                    onChange={() => onUpdate({ show_qrcode: !field.show_qrcode })}
                  />
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="pl-12">
                    <p className="font-semibold">Kode QR Ulasan</p>
                    <p className="mt-4 text-sm">
                      Struk digital akan menampilkan kode QR yang bisa discan pelanggan <br /> untuk
                      memberi ulasan untuk lokasi
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <h1 className="font-semibold text-xl mt-10 mb-4">
            Tambah kalimat&nbsp;
            <span className="text-gray-600 text-base font-normal">
              (ketuk diluar form untuk simpan otomatis)
            </span>
          </h1>
          <ul className="setting-location">
            <li className="flex w-full border-gray-04 py-2 ">
              <TextArea
                variant="default"
                rows={5}
                placeholder="placeholder"
                label="Kebijakan pengambilan"
                name="textarea"
                value={field.term_condition}
                onChange={(e) => setField({ ...field, term_condition: e.target.value })}
                onBlur={() => onUpdate({ term_condition: field.term_condition })}
              />
            </li>
            <li className="flex w-full border-b border-gray-04 py-2">
              <TextArea
                variant="default"
                rows={5}
                placeholder="placeholder"
                label="Kalimat apresiasi"
                name="textarea"
                value={field.outro_text}
                onChange={(e) => setField({ ...field, outro_text: e.target.value })}
                onBlur={() => onUpdate({ outro_text: field.outro_text })}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
