import React from "react";

export default function Drag({ width, fill, height, className }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00008 4.66667C6.26379 4.66667 6.52158 4.58847 6.74084 4.44196C6.96011 4.29545 7.131 4.08721 7.23192 3.84358C7.33284 3.59994 7.35924 3.33185 7.3078 3.07321C7.25635 2.81457 7.12936 2.57699 6.94289 2.39052C6.75642 2.20405 6.51884 2.07707 6.2602 2.02562C6.00156 1.97417 5.73347 2.00058 5.48984 2.10149C5.2462 2.20241 5.03796 2.37331 4.89146 2.59257C4.74495 2.81184 4.66675 3.06963 4.66675 3.33333C4.66675 3.68696 4.80722 4.02609 5.05727 4.27614C5.30732 4.52619 5.64646 4.66667 6.00008 4.66667ZM6.00008 11.3333C5.73637 11.3333 5.47859 11.4115 5.25932 11.558C5.04006 11.7045 4.86916 11.9128 4.76824 12.1564C4.66733 12.4001 4.64092 12.6681 4.69237 12.9268C4.74382 13.1854 4.8708 13.423 5.05727 13.6095C5.24374 13.7959 5.48132 13.9229 5.73996 13.9744C5.9986 14.0258 6.26669 13.9994 6.51033 13.8985C6.75396 13.7976 6.9622 13.6267 7.10871 13.4074C7.25522 13.1882 7.33342 12.9304 7.33342 12.6667C7.33342 12.313 7.19294 11.9739 6.94289 11.7239C6.69284 11.4738 6.3537 11.3333 6.00008 11.3333ZM6.00008 6.66667C5.73637 6.66667 5.47859 6.74487 5.25932 6.89137C5.04006 7.03788 4.86916 7.24612 4.76824 7.48976C4.66733 7.73339 4.64092 8.00148 4.69237 8.26012C4.74382 8.51876 4.8708 8.75634 5.05727 8.94281C5.24374 9.12928 5.48132 9.25627 5.73996 9.30771C5.9986 9.35916 6.26669 9.33276 6.51033 9.23184C6.75396 9.13092 6.9622 8.96003 7.10871 8.74076C7.25522 8.52149 7.33342 8.26371 7.33342 8C7.33342 7.64638 7.19294 7.30724 6.94289 7.05719C6.69284 6.80714 6.3537 6.66667 6.00008 6.66667Z"
        fill={fill}
      />
      <path
        d="M10.0001 4.66667C10.2638 4.66667 10.5216 4.58847 10.7408 4.44196C10.9601 4.29545 11.131 4.08721 11.2319 3.84358C11.3328 3.59994 11.3592 3.33185 11.3078 3.07321C11.2563 2.81457 11.1294 2.57699 10.9429 2.39052C10.7564 2.20405 10.5188 2.07707 10.2602 2.02562C10.0016 1.97417 9.73347 2.00058 9.48984 2.10149C9.2462 2.20241 9.03796 2.37331 8.89146 2.59257C8.74495 2.81184 8.66675 3.06963 8.66675 3.33333C8.66675 3.68696 8.80722 4.02609 9.05727 4.27614C9.30732 4.52619 9.64646 4.66667 10.0001 4.66667ZM10.0001 11.3333C9.73637 11.3333 9.47859 11.4115 9.25932 11.558C9.04006 11.7045 8.86916 11.9128 8.76824 12.1564C8.66733 12.4001 8.64092 12.6681 8.69237 12.9268C8.74382 13.1854 8.8708 13.423 9.05727 13.6095C9.24374 13.7959 9.48132 13.9229 9.73996 13.9744C9.9986 14.0258 10.2667 13.9994 10.5103 13.8985C10.754 13.7976 10.9622 13.6267 11.1087 13.4074C11.2552 13.1882 11.3334 12.9304 11.3334 12.6667C11.3334 12.313 11.1929 11.9739 10.9429 11.7239C10.6928 11.4738 10.3537 11.3333 10.0001 11.3333ZM10.0001 6.66667C9.73637 6.66667 9.47859 6.74487 9.25932 6.89137C9.04006 7.03788 8.86916 7.24612 8.76824 7.48976C8.66733 7.73339 8.64092 8.00148 8.69237 8.26012C8.74382 8.51876 8.8708 8.75634 9.05727 8.94281C9.24374 9.12928 9.48132 9.25627 9.73996 9.30771C9.9986 9.35916 10.2667 9.33276 10.5103 9.23184C10.754 9.13092 10.9622 8.96003 11.1087 8.74076C11.2552 8.52149 11.3334 8.26371 11.3334 8C11.3334 7.64638 11.1929 7.30724 10.9429 7.05719C10.6928 6.80714 10.3537 6.66667 10.0001 6.66667Z"
        fill={fill}
      />
    </svg>
  );
}

Drag.defaultProps = {
  width: "16px",
  height: "16px",
  fill: "#8F8F8F",
};
