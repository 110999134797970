export const Product = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.00002 6.00002C6.80223 6.00002 6.6089 6.05867 6.44445 6.16855C6.28 6.27843 6.15182 6.43461 6.07614 6.61733C6.00045 6.80006 5.98065 7.00113 6.01923 7.19511C6.05782 7.38909 6.15306 7.56727 6.29291 7.70712C6.43276 7.84698 6.61094 7.94222 6.80493 7.9808C6.99891 8.01939 7.19997 7.99958 7.3827 7.9239C7.56543 7.84821 7.7216 7.72004 7.83149 7.55559C7.94137 7.39114 8.00002 7.1978 8.00002 7.00002C8.00002 6.7348 7.89466 6.48045 7.70712 6.29291C7.51959 6.10537 7.26523 6.00002 7.00002 6.00002ZM21.71 11.78L12.23 2.32002C12.1368 2.21948 12.0239 2.13919 11.8983 2.08414C11.7727 2.02909 11.6371 2.00046 11.5 2.00002H5.50002C5.36841 1.99926 5.23795 2.02448 5.11611 2.07425C4.99427 2.12401 4.88346 2.19734 4.79002 2.29002L2.29002 4.78002C2.19734 4.87346 2.12401 4.98427 2.07425 5.10611C2.02448 5.22795 1.99926 5.35841 2.00002 5.49002V11.49C2.0037 11.7548 2.10728 12.0084 2.29002 12.2L11.78 21.7C11.9716 21.8828 12.2252 21.9863 12.49 21.99C12.6216 21.9908 12.7521 21.9656 12.8739 21.9158C12.9958 21.866 13.1066 21.7927 13.2 21.7L21.71 13.19C21.8027 13.0966 21.876 12.9858 21.9258 12.8639C21.9756 12.7421 22.0008 12.6116 22 12.48C21.9938 12.2187 21.8904 11.9691 21.71 11.78ZM12.49 19.59L4.00002 11.09V5.90002L5.90002 4.00002H11.08L19.58 12.49L12.49 19.59Z" />
    </svg>
  );
};
