import React from "react";
import { Dropdown, DropdownList } from "components/atoms/Dropdown/dropdown";
import Icon from "components/atoms/Icons/indexV2";
import Spinner from "components/atoms/Spinner";
// import Banner from "components/atoms/Banner";
import { useDispatch, useSelector } from "react-redux";
import {
  setExpand,
  setToggleMaterial,
  setToggleVariant,
  setProductName,
  setModalPrimary,
  setModalSecondary,
  setWithMaterialVariant,
  setWithoutMaterialVariant,
  setMaterialOnly,
  setVariantWithLocation,
  setVariantOnly,
  setIdStock,
  setDisabledVariant,
} from "features/Stock/actions";
import { setId, setModalHistory } from "features/StockHistory/actions";
import { setMaterialUsed } from "features/Material/action";
import { getData } from "utils/fetchData";
import ModalCreate from "pages/Inventory/Stock/create";
import EditStockVariant from "pages/Inventory/Stock/modal/editStockVariant";
import EditTrackStockVariant from "pages/Inventory/Stock/modal/editTrackStockVariant";
import ReciptManage from "pages/Inventory/Stock/modal/reciptManage";
import StockHistory from "pages/Inventory/Stock/modal/stockHistory";

function TableStock({ data }) {
  const dispatch = useDispatch();

  const historyStock = useSelector((state) => state.inventory_history);
  const [outlets, setOutlets] = React.useState([]);

  const getOutlet = async () => {
    await getData("outlets").then((res) => {
      let _temp = [];
      res.data.outlets.forEach((item) => {
        _temp.push({
          label: item.name,
          value: item.id,
        });
      });
      setOutlets(_temp);
    });
  };

  const openEditTrackStock = async (id) => {
    await getData(`v2/inventory/${id}`).then((res) => {
      let results = res.data.inventory;
      dispatch(setIdStock(id));
      dispatch(setToggleMaterial(results.withMaterial));
      dispatch(setToggleVariant(results.withVariant));
      dispatch(setProductName(results.productName));
      // console.log("results");
      // console.log(results);
      if (!results.withMaterial && !results.withVariant) {
        let _location = [];
        results.locations.forEach((items) => {
          _location.push({
            abstractId: items.abstractId,
            outletId: items.outletId,
            outletName: items.outletName,
            name: items.name,
            sku: items.sku,
            trackStock: items.trackStock,
            trackingStatus: items.trackingStatus,
            limitStock: items.limitStock,
            beforeStock: items.stock,
            changeStock: 0,
            afterStock: items.stock,
            reasonId: 1,
            isUpdate: false,
          });
        });
        dispatch(setWithoutMaterialVariant(_location));
        dispatch(setDisabledVariant(true));
        dispatch(setModalSecondary("show-editTrackStockVariant-update"));
      } else if (!results.withMaterial && results.withVariant) {
        let _outletsWithVariant = [];
        let _outletsOnly = [];
        results.locations.forEach((items) => {
          let _variants = [];
          items.variants.forEach((child) => {
            _variants.push({
              abstractId: child.abstractId,
              variantAbstractId: child.variantAbstractId,
              limitStock: child.limitStock,
              name: child.name,
              sku: child.sku,
              beforeStock: child.stock,
              trackStock: child.trackStock,
              trackingStatus: child.trackingStatus,
              variantOption: child.optionId,
              changeStock: 0,
              afterStock: child.stock,
              reasonId: 1,
              isUpdate: false,
            });
          });
          _outletsOnly.push({
            abstractId: items.abstractId,
            outletId: items.outletId,
            outletName: items.outletName,
            name: items.name,
            sku: items.sku,
            trackStock: items.trackStock,
            trackingStatus: items.trackingStatus,
            limitStock: items.limitStock,
            beforeStock: items.stock,
            changeStock: 0,
            afterStock: items.stock,
            reasonId: 1,
            isUpdate: false,
          });
          _outletsWithVariant.push({
            outletId: items.outletId,
            outletName: items.outletName,
            variants: _variants,
          });
          // dispatch(setVariantOnly(_variants));
        });
        dispatch(setWithoutMaterialVariant(_outletsOnly));
        dispatch(setVariantWithLocation(_outletsWithVariant));
        dispatch(setModalSecondary("show-editTrackStockVariant-update"));
      }
    });
  };
  const openEditCountStock = async (id) => {
    await getData(`v2/inventory/${id}`).then((res) => {
      let results = res.data.inventory;
      dispatch(setIdStock(id));
      dispatch(setToggleMaterial(results.withMaterial));
      dispatch(setToggleVariant(results.withVariant));
      dispatch(setProductName(results.productName));
      // console.log("results");
      // console.log(results);
      if (!results.withMaterial && !results.withVariant) {
        let _location = [];
        results.locations.forEach((items) => {
          _location.push({
            abstractId: items.abstractId,
            outletId: items.outletId,
            outletName: items.outletName,
            name: items.name,
            sku: items.sku,
            trackStock: items.trackStock,
            trackingStatus: items.trackingStatus,
            limitStock: items.limitStock,
            beforeStock: items.stock,
            changeStock: 0,
            afterStock: items.stock,
            reasonId: 1,
            isUpdate: false,
          });
        });
        dispatch(setWithoutMaterialVariant(_location));
        dispatch(setDisabledVariant(true));
        dispatch(setModalSecondary("show-editStockVariant-update"));
      } else if (!results.withMaterial && results.withVariant) {
        let _outletsWithVariant = [];
        let _outletsOnly = [];
        results.locations.forEach((items) => {
          let _variants = [];
          items.variants.forEach((child) => {
            _variants.push({
              abstractId: child.abstractId,
              variantAbstractId: child.variantAbstractId,
              limitStock: child.limitStock,
              name: child.name,
              sku: child.sku,
              beforeStock: child.stock,
              trackStock: child.trackStock,
              trackingStatus: child.trackingStatus,
              variantOption: child.optionId,
              changeStock: 0,
              afterStock: child.stock,
              reasonId: 1,
              isUpdate: false,
            });
          });
          _outletsOnly.push({
            abstractId: items.abstractId,
            outletId: items.outletId,
            outletName: items.outletName,
            name: items.name,
            sku: items.sku,
            trackStock: items.trackStock,
            trackingStatus: items.trackingStatus,
            limitStock: items.limitStock,
            beforeStock: items.stock,
            changeStock: 0,
            afterStock: items.stock,
            reasonId: 1,
            isUpdate: false,
          });
          _outletsWithVariant.push({
            outletId: items.outletId,
            outletName: items.outletName,
            variants: _variants,
          });
          // dispatch(setVariantOnly(_variants));
        });
        dispatch(setWithoutMaterialVariant(_outletsOnly));
        dispatch(setVariantWithLocation(_outletsWithVariant));
        dispatch(setModalSecondary("show-editStockVariant-update"));
      }
    });
  };
  const openEditStock = async (id) => {
    await getData(`v2/inventory/${id}`).then((res) => {
      let results = res.data.inventory;
      console.log("results");
      console.log(res);
      dispatch(setIdStock(id));
      dispatch(setToggleMaterial(results.withMaterial));
      dispatch(setToggleVariant(results.withVariant));
      dispatch(setProductName(results.productName));
      if (!results.withMaterial && !results.withVariant) {
        let _location = [];
        results.locations.forEach((items) => {
          _location.push({
            isUpdate: false,
            abstractId: items.abstractId,
            outletId: items.outletId,
            outletName: items.outletName,
            name: items.name,
            sku: items.sku,
            trackStock: items.trackStock,
            trackingStatus: items.trackingStatus,
            limitStock: items.limitStock,
            beforeStock: items.stock,
            changeStock: 0,
            afterStock: items.stock,
            totalStock: items.stock,
            reasonId: 1,
          });
        });
        dispatch(setWithoutMaterialVariant(_location));
        dispatch(setWithMaterialVariant([]));
        dispatch(setDisabledVariant(true));
        dispatch(setModalPrimary("show-update-stock"));
      } else if (results.withMaterial && results.withVariant) {
        let _variantMaterials = [];
        results.inventory.forEach((items) => {
          _variantMaterials.push({
            id: items.id,
            sku: items.sku,
            name: `${results.productName} - ${items.name}`,
            variantOptionId: items.variantOptionId,
            isChecked: false,
            isUpdate: false,
            rawMaterial: items.rawMaterials,
          });
        });

        let _tempVariantLocation = [];
        let _tempWithoutBoth = [];
        outlets.forEach((current, i) => {
          //without both
          _tempWithoutBoth.push({
            outletId: current.value,
            outletName: current.label,
            name: results.productName,
            sku: `${results.productName.replace(/\s/g, "").toUpperCase()}${i + 1}`,
            trackStock: true,
            trackingStatus: true,
            limitStock: 0,
            beforeStock: 0,
            changeStock: 0,
            afterStock: 0,
            reasonId: 1,
            isUpdate: false,
          });
          //generate variant only
          let _tempVariantOnly = [];
          results.variantUsed.forEach((current) => {
            let id = [];
            let name = [];
            current.ProductVariantOptions.forEach((item) => {
              if (item.type === 1) {
                id.push(item.id);
                name.push(item.name);
              }
            });
            _tempVariantOnly.push({
              name: `${results.productName} - ${name.join(", ")}`,
              sku: `${results.productName} - ${name.join(", ")}`,
              trackStock: true,
              trackingStatus: true,
              limitStock: 0,
              beforeStock: 0,
              changeStock: 0,
              afterStock: 0,
              reasonId: 1,
              variantOption: id,
              isUpdate: false,
            });
          });
          //generate variant with outlet
          _tempVariantLocation.push({
            outletId: current.value,
            outletName: current.label,
            variants: _tempVariantOnly,
          });
        });

        dispatch(setVariantWithLocation(_tempVariantLocation));
        dispatch(setWithoutMaterialVariant(_tempWithoutBoth));

        dispatch(setModalPrimary("show-update-stock"));
        dispatch(setDisabledVariant(false));
        dispatch(setWithMaterialVariant(_variantMaterials));
      } else if (results.withMaterial && !results.withVariant) {
        let _used = [];
        let _materials = [];
        results.rawMaterial.forEach((prev) => {
          _materials.push({
            id: prev?.id,
            rawMaterialId: prev?.rawMaterialId,
            name: prev?.rawMaterialName,
            value: prev?.value,
            unit: prev?.unit,
          });
          _used.push({
            id: null,
            rawMaterialId: prev?.rawMaterialId,
            name: prev?.rawMaterialName,
            value: prev.value,
            unit: prev?.unit,
            isChecked: true,
            date: prev.createdAt,
          });
        });

        dispatch(setMaterialUsed(_used));
        dispatch(setMaterialOnly(_materials));
        dispatch(setDisabledVariant(true));
        dispatch(setModalPrimary("show-update-stock"));
      } else if (!results.withMaterial && results.withVariant) {
        let _outlets = [];
        let _location = [];
        results.locations.forEach((item, i) => {
          let _variants = [];
          item.variants.forEach((child) => {
            _variants.push({
              abstractId: child.abstractId,
              variantAbstractId: child.variantAbstractId,
              limitStock: child.limitStock,
              name: child.name,
              sku: child.sku,
              beforeStock: child.stock,
              trackStock: child.trackStock,
              trackingStatus: child.trackingStatus,
              variantOption: child.optionId,
              changeStock: 0,
              afterStock: child.stock,
              reasonId: 1,
              isUpdate: false,
            });
          });
          _location.push({
            isUpdate: false,
            abstractId: null,
            outletId: item.outletId,
            outletName: item.outletName,
            name: results.productName,
            sku: `${results.productName.replace(/\s/g, "").toUpperCase()}${i + 1}`,
            trackStock: false,
            trackingStatus: false,
            limitStock: 0,
            beforeStock: 0,
            changeStock: 0,
            afterStock: 0,
            totalStock: 0,
            reasonId: 1,
          });
          _outlets.push({
            outletId: item.outletId,
            outletName: item.outletName,
            isUpdate: false,
            variants: _variants,
          });
        });
        dispatch(setWithoutMaterialVariant(_location));
        let _variantTable = [];
        results.defaultVariant.forEach((table) => {
          _variantTable.push({
            id: table.id,
            name: table.name,
            sku: table.sku,
            totalStock: table.totalStock,
          });
        });

        dispatch(setVariantOnly(_variantTable));
        dispatch(setVariantWithLocation(_outlets));
        dispatch(setModalPrimary("show-update-stock"));
        dispatch(setDisabledVariant(false));
      }
    });
  };
  const openRecipt = async (id) => {
    await getData(`v2/inventory/${id}`).then((res) => {
      let results = res.data.inventory;
      dispatch(setIdStock(id));
      dispatch(setToggleMaterial(results.withMaterial));
      dispatch(setToggleVariant(results.withVariant));
      dispatch(setProductName(results.productName));
      // console.log("results");
      // console.log(results);
      let _variantMaterials = [];
      results.inventory.forEach((items) => {
        _variantMaterials.push({
          id: items.id,
          sku: items.sku,
          name: `${results.productName} - ${items.name}`,
          variantOptionId: items.variantOptionId,
          isChecked: false,
          rawMaterial: items.rawMaterials,
        });
      });
      dispatch(setWithMaterialVariant(_variantMaterials));
      dispatch(setModalSecondary("show-reciptManage-update"));
    });
  };

  const openHistory = (id) => {
    dispatch(setId(id));
    if (id) {
      dispatch(setModalHistory("show-historyStock-update"));
    }
  };

  const expandVariant = (id) => {
    dispatch(
      setExpand(
        data.data.map((current) => {
          if (id === current.id) {
            current.isExpand = !current.isExpand;
          }
          return current;
        })
      )
    );
  };

  React.useEffect(() => {
    getOutlet();
  }, []);
  return (
    <React.Fragment>
      <table className="w-full">
        <thead>
          <tr className="bg-gray-100 border-t border-b text-left border-gray-300">
            <th></th>
            <th>Nama produk</th>
            <th>Stok tersisa</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.status === "process" ? (
            <tr key="status">
              <td colSpan="4" className="text-center">
                <div className="flex items-center justify-center">
                  <Spinner size={64} color="#859DFF" />
                </div>
              </td>
            </tr>
          ) : data.data.length ? (
            data.data.map((items) => {
              return (
                <React.Fragment key={items.id}>
                  <tr>
                    <td onClick={() => expandVariant(items.id)}>
                      {(!items.withVariant && items.withMaterial) ||
                      (!items.withVariant && !items.withMaterial) ? (
                        ""
                      ) : (
                        <Icon iconName={items.isExpand ? "UilAngleDown" : "UilAngleRight"} />
                      )}
                    </td>
                    <td>{items.productName}</td>
                    <td>{items.stock}</td>
                    <td>
                      {(!items.withVariant && !items.withMaterial) ||
                      (items.withVariant && !items.withMaterial) ? (
                        <Dropdown variant="subtle" color="blue" content="icon only">
                          <DropdownList
                            onClick={() => openEditStock(items.id)}
                            type="button"
                            color="blue"
                          >
                            Edit stock
                          </DropdownList>
                          <DropdownList
                            onClick={() => openEditCountStock(items.id)}
                            type="button"
                            color="blue"
                          >
                            Edit jumlah stok
                          </DropdownList>
                          <DropdownList
                            onClick={() => openEditTrackStock(items.id)}
                            type="button"
                            color="blue"
                          >
                            Edit pelacakan stok
                          </DropdownList>
                          <DropdownList
                            onClick={() => openHistory(items.id)}
                            type="button"
                            color="blue"
                          >
                            Lihat sejarah stok
                          </DropdownList>
                          {/* <DropdownList type="button" color="blue">
                            Buat barcode product
                          </DropdownList> */}
                        </Dropdown>
                      ) : items.withVariant && items.withMaterial ? (
                        <Dropdown variant="subtle" color="blue" content="icon only">
                          <DropdownList
                            onClick={() => openEditStock(items.id)}
                            type="button"
                            color="blue"
                          >
                            Edit stock
                          </DropdownList>
                          <DropdownList
                            onClick={() => openRecipt(items.id)}
                            type="button"
                            color="blue"
                          >
                            Edit resep
                          </DropdownList>
                          {/* <DropdownList type="button" color="blue">
                            Tambah bahan baku
                          </DropdownList> */}
                          {/* <DropdownList type="button" color="blue">
                            Buat barcode product
                          </DropdownList> */}
                        </Dropdown>
                      ) : !items.withVariant && items.withMaterial ? (
                        <Dropdown variant="subtle" color="blue" content="icon only">
                          <DropdownList
                            onClick={() => openEditStock(items.id)}
                            type="button"
                            color="blue"
                          >
                            Edit stock
                          </DropdownList>
                          {/* <DropdownList type="button" color="blue">
                            Buat barcode product
                          </DropdownList> */}
                        </Dropdown>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  {items.variants.length && items.isExpand
                    ? items.variants.map((variant) => {
                        return (
                          <tr key={variant.id}>
                            <td></td>
                            <td>{variant.name}</td>
                            <td>{variant.stock}</td>
                            <td></td>
                          </tr>
                        );
                      })
                    : ""}
                  {/* {data.notif.toggle && data.notif.id === items.id && (
                    <tr>
                      <td colSpan={6} className="p-0">
                        <Banner
                          status={data.notif.status}
                          type={data.notif.type}
                          message={data.notif.message}
                        />
                      </td>
                    </tr>
                  )} */}
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                <span className="text-gray-02">Tidak ditemukan data</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <ModalCreate
        show={data.modal_primary === "show-update-stock" ? true : false}
        hide={() => dispatch(setModalPrimary("hide-update-stock"))}
      />
      <EditStockVariant
        close={() => dispatch(setModalSecondary("hide-editStockVariant-update"))}
        show={data.modal_secondary === "show-editStockVariant-update" ? true : false}
      />
      <EditTrackStockVariant
        close={() => dispatch(setModalSecondary("hide-editTrackStockVariant-update"))}
        show={data.modal_secondary === "show-editTrackStockVariant-update" ? true : false}
      />
      <ReciptManage
        close={() => dispatch(setModalSecondary("hide-reciptManage-update"))}
        show={data.modal_secondary === "show-reciptManage-update" ? true : false}
      />
      <StockHistory
        close={() => dispatch(setModalHistory("hide-historyStock-update"))}
        show={historyStock.modal === "show-historyStock-update" ? true : false}
      />
    </React.Fragment>
  );
}

export default TableStock;
