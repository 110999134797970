import React from "react";
import { Tooltips } from "../Component/ComponentInput/Index";

export default function Tooltip() {
  return (
    <div className="w-10/12 pt-16 mt-5">
      <div className="relative h-56">
        {/* start top all position absolute */}
        <Tooltips
          className={"left-0"}
          leftArrow={"-bottom-1"}
          topArrow={"left-5"}
        />
        <Tooltips
          className={"left-1/2 transform -translate-x-1/2"}
          leftArrow={"-bottom-1 left-1/2 transform -translate-x-1/2"}
        />
        <Tooltips
          className={"top-0 right-5"}
          leftArrow={"-bottom-1 right-5"}
          bottomArrow={"bottom-4"}
        />
        {/* end top all position absolute */}
      </div>

      <div className="relative" style={{ height: "50rem" }}>
        {/* start right all position absolute */}
        <Tooltips
          className={"right-5 "}
          leftArrow={"-left-1"}
          topArrow={"top-4"}
        />
        <Tooltips className={"right-5 top-166 mt-10"} leftArrow={"-left-1"} />
        <Tooltips
          className={"right-5 top-332px mt-20"}
          leftArrow={"-left-1"}
          bottomArrow={"bottom-4"}
        />
        {/* end right all position absolute */}

        {/* start left all position absolute */}
        <Tooltips leftArrow={"-right-1"} topArrow={"top-5"} />
        <Tooltips className={"top-5 top-166 mt-10"} leftArrow={"-right-1"} />
        <Tooltips
          className={"top-5 top-332px mt-20"}
          leftArrow={"-right-1"}
          bottomArrow={"bottom-4"}
        />
        {/* end left all position absolute */}

        {/* start bottom all position absolute */}
        <Tooltips
          className={"bottom-0"}
          leftArrow={"-top-1"}
          topArrow={"left-5"}
        />
        <Tooltips
          className={"left-1/2 transform -translate-x-1/2 bottom-0"}
          leftArrow={"-top-1 left-1/2 transform -translate-x-1/2"}
        />
        <Tooltips
          className={"bottom-0 right-5"}
          leftArrow={"-top-1 right-5"}
          bottomArrow={"bottom-4"}
        />
        {/* end bottom all position absolute */}
      </div>

      {/* tooltips with btn */}
      <h1 className="text-2xl font-bold text-gray-500 mt-10">
        Tooltips with btn
      </h1>
      <div className="relative h-56 mt-5">
        {/* start top all position absolute */}

        <Tooltips
          className={"left-0"}
          leftArrow={"-bottom-1"}
          topArrow={"left-5"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        <Tooltips
          className={"left-1/2 transform -translate-x-1/2"}
          leftArrow={"-bottom-1 left-1/2 transform -translate-x-1/2"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        <Tooltips
          className={"top-0 right-5"}
          leftArrow={"-bottom-1 right-5"}
          bottomArrow={"bottom-4"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        {/* end top all position absolute */}
      </div>

      <div className="relative" style={{ height: "55rem" }}>
        {/* start right all position absolute */}
        <Tooltips
          className={"right-5 "}
          leftArrow={"-left-1"}
          topArrow={"top-4"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        <Tooltips
          className={"right-5 top-166 mt-10"}
          leftArrow={"-left-1"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        <Tooltips
          className={"right-5 top-332px mt-20"}
          leftArrow={"-left-1"}
          bottomArrow={"bottom-4"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        {/* end right all position absolute */}

        {/* start left all position absolute */}
        <Tooltips
          leftArrow={"-right-1"}
          topArrow={"top-5"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        <Tooltips
          className={"top-5 top-166 mt-10"}
          leftArrow={"-right-1"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        <Tooltips
          className={"top-5 top-332px mt-20"}
          leftArrow={"-right-1"}
          bottomArrow={"bottom-4"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        {/* end left all position absolute */}

        {/* start bottom all position absolute */}
        <Tooltips
          className={"bottom-0"}
          leftArrow={"-top-1"}
          topArrow={"left-5"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        <Tooltips
          className={"left-1/2 transform -translate-x-1/2 bottom-0"}
          leftArrow={"-top-1 left-1/2 transform -translate-x-1/2"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        <Tooltips
          className={"bottom-0 right-5"}
          leftArrow={"-top-1 right-5"}
          bottomArrow={"bottom-4"}
          btn1={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold mr-2">
              Button
            </button>
          }
          btn2={
            <button className="py-2 px-4 bg-gray-03 text-white rounded-lg font-semibold">
              Button
            </button>
          }
        />
        {/* end bottom all position absolute */}
      </div>
    </div>
  );
}
