import * as  React from "react";
import ModalDialog from "components/molecules/Modal";
import Button from "components/atoms/Button";
import Checkbox from "components/atoms/Checkbox";
import SelectBox from "components/atoms/SelectBox";
import TextCaption from "components/atoms/Text/TextCaption";
import { useForm } from "react-hook-form";
import { postData } from "utils/fetchData";
import { useDispatch } from "react-redux";
import { fetchTransaction } from "features/Transaction/actions";

export default function Reason({ closeRefund, show, close, transaction, productRefund }) {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setError, clearError } = useForm({
    mode: "onBlur"
  });
  const [loading, setLoading] = React.useState(false);
  const [field, setField] = React.useState({
    message: {
      label: "Pilih alasan",
      value: "",
    },
    info: false

  });
  const option = [
    {
      label: "Kecerobohan karyawan",
      value: "Kecerobohan karyawan",
    },
    {
      label: "Kesalahan teknis / perangkat",
      value: "Kesalahan teknis / perangkat",
    },
    {
      label: "Pesanan dibatalkan",
      value: "Pesanan dibatalkan",
    },
    {
      label: "Penipuan",
      value: "Penipuan",
    },
    {
      label: "Alasan lain",
      value: "Alasan lain",
    },
  ];

  const onChange = (e) => {
    setField({ ...field, info: e.target.checked })
  }

  const onChangeReason = (e) => {
    if (e.value === "") {
      setError("message", "message", "alasan tidak boleh kosong.");
    } else {
      clearError("message");
    }
    setField({ ...field, message: e });

  };

  const onSubmit = async () => {
    try {


      setLoading(true)
      let items = []
      productRefund.forEach((data) => {
        if (data.isChecked) {
          items.push(data.id)
        }
      })

      const payload = {
        items: items,
        message: field.message.value,
        info: field.info,
      }


      const res = await postData(`sales/refund/products/${transaction}`, payload)

      if (res.data.code === 200) {
        setLoading(false)
        dispatch(fetchTransaction());
        close()
        closeRefund()
        setField({
          ...field, message: {
            label: "Pilih alasan",
            value: "",
          },
          info: false
        });
      }

    } catch (err) {
      console.log("err.response >>>>")
      console.log(err.response)
    }

  }


  return (
    <ModalDialog
      size={"alert"}
      show={show}
      onClose={close}
      title={<h1 className="option-gray-02 heading-3">Alasan</h1>}
    >
      <div className={`bg-white border-t h-full overflow-scroll`}>
        <h1 className="font-normal p-4">
          <span className="font-semibold">
            Pilih produk yang akan direfund&nbsp;
          </span>
          (Refund akan termasuk dengan jumlah variasi, diskon, dan pajak).
        </h1>

        <SelectBox type="text" value={field.message} options={option} onChange={onChangeReason}>
          {errors.message && <TextCaption status="error" message={errors.message?.message} />}
        </SelectBox>
        <ul
          className="scroll-hidden overflow-y-scroll"
          style={{ height: "45vh" }}
        >

          <li className="flex items-center justify-between px-4 border p-4">
            <div className="flex items-center">
              <div className="relative -top-3">
                <Checkbox
                  type="default"
                  checked={field.info}
                  name="checkbox"
                  value={field.info}
                  size="md"
                  onChange={(e) => onChange(e)}
                />
              </div>
              <p className="text-blue-03 ml-10">Martabak variant diskon</p>

            </div>
            <p className="font-semibold">Rp. 20.900</p>
          </li>
        </ul>

        <div className="px-5 flex justify-end items-center bg-white border-t">
          <Button
            variant="secondary"
            color="red"
            className="mr-4"
            type="button"
            onClick={close}
          >
            Kembali
          </Button>
          <Button
            variant="secondary"
            color="red"
            className="mr-4"
            type="button"
            onClick={() => {
              closeRefund()
              close()
            }}
          >
            Batal
          </Button>
          <Button
            loading={loading}
            onClick={() => (!loading ? onSubmit() : null)}
            disabled={field.message.value === "" ? true : false}
            variant="primary" color="blue" className="my-4" type="button">
            Refund produk
          </Button>
        </div>
      </div >
    </ModalDialog >
  );
}
