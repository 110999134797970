import React, { useState, useEffect } from "react";
import Logo from "assets/icon/LogoSello.svg";
import IconSadFace from "components/atoms/Icons/Custom/icon new/SadFace";
import IconCustomerHappy from "components/atoms/Icons/Custom/icon new/CustomerHappy";
import TextArea from "components/atoms/Form/InputTextArea";
import Button from "components/atoms/Button";
import { getData } from "utils/fetchData";
import "./style.css";

import { config } from "../../config";
import axios from "axios";

export default function Create(props) {
  const [loading, setLoading] = useState(false);

  const token = props.location.search.substring("?token=".length);
  const types = props.location.pathname.substring("customer/review/create".length);
  const id = types.split("/");
  let typeValue = id[1];
  if (typeValue === "+1") {
    typeValue = 1;
  } else {
    typeValue = 0;
  }
  localStorage.setItem("token", token);

  const [positive, setPositive] = useState(true);
  const [aspect, setAspect] = useState([]);

  const [field, setField] = useState({
    message: "",
    aspect: 1,
    assign: 0,
    type: typeValue,
    token: token,
  });

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "aspect") {
      setAspect(
        aspect.map((item) => {
          if (item.id === value) {
            item.isChecked = !item.isChecked;
          }
          return item;
        })
      );
      setField({ ...field, [name]: parseInt(value) });
    } else {
      setField({ ...field, [name]: value });
    }
  };
  const handleClickPositive = async () => {
    setPositive(true);
    setField({ ...field, type: 1 });
    try {
      let res = await getData("review/aspect?type=1");
      let _temp = [];
      res.data.aspect.forEach((item) => {
        _temp.push({
          id: item.id,
          name: item.name,
          isChecked: false,
        });
      });
      setAspect(_temp);
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleClickNegative = async () => {
    setPositive(false);
    setField({ ...field, type: 0 });
    try {
      let res = await getData("review/aspect?type=0");

      let _temp = [];
      res.data.aspect.forEach((item) => {
        _temp.push({
          id: item.id,
          name: item.name,
          isChecked: false,
        });
      });
      setAspect(_temp);
    } catch (error) {
      console.log(error.response);
    }
  };
  const fetchAspect = async () => {
    try {
      let res = await axios.get(`${config.api_host}/review/aspect?type=1"`);

      let _temp = [];
      res.data.aspect.forEach((item) => {
        _temp.push({
          id: item.id,
          name: item.name,
          isChecked: false,
        });
      });
      setAspect(_temp);
    } catch (error) {
      console.log(error.response);
    }
  };
  const createReview = async () => {
    setLoading(true);
    await axios
      .post(`${config.api_host}/review/customer/${id[2]}`, field, {
        headers: { "x-access-token": token },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setLoading(false);
          window.location.href = "/customer/review/finish";
        } else if (res.data.code === 401) {
          alert("Ulasan yang anda gunakan sudah tidak bisa dipakai.");
          setLoading(false);
        }
      })
      .catch(() => {
        alert("Ulasan yang anda gunakan sudah tidak bisa dipakai.");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAspect();
    if (id[1] === "+1") {
      setPositive(true);
    } else {
      setPositive(false);
    }
  }, []);

  return (
    <div>
      <div className="bg-white z-10 flex justify-center p-4">
        <div className="flex items-center">
          <img src={Logo} alt={Logo} />
        </div>
      </div>
      {positive ? (
        <div className="bg-green-200 pt-8 pb-4 flex justify-center">
          <div>
            <h5 className="bold-text-1 text-dark">
              Bagaimana pengalaman anda di Restoran Nomor Satu?
            </h5>
            <div className="flex items-center mt-6 justify-center">
              <span onClick={handleClickNegative}>
                <IconSadFace className="mr-4" fill="#757575" width="64" height="64" />
              </span>
              <span onClick={handleClickPositive}>
                <IconCustomerHappy className="mr-4" width="75" height="75" />
              </span>

              <p className="text-green-02 bold-text-1">Positif</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-red-04 pt-8 pb-4 flex justify-center">
          <div>
            <h5 className="bold-text-1 text-dark">
              Bagaimana pengalaman anda di Restoran Nomor Satu?
            </h5>
            <div className="flex items-center mt-6 justify-center">
              <p className="text-red-02 bold-text-1 mr-4">Negatif</p>
              <span onClick={handleClickNegative}>
                <IconSadFace className="mr-4" fill="#757575" width="64" height="64" />
              </span>
              <span onClick={handleClickPositive}>
                <IconCustomerHappy className="mr-4" width="75" height="75" />
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="mt-6">
        {positive ? (
          <h5 className="bold-text-2 text-blue-03 text-center">
            Terima kasih untuk ulasan positifnya! Dalam aspek apa kami
            <br /> membuat pengalaman anda senang dan memuaskan?
          </h5>
        ) : (
          <h5 className="bold-text-2 text-blue-03 text-center">
            Kami mohon maaf atas ketidakpuasannya. Dalam aspek apa
            <br /> kami bisa diperbaiki dan ditingkatkan lagi?
          </h5>
        )}

        <ul className="flex flex-col items-center w-full justify-center mt-4">
          {aspect.length &&
            aspect.map((item) => {
              return (
                <li
                  key={item.id}
                  className="w-96 px-4 py-3 border-t border-b hover:bg-blue-05 duration-200 cursor-pointer"
                  style={{ minWidth: "24rem" }}
                >
                  <div className="flex items-center relative">
                    <input onChange={onChange} type="radio" name="aspect" value={item.id} />

                    <p className="text-2 ml-3">{item.name}</p>
                  </div>
                </li>
              );
            })}
          <li className="w-96 border-t border-b" style={{ minWidth: "24rem" }}>
            <div className="review-text-area mt-4">
              <TextArea
                variant="default"
                rows={5}
                placeholder="Customer servicenya bagus top bener!"
                label=""
                name="message"
                value={field.message}
                onChange={onChange}
              />
            </div>
          </li>
        </ul>
        <div className="flex justify-center w-full mt-6 mb-10">
          <Button loading={loading} onClick={createReview} variant="primary" color="blue">
            Kirimkan ulasan saya
          </Button>
        </div>
      </div>
    </div>
  );
}
