import {
  START_FETCHING_REGION,
  SUCCESS_FETCHING_REGION,
  ERROR_FETCHING_REGION,
  SET_PROVINCE,
  SET_CITY,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  countries: [],
  province: [],
  city: [],
  status: statuslist.idle,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_REGION:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_REGION:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_REGION:
      return {
        ...state,
        status: statuslist.success,
        province: action.province,
      };
    case SET_PROVINCE:
      return {
        ...state,
        province: action.province,
      };
    case SET_CITY:
      return {
        ...state,
        city: action.city,
      };

    default:
      return state;
  }
}
