export const listTransaction = [{
  value: '',
  label: "Semua transaksi",
},
{
  value: 'COMPLETED',
  label: "Penjualan",
},
{
  value: 'Refund',
  label: "Refund",
}]
