import { USER_LOGIN, USER_LOGOUT } from './constant';

let initialState =
  localStorage.getItem('auth')
    ? JSON.parse(localStorage.getItem('auth'))
    : { tour: null, token: null, access: null }


export default function reducer(state = initialState, action) {

  switch (action.type) {

    // (1) logika menangani action USER_LOGIN
    case USER_LOGIN:
      return {
        tour: action.tour,
        token: action.token,
        access: action.access,
        username: action.username,
        email: action.email,
        created_at: action.created_at,
        role: action.role
      }

    // (2) logika state `USER_LOGOUT`
    case USER_LOGOUT:
      return { tour: null, token: null, access: null, role: null }

    default:
      return state;
  }
}